import {Component, Input, OnInit} from '@angular/core';
import {Glossar} from '../../../../_classes/glossar/glossar';
import {Gsonderfertigkeit} from '../../../../_classes/glossar/gsonderfertigkeit';
import {Gkampfsonderfertigkeit} from '../../../../_classes/glossar/gkampfsonderfertigkeit';
import {Gtraditionsartefaktsonderfertigkeit} from '../../../../_classes/glossar/gtraditionsartefaktsonderfertigkeit';
import {Gtier} from '../../../../_classes/glossar/gtier';
import {Gtiergroesse} from '../../../../_classes/glossar/gtiergroesse';
import {NameWert} from '../../../../_classes/name-wert';
import {Gtierangriffenk} from '../../../../_classes/glossar/gtierangriffenk';
import {Gtierangriffefk} from '../../../../_classes/glossar/gtierangriffefk';
import {Gtiersf} from '../../../../_classes/glossar/gtiersf';
import {VorUndNachteil} from '../../../../_classes/charakter/vorUndNachteil';
import {Gtierwissen} from '../../../../_classes/glossar/gtierwissen';
import {DataService} from '../../../../_services/data.service';

@Component({
  selector: 'app-gtier',
  templateUrl: './gtier.component.html',
  styleUrls: ['./gtier.component.sass']
})
export class GtierComponent implements OnInit {
  @Input() glossar: Glossar;

  arten: string[] = [];
  art = '';
  unterarten: string[] = [];
  unterart = '';
  namen: string[] = [];
  name = '';
  tier: Gtier = new Gtier();

  groesseRaw = '';
  werteRaw = '';
  talenteRaw = '';
  zauberRaw = '';
  rsBeRaw = '';

  neuerAngriffNk: Gtierangriffenk = new Gtierangriffenk();
  neuerAngriffFk: Gtierangriffefk = new Gtierangriffefk();

  neueSonderfertigkeit: Gtiersf = new Gtiersf();

  neueVuN: VorUndNachteil = new VorUndNachteil();

  zielgroessen: string[] = ['winzig', 'klein', 'mittel', 'groß', 'riesig'];
  zieltypen: string[] = ['mehrbeinig', 'arachnoid', 'sechsbeinig', 'vierbeinig', 'vogelartig', 'humanoid'];

  neueSonderregel: NameWert = new NameWert();

  neueAnmerkung: NameWert = new NameWert();

  talente: string[] = [];
  anwendungen: string[] = [];

  neuesWissen: Gtierwissen = new Gtierwissen();

  neueAnekdote = '';


  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.glossar.talente.forEach(talent => {
      this.talente.push(talent.name);
    });
    this.talente.sort((a, b) => (a < b ? -1 : 1));
    this.neuesWissen.talent = 'Tierkunde';
    this.getAnwendungen();
    this.neuesWissen.anwendung = 'Wildtiere';
    this.neuesWissen.qs = 1;

    this.glossar.tiere.forEach(t => {
      if (!this.arten.includes(t.art)) {
        this.arten.push(t.art);
      }
    });
    this.arten.sort((a, b) => (a < b ? -1 : 1));
    this.art = this.arten[0];
    this.aendereArt();
  }


  aendereArt(): void {
    this.unterarten = [];
    this.glossar.tiere.forEach(data => {
      // Fülle unterArten für art
      if (data.art === this.art) {
        if (!this.unterarten.includes(data.unterArt)) {
          this.unterarten.push(data.unterArt);
        }
      }
    });
    this.unterarten.sort((a, b) => (a < b ? -1 : 1));
    // lege unterArt initial fest
    this.unterart = this.unterarten[0];
    this.aendereUnterart();
  }

  aendereUnterart(): void {
    this.namen = [];
    this.glossar.tiere.forEach(data => {
      // Fülle unterArten für art
      if (data.art === this.art && data.unterArt === this.unterart) {
        if (!this.namen.includes(data.variante)) {
          this.namen.push(data.variante);
        }
      }
    });
    this.namen.sort((a, b) => (a < b ? -1 : 1));
    // lege unterArt initial fest
    this.name = this.namen[0];
    this.waehleTier();
  }

  waehleTier(): void {
    console.log('wähle Tier');
    console.log(this.glossar.tiere);
    this.tier = new Gtier();
    this.glossar.tiere.forEach(data => {
      // Fülle unterArten für art
      if (data.art === this.art && data.unterArt === this.unterart && data.variante === this.name) {
        console.log(data);
        this.tier.copy(data);
      }
    });
    console.log(this.tier);
  }

  leseGroesseAus(): void {
    this.tier.groessen = [];
    while (this.groesseRaw.includes('(')) {
      this.groesseRaw = this.groesseRaw.replace('(', '');
    }
    while (this.groesseRaw.includes(')')) {
      this.groesseRaw = this.groesseRaw.replace(')', '');
    }
    while (this.groesseRaw.includes(',')) {
      this.groesseRaw = this.groesseRaw.replace(',', '.');
    }
    while (this.groesseRaw.includes(' Schritt')) {
      this.groesseRaw = this.groesseRaw.replace(' Schritt', '');
    }
    if (this.groesseRaw.includes(';')) {
      const splitted: string[] = this.groesseRaw.split(';');
      splitted.forEach(eintrag => {
        const groesse: Gtiergroesse = this.bildeGroesse(eintrag);
        if (groesse != null) {
          this.tier.groessen.push(groesse);
        }
      });
    } else {
      const groesse: Gtiergroesse = this.bildeGroesse(this.groesseRaw);
      if (groesse != null) {
        this.tier.groessen.push(groesse);
      }
    }
    console.log(this.tier.groessen);
  }

  bildeGroesse(eintrag: string): Gtiergroesse {
    let groesse: Gtiergroesse = new Gtiergroesse();
    if (eintrag.includes(' bis ')) {
      let subsplit: string[] = eintrag.split(' bis ');
      let anhang = '';
      if (subsplit.length > 2) {
        for (let i = 2; i < subsplit.length; i++) {
          anhang += ' ' + subsplit[i];
        }
      }
      groesse.min = Number(subsplit[0]);
      subsplit = subsplit[1].split(' ');
      groesse.max = Number(subsplit[0]);


      groesse.kategorie = subsplit[1];
      for (let i = 2; i < subsplit.length; i++) {
        groesse.kategorie += ' ' + subsplit[i];
      }
      groesse.kategorie += anhang;
    } else {
      groesse.kategorie = eintrag;
      groesse.min = 0;
      groesse.max = 0;
    }
    if (isNaN(groesse.min) || isNaN(groesse.max)) {
      groesse = null;
    }
    return groesse;
  }

  leseWerteAus(): void {
    let splitted: string[] = this.werteRaw.split(' ');
    console.log(splitted);
    const wertPaare: string[] = [];
    let paarTeil = '';
    let anzahl = 0;
    let gsraw = false;
    splitted.forEach(raw => {
      console.log(raw);
      if (raw === 'GS') {
        gsraw = true;
      }
      if (gsraw) {
        paarTeil += raw + ' ';
      } else {
        if (anzahl === 0 && !raw.includes('(')) {
          paarTeil = raw;
          anzahl++;
        } else if (!raw.includes('(')){
          paarTeil += ' ' + raw;
          anzahl = 0;
          wertPaare.push(paarTeil);
          paarTeil = '';
        }
      }

    });
    paarTeil = paarTeil.trim();
    if (paarTeil.includes(' / ')) {
      paarTeil = paarTeil.replace(' / ', '/');
    }
    console.log(paarTeil);
    if (paarTeil.includes('(')) {
      splitted = paarTeil.split('(');
      console.log(splitted);
      let rechteSeite: string[] = [];
      let linkeSeite: string[] = [];
      splitted.forEach(eintrag => {
        if (eintrag.includes('GS')) { // GS X/Y
          eintrag = eintrag.trim();
          const subsplit: string[] = eintrag.split(' ');
          rechteSeite = subsplit[1].split('/');
        } else { // an Land/in der Luft
          linkeSeite = eintrag.split('/');
        }
      });
      for (let i = 0; i < rechteSeite.length; i++) {
        let gsPaar = 'GS';
        if (linkeSeite[i].includes('Luft')) {
          gsPaar += '(Luft)';
        } else if (linkeSeite[i].includes('Wasser')) {
          gsPaar += '(Wasser)';
        }
        gsPaar += ' ' + rechteSeite[i];
        wertPaare.push(gsPaar);
      }
    } else {
      wertPaare.push(paarTeil);
    }
    console.log(wertPaare);
    this.tier.werte = [];
    wertPaare.forEach(paar => {
      if (paar.endsWith(',')) {
        paar = paar.substring(0, paar.length - 1);
      }
      const split: string[] = paar.split(' ');
      const nw: NameWert = new NameWert();
      nw.name = split[0];
      nw.wert = split[1];
      this.tier.werte.push(nw);
    });
  }

  leseTalente(): void {
    const splitted: string[] = this.talenteRaw.split(', ');
    this.verarbeiteTalentOderZauber(splitted);
  }

  leseZauber(): void {
    const splitted: string[] = this.zauberRaw.split(', ');
    this.verarbeiteTalentOderZauber(splitted);
  }

  verarbeiteTalentOderZauber(splitted: string[]): void {
    splitted.forEach(kombi => {
      if (kombi.includes(' (')) {
        kombi = kombi.split(' (')[0];
      }
      const nw: NameWert = new NameWert();
      const split: string[] = kombi.split(' ');
      nw.name = split[0];
      for (let i = 1; i < split.length - 1; i++) {
        nw.name += ' ' + split[i];
      }
      nw.wert = split[split.length - 1];
      if (nw.name.startsWith(nw.wert)) {
        nw.wert = '-1';
      }
      this.tier.werte.push(nw);
    });
  }

  entferneAngriffNk(a: Gtierangriffenk): void {
    const neu: Gtierangriffenk[] = [];
    this.tier.angriffenk.forEach(ang => {
      if (ang !== a) {
        neu.push(ang);
      }
    });
    this.tier.angriffenk = [];
    neu.forEach(ang => {
      this.tier.angriffenk.push(ang);
    });
  }
  entferneAngriffFk(a: Gtierangriffefk): void {
    const neu: Gtierangriffefk[] = [];
    this.tier.angriffefk.forEach(ang => {
      if (ang !== a) {
        neu.push(ang);
      }
    });
    this.tier.angriffefk = [];
    neu.forEach(ang => {
      this.tier.angriffefk.push(ang);
    });
  }
  fuegeAngriffNkHinzu(): void {
    this.tier.angriffenk.push(this.neuerAngriffNk);
    this.neuerAngriffNk = new Gtierangriffenk();
    console.log(this.tier.angriffenk);
  }
  fuegeAngriffFkHinzu(): void {
    this.tier.angriffefk.push(this.neuerAngriffFk);
    this.neuerAngriffFk = new Gtierangriffefk();
  }

  entferneAnmerkung(a: NameWert): void {
    const neu: NameWert[] = [];
    this.tier.anmerkungen.forEach(anm => {
      if (anm !== a) {
        neu.push(anm);
      }
    });
    this.tier.anmerkungen = [];
    neu.forEach(ang => {
      this.tier.anmerkungen.push(ang);
    });
  }
  fuegeAnmerkungHinzu(): void {
    this.tier.anmerkungen.push(this.neueAnmerkung);
    this.neueAnmerkung = new NameWert();
  }

  entferneSF(data: Gtiersf): void {
    const neu: Gtiersf[] = [];
    this.tier.sf.forEach(eintrag => {
      if (eintrag !== data) {
        neu.push(eintrag);
      }
    });
    this.tier.sf = [];
    neu.forEach(eintrag => {
      this.tier.sf.push(eintrag);
    });
  }
  fuegeSFHinzu(): void {
    this.tier.sf.push(this.neueSonderfertigkeit);
    this.neueSonderfertigkeit = new Gtiersf();
  }

  entferneVuN(data: VorUndNachteil): void {
    const neu: VorUndNachteil[] = [];
    this.tier.vun.forEach(eintrag => {
      if (eintrag !== data) {
        neu.push(eintrag);
      }
    });
    this.tier.vun = [];
    neu.forEach(eintrag => {
      this.tier.vun.push(eintrag);
    });
  }
  fuegeVuNHinzu(): void {
    this.tier.vun.push(this.neueVuN);
    this.neueVuN = new VorUndNachteil();
  }

  entferneWissen(data: Gtierwissen): void {
    const neu: Gtierwissen[] = [];
    this.tier.wissensProbe.forEach(eintrag => {
      if (eintrag !== data) {
        neu.push(eintrag);
      }
    });
    this.tier.wissensProbe = [];
    neu.forEach(eintrag => {
      this.tier.wissensProbe.push(eintrag);
    });
  }
  fuegeWissenHinzu(): void {
    const talent: string = this.neuesWissen.talent;
    const anwendung: string = this.neuesWissen.anwendung;
    const qs: number = this.neuesWissen.qs + 1;
    this.tier.wissensProbe.push(this.neuesWissen);
    this.neuesWissen = new Gtierwissen();
    this.neuesWissen.talent = talent;
    this.neuesWissen.anwendung = anwendung;
    this.neuesWissen.qs = qs;
  }

  entferneSonderregel(data: NameWert): void {
    const neu: NameWert[] = [];
    this.tier.sonderregeln.forEach(eintrag => {
      if (eintrag !== data) {
        neu.push(eintrag);
      }
    });
    this.tier.sonderregeln = [];
    neu.forEach(eintrag => {
      this.tier.sonderregeln.push(eintrag);
    });
  }
  fuegeSonderregelHinzu(): void {
    this.tier.sonderregeln.push(this.neueSonderregel);
    this.neueSonderregel = new NameWert();
  }

  entferneWert(data: NameWert): void {
    const neu: NameWert[] = [];
    this.tier.werte.forEach(eintrag => {
      if (eintrag !== data) {
        neu.push(eintrag);
      }
    });
    this.tier.werte = [];
    neu.forEach(eintrag => {
      this.tier.werte.push(eintrag);
    });
  }

  entferneAnekdote(data: string): void {
    const neu: string[] = [];
    this.tier.anekdoten.forEach(eintrag => {
      if (eintrag !== data) {
        neu.push(eintrag);
      }
    });
    this.tier.anekdoten = [];
    neu.forEach(eintrag => {
      this.tier.anekdoten.push(eintrag);
    });
  }
  fuegeAnekdoteHinzu(): void {
    this.tier.anekdoten.push(this.neueAnekdote);
    this.neueAnekdote = '';
  }

  verarbeiteRsBe(): void {
    const splitted: string[] = this.rsBeRaw.split('/');
    let nw: NameWert = new NameWert();
    nw.name = 'RS';
    nw.wert = splitted[0];
    this.tier.werte.push(nw);
    nw = new NameWert();
    nw.name = 'BE';
    nw.wert = splitted[1];
    this.tier.werte.push(nw);
  }

  entferneUmbrueche(text: string): string {
    while (text.includes('\n')) {
      text = text.replace('\n', ' ');
    }
    return text;
  }

  getAnwendungen(): void {
    console.log('getAnwendungen');
    this.anwendungen = [];
    this.glossar.talente.forEach(talent => {
      if (talent.name === this.neuesWissen.talent) {
        talent.anwendungsgebiete.forEach(anw => {
          this.anwendungen.push(anw.anwendung);
        });
      }
    });
    console.log(this.anwendungen);
  }

  sendeTierAnDB(): void {
    this.dataService.addGTier(this.tier);
    this.resetTier();
  }

  resetTier(): void {
    const art = this.tier.art;
    const unterart = this.tier.unterArt;
    const variante = this.tier.variante;
    this.tier = new Gtier();
    this.tier.art = art;
    this.tier.unterArt = unterart;
    this.tier.variante = variante;
  }
}
