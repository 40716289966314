import {NameWertNumber} from '../../_interfaces/name-wert-number';

export class Waffe {
  nahkampf: boolean;

  name: string;
  dateiname: string;
  kampftechnik: string;
  schandensb: number;
  schadenseig: string;
  chosenschadenseig: string;
  schadensschwelle: number;
  tp: string;
  atMod: number;
  paMod: number;
  reichweite: string;
  chosenReichweite: string;
  at: number;
  pa: number;
  gewicht: number;

  ladezeiten: string;
  reichweiten: string;
  munitionstyp: string;

  zitat: string;
  beschreibung: string;
  vorteil: string;
  nachteil: string;

  laengeInHF: number;
  preisInSilber: number;

  zweihand: boolean;

  status: string;

  constructor() {
    console.log('constructor of waffe');
    this.nahkampf = true;
    this.name = '';
    this.dateiname = '';
    this.kampftechnik = 'Raufen';
    this.schandensb = 0;
    this.schadenseig = '';
    this.chosenschadenseig = '';
    this.schadensschwelle = 0;
    this.tp = '';
    this.atMod = 0;
    this.paMod = 0;
    this.reichweite = '';
    this.chosenReichweite = '';
    this.at = 0;
    this.pa = 0;
    this.gewicht = 0;
    this.ladezeiten = '';
    this.reichweiten = '';
    this.munitionstyp = '';
    this.zitat = '';
    this.beschreibung = '';
    this.vorteil = '';
    this.nachteil = '';
    this.laengeInHF = 0;
    this.preisInSilber = 0;
    this.zweihand = false;
    this.status = '';
  }

  public clone(): Waffe {
    const waffe = new Waffe();
    waffe.nahkampf = this.nahkampf;
    waffe.name = this.name;
    waffe.dateiname = this.dateiname;
    waffe.kampftechnik = this.kampftechnik;
    waffe.schandensb = this.schandensb;
    waffe.schadenseig = this.schadenseig;
    waffe.chosenschadenseig = this.chosenschadenseig;
    waffe.schadensschwelle = this.schadensschwelle;
    waffe.tp = this.tp;
    waffe.atMod = this.atMod;
    waffe.paMod = this.paMod;
    waffe.reichweite = this.reichweite;
    waffe.chosenReichweite = this.chosenReichweite;
    waffe.at = this.at;
    waffe.pa = this.pa;
    waffe.gewicht = this.gewicht;
    waffe.ladezeiten = this.ladezeiten;
    waffe.reichweiten = this.reichweiten;
    waffe.munitionstyp = this.munitionstyp;
    waffe.zitat = this.zitat;
    waffe.beschreibung = this.beschreibung;
    waffe.vorteil = this.vorteil;
    waffe.nachteil = this.nachteil;
    waffe.laengeInHF = this.laengeInHF;
    waffe.preisInSilber = this.preisInSilber;
    waffe.zweihand = this.zweihand;
    waffe.status = this.status;
    return waffe;
  }

  public copy(other: Waffe): void {
    this.nahkampf = other.nahkampf;
    this.name = other.name;
    this.dateiname = other.dateiname;
    this.kampftechnik = other.kampftechnik;
    this.schandensb = other.schandensb;
    this.schadenseig = other.schadenseig;
    this.chosenschadenseig = other.chosenschadenseig;
    this.schadensschwelle = other.schadensschwelle;
    this.tp = other.tp;
    this.atMod = other.atMod;
    this.paMod = other.paMod;
    this.reichweite = other.reichweite;
    this.chosenReichweite = other.chosenReichweite;
    this.at = other.at;
    this.pa = other.pa;
    this.gewicht = other.gewicht;
    this.ladezeiten = other.ladezeiten;
    this.reichweiten = other.reichweiten;
    this.munitionstyp = other.munitionstyp;
    this.zitat = other.zitat;
    this.beschreibung = other.beschreibung;
    this.vorteil = other.vorteil;
    this.nachteil = other.nachteil;
    this.laengeInHF = other.laengeInHF;
    this.preisInSilber = other.preisInSilber;
    this.zweihand = other.zweihand;
    this.status = other.status;
  }

  atGeschafft(wurf: number): boolean {
    return wurf <= this.at && wurf !== 20;
  }

  atMitErschwernisGeschafft(wurf: number, erschw: number): boolean {
    return wurf <= (this.at - erschw) && wurf !== 20;
  }

  paGeschafft(wurf: number): boolean {
    return wurf <= this.pa && wurf !== 20;
  }

  calculateSchadensbonus(attribute: NameWertNumber[]): void {
    console.log('calculate Schadensbonus für ' + this.name);
    console.log(attribute);
    let schadensbonus = 0;

    const eigenschaften: string[] = [];
    if (this.schadenseig.includes('/')) {
      this.schadenseig.split('/').forEach(eig => {
        eigenschaften.push(eig);
      });
    } else {
      eigenschaften.push(this.schadenseig);
    }
    eigenschaften.forEach(le => {
      let attrWert = 8;
      attribute.forEach(attr => {
        if (attr.name.toLowerCase() === le.toLowerCase()) {
          attrWert = attr.wert;
        }
      });
      let sb = 0;
      for (let i = this.schadensschwelle; i < attrWert; i++) {
        sb ++;
      }
      schadensbonus = Math.max(schadensbonus, sb);
    });
    this.schandensb = schadensbonus;
  }

  getCurrentTP(mod: number): string {
    console.log('call getCurrentTP() on "' + this.name + '"');
    console.log('tpString: ' + this.tp);
    console.log('schadensB: ' + this.schandensb); // beim Khonchomer ERROR
    let tpString = '';

    let firstPart = '';
    let secondPart = 0;
    if (this.tp.includes('+')) {
      const splitted: string[] =  this.tp.split('+');
      firstPart = splitted[0];
      secondPart = Number(splitted[1]);
    } else if (this.tp.includes('-')) {
      const splitted: string[] =  this.tp.split('-');
      firstPart = splitted[0];
      secondPart = -Number(splitted[1]);
    } else {
      firstPart = this.tp;
    }
    secondPart += this.schandensb - -mod;
    console.log('first Part: ' + firstPart);
    console.log('second Part: ' + secondPart);
    if (secondPart > 0) {
      tpString = firstPart + '+' + secondPart;
    } else if (secondPart < 0) {
      tpString = firstPart + '-' + secondPart;
    } else {
      tpString = firstPart;
    }
    return tpString;
  }

}
