import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Heldin} from '../../_classes/charakter/heldin';
import {Gruppenmitglied} from '../../_classes/abenteuer/gruppenmitglied';
import {DataService} from '../../_services/data.service';
import {NameWert} from '../../_classes/name-wert';
import {Schriftstueck} from '../../_classes/abenteuer/schriftstueck';
import {Sonderfertigkeit} from '../../_classes/charakter/sonderfertigkeit';

@Component({
  selector: 'app-abenteuer',
  templateUrl: './abenteuer.component.html',
  styleUrls: ['./abenteuer.component.sass']
})
export class AbenteuerComponent implements OnInit {

  @Input() charakter: Heldin;
  @Input() gruppe: Gruppenmitglied[];

  material: NameWert[] = [];

  active: NameWert = new NameWert();
  index = 0;

  menu = ['Personen', 'Material', 'Schriftstücke'];
  nav = 'Personen';


  schriften: Schriftstueck[] = [];
  schriftIndex = 0;
  selectedSchrift: Schriftstueck = new Schriftstueck();

  schriftName = '';
  schriftInhalt = '';
  schriftSprache = '';
  schriftSchrift = '';
  schriftTextart = '';

  angezeigterText = '';

  constructor(private sanitizer: DomSanitizer,
              private dataService: DataService) {
    this.getMaterial();
    this.getSchriftstueck();
  }

  ngOnInit(): void {
  }

  getSanitizedURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public setTalentart(art: string): void {
    this.nav = art;
  }

  public getMaterial(): void {
    console.log('getMaterial()');
    this.dataService.getMaterial().subscribe((data: NameWert[]) => {
      this.material = data;
      console.log(data);
      if (data.length > 0) {
        this.active.copy(data[0]);
      }
    });
  }

  public changeMaterial(incremet: number): void {
    let newIndex: number = this.index + incremet;
    if (newIndex >= this.material.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = this.material.length - 1;
    }
    this.index = newIndex;
    this.active.copy(this.material[this.index]);
  }

  public getSchriftstueck(): void {
    console.log('getSchriftstuecke()');
    this.dataService.getSchriftstuecke().subscribe((data: Schriftstueck[]) => {
      this.schriften = data;
      console.log(data);
      if (data.length > 0) {
        this.selectSchriftstueck();
      }
      console.log(this.schriften);
    });
  }

  selectSchriftstueck(): void {
    this.selectedSchrift.copy(this.schriften[this.schriftIndex]);
    this.schriftName = this.selectedSchrift.name;
    this.schriftInhalt = this.selectedSchrift.inhalt;
    this.schriftSprache = this.selectedSchrift.sprache;
    this.schriftSchrift = this.selectedSchrift.schrift;
    this.schriftTextart = this.selectedSchrift.textart;
    this.getTextNachLevel();
  }

  getTextNachLevel(): void {
    this.angezeigterText = '';
    const level = this.getLevel();
    this.angezeigterText = this.readText(level);
  }

  getLevel(): number {
    const sprachSF: Sonderfertigkeit[] = this.charakter.getAlleSonderfertigkeitenMitName(this.selectedSchrift.sprache);
    let sprache: Sonderfertigkeit = null;
    if (sprachSF.length > 0) {
      sprache = sprachSF[0];
    }
    const schriftSF: Sonderfertigkeit[] = this.charakter.getAlleSonderfertigkeitenMitName(this.selectedSchrift.schrift);
    let schrift: Sonderfertigkeit = null;
    if (schriftSF.length > 0) {
      schrift = schriftSF[0];
    }
    let sprachLvl = 0;
    if (sprache != null) {
      sprachLvl = (sprache.stufe / 3.0) * 5;
    }
    let schriftLvL = 0;
    if (schrift != null) {
      schriftLvL = schrift.stufe;
    }
    const level = Math.min(sprachLvl, schriftLvL);
    console.log('level Sprache: ' + sprachLvl);
    console.log('level Schrift: ' + schriftLvL);
    console.log('level: ' + level);
    return level;
  }

  readText(level: number): string {
    let read = '';
    const wordForWord: string[] = this.selectedSchrift.inhalt.split(' ');
    wordForWord.forEach(word => {
      const newWord = this.readWord(word, level);
      console.log(word + ' -> ' + newWord);
      read += newWord + ' ';
    });
    return read;
  }

  readWord(word: string, level: number): string {
    let result = '';
    const letterArray: string[][] = [
      ['A', 'R', 'P'],
      ['Ä', 'A', 'R', 'P'],
      ['B', 'D', 'R', 'P', 'H', 'K'],
      ['C', 'G', 'D', 'L', 'J', 'E'],
      ['D', 'C', 'G', 'O', 'P', 'B'],
      ['E', 'F', 'C', 'L', 'P', 'B'],
      ['F', 'E', 'P', 'C', 'L', 'B'],
      ['G', 'C', 'O', 'D', 'Q', 'J'],
      ['H', 'X', 'B', 'R', 'K'],
      ['I', 'J', 'L', 'T'],
      ['J', 'I', 'C', 'T', 'G'],
      ['K', 'X', 'Y', 'R', 'H'],
      ['L', 'J', 'T', 'I'],
      ['M', 'N', 'W', 'A', 'V'],
      ['N', 'M', 'W', 'A', 'V'],
      ['O', 'Q', 'D', 'G', 'C'],
      ['Ö', 'O', 'Q', 'D', 'G', 'C'],
      ['P', 'F', 'R', 'D', 'B'],
      ['Q', 'O', 'G', 'D', 'C'],
      ['R', 'P', 'K', 'B', 'H'],
      ['S', 'Z', 'J', 'I'],
      ['T', 'I', 'Y', 'L', 'J'],
      ['U', 'V', 'W', 'J'],
      ['Ü', 'U', 'V', 'W', 'J'],
      ['V', 'W', 'U', 'N', 'M'],
      ['W', 'V', 'M', 'N', 'U'],
      ['X', 'K', 'Y', 'R', 'H'],
      ['Y', 'X', 'K', 'H', 'I'],
      ['Z', 'S', 'K', 'L', 'T'],
      ['a', 'e', 's', 'o'],
      ['ä', 'a', 'e', 's', 'o'],
      ['b', 'd', 'p', 'q', 'h'],
      ['c', 'e', 'o', 's'],
      ['d', 'b', 'q', 'p'],
      ['e', 'a', 's', 'o'],
      ['f', 't', 'l'],
      ['g', 'q'],
      ['h', 'b', 'k'],
      ['i', 'j'],
      ['j', 'i'],
      ['k', 'x', 'h'],
      ['l', 't', 'f'],
      ['m', 'n', 'r'],
      ['n', 'm', 'r'],
      ['o', 'a', 'e'],
      ['ö', 'o', 'a', 'e'],
      ['p', 'q', 'b', 'd'],
      ['q', 'd', 'p', 'b'],
      ['r', 'n', 'e', 'a'],
      ['s', 'z', 'r', 'e', 'a'],
      ['t', 'f', 'l'],
      ['u', 'n', 'v', 'w'],
      ['ü', 'u', 'n', 'v', 'w'],
      ['v', 'w', 'u', 'n'],
      ['w', 'v', 'u', 'n'],
      ['x', 'v', 'z', 's'],
      ['y', 'k', 'x'],
      ['z', 's', 'x']
    ];
    let letterBefore = ' ';
    let newLetterBefore = ' ';
    let newLetter = ' ';
    for (let i = 0; i < word.length; i++) {
      let found = false;
      const letter = word.charAt(i);
      for (let zeile = 0; zeile < letterArray.length; zeile++) {
        if (letterArray[zeile][0] === letter) {
          found = true;
          newLetter = this.getRandomLetter(letterArray[zeile], level);
          if (letterBefore === letter) {
            newLetter = newLetterBefore;
          } else {
            newLetterBefore = newLetter;
          }
        }
      }
      if (found) {
        result += newLetter;
      } else {
        result += letter;
      }
      letterBefore = letter;
    }
    return result;
  }

  getRandomLetter(array: string[], lvl: number): string {
    const lvlChance = Math.pow(1 - (lvl / 5.0), 2);
    const n = array.length;
    const chance = Math.random();
    let index = 0;
    if (lvlChance >= chance) {
      index = Math.floor(Math.random() * n);
      if (lvl >= 2) {
        index /= (lvl - 1);
        index = Math.round(index);
      }
    }
    return array[index];
  }
}
