<div class="body">
  <h2>Sonderfertigkeiten</h2>
  <div class="zeile" style="width: 100%; justify-content: space-around">
    <select [(ngModel)]="art" (ngModelChange)="aendereArt()">
      <option [value]="a" *ngFor="let a of arten">{{a}}</option>
    </select>
    <select [(ngModel)]="unterart" (ngModelChange)="aendereUnterart()">
      <option [value]="ua" *ngFor="let ua of unterarten">{{ua}}</option>
    </select>
    <select [(ngModel)]="name" (ngModelChange)="waehleSonderfertigkeit()">
      <option [value]="sf" *ngFor="let sf of namen">{{sf}}</option>
    </select>
  </div>
  <div class="zeile" style="width: 100%; justify-content: flex-start">
    <h3>Neue Sonderfertigkeit</h3>
    <div class="img">
      <img alt="plus" src="assets/images/button_s_pos.png" (click)="sendeSonderfertigkeitAnDB()"/>
    </div>
    <div class="img">
      <img alt="arrows" src="assets/images/reload.png" (click)="resetSonderfertigkeit()"/>
    </div>
  </div>
  <div class="zeile" style="width: 100%; justify-content: flex-start">
    <label>Klasse:</label>
    <select class="wideInput" [(ngModel)]="sonderfertigkeit.klasse">
      <option [value]="klasse" *ngFor="let klasse of klassen">{{klasse}}</option>
    </select>
  </div>
  <div class="zeile" style="width: 100%; justify-content: flex-start">
    <label>Art:</label>
    <input class="wideInput" type="text" [(ngModel)]="sonderfertigkeit.art">
    <label>Unterart:</label>
    <input class="wideInput" type="text" [(ngModel)]="sonderfertigkeit.unterArt">
    <label>Name:</label>
    <input class="wideInput" type="text" [(ngModel)]="sonderfertigkeit.name">
  </div>
  <div class="zeile">
    <label>Stufen:</label>
    <input type="number" [(ngModel)]="sonderfertigkeit.stufen">
    <label>Spezifikation nötig:</label>
    <input type="checkbox" [(ngModel)]="sonderfertigkeit.spezifikationNoetig">
  </div>

  <div class="zeile">
    <label>Beschreibung:</label>
    <textarea class="vielText" [(ngModel)]="sonderfertigkeit.beschreibung" (ngModelChange)="entferneUmbrueche()"></textarea>
  </div>
  <div class="zeile">
    <label>Regel:</label>
    <textarea class="vielText" [(ngModel)]="sonderfertigkeit.regel" (ngModelChange)="entferneUmbrueche()"></textarea>
  </div>
  <div class="zeile" *ngIf="sonderfertigkeit.klasse === 'GKampfSonderfertigkeit'">
    <label>Kampftechniken:</label>
    <div class="zeile" *ngFor="let kt of $any(sonderfertigkeit).kampffertigkeiten">
      {{kt}}
      <img class="roundButton" alt="minus" src="assets/images/button_s_neg.png" (click)="entferneKampftechnik(kt)"/>
    </div>
    <select [(ngModel)]="neueKampftechnik">
      <option *ngFor="let kt of kampftechniken">{{kt}}</option>
    </select>
    <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeKampftechnikHinzu()"/>
  </div>
  <div class="zeile" *ngIf="sonderfertigkeit.klasse === 'GKampfSonderfertigkeit'">
    <label>begrenzt auf Waffen:</label>
    <div class="zeile" *ngFor="let w of $any(sonderfertigkeit).begrenztAufWaffen">
      {{w}}
      <img class="roundButton" alt="minus" src="assets/images/button_s_neg.png" (click)="entferneWaffenbegrenzung(w)" l/>
    </div>
    <select [(ngModel)]="neueWaffenbegrenzung">
      <option *ngFor="let w of waffen">{{w}}</option>
    </select>
    <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeWaffenbegrenzungHinzu()"/>
  </div>
  <div class="zeile" *ngIf="sonderfertigkeit.klasse === 'GKampfSonderfertigkeit'">
    <label>freigeschaltete Sonderfertigkeiten:</label>
    <div class="zeile" *ngFor="let s of $any(sonderfertigkeit).freigeschalteteSonderfertigkeiten">
      {{s}}
      <img class="roundButton" alt="minus" src="assets/images/button_s_neg.png" (click)="entferneFreigeschalteteSF(s)" l/>
    </div>
    <input type="text" [(ngModel)]="neueFreigeschalteteSF">
    <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeFreigeschalteteSFHinzu()"/>
  </div>
  <h4>Voraussetzungen</h4>
  <div class="tabellenZeile" style="font-weight: bold; border: none">
    <div class="colStufe">Stufe</div>
    <div class="colHell">Kategorie</div>
    <div class="col">KatStufe</div>
    <div class="colHell">Vorausgesetzt</div>
    <div class="colStufe">V. Stufe</div>
    <div class="colHell">AP</div>
  </div>
  <div class="tabellenZeile" *ngFor="let voraussetzung of sonderfertigkeit.voraussetzungen">
    <input type="number" class="colStufe" [(ngModel)]="voraussetzung.stufe">
    <input type="text" class="colHell" [(ngModel)]="voraussetzung.kategorie">
    <input type="text" class="col" [(ngModel)]="voraussetzung.katStufe">
    <input type="text" class="colHell" [(ngModel)]="voraussetzung.voraussetzung">
    <input type="number" class="colStufe" [(ngModel)]="voraussetzung.voraussetzungStufe">
    <input type="number" class="colHell" [(ngModel)]="voraussetzung.kosten">
    <img alt="minus" src="assets/images/button_s_neg.png" (click)="entferneVoraussetzung(voraussetzung)"/>
  </div>
  <div class="tabellenZeile">
    <input type="number" class="colStufe" [(ngModel)]="neueVoraussetzung.stufe">
    <input type="text" class="colHell" [(ngModel)]="neueVoraussetzung.kategorie">
    <input type="text" class="col" [(ngModel)]="neueVoraussetzung.katStufe">
    <input type="text" class="colHell" [(ngModel)]="neueVoraussetzung.voraussetzung">
    <input type="number" class="colStufe" [(ngModel)]="neueVoraussetzung.voraussetzungStufe">
    <input type="number" class="colHell" [(ngModel)]="neueVoraussetzung.kosten">
    <img alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeVoraussetzungHinzu()"/>
  </div>

  <div *ngIf="sonderfertigkeit.klasse === 'GTraditionsartefaktSonderfertigkeit'" style="display: flex; flex-flow: column; justify-content: flex-start">
    <h4>Traditionsartefaktregeln</h4>
    <div class="tabellenZeile" style="font-weight: bold; border: none">
      <div class="colStufe">Stufe</div>
      <div class="colHell">Volumen</div>
      <div class="col">AsP-Kosten</div>
      <div class="colHell">Merkmal</div>
    </div>
    <div class="tabellenZeile" *ngFor="let trad of $any(sonderfertigkeit).traditionsartefakte">
      <input type="number" class="colStufe" [(ngModel)]="trad.stufe">
      <input type="number" class="colHell" [(ngModel)]="trad.volumen">
      <input type="text" class="col" [(ngModel)]="trad.aspKosten">
      <input type="text" class="colHell" [(ngModel)]="trad.merkmal">
      <img alt="minus" src="assets/images/button_s_neg.png" (click)="entferneTraditionsartefakt(trad)"/>
    </div>
    <div class="tabellenZeile">
      <input type="number" class="colStufe" [(ngModel)]="neuesTraditionsartefakt.stufe">
      <input type="number" class="colHell" [(ngModel)]="neuesTraditionsartefakt.volumen">
      <input type="text" class="col" [(ngModel)]="neuesTraditionsartefakt.aspKosten">
      <input type="text" class="colHell" [(ngModel)]="neuesTraditionsartefakt.merkmal">
      <img alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeTraditionsartefaktHinzu()"/>
    </div>
  </div>
</div>
