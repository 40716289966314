<div class="flexVertikal">
  <div class="hSpaceAround" id="armor">
    <div class="armorBox" *ngIf="charakter.geschlecht === 'weiblich'" style="background-image: url('./assets/images/armor_f.png')">
      <div class="armorZeile">
        <div class="armorWert">
          <div>Kopf</div>
          <div>{{rsKopf}}</div>
          <select class="wound" [(ngModel)]="wKopf" (ngModelChange)="changeWoundKopf()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
      </div>

      <div class="armorZeile">
        <div class="armorWert">
          <div>l. Arm</div>
          <div>{{rslArm}}</div>
          <select class="wound" [(ngModel)]="wlArm" (ngModelChange)="changeWoundLArm()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
        <div class="armorWert">
          <div>Torso</div>
          <div>{{rsTorso}}</div>
          <select class="wound" [(ngModel)]="wTorso" (ngModelChange)="changeWoundTorso()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
        <div class="armorWert">
          <div>r. Arm</div>
          <div>{{rsrArm}}</div>
          <select class="wound" [(ngModel)]="wrArm" (ngModelChange)="changeWoundRArm()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
      </div>
      <div class="armorZeile">
        <div class="armorWert">
          <div>l. Bein</div>
          <div>{{rslBein}}</div>
          <select class="wound" [(ngModel)]="wlBein" (ngModelChange)="changeWoundLBein()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
        <div class="armorWert">
          <div>r. Bein</div>
          <div>{{rsrBein}}</div>
          <select class="wound" [(ngModel)]="wrBein" (ngModelChange)="changeWoundRBein()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
      </div>
    </div>
    <div class="armorBox" *ngIf="charakter.geschlecht === 'männlich'" style="background-image: url('./assets/images/armor_m.png')">
      <div class="armorZeile">
        <div class="armorWert">
          <div>Kopf</div>
          <div>{{rsKopf}}</div>
          <select class="wound" [(ngModel)]="wKopf" (ngModelChange)="changeWoundKopf()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
      </div>
      <div class="armorZeile">
        <div class="armorWert">
          <div>l. Arm</div>
          <div>{{rslArm}}</div>
          <select class="wound" [(ngModel)]="wlArm" (ngModelChange)="changeWoundLArm()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
        <div class="armorWert">
          <div>Torso</div>
          <div>{{rsTorso}}</div>
          <select class="wound" [(ngModel)]="wTorso" (ngModelChange)="changeWoundTorso()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
        <div class="armorWert">
          <div>r. Arm</div>
          <div>{{rsrArm}}</div>
          <select class="wound" [(ngModel)]="wrArm" (ngModelChange)="changeWoundRArm()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
      </div>
      <div class="armorZeile">
        <div class="armorWert">
          <div>l. Bein</div>
          <div>{{rslBein}}</div>
          <select class="wound" [(ngModel)]="wlBein" (ngModelChange)="changeWoundLBein()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
        <div class="armorWert">
          <div>r. Bein</div>
          <div>{{rsrBein}}</div>
          <select class="wound" [(ngModel)]="wrBein" (ngModelChange)="changeWoundRBein()">
            <option [value]=0>-</option>
            <option [value]=-1>I</option>
            <option [value]=-2>II</option>
            <option [value]=-3>III</option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <h3>Kampf-Sonderfertigkeiten</h3>
      <h4>passiv</h4>
      <div *ngFor="let sf of sonderfertigkeiten_passiv" (click)="click_sf_info(sf)">{{sf.name}} {{romanLetters[sf.stufe]}}</div>
      <h4>Basis-Manöver</h4>
      <div *ngFor="let sf of sonderfertigkeiten_basis" (click)="click_sf_info(sf)">{{sf.name}} {{romanLetters[sf.stufe]}}</div>
      <h4>Spezial-Manöver</h4>
      <div *ngFor="let sf of sonderfertigkeiten_spezial" (click)="click_sf_info(sf)">{{sf.name}} {{romanLetters[sf.stufe]}}</div>
    </div>
  </div>
  <div class="hSpaceAround">
    <button (click)="ermittleRuestung()">Aktualisiere Rüstungsschutz</button>
    <button style="visibility: hidden">Aktualisiere Rüstungsschutz</button>
  </div>

  <div class="sf_info_box" *ngIf="sf_info.name !== ''">
    <h3 style="text-align: center">Sonderfertigkeit {{sf_info.name}} {{romanLetters[sf_info.stufe]}} {{sf_info.kampfsftyp}}</h3>
    <div><b>Kampftechniken: </b>{{sf_info.kampftechniken}}</div>
    <div><b>Regel: </b>{{sf_info.regel}}</div>
    <div style="color: darkred" *ngIf="sf_info.name === 'Verbessertes Ausweichen'">bereits berechnet!</div>
  </div>

  <h3 class="abschnittsUeberschrift">aktuell ausgerüstet</h3>
  <div class="spalten">
    <div class="spalte" *ngIf="!$any(this.localData.rechteHand.gegenstand).zweihand">
      <h3 class="abschnittsUeberschrift" *ngIf="!$any(this.localData.linkeHand.gegenstand).zweihand">linke Hand</h3>
      <h3 class="abschnittsUeberschrift" *ngIf="$any(this.localData.linkeHand.gegenstand).zweihand">beide Hände</h3>
      <div class="spalten">

        <div class="unterspalte">
          <h3 class="spaltenueberschrift">{{localData.linkeHand.ausruestung.name}}</h3>
          <div class="zeile">
            <div class="boxM">
              <div class="name">AT</div>
              <!--div class="wert">{{berechneAT(false)}}</div-->
              <div class="wert">{{atLinks}}</div>
            </div>
            <div class="boxM">
              <div class="name">PA</div>
              <!--div class="wert">{{berechnePA(false)}}</div-->
              <div class="wert">{{paLinks}}</div>
            </div>
            <div class="boxL">
              <div class="name">Waffenschaden</div>
              <!--div class="wert">{{berechneSchaden(localData.linkeHand, false)}}</div-->
              <div class="wert">{{tpPrefixLinks}}{{tpSuffixLinks}}</div>
            </div>
          </div>
          <div class="zeile" *ngIf="localData.linkeHand.gegenstand.type === 'Fernkampfwaffe'">
            <div class="boxL">
              <div class="name" *ngIf="localData.distanzL != null">Distanz: {{localData.distanzL.name}}</div>
              <select class="wert" [(ngModel)]="localData.distanzLIndex" (ngModelChange)="waehleDistanz(false)">
                <option [value]="schussdistanzenL.indexOf(distanz)" *ngFor="let distanz of schussdistanzenL">
                  {{distanz.entfernung}} Schritt
                </option>
              </select>
            </div>
            <div class="boxLZ">
              <div class="name">Ladezeit</div>
              <!--div class="wert">{{berechneAT(false)}}</div-->
              <div class="wert">{{ladezeitLinks}} Akt.</div>
            </div>
          </div>
          <!--div *ngIf="localData.linkeHand.gegenstand.type === 'Fernkampfwaffe'">
            <div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['AT', 'AT_Links', 'AT_FK', 'AT_' + localData.linkeHand.ausruestung.name])">
              {{mod.modName}}: {{mod.modWert}}
            </div>
          </div>
          <div *ngIf="localData.linkeHand.gegenstand.type !== 'Fernkampfwaffe'">
            <div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['AT', 'AT_Links', 'AT_NK', 'AT_' + localData.linkeHand.ausruestung.name])">
              {{mod.modName}}: {{mod.modWert}}
            </div>
          </div-->
          <!--div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['VE', 'PA', 'PA_Links', 'PA_' + localData.linkeHand.ausruestung.name])">
            {{mod.modName}}: {{mod.modWert}} ({{mod.modifiziertes}})
          </div-->
          <!--div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['TP', 'TP_Links', 'TP_' + localData.linkeHand.ausruestung.name])">
            {{mod.modName}}: {{mod.modWert}} ({{mod.modifiziertes}})
          </div-->
          <div class="kopfzeile">
            <div class="kampftechnik">Kampftechnik</div>
            <div class="leiteigenschaft">Leiteigenschaft</div>
          </div>
          <div class="zeile">
            <div class="kampftechnik">{{$any(localData.linkeHand.gegenstand).kampftechnik}}</div>

            <select class="leiteigenschaft" [(ngModel)]="localData.linkeHandLe" (ngModelChange)="ermittlePaUndTpDurchLE()" *ngIf="localData.linkeHand.gegenstand.type === 'Nahkampfwaffe' || localData.linkeHand.gegenstand.type === 'Parierwaffe'">
              <option *ngFor="let le of $any(localData.linkeHand.gegenstand).leiteigenschaft.split('/')">{{le}}</option>
            </select>
            <div class="leiteigenschaft" *ngIf="localData.linkeHand.gegenstand.type === 'Fernkampfwaffe'">FF</div>
          </div>

          <div class="vorteil" *ngIf="$any(localData.linkeHand.gegenstand).vorteil !== '' && $any(localData.linkeHand.gegenstand).vorteil != null">
            <b>Vorteil: </b>{{$any(localData.linkeHand.gegenstand).vorteil}}
          </div>
          <div class="nachteil" *ngIf="$any(localData.linkeHand.gegenstand).nachteil !== '' && $any(localData.linkeHand.gegenstand).nachteil != null">
            <b>Nachteil: </b>{{$any(localData.linkeHand.gegenstand).nachteil}}
          </div>
          <h3 class="zwischenUeberschrift">- Ausrüstung -</h3>
          <div class="zeile" *ngIf="ziebareWaffen.length && localData.linkeHand.gegenstand.name === 'Fäuste'">
            <!--select class="waffe" [(ngModel)]="ziehbarLinksIndex" (ngModelChange)="waehleWaffe(false)"-->
            <select class="waffe" [(ngModel)]="ziehbarLinksIndex">
              <option [value]="ziebareWaffen.indexOf(waffe)" *ngFor="let waffe of ziebareWaffen">{{waffe.ausruestung.name}}</option>
            </select>
            <div class="aktionen">
              <label *ngIf="waffenTrageorte.includes(ziehbarLinks.ausruestung.trageort)">
                <label *ngIf="!schnellziehen">2 Aktionen</label>
                <label *ngIf="schnellziehen">1 Aktion</label>
              </label>
              <label *ngIf="!waffenTrageorte.includes(ziehbarLinks.ausruestung.trageort)">
                <label *ngIf="!schnellziehen">1 Aktion</label>
                <label *ngIf="schnellziehen">1 freie Aktion</label>
              </label>
            </div>
            <div class="ziehen" (click)="zieheWaffe(false)">ziehen</div>
          </div>
          <div class="center" *ngIf="localData.linkeHand.gegenstand.name !== 'Fäuste'">
            <div class="wegstecken" (click)="wegstecken(false)">Waffe wegstecken</div>
          </div>
        </div>

        <div class="unterspalteSchmal" *ngIf="!$any(this.localData.linkeHand.gegenstand).zweihand">
          <div *ngIf="$any(localData.linkeHand.gegenstand).laenge >= 10" class="image" style="max-height: {{localData.linkeHand.gegenstand.laenge * 3}}px;background-image: url('assets/images/itemsAusruestung/{{localData.linkeHand.gegenstand.dateiname}}.png')">
          </div>
          <div *ngIf="$any(localData.linkeHand.gegenstand).laenge < 10" class="image" style="background-image: url('assets/images/itemsAusruestung/{{localData.linkeHand.gegenstand.dateiname}}.png')">
          </div>


        </div>
        <div class="unterspaltebreit" *ngIf="$any(this.localData.linkeHand.gegenstand).zweihand">
          <div class="image" style="height: {{localData.linkeHand.gegenstand.laenge * 2}}px;background-image: url('assets/images/itemsAusruestung/{{localData.linkeHand.gegenstand.dateiname}}.png')"></div>
          <!--div *ngIf="localData.linkeHand.gegenstand.type === 'Fernkampfwaffe'">
            <h3>Munition</h3>
            <div *ngFor="let munition of munitionLinks">{{munition.ausruestung.name}}</div>
          </div-->
        </div>

      </div>


    </div>

    <div class="spalte" *ngIf="!$any(this.localData.linkeHand.gegenstand).zweihand">
      <h3 class="abschnittsUeberschrift" *ngIf="!$any(this.localData.rechteHand.gegenstand).zweihand">rechte Hand</h3>
      <h3 class="abschnittsUeberschrift" *ngIf="$any(this.localData.rechteHand.gegenstand).zweihand">beide Hände</h3>
      <div class="spalten">

        <div class="unterspalteSchmal" *ngIf="!$any(this.localData.rechteHand.gegenstand).zweihand">
          <div *ngIf="$any(localData.rechteHand.gegenstand).laenge >= 10" class="image" style="max-height: {{localData.rechteHand.gegenstand.laenge * 3}}px;background-image: url('assets/images/itemsAusruestung/{{localData.rechteHand.gegenstand.dateiname}}.png')">
          </div>
          <div *ngIf="$any(localData.rechteHand.gegenstand).laenge < 10" class="image" style="background-image: url('assets/images/itemsAusruestung/{{localData.rechteHand.gegenstand.dateiname}}.png')">
          </div>
        </div>
        <div class="unterspaltebreit" *ngIf="$any(this.localData.rechteHand.gegenstand).zweihand">
          <div class="image" style="height: {{localData.rechteHand.gegenstand.laenge * 2}}px;background-image: url('assets/images/itemsAusruestung/{{localData.rechteHand.gegenstand.dateiname}}.png')">
          </div>
        </div>

        <div class="unterspalte">
          <h3 class="spaltenueberschrift">{{localData.rechteHand.ausruestung.name}}</h3>
          <div class="zeile">
            <div class="boxM">
              <div class="name">AT</div>
              <!--div class="wert">{{berechneAT(true)}}</div-->
              <div class="wert">{{atRechts}}</div>
            </div>
            <div class="boxM">
              <div class="name">PA</div>
              <!--div class="wert">{{berechnePA(true)}}</div-->
              <div class="wert">{{paRechts}}</div>
            </div>
            <div class="boxL">
              <div class="name">Waffenschaden</div>
              <!--div class="wert">{{berechneSchaden(localData.rechteHand, true)}}</div-->
              <div class="wert">{{tpPrefixRechts}}{{tpSuffixRechts}}</div>
            </div>
          </div>
          <div class="zeile" *ngIf="localData.rechteHand.gegenstand.type === 'Fernkampfwaffe'">
            <div class="boxL">
              <div class="name" *ngIf="localData.distanzR != null">Distanz: {{localData.distanzR.name}}</div>
              <select class="wert" [(ngModel)]="localData.distanzRIndex" (ngModelChange)="waehleDistanz(true)">
                <option [value]="schussdistanzenR.indexOf(distanz)" *ngFor="let distanz of schussdistanzenR">
                  {{distanz.entfernung}} Schritt
                </option>
              </select>
            </div>
            <div class="boxLZ">
              <div class="name">Ladezeit</div>
              <!--div class="wert">{{berechneAT(false)}}</div-->
              <div class="wert">{{ladezeitRechts}} Akt.</div>
            </div>
          </div>
          <!--div *ngIf="localData.rechteHand.gegenstand.type === 'Fernkampfwaffe'">
            <div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['AT', 'AT_Rechts', 'AT_FK', 'AT_' + localData.rechteHand.ausruestung.name])">
              {{mod.modName}}: {{mod.modWert}}
            </div>
          </div>
          <div *ngIf="localData.rechteHand.gegenstand.type !== 'Fernkampfwaffe'">
            <div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['AT', 'AT_Rechts', 'AT_NK', 'AT_' + localData.rechteHand.ausruestung.name])">
              {{mod.modName}}: {{mod.modWert}}
            </div>
          </div-->
          <!--div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['PA', 'PA_Rechts', 'PA_' + localData.rechteHand.ausruestung.name])">
            {{mod.modName}}: {{mod.modWert}}
          </div-->
          <!--div *ngFor="let mod of charakter.getAlleBeeinflWerteMulti(['TP', 'TP_Rechts', 'TP_' + localData.rechteHand.ausruestung.name])">
            {{mod.modName}}: {{mod.modWert}}
          </div-->
          <div class="kopfzeile">
            <div class="kampftechnik">Kampftechnik</div>
            <div class="leiteigenschaft">Leiteigenschaft</div>
          </div>
          <div class="zeile">
            <div class="kampftechnik">{{$any(localData.rechteHand.gegenstand).kampftechnik}}</div>

            <select class="leiteigenschaft" [(ngModel)]="localData.rechteHandLe" (ngModelChange)="ermittlePaUndTpDurchLE()" *ngIf="localData.rechteHand.gegenstand.type === 'Nahkampfwaffe' || localData.rechteHand.gegenstand.type === 'Parierwaffe'">
              <option *ngFor="let le of $any(localData.rechteHand.gegenstand).leiteigenschaft.split('/')">{{le}}</option>
            </select>
            <div class="leiteigenschaft" *ngIf="localData.rechteHand.gegenstand.type === 'Fernkampfwaffe'">FF</div>
          </div>

          <div class="vorteil" *ngIf="$any(localData.rechteHand.gegenstand).vorteil !== '' && $any(localData.rechteHand.gegenstand).vorteil != null">
            <b>Vorteil: </b>{{$any(localData.rechteHand.gegenstand).vorteil}}
          </div>
          <div class="nachteil" *ngIf="$any(localData.rechteHand.gegenstand).nachteil !== '' && $any(localData.rechteHand.gegenstand).nachteil != null">
            <b>Nachteil: </b>{{$any(localData.rechteHand.gegenstand).nachteil}}
          </div>

          <h3 class="zwischenUeberschrift">- Ausrüstung -</h3>
          <div class="zeile" *ngIf="ziebareWaffen.length && localData.rechteHand.gegenstand.name === 'Fäuste'">
            <!--select class="waffe" [(ngModel)]="ziehbarLinksIndex" (ngModelChange)="waehleWaffe(false)"-->
            <select class="waffe" [(ngModel)]="ziehbarRechtsIndex">
              <option [value]="ziebareWaffen.indexOf(waffe)" *ngFor="let waffe of ziebareWaffen">{{waffe.ausruestung.name}}</option>
            </select>
            <div class="aktionen">
              <label *ngIf="waffenTrageorte.includes(ziehbarRechts.ausruestung.trageort)">
                <label *ngIf="!schnellziehen">2 Aktionen</label>
                <label *ngIf="schnellziehen">1 Aktion</label>
              </label>
              <label *ngIf="!waffenTrageorte.includes(ziehbarRechts.ausruestung.trageort)">
                <label *ngIf="!schnellziehen">1 Aktion</label>
                <label *ngIf="schnellziehen">1 freie Aktion</label>
              </label>
            </div>
            <div class="ziehen" (click)="zieheWaffe(true)">ziehen</div>
          </div>
          <div class="center" *ngIf="localData.rechteHand.gegenstand.name !== 'Fäuste'">
            <div class="wegstecken" (click)="wegstecken(true)">Waffe wegstecken</div>
          </div>

        </div>



      </div>
    </div>
  </div>
  <h3 class="abschnittsUeberschrift">Kampfeinstellungen</h3>
  <div class="spalten">
    <div class="breitespaltePlus">
      <div class="zeile" *ngIf="localData.sicht != null">
        <div class="einstellung">Sichtbehinderungen:</div>
        <select class="modifikator" [(ngModel)]="localData.sichtIndex" (ngModelChange)="waehleSicht()">
          <option [value]="kampfdaten.sichtModifikatoren.indexOf(sicht)" *ngFor="let sicht of kampfdaten.sichtModifikatoren">{{sicht.name}}</option>
        </select>
        <div class="beispiel">{{localData.sicht.beispiel}}</div>
      </div>
      <div class="zeile" *ngIf="localData.zielgroesse != null">
        <div class="einstellung">Größe des Ziels:</div>
        <select class="modifikator" [(ngModel)]="localData.zielgroesseIndex" (ngModelChange)="waehleZielgroesse()">
          <option [value]="kampfdaten.zielGroesseModifikatoren.indexOf(zielgroesse)" *ngFor="let zielgroesse of kampfdaten.zielGroesseModifikatoren">{{zielgroesse.name}}</option>
        </select>
        <div class="beispiel">{{localData.zielgroesse.beispiel}}</div>
      </div>
    </div>
  </div>
  <div class="spalten">
    <div class="breitespalte">
      <h3 class="abschnittsUeberschrift">Fernkampf</h3>

      <div class="zeile">
        <div class="einstellung">Bewegung des Ziels:</div>
        <select class="modifikator" [(ngModel)]="localData.bewegung_ziel" (ngModelChange)="waehleZielBewegung()">
          <option [value]="2">steht still</option>
          <option [value]="0">bewegt sich leicht</option>
          <option [value]="-2">bewegt sich schnell</option>
          <option [value]="-2">befindet sich im Nahkampf</option>
          <option [value]="-4">schlägt Haken</option>
        </select>
      </div>
      <div class="zeile">
        <div class="einstellung">Eigene Bewegung:</div>
        <select class="modifikator" [(ngModel)]="localData.bewegung_schuetze" (ngModelChange)="waehleEigeneBewegung()">
          <option [value]="0">Ich stehe still</option>
          <option [value]="-2">Ich gehe</option>
          <option [value]="-4">ich renne</option>
        </select>
      </div>
    </div>
    <div class="breitespalte">
      <h3 class="abschnittsUeberschrift">Nahkampf</h3>
      <div class="zeile">
        <div class="einstellung">Waffenlänge des Gegners:</div>
        <select class="modifikator" [(ngModel)]="localData.waffenlaenge" (ngModelChange)="waehleWaffenlaengeGegner()">
          <option value="kurz">kurz</option>
          <option value="mittel">mittel</option>
          <option value="lang">lang</option>
        </select>
      </div>
      <div class="zeile">
        <div class="einstellung">Beengte Umgebung:</div>
        <select class="modifikator" [(ngModel)]="localData.umgebung" (ngModelChange)="waehleUmgebung()">
          <option [value]="0">nein</option>
          <option [value]="1">ja</option>
        </select>
      </div>
    </div>
  </div>
</div>

