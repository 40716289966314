import {Waffe} from './waffe';

export class Nahkampfwaffe  extends Waffe {
  leiteigenschaft: string;
  schadensschwelle: number;
  atMod: number;
  paMod: number;
  reichweite: string;

  public copy(other: Nahkampfwaffe): void {
    console.log('Nahkampfwaffe.copy()');
    console.log(other);
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
    this.beschreibung = other.beschreibung;
    this.zitat = other.zitat;
    this.preis = other.preis;
    this.gewicht = other.gewicht;
    this.komplexitaet = other.komplexitaet;
    this.anzahl = other.anzahl;
    this.kampftechnik = other.kampftechnik;
    this.vorteil = other.vorteil;
    this.nachteil = other.nachteil;
    this.tp = other.tp;
    this.laenge = other.laenge;
    this.improvisiert = other.improvisiert;
    this.zweihand = other.zweihand;
    this.beschaedigung = other.beschaedigung;
    this.leiteigenschaft = other.leiteigenschaft;
    this.schadensschwelle = other.schadensschwelle;
    this.atMod = other.atMod;
    this.paMod = other.paMod;
    this.reichweite = other.reichweite;
  }
}
