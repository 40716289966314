import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SteigerungstabelleService {

  public tabelle: number[][];

  constructor() {
    this.tabelle = [];
    for (let i = 0; i < 26; i++) {
      const row: number[] = [];
      for (let j = 0; j < 5; j++) {
        row.push(0);
      }
      this.tabelle.push(row);
    }

    let wert = 1;
    for (let i = 0; i < 26; i++) {
      if (i > 12) {
        wert++;
      }
      for (let j = 0; j < 4; j++) {
        this.tabelle[i][j] = wert * (j + 1);
      }
    }

    wert = 1;
    this.tabelle[0][4] = 0;
    for (let i = 1; i < 26; i++) {
      if (i > 14) {
        wert++;
      }
      this.tabelle[i][4] = wert * 15;
    }
  }

  public printTable(): void {
    for (let i = 0; i < 26; i++) {
      let row = '';
      for (let j = 0; j < 5; j++) {
        row += this.tabelle[i][j] + ' ';
      }
      console.log(row);
    }
  }

  /**
   *
   * @param wert: neuer Wert
   * @param spalte: 0(A) 1(B) 2(C) 3(D) 4(E)
   */
  public getKosten(wert: number, spalte: number): number {
    return this.tabelle[wert][spalte];
  }
}
