import {Component, OnDestroy, OnInit} from '@angular/core';
import {WebsocketService} from '../../../_services/websocket.service';
import {delay, max, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
// import {Kaempferin} from '../../../_interfaces/kaempferin';
import {Profession} from '../../../_interfaces/profession';
import {KampfdatenService} from '../../../_services/kampfdaten.service';
import {ModifikatorenService} from '../../../_services/modifikatoren.service';
import {Kampftechnik} from '../../../_interfaces/kampftechnik';
import {DataService} from '../../../_services/data.service';
import {ObjectFactoryService} from '../../../_services/object-factory.service';
// import {Waffe} from '../../../_interfaces/waffe';
import {NameWertNumber} from '../../../_interfaces/name-wert-number';
import {Waffe} from '../../../_classes/kampf/waffe';
import {Kaempferin} from '../../../_classes/kampf/kaempferin';

@Component({
  selector: 'app-arena',
  templateUrl: './arena.component.html',
  styleUrls: ['./arena.component.sass']
})
export class ArenaComponent implements OnInit, OnDestroy {
  messages: string[] = [];
  phasen: string[] = [];
  kaempfer: Kaempferin[];
  alleKaempfer: Kaempferin[];
  kaempferInfo: string;

  /** Neue Kämpferin */
  neueKaempferin = new Kaempferin();
  zielTypen: string[] = [];
  zielGroessen: string[] = [];
  zielZonen: string[] = [];
  waffeIndexL = 0;
  waffeIndexR = 0;
  waffeTpR = '';
  waffeTpL = '';

  alleKampftechniken: Kampftechnik[] = [];
  alleWaffen: Waffe[] = [];

  kaempferTrefferzone = new Map();
  mapVerteidigerAngreifer = new Map();

  verteidigungsClass: string[];

  destroyed$ = new Subject();
  kampfrunde = 0;

  aktuell: string[];
  angreifer: string;
  verteidiger: string;

  schadenErhalter: string;
  angreiferIstNpc = false;
  trefferpunkte = 0;
  atpaMod = 0;
  sp = 0;
  angreiferWaffeTp = '';
  angreiferWurfString = '';

  atL = 0;
  atR = 0;

  veL = 0;
  veR = 0;
  veAw = 0;

  fkReichweiten: string[] = [];
  fkRwWert = [-2, 0, 2];

  bewegZielIndex = 0;
  bewegSchuetzeIndex = 0;
  fkRwErschwernis = 0;


  public kampfPhase = 'keine';
  public anDerReihe = 'Niemand';
  public anDerReiheKaempferin: Kaempferin;
  public gegnerInReichweite: Kaempferin[] = [];
  public gewaehlterGegner: Kaempferin;
  public gewaehlteWaffe: Waffe;

  public kritFlag = 0;
  public veErschw = 0;
  public veDivisor = 1;
  public tpMultiplier = 1;
  public shownMsg = '';
  public geschafft = false;
  public angreiferKaempfer: Kaempferin;
  public verteidigerKaempfer: Kaempferin;
  public kampftechnik: string;
  public kampftechnikIsNahkampf = true;

  public messageParts: string[] = [];

  constructor(private chatService: WebsocketService,
              public kampfservice: KampfdatenService,
              public mods: ModifikatorenService,
              public dataService: DataService,
              public factory: ObjectFactoryService) {
    this.kaempfer = [];
    this.verteidigungsClass = [];
    this.aktuell = [];
    this.angreifer = '';
    this.verteidiger = '';

    this.resetNeueKaempferin();
    this.zielTypen = kampfservice.getAlleZielTypen();
    this.getGroessenOfZieltype();
    this.dataService.getAlleKampftechniken().subscribe((data: Kampftechnik[]) => {
      this.alleKampftechniken = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.dataService.getAlleKaempferin().subscribe((data: Kaempferin[]) => {
      this.alleKaempfer = [];
      data.forEach(d => {
        const k: Kaempferin = new Kaempferin();
        k.copy(d);
        this.alleKaempfer.push(k);
      });
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.dataService.getAlleWaffen().subscribe((data: Waffe[]) => {
      this.alleWaffen = [];
      let index = 0;
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.forEach(w => {
        const waffe = new Waffe();
        waffe.copy(w);
        this.alleWaffen.push(waffe);
        if (w.name === 'Fäuste') {
          console.log(w);
          this.waffeIndexR = index;
          this.waffeIndexL = index;
        }
        index ++;
      });
      this.getWaffeOfNeueKaempferin(true);
      this.getWaffeOfNeueKaempferin(false);
      this.berechneWerteVonNeueKaempferin();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  ngOnInit(): void {
    const $username = sessionStorage.getItem('user');
    const chatSub$ = this.chatService.connect($username, 'kampf').pipe(
      takeUntil(this.destroyed$),
    );

    chatSub$.subscribe((data: string) => {
      const message = JSON.stringify(data);
      if (message.startsWith('\"[')) {
        const list: Kaempferin[] = JSON.parse(data);
        this.kaempfer = [];
        list.forEach(k => {
          const kaempferin = new Kaempferin();
          kaempferin.copy(k);
          this.kaempfer.push(kaempferin);
        });

        // console.log(this.kaempfer);
        this.getWsUpdate();
        if (this.kaempfer.length === 0) {
          // console.log('erstelleKampf');
          this.erstelleKampf();
        }
        this.setAnDerReihe();
      } else {
        this.messages.push(message);
        if (message.startsWith('\"Zone')){
          // console.log(message);
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');
          const verteidiger: Kaempferin = this.getKaempferWithName(splitted[1]);
          const zone = this.kampfservice.getZone(Number(splitted[2]), verteidiger.zielName, verteidiger.zielGroesse);
          this.checkRS(verteidiger);
          const rs = verteidiger.rs[verteidiger.rsZonen.indexOf(zone)];
          // console.log(splitted[2]);
          // console.log(Number(splitted[2]), verteidiger.zielName, verteidiger.zielGroesse);

          this.aktuell.push('🎯 Trefferzone bei ' + verteidiger.name + ': ' + zone + '. Rüstung dort: ' + rs + '.');
          this.kaempferTrefferzone.set(verteidiger.name, zone);

        } else if (message.startsWith('\"logAT')){
          // console.log(message);
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');
          const angreifer = splitted[1];
          const verteidiger = splitted[3];
          const waffenTp = splitted[7];
          /** speichere dem Verteidiger zugeordneten Angreifer */
          this.mapVerteidigerAngreifer.set(verteidiger, angreifer + '#' + waffenTp);

          const kt = splitted[4];
          const paErsch = Number(splitted[5]);
          const kritFlag = Number(splitted[6]);
          let kritString = '';
          if (kritFlag !== 0) {
            kritString = ' mit einem kritischen Treffer';
          }
          let paString = '';
          if (paErsch > 0) {
            paString = ' Die Verteidigung ist um ' + paErsch + ' erschwert!';
          }
          this.aktuell.push('⚔️' + angreifer + ' greift ' + verteidiger + kritString + ' mit der Kampftechnik "' + kt + '" an.' + paString);

        } else if (message.startsWith('\"ATEnde')){
          // console.log(message);
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');
          const angreifer = splitted[1];
          this.decementAkt(this.getKaempferWithName(angreifer));
          this.aktuell.push('❌ Die Attacke von ' + angreifer + ' endet.');
          this.changeKampfPhase('warteAufVe');

        } else if (message.startsWith('\"PatzerAT')){
          // console.log(message);
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');
          const angreifer = splitted[1];
          const bereich = Number(splitted[2]);
          const patzerString = this.kampfservice.getNahkampfPatzer(this.getKaempferWithName(angreifer), true, bereich);
          this.aktuell.push('💀 AT-Patzer von ' + angreifer + '! ' + patzerString);

        } else if (message.startsWith('\"PatzerVE')){
          // console.log(message);
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');
          const verteidiger = splitted[1];
          const bereich = Number(splitted[2]);
          const patzerString = this.kampfservice.getNahkampfPatzer(this.getKaempferWithName(verteidiger), true, bereich);
          this.aktuell.push('💀 VE-Patzer von ' + verteidiger + '! ' + patzerString);

        } else if (message.startsWith('\"VEEnde')){
          // console.log(message);
          this.changeKampfPhase('');
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');
          const verteidiger = splitted[1];
          const erfolg = Number(splitted[2]);
          this.incrementVerteidigungOf(verteidiger);
          let veString = '❌ Die Verteidigung von ' + verteidiger + ' endet ';
          if (erfolg === 1) {
            veString += 'erfolgreich!';
          } else {
            veString += 'nicht erfolgreich!';
          }
          this.aktuell.push(veString);
          if (erfolg === 0) {
            this.tpErhalten(verteidiger);
          }
          this.setAnDerReihe();

        } else if (message.startsWith('\"PassierZone')){
          // console.log(message);
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');

          const verteidiger = splitted[1];
          const angreifer: Kaempferin = this.getKaempferWithName(splitted[2]);
          const zone = this.kampfservice.getZone(Number(splitted[3]), angreifer.zielName, angreifer.zielGroesse);

          this.aktuell.push('🛡️⚔️' + verteidiger + ' führt einen Passierschlag gegen ' + angreifer.name + ' aus!');
          this.checkRS(angreifer);
          const rs = angreifer.rs[angreifer.rsZonen.indexOf(zone)];
          this.aktuell.push('🎯 Trefferzone bei ' + angreifer.name + ': ' + zone + '. Rüstung dort: ' + rs + '.');
          this.kaempferTrefferzone.set(angreifer.name, zone);
          this.tpErhalten(angreifer.name);

        } else if (message.startsWith('\"AT#')) {
          this.kampftechnikIsNahkampf = true;
          const msg = message.substr(1, message.length - 2);
          const splitted: string[] = msg.split('#');
          this.angreiferKaempfer = this.getKaempferWithName(splitted[1]);
          this.verteidigerKaempfer = this.getKaempferWithName(splitted[3]);
          this.kampftechnik = splitted[4];
          this.kampftechnikIsNahkampf = this.isKampftechnikNahkampf(this.kampftechnik);
          this.veErschw = Number(splitted[5]);
          if (Number(splitted[6]) === 1) {
            this.veDivisor = 2;
          }
          this.berechneVeWerte();
          this.changeKampfPhase('veWahl');

        }
      }

      /*
      const msg = message.substr(1, message.length - 2);
      const splitted: string[] = msg.split('#');
      const angreifer = splitted[1];
      const verteidiger = splitted[3];
      const kt = splitted[4];
      const paErsch = Number(splitted[5]);
      const kritFlagPA = Number(splitted[6]);
      let kritString = '';
      if (kritFlagPA === 1) {
        kritString = ' (kritisch)';
      }
      let veDivisorPA = 1;
      if (Number(splitted[6]) === 1) {
        veDivisorPA = 2;
      }
      console.log('AT von ' + angreifer + kritString + ' gegen ' + verteidiger + ' mit KT ' + kt + '. VE erschwert um '
      + paErsch + ' und geteilt durch ' + veDivisorPA);
    }
    */
    });

  }

  public isKampftechnikNahkampf(name: string): boolean {
    let isNahkampf = false;
    this.alleKampftechniken.forEach(kt => {
      if (name === kt.name) {
        isNahkampf = kt.nahkampf;
      }
    });
    return isNahkampf;
  }

  public tpErhalten(verteidigerName: string): void {
    const verteidiger = this.getKaempferWithName(verteidigerName);
    const angreiferUndWaffe: string[] = this.mapVerteidigerAngreifer.get(verteidigerName).split('#');
    const angreiferName = angreiferUndWaffe[0];
    this.angreiferWaffeTp = angreiferUndWaffe[1];
    const angreifer = this.getKaempferWithName(angreiferName);
    /** #Depr# untescheide ob Verteidiger Npc oder Spieler ist #Depr# */
    this.schadenErhalter = verteidigerName;
    if (angreifer.npc) {
      this.angreiferIstNpc = true;
    } else {
      this.angreiferIstNpc = false;
    }
    this.changeKampfPhase('tpErhalten');
  }

  getAtUndPa(kaempfer: Kaempferin, waffe: Waffe): void {
    waffe.at = this.mods.getAt(kaempfer, waffe);
    waffe.pa = this.mods.getPa(kaempfer, waffe);
  }

  getWsUpdate(): void {
    this.kaempfer.forEach(k => {
      k.tpWaffeR = this.getTP(k.rechteHand);
      k.tpWaffeL = this.getTP(k.linkeHand);
    });
    this.kaempfer.sort((a, b) => (a.basisIni > b.basisIni ? -1 : 1));
    this.kaempfer.sort((a, b) => (a.ini > b.ini ? -1 : 1));
    this.resetVerteidungungsClass();
  }

  public resetVerteidungungsClass(): void {
    if (this.verteidigungsClass.length !== this.kaempfer.length) {
      this.verteidigungsClass = [];
      this.kaempfer.forEach(k => {
        this.verteidigungsClass.push('veBish');
      });
    }
  }

  getTP(waffe: Waffe): string {
    console.log('getTP for ' + waffe.name);
    let tp = '';
    let tpArray: string[];
    if (waffe.tp.includes('+')) {
      tpArray = waffe.tp.split('+');
    } else {
      tpArray = waffe.tp.split('-');
    }
    console.log(tpArray);
    const tpAnfang = tpArray[0];
    let tpEnde = 0;
    if (tpArray.length > 1) {
      tpEnde = Number(tpArray[1]);
    }

    if (waffe.tp.includes('-')) {
      tpEnde = (tpEnde * -1 );
    }
    console.log('tpEnde: ' + tpEnde);
    // console.log(waffe);
    // console.log(waffe.schandensb);

    tpEnde = Number(tpEnde) + Number(waffe.schandensb);
    // console.log(tpEnde);

    tp = tpAnfang;
    if (tpEnde < 0) {
      tp += Number(tpEnde);
    } else if (tpEnde > 0) {
      tp += '+' + tpEnde;
    }
    return tp;
  }

  erstelleKampf(): void {
    this.chatService.send('createFight');
  }

  neueKampfrunde(): void {
    this.kampfrunde ++;
    this.chatService.send('neueKR');
    this.kampfPhase = '';
    this.aktuell = [];
    this.setAnDerReihe();
  }

  resetKampf(): void {
    this.chatService.send('resetArena');
    this.kampfrunde = 0;
    this.aktuell = [];
  }

  decementAkt(kaempferin: Kaempferin): void {
    kaempferin.aktionenUebrig--;
    this.updateKaempferin(kaempferin);
  }

  incrementAkt(kaempferin: Kaempferin): void {
    kaempferin.aktionenUebrig++;
    this.updateKaempferin(kaempferin);
  }

  decementIni(kaempferin: Kaempferin): void {
    kaempferin.ini--;
    this.updateKaempferin(kaempferin);
  }

  incrementIni(kaempferin: Kaempferin): void {
    kaempferin.ini++;
    this.updateKaempferin(kaempferin);
  }

  decementLep(kaempferin: Kaempferin): void {
    kaempferin.lep--;
    this.updateKaempferin(kaempferin);
  }

  incrementLep(kaempferin: Kaempferin): void {
    kaempferin.lep++;
    this.updateKaempferin(kaempferin);
  }

  decementFAkt(kaempferin: Kaempferin): void {
    kaempferin.freieAktionenUebrig--;
    this.updateKaempferin(kaempferin);
  }

  incrementVerteidigungOf(name: string): void {
    const verteidigerin: Kaempferin = this.getKaempferWithName(name);
    verteidigerin.verteidigungenBisher++;
    this.updateKaempferin(verteidigerin);
  }

  incrementVE(kaempferin: Kaempferin, id: number, wert: number): void {
    if (kaempferin.npc) {
      wert -= 3 * kaempferin.verteidigungenBisher;
      if (wert === 0) {
        wert = Math.max(kaempferin.aw, kaempferin.rechteHand.pa, kaempferin.linkeHand.pa);
      }
      let geschafft = false;
      // const wurf = this.getRandomInt(20) - -1;
      const wurf = 20;

      let msg: string = '🛡️' + kaempferin.name + ' würfelt eine ' + wurf + ' [' + wert + ']. ';
      let msg2: string;
      msg2 = '';
      if (wurf === 20) {
        msg += ' Patzer!';
        const bestaetigung = this.getRandomInt(20) - -1;
        msg += ' Bestätigungswurf: ' + bestaetigung + '.';
        if (bestaetigung > wert) {
          msg += ' Bestätigt!';
          let patzerwurf = 0;
          patzerwurf -= -(this.getRandomInt(6) - -1);
          patzerwurf -= -(this.getRandomInt(6) - -1);
          msg += '\n' + this.kampfservice.getNahkampfPatzer(kaempferin, true, patzerwurf);
        } else {
          msg += ' Nicht Bestätigt!';
        }
      } else if (wurf === 1) {
        const bestaetigung = this.getRandomInt(20) - -1;
        msg += ' Bestätigungswurf: ' + bestaetigung + '.';
        if (bestaetigung <= wert) {
          msg += ' Bestätigt!';
          msg += ' Kritischer Erfolg! Es ist ein sofortiger Passierschlag';
          if (this.angreifer.length > 0) {
            msg += ' gegen ' + this.angreifer;
          }
          msg += ' erlaubt!';
          msg2 += '⚔️' + kaempferin.name + ' führt einen Passiertschlag';
          if (this.angreifer.length > 0) {
            msg2 += ' gegen ' + this.angreifer;
          }
          const pwurf = this.getRandomInt(20) - -1;
          let rechteHand = true;
          if (kaempferin.linkeHand.at > kaempferin.rechteHand.at) {
            rechteHand = false;
          }
          const atWert = Math.max(kaempferin.rechteHand.at, kaempferin.linkeHand.at) - 4;
          msg2 += ' aus und würfelt eine ' + pwurf + ' [' + atWert + '].';
          if (pwurf <= atWert) {
            let tpString = kaempferin.tpWaffeR;
            if (!rechteHand) {
              tpString = kaempferin.tpWaffeL;
            }

            // tpString auflösen
            const parts1: string[] = tpString.split('W');
            let anzW = 1;
            if (parts1[0].length > 0) {
              anzW = Number(parts1[0]);
            }
            let modifikator = 0;
            let w = 6;
            if (parts1[1].includes('+')) {
              const parts2 = parts1[1].split('+');
              modifikator = Number(parts2[1]);
              w = Number(parts2[0]);
            } else if (parts1[1].includes('-')) {
              const parts2 = parts1[1].split('-');
              modifikator = -Number(parts2[1]);
              w = Number(parts2[0]);
            } else {
              w = Number(parts1[1]);
            }
            msg2 += ' Ermittlung der TP (' + anzW + 'W' + w;
            if (modifikator > 0) {
              msg2 += '+';
            }
            msg2 += modifikator + '): ';
            let summeTP = 0;
            msg2 += ' [';
            for (let i = 0; i < anzW; i++) {
              const tpWurf = this.getRandomInt(w) - -1;
              if (i > 0) {
                msg2 += ', ';
              }
              msg2 += tpWurf;
              summeTP += tpWurf;

            }
            msg2 += ']';
            summeTP += modifikator;

            msg2 += ' Der Passierschlag gelingt und erzeugt ' + summeTP + ' TP!';
          }
        } else {
          msg += ' Nicht Bestätigt!';
        }

      } else {
        if (wurf <= (wert)) {
          geschafft = true;
          msg += ' Geschafft!';
        } else {
          msg += ' Nicht geschafft!';
        }
      }
      this.aktuell.push(msg);
      if (msg2.length > 0) {
        this.aktuell.push(msg2);
      }

      this.showPopup(id, geschafft);
    }
    kaempferin.verteidigungenBisher++;
    this.updateKaempferin(kaempferin);
  }

  changeGruppe(kaempferin: Kaempferin): void {
    this.chatService.send('ändereGruppe#' + kaempferin.gruppe + '#' + kaempferin.name + '#' + kaempferin.spieler);
    this.getGegnerInReichweite();
  }

  updateKaempferin(kaempferin: Kaempferin): void {
    console.log(kaempferin);
    this.chatService.send(kaempferin);
    this.setAnDerReihe();
  }

  removeKaempferin(kaempferin: Kaempferin): void {
    this.messageParts.push('removeKaempferin');
    this.messageParts.push(kaempferin.name);
    this.messageParts.push(kaempferin.spieler);
    this.sendMessage();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  showPopup(id: number, positiv: boolean): void {
    if (positiv) {
      this.verteidigungsClass[id] = 'veBishPos';
    } else {
      this.verteidigungsClass[id] = 'veBishNeg';
    }

    setTimeout(() => {
      this.verteidigungsClass[id] = 'veBish';
      if (positiv) {
        // this.aktuell = [];
        const k: Kaempferin = this.getKaempferWithName(this.angreifer);
        if (k !== null) {
          k.aktionenUebrig --;
          this.updateKaempferin(k);
        }
      }
    }, 3000);
  }

  public getKaempferWithName(name: string): Kaempferin {
    let wanted: Kaempferin = null;
    this.kaempfer.forEach(k => {
      if (k.name === name) {
        wanted = k;
        return wanted;
      }
    });
    return wanted;
  }

  getRandomInt(maxNr: number): number {
    return Math.floor(Math.random() * maxNr) + 1;
  }

  checkRS(kaempferin: Kaempferin): void {
    if (kaempferin.rsZonen.length === 0) {
      kaempferin.rsZonen = this.kampfservice.getZonenOfZiel(kaempferin.zielName, kaempferin.zielGroesse);
      kaempferin.rsZonen.forEach(zone => {
        kaempferin.rs.push(0);
      });
    }
  }

  public sendMessage(): void {
    let message = this.messageParts[0];
    for (let i = 1; i < this.messageParts.length; i++) {
      message += '#' + this.messageParts[i];
    }
    this.chatService.send(message);
    this.messageParts = [];
  }

  public changeKampfPhase(phase: string): void {
    if ((phase === 'npcAT' || phase === 'keine' || phase === 'warteAufVe') &&
      (this.kampfPhase === 'veWahl' || this.kampfPhase.startsWith('tpErhalten') || this.kampfPhase === 'warteAufVe')) {
      // nothing
    } else {
      this.kampfPhase = phase;
      this.phasen.push(phase);
    }
  }

  public setAnDerReihe(): void {
    let niemandAnDerReihe = true;
    for (let i = 0; i < this.kaempfer.length; i++) {
      if (this.kaempfer[i].aktionenUebrig > 0) {
        this.anDerReiheKaempferin = this.kaempfer[i];
        this.anDerReihe = this.anDerReiheKaempferin.name;
        i = this.kaempfer.length;
        niemandAnDerReihe = false;
        if (this.anDerReiheKaempferin.npc) {
          this.changeKampfPhase('npcAT');
        }
      }
    }
    if (niemandAnDerReihe) {
      this.changeKampfPhase('keine');
    } else {
      if (!this.anDerReiheKaempferin.npc) {
        this.changeKampfPhase('keine');
      }
      this.getGegnerInReichweite();
    }
  }

  public getGegnerInReichweite(): void {
    this.gegnerInReichweite = [];
    if (this.anDerReiheKaempferin.rechteHand.nahkampf && this.anDerReiheKaempferin.linkeHand.nahkampf) {
      this.kaempfer.forEach(k => {
        if (this.anDerReiheKaempferin.gruppe !== 0) {
          if (k.team !== this.anDerReiheKaempferin.team && k.gruppe === this.anDerReiheKaempferin.gruppe) {
            this.gegnerInReichweite.push(k);
          }
        }
      });
    } else {
      this.kaempfer.forEach(k => {
        if (k.team !== this.anDerReiheKaempferin.team) {
          this.gegnerInReichweite.push(k);
        }
      });
    }
  }

  public waehleGegner(gegner: Kaempferin): void {
    this.gewaehlterGegner = gegner;
    this.berechneAtWerte();
    this.changeKampfPhase('npcATGewählt');
  }

  public waehleWaffe(waffe: Waffe, hand: string): void {
    this.gewaehlteWaffe = this.factory.getNewWaffe();
    this.factory.copyWaffe(this.gewaehlteWaffe, waffe);
    this.wuerfleAttacke(hand);
    this.changeKampfPhase('npcATWaffeGewählt');
  }

  public wuerfleAttacke(hand: string): void {
    let patzerString = '';

    const wurf = this.getRandomInt(20);

    let wert = this.atR;
    if (hand === 'l') {
      wert = this.atL;
    }

    this.shownMsg = this.anDerReiheKaempferin.name + ' würfelt eine ' + wurf;

    if (wurf <= wert) {

      this.shownMsg += ' (geschafft). ';
      if (wurf === 1) {
        const pruefWurf = this.getRandomInt(20);
        this.shownMsg += 'Bestätigung: ' + pruefWurf;
        this.kritFlag = 1;
        if (pruefWurf <= wert) {
          this.shownMsg += ' (bestätigt). ';
          this.tpMultiplier = 2;
        } else {
          this.shownMsg += ' (nicht bestätigt). ';
        }
      }
      this.geschafft = true;
    } else {
      this.shownMsg += ' (nicht geschafft). ';
      if (wurf === 20) {
        const pruefWurf = this.getRandomInt(20);
        this.shownMsg += 'Bestätigung: ' + pruefWurf;
        if (pruefWurf > wert) {
          this.shownMsg += ' (bestätigt). ';
          // Patzer
          const rechts = this.anDerReiheKaempferin.rechteHand === this.gewaehlteWaffe;
          patzerString = ' ' + this.kampfservice.getNahkampfPatzer(this.anDerReiheKaempferin, rechts, this.getRandomInt(11) + 1);
          this.shownMsg += patzerString;
        } else {
          this.shownMsg += ' (nicht bestätigt). ';
        }
      }
      this.geschafft = false;
    }

  }

  public beendeAttacke(): void {
    if (this.geschafft) {
      this.sendAtToDefenderAndZoneToGM();
    }
    this.gewaehlteWaffe = this.factory.getNewWaffe();
    this.kritFlag = 0;
    this.atpaMod = 0;
    this.geschafft = false;

    this.messageParts.push('ATEnde');
    this.messageParts.push(this.anDerReiheKaempferin.name);
    this.sendMessage();

    this.setAnDerReihe();
  }

  public sendAtToDefenderAndZoneToGM(): void {
    // message to defender
    this.messageParts.push('AT');
    this.messageParts.push(this.anDerReiheKaempferin.name);
    this.messageParts.push(this.gewaehlteWaffe.kampftechnik);
    this.messageParts.push(String(0)); // VE-Erschwernis
    this.messageParts.push(this.gewaehlterGegner.name);
    this.messageParts.push(String(this.kritFlag));
    this.messageParts.push(this.gewaehlteWaffe.getCurrentTP(0));
    this.sendMessage();
    // message to GM
    const zone = this.getRandomInt(20);
    this.messageParts.push('Zone');
    this.messageParts.push(this.gewaehlterGegner.name);
    this.messageParts.push(String(zone));
    this.sendMessage();
    // reset choices before attack
  }

  public verteidigung(parade: boolean, wert: number, rechts: boolean): void {
    let patzerString = '';

    const wurf = this.getRandomInt(20);
    let passierPhase = false;

    this.shownMsg = this.verteidigerKaempfer.name + ' würfelt eine ' + wurf;
    if (wurf <= wert) { // geschafft
      this.shownMsg += ' (geschafft). ';
      if (wurf === 1) { // kritisch
        const pruefWurf = this.getRandomInt(20);
        this.shownMsg += 'Bestätigung: ' + pruefWurf;
        if (pruefWurf <= wert) {
          this.shownMsg += ' (bestätigt). ';
          passierPhase = true;
        } else {
          this.shownMsg += ' (nicht bestätigt). ';
        }
      }
      this.geschafft = true;
    } else { // nicht geschafft
      this.shownMsg += ' (nicht geschafft). ';
      if (wurf === 20) { // Patzer
        const pruefWurf = this.getRandomInt(20);
        this.shownMsg += 'Bestätigung: ' + pruefWurf;
        if (pruefWurf > wert) {
          this.shownMsg += ' (bestätigt). ';
          // Patzer
          const patzerWurf = this.getRandomInt(11) + 1;
          this.messageParts.push('PatzerVE');
          this.messageParts.push(this.verteidigerKaempfer.name);
          this.messageParts.push(String(patzerWurf));
          this.sendMessage();

          patzerString = ' ' + this.kampfservice.getNahkampfPatzer(this.anDerReiheKaempferin, rechts, patzerWurf);
          this.shownMsg += patzerString;
        } else {
          this.shownMsg += ' (nicht bestätigt). ';
        }
      }
      this.geschafft = false;
    }
    if (passierPhase) {
      this.changeKampfPhase('passierschlag');
    } else {
      this.changeKampfPhase('npcVEWaffeGewählt');
    }
  }

  public beendeVerteidigung(): void {
    let verteidigt = '0';
    if (this.geschafft) {
      verteidigt = '1';
    }
    this.geschafft = false;
    this.messageParts.push('VEEnde');
    this.messageParts.push(this.verteidigerKaempfer.name);
    this.messageParts.push(verteidigt);
    this.sendMessage();
    this.veDivisor = 1;
    this.veErschw = 0;
    this.atpaMod = 0;
    this.veL = 0;
    this.veR = 0;
    this.veAw = 0;
    this.setAnDerReihe();
  }

  public nichtVerteidigen(): void {
    this.shownMsg = this.verteidigerKaempfer.name + ' verteidigt sich nicht!';
    this.geschafft = false;
    this.tpErhalten(this.verteidigerKaempfer.name);
  }

  public schadenErmitteln(): void {
    if (this.angreiferIstNpc) {
      const split1: string[] = this.angreiferWaffeTp.split('W');
      const count: number = Number(split1[0]);
      let w: number;
      let mod = 0;
      let split2 = [];
      if (split1[1].includes('-')) {
        split2 = split1[1].split('-');
        w = Number(split2[0]);
        mod = -Number(split2[1]);
      } else if (split1[1].includes('+')) {
        split2 = split1[1].split('+');
        w = Number(split2[0]);
        mod = Number(split2[1]);
      } else {
        w = Number(split1[1]);
      }
      let sum = mod;
      this.angreiferWurfString = 'Würfelwurf:';
      for (let i = 0; i < count; i++) {
        const wurf = this.getRandomInt(w);
        this.angreiferWurfString += ' [' + wurf + ']';
        sum += wurf;
      }
      this.trefferpunkte = sum;
    }
    const kaempferin = this.getKaempferWithName(this.schadenErhalter);
    const zone = this.kaempferTrefferzone.get(this.schadenErhalter);
    let rs = kaempferin.rs[kaempferin.rsZonen.indexOf(zone)];
    if (rs == null) {
      rs = 0;
    }
    this.sp = this.trefferpunkte - rs;
    this.shownMsg = '💥' + kaempferin.name + ' erhält ' + this.trefferpunkte
      + ' Schadenspunkte in Zone "' + zone + '". Durch die Rüstung dort (' + rs
      + ') kommen ' + this.sp + ' durch.';
    this.changeKampfPhase('tpErhaltenNachricht');
    this.trefferpunkte = 0;
  }

  public berechneVeWerte(): void {
    const erschwernis = this.atpaMod + this.veErschw + (this.verteidigerKaempfer.verteidigungenBisher * 3);
    this.veL = Math.round((this.verteidigerKaempfer.linkeHand.pa - erschwernis) / this.veDivisor);
    this.veR = Math.round((this.verteidigerKaempfer.rechteHand.pa - erschwernis) / this.veDivisor);

    const awMod = (2 * this.kampfservice.getKampftechnikType(this.kampftechnik));

    this.veAw = Math.round((this.verteidigerKaempfer.aw - erschwernis - awMod) / this.veDivisor);
  }

  public berechneAtWerte(): void {
    let erschwernis = this.atpaMod;
    if (!this.anDerReiheKaempferin.rechteHand.nahkampf) {
      erschwernis -= this.kampfservice.bewegungDesZielsMod[this.bewegZielIndex];
      erschwernis -= this.kampfservice.bewegungDesSchuetzenMod[this.bewegSchuetzeIndex];
      erschwernis -= this.kampfservice.zielgroessenMod[this.kampfservice.zielgroessen.indexOf(this.gewaehlterGegner.zielGroesse)];
      erschwernis -= -this.fkRwErschwernis;

      this.fkReichweiten = this.anDerReiheKaempferin.rechteHand.reichweiten.split('/');
    }
    this.atL = Math.round(this.anDerReiheKaempferin.linkeHand.at - erschwernis);
    this.atR = Math.round(this.anDerReiheKaempferin.rechteHand.at - erschwernis);

  }

  public tpPhaseBeenden(): void {
    const kaempferin = this.getKaempferWithName(this.schadenErhalter);
    const zone = this.kaempferTrefferzone.get(this.schadenErhalter);
    if (kaempferin.npc) {
      kaempferin.lep -= this.sp;
      this.updateKaempferin(kaempferin);
    }
    this.aktuell.push('💥' + kaempferin.name + ' erhält ' + this.sp + ' Schadenspunkte in Zone "' + zone + '".');
    this.changeKampfPhase('');
    this.shownMsg = '';
    this.setAnDerReihe();
  }

  resetNeueKaempferin(): void {
    // this.neueKaempferin = this.factory.getNewKaempferin();
    this.neueKaempferin = new Kaempferin();
    this.neueKaempferin.spieler = sessionStorage.getItem('user');
    this.neueKaempferin.npc = true;
    this.neueKaempferin.team = 2;
  }

  getGroessenOfZieltype(): void {
    this.zielGroessen = this.kampfservice.getAlleGroessenOfType(this.neueKaempferin.zielName);
    this.getZielZonenOfZiel();
  }
  getZielZonenOfZiel(): void {
    this.neueKaempferin.rs = [];
    this.neueKaempferin.rsZonen =  this.kampfservice.getZonenOfZiel(this.neueKaempferin.zielName, this.neueKaempferin.zielGroesse);
    this.neueKaempferin.rsZonen.forEach(zone => {
      this.neueKaempferin.rs.push(0);
    });
  }

  getWaffeOfNeueKaempferin(rechts: boolean): void {
    let waffe: Waffe;
    if (rechts) {
      waffe = this.alleWaffen[this.waffeIndexR].clone();
      this.neueKaempferin.rechteHand = waffe;
    } else {
      waffe = this.alleWaffen[this.waffeIndexL].clone();
      this.neueKaempferin.linkeHand = waffe;
    }
    if (waffe != null) {
      const kt = waffe.kampftechnik;
      this.alleKampftechniken.forEach(k => {
        if (k.name === kt) {
          let neu = true;
          this.neueKaempferin.kampftechniken.forEach(vorhanden => {
            if (vorhanden.name === kt) {
              neu = false;
            }
          });
          if (neu) {
            const nw: NameWertNumber = this.factory.createNameWert(k);
            this.neueKaempferin.kampftechniken.push(nw);
          }
        }
      });
    }
    this.getWaffenwerteOfNeuerKaempferin();
  }

  removeKampftechnik(kt: NameWertNumber): void {
    let found = false;
    for (let i = 0; i < this.neueKaempferin.kampftechniken.length; i++) {
      if (this.neueKaempferin.kampftechniken[i].name === kt.name) {
        found = true;
      }
      if (found && i < this.neueKaempferin.kampftechniken.length - 1) {
        this.neueKaempferin.kampftechniken[i] = this.neueKaempferin.kampftechniken[i + 1];
      }
    }
    if (found) {
      this.neueKaempferin.kampftechniken.pop();
    }

  }

  getKampftechnikWithName(name: string): Kampftechnik {
    let gesucht: Kampftechnik;
    this.alleKampftechniken.forEach(kt => {
      if (kt.name === name) {
        gesucht = kt;
      }
    });
    return gesucht;
  }

  berechneWerteVonNeueKaempferin(): void {
    this.neueKaempferin.resetWerteAufBasis();
    this.getWaffenwerteOfNeuerKaempferin();
  }

  getWaffenwerteOfNeuerKaempferin(): void {
    this.neueKaempferin.calculateWerte(this.alleKampftechniken);
    this.waffeTpR = this.neueKaempferin.rechteHand.getCurrentTP(0);
    this.waffeTpL = this.neueKaempferin.linkeHand.getCurrentTP(0);
  }

  toggleKaempferinInfo(name: string): void {
    if (this.kaempferInfo === name) {
      this.kaempferInfo = '';
    } else {
      this.kaempferInfo = name;
    }
  }

  addKaempferinToDB(k: Kaempferin): void {
    this.dataService.addKaempferinToDB(k);
  }

  changeZustand(kaempferin: Kaempferin): void {
    kaempferin.calculateWerte(this.alleKampftechniken);
    this.updateKaempferin(kaempferin);
  }

  setRsOfNewKaempferin(index: number, rs: number): void {
    this.neueKaempferin.rs[index] = rs;
  }

}
