import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../_interfaces/user';
import {DataService} from '../../_services/data.service';
import {WebsocketService} from '../../_services/websocket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  @Input() $userlist: User[];
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  public user$: User;
  public $errorMessage: boolean;

  constructor(public dataService: DataService, public ws: WebsocketService) {
    this.user$ = {
      username: null,
      password: null
    };
    this.$errorMessage = false;
  }

  ngOnInit(): void {
  }

  public authenticate(): void {
    console.log('authenticate()');
    this.dataService.authenticate(this.user$.username, this.user$.password).subscribe((data: boolean) => {
      console.log('data: ' + data);
      if (data) {
        this.$errorMessage = false;
        sessionStorage.setItem('auth', 'true');
        sessionStorage.setItem('user', this.user$.username);
        this.ping.emit();
      } else {
        this.$errorMessage = true;
      }
    });
  }

}
