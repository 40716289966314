import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Armor} from '../../../_interfaces/armor';
import {DataService} from '../../../_services/data.service';
import {Item} from '../../../_interfaces/item';
import {Glossar} from '../../../_classes/glossar/glossar';
import {Gegenstand} from '../../../_classes/ausruestung/gegenstand';
import {Allgemein} from '../../../_classes/ausruestung/allgemein';
import {Behaeltnis} from '../../../_classes/ausruestung/behaeltnis';
import {Zaehlbar} from '../../../_classes/ausruestung/zaehlbar';
import {Seil} from '../../../_classes/ausruestung/seil';
import {Leuchtmittel} from '../../../_classes/ausruestung/leuchtmittel';
import {Nahkampfwaffe} from '../../../_classes/ausruestung/nahkampfwaffe';
import {Parierwaffe} from '../../../_classes/ausruestung/parierwaffe';
import {Fernkampfwaffe} from '../../../_classes/ausruestung/fernkampfwaffe';
import {Ruestung} from '../../../_classes/ausruestung/ruestung';
import {Waffe} from '../../../_classes/ausruestung/waffe';
import {Alkohol} from '../../../_classes/ausruestung/alkohol';
import {StringEvent} from '../../../_interfaces/events/string-event';
import {All} from 'tslint/lib/rules/completedDocsRule';
import {HeldenblattCommService} from '../../../_services/heldenblatt-comm.service';
import {Werte} from '../../../_classes/charakter/werte';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.sass']
})
export class ItemComponent implements OnInit {

  @Output() reload: EventEmitter<any> = new EventEmitter<any>();
  @Input() glossar: Glossar;
  @Input() spielmeister: boolean;
  @Input() held: string;
  typen: string[] = ['Allgemein', 'Behältnis', 'Alkohol', 'Seil', 'Leuchtmittel', 'Nahkampfwaffe', 'Parierwaffe', 'Fernkampfwaffe', 'Rüstung'];

  eventsubscription: Subscription;

  gegenstand: Gegenstand = new Gegenstand();

  index = 0;
  waehlbareGegenstaende: Gegenstand[] = [];

  userZitat = '';

  constructor(public dataService: DataService,
              private sharedService: HeldenblattCommService) {
    this.gegenstand = new Allgemein();
    this.gegenstand.type = 'Allgemein';
  }

  ngOnInit(): void {
    this.waehleGegenstand();
    this.eventsubscription = this.sharedService.getEvent().subscribe((data) => {
      // console.log(data);
      this.gegenstand.copy(data);
    });
  }

  waehleGegenstand(): void {
    this.waehlbareGegenstaende = [];
    this.glossar.ausruestung.forEach(item => {
      if (item.type === this.gegenstand.type) {
        this.waehlbareGegenstaende.push(item);
      }
    });
  }

  changeTyp(): void {
    if (this.gegenstand.type === 'Allgemein') {
      this.gegenstand = new Allgemein();
      this.gegenstand.type = 'Allgemein';

    } else if (this.gegenstand.type === 'Behältnis') {
      this.gegenstand = new Behaeltnis();
      this.gegenstand.type = 'Behältnis';

    } else if (this.gegenstand.type === 'Alkohol') {
      this.gegenstand = new Alkohol();
      this.gegenstand.type = 'Alkohol';

    } else if (this.gegenstand.type === 'Leuchtmittel') {
      this.gegenstand = new Leuchtmittel();
      this.gegenstand.type = 'Leuchtmittel';

    } else if (this.gegenstand.type === 'Seil') {
      this.gegenstand = new Seil();
      this.gegenstand.type = 'Seil';

    } else if (this.gegenstand.type === 'Nahkampfwaffe') {
      this.gegenstand = new Nahkampfwaffe();
      this.gegenstand.type = 'Nahkampfwaffe';

    } else if (this.gegenstand.type === 'Parierwaffe') {
      this.gegenstand = new Parierwaffe();
      this.gegenstand.type = 'Parierwaffe';

    } else if (this.gegenstand.type === 'Fernkampfwaffe') {
      this.gegenstand = new Fernkampfwaffe();
      this.gegenstand.type = 'Fernkampfwaffe';

    } else if (this.gegenstand.type === 'Rüstung') {
      this.gegenstand = new Ruestung();
      this.gegenstand.type = 'Rüstung';

    }
    this.waehleGegenstand();
  }

  public addItemToDB(): void {
    console.log(this.gegenstand);
    if (this.gegenstand.name != null && this.gegenstand.name !== '') {
      if (this.spielmeister) {
        this.dataService.addGegenstandToDB(this.gegenstand);
      } else {
        this.dataService.schlageGegenstandVor(this.gegenstand);
      }
      this.reloadAusruestung();
    }
  }

  changeName(): void {
    this.gegenstand.dateiname = this.gegenstand.name;
  }

  changePreis(): void {
    (this.gegenstand as Alkohol).preis = (this.gegenstand as Alkohol).verkaufsPreis;
  }

  changeUserzitat(): void {
    (this.gegenstand as Allgemein).zitat = '»' + this.userZitat + '« -' + this.held;
  }

  load(): void {
    this.gegenstand.copy(this.waehlbareGegenstaende[this.index]);
  }

  reset(): void {
    this.changeTyp();
  }

  isZaehlbar(): boolean {
    return this.gegenstand instanceof Zaehlbar;
  }
  isAllgemein(): boolean {
    return this.gegenstand instanceof Allgemein;
  }
  isBehaeltnis(): boolean {
    return this.gegenstand instanceof Behaeltnis;
  }
  isAlkohol(): boolean {
    return this.gegenstand instanceof Alkohol;
  }
  isSeil(): boolean {
    return this.gegenstand instanceof Seil;
  }
  isLeuchtmittel(): boolean {
    return this.gegenstand instanceof Leuchtmittel;
  }
  isWaffe(): boolean {
    return this.gegenstand instanceof Waffe;
  }
  isNahkampfwaffe(): boolean {
    return this.gegenstand instanceof Nahkampfwaffe;
  }
  isParierwaffe(): boolean {
    return this.gegenstand instanceof Parierwaffe;
  }
  isFernkampfwaffe(): boolean {
    return this.gegenstand instanceof Fernkampfwaffe;
  }
  isRuestung(): boolean {
    return this.gegenstand instanceof Ruestung;
  }

  reloadAusruestung(): void {
    const event: StringEvent = {
      wert: 'ausruestung'
    };
    this.reload.emit(event);
  }
}
