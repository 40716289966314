<div class="body" *ngIf="charakter !== undefined">
  <h3>Zauber und Rituale</h3>
  <div class="table">
    <div class="headline">
      <div class="name">Zauber/Ritual</div>
      <div class="probe">Probe</div>
      <div class="fw">FW +/- Mod.</div>
      <div class="merkmal">Merkmal</div>
      <div class="zdauer">Zauberdauer</div>
      <div class="reichweite">Reichweite</div>
      <div class="wdauer">Wirkungsdauer</div>
      <div class="merkmal">Merkmal</div>
      <div class="kosten">Kosten</div>
    </div>
    <div class="line" *ngFor="let zauber of alleZauber index as i">
      <div class="name" (click)="setZauberInfo(zauber)">{{zauber.name}}</div>
      <div class="probe" *ngIf="attrInfos[i]" (click)="toggleAttrInfo(zauber)">
        <div class="attr">{{zauber.probe[0]}}</div>
        <div class="attr">{{zauber.probe[1]}}</div>
        <div class="attr">{{zauber.probe[2]}}</div>
      </div>
      <div class="probe" *ngIf="!attrInfos[i]" (click)="toggleAttrInfo(zauber)">
        <div class="attr">{{getAttributeWert(zauber.probe[0],zauber)}}</div>
        <div class="attr">{{getAttributeWert(zauber.probe[1],zauber)}}</div>
        <div class="attr">{{getAttributeWert(zauber.probe[2],zauber)}}</div>
      </div>
      <div class="fw">
        <div class="fwWert">
          {{zauber.fw}}
        </div>
        <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseMod(zauber)"/>
        <div class="fwMod">
          <label class="good" *ngIf="modifikatoren[i] > 0">+{{modifikatoren[i]}}</label>
          <label class="bad" *ngIf="modifikatoren[i] < 0">{{modifikatoren[i]}}</label>
        </div>
        <img src="../../../../assets/images/button_s_pos.png" (click)="increaseMod(zauber)"/>
      </div>
      <div class="merkmal">{{zauber.merkmal}}</div>
      <div class="zdauer">{{zauber.zauberDauer.split('(')[0]}}</div>
      <div class="reichweite">{{zauber.reichweite.split('(')[0]}}</div>
      <div class="wdauer">{{zauber.wirkungsDauer.split('(')[0]}}</div>
      <div class="merkmal">{{zauber.merkmal}}</div>
      <div class="kosten">{{zauber.aspKosten.split('(')[0].split(' bzw.')[0].replace('mindestens ','')}}</div>
      <div class="info" *ngIf="zauberInfo.name === zauber.name">
        <b>Beschreibung</b>
        {{zauber.beschreibung}}
        <b>Wirkung</b>
        {{zauber.wirkung}}
        <b>Zauberdauer</b>
        {{zauber.zauberDauer}}
        <b>Kosten</b>
        {{zauber.aspKosten}}
        <b>Reichweite</b>
        {{zauber.reichweite}}
        <b>Wirkungsdauer</b>
        {{zauber.wirkungsDauer}}
        <b>Zielkathegorie</b>
        {{zauber.zielKathegorie}}
        <b>Merkmal</b>
        {{zauber.merkmal}}
        <b>Verbreitung</b>
        {{zauber.verbreitung}}
      </div>
    </div>
  </div>
  <h3>Zaubertricks</h3>
  <div style="padding-bottom: 8px" *ngFor="let trick of alleZauberTrick">
    <div>Name: <b>{{trick.name}}</b></div>
    <div>Reichweite: {{trick.reichweite}}</div>
    <div>Ziel: {{trick.zielKathegorie}}</div>
    <div>Beschreibung: {{trick.beschreibung}}</div>
  </div>
</div>
