import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Profession} from '../../../_interfaces/profession';
import {DataService} from '../../../_services/data.service';
import {Step1} from '../../../_interfaces/charaktererstellung/step1';
import {Step5} from '../../../_interfaces/charaktererstellung/step5';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';
import {Zauber} from '../../../_interfaces/zauber';
import {SteigerungstabelleService} from '../../../_services/steigerungstabelle.service';
import {NumberEvent} from '../../../_interfaces/events/number-event';
import {VorUndNachteil} from '../../../_interfaces/vor-und-nachteil';
import {CharakterVuN} from '../../../_interfaces/charakter-vu-n';

@Component({
  selector: 'app-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['./profession.component.sass']
})
export class ProfessionComponent implements OnInit {
  @Input() heldin: Heldenerschaffung;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  public $zauberKostenChange: number;

  public $professionMenu: Profession[];
  public $choosableProfession: Profession[];
  public $professionIndex: number;
  // public $profession: Profession;
  public $professionsarten: string[];
  public $professionsart: string;
  public $professionsgattungen: string[];
  public $gattungskosten: number[];
  public $professionsgattung: string;
  public $kampftechnikwahl: string;
  public $kampftechnikFw: number;
  public $kampftechniken: string[];
  public $zaubertrickwahl: string;
  public $zaubertricks: string[];

  public $alleZauber: Zauber[];

  constructor(public dataService: DataService, public steigerung: SteigerungstabelleService) {
    this.$professionIndex = 0;
  }

  ngOnInit(): void {
    this.getAlleProfessionen(true);
  }

  /**
   * füllt $professionMenu
   */
  public getAlleProfessionen(initial: boolean): void {
    this.$professionMenu = [];
    this.$professionsarten = [];
    this.dataService.getAlleProfessionen().subscribe((data: Profession[]) => {
      data.sort((a, b) => (a.nameM < b.nameM ? -1 : 1));
      this.$professionMenu = data;
      data.forEach((prof) => {
        const art = prof.art;
        if (!this.$professionsarten.includes(art)) {
          this.$professionsarten.push(art);
        }
      });
      if (this.heldin.profession !== null) {
        this.$professionsart = this.heldin.profession.art;
      } else {
        this.$professionsart = this.$professionsarten[0];
      }

      this.getAlleZauber(initial);
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getProfession(): void {
    console.log('getProfession');
    console.log('index: ' + this.$professionIndex);
    console.log(this.$choosableProfession);
    console.log(this.$choosableProfession[this.$professionIndex]);
    this.$zauberKostenChange = 0;
    /*if (this.heldin.profession !== null) {
      if (this.heldin.profession.art === 'magisch') {
        const vun2keep: CharakterVuN[] = [];
        this.heldin.vorUndNachteile.forEach(vun => {
          if (vun.name !== 'Zauberer') {
            vun2keep.push(vun);
          }
        });
        this.heldin.vorUndNachteile = vun2keep;
      }
    }*/
    if (this.heldin.zauber.length > 0 && this.heldin.profession !== null) {
      // console.log('deleteZauber');
      this.deleteZauberOfProfession();
    }
    // console.log(this.$choosableProfession);
    this.heldin.profession = this.$choosableProfession[this.$professionIndex];

    this.getZauberOfProfession();
    // console.log(this.heldin.profession);
    this.readOptionaleKampftechniken();
    this.readOptionaleZaubertricks();
    const eventObject: Step5 = {
      profession: this.heldin.profession
    };
    this.ping.emit(eventObject);
    console.log(this.$choosableProfession[this.$professionIndex]);
    this.heldin.$optionaleKtListe = [];
    this.heldin.profession.kampftechniken.forEach(ktstring => {
      if (ktstring.includes(',')) {
        const array = ktstring.split(',');
        this.heldin.$optionaleKtAnz = +array[0];
        this.heldin.$optionaleKtWert = +array[array.length - 1] - 6;
        for (let i = 1; i < array.length - 1; i++) {
          this.heldin.$optionaleKtListe.push(array[i] + ' ' + array[array.length - 1]);
        }
      }
    });
    this.heldin.$optionaleKtListe.sort((a, b) => (a < b ? -1 : 1));
  }

  public getGattungenOfProfessionsart(initial: boolean): void {
    this.$professionsgattungen = [];
    this.$gattungskosten = [];
    this.$choosableProfession = [];
    // bestimme wählbare Professionen der gewählten Art
    this.$professionMenu.forEach((p) => {
      // console.log(p.art + ' === ' + this.$professionsart);
      if (p.art === this.$professionsart) {
        // console.log('-> true');
        this.$choosableProfession.push(p);
      }
    });
    // console.log(this.$choosableProfession);
    this.$choosableProfession.forEach((prof) => {
      const gattung = prof.gattung;
      if (!this.$professionsgattungen.includes(gattung)) {
        this.$professionsgattungen.push(gattung);
        this.$gattungskosten.push(prof.paketKosten);
      }
    });
    if (this.heldin.profession !== null && initial) {
      // console.log('gattung -> von heldin: ' + this.heldin.profession.gattung);
      this.$professionsgattung = this.heldin.profession.gattung;
    } else {
      // console.log('gattung -> 0: ' + this.$professionsgattungen[0]);
      this.$professionsgattung = this.$professionsgattungen[0];
    }
    this.getProfessionsgattung(initial);
  }

  public getProfessionsgattung(initial: boolean): void {
    this.$choosableProfession = [];
    this.$professionMenu.forEach((p) => {
      // console.log(p.gattung + ' === ' + this.$professionsgattung + ' && ' + p.art + ' === ' + this.$professionsart);
      if (p.gattung === this.$professionsgattung && p.art === this.$professionsart) {
        // console.log('-> true');
        this.$choosableProfession.push(p);
      }
    });
    // console.log(this.$choosableProfession);
    if (this.heldin.profession !== null && initial) {
      this.$professionIndex = -1;
      for (let i = 0; i < this.$choosableProfession.length; i++) {
        if (this.$choosableProfession[i].nameM === this.heldin.profession.nameM) {
          this.$professionIndex = i;
        }
      }
      if (this.$professionIndex < 0) {
        this.$professionIndex = 0;
      }
      this.getZauberOfProfession();
    } else {
      this.$professionIndex = 0;
    }
    this.getProfession();
    // this.$profession = this.$choosableProfession[0];
  }

  public readOptionaleKampftechniken(): void {
    this.$kampftechniken = [];
    this.$kampftechnikwahl = undefined;
    // console.log(this.heldin);
    this.heldin.profession.kampftechniken.forEach(kt => {
      if (kt.includes(',')) {
        const array = kt.split(',');
        this.$kampftechnikwahl = array[0];
        for (let i = 1; i < array.length - 1; i++) {
          this.$kampftechniken.push(array[i]);
        }
        this.$kampftechnikFw = +array[array.length - 1];
      }
    });
  }

  public readOptionaleZaubertricks(): void {
    if (this.heldin.profession.zauber !== undefined) {
      this.$zaubertricks = [];
      this.$zaubertrickwahl = undefined;
      this.heldin.profession.zauber.forEach(z => {
        if (z.includes('Zaubertrick')) {
          const array = z.split('(');
          const result = array[0]; // 'ein Zaubertrick aus folgender Liste '
          let zaubertricksString = array[1];
          zaubertricksString = zaubertricksString.substring(0, zaubertricksString.length - 1);
          this.$zaubertrickwahl = result;
          zaubertricksString.split(', ').forEach(zauber => {
            if (zauber !== '') {
              this.$zaubertricks.push(zauber);
            }
          });
        }
      });
    }
  }

  public getAlleZauber(initial: boolean): void {
    this.$alleZauber = [];
    this.dataService.getAlleZauber().subscribe((data: Zauber[]) => {
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, true);
      });
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, false);
      });
      this.$alleZauber = data;
      this.getGattungenOfProfessionsart(initial);
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getZauberOfProfession(): void {
    console.log(this.$alleZauber);
    // console.log(this.heldin.profession.nameM);
    // console.log(this.heldin.profession.zauber);
    if (this.heldin.profession.zauber !== undefined) {
      this.heldin.profession.zauber.forEach(zauberStr => {
        // console.log(zauberStr);
        if (!zauberStr.includes('Zaubertrick')) {
          const array = zauberStr.split(' ');
          let name = array[0];
          for (let i = 1; i < array.length - 1; i++) {
            name += ' ' + array[i];
          }
          const zauber = this.getZauberWIthName(name);
          if (zauber !== undefined) {
            zauber.fw = Number(array[array.length - 1]);
            this.heldin.zauber.push(zauber);

          }
        }
      });
      // console.log(this.heldin.zauber);
      this.heldin.zauber.forEach(z => {
        z.name = this.replaceUmlaute(z.name, true);
      });
      this.heldin.zauber.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.heldin.zauber.forEach(z => {
        z.name = this.replaceUmlaute(z.name, false);
      });
    }
  }

  public deleteZauberOfProfession(): void {
    const zauberArray: string[] = [];
    const fwArray: number[] = [];
    if (this.heldin.profession.zauber !== undefined) {
      this.heldin.profession.zauber.forEach(zauberStr => {
        // console.log(zauberStr);
        if (!zauberStr.includes('Zaubertrick')) {
          const array = zauberStr.split(' ');
          let name = array[0];
          for (let i = 1; i < array.length - 1; i++) {
            name += ' ' + array[i];
          }
          zauberArray.push(name);
          fwArray.push(Number(array[array.length - 1]));
        }
      });

      const allgemeineZauber: Zauber[] = [];

      // console.log(this.$alleZauber);
      this.heldin.zauber.forEach(z => {
        let keep = true;
        for (let i = 0; i < zauberArray.length; i++) {
          // console.log('getZauberWithName: ' + zauberArray[i]);
          // console.log(z);
          const zauber: Zauber = this.getZauberWIthName(zauberArray[i]);
          console.log(zauberArray[i]);
          console.log(zauber);

          if (zauber !== undefined) {
            if (z.name === zauber.name) {
              zauber.fw = fwArray[i];
              if (z.fw > zauber.fw) {
                const diff = z.fw - zauber.fw;
                for (let j = 0; j < diff; j++) {
                  this.decreaseZauber(z);
                }
              } else if (z.fw < zauber.fw) {
                const diff = zauber.fw - z.fw;
                for (let j = 0; j < diff; j++) {
                  this.increaseZauber(z);
                }
              }
              keep = false;
            }
          }
          // console.log(z.name + ' === ' + zauber.name);
        }
        if (keep) {
          // console.log('behalte Zauber ' + z.name);
          allgemeineZauber.push(z);
        } else {
          // console.log('vergesse Zauber ' + z.name);
        }

      });
      this.heldin.zauber = allgemeineZauber;
      // console.log(this.heldin.zauber);
      this.heldin.zauber.forEach(z => {
        z.name = this.replaceUmlaute(z.name, true);
      });
      this.heldin.zauber.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.heldin.zauber.forEach(z => {
        z.name = this.replaceUmlaute(z.name, false);
      });
    }
  }

  public getZauberWIthName(name: string): Zauber {
    if (name === 'Projektimago') {
      name = 'Projectimago';
    } else if (name === 'Aeolito') {
      name = 'Äolito';
    }

    let wanted: Zauber;
    this.$alleZauber.forEach(zauber => {
      if (zauber.name === name) {
        wanted = zauber;
      }
    });
    if (wanted === undefined) {
      this.$alleZauber.forEach(zauber => {
        const zauberSplitted = zauber.name.split(' ');
        if (name.startsWith(zauberSplitted[0])) {
          wanted = zauber;
        }
        if (wanted === undefined) {
          // console.log(name + ' nicht in Zaubern gefunden!');
        }
      });
    }
    return wanted;
  }

  public replaceUmlaute(str: string, forward: boolean): string {
    let guard = true;
    while (guard) {
      guard = false;
      if (forward) {
        if (str.includes('Ä')) {
          str = str.replace('Ä', 'Ae');
          guard = true;
        } else if (str.includes('Ö')) {
          str = str.replace('Ö', 'Oe');
          guard = true;
        } else if (str.includes('Ü')) {
          str = str.replace('Ü', 'Ue');
          guard = true;
        }
      } else {
        if (str.includes('Ae')) {
          str = str.replace('Ae', 'Ä');
          guard = true;
        } else if (str.includes('Oe')) {
          str = str.replace('Oe', 'Ö');
          guard = true;
        } else if (str.includes('Ue')) {
          str = str.replace('Ue', 'Ü');
          guard = true;
        }
      }
    }
    return str;
  }

  public increaseZauber(zauber: Zauber): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    const neuerWert = zauber.fw + 1;
    const kosten = this.steigerung.getKosten(neuerWert, spalten.indexOf(zauber.steigerungskosten));
    this.$zauberKostenChange += kosten;
    // console.log('Kosten: ' + kosten);
    zauber.fw = neuerWert;
  }

  public decreaseZauber(zauber: Zauber): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    if (zauber.fw > 0) {
      const neuerWert = zauber.fw - 1;
      const kosten = this.steigerung.getKosten(zauber.fw, spalten.indexOf(zauber.steigerungskosten)) * -1;
      this.$zauberKostenChange += kosten;
      // console.log('Kosten: ' + kosten);
      if (neuerWert > 0) {
        zauber.fw = neuerWert;
      } else {
        const index = this.heldin.zauber.indexOf(zauber);
        for (let i = index; i < this.heldin.zauber.length - 1; i++) {
          this.heldin.zauber[i] = this.heldin.zauber[i + 1];
        }
        this.heldin.zauber.pop();
      }
    }
  }
}
