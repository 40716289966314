import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Glossar} from '../../_classes/glossar/glossar';
import {Heldin} from '../../_classes/charakter/heldin';
import {Kombitalent} from '../../_classes/kombitalent';
import {Gtalent} from '../../_classes/glossar/gtalent';
import {Gtalentanwendung} from '../../_classes/glossar/gtalentanwendung';
import {AusruestungSheet} from '../../_interfaces/components/sheet/ausruestungSheet';
import {Kombigegenstand} from '../../_classes/kombigegenstand';
import {Talentblatt} from '../../_interfaces/components/sheet/talentblatt';
import {PersistenceService} from '../../_services/persistence.service';
import {Gvorundnachteil} from '../../_classes/glossar/gvorundnachteil';
import {Gsonderfertigkeit} from '../../_classes/glossar/gsonderfertigkeit';
import {VorUndNachteil} from '../../_classes/charakter/vorUndNachteil';
import {Sonderfertigkeit} from '../../_classes/charakter/sonderfertigkeit';
import {Kombivun} from '../../_classes/kombivun';
import {Kombisf} from '../../_classes/kombisf';
import {Talentwert} from '../../_interfaces/talentwert';
import {Werte} from '../../_classes/charakter/werte';
import {rename} from 'fs';
import {Kombikampftechnik} from '../../_classes/kombikampftechnik';
import {Gkampftechnik} from '../../_classes/glossar/gkampftechnik';

@Component({
  selector: 'app-talentbogen',
  templateUrl: './talentbogen.component.html',
  styleUrls: ['./talentbogen.component.sass']
})
export class TalentbogenComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  public  localData: Talentblatt = {
    art: 'Körpertalente',
    anzeigeListe: new Map<string, boolean>()
  };
  ktWertBedeutung: string[] = [ 'Unfähig', 'Unfähig', 'Unfähig', 'Unfähig', 'Unfähig', 'Unfähig' // 0 - 5
    , 'Unfähig', 'Absoluter Anfänger', 'Anfänger', 'Grundkenntnisse', 'Einige Kenntnisse'  // 6 - 10
    , 'Genaue Kenntnisse', 'Dilettant', 'Fähiger Dilettant', 'Angehender Meister', 'Meister' // 11 - 15
    , 'Bewährter Meister', 'Regionaler Meister', 'Angehender Großmeister', 'Großmeister', 'Legendärer Großmeister'];
  talentWertBedeutung: string[] = ['Gerade mal davon gehört', 'Absoluter Anfänger', 'Anfänger', 'Grundkenntnisse', 'Einige Kenntnisse', 'Genaue Kenntnisse', 'Fähiger Amateur', 'Schlechter Profi', 'Annehmbarer Profi', 'Solider Profi', 'Erfahrener Profi', 'Sehr erfahrener Profi', 'Experte', 'Bewährter Experte', 'Meister', 'Bewährter Meister', 'Regionaler Meister', 'Regionaler Großmeister', 'Globaler Meister', 'Globaler Großmeister', 'Legendärer Großmeister'];
  bedeutungAktiv = '';

  public kampftechniken: Kombikampftechnik[] = [];

  public kTalente: Kombitalent[] = [];
  public gTalente: Kombitalent[] = [];
  public nTalente: Kombitalent[] = [];
  public wTalente: Kombitalent[] = [];
  public hTalente: Kombitalent[] = [];

  public talentarten = ['Körpertalente', 'Gesellschaftstalente', 'Naturtalente', 'Wissenstalente', 'Handwerkstalente', 'Kampf', '⚙️'];

  public talentInfo = '';
  public betrachteteAnwendung: Gtalentanwendung;
  public talentanwendungInfo = '';
  public einsatzmoeglichkeitInfoType = -1;
  public einsatzmoeglichkeitInfo: Kombisf | Kombivun;

  public vorteile: Gvorundnachteil[] = [];
  public sonderfertigkeiten: Gsonderfertigkeit[] = [];
  public nachteile: Gvorundnachteil[] = [];

  constructor(private persistence: PersistenceService) {

  }

  ngOnInit(): void {
    const stored: Talentblatt = this.persistence.retrieveComponent('talentblatt');
    // console.log(stored);
    if (stored != null) {
      this.localData = stored;
      const map = new Map<string, boolean>();
      Object.keys(this.localData.anzeigeListe).forEach((key: string) => {
        map.set(key, this.localData.anzeigeListe[key]);
      });
      this.localData.anzeigeListe = map;
    } else {
      this.localData.art = 'Körpertalente';
      this.localData.anzeigeListe = new Map<string, boolean>();
    }
    this.initialisiereTalente();
    console.log(this.kTalente);
  }

  public aktiveEinsatzmoeglichkeiten(talent: Gtalent): number {
    if (talent.name === 'Klettern') {
      console.log('aktiveEinsatzmoeglichkeiten(' + talent.name + ')');
      console.log(talent);
    }
    let aktiv = 0;
    let nachteilAktiv = false;
    talent.einsatzmoeglichkeiten.forEach(em => {
      if (em.aktiv && em.typ !== 'Nachteil') {
        if (!nachteilAktiv) {
          aktiv = 1;
        }
      } else if (em.aktiv && em.typ === 'Nachteil') {
        aktiv = -1;
        nachteilAktiv = true;
      }
    });
    return aktiv;
  }

  zeigeTalentwertBeschreibung(name: string): void {
    this.bedeutungAktiv = name;
    setTimeout(() => {
      if (this.bedeutungAktiv === name) {
        this.bedeutungAktiv = '';
      }
    }, 1500);
  }

  public aktiveEinsatzmoeglichkeitenAnwendung(anw: Gtalentanwendung): number {
    let aktiv = 0;
    anw.einsatzmoeglichkeiten.forEach(em => {
      if (em.aktiv && em.typ !== 'Nachteil') {
        aktiv = 1;
      } else if (em.aktiv && em.typ === 'Nachteil') {
        aktiv = -1;
      }
    });
    return aktiv;
  }

  public hatSpezialisierung(talent: string, anwendung: string): boolean {
    let vorhanden = false;
    this.charakter.getAlleSonderfertigkeitenMitName('Fertigkeitsspezialisierung').forEach(sf => {
      if (sf.gewaehlteKategorie === talent && sf.spezifikation === anwendung) {
        vorhanden = true;
      }
    });
    return vorhanden;
  }

  public changeTalentMarkierungen(key: string, value: boolean): void {
    this.localData.anzeigeListe.set(key, value);
    console.log('changeTalentMarkierungen');
    console.log(this.localData);
    this.kTalente.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });
    this.gTalente.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });
    this.nTalente.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });
    this.wTalente.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });
    this.hTalente.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });
    this.persistence.storeComponent('talentblatt', this.localData);
  }

  public ermittleEinsatzmoeglichkeiten(talent: Kombitalent): void {
    talent.glossartalent.einsatzmoeglichkeiten.forEach(em => {
      if (em.typ === 'Vorteil' || em.typ === 'Nachteil') {
        this.charakter.vorUndNachteile.forEach(vun => {
          if (vun.name === em.einsatz) {
            if (em.typ === 'Nachteil') {
              this.fuegeNachteilHinzu(vun, talent);
            } else {
              this.fuegeVorteilHinzu(vun, talent);
            }
            if (!this.localData.anzeigeListe.has(vun.name)) {
              em.aktiv = true;
              this.localData.anzeigeListe.set(vun.name, true);
            } else {
              em.aktiv = this.localData.anzeigeListe.get(vun.name);
            }
          }
        });
      } else if (em.typ === 'Sonderfertigkeit') {
        this.charakter.sonderfertigkeiten.forEach(sf => {
          if (sf.name === em.einsatz) {
            this.fuegeSFHinzu(sf, talent);
            if (!this.localData.anzeigeListe.has(sf.name)) {
              em.aktiv = true;
              this.localData.anzeigeListe.set(sf.name, true);
            } else {
              em.aktiv = this.localData.anzeigeListe.get(sf.name);
            }
          }
        });
      }
    });

    talent.glossartalent.anwendungsgebiete.forEach(anw => {
      anw.einsatzmoeglichkeiten.forEach(em => {
        if (em.typ === 'Vorteil' || em.typ === 'Nachteil') {
          this.charakter.vorUndNachteile.forEach(vun => {
            if (vun.name === em.einsatz) {
              if (em.typ === 'Nachteil') {
                this.fuegeAnwNachteilHinzu(anw.anwendung, vun, talent);
              } else {
                this.fuegeAnwVorteilHinzu(anw.anwendung, vun, talent);
              }
              if (!this.localData.anzeigeListe.has(vun.name)) {
                em.aktiv = true;
                this.localData.anzeigeListe.set(vun.name, true);
              } else {
                em.aktiv = this.localData.anzeigeListe.get(vun.name);
              }
            }
          });
        } else if (em.typ === 'Sonderfertigkeit') {
          this.charakter.sonderfertigkeiten.forEach(sf => {
            if (sf.name === em.einsatz) {
              this.fuegeAnwSFHinzu(anw.anwendung, sf, talent);
              if (!this.localData.anzeigeListe.has(sf.name)) {
                em.aktiv = true;
                this.localData.anzeigeListe.set(sf.name, true);
              } else {
                em.aktiv = this.localData.anzeigeListe.get(sf.name);
              }
            }
          });
        }
      });
    });
    console.log(this.gTalente);
    this.persistence.storeComponent('talentblatt', this.localData);
  }

  private fuegeNachteilHinzu(vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    talent.nachteile.push(kombivun);
  }

  private fuegeAnwNachteilHinzu(anwendung: string, vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    let nListe = talent.anwNachteile.get(anwendung);
    if (nListe == null) {
      nListe = [];
    }
    nListe.push(kombivun);
    talent.anwNachteile.set(anwendung, nListe);
  }

  private fuegeSFHinzu(sf: Sonderfertigkeit, talent: Kombitalent): void {
    const kombisf = new Kombisf();
    kombisf.charakterSF = sf;
    kombisf.glossarSF = this.glossar.getSonderfertigkeit(sf.name);
    talent.sonderfertigkeiten.push(kombisf);
  }
  private fuegeAnwSFHinzu(anwendung: string, sf: Sonderfertigkeit, talent: Kombitalent): void {
    const kombisf = new Kombisf();
    kombisf.charakterSF = sf;
    kombisf.glossarSF = this.glossar.getSonderfertigkeit(sf.name);
    let sfListe = talent.anwSonderfertigkeiten.get(anwendung);
    if (sfListe == null) {
      sfListe = [];
    }
    sfListe.push(kombisf);
    talent.anwSonderfertigkeiten.set(anwendung, sfListe);
  }

  private fuegeVorteilHinzu(vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    talent.vorteile.push(kombivun);
  }

  private fuegeAnwVorteilHinzu(anwendung: string, vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    let vListe = talent.anwVorteile.get(anwendung);
    if (vListe == null) {
      vListe = [];
    }
    vListe.push(kombivun);
    talent.anwVorteile.set(anwendung, vListe);
  }

  public getTalentAttributWert(talent: string, attribut: string): number {
    let wert = this.charakter.getCurrentWert(attribut);
    if (talent === 'Zechen') {
      console.log(this.charakter.getWert(talent));
    }
    wert -= this.charakter.getWert(talent).abzug;
    return wert;
  }

  public getAttributMod(talent: string, attribut: string): number {
    let mod = this.charakter.getWert(attribut).abzug;
    mod += this.charakter.getWert(talent).abzug;
    return mod;
  }

  public decreaseTalentMod(talent: string): void {
    this.charakter.decreaseBeeinflWert(talent, 'manuell');
    this.ping.emit();
  }

  public increaseTalentMod(talent: string): void {
    this.charakter.increaseBeeinflWert(talent, 'manuell');
    this.ping.emit();
  }

  public initialisiereTalente(): void {
    this.kampftechniken = [];
    this.kTalente = [];
    this.gTalente = [];
    this.nTalente = [];
    this.wTalente = [];
    this.hTalente = [];
    this.glossar.talente.forEach(talent => {
      const kombi: Kombitalent = new Kombitalent();
      kombi.glossartalent = new Gtalent();
      kombi.glossartalent.copy(talent);
      this.pruefeAwendungsgebiet(kombi);
      this.ermittleEinsatzmoeglichkeiten(kombi);
      kombi.charaktertalent = this.charakter.getWert(talent.name);
      if (talent.talentArt === 'körperlich') {
        this.kTalente.push(kombi);
      } else if (talent.talentArt === 'gesellschaftlich') {
        this.gTalente.push(kombi);
      } else if (talent.talentArt === 'natur') {
        this.nTalente.push(kombi);
      } else if (talent.talentArt === 'wissen') {
        this.wTalente.push(kombi);
      } else if (talent.talentArt === 'handwerk') {
        this.hTalente.push(kombi);
      }
    });
    this.glossar.kampftechniken.forEach(talent => {
      const kombi: Kombikampftechnik = new Kombikampftechnik();
      kombi.glossarkampftechnik = new Gkampftechnik();
      kombi.glossarkampftechnik.copy(talent);
      kombi.attributeAlsZahl = false;
      kombi.charaktertalent = this.charakter.getWert(talent.name);
      if (kombi.charaktertalent == null) {
        this.charakter.setWert(talent.name, 6);
        kombi.charaktertalent = this.charakter.getWert(talent.name);
      }
      if (kombi.glossarkampftechnik.leiteigenschaften.includes('/')) {
        kombi.glossarkampftechnik.leiteigenschaften.split('/').forEach(le => {
          kombi.leiteigenschaften.push(le);
        });
      } else {
        kombi.leiteigenschaften.push(kombi.glossarkampftechnik.leiteigenschaften);
      }
      this.kampftechniken.push(kombi);
    });
    this.kampftechniken.sort((a, b) => (a.glossarkampftechnik.name < b.glossarkampftechnik.name ? -1 : 1));
    this.kTalente.sort((a, b) => (a.glossartalent.name < b.glossartalent.name ? -1 : 1));
    this.gTalente.sort((a, b) => (a.glossartalent.name < b.glossartalent.name ? -1 : 1));
    this.nTalente.sort((a, b) => (a.glossartalent.name < b.glossartalent.name ? -1 : 1));
    this.wTalente.sort((a, b) => (a.glossartalent.name < b.glossartalent.name ? -1 : 1));
    this.hTalente.sort((a, b) => (a.glossartalent.name < b.glossartalent.name ? -1 : 1));
  }

  public pruefeAwendungsgebiet(talent: Kombitalent): void {
    const bleibendeAnwendungen: Gtalentanwendung[] = [];
    talent.anwSpezialisierungen = [];
    talent.glossartalent.anwendungsgebiete.forEach(anw => {
      if (this.hatSpezialisierung(talent.glossartalent.name, anw.anwendung)) {
        talent.anwSpezialisierungen.push(anw.anwendung);
      }
      let add = false;
      // console.log(talent.name + ' (' + anw.anwendung + '): ' + anw.apKosten);
      if (anw.apKosten > 0) {
        this.charakter.sonderfertigkeiten.forEach(sf => {
          if (sf.name === anw.anwendung) {
            add = true;
          }
        });
        if (anw.apKosten > 0) {
          this.charakter.vorUndNachteile.forEach(vun => {
            if (vun.name === anw.anwendung) {
              add = true;
            }
          });
        }
      } else {
        add = true;
      }
      if (add) {
        bleibendeAnwendungen.push(anw);
      }
    });
    talent.glossartalent.anwendungsgebiete = [];
    bleibendeAnwendungen.sort((a, b) => (a.anwendung < b.anwendung ? -1 : 1));
    bleibendeAnwendungen.forEach(anw => {
      talent.glossartalent.anwendungsgebiete.push(anw);
    });
  }

  public setTalentart(art: string): void {
    this.localData.art = art;
    this.persistence.storeComponent('talentblatt', this.localData);
  }

  public setAnwendungsspezialisierung(talent: Kombitalent, spez: string): void {
    if (talent.aktiveSpzialisierung === spez) {
      talent.aktiveSpzialisierung = '';
    } else {
      talent.aktiveSpzialisierung = spez;
    }
  }

  public setEinsatzInfo(einstz: Kombisf | Kombivun): void {
    console.log('setEinsatzInfo');
    let kombi: Kombivun | Kombisf;
    if (einstz instanceof Kombisf) {
      kombi = new Kombisf();
      kombi.copy(einstz);
    } else if (einstz instanceof Kombivun) {
      kombi = new Kombivun();
      kombi.copy(einstz);
    }
    console.log(kombi);
    if (this.einsatzmoeglichkeitInfo != null && this.einsatzmoeglichkeitInfo.anzeigeName() === kombi.anzeigeName()) {
      this.resetEinsatzInfo();
    } else {
      this.einsatzmoeglichkeitInfoType = kombi.type();
      this.einsatzmoeglichkeitInfo = kombi;
    }
  }

  public setTalentInfo(name: string): void {
    if (this.talentInfo === name) {
      this.resetTalentInfo();
    } else {
      this.talentInfo = name;
    }
    console.log(this.einsatzmoeglichkeitInfo);
  }

  public setAnwendungEinsatzInfo(talent: string, name: string): void {
    this.talentInfo = talent;
    if (this.talentanwendungInfo === name) {
      this.resetTalentInfo();
    } else {
      this.talentanwendungInfo = name;
    }
    console.log(this.einsatzmoeglichkeitInfo);
  }

  public setAnwendungInfo(talent: string, name: string, info: Gtalentanwendung): void {
    this.talentInfo = talent;
    if (this.talentanwendungInfo === name) {
      this.resetTalentInfo();
    } else {
      this.talentanwendungInfo = name;
      this.betrachteteAnwendung = info;
    }
    console.log(this.einsatzmoeglichkeitInfo);
  }

  public resetEinsatzInfo(): void {
    this.einsatzmoeglichkeitInfoType = -1;
    this.einsatzmoeglichkeitInfo = null;
  }

  public resetTalentInfo(): void {
    this.resetEinsatzInfo();
    this.resetAnwendungInfo();
    this.betrachteteAnwendung = null;
    this.talentInfo = '';
  }

  public resetAnwendungInfo(): void {
    this.talentanwendungInfo = '';
  }

  public istRoutine(talent: Kombitalent): boolean {
    if (talent.glossartalent.name === 'Körperbeherrschung') {
      console.log('istRoutine(' + talent.glossartalent.name + ')');
    }
    let routine = false;
    this.charakter.calculateGesamtModifikationFuer(talent.glossartalent.name);
    let mod: number = -this.charakter.getWert(talent.glossartalent.name).abzug;
    if (talent.glossartalent.name === 'Körperbeherrschung') {
      console.log('mod: ' + mod);
    }
    if (mod >= -3) {
      if (mod > 3) {
        mod = 3;
      }
      let fw = 19 - ((mod + 3) * 3);
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('fw: ' + fw);
      }
      let punkteUnter13 = 0;
      talent.glossartalent.probe.forEach(attr => {
        const attrWert = this.charakter.getCurrentWert(attr);

        if (attrWert < 13) {
          punkteUnter13 = punkteUnter13 + (13 - attrWert);
          if (talent.glossartalent.name === 'Körperbeherrschung') {
            console.log('attrWert < 13');
            console.log('=> punkteUnter13 = punkteUnter13 + (13 - ' + attrWert + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + punkteUnter13 + ' + (13 - ' + attrWert + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + punkteUnter13 + ' + (' + (13 - attrWert) + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + (punkteUnter13 + (13 - attrWert)));
            console.log('=> ' + punkteUnter13);
          }
        }
        if (talent.glossartalent.name === 'Körperbeherrschung') {
          console.log('attrWert(' + attr + '): ' + attrWert);
          console.log('punkteUnter13(' + attr + '): ' + punkteUnter13);
        }
      });
      fw += (punkteUnter13 * 3);
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('benötigter FW: ' + fw);
      }
      let aktFw = this.charakter.getWert(talent.glossartalent.name).wert;
      if (talent.aktiveSpzialisierung !== '') {
        aktFw += 2;
      }
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('vorhandener FW: ' + aktFw);
      }
      if (aktFw >= fw) {
        routine = true;
      }
    }
    return routine;
  }
}
