export class Loyalitaet {
  charid: number;
  name: string;
  wert: number;

  public copy(other: Loyalitaet): void {
    this.charid = other.charid;
    this.name = other.name;
    this.wert = other.wert;
  }
}
