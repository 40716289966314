export class Werte {
  kategorie: string;
  name: string;
  wert: number;
  abzug: number;

  public copy(other: Werte): void {
    this.kategorie = other.kategorie;
    this.name = other.name;
    this.wert = other.wert;
    this.abzug = other.abzug;
  }

  public akt(): number {
    return this.wert - this.abzug;
  }
}
