export class NameWert {
  name: string;
  wert: string;

  constructor() {
    this.name = '';
    this.wert = '';
  }

  public copy(other: NameWert): void {
    this.name = other.name;
    this.wert = other.wert;
  }
}
