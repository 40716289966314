import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {Alkohol} from '../../_classes/ausruestung/alkohol';
import {Proben} from '../../_interfaces/options/proben';
import {PersistenceService} from '../../_services/persistence.service';

@Component({
  selector: 'app-probenblatt',
  templateUrl: './probenblatt.component.html',
  styleUrls: ['./probenblatt.component.sass']
})
export class ProbenblattComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  options: Proben = {
    nav: 0,
    nav2: 0,
    nav3: 0
  };

  navBar: string[] = ['Jagd', 'Wache halten', 'Zechen'];

  alkSorten: string[] = ['Bier', 'Wein', 'Schnaps'];
  biere: Alkohol[] = [];
  weine: Alkohol[] = [];
  schnaepse: Alkohol[] = [];
  waehlbar: Alkohol[] = [];
  gewaehlt: Alkohol;
  wurf = 6;
  wuerfe: number[] = [];
  zechen: string[] = [];
  zechenTaP: number[] = [];
  wertWurf = 1;
  wertZechen = 0;
  ergebnis = 0;
  reaktion = '';
  /** DEBUG */
  posBesonders: Alkohol[] = [];


  constructor(private persistenceService: PersistenceService) { }

  ngOnInit(): void {
    if (this.charakter.getWert('bisherige Getränke') == null) {
      this.charakter.setWert('bisherige Getränke', 0);
    }
    const stored: Proben = this.persistenceService.retrieveComponent('Proben');
    if (stored != null) {
      this.options = stored;
    }
    this.posBesonders = [];

    this.biere = [];
    this.weine = [];
    this.schnaepse = [];
    this.glossar.ausruestung.forEach(item => {
      if (item.type === 'Alkohol') {
        if (item.subtyp === 'Bier') {
          this.biere.push(item as Alkohol);
        } else if (item.subtyp === 'Wein') {
          this.weine.push(item as Alkohol);
        } else if (item.subtyp === 'Schnaps') {
          this.schnaepse.push(item as Alkohol);
        }
        if ((item as Alkohol).wirkungPos !== '1 Berauscht' || (item as Alkohol).wirkungNeg !== '2 Berauscht') {
          this.posBesonders.push(item as Alkohol);
        }
      }
    });
    this.biere.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.weine.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.schnaepse.sort((a, b) => (a.name < b.name ? -1 : 1));


    this.posBesonders.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.posBesonders.sort((a, b) => (a.subtyp < b.subtyp ? -1 : 1));

    this.waehleAlkSorte();
    this.aktualisiereZechenWerte();
  }

  aktualisiereZechenWerte(): void {
    this.zechen = [];
    this.zechenTaP = [];
    this.glossar.talente.forEach(talent => {
      if (talent.name === 'Zechen') {
        talent.probe.forEach(attr => {
          let wert = this.charakter.getCurrentWert(attr);
          this.charakter.calculateGesamtModifikationFuer('Zechen');
          wert -= this.charakter.getWert(talent.name).abzug;
          // wert -= this.charakter.calculateGesamtModifikationFuer('Zechen');
          this.zechen.push(attr + ' ( ' + wert + ' )');
        });
        const fw = this.charakter.getWert(talent.name).wert;
        this.zechen.push('FW: ' + fw);
        for (let i = 0; i <= fw; i++) {
          this.zechenTaP.push(i);
        }
      }
    });
  }

  changeNav(wert: number): void {
    this.options.nav = wert;
    this.options.nav2 = 0;
    this.options.nav3 = 0;
    this.persistenceService.storeComponent('Proben', this.options);
    this.aktualisiereZechenWerte();
  }

  waehleAlkSorte(): void {
    // Falls alkSorte umgestellt wurde
    if (this.waehlbar.length > 0) {
      if (this.alkSorten.indexOf(this.waehlbar[this.options.nav3].subtyp) !== this.options.nav2) {
        this.options.nav3 = 0;
      }
    }
    this.waehlbar = [];
    if (Number(this.options.nav2) === 0) {
      this.biere.forEach(alk => {
        this.waehlbar.push(alk);
      });
    } else if (Number(this.options.nav2) === 1) {
      this.weine.forEach(alk => {
        this.waehlbar.push(alk);
      });
    } else if (Number(this.options.nav2) === 2) {
      this.schnaepse.forEach(alk => {
        this.waehlbar.push(alk);
      });
    }
    this.persistenceService.storeComponent('Proben', this.options);
    this.waehleAlk();
  }

  waehleAlk(): void {
    this.gewaehlt = this.waehlbar[this.options.nav3];
    if (this.gewaehlt.wirkungPos === 'keine') {
      this.wurf = 4;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '2 Berauscht') {
      this.wurf = 6;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '3 Berauscht') {
      this.wurf = 8;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '1 Betäubung') {
      this.wurf = 8;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '2 Berauscht, 1 Verwirrung') {
      this.wurf = 10;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '1 Verwirrung, 1 Berauscht') {
      this.wurf = 10;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '1 Berauscht, 1 Verwirrung') {
      this.wurf = 10;
    } else if (this.gewaehlt.wirkungPos === '2 Berauscht' && this.gewaehlt.wirkungNeg === '3 Berauscht') {
      this.wurf = 12;
    } else if (this.gewaehlt.wirkungPos === '2 Berauscht' && this.gewaehlt.wirkungNeg === '1 Betäubung') {
      this.wurf = 12;
    } else if (this.gewaehlt.wirkungPos === '2 Berauscht' && this.gewaehlt.wirkungNeg === '1 Betäubung, 1 Verwirrung') {
      this.wurf = 12;
    }
    this.wuerfe = [];
    for (let i = 1; i <= this.wurf; i++) {
      this.wuerfe.push(i);
    }
    this.persistenceService.storeComponent('Proben', this.options);
  }

  public decreaseBisherigeGetraenke(): void {
    if (this.charakter.getCurrentWert('bisherige Getränke') - 1 >= 0) {
      this.charakter.decreaseBeeinflWert('bisherige Getränke', 'manuell');
    }
    this.charakter.calculateGesamtModifikationFuer('bisherige Getränke');
    this.ping.emit();
  }

  public increaseBisherigeGetraenke(): void {
    this.charakter.increaseBeeinflWert('bisherige Getränke', 'manuell');
    this.charakter.calculateGesamtModifikationFuer('bisherige Getränke');
    this.ping.emit();
  }

  trinken(): void {
    let wert: number = (this.wertWurf - -this.charakter.getCurrentWert('bisherige Getränke')) - this.wertZechen;
    if (wert < 0) {
      wert = 0;
    }
    this.ergebnis = wert;
    this.increaseBisherigeGetraenke();
    const reaktionen: string[][] = [];
    if (this.gewaehlt.subtyp === 'Bier') {
      reaktionen.push(['Erfrischend!', 'Ist da überhaupt Alkohol drin?!']);
      reaktionen.push(['Lässt sich gut wegtrinken.']);
      reaktionen.push(['Morgen hab ich bestimmt nen Wolf...']);
      reaktionen.push(['Morgen hab ich sicher nen Werwolf...']);
      reaktionen.push(['Ich glaube ich kotze gleich!']);
    } else if (this.gewaehlt.subtyp === 'Wein') {
      reaktionen.push(['Erfrischend!', 'Ist da überhaupt Alkohol drin?!']);
      reaktionen.push(['Lässt sich gut wegtrinken.']);
      reaktionen.push(['Morgen hab ich bestimmt nen Wolf...']);
      reaktionen.push(['Morgen hab ich sicher nen Werwolf...']);
      reaktionen.push(['Ich glaube ich kotze gleich!']);
    } else if (this.gewaehlt.subtyp === 'Schnaps') {
      reaktionen.push(['Geht runter wie Öl!', 'Ist da überhaupt Alkohol drin?!']);
      reaktionen.push(['Lässt sich gut wegtrinken.']);
      reaktionen.push(['Morgen hab ich bestimmt nen Wolf...']);
      reaktionen.push(['Morgen hab ich sicher nen Werwolf...', 'Ouuu das brennt!', '*Hust* *Hust*']);
      reaktionen.push(['Ich glaube ich kotze gleich!', 'Schaaarf!', 'Meine Innereien brennen!', 'Alles brennt!']);
    }
    let stufe = 0;
    if (wert > 10) {
      stufe = 4;
    } else if (wert > 6) {
      stufe = 3;
    } else if (wert > 3) {
      stufe = 2;
    } else if (wert > 1) {
      stufe = 1;
    } else {
      stufe = 0;
    }
    const count = reaktionen[stufe].length;
    const index = Math.floor(Math.random() * count);
    this.reaktion = reaktionen[stufe][index];
    for (let i = 0; i < wert; i++) {
      this.increasePromille();
    }
    setTimeout(() => {
      this.reaktion = '';
    }, 3000);
    this.aktualisiereZechenWerte();
  }

  public increasePromille(): void {
    if (this.charakter.getCurrentWert('Trink-AuP') - 1 >= 0) {
      this.charakter.decreaseBeeinflWert('Trink-AuP', 'manuell');
    }
    this.charakter.calculateGesamtModifikationFuer('Trink-AuP');
    this.ping.emit();
  }
}
