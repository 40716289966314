<h1>Erfahrungsgrad</h1>

<h2>{{limits.name}}</h2>

<select [(ngModel)]="gewaehlteStufe" (ngModelChange)="loadData()">
  <option [value]="stufen.indexOf(stufe)" *ngFor="let stufe of stufen">{{stufe}}</option>
</select>
<div>
  <div>
    <label>Stufe: </label>{{limits.name}}
  </div>
  <div>
    <label>Start-AP: </label>{{limits.startAP}}
  </div>
  <div>
    <label>max. Eigenschaft: </label>{{limits.maxEigenschaft}}
  </div>
  <div>
    <label>max. Fertigkeit: </label>{{limits.maxFertigkeit}}
  </div>
  <div>
    <label>max. Kampftechnik: </label>{{limits.maxKampftechnik}}
  </div>
  <div>
    <label>max. Punkte in Eigenschaften: </label>{{limits.maxPktEigenschaft}}
  </div>
  <div>
    <label>max. Anz. Zauber: </label>{{limits.maxAnzZauber}}
  </div>
  <div>
    <label>max. Fremdzauber: </label>{{limits.maxFremdZauber}}
  </div>
</div>
