<div class="body">
  <div class="horizontalCenter">
    <input type="file" (change)="onFileChanged($event)">
    <button (click)="getMaterial()">reload</button>
    <select [(ngModel)]="matIndex" (ngModelChange)="selectMaterial()">
      <option [value]="material.indexOf(mat)" *ngFor="let mat of material">{{mat.name}}</option>
    </select>
    <button (click)="deleteMaterial()">löschen</button>
  </div>
  <div *ngIf="image != null" class="horizontalCenter">
    <img class="image" alt="bild"  [src]="sanitizer.bypassSecurityTrustUrl(image)"/>
  </div>
  <div *ngIf="image != null" class="horizontalCenter">
    <h3>Name</h3>
  </div>
  <div *ngIf="image != null" class="horizontalCenter">
    <input type="text" [(ngModel)]="name">
  </div>
  <div *ngIf="image != null" class="horizontalCenter">
    <button (click)="addMaterial()">hinzufügen</button>
  </div>

  <div class="horizontalCenter">
    <button (click)="getSchriftstueck()">reload Schriftstücke</button>
    <select [(ngModel)]="schriftIndex" (ngModelChange)="selectSchriftstueck()">
      <option [value]="schriften.indexOf(stck)" *ngFor="let stck of schriften">{{stck.name}}</option>
    </select>
    <button (click)="deleteSchriftstueck()">löschen</button>
  </div>
  <div class="horizontalCenter">
    <div>Name:</div>
    <input type="text" [(ngModel)]="schriftName">
  </div>
  <div class="horizontalCenter">
    <div>Inhalt:</div>
    <input type="text" [(ngModel)]="schriftInhalt">
  </div>
  <div class="horizontalCenter">
    <div class="text">{{schriftInhalt}}</div>
  </div>
  <div class="horizontalCenter">
    <div>Sprache:</div>
    <input type="text" [(ngModel)]="schriftSprache">
  </div>
  <div class="horizontalCenter">
    <div>Schrift:</div>
    <input type="text" [(ngModel)]="schriftSchrift">
  </div>
  <div class="horizontalCenter">
    <div>Textart:</div>
    <input type="text" [(ngModel)]="schriftTextart">
  </div>
  <div *ngIf="schriftInhalt != null" class="horizontalCenter">
    <button (click)="addSchriftstueck()">Schrift hinzufügen</button>
  </div>
</div>

