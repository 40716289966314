import {Injectable, OnDestroy} from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import {Observable} from 'rxjs';
import {IpService} from './ip.service';
import {Nachricht} from '../_classes/comm/nachricht';

@Injectable({
  providedIn: 'root'
})

export class WebsocketService implements OnDestroy {
  connection$: WebSocketSubject<any>;

  constructor(private ipService: IpService) { }

  connect(username: string, type: string): Observable<any> {
    const $username = sessionStorage.getItem('user');
    // const storedIP = sessionStorage.getItem('ip');
    const storedIP = this.ipService.getIp();
    this.connection$ = webSocket({
      url: 'ws://' + storedIP + ':8080/' + type + '/' + username,
      // url: 'ws://' + storedIP + ':8080/ws/' + username,
      deserializer: ({data}) => data,
    });
    console.log('ws ' + type + ' from ' + username + ' connected');
    return this.connection$;
  }

  connectWithoutUsername(type: string): Observable<any> {
    const $username = sessionStorage.getItem('user');
    // const storedIP = sessionStorage.getItem('ip');
    const storedIP = this.ipService.getIp();
    this.connection$ = webSocket({
      url: 'ws://' + storedIP + ':8080/' + type,
      deserializer: ({data}) => data,
    });
    console.log('ws ' + type + ' connected');
    return this.connection$;
  }

  send(data: any): void {
    if (this.connection$) {
      console.log(data);
      this.connection$.next(data);
    } else {
      console.log('Did not send data, unable to open connection');
    }
  }
  sendWithProtocol(sender: string, reciever: string, data: any): void {
    sender = '[' + sender + ']';
    reciever = '[' + reciever + ']';
    if (this.connection$) {
      console.log(data);
      this.connection$.next(sender + reciever + data);
    } else {
      console.log('Did not send data, unable to open connection');
    }
  }
  sendNachricht(nachricht: Nachricht): void {
    if (this.connection$) {
      console.log(nachricht);
      this.connection$.next(JSON.stringify(nachricht));
    } else {
      console.log('Did not send data, unable to open connection');
    }
  }

  closeConnection(): void {
    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
    }
  }

  ngOnDestroy(): void {
    this.closeConnection();
  }


}
