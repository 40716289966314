<div *ngIf="heldin.spezies !== null">
  <h3>Wähle eine Spezies</h3>
  <select [(ngModel)]="$speziesIndex" (ngModelChange)="getSpezies()">
    <option [value]="$speziesMenu.indexOf(spezies)" *ngFor="let spezies of $speziesMenu">{{spezies.art}} - {{spezies.unterArt}} ({{spezies.apWert}} AP)</option>
  </select>
  <div class="infobox">
    <div *ngIf="heldin.spezies.typischeVorteile !== undefined">
      <h3 class="textHead">Vorteile</h3>
      <div *ngIf="heldin.spezies.automatischeVorteile !== undefined">
        <div class="minihead">automatische</div>
        <div *ngFor="let vun of heldin.spezies.automatischeVorteile">{{vun}}</div>
      </div>
      <div *ngIf="heldin.spezies.dringendEmpfohleneVorteile !== undefined">
        <div class="minihead">dringend empfohlen</div>
        <div *ngFor="let vun of heldin.spezies.dringendEmpfohleneVorteile">{{vun}}</div>
      </div>
      <div *ngIf="heldin.spezies.typischeVorteile !== undefined">
        <div class="minihead">typisch</div>
        <div *ngFor="let vun of heldin.spezies.typischeVorteile">{{vun}}</div>
      </div>
      <div *ngIf="heldin.spezies.untypischeVorteile !== undefined">
        <div class="minihead">untypisch</div>
        <div *ngFor="let vun of heldin.spezies.untypischeVorteile">{{vun}}</div>
      </div>
    </div>
    <div *ngIf="heldin.spezies.typischeNachteile !== undefined">
      <h3 class="textHead">Nachteile</h3>
      <div *ngIf="heldin.spezies.automatischeNachteile !== undefined">
        <div class="minihead">automatische</div>
        <div *ngFor="let vun of heldin.spezies.automatischeNachteile">{{vun}}</div>
      </div>
      <div *ngIf="heldin.spezies.dringendEmpfohleneNachteile !== undefined">
        <div class="minihead">dringend empfohlen</div>
        <div *ngFor="let vun of heldin.spezies.dringendEmpfohleneNachteile">{{vun}}</div>
      </div>
      <div *ngIf="heldin.spezies.typischeNachteile !== undefined">
        <div class="minihead">typisch</div>
        <div *ngFor="let vun of heldin.spezies.typischeNachteile">{{vun}}</div>
      </div>
      <div *ngIf="heldin.spezies.untypischeNachteile !== undefined">
        <div class="minihead">untypisch</div>
        <div *ngFor="let vun of heldin.spezies.untypischeNachteile">{{vun}}</div>
      </div>
    </div>
    <div>
      <h3 class="textHead">Grundwerte</h3>
      <div>Geschwindigkeit: {{heldin.spezies.GSGrundwert}}</div>
      <div>Seelenkraft: {{heldin.spezies.SKGrundwert}}</div>
      <div>Zähigkeit: {{heldin.spezies.ZKGrundwert}}</div>
      <div *ngIf="$eigMods.length > 0">
        <h3 class="textHead">Eigenschaftsmodifikatoren</h3>
        <div *ngFor="let mod of $eigMods">
          {{mod}}
        </div>
      </div>
    </div>

  </div>
  <h3 class="textHead">Herkunft & Verbreitung</h3>
  <div class="textblock">
    {{ heldin.spezies.herkunftUndVerbreitung }}
  </div>
  <h3 class="textHead">Körperbau & Aussehen</h3>
  <div class="textblock">
    {{ heldin.spezies.koerperbauUndAussehen }}
  </div>
  <h3 class="textHead">Vermehrung & Alterung</h3>
  <div class="textblock">
    {{ heldin.spezies.vermehrungUndAlterung }}
  </div>
</div>
