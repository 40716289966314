import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DataService} from '../../_services/data.service';
import {HeldenblattCommService} from '../../_services/heldenblatt-comm.service';
import {ObjectFactoryService} from '../../_services/object-factory.service';
import {WebsocketService} from '../../_services/websocket.service';
import {Subject} from 'rxjs';
import {Glossar} from '../../_classes/glossar/glossar';
import {Heldin} from '../../_classes/charakter/heldin';
import {Ruestung} from '../../_classes/ausruestung/ruestung';
import {Nahkampfwaffe} from '../../_classes/ausruestung/nahkampfwaffe';
import {Fernkampfwaffe} from '../../_classes/ausruestung/fernkampfwaffe';
import {Ausruestung} from '../../_classes/charakter/ausruestung';
import {Kombigegenstand} from '../../_classes/kombigegenstand';
import {Allgemein} from '../../_classes/ausruestung/allgemein';
import {Waffe} from '../../_classes/ausruestung/waffe';
import {Gegenstand} from '../../_classes/ausruestung/gegenstand';
import {PersistenceService} from '../../_services/persistence.service';
import {AusruestungSheet} from '../../_interfaces/components/sheet/ausruestungSheet';
import {Zaehlbar} from '../../_classes/ausruestung/zaehlbar';
import {Behaeltnis} from '../../_classes/ausruestung/behaeltnis';
import {StringEvent} from '../../_interfaces/events/string-event';
import {GrpKombiGegenstand} from '../../_classes/grp-kombi-gegenstand';

// import {Waffe} from '../../_interfaces/waffe';

@Component({
  selector: 'app-ausruestung',
  templateUrl: './ausruestung.component.html',
  styleUrls: ['./ausruestung.component.sass']
})
export class AusruestungComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Output() reload: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  public localData: AusruestungSheet = {
    gewaehlterTyp: '',
    gewaehlterSubtyp: '',
    verfuegbareSubtypen: [],
    verfuegbareItems: [],
    gewaehltesItem: new GrpKombiGegenstand(),
    realerItemName: '',
    itemGewicht: 0,
    kategorien: [],
    erstelleItemMenu: false
  };

  itemNeu: GrpKombiGegenstand = new GrpKombiGegenstand();
  itemArten: string[] = [];
  chosenItemArt: string;
  itemSubArten: string[] = [];
  chosenItemSubArt: string;
  itemList: string[] = [];
  chosenItem: string;
  itemUmbenennen = false;
  itemName: string;
  itemId: number;

  itemAnzahl = 1;
  itemTrageort = 'Körper';

  itemZustand = 0;

  itemAnzeige = -1;

  mengenSubTypen: string[] = ['Alchemiezutat', 'Baumaterial', 'Munition', 'Währung'];

  public singulaereItemTypen: string[] = ['Behältnis', 'Nahkampfwaffe', 'Fernkampfwaffe', 'Parierwaffe', 'Rüstung'];

  zustaende = ['gut', 'leicht beschädigt', 'beschädigt', 'schwer beschädigt', 'zerstört'];

  trageorte: string[] = [];
  behaeltnisTrageorte = new Map<string, string[]>();

  seile: number[] = [];
  schneidLaenge = 0;

  alleItems: GrpKombiGegenstand[] = [];
  itemWaehrung: GrpKombiGegenstand[] = [];
  itemsAllgemein: GrpKombiGegenstand[] = [];
  itemsLeuchtmittel: GrpKombiGegenstand[] = [];
  itemBehaeltnis: GrpKombiGegenstand[] = [];
  itemAlkohol: GrpKombiGegenstand[] = [];
  itemsSeil: GrpKombiGegenstand[] = [];
  itemsNahkampfwaffe: GrpKombiGegenstand[] = [];
  itemsFernkampfwaffe: GrpKombiGegenstand[] = [];
  itemsMunition: GrpKombiGegenstand[] = [];
  itemsRuestung: GrpKombiGegenstand[] = [];

  gewichtAmKoerper = 0;

  transferBereit = false;

  transferIds: number[] = [];

  transferPipeEingang: GrpKombiGegenstand;
  transferPipeAusr: Ausruestung;
  transferPipeAusgang: GrpKombiGegenstand;
  transferOrte: string[] = [];
  gewaehlterTransferOrt: string;

  aktuellerBeaheltnisName = '';

  zuLoeschen: GrpKombiGegenstand;
  deleteIds: number[] = [];

  popupMessage = '';
  popupType = 0;

  allesAusgeklappt = false;

  public stufen = ['', 'I', 'II', 'III', 'IV'];

  constructor(public dataService: DataService,
              private sharedService: HeldenblattCommService,
              private chatService: WebsocketService,
              private persistence: PersistenceService) {
  }

  ngOnInit(): void {
    this.initialisiereGegenstaende();
    const stored: AusruestungSheet = this.persistence.retrieveComponent('ausrüstung');
    // console.log(stored);
    if (stored != null) {
      this.localData = stored;
      this.checkAusgeklapptes();
    } else {
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
      this.localData.kategorien.push(false);
    }
    this.initialisiereAusgeruesteteItems();
  }

  initialisiereGegenstaende(): void {
    this.itemWaehrung = [];
    this.itemBehaeltnis = [];
    this.itemsAllgemein = [];
    this.itemsLeuchtmittel = [];
    this.itemAlkohol = [];
    this.itemsSeil = [];
    this.itemsNahkampfwaffe = [];
    this.itemsFernkampfwaffe = [];
    this.itemsMunition = [];
    this.itemsRuestung = [];

    this.trageorte = [];
    this.trageorte.push('Körper');
    this.trageorte.push('abgelegt');
    this.seile = [];
    this.behaeltnisTrageorte = new Map<string, string[]>();
    this.glossar.ausruestung.forEach(item => {
      this.charakter.ausruestung.forEach(ausr => {
        if (!this.itemArten.includes(item.type)) {
          this.itemArten.push(item.type);
        }
        if (item.id === ausr.itemid) {
          this.ordneItemZu(item, ausr);
        }
      });
    });

    /*
    this.charakter.ausruestung.forEach(ausr => {
      this.glossar.ausruestung.forEach(item => {
        if (!this.itemArten.includes(item.type)) {
          this.itemArten.push(item.type);
        }
        if (item.id === ausr.itemid) {
          this.ordneItemZu(item, ausr);
        }
      });
    });
     */

    this.sortItemListe(this.itemWaehrung);
    this.sortItemListe(this.itemsAllgemein);
    this.sortItemListe(this.itemsLeuchtmittel);
    this.sortItemListe(this.itemBehaeltnis);
    this.sortItemListe(this.itemAlkohol);
    this.sortItemListe(this.itemsSeil);
    this.sortItemListe(this.itemsNahkampfwaffe);
    this.sortItemListe(this.itemsFernkampfwaffe);
    this.sortItemListe(this.itemsRuestung);
    this.sortItemListe(this.itemsMunition);
    this.itemArten.sort((a, b) => (a < b ? -1 : 1));
    this.chosenItemArt = this.itemArten[0];
    this.aendereItemArt();

    /*
    this.sortItemWaehrung();

    this.itemsAllgemein.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.itemsAllgemein.sort((a, b) => (a.trageort < b.trageort ? -1 : 1));

    this.itemBehaeltnis.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.itemBehaeltnis.sort((a, b) => (a.trageort < b.trageort ? -1 : 1));
    */

    this.itemBehaeltnis.forEach(behaeltnis => {
      const orte: string[] = [];
      this.trageorte.forEach(ort => {
        if (ort !== behaeltnis.name) {
          orte.push(ort);
        }
      });
      this.behaeltnisTrageorte.set(behaeltnis.name, orte);
    });

    this.itemsSeil.forEach(seil => {
      this.seile.push(seil.ausruestungen[0].zustand);
    });
    this.berechneGewicht();
  }

  public itemInfos(id: number): void {
    if (this.itemAnzeige === id) {
      this.itemAnzeige = -1;
    } else {
      this.itemAnzeige = id;
    }
  }

  checkAusgeklapptes(): void {
    this.allesAusgeklappt = true;
    this.localData.kategorien.forEach(kat => {
      if (!kat) {
        this.allesAusgeklappt = false;
      }
    });
  }

  aendereItemArt(): void {
    this.itemSubArten = [];
    this.glossar.ausruestung.forEach(item => {
      if (item.type === this.chosenItemArt) {
        if (!this.itemSubArten.includes(item.subtyp) && item.subtyp !== '' && item.subtyp != null) {
          this.itemSubArten.push(item.subtyp);
        }
      }
    });
    if (this.itemSubArten.length === 0) {
      this.itemSubArten.push('-');
    }
    this.itemSubArten.sort((a, b) => (a < b ? -1 : 1));
    if (this.itemSubArten.length > 0) {
      this.chosenItemSubArt = this.itemSubArten[0];
    } else {
      this.chosenItemSubArt = '-';
    }
    this.aendereItemSubArt();
  }

  aendereItemSubArt(): void {
    console.log(this.chosenItemSubArt);
    this.itemList = [];
    this.glossar.ausruestung.forEach(item => {
      if (item.type === this.chosenItemArt) {
        if (this.chosenItemSubArt !== '-' && item.subtyp === this.chosenItemSubArt) {
          if (!this.itemList.includes(item.name)) {
            if (item.name !== 'Körper' && item.name !== 'Fäuste') {
              this.itemList.push(item.name);
            }
          }
        } else if (this.chosenItemSubArt === '-') {
          if (!this.itemList.includes(item.name)) {
            if (item.name !== 'Körper' && item.name !== 'Fäuste') {
              this.itemList.push(item.name);
            }
          }
        }
      }
    });
    this.itemList.sort((a, b) => (a < b ? -1 : 1));
    this.chosenItem = this.itemList[0];
    this.aendereItem();
  }
  aendereItem(): void {
    this.itemName = this.chosenItem;
    this.glossar.ausruestung.forEach(item => {
      if (item.name === this.itemName) {
        this.itemId = item.id;
      }
    });
  }

  ordneItemZu(item: Gegenstand, ausr: Ausruestung): void {
    const neuerGegenstand: GrpKombiGegenstand = new GrpKombiGegenstand();
    neuerGegenstand.abgelegt = false;

    // finde Bild des Trageortes
    if (ausr.trageort === 'Körper') {
      neuerGegenstand.trageortBild = 'Körper' + this.charakter.geschlecht;
    } else {
      this.charakter.ausruestung.forEach(charItem => {
        if (charItem.name === ausr.trageort) {
          this.glossar.ausruestung.forEach(gi => {
            if (gi.id === charItem.itemid) {
              neuerGegenstand.trageortBild = gi.dateiname;
            }
          });
        }
      });
    }

    // prüfe ob Item schon vorhanden
    let neuesItem = true;
    if (item.type === 'Allgemein' && item.subtyp === 'Währung') {
      // Währung
      this.itemWaehrung.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && vorhanden.zustand === ausr.zustand
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          const list: Ausruestung[] = [];
          this.charakter.ausruestung.forEach(charItem => {
            if (charItem.charItemId !== ausr.charItemId) {
              list.push(charItem);
            }
          });
          this.charakter.ausruestung = [];
          list.forEach(charItem => {
            this.charakter.ausruestung.push(charItem);
          });
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemWaehrung.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    } else if (item.type === 'Allgemein' && item.subtyp !== 'Währung' && item.subtyp !== 'Munition') {
      // Allgemeine Gegenstände
      this.itemsAllgemein.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && vorhanden.zustand === ausr.zustand
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;

          /*
          // lösche Item aus Charakter Items
          const list: Ausruestung[] = [];
          this.charakter.ausruestung.forEach(charItem => {
            if (charItem.charItemId !== ausr.charItemId) {
              list.push(charItem);
            }
          });
          this.charakter.ausruestung = [];
          list.forEach(charItem => {
            this.charakter.ausruestung.push(charItem);
          });
          */
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemsAllgemein.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }

    } else if (item.type === 'Leuchtmittel') {
      // Leuchtmittel
      this.itemsLeuchtmittel.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && Math.round((vorhanden.zustand) * 10) / 10 === Math.round((ausr.zustand) * 10) / 10
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemsLeuchtmittel.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }

    } else if (item.type === 'Behältnis') {
      if (!this.trageorte.includes(ausr.name)) {
        this.trageorte.push(ausr.name);
      }
      if (item.name !== 'Körper') {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemBehaeltnis.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    } else if (item.type === 'Alkohol') {
      this.itemAlkohol.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && vorhanden.zustand === ausr.zustand
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemAlkohol.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    } else if (item.type === 'Seile') {
      this.itemsSeil.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && Math.round((vorhanden.zustand) * 10) / 10 === Math.round((ausr.zustand) * 10) / 10
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemsSeil.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    } else if (item.type === 'Nahkampfwaffe' && item.name !== 'Fäuste') {
      this.itemsNahkampfwaffe.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && Math.round((vorhanden.zustand) * 10) / 10 === Math.round((ausr.zustand) * 10) / 10
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemsNahkampfwaffe.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    } else if (item.type === 'Fernkampfwaffe') {
      this.itemsFernkampfwaffe.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && Math.round((vorhanden.zustand) * 10) / 10 === Math.round((ausr.zustand) * 10) / 10
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemsFernkampfwaffe.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    } else if (item.type === 'Allgemein' && item.subtyp === 'Munition') {
      // Munition
      this.itemsMunition.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && vorhanden.zustand === ausr.zustand
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          const list: Ausruestung[] = [];
          this.charakter.ausruestung.forEach(charItem => {
            if (charItem.charItemId !== ausr.charItemId) {
              list.push(charItem);
            }
          });
          this.charakter.ausruestung = [];
          list.forEach(charItem => {
            this.charakter.ausruestung.push(charItem);
          });
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemsMunition.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    } else if (item.type === 'Rüstung') {
      this.itemsRuestung.forEach(vorhanden => {
        if (vorhanden.name === ausr.name
          && vorhanden.notiz === ausr.notiz
          && Math.round((vorhanden.zustand) * 10) / 10 === Math.round((ausr.zustand) * 10) / 10
          && vorhanden.trageort === ausr.trageort) {
          neuesItem = false;
          if (vorhanden.mengenItem) {
            vorhanden.ausruestungen[0].anzahl += ausr.anzahl;
            vorhanden.anzahl += ausr.anzahl;
          } else {
            vorhanden.addAusruestung(ausr, false);
          }
        }
      });
      if (neuesItem) {
        neuerGegenstand.gegenstand = item;
        neuerGegenstand.addAusruestung(ausr, this.mengenSubTypen.includes(item.subtyp));
        this.itemsRuestung.push(neuerGegenstand);
        this.alleItems.push(neuerGegenstand);
      }
    }
    /*
    if (neuerGegenstand.trageort === 'abgelegt') {
      neuerGegenstand.ausruestungen.forEach(ausruestung => {
        ausr.ausgeruestet = false;
      });
    } else {
      neuerGegenstand.ausruestungen.forEach(ausruestung => {
        ausr.ausgeruestet = true;
      });
    }
     */
  }

  sortItemWaehrung(): void {
    this.itemWaehrung.sort((a, b) => {
      let aName: string = a.gegenstand.name;
      let bName: string = b.gegenstand.name;
      const waehrungen: string[] = ['Dukat', 'Silbertaler', 'Heller', 'Kreuzer'];
      if (waehrungen.includes(aName)) {
        aName = waehrungen.indexOf(aName) + aName;
      }
      if (waehrungen.includes(bName)) {
        bName = waehrungen.indexOf(bName) + bName;
      }
      if (aName < bName) {
        return -1;
      } else {
        return 1;
      }
    });
    this.itemWaehrung.sort((a, b) => (a.trageort < b.trageort ? -1 : 1));
  }

  sortItemListe(liste: GrpKombiGegenstand[]): void {
    liste.sort((a, b) => {
      let aName: string = a.gegenstand.name;
      let bName: string = b.gegenstand.name;
      const waehrungen: string[] = ['Dukat', 'Silbertaler', 'Heller', 'Kreuzer'];
      if (waehrungen.includes(aName)) {
        aName = waehrungen.indexOf(aName) + aName;
      }
      if (waehrungen.includes(bName)) {
        bName = waehrungen.indexOf(bName) + bName;
      }
      if (aName < bName) {
        return -1;
      } else {
        return 1;
      }
    });
    liste.sort((a, b) => (a.trageort < b.trageort ? -1 : 1));
  }

  initialisiereAusgeruesteteItems(): void {
    this.alleItems.forEach(item => {
      this.aendereTrageort(item);
    });
  }

  increaseMengenItem(item: GrpKombiGegenstand): void {
    item.anzahl++;
    this.charakter.ausruestung.forEach(ausr => {
      if (ausr.charItemId === item.ausruestungen[0].charItemId) {
        ausr.anzahl++;
      }
    });
    if (item.gegenstand.subtyp === 'Währung') {
      this.aktualisiereGeld();
    }
    this.berechneGewicht();
  }

  increaseItem(item: GrpKombiGegenstand): void {
    this.dataService.getNewItemId().subscribe((data: string) => {
      // erstelle neues Item
      const id = Number(data);
      const neuesItem: Ausruestung = new Ausruestung();
      neuesItem.copyGrpItem(id, item);
      item.addAusruestung(neuesItem, false);
      this.charakter.ausruestung.push(neuesItem);
      // this.initialisiereGegenstaende();
      this.berechneGewicht();
    });
  }

  decreaseMengenItem(item: GrpKombiGegenstand): void {
    if (item.anzahl > 0) {
      item.anzahl--;
      this.charakter.ausruestung.forEach(ausr => {
        if (ausr.charItemId === item.ausruestungen[0].charItemId) {
          ausr.anzahl--;
        }
      });
      if (item.gegenstand.subtyp === 'Währung') {
        this.aktualisiereGeld();
      }
    }
    this.berechneGewicht();
  }

  decreaseItem(item: GrpKombiGegenstand): void {
    if (item.anzahl > 1) {
      const charItemList: Ausruestung[] = [];
      const toRemove: Ausruestung = item.ausruestungen.pop();
      this.charakter.ausruestung.forEach(ausr => {
        if (ausr.charItemId !== toRemove.charItemId) {
          charItemList.push(ausr);
        }
      });
      this.charakter.ausruestung = [];
      charItemList.forEach(ausr => {
        this.charakter.ausruestung.push(ausr);
      });
      item.anzahl--;
    }
    this.berechneGewicht();
  }

  public aktualisiereGeld(): void {
    this.sharedService.aktualisiereGeld();
  }

  berechneGewicht(): void {
    this.itemBehaeltnis.forEach(behaelter => {
      behaelter.behGewicht = 0;
      behaelter.behAnz = 0;
      behaelter.ueberlast = false;
      if ((behaelter.gegenstand as Behaeltnis).maxInhalt > 0) {
        behaelter.behReinheit = true;
      } else {
        behaelter.behReinheit = false;
      }
    });
    this.gewichtAmKoerper = 0;
    this.berechneGewichtFuer(this.itemWaehrung);
    this.berechneGewichtFuer(this.itemsAllgemein);
    this.berechneGewichtFuer(this.itemsLeuchtmittel);
    this.berechneGewichtFuer(this.itemAlkohol);
    this.berechneGewichtFuer(this.itemsSeil);
    this.berechneGewichtFuer(this.itemsNahkampfwaffe);
    this.berechneGewichtFuer(this.itemsFernkampfwaffe);
    this.berechneGewichtFuer(this.itemsMunition);
    this.berechneGewichtFuer(this.itemsRuestung);

    this.berechneGewichtFuer(this.itemBehaeltnis);
    this.pruefeUeberlast();
  }

  berechneGewichtFuer(items: GrpKombiGegenstand[]): void {
    items.forEach(item => {
      let gewicht = (item.gegenstand as Zaehlbar).gewicht * item.anzahl;
      if (item.gegenstand.type === 'Seile') {
        console.log('Seile');
        console.log('gewicht vorher: ' + gewicht);
        gewicht *= item.ausruestungen[0].zustand;
        console.log('gewicht hinterher: ' + gewicht);
      }
      if (item.trageort === 'Körper') {
        this.gewichtAmKoerper += gewicht;
      } else {
        this.itemBehaeltnis.forEach(behaelter => {
          if (behaelter.name === item.trageort) {
            behaelter.behGewicht += gewicht;
            if ((behaelter.gegenstand as Behaeltnis).inhaltTyp !== item.gegenstand.subtyp) {
              behaelter.behReinheit = false;
            } else {
              behaelter.behAnz += item.anzahl;
            }

            if (Number(behaelter.behGewicht.toFixed(4)) > (behaelter.gegenstand as Behaeltnis).maxTragkraft
              || (behaelter.behAnz > (behaelter.gegenstand as Behaeltnis).maxInhalt && behaelter.behReinheit)) {
              console.log(behaelter);
              behaelter.ueberlast = true;
            }
            this.behaelterRekursion(gewicht, behaelter);
          }
        });
      }
    });
  }

  behaelterRekursion(gewichtBisher: number, behaelter: GrpKombiGegenstand): void {
    if (behaelter.trageort === 'Körper') {
      this.gewichtAmKoerper += gewichtBisher;
    } else {
      this.itemBehaeltnis.forEach(parent => {
        if (parent.name === behaelter.trageort) {
          parent.behGewicht += gewichtBisher;
          if (parent.behGewicht > (parent.gegenstand as Behaeltnis).maxTragkraft
            || parent.behAnz > (parent.gegenstand as Behaeltnis).maxInhalt) {
            parent.ueberlast = true;
          }
          this.behaelterRekursion(gewichtBisher, parent);
        }
      });
    }
  }

  pruefeUeberlast(): void {
    this.itemBehaeltnis.forEach(behaelter => {
      if (behaelter.ueberlast) {
        const behName = behaelter.name;
        this.meldeUeberlastAnGM(behName, this.getAlleItemsInBehaeltnis(behName));
      }
    });
  }

  getAlleItemsInBehaeltnis(behaeltnisName: string): number[] {
    const ids: number[] = [];
    this.charakter.ausruestung.forEach(item => {
      if (item.trageort === behaeltnisName) {
        ids.push(item.charItemId);
      }
    });
    return ids;
  }

  aendereNotiz(item: GrpKombiGegenstand): void {
    item.bearbeiteNotiz = false;
    item.ausruestungen.forEach(ausr => {
      ausr.notiz = item.notiz;
    });
    this.initialisiereGegenstaende();
  }

  aendereTrageort(item: GrpKombiGegenstand): void {
    if (item.trageort === 'abgelegt') {
      this.checkChildItems(item, false);
    } else {
      if (item.gegenstand.type === 'Behältnis') {
        this.checkChildItems(item, true);
      }
      const ausgeruestet = this.checkParentItems(item);
      item.ausruestungen.forEach(ausr => {
        ausr.ausgeruestet = ausgeruestet;
      });
    }
    item.ausruestungen.forEach(ausr => {
      ausr.trageort = item.trageort;
    });
    this.initialisiereGegenstaende();
  }

  checkParentItems(item: GrpKombiGegenstand): boolean {
    console.log('checkParentItems(' + item.ausruestungen[0].name + ')');
    if (item.trageort === 'Körper') {
      console.log('trageort: "körper"');
      return true;
    } else if (item.trageort === 'abgelegt') {
      console.log('trageort: "abgelegt"');
      return false;
    } else {
      console.log('trageort: "' + item.trageort + '"');
      let parentValue = true;
      this.itemBehaeltnis.forEach(behaelter => {
        if (behaelter.ausruestungen[0].name === item.trageort) {
          console.log('check "' + item.trageort + '"');
          parentValue = this.checkParentItems(behaelter);
        }
      });
      console.log('return: ' + parentValue);
      return parentValue;
    }
  }

  checkChildItems(item: GrpKombiGegenstand, ausgeruestet): void {
    item.ausruestungen.forEach(ausr => {
      ausr.ausgeruestet = ausgeruestet;
      console.log(ausr);
    });
    console.log(item);
    if (item.gegenstand.type === 'Behältnis') {
      this.alleItems.forEach(subItem => {
        if (subItem.ausruestungen[0].trageort === item.ausruestungen[0].name) {
          subItem.ausruestungen.forEach(ausr => {
            ausr.ausgeruestet = ausgeruestet;
          });
          this.checkChildItems(subItem, ausgeruestet);
        }
      });
    }
  }

  aendereName(item: GrpKombiGegenstand): void {
    item.bearbeiteName = false;
    let aenderungErlaubt = true;
    this.itemBehaeltnis.forEach(behaeltnis => {
      if (behaeltnis !== item && behaeltnis.name === item.name) {
        aenderungErlaubt = false;
      }
    });
    if (aenderungErlaubt) {
      if (item.gegenstand.type === 'Behältnis') {
        this.charakter.ausruestung.forEach(ausr => {
          if (ausr.trageort === this.aktuellerBeaheltnisName) {
            ausr.trageort = item.name;
          }
        });
      }
      item.ausruestungen.forEach(itemAusr => {
        itemAusr.name = item.name;
      });
      this.initialisiereGegenstaende();
    } else {
      item.name = this.aktuellerBeaheltnisName;
    }

  }

  leiteTransferEin(item: GrpKombiGegenstand): void {
    this.transferIds = [];
    item.ausruestungen.forEach(ausr => {
      this.transferIds.push(ausr.charItemId);
    });
    if (item.anzahl > 1 && this.behaeltnisTrageorte.size > 0) {
      this.transferBereit = true;
      this.transferPipeEingang = item;
      this.transferPipeAusr = item.ausruestungen[0];
      this.transferOrte = [];
      this.itemBehaeltnis.forEach(behalter => {
        if (behalter.name !== this.transferPipeEingang.trageort) {
          this.transferOrte.push(behalter.name);
        }
      });
      if (item.trageort !== 'Körper') {
        this.transferOrte.push('Körper');
      }
      this.gewaehlterTransferOrt = this.transferOrte[0];

      this.getTransferPipelineAusgang();
    }
  }

  getTransferPipelineAusgang(): void {
    this.transferPipeAusgang = undefined;
    const itemAnNeuemOrt: GrpKombiGegenstand = new GrpKombiGegenstand();
    itemAnNeuemOrt.copy(this.transferPipeEingang);
    itemAnNeuemOrt.trageort = this.gewaehlterTransferOrt;
    this.alleItems.forEach(vorhanden => {
      if (itemAnNeuemOrt.compare(vorhanden)) {
        this.transferPipeAusgang = vorhanden;
      }
    });
  }

  stoppeTransfer(): void {
    this.transferPipeAusgang = undefined;
    this.transferPipeEingang = new GrpKombiGegenstand();
    this.transferPipeAusr = new Ausruestung();
    this.transferOrte = [];
    this.gewaehlterTransferOrt = '';
    // this.transferPipeAusgang = this.getBehaeltnis(this.gewaehlterTransferOrt);
    this.transferBereit = false;
  }

  /*andereTransferPipe(): void {
    this.transferPipeAusgang = this.getBehaeltnis(this.gewaehlterTransferOrt);
  }*/

  getBehaeltnis(trageort: string): GrpKombiGegenstand {
    let gesucht: GrpKombiGegenstand;
    this.itemBehaeltnis.forEach(behaelter => {
      if (behaelter.name === trageort) {
        gesucht = behaelter;
      }
    });
    return gesucht;
  }

  transferiereMengenItem(): void {
    this.transferPipeAusr.anzahl--;
    this.transferBereit = false;
    this.dataService.getNewItemId().subscribe((data: string) => {
      // erstelle neues Item
      const id = Number(data);
      const neuesItem: Ausruestung = new Ausruestung();
      neuesItem.copy(this.transferPipeAusr);
      neuesItem.anzahl = 1;
      neuesItem.charItemId = id;
      neuesItem.trageort = this.gewaehlterTransferOrt;
      this.charakter.ausruestung.push(neuesItem);
      this.transferBereit = true;
      this.initialisiereGegenstaende();
      if (this.transferPipeAusr.anzahl < 1) {
        this.stoppeTransfer();
      }
    });
  }

  transferiere(): void {
    const id = this.transferIds.pop();
    console.log('transferiere()');
    this.transferPipeEingang.anzahl --;
    this.charakter.ausruestung.forEach(ausr => {
      const idA: number = ausr.charItemId;
      if (idA === id) {
        ausr.trageort = this.gewaehlterTransferOrt;
      }
    });
    console.log(this.transferPipeEingang.anzahl);
    if (this.transferIds.length < 1) {
      this.stoppeTransfer();
    }
    this.initialisiereGegenstaende();
  }

  trash(item: GrpKombiGegenstand): void {
    this.popupMessage = 'Soll der Gegenstand "' + item.name + '" am Trageort "' + item.trageort + '" wirklich gelöscht werden?';
    this.deleteIds = [];
    item.ausruestungen.forEach(ausr => {
      this.deleteIds.push(ausr.charItemId);
    });
    this.zuLoeschen = item;
  }

  loeschenAbbrechen(): void {
    this.zuLoeschen = undefined;
    this.deleteIds = [];
    this.popupMessage = '';
  }

  aendereBrenndauer(): void {
    this.initialisiereGegenstaende();
    // ToDo bessere performance
    this.itemsLeuchtmittel.forEach(item => {
      if (item.trageort === 'abgelegt') {
        item.ausruestungen.forEach(ausr => {
          ausr.ausgeruestet = false;
        });
      }
      const ausgeruestet = this.checkParentItems(item);
      item.ausruestungen.forEach(ausr => {
        ausr.ausgeruestet = ausgeruestet;
      });
    });
  }

  aendereZustand(): void {
    this.initialisiereGegenstaende();
  }

  initiereSchnitt(item: GrpKombiGegenstand): void {
    this.itemsSeil.forEach(seil => {
      seil.erweiterteAnzeige = false;
    });
    item.erweiterteAnzeige = true;
    this.schneidLaenge = Math.round((item.ausruestungen[0].zustand / 2) * 10) / 10;
  }

  schnitt(item: GrpKombiGegenstand): void {
    item.erweiterteAnzeige = false;
    if (item.ausruestungen[0].zustand - this.schneidLaenge !== 0 && this.schneidLaenge !== 0) {
      this.dataService.getNewItemId().subscribe((data: string) => {
        // erstelle neues Item
        const id = Number(data);
        const neuesItem: Ausruestung = new Ausruestung();
        neuesItem.copyGrpItem(id, item);
        neuesItem.zustand = item.ausruestungen[0].zustand - this.schneidLaenge;
        neuesItem.ausgeruestet = item.ausruestungen[0].ausgeruestet;
        item.ausruestungen[0].zustand = this.schneidLaenge;
        this.charakter.ausruestung.push(neuesItem);
        this.initialisiereGegenstaende();
        // this.initialisiereAusgeruesteteItems();
        // this.berechneGewicht();
      });
    }
  }

  loescheGegenstand(): void {
    /*
    const liste: Ausruestung[] = [];
    this.zuLoeschen.ausruestungen.forEach(del => {
      this.charakter.ausruestung.forEach(ausr => {
        if (ausr.charItemId !== del.charItemId) {
          liste.push(ausr);
        }
      });
    });
    this.charakter.ausruestung = [];
    liste.forEach(item => {
      this.charakter.ausruestung.push(item);
    });
     */
    const liste: Ausruestung[] = [];
    this.charakter.ausruestung.forEach(ausr => {
      if (!this.deleteIds.includes(ausr.charItemId)) {
        liste.push(ausr);
      }
    });
    this.charakter.ausruestung = [];
    liste.forEach(item => {
      this.charakter.ausruestung.push(item);
    });
    this.popupMessage = '';
    if (this.zuLoeschen.gegenstand.subtyp === 'Währung') {
      this.aktualisiereGeld();
    }
    this.initialisiereGegenstaende();
  }

  initiereItemErstellung(): void {
    this.localData.erstelleItemMenu = true;
    this.persistence.storeComponent('ausrüstung', this.localData);
    console.log(this.glossar.ausruestung);
  }

  brecheItemErstellungAb(): void {
    this.localData.erstelleItemMenu = false;
    this.persistence.storeComponent('ausrüstung', this.localData);
  }

  toggleMenu(index: number): void {
    this.localData.kategorien[index] = !this.localData.kategorien[index];
    this.persistence.storeComponent('ausrüstung', this.localData);
    this.checkAusgeklapptes();
  }

  klappeAllesAus(): void {
    for (let i = 0; i < this.localData.kategorien.length; i++) {
      this.localData.kategorien[i] = true;
    }
    this.persistence.storeComponent('ausrüstung', this.localData);
    this.allesAusgeklappt = true;
  }

  klappeAllesEin(): void {
    for (let i = 0; i < this.localData.kategorien.length; i++) {
      this.localData.kategorien[i] = false;
    }
    this.persistence.storeComponent('ausrüstung', this.localData);
    this.allesAusgeklappt = false;
  }

  meldeUeberlastAnGM(behaelterName: string, inhaltIDs: number[]): void {
    console.log('ToDo: melde ' + behaelterName + ', Inhalt: ' + inhaltIDs + ' an GM');
  }

  itemHinzufuegen(): void {
    for (let i = 0; i < this.itemAnzahl; i++) {
      this.dataService.getNewItemId().subscribe((data: string) => {
        // erstelle neues Item
        const ausruestung: Ausruestung = new Ausruestung();
        ausruestung.name = this.itemName;
        ausruestung.itemid = this.itemId;
        ausruestung.zustand = this.itemZustand;
        ausruestung.trageort = this.itemTrageort;
        ausruestung.charItemId = Number(data);
        ausruestung.ausgeruestet = true;
        ausruestung.mengenItem = this.mengenSubTypen.includes(this.chosenItemSubArt);
        if (ausruestung.mengenItem) {
          ausruestung.anzahl = this.itemAnzahl;
          i = this.itemAnzahl;
        }
        this.charakter.ausruestung.push(ausruestung);
        this.brecheItemErstellungAb();
        this.initialisiereGegenstaende();
      });
    }
    this.itemAnzahl = 1;
  }

}
