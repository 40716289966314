<div class="zeile">
  <div class="spalte1" (click)="alertParent()">{{ talentItem$.name }}</div>
  <div class="attribute" *ngIf="!$attribute" (click)="changeAttributeView()">
    <div class="spalte2a">{{getAttributeWert(talentItem$.probe[0], talentItem$)}}</div>
    <div class="spalte2b">{{getAttributeWert(talentItem$.probe[1], talentItem$)}}</div>
    <div class="spalte2c">{{getAttributeWert(talentItem$.probe[2], talentItem$)}}</div>
  </div>
  <div class="attribute" *ngIf="$attribute" (click)="changeAttributeView()">
    <div class="spalte2a">{{ talentItem$.probe[0] }}</div>
    <div class="spalte2b">{{ talentItem$.probe[1] }}</div>
    <div class="spalte2c">{{ talentItem$.probe[2] }}</div>
  </div>
  <div class="spalte56">
    {{ talentItem$.aktFW }}
  </div>
  <div class="spalte5">
    <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseTalentMod(talentItem$)"/>
    <div class="fw">
      <label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
        <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
        <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
      </label>
    </div>
    <img src="../../../../assets/images/button_s_pos.png" (click)="increaseTalentMod(talentItem$)"/>
  </div>
  <div class="spalte6">{{ talentItem$.routine }}</div>
  <div class="spalte3">{{ talentItem$.be }}</div>
  <div *ngIf="talentItem$.art!=='wissen' && talentItem$.art!=='handwerk'" class="vl"></div>
</div>
