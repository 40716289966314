export class Schriftstueck {
  name: string;
  inhalt: string;
  sprache: string;
  schrift: string;
  textart: string;

  copy(other: Schriftstueck): void {
    this.name = other.name;
    this.inhalt = other.inhalt;
    this.sprache = other.sprache;
    this.schrift = other.schrift;
    this.textart = other.textart;
  }
}

