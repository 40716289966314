export class Gsprachen {
  name: string;
  dialekte: string;
  schrift: string;
  anmerkung: string;

  public copy(other: Gsprachen): void {
    console.log('copy ...');
    this.name = other.name;
    this.dialekte = other.dialekte;
    this.schrift = other.schrift;
    this.anmerkung = other.anmerkung;
  }
}
