<div *ngIf="heldin !== undefined">
  <h3>Wähle Zauber, Rituale und Zaubertricks</h3>
  <select [(ngModel)]="$zauberIndex" (ngModelChange)="getZauber()">
    <option [value]="$alleZauber.indexOf(zauber)" *ngFor="let zauber of $alleZauber">{{ zauber.name }} ({{zauber.verbreitung}})</option>
  </select>
  <div *ngIf="$tradition != undefined">
    deine Tradition: {{ $tradition }}
  </div>
  <div *ngIf="$tradition != undefined">
    <img class="tradition" src="../../../../assets/images/traditionen/{{$tradition}}.JPG"/>
  </div>
  <div>
    Zauber: {{ $zauberAnz }} / {{ maxAnzahlZauber }}
  </div>
  <div>
    Fremdzauber: {{ $fremdZauberAnz }} / {{ maxAnzahlFremdZauber }}
  </div>
  <div class="vorUndNachteilBox" *ngIf="$zauber !== undefined">
    <div class="zeile2">
      <label>
        <b>
          <label *ngIf="$zauber.ritual">Ritual: </label>
          <label *ngIf="!$zauber.ritual">Zauber: </label>
        </b>
        {{$zauber.name}}
      </label>
      <label><b>Probe:</b> {{$zauber.probe}} <label *ngIf="$zauber.probenMod !== ''"> (+{{$zauber.probenMod}})</label></label>
    </div>
    <div class="zeile2" style="text-align: justify">
      {{$zauber.beschreibung}}
    </div>
    <div class="zeile2">
      <label>
        <b>
          <label *ngIf="$zauber.ritual">Ritualdauer: </label>
          <label *ngIf="!$zauber.ritual">Zauberdauer: </label>
        </b>
        {{$zauber.zauberDauer}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>AsP-Kosten:</b> {{$zauber.aspKosten}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Reichweite</b> {{$zauber.reichweite}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Wirkungsdauer:</b> {{$zauber.wirkungsDauer}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Zielkategorie:</b> {{$zauber.zielKathegorie}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Merkmal:</b> {{$zauber.merkmal}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Verbreitung:</b> {{$zauber.verbreitung}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Steigerungsfaktor:</b> {{$zauber.steigerungskosten}}
      </label>
    </div>
    <div class="zeile2">
      <label style="text-align: justify">
        <b>Wirkung: </b>
        {{$zauber.wirkung}}
      </label>
    </div>
    <div *ngIf="$zauber.wirkungQS.length > 0" class="zeile">
      <b>Wirkung je nach erreichter QS:</b>
    </div>
    <div *ngFor="let stufe of $zauber.wirkungQS" class="zeile">
      {{stufe}}
    </div>
    <div *ngIf="$zauber.erweiterungen.length > 0" class="zeile">
      <b>Spätere mögliche Erweiterungen:</b>
    </div>
    <div *ngFor="let erw of $zauber.erweiterungen" class="zeile2" style="text-align: justify">
      {{erw}}
    </div>
  </div>

  <input type="button" class="button" value="Zauber hinzufügen" (click)="addZauber()">
  <h3>Gewählte Zauber und Rituale</h3>
  <div class="table">
    <div class="row" style="font-weight: bold">
      <div class="name">Name</div>
      <div class="probe">Probe</div>
      <div class="fwMod">-</div>
      <div class="fw">FW</div>
      <div class="fwMod">+</div>
      <div class="typ">Typ</div>
      <div class="verbreitung">Verbreitung</div>
      <div class="merkmal">Merkmal</div>
      <div class="stg">Stg.</div>
    </div>
    <div class="row" *ngFor="let zauber of heldin.zauber">
      <div class="name">{{zauber.name}}</div>
      <div class="probe">{{zauber.probe}} <label *ngIf="zauber.probenMod !== ''">+{{zauber.probenMod}}</label></div>
      <img class="fwMod" src="../../../assets/images/button_s_neg.png" (click)="decreaseZauber(zauber)"/>
      <div class="fw">{{zauber.fw}}</div>
      <img class="fwMod" src="../../../assets/images/button_s_pos.png" (click)="increaseZauber(zauber)"/>
      <div class="typ" *ngIf="zauber.ritual">Ritual</div>
      <div class="typ" *ngIf="!zauber.ritual">Zauber</div>
      <div class="verbreitung">{{zauber.verbreitung}}</div>
      <div class="merkmal">{{zauber.merkmal}}</div>
      <div class="stg">{{zauber.steigerungskosten}}</div>
    </div>
  </div>



  <h3 style="padding-top: 24px">Zaubertricks</h3>
  <b *ngIf="$noOfProfTricks > 0">wähle {{$noOfProfTricks}} aus folgenden:</b>
  <div *ngFor="let trick of $zaubertricksOfProfession">
    {{trick}}
  </div>

  <select [(ngModel)]="$zaubertrickIndex" (ngModelChange)="getZaubertrick()">
    <option [value]="$alleZaubertricks.indexOf(zaubertrick)" *ngFor="let zaubertrick of $alleZaubertricks">{{ zaubertrick.name }}</option>
  </select>

  <div class="vorUndNachteilBox" *ngIf="$zaubertrick !== undefined">
    <div class="zeile2">
      <label>
        <b>Zaubertrick:</b> {{$zaubertrick.name}}
      </label>
    </div>
    <div class="zeile2" style="text-align: justify">
      {{$zaubertrick.beschreibung}}
    </div>
    <div class="zeile2">
      <label>
        <b>Reichweite</b> {{$zaubertrick.reichweite}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Wirkungsdauer:</b> {{$zaubertrick.wirkungsDauer}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Zielkategorie:</b> {{$zaubertrick.zielKathegorie}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Merkmal:</b> {{$zaubertrick.merkmal}}
      </label>
    </div>
    <div class="zeile2">
      <label>
        <b>Verbreitung:</b> {{$zaubertrick.verbreitung}}
      </label>
    </div>
  </div>
  <input type="button" class="button" value="Zaubertrick hinzufügen" (click)="addZaubertrick()">

  <h3>Gewählte Zaubertricks</h3>
  <div class="table">
    <div class="row" style="font-weight: bold">
      <div class="name">Name</div>
      <div class="beschreibung">Beschreibung</div>
    </div>
    <div class="row" *ngFor="let zaubertrick of heldin.zaubertricks">
      <div class="name">{{zaubertrick.name}}</div>
      <div class="beschreibung">{{zaubertrick.beschreibung}}</div>
      <img class="image" src="../../../assets/images/button_s_neg.png" (click)="removeZaubertrick(zaubertrick)"/>
    </div>
  </div>
</div>
