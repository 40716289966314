import {BeeinflussteWerte} from './beeinflusste-werte';
import {Ausruestung} from './ausruestung';
import {Sonderfertigkeit} from './sonderfertigkeit';
import {VorUndNachteil} from './vorUndNachteil';
import {Werte} from './werte';
import {Notiz} from './notiz';
import {SafeResourceUrl} from '@angular/platform-browser';
import {Tier} from './tier';
import {Spezies} from '../../_interfaces/spezies';

export class Heldin {
  id: number;
  name: string;
  geschlecht: string;
  tsaTag: string;
  spezies: string;
  kultur: string;
  profession: string;
  haarfarbe: string;
  augenfarbe: string;
  schamhaare: string;
  brueste: string;
  genital: string;
  alter: number;
  groesse: number;
  gewicht: number;
  beeinflussteWerte: BeeinflussteWerte[] = [];
  ausruestung: Ausruestung[] = [];
  sonderfertigkeiten: Sonderfertigkeit[] = [];
  vorUndNachteile: VorUndNachteil[] = [];
  werte: Werte[] = [];
  notizen: Notiz[] = [];
  tiere: Tier[] = [];
  titel: string;

  portrait: string;


  public getWert(name: string): Werte {
    // console.log('getWert(' + name + ')');
    let wanted: Werte;
    this.werte.forEach(wert => {
      if (wert.name === name) {
        wanted = wert;
      }
    });
    return wanted;
  }

  public setWert(name: string, val: number): void {
    const neuerWert: Werte = new Werte();
    neuerWert.name = name;
    neuerWert.wert = val;
    neuerWert.abzug = 0;
    let vorhanden = false;
    this.werte.forEach(wert => {
      if (wert.name === name) {
        wert.wert = val;
        vorhanden = true;
      }
    });
    if (!vorhanden) {
      this.werte.push(neuerWert);
    }
  }

  public steigereWert(steigerung: Werte): void {
    this.werte.forEach(wert => {
      if (wert.name === steigerung.name) {
        wert.wert += steigerung.wert;
      }
    });
  }

  public getWerteDerKategorie(kategorie: string): Werte[] {
    const wanted: Werte[] = [];
    this.werte.forEach(wert => {
      if (wert.kategorie === kategorie) {
        wanted.push(wert);
      }
    });
    return wanted;
  }

  public clearBeeinflWert(modifiziertes: string): void {
    const bleibendeWerte: BeeinflussteWerte[] = [];
    this.beeinflussteWerte.forEach(bw => {
      if (bw.modifiziertes !== modifiziertes) {
        bleibendeWerte.push(bw);
      }
    });
    this.beeinflussteWerte = bleibendeWerte;
  }

  public clearModifikator(modifikator: string): void {
    const bleibendeWerte: BeeinflussteWerte[] = [];
    this.beeinflussteWerte.forEach(bw => {
      if (bw.modName !== modifikator) {
        bleibendeWerte.push(bw);
      }
    });
    this.beeinflussteWerte = bleibendeWerte;
  }

  public removeModifikator(modifiziertes: string, modifikator: string): void {
    const bleibendeWerte: BeeinflussteWerte[] = [];
    this.beeinflussteWerte.forEach(bw => {
      if (!(bw.modifiziertes === modifiziertes && bw.modName === modifikator)) {
        bleibendeWerte.push(bw);
      }
    });
    this.beeinflussteWerte = bleibendeWerte;
  }

  public removeModifikatorPrefix(modifiziertes: string, modifikatorPrefix: string): void {
    const bleibendeWerte: BeeinflussteWerte[] = [];
    this.beeinflussteWerte.forEach(bw => {
      if (!(bw.modifiziertes === modifiziertes && bw.modName.startsWith(modifikatorPrefix))) {
        bleibendeWerte.push(bw);
      }
    });
    this.beeinflussteWerte = bleibendeWerte;
  }

  public setBeeinflWert(modifiziertes: string, modifikator: string, mod: string): void {
    // console.log(modifiziertes + ' wird modifiziert...');
    let beeinflussterWert: BeeinflussteWerte;
    this.beeinflussteWerte.forEach(vorhanden => {
      if (vorhanden.modifiziertes === modifiziertes &&
        vorhanden.modName === modifikator) {
        beeinflussterWert = vorhanden;
      }
    });
    if (beeinflussterWert == null) {
      beeinflussterWert = new BeeinflussteWerte();
      beeinflussterWert.modifiziertes = modifiziertes;
      beeinflussterWert.modName = modifikator;
      this.beeinflussteWerte.push(beeinflussterWert);
    }
    beeinflussterWert.setWert(mod);
    // console.log('calculateGesamtModifikationFuer(' + modifiziertes + ')');
    this.calculateGesamtModifikationFuer(modifiziertes);
    const zustaende = ['Belastung',
      'Berauscht',
      'Betäubung',
      'Durst',
      'Furcht',
      'Hunger',
      'Paralyse',
      'Schmerz',
      'Schmutz',
      'Verwirrung'];
    if (zustaende.includes(modifiziertes)) {
      this.zustandBeeinflusst(modifiziertes);
    }
  }

  public updateBeeinflWert(neu: BeeinflussteWerte): void {
    // console.log(modifiziertes + ' wird modifiziert...');
    let beeinflussterWert: BeeinflussteWerte;
    this.beeinflussteWerte.forEach(vorhanden => {
      if (vorhanden.modifiziertes === neu.modifiziertes &&
        vorhanden.modName === neu.modName) {
        vorhanden.modWert = neu.modWert;
        vorhanden.kr = neu.kr;
        beeinflussterWert = vorhanden;
      }
    });
    if (beeinflussterWert == null) {
      beeinflussterWert = new BeeinflussteWerte();
      beeinflussterWert.modifiziertes = neu.modifiziertes;
      beeinflussterWert.modName = neu.modName;
      beeinflussterWert.modWert = neu.modWert;
      beeinflussterWert.kr = neu.kr;
      this.beeinflussteWerte.push(beeinflussterWert);
    }
    // console.log('calculateGesamtModifikationFuer(' + modifiziertes + ')');
    this.calculateGesamtModifikationFuer(neu.modifiziertes);
    const zustaende = ['Belastung',
      'Berauscht',
      'Betäubung',
      'Durst',
      'Furcht',
      'Hunger',
      'Paralyse',
      'Schmerz',
      'Schmutz',
      'Verwirrung'];
    if (zustaende.includes(neu.modifiziertes)) {
      this.zustandBeeinflusst(neu.modifiziertes);
    }
  }

  public increaseBeeinflWert(modifiziertes: string, modifikator: string): void {
    let wert: number = this.getBeeinflWert(modifiziertes, modifikator).getNumericWert();
    wert ++;
    this.setBeeinflWert(modifiziertes, modifikator, String(wert));
  }

  public decreaseBeeinflWert(modifiziertes: string, modifikator: string): void {
    let wert: number = this.getBeeinflWert(modifiziertes, modifikator).getNumericWert();
    wert --;
    this.setBeeinflWert(modifiziertes, modifikator, String(wert));
  }


  public getBeeinflWertIfVorhanden(modifiziertes: string, modifikator: string): BeeinflussteWerte {
    let beeinflussterWert: BeeinflussteWerte;
    this.beeinflussteWerte.forEach(vorhanden => {
      if (vorhanden.modifiziertes === modifiziertes &&
        vorhanden.modName === modifikator) {
        beeinflussterWert = vorhanden;
      }
    });
    return beeinflussterWert;
  }

  public getBeeinflWert(modifiziertes: string, modifikator: string): BeeinflussteWerte {
    let beeinflussterWert: BeeinflussteWerte;
    this.beeinflussteWerte.forEach(vorhanden => {
      if (vorhanden.modifiziertes === modifiziertes &&
                vorhanden.modName === modifikator) {
        beeinflussterWert = vorhanden;
      }
    });
    if (beeinflussterWert == null) {
      beeinflussterWert = new BeeinflussteWerte();
      beeinflussterWert.modifiziertes = modifiziertes;
      beeinflussterWert.modName = modifikator;
      this.beeinflussteWerte.push(beeinflussterWert);
    }
    return beeinflussterWert;
  }

  getAlleBeeinflWerte(modifiziertes: string): BeeinflussteWerte[] {
    const wanted: BeeinflussteWerte[] = [];
    this.beeinflussteWerte.forEach(bw => {
      if (bw.modifiziertes === modifiziertes && bw.modWert !== '0' && bw.modWert !== 'x1' ) {
        wanted.push(bw);
      }
    });
    return wanted;
  }

  getAlleBeeinflWerteMulti(modifizierte: string[]): BeeinflussteWerte[] {
    const wanted: BeeinflussteWerte[] = [];
    modifizierte.forEach(modifiziertes => {
      this.beeinflussteWerte.forEach(bw => {
        if (bw.modifiziertes === modifiziertes && bw.modWert !== '0' && bw.modWert !== 'x1' ) {
          wanted.push(bw);
        }
      });
    });
    return wanted;
  }

  public calculateGesamtModifikationFuer(name: string): number {
    // console.log('calculateGesamtModifikationFuer(' + name + ')');
    let wert: Werte;
    this.werte.forEach(w => {
      if (w.name === name) {
        wert = w;
      }
    });
    let summe = 0;
    if (wert != null) {
      summe = wert.wert;
    }
    let multiplikator = 1;
    this.beeinflussteWerte.forEach(bw => {
      if (bw.modifiziertes === name) {
        if (bw.modWert.startsWith('x')) {
          multiplikator *= Number(bw.modWert.substr(1));
        } else {
          summe += Number(bw.modWert);
        }
      }
    });
    if (wert != null) {
      wert.abzug = Math.round(wert.wert - (summe * multiplikator));
    }
    if (wert != null && wert.name === 'LeP') {
      const verhaeltnis: number = (wert.wert - wert.abzug) / wert.wert;
      this.removeModifikator('Schmerz', 'niedrige LeP');
      if (verhaeltnis < 0.75 && verhaeltnis >= 0.5) {
        this.setBeeinflWert('Schmerz', 'niedrige LeP', '1');
      } else if (verhaeltnis < 0.5 && verhaeltnis >= 0.25) {
        this.setBeeinflWert('Schmerz', 'niedrige LeP', '2');
      } else if (verhaeltnis < 0.25) {
        this.setBeeinflWert('Schmerz', 'niedrige LeP', '3');
      }
      console.log(wert.wert + ' - ' + wert.abzug + ' = ' + (wert.wert - wert.abzug));
      if ((wert.wert - wert.abzug) <= 5) {
        this.increaseBeeinflWert('Schmerz', 'niedrige LeP');
      }
      this.calculateGesamtModifikationFuer('Schmerz');
    } else if (wert != null && wert.name === 'Trink-AuP') {
      const verhaeltnis: number = (wert.wert - wert.abzug) / wert.wert;
      console.log((wert.wert - wert.abzug));
      // this.removeModifikator('Berauscht', 'niedrige Trink-AuP');
      if (verhaeltnis >= 0.8) {
        this.setBeeinflWert('Berauscht', 'niedrige Trink-AuP', '0');
      } else if (verhaeltnis < 0.8 && verhaeltnis >= 0.6) {
        this.setBeeinflWert('Berauscht', 'niedrige Trink-AuP', '1');
      } else if (verhaeltnis < 0.6 && verhaeltnis >= 0.4) {
        this.setBeeinflWert('Berauscht', 'niedrige Trink-AuP', '2');
      } else if (verhaeltnis < 0.4 && verhaeltnis >= 0.2) {
        this.setBeeinflWert('Berauscht', 'niedrige Trink-AuP', '3');
      } else if (verhaeltnis < 0.2 && (wert.wert - wert.abzug) > 0) {
        this.setBeeinflWert('Berauscht', 'niedrige Trink-AuP', '4');
      } else if ((wert.wert - wert.abzug) <= 0) {
        console.log('Berauscht -> 5');
        this.setBeeinflWert('Berauscht', 'niedrige Trink-AuP', '5');
      }
      this.calculateGesamtModifikationFuer('Berauscht');
    }
    return Math.round(summe * multiplikator);
  }

  public getAlleWerteDerKategorie(kategorie: string): Werte[] {
    // console.log('getAlleWerteDerKategorie(' + kategorie + ')');
    const wanted: Werte[] = [];
    this.werte.forEach(wert => {
      if (wert.kategorie === kategorie) {
        this.calculateGesamtModifikationFuer(wert.name);
        wanted.push(wert);
      }
    });
    if (kategorie === 'Attribut') {
      const attr = ['MU', 'KL', 'IN', 'CH', 'FF', 'GE', 'KO', 'KK'];
      wanted.sort((a, b) => (attr.indexOf(a.name) < attr.indexOf(b.name) ? -1 : 1));
    }
    return wanted;
  }

  public getAlleNormalenZustaende(): Werte[] {
    const wanted: Werte[] = [];
    const erlaubt: string[] = ['Überanstrengung', 'Belastung', 'Betäubung', 'Furcht', 'Paralyse', 'Schmerz', 'Verwirrung', 'Berauscht',
      'Schmutz', 'Hunger', 'Durst'];
    this.werte.forEach(wert => {
      if (wert.kategorie === 'Zustand' && erlaubt.includes(wert.name)) {
        this.calculateGesamtModifikationFuer(wert.name);
        wanted.push(wert);
      }
    });
    return wanted;
  }

  public createWertIfNotExists(kategorie: string, name: string): Werte {
    const wanted: Werte = new Werte();
    let vorhanden = false;
    this.werte.forEach(wert => {
      if (wert.kategorie === kategorie && wert.name === name) {
        wanted.copy(wert);
        vorhanden = true;
      }
    });
    if (!vorhanden) {
      wanted.kategorie = kategorie;
      wanted.name = name;
      wanted.wert = 0;
      wanted.abzug = 0;
    }
    return wanted;
  }

  public getCurrentWert(name: string): number {
    this.calculateGesamtModifikationFuer(name);
    return this.getWert(name).akt();
  }

  public getAlleSonderfertigkeitenMitName(name: string): Sonderfertigkeit[] {
    const wanted: Sonderfertigkeit[] = [];
    this.sonderfertigkeiten.forEach(sf => {
      if (sf.name === name) {
        wanted.push(sf);
      }
    });
    return wanted;
  }

  public hatSonderfertigkeit(name: string): boolean {
    let vorhanden = false;
    this.sonderfertigkeiten.forEach(data => {
      if (data.name === name) {
        vorhanden = true;
      }
    });
    return vorhanden;
  }

  public hatVorUndNachteil(name: string): boolean {
    let vorhanden = false;
    this.vorUndNachteile.forEach(data => {
      if (data.name === name) {
        vorhanden = true;
      }
    });
    return vorhanden;
  }

  public getVorUndNachteil(name: string): VorUndNachteil {
    let vun: VorUndNachteil;
    this.vorUndNachteile.forEach(data => {
      if (data.name === name) {
        vun = new VorUndNachteil();
        vun.copy(data);
      }
    });
    return vun;
  }


  public zustandBeeinflusst(name: string): void {
    if (name === 'Belastung') {
      // Talente
      this.setBeeinflWert('Fliegen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Gaukeleien', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Klettern', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Kraftakt', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Körperbeherrschung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Reiten', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Schwimmen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Tanzen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Taschendiebstahl', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Verbergen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Verkleiden', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fährtensuchen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Wildnisleben', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Alchemie', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Boote & Schiffe', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fahrzeuge', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Gift', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Krankheiten', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Wunden', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Holzbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Lebensmittelbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Lederbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Malen & Zeichnen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Metallbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Musizieren', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Schlösserknacken', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Steinbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Stoffbearbeitung', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('AT', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('VE', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('INI', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('GS', name, String(-this.getWert(name).akt()));
    } else if (name === 'Berauscht') {
      // Talente
      this.setBeeinflWert('Zechen', name, String(-this.getWert(name).akt()));
    } else if (name === 'Betäubung' || name === 'Furcht' || name === 'Schmerz' || name === 'Verwirrung') {
      // Talente
      this.setBeeinflWert('Fliegen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Gaukeleien', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Klettern', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Kraftakt', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Körperbeherrschung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Reiten', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Schwimmen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Selbstbeherrschung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Singen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Sinnesschärfe', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Tanzen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Taschendiebstahl', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Verbergen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Zechen', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('Bekehren & Überzeugen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Betören', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Einschüchtern', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Etikette', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Gassenwissen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Menschenkenntnis', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Verkleiden', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Willenskraft', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Überreden', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('Fesseln', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fischen & Angeln', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fährtensuchen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Orientierung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Pflanzenkunde', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Tierkunde', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Wildnisleben', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('Brett- & Glücksspiel', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Geographie', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Geschichtswissen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Götter & Kulte', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Kriegskunst', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Magiekunde', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Mechanik', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Rechnen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Rechtskunde', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Sagen & Legenden', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Sphärenkunde', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Sternkunde', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('Alchemie', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Boote & Schiffe', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fahrzeuge', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Handel', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Gift', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Krankheiten', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Seele', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Wunden', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Holzbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Lebensmittelbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Lederbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Malen & Zeichnen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Metallbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Musizieren', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Schlösserknacken', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Steinbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Stoffbearbeitung', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('AT', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('VE', name, String(-this.getWert(name).akt()));
    } else if (name === 'Durst') {

    } else if (name === 'Hunger') {

    } else if (name === 'Paralyse') {
      const mod = (1 - (0.25 * this.getWert('Paralyse').akt())).toFixed(2);
      this.setBeeinflWert('GS', 'Paralyse', 'x' + mod);
      // Talente
      this.setBeeinflWert('Fliegen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Gaukeleien', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Klettern', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Kraftakt', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Körperbeherrschung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Reiten', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Schwimmen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Selbstbeherrschung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Singen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Sinnesschärfe', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Tanzen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Taschendiebstahl', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Verbergen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Zechen', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('Bekehren & Überzeugen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Betören', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Einschüchtern', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Etikette', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Gassenwissen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Verkleiden', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Willenskraft', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Überreden', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('Fesseln', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fischen & Angeln', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fährtensuchen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Wildnisleben', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('Alchemie', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Boote & Schiffe', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Fahrzeuge', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Handel', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Gift', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Krankheiten', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Seele', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Heilkunde Wunden', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Holzbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Lebensmittelbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Lederbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Malen & Zeichnen', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Metallbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Musizieren', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Schlösserknacken', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Steinbearbeitung', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('Stoffbearbeitung', name, String(-this.getWert(name).akt()));

      this.setBeeinflWert('AT', name, String(-this.getWert(name).akt()));
      this.setBeeinflWert('VE', name, String(-this.getWert(name).akt()));
    } else if (name === 'Schmutz') {
      this.setBeeinflWert('Bekehren & Überzeugen', name, String(-(this.getWert(name).akt() - 1)));
      this.setBeeinflWert('Betören', name, String(-(this.getWert(name).akt() - 1)));
      this.setBeeinflWert('Etikette', name, String(-(this.getWert(name).akt() - 1)));
      this.setBeeinflWert('Verkleiden', name, String(-(this.getWert(name).akt() - 1)));
      this.setBeeinflWert('Überreden', name, String(-(this.getWert(name).akt() - 1)));
    }
  }

  berechneGesamtModifikator(modifizierte: string[]): number {
    let summe = 0;
    let multiplikator = 1;
    modifizierte.forEach(modifiziertes => {
      console.log(modifiziertes + ':');
      this.getAlleBeeinflWerte(modifiziertes).forEach(beeinflWert => {
        console.log(beeinflWert);
        const stringWert: string = beeinflWert.getWert();
        if (stringWert.startsWith('x')) {
          const wert: number = Number(stringWert.substr(1));
          multiplikator *= wert;
        } else {
          const wert: number = Number(stringWert);
          summe += wert;
        }
      });
    });
    const ergebnis = Math.round(summe * multiplikator);
    return ergebnis;
  }

  errechneAT(wert: number): number {
    for (let i = 11; i <= this.getCurrentWert('MU'); i += 3) {
      wert ++;
    }
    let summe = 0;
    let multiplikator = 1;
    this.getAlleBeeinflWerte('AT').forEach(bw => {
      if (bw.modWert.startsWith('x')) {
        multiplikator *= Number(bw.modWert.substr(1));
      } else {
        summe += Number(bw.modWert);
      }
    });
    wert = (wert + summe) * multiplikator;
    return wert;
  }

  errechneFK(wert: number): number {
    for (let i = 11; i <= this.getCurrentWert('FF'); i += 3) {
      wert ++;
    }
    let summe = 0;
    let multiplikator = 1;
    this.getAlleBeeinflWerte('AT').forEach(bw => {
      if (bw.modWert.startsWith('x')) {
        multiplikator *= Number(bw.modWert.substr(1));
      } else {
        summe += Number(bw.modWert);
      }
    });
    wert = (wert + summe) * multiplikator;
    return wert;
  }

  errechneVE(wert: number, le: string): number {
    wert = Math.ceil(wert / 2);
    for (let i = 11; i <= this.getCurrentWert(le); i += 3) {
      wert ++;
    }
    let summe = 0;
    let multiplikator = 1;
    this.getAlleBeeinflWerte('VE').forEach(bw => {
      if (bw.modWert.startsWith('x')) {
        multiplikator *= Number(bw.modWert.substr(1));
      } else {
        summe += Number(bw.modWert);
      }
    });
    wert = (wert + summe) * multiplikator;
    return wert;
  }


  getSteckbrief(): Notiz[] {
    const wanted: Notiz[] = [];
    this.notizen.forEach(notiz => {
      if (notiz.name.startsWith('#')) {
        wanted.push(notiz);
      }
    });
    return wanted;
  }

  public copy(other: Heldin): void {
    this.id = other.id;
    this.name = other.name;
    this.geschlecht = other.geschlecht;
    this.tsaTag = other.tsaTag;
    this.spezies = other.spezies;
    this.kultur = other.kultur;
    this.profession = other.profession;
    this.haarfarbe = other.haarfarbe;
    this.augenfarbe = other.augenfarbe;
    this.schamhaare = other.schamhaare;
    this.brueste = other.brueste;
    this.genital = other.genital;
    this.alter = other.alter;
    this.groesse = other.groesse;
    this.gewicht = other.gewicht;
    this.beeinflussteWerte = [];
    this.ausruestung = [];
    this.sonderfertigkeiten = [];
    this.vorUndNachteile = [];
    this.werte = [];
    this.notizen = [];
    other.beeinflussteWerte.forEach(o => {
      const b: BeeinflussteWerte = new BeeinflussteWerte();
      b.copy(o);
      this.beeinflussteWerte.push(b);
    });
    other.ausruestung.forEach(o => {
      const b: Ausruestung = new Ausruestung();
      b.copy(o);
      this.ausruestung.push(b);
    });
    other.sonderfertigkeiten.forEach(o => {
      const b: Sonderfertigkeit = new Sonderfertigkeit();
      b.copy(o);
      this.sonderfertigkeiten.push(b);
    });
    other.vorUndNachteile.forEach(o => {
      const b: VorUndNachteil = new VorUndNachteil();
      b.copy(o);
      this.vorUndNachteile.push(b);
    });
    other.werte.forEach(o => {
      const b: Werte = new Werte();
      b.copy(o);
      this.werte.push(b);
    });
    other.notizen.forEach(o => {
      const b: Notiz = new Notiz();
      b.copy(o);
      this.notizen.push(b);
    });
    this.tiere = [];
    other.tiere.forEach(o => {
      const b: Tier = new Tier();
      b.copy(o);
      this.tiere.push(b);
    });
    this.titel = other.titel;
    this.portrait = other.portrait;
  }
}
