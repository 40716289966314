import {Allgemein} from './allgemein';

export class Gegenstand {
  id: number;
  name: string;
  type: string;
  subtyp: string;
  dateiname: string;
  notiz: string;

  public copy(other: Gegenstand): void {
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
  }
}
