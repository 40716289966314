import {Ausruestung} from './charakter/ausruestung';
import {Gegenstand} from './ausruestung/gegenstand';
import {Allgemein} from './ausruestung/allgemein';
import {Seil} from './ausruestung/seil';
import {Behaeltnis} from './ausruestung/behaeltnis';
import {Leuchtmittel} from './ausruestung/leuchtmittel';
import {Nahkampfwaffe} from './ausruestung/nahkampfwaffe';
import {Fernkampfwaffe} from './ausruestung/fernkampfwaffe';
import {Ruestung} from './ausruestung/ruestung';
import {Parierwaffe} from './ausruestung/parierwaffe';

export class Kombigegenstand {
  ausruestung = new Ausruestung();
  gegenstand: Gegenstand | Allgemein | Seil | Behaeltnis | Leuchtmittel | Nahkampfwaffe | Fernkampfwaffe | Ruestung;

  public copy(other: Kombigegenstand): void {
    this.ausruestung = new Ausruestung();
    this.ausruestung.copy(other.ausruestung);
    if (other.gegenstand.type === 'Allgemein') {
      this.gegenstand = new Allgemein();
      this.gegenstand.copy(other.gegenstand as Allgemein);
    } else if (other.gegenstand.type === 'Seil') {
      this.gegenstand = new Seil();
      this.gegenstand.copy(other.gegenstand as Seil);
    } else if (other.gegenstand.type === 'Behaeltnis') {
      this.gegenstand = new Behaeltnis();
      this.gegenstand.copy(other.gegenstand as Behaeltnis);
    } else if (other.gegenstand.type === 'Leuchtmittel') {
      this.gegenstand = new Leuchtmittel();
      this.gegenstand.copy(other.gegenstand as Leuchtmittel);
    } else if (other.gegenstand.type === 'Nahkampfwaffe') {
      console.log('copy as Nahkampfwaffe');
      console.log(other.gegenstand as Nahkampfwaffe);
      this.gegenstand = new Nahkampfwaffe();
      this.gegenstand.copy(other.gegenstand as Nahkampfwaffe);
      console.log(this.gegenstand);
    } else if (other.gegenstand.type === 'Parierwaffe') {
      console.log('copy as Parierwaffe');
      console.log(other.gegenstand as Parierwaffe);
      this.gegenstand = new Parierwaffe();
      this.gegenstand.copy(other.gegenstand as Parierwaffe);
      console.log(this.gegenstand);
    } else if (other.gegenstand.type === 'Fernkampfwaffe') {
      this.gegenstand = new Fernkampfwaffe();
      this.gegenstand.copy(other.gegenstand as Fernkampfwaffe);
    } else if (other.gegenstand.type === 'Ruestung') {
      this.gegenstand = new Ruestung();
      this.gegenstand.copy(other.gegenstand as Ruestung);
    }
  }


}
