export class Limits {
  name: string;
  startAP: number;
  maxEigenschaft: number;
  maxFertigkeit: number;
  maxKampftechnik: number;
  maxPktEigenschaft: number;
  maxAnzZauber: number;
  maxFremdZauber: number;
  apLog: number;
  apKonto: number;
  stufe: number;

  constructor() {
    this.name = 'unerfahren';
    this.startAP = 900;
    this.maxEigenschaft = 12;
    this.maxFertigkeit = 10;
    this.maxKampftechnik = 8;
    this.maxPktEigenschaft = 95;
    this.maxAnzZauber = 8;
    this.maxFremdZauber = 0;
    this.apLog = 0;
    this.apKonto = 900;
    this.stufe = 0;
  }

  aktualisiere(neueStufe: number, data: string[][]): void {
    this.stufe = neueStufe;
    this.name = data[0][1];
    this.startAP = Number(data[1][1]);
    this.maxEigenschaft = Number(data[2][1]);
    this.maxFertigkeit = Number(data[3][1]);
    this.maxKampftechnik = Number(data[4][1]);
    this.maxPktEigenschaft = Number(data[5][1]);
    this.maxAnzZauber = Number(data[6][1]);
    this.maxFremdZauber = Number(data[7][1]);
  }

  public copy(other: Limits): void {
    this.name = other.name;
    this.startAP = other.startAP;
    this.maxEigenschaft = other.maxEigenschaft;
    this.maxFertigkeit = other.maxFertigkeit;
    this.maxKampftechnik = other.maxKampftechnik;
    this.maxPktEigenschaft = other.maxPktEigenschaft;
    this.maxAnzZauber = other.maxAnzZauber;
    this.maxFremdZauber = other.maxFremdZauber;
    this.apLog = other.apLog;
    this.apKonto = other.apKonto;
    this.stufe = other.stufe;
  }
}
