import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Charakter} from '../../_interfaces/charakter';
import {Observable, Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {concatMap, takeUntil} from 'rxjs/operators';
import {WebsocketService} from '../../_services/websocket.service';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {Kombigegenstand} from '../../_classes/kombigegenstand';
import {Ruestung} from '../../_classes/ausruestung/ruestung';
import {AusruestungSheet} from '../../_interfaces/components/sheet/ausruestungSheet';
import {Kampfblatt} from '../../_interfaces/components/sheet/kampfblatt';
import {PersistenceService} from '../../_services/persistence.service';
import {Nahkampfwaffe} from '../../_classes/ausruestung/nahkampfwaffe';
import {Waffe} from '../../_classes/ausruestung/waffe';
import {leadingComment} from '@angular/compiler';
import {KampfdatenService} from '../../_services/kampfdaten.service';
import {Distanz} from '../../_interfaces/kampf/distanz';
import {Fernkampfwaffe} from '../../_classes/ausruestung/fernkampfwaffe';
import {convertActionBinding} from '@angular/compiler/src/compiler_util/expression_converter';
import {Parierwaffe} from '../../_classes/ausruestung/parierwaffe';
import {Sonderfertigkeit} from '../../_classes/charakter/sonderfertigkeit';
import {Kaempferin} from '../../_classes/kampf/kaempferin';
import {BeeinflussteWerte} from "../../_classes/charakter/beeinflusste-werte";

@Component({
  selector: 'app-kampfsystem',
  templateUrl: './kampfsystem.component.html',
  styleUrls: ['./kampfsystem.component.sass']
})
export class KampfsystemComponent implements OnInit {
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;
  @Input() katalog: any;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  destroyed$ = new Subject();

  charakterWaffen: Kombigegenstand[] = [];
  charakterWaffenNK: Kombigegenstand[] = [];
  charakterWaffenFK: Kombigegenstand[] = [];
  charakterAusruestung: Kombigegenstand[] = [];
  ziebareWaffen: Kombigegenstand[] = [];
  ziehbarLinks: Kombigegenstand;
  ziehbarLinksIndex = 0;
  ziehbarRechts: Kombigegenstand;
  ziehbarRechtsIndex = 0;

  waffenTrageorte: string[] = [];
  schnellziehen = false;

  schussdistanzenL: Distanz[] = [];
  schussdistanzenR: Distanz[] = [];

  atLinks = '-';
  paLinks = '-';
  tpPrefixLinks = '';
  tpSuffixLinks = '';

  atRechts = '-';
  paRechts = '-';
  tpPrefixRechts = '';
  tpSuffixRechts = '';

  rsKopf = 0;
  rsTorso = 0;
  rslArm = 0;
  rsrArm = 0;
  rslBein = 0;
  rsrBein = 0;

  wKopf = 0;
  wTorso = 0;
  wlArm = 0;
  wrArm = 0;
  wlBein = 0;
  wrBein = 0;

  waffenlaengen: string[] = ['kurz', 'mittel', 'lang'];

  munitionLinks: Kombigegenstand[] = [];
  munitionRechts: Kombigegenstand[] = [];

  ladezeitLinks = 0;
  ladezeitRechts = 0;

  sonderfertigkeiten_passiv: any[] = [];
  sonderfertigkeiten_basis: any[] = [];
  sonderfertigkeiten_spezial: any[] = [];
  sf_info: any = {
    name: '',
    kampfsftyp: '',
    kampftechniken: '',
    stufe: 0,
    spezifikation: '',
    gewaehlteKategorie: '',
    regel: '',
    erschwernis: '',
  };

  romanLetters: string[] = ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIII'];

  public  localData: Kampfblatt = {
    linkeHand: null,
    linkeHandLe: '',
    rechteHand: null,
    rechteHandLe: '',
    waffenlaenge: '',
    umgebung: 0,
    wassertiefe: '',
    distanzL: undefined,
    distanzR: undefined,
    bewegung_ziel: 0,
    bewegung_schuetze: 0,
    distanzLIndex: 1,
    distanzRIndex: 1,
    sicht: undefined,
    sichtIndex: 0,
    zielgroesse: undefined,
    zielgroesseIndex: 0
  };

  constructor(private persistence: PersistenceService,
              public kampfdaten: KampfdatenService,
              private websocket: WebsocketService) { }

  ngOnInit(): void {
    this.initialisiereWaffen();
    const stored: Kampfblatt = this.persistence.retrieveComponent('kampfblatt');
    // console.log(stored);
    if (stored != null) {
      this.localData = stored;
      this.berechneGrundwerteFuerWaffe(this.localData.rechteHand);
      this.berechneGrundwerteFuerWaffe(this.localData.linkeHand);
    } else {
      this.charakterWaffenNK.forEach(waffe => {
        if (waffe.gegenstand.name === 'Fäuste') {
          this.localData.linkeHand = new Kombigegenstand();
          this.localData.linkeHand.copy(waffe);
          this.localData.linkeHand.ausruestung.name = 'linke Faust';
          this.berechneGrundwerteFuerWaffe(this.localData.linkeHand);
          this.localData.rechteHand = new Kombigegenstand();
          this.localData.rechteHand.copy(waffe);
          this.localData.rechteHand.ausruestung.name = 'rechte Faust';
          this.berechneGrundwerteFuerWaffe(this.localData.rechteHand);
          this.initialisiereLe(this.localData.linkeHand, false);
          this.initialisiereLe(this.localData.rechteHand, true);
        }
      });
      this.localData.sichtIndex = 0;
      this.localData.zielgroesseIndex = 2;
      this.localData.bewegung_ziel = 0;
      this.localData.bewegung_schuetze = 0;
      this.localData.waffenlaenge = 'mittel';
      this.localData.umgebung = 0;
    }
    this.ermittleZiebareWaffen();
    this.ermittleAlleSchussdistanzen();
    this.schnellziehen = this.charakter.hatSonderfertigkeit('Schnellziehen');
    const ff = this.charakter.getCurrentWert('FF');
    if (ff > 8) {
      const diff: number = Math.floor((ff - 8) / 3);
      this.charakter.setBeeinflWert('AT_FK', 'je 3 Punkte FF über 8', String(diff));
    }
    const mu = this.charakter.getCurrentWert('MU');
    if (mu > 8) {
      const diff: number = Math.floor((mu - 8) / 3);
      this.charakter.setBeeinflWert('AT_NK', 'je 3 Punkte MU über 8', String(diff));
    }
    this.ermittlePaUndTpDurchLE();
    if (!this.charakter.hatSonderfertigkeit('Beidhändig')) {
      if (this.charakter.hatVorUndNachteil('Linkshänder')) {
        this.charakter.setBeeinflWert('AT_Rechts', 'falsche Hand', '-4');
        this.charakter.removeModifikator('AT_Links', 'falsche Hand');
        this.charakter.setBeeinflWert('PA_Rechts', 'falsche Hand', '-4');
        this.charakter.removeModifikator('PA_Links', 'falsche Hand');
      } else {
        this.charakter.setBeeinflWert('AT_Links', 'falsche Hand', '-4');
        this.charakter.removeModifikator('AT_Rechts', 'falsche Hand');
        this.charakter.setBeeinflWert('PA_Links', 'falsche Hand', '-4');
        this.charakter.removeModifikator('PA_Rechts', 'falsche Hand');
      }
    } else {
      this.charakter.removeModifikator('AT_Links', 'falsche Hand');
      this.charakter.removeModifikator('AT_Rechts', 'falsche Hand');
      this.charakter.removeModifikator('PA_Links', 'falsche Hand');
      this.charakter.removeModifikator('PA_Rechts', 'falsche Hand');
    }
    this.waehleKampfModifikatoren(true);
    this.waehleKampfModifikatoren(false);
    if ((this.localData.rechteHand.gegenstand as Waffe).kampftechnik === 'Schilde') {
      console.log('removeModifikator(\'PA_Rechts\', \'falsche Hand\')');
      this.charakter.removeModifikator('PA_Rechts', 'falsche Hand');
    }
    if ((this.localData.linkeHand.gegenstand as Waffe).kampftechnik === 'Schilde') {
      console.log('removeModifikator(\'PA_Links\', \'falsche Hand\')');
      this.charakter.removeModifikator('PA_Links', 'falsche Hand');
    }
    this.berechneGrundTPFuerBeideWaffen();
    this.berechneWaffenWerte();
    console.log(this.localData.rechteHand);

    const chatSub$ = this.websocket.connect(String(this.charakter.id), 'arena').pipe(
      takeUntil(this.destroyed$),
    );

    chatSub$.subscribe((data: string) => {
      const message = JSON.stringify(data);
      console.log(message);
    });
    this.ermittleRuestung();
    this.getWunden();

    this.sonderfertigkeiten_passiv = [];
    this.sonderfertigkeiten_basis = [];
    this.sonderfertigkeiten_spezial = [];
    this.charakter.sonderfertigkeiten.forEach(sf_char => {
      this.katalog.all_sonderfertigkeiten.forEach(sf => {
        if (sf.name === sf_char.name) {
          if (sf.sf_art.toLowerCase().includes('kampf')) {
            console.log('katalog', sf);
            let stufe = 0;
            if (sf.stufen > 0) {
              stufe = sf_char.stufe;
            }
            const sf_2_add = {
              name: sf.name,
              kampfsftyp: sf.kampfsftyp,
              kampftechniken: sf.kampftechniken,
              stufe: stufe,
              spezifikation: sf_char.spezifikation,
              gewaehlteKategorie: sf_char.gewaehlteKategorie,
              regel: sf.regel,
              erschwernis: sf.erschwernis,
            };
            if (sf.kampfsftyp === '(Spezialmanöver)') {
              this.sonderfertigkeiten_spezial.push(sf_2_add);
            } else if (sf.kampfsftyp === '(Basismanöver)') {
              this.sonderfertigkeiten_basis.push(sf_2_add);
            } else {
              this.sonderfertigkeiten_passiv.push(sf_2_add);
            }
          }
        }
      });
    });
    this.sonderfertigkeiten_passiv.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.sonderfertigkeiten_basis.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.sonderfertigkeiten_spezial.sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  public berechneWaffenWerte(): void {
    this.berechneATfuerWaffe(this.localData.rechteHand);
    this.berechneATfuerWaffe(this.localData.linkeHand);
    this.berechnePAfuerWaffe(this.localData.rechteHand);
    this.berechnePAfuerWaffe(this.localData.linkeHand);
    this.berechneTPFuerWaffe(this.localData.rechteHand);
    this.berechneTPFuerWaffe(this.localData.linkeHand);
    this.berechneAusweichen();
    this.ping.emit();
  }

  public berechneTPFuerWaffe(waffe: Kombigegenstand): void {
    console.log('berechneTPFuerWaffe(' + waffe.ausruestung.name + ')');
    const modifikatoren: string[] = ['TP'];
    modifikatoren.push('TP_' + waffe.ausruestung.name);
    if (this.localData.rechteHand.ausruestung.name === waffe.ausruestung.name) {
      modifikatoren.push('TP_Rechts');
      if (waffe.gegenstand.type === 'Fernkampfwaffe') {
        modifikatoren.push('TP_Rechts_FK');
      }
      console.log(modifikatoren);
      const tp = this.charakter.berechneGesamtModifikator(modifikatoren);
      if (tp > 0) {
        this.tpSuffixRechts = '+' + String(tp);
      } else if (tp < 0) {
        this.tpSuffixRechts = String(tp);
      } else {
        this.tpSuffixRechts = '';
      }
    } else if (this.localData.linkeHand.ausruestung.name === waffe.ausruestung.name) {
      modifikatoren.push('TP_Links');
      if (waffe.gegenstand.type === 'Fernkampfwaffe') {
        modifikatoren.push('TP_Links_FK');
      }
      const tp = this.charakter.berechneGesamtModifikator(modifikatoren);
      if (tp > 0) {
        this.tpSuffixLinks = '+' + String(tp);
      } else if (tp < 0) {
        this.tpSuffixLinks = String(tp);
      } else {
        this.tpSuffixLinks = '';
      }
    }
  }

  public berechneGrundTPFuerBeideWaffen(): void {
    console.log('berechneGrundTPFuerBeideWaffen');
    const rechts: string = this.berechneGrundTPFuerWaffe(this.localData.rechteHand);
    this.tpPrefixRechts = rechts;
    const links: string = this.berechneGrundTPFuerWaffe(this.localData.linkeHand);
    this.tpPrefixLinks = links;
  }

  public berechneGrundTPFuerWaffe(waffe: Kombigegenstand): string {
    console.log('berechneGrundTPFuerWaffe(' + waffe.ausruestung.name + ')');
    const schaden: string = (waffe.gegenstand as Waffe).tp;
    let prefix: string;
    if (schaden.includes('+')) {
      const splitted: string[] = schaden.split('+');
      prefix = splitted[0];
      this.charakter.setBeeinflWert('TP_' + waffe.ausruestung.name, 'Waffe', splitted[1]);
    } else if (schaden.includes('-')) {
      const splitted: string[] = schaden.split('-');
      prefix = splitted[0];
      this.charakter.setBeeinflWert('TP_' + waffe.ausruestung.name, 'Waffe', '-' + splitted[1]);
    } else {
      prefix = schaden;
      this.charakter.removeModifikator('TP_' + waffe.ausruestung.name, 'Waffe');
    }
    return prefix;
  }

  public berechneAusweichen(): void {
    const sf: Sonderfertigkeit[] = this.charakter.getAlleSonderfertigkeitenMitName('Verbessertes Ausweichen');
    if (sf.length > 0) {
      const verbAw: Sonderfertigkeit = sf[0];
      this.charakter.setBeeinflWert('AW', 'Sonderfertigkeit: Verbessertes Ausweichen', String(verbAw.stufe));
    } else {
      this.charakter.removeModifikator('AW', 'Sonderfertigkeit: Verbessertes Ausweichen');
    }
  }

  public ermittlePaUndTpDurchLE(): void {
    console.log('ermittlePaUndTpDurchLE');
    const leL = this.charakter.getCurrentWert(this.localData.linkeHandLe);
    if (leL > 8) {
      const diff: number = Math.floor((leL - 8) / 3);
      this.charakter.setBeeinflWert('PA_Links', 'je 3 Punkte LE über 8', String(diff));
    } else {
      this.charakter.removeModifikator('PA_Links', 'je 3 Punkte LE über 8');
    }
    if (this.localData.linkeHand.gegenstand.type !== 'Fernkampfwaffe') {
      if (leL > (this.localData.linkeHand.gegenstand as Nahkampfwaffe).schadensschwelle) {
        const diff: number = leL - (this.localData.linkeHand.gegenstand as Nahkampfwaffe).schadensschwelle;
        this.charakter.setBeeinflWert('TP_Links', 'LE über Schadensschwelle', String(diff));
      } else {
        this.charakter.removeModifikator('TP_Links', 'LE über Schadensschwelle');
      }
    } else {
      this.charakter.removeModifikator('TP_Links', 'LE über Schadensschwelle');
    }
    const leR = this.charakter.getCurrentWert(this.localData.rechteHandLe);
    if (leR > 8) {
      const diff: number = Math.floor((leR - 8) / 3);
      this.charakter.setBeeinflWert('PA_Rechts', 'je 3 Punkte LE über 8', String(diff));
    } else {
      this.charakter.removeModifikator('PA_Rechts', 'je 3 Punkte LE über 8');
    }
    if (this.localData.rechteHand.gegenstand.type !== 'Fernkampfwaffe') {
      if (leR > (this.localData.rechteHand.gegenstand as Nahkampfwaffe).schadensschwelle) {
        const diff: number = leR - (this.localData.rechteHand.gegenstand as Nahkampfwaffe).schadensschwelle;
        this.charakter.setBeeinflWert('TP_Rechts', 'LE über Schadensschwelle', String(diff));
      } else {
        this.charakter.removeModifikator('TP_Rechts', 'LE über Schadensschwelle');
      }
    } else {
      this.charakter.removeModifikator('TP_Rechts', 'LE über Schadensschwelle');
    }
    this.persistenceCall();
    this.berechneWaffenWerte();
    this.ping.emit();
  }

  ermittleMunition(waffe: Kombigegenstand): void {
    if (waffe.gegenstand.type === 'Fernkampfwaffe') {
      this.munitionLinks = [];
      this.munitionRechts = [];
      let rechts = false;
      if (this.localData.rechteHand.ausruestung.name === waffe.ausruestung.name) {
        rechts = true;
      }
      const fkWaffe: Fernkampfwaffe = waffe.gegenstand as Fernkampfwaffe;
      if (fkWaffe.munitionstyp === waffe.gegenstand.name) {
        // keine Schusswaffe
        this.charakterAusruestung.forEach(item => {
          if (item.gegenstand.name === fkWaffe.munitionstyp) {
            if (rechts) {
              this.munitionRechts.push(item);
            } else {
              this.munitionLinks.push(item);
            }
          }
        });
      }
    }
  }

  public ermittleZiebareWaffen(): void {
    this.ziebareWaffen = [];
    this.waffenTrageorte = [];
    this.charakterWaffen.forEach(waffe => {
      if (waffe.gegenstand.name !== 'Fäuste') {
        if (waffe.ausruestung.name !== this.localData.linkeHand.ausruestung.name &&
          waffe.ausruestung.name !== this.localData.rechteHand.ausruestung.name) {
          this.ziebareWaffen.push(waffe);
          this.waffenTrageorte.push(waffe.ausruestung.trageort);
        }
      }
    });
    this.ziehbarLinks = this.ziebareWaffen[0];
    this.ziehbarRechts = this.ziebareWaffen[0];
  }

  public ermittleAlleSchussdistanzen(): void {
    if (this.localData.linkeHand.gegenstand.type === 'Fernkampfwaffe') {
      this.schussdistanzenL = this.ermittleSchussdistanzen(this.localData.linkeHand.gegenstand as Fernkampfwaffe);
      console.log(this.schussdistanzenL);
      this.ladezeitLinks = this.ermittleLadezeit((this.localData.linkeHand.gegenstand as Fernkampfwaffe));
      this.localData.distanzL = this.schussdistanzenL[this.localData.distanzLIndex];
    }
    if (this.localData.rechteHand.gegenstand.type === 'Fernkampfwaffe') {
      this.schussdistanzenR = this.ermittleSchussdistanzen(this.localData.rechteHand.gegenstand as Fernkampfwaffe);
      console.log(this.schussdistanzenR);
      this.ladezeitRechts = this.ermittleLadezeit((this.localData.rechteHand.gegenstand as Fernkampfwaffe));
      this.localData.distanzR = this.schussdistanzenR[this.localData.distanzRIndex];
    }
  }

  public ermittleLadezeit(waffe: Fernkampfwaffe): number {
    let ladezeit = waffe.ladezeit;
    let kt: string = waffe.kampftechnik;
    if (kt === 'Armbrüste') {
      kt = 'Armbrust';
    } else if (kt === 'Bögen') {
      kt = 'Bogen';
    }

    if (this.charakter.hatSonderfertigkeit('Schnellladen (' + kt + ')')) {
      if (waffe.kampftechnik === 'Armbrüste') {
        ladezeit = Math.floor(ladezeit / 2);
      } else {
        ladezeit--;
      }
    }
    return ladezeit;
  }

  public ermittleSchussdistanzen(waffe: Fernkampfwaffe): Distanz[] {
    const schussdistanzen: Distanz[] = [];
    const reichweiten: string[] = waffe.reichweiten.split('/');
    if (this.charakter.hatVorUndNachteil('Kernschuss')) {
      const nahdistanz: Distanz = {
        name: 'Nahkampf',
        entfernung: 0,
        fkMod: (4 - this.charakter.getVorUndNachteil('Kernschuss').stufe) * -2,
        tpMod: 1
      };
      schussdistanzen.push(nahdistanz);
    }
    let distanz: Distanz = {
      name: 'nah',
      entfernung: Number(reichweiten[0]),
      fkMod: 2,
      tpMod: 1
    };
    schussdistanzen.push(distanz);
    distanz = {
      name: 'mittel',
      entfernung: Number(reichweiten[1]),
      fkMod: 0,
      tpMod: 0
    };
    schussdistanzen.push(distanz);
    /*if (this.localData.distanzL == null) {
      this.localData.distanzL = distanz;
    }
    if (this.localData.distanzR == null) {
      this.localData.distanzR = distanz;
    }*/
    distanz = {
      name: 'weit',
      entfernung: Number(reichweiten[2]),
      fkMod: -2,
      tpMod: -1
    };
    schussdistanzen.push(distanz);
    if (this.charakter.hatVorUndNachteil('Ballistischer Schuss')) {
      const nahdistanz: Distanz = {
        name: 'Ballistischer Schuss',
        entfernung: Number(reichweiten[2]) * 1.5,
        fkMod: -4,
        tpMod: -1
      };
      schussdistanzen.push(nahdistanz);
    }
    return schussdistanzen;
  }

  initialisiereLe(waffe: Kombigegenstand, rechts: boolean): void {
    if (waffe.gegenstand.type === 'Nahkampfwaffe' || waffe.gegenstand.type === 'Parierwaffe') {
      if (rechts) {
        if ((waffe.gegenstand as Nahkampfwaffe).leiteigenschaft.includes('/')) {
          this.localData.rechteHandLe = (waffe.gegenstand as Nahkampfwaffe).leiteigenschaft.split('/')[0];
        } else {
          this.localData.rechteHandLe = (waffe.gegenstand as Nahkampfwaffe).leiteigenschaft;
        }
      } else {
        if ((waffe.gegenstand as Nahkampfwaffe).leiteigenschaft.includes('/')) {
          this.localData.linkeHandLe = (waffe.gegenstand as Nahkampfwaffe).leiteigenschaft.split('/')[0];
        } else {
          this.localData.linkeHandLe = (waffe.gegenstand as Nahkampfwaffe).leiteigenschaft;
        }
      }
    } else {
      if (rechts) {
        this.localData.rechteHandLe = 'FF';
      } else {
        this.localData.linkeHandLe = 'FF';
      }
    }
  }

  initialisiereWaffen(): void {
    this.charakter.ausruestung.forEach(item => {
      this.glossar.ausruestung.forEach(gitem => {
        if (gitem.id === item.itemid) {
          if (gitem.name !== 'Körper') {
            const gegenstandAllg: Kombigegenstand = new Kombigegenstand();
            gegenstandAllg.gegenstand = gitem;
            gegenstandAllg.ausruestung = item;
            this.charakterAusruestung.push(gegenstandAllg);
            if (gitem.type === 'Nahkampfwaffe' || gitem.type === 'Fernkampfwaffe' || gitem.type === 'Parierwaffe') {
              for (let i = 0; i < item.anzahl; i++) {
                const gegenstand: Kombigegenstand = new Kombigegenstand();
                gegenstand.gegenstand = gitem;
                gegenstand.ausruestung = item;
                this.charakterWaffen.push(gegenstand);
                if (gitem.type === 'Nahkampfwaffe' || gitem.type === 'Parierwaffe') {
                  gegenstand.gegenstand = gitem;
                  gegenstand.ausruestung = item;
                  this.charakterWaffenNK.push(gegenstand);
                } else {
                  gegenstand.gegenstand = gitem;
                  gegenstand.ausruestung = item;
                  this.charakterWaffenFK.push(gegenstand);
                }
              }
            }
          }
        }
      });
    });
  }

  berechneSchaden(waffe: Kombigegenstand, rechts: boolean): string {
    let schaden: string = (waffe.gegenstand as Waffe).tp;
    let schadenWurf: string;
    let schadenZusatz: number;
    if (schaden.includes('+')) {
      const splitted: string[] = schaden.split('+');
      schadenWurf = splitted[0];
      schadenZusatz = Number(splitted[1]);
    } else if (schaden.includes('-')) {
      const splitted: string[] = schaden.split('-');
      schadenWurf = splitted[0];
      schadenZusatz = -Number(splitted[1]);
    } else {
      schadenWurf = schaden;
      schadenZusatz = 0;
    }
    let leWert = 0;
    if (rechts) {
      leWert = this.charakter.getCurrentWert(this.localData.rechteHandLe);
      if (this.localData.rechteHand.gegenstand.type === 'Fernkampfwaffe') {
        schadenZusatz += this.localData.distanzR.tpMod;
      }
    } else {
      leWert = this.charakter.getCurrentWert(this.localData.linkeHandLe);
      if (this.localData.linkeHand.gegenstand.type === 'Fernkampfwaffe') {
        schadenZusatz += this.localData.distanzL.tpMod;
      }
    }
    if (waffe.gegenstand.type === 'Nahkampfwaffe' || waffe.gegenstand.type === 'Parierwaffe') {
      if ((waffe.gegenstand as Nahkampfwaffe).schadensschwelle < leWert) {
        schadenZusatz += (leWert - (waffe.gegenstand as Nahkampfwaffe).schadensschwelle);
      }
    }
    schaden = schadenWurf;
    if (schadenZusatz > 0) {
      schaden += '+' + schadenZusatz;
    } else if (schadenZusatz < 0) {
      schaden += schadenZusatz;
    }
    return schaden;
  }


  berechneATfuerWaffe(waffe: Kombigegenstand): void {
    const modifikatoren: string[] = ['AT'];
    if (waffe.gegenstand.type === 'Fernkampfwaffe') {
      modifikatoren.push('AT_FK');
    } else {
      modifikatoren.push('AT_NK');
    }
    modifikatoren.push('AT_' + waffe.ausruestung.name);

    if (this.localData.rechteHand.ausruestung.name === waffe.ausruestung.name) {
      modifikatoren.push('AT_Rechts');
      const at = this.charakter.berechneGesamtModifikator(modifikatoren);
      if (at > 0) {
        this.atRechts = String(at);
      } else {
        this.atRechts = '-';
      }
    } else if (this.localData.linkeHand.ausruestung.name === waffe.ausruestung.name) {
      modifikatoren.push('AT_Links');
      const at = this.charakter.berechneGesamtModifikator(modifikatoren);
      if (at > 0) {
        this.atLinks = String(at);
      } else {
        this.atLinks = '-';
      }
    }
  }

  berechnePAfuerWaffe(waffe: Kombigegenstand): void {
    const modifikatoren: string[] = ['VE'];
    modifikatoren.push('PA_' + waffe.ausruestung.name);

    if (this.localData.rechteHand.ausruestung.name === waffe.ausruestung.name) {
      modifikatoren.push('PA_Rechts');
      const pa = this.charakter.berechneGesamtModifikator(modifikatoren);
      if (pa > 0) {
        this.paRechts = String(pa);
      } else {
        this.paRechts = '-';
      }
    } else if (this.localData.linkeHand.ausruestung.name === waffe.ausruestung.name) {
      modifikatoren.push('PA_Links');
      const pa = this.charakter.berechneGesamtModifikator(modifikatoren);
      if (pa > 0) {
        this.paLinks = String(pa);
      } else {
        this.paLinks = '-';
      }
    }
  }

  /**
   * zu Beachten
   * 1. aktive AT-Mod
   * 2. linke/rechte Hand (außer beidhändig als sf/vorteil)
   * 3. kamptechnikwert
   * 4. atMod der Waffe
   * 5. mu / ff über 8 (-> /3)
   */
  berechneAT(rechts: boolean): number {
    // 1. aktive AT-Mod
    let at = this.charakter.calculateGesamtModifikationFuer('AT');
    if (rechts) {
      // 2. linke/rechte Hand (außer beidhändig als sf/vorteil)
      if (!this.charakter.hatSonderfertigkeit('Beidhändig') && this.charakter.hatVorUndNachteil('Linkshänder')) {
        at -= 4;
      }
      // 3. kamptechnikwert
      at += this.charakter.getCurrentWert((this.localData.rechteHand.gegenstand as Waffe).kampftechnik);

      if (this.localData.rechteHand.gegenstand.type === 'Nahkampfwaffe' || this.localData.rechteHand.gegenstand.type === 'Parierwaffe') {
        // 4. atMod der Waffe
        at += (this.localData.rechteHand.gegenstand as Nahkampfwaffe).atMod;

        // 5. mu / ff über 8 (-> /3)
        const mu = this.charakter.getCurrentWert('MU');
        if (mu > 8) {
          const diff: number = Math.floor((mu - 8) / 3);
          at += diff;
        }
        at += this.localData.sicht.atMod;
        at += this.localData.zielgroesse.atMod;
        const myRwIndex: number = this.waffenlaengen.indexOf((this.localData.rechteHand.gegenstand as Nahkampfwaffe).reichweite);
        const gegnerRwIndex: number = this.waffenlaengen.indexOf(this.localData.waffenlaenge);
        let rwMod: number = (myRwIndex - gegnerRwIndex) * 2;
        if (rwMod > 0) {
          rwMod = 0;
        }
        at += Number(rwMod);
        if (Number(this.localData.umgebung) === 1) {
          const waffe: Nahkampfwaffe = this.localData.rechteHand.gegenstand as Nahkampfwaffe;
          if (waffe.kampftechnik === 'Schilde') {
            console.log(this.localData.rechteHand.gegenstand);
            console.log(waffe);
            console.log('beengte Umgebung Schild: ' + (waffe as Parierwaffe).schildgroesse);
            if ((waffe as Parierwaffe).schildgroesse === 'klein') {
              console.log('-> klein');
              at += -2;
            } else if ((waffe as Parierwaffe).schildgroesse === 'mittel') {
              console.log('-> mittel');
              at += -4;
            } else if ((waffe as Parierwaffe).schildgroesse === 'groß') {
              console.log('-> groß');
              at += -6;
            }
          } else {
            at += -4 * this.waffenlaengen.indexOf(waffe.reichweite);
          }
        }
      } else {
        if (this.localData.rechteHand.gegenstand.name === 'Ifirnshavener Hailanze') {
          at -= 4;
        }
        // 5. mu / ff über 8 (-> /3)
        const ff = this.charakter.getCurrentWert('FF');
        if (ff > 8) {
          const diff: number = Math.floor((ff - 8) / 3);
          at += diff;
        }
        at += this.localData.distanzR.fkMod;
        at += this.localData.sicht.fkMod;
        at += this.localData.zielgroesse.fkMod;
        at += Number(this.localData.bewegung_ziel);
        at += Number(this.localData.bewegung_schuetze);
      }
    } else {
      // 2. linke/rechte Hand (außer beidhändig als sf/vorteil)
      if (!this.charakter.hatSonderfertigkeit('Beidhändig')) {
        at -= 4;
      }
      // 3. kamptechnikwert
      at += this.charakter.getCurrentWert((this.localData.linkeHand.gegenstand as Waffe).kampftechnik);

      if (this.localData.linkeHand.gegenstand.type === 'Nahkampfwaffe' || this.localData.linkeHand.gegenstand.type === 'Parierwaffe') {
        // 4. atMod der Waffe
        at += (this.localData.linkeHand.gegenstand as Nahkampfwaffe).atMod;

        // 5. mu / ff über 8 (-> /3)
        const mu = this.charakter.getCurrentWert('MU');
        if (mu > 8) {
          const diff: number = Math.floor((mu - 8) / 3);
          at += diff;
        }
        at += this.localData.sicht.atMod;
        at += this.localData.zielgroesse.atMod;
        const myRwIndex: number = this.waffenlaengen.indexOf((this.localData.linkeHand.gegenstand as Nahkampfwaffe).reichweite);
        const gegnerRwIndex: number = this.waffenlaengen.indexOf(this.localData.waffenlaenge);
        let rwMod: number = (myRwIndex - gegnerRwIndex) * 2;
        if (rwMod > 0) {
          rwMod = 0;
        }
        at += Number(rwMod);
        if (Number(this.localData.umgebung) === 1) {
          const waffe: Nahkampfwaffe = this.localData.linkeHand.gegenstand as Nahkampfwaffe;
          if (waffe.kampftechnik === 'Schilde') {
            if ((waffe as Parierwaffe).schildgroesse === 'klein') {
              at += -2;
            } else if ((waffe as Parierwaffe).schildgroesse === 'mittel') {
              at += -4;
            } else if ((waffe as Parierwaffe).schildgroesse === 'groß') {
              at += -6;
            }
          } else {
            at += -4 * this.waffenlaengen.indexOf(waffe.reichweite);
          }
        }
      } else {
        if (this.localData.linkeHand.gegenstand.name === 'Ifirnshavener Hailanze') {
          at -= 4;
        }
        // 5. mu / ff über 8 (-> /3)
        const ff = this.charakter.getCurrentWert('FF');
        if (ff > 8) {
          const diff: number = Math.floor((ff - 8) / 3);
          at += diff;
        }
        at += this.localData.distanzL.fkMod;
        at += this.localData.sicht.fkMod;
        at += this.localData.zielgroesse.fkMod;
        at += Number(this.localData.bewegung_ziel);
        at += Number(this.localData.bewegung_schuetze);
      }
    }
    return at;
  }

  /**
   * zu Beachten
   * 1. aktive VE-Mod
   * 2. linke/rechte Hand (außer beidhändig als sf/vorteil oder Schild)
   * 3. kamptechnikwert / 2
   * 4. paMod der Waffe
   * 5. le über 8 (-> /3)
   * 6. Fernkampfwaffe -> PA: 0
   */
  berechnePA(rechts: boolean): number {
    // 1. aktive VE-Mod
    let pa = this.charakter.calculateGesamtModifikationFuer('VE');
    pa += this.localData.sicht.veMod;
    if (rechts) {
      // 2. linke/rechte Hand (außer beidhändig als sf/vorteil oder Schild)
      if (!this.charakter.hatSonderfertigkeit('Beidhändig') && this.charakter.hatVorUndNachteil('Linkshänder')) {
        if ((this.localData.rechteHand.gegenstand as Waffe).kampftechnik !== 'Schilde') {
          pa -= 4;
        }
      }
      if (this.localData.rechteHand.gegenstand.type === 'Nahkampfwaffe' || this.localData.rechteHand.gegenstand.type === 'Parierwaffe') {
        const waffe = this.localData.rechteHand.gegenstand as Nahkampfwaffe;
        if (this.localData.linkeHand.gegenstand.type === 'Nahkampfwaffe' || this.localData.linkeHand.gegenstand.type === 'Parierwaffe') {
          const paWaffe = this.localData.linkeHand.gegenstand as Nahkampfwaffe;
          if (paWaffe.kampftechnik === 'Schilde') {
            pa += paWaffe.paMod;
          }
        }
        // 3. kamptechnikwert / 2
        pa += Math.round(this.charakter.getCurrentWert(waffe.kampftechnik) / 2.0);

        // 4. paMod der Waffe
        pa += waffe.paMod;
        if (waffe.kampftechnik === 'Schilde') {
          pa += waffe.paMod;
        }

        // 5. le über 8 (-> /3)
        const le = this.charakter.getCurrentWert(this.localData.rechteHandLe);
        if (le > 8) {
          const diff: number = Math.floor((le - 8) / 3);
          pa += diff;
        }
        if (Number(this.localData.umgebung) === 1) {
          if (waffe.kampftechnik === 'Schilde') {
            if ((waffe as Parierwaffe).schildgroesse === 'klein') {
              pa += -2;
            } else if ((waffe as Parierwaffe).schildgroesse === 'mittel') {
              pa += -4;
            } else if ((waffe as Parierwaffe).schildgroesse === 'groß') {
              pa += -6;
            }
          } else {
            pa += -4 * this.waffenlaengen.indexOf(waffe.reichweite);
          }
        }
        if (this.localData.zielgroesse.nurSchildOderAw && (this.localData.rechteHand.gegenstand as Waffe).kampftechnik !== 'Schilde') {
          pa = 0;
        }

      } else {
        // 2. Fernkampfwaffe -> PA: 0
        pa = 0;

      }
    } else {
      // 2. linke/rechte Hand (außer beidhändig als sf/vorteil oder Schild)
      if (!this.charakter.hatSonderfertigkeit('Beidhändig')) {
        if ((this.localData.linkeHand.gegenstand as Waffe).kampftechnik !== 'Schilde') {
          pa -= 4;
        }
      }

      if (this.localData.linkeHand.gegenstand.type === 'Nahkampfwaffe' || this.localData.linkeHand.gegenstand.type === 'Parierwaffe') {
        const waffe = this.localData.linkeHand.gegenstand as Nahkampfwaffe;
        if (this.localData.rechteHand.gegenstand.type === 'Nahkampfwaffe' || this.localData.rechteHand.gegenstand.type === 'Parierwaffe') {
          const paWaffe = this.localData.rechteHand.gegenstand as Nahkampfwaffe;
          if (paWaffe.kampftechnik === 'Schilde') {
            pa += paWaffe.paMod;
          }
        }
        // 3. kamptechnikwert / 2
        pa += Math.round(this.charakter.getCurrentWert(waffe.kampftechnik) / 2.0);

        // 4. paMod der Waffe
        pa += waffe.paMod;
        if (waffe.kampftechnik === 'Schilde') {
          pa += waffe.paMod;
        }
        // 5. le über 8 (-> /3)
        const le = this.charakter.getCurrentWert(this.localData.linkeHandLe);
        if (le > 8) {
          const diff: number = Math.floor((le - 8) / 3);
          pa += diff;
        }
        if (Number(this.localData.umgebung) === 1) {
          if (waffe.kampftechnik === 'Schilde') {
            if ((waffe as Parierwaffe).schildgroesse === 'klein') {
              pa += -2;
            } else if ((waffe as Parierwaffe).schildgroesse === 'mittel') {
              pa += -4;
            } else if ((waffe as Parierwaffe).schildgroesse === 'groß') {
              pa += -6;
            }
          } else {
            pa += -4 * this.waffenlaengen.indexOf(waffe.reichweite);
          }
        }
        if (this.localData.zielgroesse.nurSchildOderAw && (this.localData.linkeHand.gegenstand as Waffe).kampftechnik !== 'Schilde') {
          pa = 0;
        }
      } else {
        // 2. Fernkampfwaffe -> PA: 0
        pa = 0;
      }
    }
    return pa;
  }

  public persistenceCall(): void {
    this.persistence.storeComponent('kampfblatt', this.localData);
  }

  public waehleWaffe(rechts: boolean): void {
    if (rechts) {
      this.ziehbarRechts = new Kombigegenstand();
      this.ziehbarRechts.copy(this.ziebareWaffen[this.ziehbarRechtsIndex]);
    } else {
      this.ziehbarLinks = new Kombigegenstand();
      this.ziehbarLinks.copy(this.ziebareWaffen[this.ziehbarLinksIndex]);
    }
    console.log(this.ziehbarRechts);
    console.log(this.ziehbarLinks);
    this.persistence.storeComponent('kampfblatt', this.localData);
  }

  public wegstecken(rechts: boolean): void {
    if (rechts) {
      this.charakter.removeModifikator('AT_Links', 'Parierwaffe in rechter Hand');
      this.charakter.removeModifikator('PA_Links', 'Parierwaffe in rechter Hand');

      this.charakter.clearBeeinflWert('AT_' + this.localData.rechteHand.ausruestung.name);
      this.charakter.clearBeeinflWert('PA_' + this.localData.rechteHand.ausruestung.name);
      this.charakter.clearBeeinflWert('TP_' + this.localData.rechteHand.ausruestung.name);
      this.charakter.clearBeeinflWert('TP_Rechts');
      this.charakter.clearBeeinflWert('TP_Rechts_FK');
      this.localData.rechteHand = new Kombigegenstand();
      this.ermittleZiebareWaffen();
      this.charakterWaffenNK.forEach(waffe => {
        if (waffe.gegenstand.name === 'Fäuste') {
          this.localData.rechteHand.copy(waffe);
          this.localData.rechteHand.ausruestung.name = 'rechte Faust';
          this.initialisiereLe(this.localData.rechteHand, true);
        }
      });
    } else {
      this.charakter.removeModifikator('AT_Rechts', 'Parierwaffe in linker Hand');
      this.charakter.removeModifikator('PA_Rechts', 'Parierwaffe in linker Hand');

      this.charakter.clearBeeinflWert('AT_' + this.localData.linkeHand.ausruestung.name);
      this.charakter.clearBeeinflWert('PA_' + this.localData.linkeHand.ausruestung.name);
      this.charakter.clearBeeinflWert('TP_' + this.localData.linkeHand.ausruestung.name);
      this.charakter.clearBeeinflWert('TP_Links');
      this.charakter.clearBeeinflWert('TP_Links_FK');
      this.localData.linkeHand = new Kombigegenstand();
      this.ermittleZiebareWaffen();
      this.charakterWaffenNK.forEach(waffe => {
        if (waffe.gegenstand.name === 'Fäuste') {
          this.localData.linkeHand.copy(waffe);
          this.localData.linkeHand.ausruestung.name = 'linke Faust';
          this.initialisiereLe(this.localData.linkeHand, false);
        }
      });
    }
    if (!this.charakter.hatSonderfertigkeit('Beidhändig')) {
      if (this.charakter.hatVorUndNachteil('Linkshänder')) {
        this.charakter.setBeeinflWert('AT_Rechts', 'falsche Hand', '-4');
        this.charakter.removeModifikator('AT_Links', 'falsche Hand');
        this.charakter.setBeeinflWert('PA_Rechts', 'falsche Hand', '-4');
        this.charakter.removeModifikator('PA_Links', 'falsche Hand');
      } else {
        this.charakter.setBeeinflWert('AT_Links', 'falsche Hand', '-4');
        this.charakter.removeModifikator('AT_Rechts', 'falsche Hand');
        this.charakter.setBeeinflWert('PA_Links', 'falsche Hand', '-4');
        this.charakter.removeModifikator('PA_Rechts', 'falsche Hand');
      }
    }
    if ((this.localData.rechteHand.gegenstand as Waffe).kampftechnik === 'Schilde') {
      this.charakter.removeModifikator('PA_Rechts', 'falsche Hand');
    }
    if ((this.localData.linkeHand.gegenstand as Waffe).kampftechnik === 'Schilde') {
      this.charakter.removeModifikator('PA_Links', 'falsche Hand');
    }
    this.waehleKampfModifikatoren(rechts);
    this.berechneGrundTPFuerBeideWaffen();
    this.ermittlePaUndTpDurchLE();
    this.berechneWaffenWerte();
    this.persistence.storeComponent('kampfblatt', this.localData);
  }

  public berechneGrundwerteFuerWaffe(waffe: Kombigegenstand): void {
    // Kampftechnik
    const wert: number = this.charakter.getCurrentWert((waffe.gegenstand as Waffe).kampftechnik);
    this.charakter.setBeeinflWert('AT_' + waffe.ausruestung.name, 'Kampftechnik', String(wert));
    this.charakter.setBeeinflWert('PA_' + waffe.ausruestung.name, 'Kampftechnik / 2', String(Math.round(wert / 2)));
    // Zustand der Waffe
    if (waffe.ausruestung.zustand > 0) {
      if (waffe.ausruestung.zustand < 3) {
        const malus: number = -waffe.ausruestung.zustand;
        this.charakter.setBeeinflWert('AT_' + waffe.ausruestung.name, 'Waffenzustand', String(malus));
        this.charakter.setBeeinflWert('PA_' + waffe.ausruestung.name, 'Waffenzustand', String(malus));
      } else {
        this.charakter.setBeeinflWert('AT_' + waffe.ausruestung.name, 'Waffenzustand', 'x0');
        this.charakter.setBeeinflWert('PA_' + waffe.ausruestung.name, 'Waffenzustand', 'x0');
      }
    }
    // AT-/PA-Mod der Waffe
    if (waffe.gegenstand.type !== 'Fernkampfwaffe') {
      const nkWaffe: Nahkampfwaffe = (waffe.gegenstand as Nahkampfwaffe);
      this.charakter.setBeeinflWert('AT_' + waffe.ausruestung.name, 'AT-Mod Waffe', String(nkWaffe.atMod));
      if (nkWaffe.kampftechnik === 'Schilde') {
        this.charakter.setBeeinflWert('PA_' + waffe.ausruestung.name, 'PA-Mod Waffe', String(nkWaffe.paMod * 2));
      } else {
        this.charakter.setBeeinflWert('PA_' + waffe.ausruestung.name, 'PA-Mod Waffe', String(nkWaffe.paMod));
      }
      this.charakter.removeModifikator('PA_' + waffe.ausruestung.name, 'Waffe ist Fernkampfwaffe');
    } else {
      this.charakter.setBeeinflWert('PA_' + waffe.ausruestung.name, 'Waffe ist Fernkampfwaffe', 'x0');
    }
    // this.ermittleMunition(waffe);
  }

  public zieheWaffe(rechts: boolean): void {
    this.waehleWaffe(rechts);
    if (rechts) {
      console.log(this.ziehbarRechts);
      this.localData.rechteHand = new Kombigegenstand();
      this.localData.rechteHand.copy(this.ziehbarRechts);
      if ((this.localData.rechteHand.gegenstand as Waffe).kampftechnik === 'Schilde') {
        this.charakter.removeModifikator('PA_Rechts', 'falsche Hand');
      }
      if (this.localData.rechteHand.gegenstand.type === 'Parierwaffe') {
        const atMod = (this.localData.rechteHand.gegenstand as Parierwaffe).passiverBonusAT;
        const paMod = (this.localData.rechteHand.gegenstand as Parierwaffe).passiverBonusPA;
        this.charakter.setBeeinflWert('AT_Links', 'Parierwaffe in rechter Hand', String(atMod));
        this.charakter.setBeeinflWert('PA_Links', 'Parierwaffe in rechter Hand', String(paMod));
      } else {
        this.charakter.removeModifikator('AT_Links', 'Parierwaffe in rechter Hand');
        this.charakter.removeModifikator('PA_Links', 'Parierwaffe in rechter Hand');
      }
      this.berechneGrundwerteFuerWaffe(this.localData.rechteHand);
      if ((this.localData.rechteHand.gegenstand as Waffe).zweihand) {
        this.wegstecken(false);
      }
    } else {
      console.log(this.ziehbarLinks);
      this.localData.linkeHand = new Kombigegenstand();
      this.localData.linkeHand.copy(this.ziehbarLinks);
      if ((this.localData.linkeHand.gegenstand as Waffe).kampftechnik === 'Schilde') {
        this.charakter.removeModifikator('PA_Links', 'falsche Hand');
      }
      if (this.localData.linkeHand.gegenstand.type === 'Parierwaffe') {
        const atMod = (this.localData.linkeHand.gegenstand as Parierwaffe).passiverBonusAT;
        const paMod = (this.localData.linkeHand.gegenstand as Parierwaffe).passiverBonusPA;
        this.charakter.setBeeinflWert('AT_Rechts', 'Parierwaffe in linker Hand', String(atMod));
        this.charakter.setBeeinflWert('PA_Rechts', 'Parierwaffe in linker Hand', String(paMod));
      } else {
        this.charakter.removeModifikator('AT_Rechts', 'Parierwaffe in linker Hand');
        this.charakter.removeModifikator('PA_Rechts', 'Parierwaffe in linker Hand');
      }
      this.berechneGrundwerteFuerWaffe(this.localData.linkeHand);
      if ((this.localData.linkeHand.gegenstand as Waffe).zweihand) {
        this.wegstecken(true);
      }
    }
    this.initialisiereLe(this.localData.linkeHand, false);
    this.initialisiereLe(this.localData.rechteHand, true);
    this.berechneGrundTPFuerBeideWaffen();
    this.ermittlePaUndTpDurchLE();
    this.ermittleZiebareWaffen();
    this.ermittleAlleSchussdistanzen();

    this.waehleKampfModifikatoren(rechts);
    this.berechneWaffenWerte();
    this.persistence.storeComponent('kampfblatt', this.localData);
  }

  public waehleDistanz(rechts: boolean): void {
    if (rechts) {
      this.localData.distanzR = this.schussdistanzenR[this.localData.distanzRIndex];
      if (this.localData.rechteHand.gegenstand.type === 'Fernkampfwaffe') {
        this.charakter.setBeeinflWert('AT_' + this.localData.rechteHand.ausruestung.name, 'Distanz', String(this.localData.distanzR.fkMod));
        this.charakter.setBeeinflWert('TP_Rechts_FK', 'Distanz', String(this.localData.distanzR.tpMod));
      } else {
        this.charakter.removeModifikator('AT_' + this.localData.rechteHand.ausruestung.name, 'Distanz');
      }

    } else {
      this.localData.distanzL = this.schussdistanzenL[this.localData.distanzLIndex];
      if (this.localData.linkeHand.gegenstand.type === 'Fernkampfwaffe') {
        this.charakter.setBeeinflWert('AT_' + this.localData.linkeHand.ausruestung.name, 'Distanz', String(this.localData.distanzL.fkMod));
        this.charakter.setBeeinflWert('TP_Links_FK', 'Distanz', String(this.localData.distanzL.tpMod));
      } else {
        this.charakter.removeModifikator('AT_' + this.localData.linkeHand.ausruestung.name, 'Distanz');
      }
    }
    this.berechneWaffenWerte();
    this.persistenceCall();
  }


  public waehleEigeneBewegung(): void {
    this.charakter.setBeeinflWert('AT_FK', 'eigene Bewegung', String(this.localData.bewegung_schuetze));
    this.berechneWaffenWerte();
    this.ping.emit();
    this.persistenceCall();
  }

  public waehleLeiteigenschaft(le: string, waffe: string): void {

  }

  waehleKampfModifikatoren(rechts: boolean): void {
    this.waehleWaffenlaengeGegner();
    this.waehleDistanz(rechts);
    this.waehleSicht();
    this.waehleZielgroesse();
    this.waehleUmgebung();
    this.waehleEigeneBewegung();
    this.waehleZielBewegung();
  }

  public waehleSicht(): void {
    this.localData.sicht = this.kampfdaten.sichtModifikatoren[this.localData.sichtIndex];
    this.charakter.setBeeinflWert('AT_NK', 'Sicht', String(this.localData.sicht.atMod));
    this.charakter.setBeeinflWert('VE', 'Sicht', String(this.localData.sicht.atMod));
    this.charakter.setBeeinflWert('AW', 'Sicht', String(this.localData.sicht.atMod));
    this.charakter.setBeeinflWert('AT_FK', 'Sicht', String(this.localData.sicht.fkMod));
    this.persistenceCall();
    this.berechneWaffenWerte();
    this.ping.emit();
  }

  public waehleUmgebung(): void {
    if (Number(this.localData.umgebung) === 1) {
      console.log(this.localData.linkeHand);
      this.berechneUmgebungsModifikator(this.localData.linkeHand);
      console.log(this.localData.rechteHand);
      this.berechneUmgebungsModifikator(this.localData.rechteHand);
    } else {
      this.charakter.removeModifikator('AT_' + this.localData.rechteHand.ausruestung.name, 'beengte Umgebung');
      this.charakter.removeModifikator('AT_' + this.localData.linkeHand.ausruestung.name, 'beengte Umgebung');
      this.charakter.removeModifikator('PA_' + this.localData.rechteHand.ausruestung.name, 'beengte Umgebung');
      this.charakter.removeModifikator('PA_' + this.localData.linkeHand.ausruestung.name, 'beengte Umgebung');
    }
    this.persistenceCall();
    this.berechneWaffenWerte();
    this.ping.emit();
  }

  berechneUmgebungsModifikator(kombiWaffe: Kombigegenstand): void {

    let at = 0;
    let pa = 0;
    let waffe: Nahkampfwaffe | Parierwaffe;
    if (kombiWaffe.gegenstand.type === 'Nahkampfwaffe') {
      waffe = kombiWaffe.gegenstand as Nahkampfwaffe;
    } else if (kombiWaffe.gegenstand.type === 'Parierwaffe') {
      waffe = kombiWaffe.gegenstand as Parierwaffe;
    }
    if (waffe != null) {
      if (waffe.kampftechnik === 'Schilde') {
        if ((waffe as Parierwaffe).schildgroesse === 'klein') {
          console.log('-> klein');
          at = -2;
          pa = -2;
        } else if ((waffe as Parierwaffe).schildgroesse === 'mittel') {
          console.log('-> mittel');
          at = -4;
          pa = -3;
        } else if ((waffe as Parierwaffe).schildgroesse === 'groß') {
          console.log('-> groß');
          at = -6;
          pa = -4;
        }
      } else {
        at = -4 * this.waffenlaengen.indexOf(waffe.reichweite);
        pa = -4 * this.waffenlaengen.indexOf(waffe.reichweite);
      }
    }
    if (at !== 0) {
      this.charakter.setBeeinflWert('AT_' + kombiWaffe.ausruestung.name, 'beengte Umgebung', String(at));
    } else {
      this.charakter.removeModifikator('AT_' + kombiWaffe.ausruestung.name, 'beengte Umgebung');
    }
    if (pa !== 0) {
      this.charakter.setBeeinflWert('PA_' + kombiWaffe.ausruestung.name, 'beengte Umgebung', String(pa));
    } else {
      this.charakter.removeModifikator('PA_' + kombiWaffe.ausruestung.name, 'beengte Umgebung');
    }
  }

  public waehleWaffenlaengeGegner(): void {
    console.log('waehleWaffenlaengeGegner');
    const myRwIndexL: number = this.waffenlaengen.indexOf((this.localData.linkeHand.gegenstand as Nahkampfwaffe).reichweite);
    const myRwIndexR: number = this.waffenlaengen.indexOf((this.localData.rechteHand.gegenstand as Nahkampfwaffe).reichweite);
    const gegnerRwIndex: number = this.waffenlaengen.indexOf(this.localData.waffenlaenge);
    const rwModL: number = (myRwIndexL - gegnerRwIndex) * 2;
    const rwModR: number = (myRwIndexR - gegnerRwIndex) * 2;
    console.log(rwModL);
    console.log(rwModR);
    if (rwModL < 0 && this.localData.linkeHand.gegenstand.type !== 'Fernkampfwaffe') {
      this.charakter.setBeeinflWert('AT_Links', 'Reichweite Gegner', String(rwModL));
    } else {
      this.charakter.removeModifikator('AT_Links', 'Reichweite Gegner');
    }
    if (rwModR < 0 && this.localData.rechteHand.gegenstand.type !== 'Fernkampfwaffe') {
      this.charakter.setBeeinflWert('AT_Rechts', 'Reichweite Gegner', String(rwModR));
    } else {
      this.charakter.removeModifikator('AT_Rechts', 'Reichweite Gegner');
    }
    console.log(this.charakter.getAlleBeeinflWerteMulti(['AT', 'AT_Rechts', 'AT_NK', 'AT_' + this.localData.rechteHand.ausruestung.name]));
    this.ping.emit();
    this.persistenceCall();
    this.berechneWaffenWerte();
  }

  public waehleZielBewegung(): void {
    this.charakter.setBeeinflWert('AT_FK', 'Bewegung des Ziels', String(this.localData.bewegung_ziel));
    this.berechneWaffenWerte();
    this.ping.emit();
    this.persistenceCall();
  }

  public waehleZielgroesse(): void {
    this.localData.zielgroesse = this.kampfdaten.zielGroesseModifikatoren[this.localData.zielgroesseIndex];
    this.charakter.setBeeinflWert('AT_NK', 'Zielgröße', String(this.localData.zielgroesse.atMod));
    this.charakter.setBeeinflWert('AT_FK', 'Zielgröße', String(this.localData.zielgroesse.fkMod));
    if (this.localData.zielgroesse.nurSchildOderAw || this.localData.zielgroesse.nurAw) {
      if (this.localData.zielgroesse.nurAw) {
        this.charakter.setBeeinflWert('PA_Links', 'Zielgröße', 'x0');
        this.charakter.setBeeinflWert('PA_Rechts', 'Zielgröße', 'x0');
      } else {
        if ((this.localData.rechteHand.gegenstand as Waffe).kampftechnik !== 'Schilde') {
          this.charakter.setBeeinflWert('PA_Rechts', 'Zielgröße', 'x0');
        } else {
          this.charakter.removeModifikator('PA_Rechts', 'Zielgröße');
        }
        if ((this.localData.linkeHand.gegenstand as Waffe).kampftechnik !== 'Schilde') {
          this.charakter.setBeeinflWert('PA_Links', 'Zielgröße', 'x0');
        } else {
          this.charakter.removeModifikator('PA_Links', 'Zielgröße');
        }
      }
    } else {
      this.charakter.removeModifikator('PA_Rechts', 'Zielgröße');
      this.charakter.removeModifikator('PA_Links', 'Zielgröße');
    }
    this.persistenceCall();
    this.berechneWaffenWerte();
    this.ping.emit();
  }

  public sendeNachricht(nachricht: string): void {
    this.websocket.send(nachricht);
  }

  ermittleRuestung(): void {
    console.log('ermittleRuestung');
    this.rsKopf = 0;
    this.rsTorso = 0;
    this.rslArm = 0;
    this.rsrArm = 0;
    this.rslBein = 0;
    this.rsrBein = 0;
    this.charakter.ausruestung.forEach(item => {
      if (item.trageort === 'Körper') {
        this.glossar.ausruestung.forEach(gi => {
          if (gi.id === item.itemid && gi.type === 'Rüstung') {
            const armor: Ruestung = gi as Ruestung;
            console.log(armor);
            console.log(armor.rsKopf);
            console.log(this.rsKopf + ' + ' + armor.rsKopf + ' = ' + (this.rsKopf + Number(armor.rsKopf)));
            this.rsKopf += Number(armor.rsKopf);
            this.rsTorso += Number(armor.rsTorso);
            this.rslArm += Number(armor.rslArm);
            this.rsrArm += Number(armor.rsrArm);
            this.rslBein += Number(armor.rslBein);
            this.rsrBein += Number(armor.rsrBein);
          }
        });
      }
    });
    const rs: number[] = [this.rsKopf, this.rsTorso, this.rslArm, this.rsrArm,
      this.rslBein, this.rsrBein]; // Kopf, Torso, LArm, RArm, LBein, RBein
    const mult: number[] = [1, 5, 2, 2, 2, 2]; // Kopf, Torso, LArm, RArm, LBein, RBein

    let rsGesamt  = 0;
    for (let i = 0; i < rs.length; i++) {
      rsGesamt += rs[i] * mult[i];
    }
    if (rsGesamt < 1) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '0');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '0');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '0');
    } else if (rsGesamt < 15) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-1');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-1');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '0');
    } else if (rsGesamt < 29) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-1');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-1');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '1');
    } else if (rsGesamt < 43) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-2');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-2');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '1');
    } else if (rsGesamt < 57) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-2');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-2');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '2');
    } else if (rsGesamt < 71) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '2');
    } else if (rsGesamt < 85) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '3');
    } else if (rsGesamt < 99) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '4');
    } else if (rsGesamt < 113) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '5');
    } else if (rsGesamt < 127) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '6');
    } else if (rsGesamt < 141) {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '7');
    } else {
      this.charakter.setBeeinflWert('GS', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('INI', 'Rüstung', '-3');
      this.charakter.setBeeinflWert('Belastung', 'Rüstung', '8');
    }

  }

  public getWunden(): void {
    this.getWKopf();
    this.getWTorso();
    this.getWLArm();
    this.getWRArm();
    this.getWLBein();
    this.getWRBein();
  }

  public getWKopf(): void {
    const wKopfBW: BeeinflussteWerte = this.charakter.getBeeinflWertIfVorhanden('MU', 'Wunde_Kopf');
    let val = 0;
    if (wKopfBW != null) {
      val = wKopfBW.getNumericWert();
    }
    this.wKopf = val;
    this.changeWoundKopf();
  }

  // MU, KL, IN -1, INI -2
  public changeWoundKopf(): void {
    const mod = 'Wunde_Kopf';
    this.charakter.clearModifikator(mod);
    this.charakter.setBeeinflWert('MU', mod, this.wKopf + '');
    this.charakter.setBeeinflWert('KL', mod, this.wKopf + '');
    this.charakter.setBeeinflWert('IN', mod, this.wKopf + '');
    this.charakter.setBeeinflWert('INI', mod, (this.wKopf * 2) + '');
    if (this.wKopf === 3) {
      this.charakter.setBeeinflWert('Betäubung', mod, '4');
    }
    this.ping.emit();
  }

  public getWTorso(): void {
    const wTorsoBW: BeeinflussteWerte = this.charakter.getBeeinflWertIfVorhanden('AT', 'Wunde_Torso');
    let val = 0;
    if (wTorsoBW != null) {
      val = wTorsoBW.getNumericWert();
    }
    this.wTorso = val;
    this.changeWoundTorso();
  }

  // AT, PA, VW, KO, KK -1
  public changeWoundTorso(): void {
    const mod = 'Wunde_Torso';
    this.charakter.clearModifikator(mod);
    this.charakter.setBeeinflWert('AT', mod, this.wTorso + '');
    this.charakter.setBeeinflWert('PA', mod, this.wTorso + '');
    this.charakter.setBeeinflWert('VW', mod, this.wTorso + '');
    this.charakter.setBeeinflWert('KO', mod, this.wTorso + '');
    this.charakter.setBeeinflWert('KK', mod, this.wTorso + '');
    if (this.wTorso === 3) {
      this.charakter.setBeeinflWert('Betäubung', mod, '4');
    }
    this.ping.emit();
  }

  public getWLArm(): void {
    const wBW: BeeinflussteWerte = this.charakter.getBeeinflWertIfVorhanden('AT', 'Wunde_LArm');
    let val = 0;
    if (wBW != null) {
      val = wBW.getNumericWert();
    }
    this.wlArm = val;
    this.changeWoundLArm()
  }

  // AT, PA, VW, KK -1, FF -2
  public changeWoundLArm(): void {
    const mod = 'Wunde_LArm';
    this.charakter.clearModifikator(mod);
    this.charakter.setBeeinflWert('AT', mod, this.wlArm + '');
    this.charakter.setBeeinflWert('PA', mod, this.wlArm + '');
    this.charakter.setBeeinflWert('VW', mod, this.wlArm + '');
    this.charakter.setBeeinflWert('FF', mod, (this.wlArm * 2) + '');
    this.charakter.setBeeinflWert('KK', mod, this.wlArm + '');
    this.ping.emit();
  }

  public getWRArm(): void {
    const wBW: BeeinflussteWerte = this.charakter.getBeeinflWertIfVorhanden('AT', 'Wunde_RArm');
    let val = 0;
    if (wBW != null) {
      val = wBW.getNumericWert();
    }
    this.wrArm = val;
    this.changeWoundRArm();
  }

  // AT, PA, VW, KK -1, FF -2
  public changeWoundRArm(): void {
    const mod = 'Wunde_RArm';
    this.charakter.clearModifikator(mod);
    this.charakter.setBeeinflWert('AT', mod, this.wrArm + '');
    this.charakter.setBeeinflWert('PA', mod, this.wrArm + '');
    this.charakter.setBeeinflWert('VW', mod, this.wrArm + '');
    this.charakter.setBeeinflWert('FF', mod, (this.wrArm * 2) + '');
    this.charakter.setBeeinflWert('KK', mod, this.wrArm + '');
    this.ping.emit();
  }

  public getWLBein(): void {
    const wBW: BeeinflussteWerte = this.charakter.getBeeinflWertIfVorhanden('AT', 'Wunde_LBein');
    let val = 0;
    if (wBW != null) {
      val = wBW.getNumericWert();
    }
    this.wlBein = val;
    this.changeWoundLBein();
  }

  // AT, PA, VW, GE, GS -1, INI -2
  public changeWoundLBein(): void {
    const mod = 'Wunde_LBein';
    this.charakter.clearModifikator(mod);
    this.charakter.setBeeinflWert('AT', mod, this.wlBein + '');
    this.charakter.setBeeinflWert('PA', mod, this.wlBein + '');
    this.charakter.setBeeinflWert('VW', mod, this.wlBein + '');
    this.charakter.setBeeinflWert('GE', mod, this.wlBein + '');
    this.charakter.setBeeinflWert('GS', mod, this.wlBein + '');
    this.charakter.setBeeinflWert('INI', mod, (this.wlBein * 2) + '');
    this.ping.emit();
  }

  public getWRBein(): void {
    const wBW: BeeinflussteWerte = this.charakter.getBeeinflWertIfVorhanden('AT', 'Wunde_RBein');
    let val = 0;
    if (wBW != null) {
      val = wBW.getNumericWert();
    }
    this.wrBein = val;
    this.changeWoundRBein();
  }

  // AT, PA, VW, GE, GS -1, INI -2
  public changeWoundRBein(): void {
    const mod = 'Wunde_RBein';
    this.charakter.clearModifikator(mod);
    this.charakter.setBeeinflWert('AT', mod, this.wrBein + '');
    this.charakter.setBeeinflWert('PA', mod, this.wrBein + '');
    this.charakter.setBeeinflWert('VW', mod, this.wrBein + '');
    this.charakter.setBeeinflWert('GE', mod, this.wrBein + '');
    this.charakter.setBeeinflWert('GS', mod, this.wrBein + '');
    this.charakter.setBeeinflWert('INI', mod, (this.wrBein * 2) + '');
    this.ping.emit();
  }

  public click_sf_info(sf: any): void {
    if (sf.name === this.sf_info.name) {
      this.sf_info = {
        name: '',
        kampfsftyp: '',
        kampftechniken: '',
        stufe: 0,
        spezifikation: '',
        gewaehlteKategorie: '',
        regel: '',
        erschwernis: '',
      };
    } else {
      this.sf_info = sf;
    }
  }

}
