import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Charakter} from '../../_interfaces/charakter';
import {Notiz} from '../../_classes/charakter/notiz';
import {Heldin} from '../../_classes/charakter/heldin';

@Component({
  selector: 'app-notizen',
  templateUrl: './notizen.component.html',
  styleUrls: ['./notizen.component.sass']
})
export class NotizenComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  aktuelleNotiz: Notiz = new Notiz();


  constructor() { }

  ngOnInit(): void {
  }

  fuegeNotizHinzu(): void {
    // this.entferneNotiz(this.aktuelleNotiz);
    if (this.charakter.notizen == null) {
      this.charakter.notizen = [];
    }
    let vorhanden = false;
    this.charakter.notizen.forEach(n => {
      if (n.name === this.aktuelleNotiz.name) {
        vorhanden = true;
      }
    });
    if (this.aktuelleNotiz.name !== '' && !vorhanden && this.aktuelleNotiz.name != null) {
      this.charakter.notizen.push(Object.assign({}, this.aktuelleNotiz));
      this.aktuelleNotiz = new Notiz();
      this.ping.emit();
    }
  }

  aendereNotiz(notiz: Notiz): void {
    this.aktuelleNotiz = Object.assign({}, notiz);
    this.ping.emit();
  }

  entferneNotiz(notiz: Notiz): void {
    const index = this.charakter.notizen.indexOf(notiz);
    const behalten: Notiz[] = [];
    for (let i = 0; i < index; i++) {
      behalten.push(this.charakter.notizen.shift());
    }
    this.charakter.notizen.shift();
    behalten.forEach(n => {
      this.charakter.notizen.unshift(n);
    });
    this.ping.emit();
  }
}
