import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../_interfaces/user';
import {VorUndNachteil} from '../../_interfaces/vor-und-nachteil';
import {DataService} from '../../_services/data.service';
import {Zauber} from '../../_interfaces/zauber';
import {Zaubertrick} from '../../_interfaces/zaubertrick';
import {Profession} from '../../_interfaces/profession';
import {Session} from 'inspector';
import {Glossar} from '../../_classes/glossar/glossar';
import {StringEvent} from '../../_interfaces/events/string-event';
import {WebsocketService} from '../../_services/websocket.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {HeldenblattCommService} from '../../_services/heldenblatt-comm.service';
import {Gegenstand} from '../../_classes/ausruestung/gegenstand';
import {Auswahlbildschirm} from '../../_interfaces/components/auswahlbildschirm';
import {NameWert} from '../../_classes/name-wert';
import {AbenteuerService} from '../../_services/abenteuer.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Nachricht} from '../../_classes/comm/nachricht';
import {Werte} from '../../_classes/charakter/werte';
import {Wetter} from '../../_classes/abenteuer/wetter';

@Component({
  selector: 'app-spielmeisteroptionen',
  templateUrl: './spielmeisteroptionen.component.html',
  styleUrls: ['./spielmeisteroptionen.component.sass']
})
export class SpielmeisteroptionenComponent implements OnInit {
  @Output() reload: EventEmitter<any> = new EventEmitter<any>();
  @Input() glossar: Glossar;
  @Input() localData: Auswahlbildschirm;

  destroyed$ = new Subject();

  /** styles */
  lampColor = 'red';

  /** Kommunikation */
  messages: string[];
  objectMessages = new Map<string, object>();

  public $option: number;
  public $message = '';

  public $vorUndNachteil: VorUndNachteil;
  public $alleVorUndNachteile: VorUndNachteil[];
  public $vunIndex: number;

  public $zauber: Zauber;
  public $probeString: string;
  public $erweiterungString: string;
  public $erweiterungenArray: string[];
  public $wirkungQSString: string;
  public $dauerBisSteigerung: string;

  public $zaubertrick: Zaubertrick;
  public $infostring: string;

  public $profession: Profession;
  public $alleProfessionen: Profession[];
  public $profIndex: number;
  public voraussetzungenStr: string;
  public sonderfertigkeitenStr: string;
  public kampftechnikenStr: string;
  public talenteStr: string;
  public zauberStr: string;
  public liturgienStr: string;
  public empfohleneVorteileStr: string;
  public empfohleneNachteileStr: string;
  public ungeeigneteVorteileStr: string;
  public ungeeigneteNachteileStr: string;
  public infokastenString: string;
  public keyWords = ['Voraussetzungen:', 'Sonderfertigkeiten:', 'Kampftechniken:', 'Talente:', 'Zauber:', 'Liturgien:',
    'Empfohlene Vorteile:', 'Empfohlene Nachteile:', 'Ungeeignete Vorteile:', 'Ungeeignete Nachteile:'];
  public infos: string[];


  /** Umgebung und Wetter */
  aktRegion = 'Dschungel';
  regions = ['Dschungel', 'Eis- und Schnee', 'Gebirge', 'Höhle', 'Kulturland mittelreichisch', 'Kulturland tulamidisch',
    'Meer', 'Steppe', 'Sumpf', 'Wald', 'Wüste'];
  // wetter: Wetter = new Wetter();
  wettervorhersagen: string[] = ['Wetter heute', 'Wetter morgen', 'Wetter übermorgen'];
  dreiTageWetter: Wetter[] = [];
  wetterWolken = new Map<string, string>();
  wetterNiederschlag = new Map<string, string>();
  wetterWind: string[] = ['windstill', 'leichter Wind', 'sanfte Brise', 'frische Brise', 'steife Brise', 'starker Wind', 'Sturm'];
  inWueste = false;
  jahreszeit: string;
  jahreszeiten: string[] = ['Frühling', 'Sommer', 'Herbst', 'Winter'];
  windigeRegion = false;
  geoRegion: string[] = ['Ewiges Eis', 'Höhen des Ehernen Schwerts', 'Hoher Norden', 'Tundra und Taiga',
    'Bornland, Thorwal', 'Streitende Königreiche bis Weiden', 'Zentrales Mittelreich',
    'Nördliches Horasreich, Almada, Aranien', 'Höhen des Raschtulswalls',
    'Südliches Horasreich, Reich der Ersten Sonne', 'Khôm', 'Echsensümpfe, Meridiana',
    'Altoum, Gewürzinseln, Südmeer'];
  region = 'Zentrales Mittelreich';


  datum = '';
  datumZG = '';
  datumThorwal = '';
  datumZwerge = '';
  datumIrdisch = '';

  abenteuerDaten: NameWert[] = [];

  verbundenIds: string[] = [];
  verbunden: string[] = [];
  portraits: string[] = [];

  /** Websocket Kommunikation */
  ausgewaehlterCharakter = '';
  ausgewaehlterCharakterId = '';
  kategorie = 'Talent';
  angefragtes: Werte[] = [];

  constructor(public dataService: DataService,
              private websocket: WebsocketService,
              private sharedService: HeldenblattCommService,
              private abenteuerService: AbenteuerService,
              private sanitizer: DomSanitizer) {
    this.$option = 0;
    this.$vorUndNachteil = {
      vorteil: true,
      name: '',
      stufen: 1,
      apKosten: 0, // pro Stufe
      mehrfachKathegorie: '', // Kampftechniken, Talente, ...
      gegensatz: '',
      voraussetzungen: '',
      beschreibung: '',
      regel: '',
      userinput: false
    };
    this.$vunIndex = 0;
    this.$profIndex = 0;
    this.$zauber = {
      name: '',
      ritual: true,
      beschreibung: '',
      probe: [],
      probenMod: '',
      wirkung: '',
      wirkungQS: [],
      zauberDauer: '',
      aspKosten: '',
      reichweite: '',
      wirkungsDauer: '',
      zielKathegorie: '',
      merkmal: '',
      verbreitung: '',
      steigerungskosten: '',
      erweiterungen: [],
      fw: 0
    };
    this.$probeString = '';
    this.$erweiterungenArray = [];
    this.loadVuN();
    this.getAlleProfessionen();
    this.$zaubertrick = {
      name: '',
      beschreibung: '',
      reichweite: '',
      wirkungsDauer: '',
      zielKathegorie: '',
      merkmal: '',
      verbreitung: ''
    };
    this.$infostring = '';
    this.wetterWolken.set('völlig wolkenlos', '☀');
    this.wetterWolken.set('einzelne Wolken', '🌤');
    this.wetterWolken.set('bewölkt mit Wolkenlücken', '🌥');
    this.wetterWolken.set('geschlossene Wolkendecke', '☁');
    this.wetterWolken.set('Gewitter', '🌩');

    this.wetterNiederschlag.set('kein Niederschlag', '☁');
    this.wetterNiederschlag.set('Nieselregen', '🌫');
    this.wetterNiederschlag.set('ergiebiger Regen', '🌦');
    this.wetterNiederschlag.set('Wolkenbruch', '🌧');

    this.wetterNiederschlag.set('Nieselregen', '🌫');
    this.wetterNiederschlag.set('leichte Hagelschauer', '🌩');
    this.wetterNiederschlag.set('starke Hagelschauer', '⛈');

    this.wetterNiederschlag.set('leichter Schneefall', '❄');
    this.wetterNiederschlag.set('ergiebiger Scheefall', '🌨');
    this.wetterNiederschlag.set('heftiger Schneefall', '☃');
  }

  ngOnInit(): void {
    this.$option = Number(sessionStorage.getItem('GmNav'));
    if (isNaN(this.$option)) {
      this.$option = 0;
    }

    this.messages = [];
    this.handleWS();
  }

  public handleWS(): void {
    const chatSub$ = this.websocket.connectWithoutUsername('spielmeister').pipe(
      takeUntil(this.destroyed$),
    );
    const abenteuerWS = this.websocket.connect('GM', 'abenteuer').pipe(
      takeUntil(this.destroyed$),
    );
    chatSub$.subscribe((data: string) => {
      let message = JSON.stringify(data);
      message = message.substring(1, message.length - 1);
      console.log(message);
      const kategory: string = message.substring(1, 4);
      const content: string = message.substring(5);
      if (message.startsWith('[obj]')) {
        this.handleObjectOld(content);
      } else if (message.startsWith('[msg]')) {
        this.handleMessageOld(content);
      } else if (message.startsWith('[cmd]')) {
        this.handleCommandOld(content);
      }
    });
    abenteuerWS.subscribe((message: string) => {
      console.log(message);
      const nachricht: Nachricht = JSON.parse(message);
      console.log(nachricht);
      if (nachricht.typ === 'obj') {
        this.handleObject(nachricht);
      } else if (nachricht.typ === 'cmd'){
        this.handleCommand(nachricht);
      } else if (nachricht.typ === 'msg') {
        this.handleMessage(nachricht);
      }
    });
  }

  public getVerbundeneCharaktere(): void {
    const nachricht: Nachricht = new Nachricht();
    nachricht.sender = 'GM';
    nachricht.reciever = 'WS';
    nachricht.typ = 'cmd';
    nachricht.body = 'getVerbundeneCharaktere()';
    // this.websocket.sendWithProtocol('GM', 'WS', '[cmd]getVerbundeneCharaktere()');
    this.websocket.sendNachricht(nachricht);
  }

  public toggleConnection(): void {
    if (this.lampColor === 'green') {
      this.websocket.closeConnection();
      this.lampColor = 'red';
    } else {
      this.handleWS();
    }
  }

  public updateRegion(): void {
    const nachricht: Nachricht = new Nachricht();
    nachricht.sender = 'GM';
    nachricht.reciever = 'alle';
    nachricht.typ = 'obj';
    nachricht.subtyp = 'rgn';
    nachricht.body = this.aktRegion;
    // this.websocket.sendWithProtocol('GM', 'alle', '[obj][rgn]' + this.aktRegion);
    this.websocket.sendNachricht(nachricht);

  }

  public updateDate(): void {
    const nachricht: Nachricht = new Nachricht();
    nachricht.sender = 'GM';
    nachricht.reciever = 'alle';
    nachricht.typ = 'obj';
    nachricht.subtyp = 'dat';
    nachricht.body = this.datum;
    // this.websocket.sendWithProtocol('GM', 'alle', '[obj][dat]' + this.datum);
    this.websocket.sendNachricht(nachricht);
  }

  public updateWetter(): void {
    const wetterBezeichnungen: string[] = ['WetterHeute', 'WetterMorgen', 'WetterÜbermorgen'];
    for (let i = 0; i < this.dreiTageWetter.length; i++) {
      const nachricht: Nachricht = new Nachricht();
      nachricht.sender = 'GM';
      nachricht.reciever = 'alle';
      nachricht.typ = 'obj';
      nachricht.subtyp = wetterBezeichnungen[i];
      nachricht.body = this.dreiTageWetter[i].toString();
      this.websocket.sendNachricht(nachricht);
    }

  }

  stelleAnfrage(): void {
    if (this.ausgewaehlterCharakter !== '') {
      const nachricht: Nachricht = new Nachricht();
      console.log(this.ausgewaehlterCharakterId);
      nachricht.sender = 'GM';
      nachricht.reciever = this.ausgewaehlterCharakterId;
      nachricht.typ = 'fwdcmd';
      nachricht.subtyp = this.kategorie;
      nachricht.body = 'getWert()';
      this.websocket.sendNachricht(nachricht);
    }
  }

  public changeMenu(nr: number): void {
    sessionStorage.setItem('GmNav', '' + nr);
    this.$option = nr;
  }

  public handleMessage(nachricht: Nachricht): void {
    this.messages.push(nachricht.body);
  }
  public handleCommand(nachricht: Nachricht): void {
    if (nachricht.body === 'connected') {
      console.log(true);
      this.lampColor = 'green';
    }
  }
  public handleObject(nachricht: Nachricht): void {
    let message = nachricht.body;
    while (message.includes('\\"')) {
      message = message.replace('\\"', '"');
    }
    const object = JSON.parse(message);
    console.log(object);
    if (nachricht.subtyp === 'Gegenstand') {
      this.handleGegenstand(object);
    } else if (nachricht.subtyp === 'nameWert<>') {
      console.log('lese nameWert<>');
      (object as NameWert[]).forEach(nw => {
        console.log(nw);
        this.abenteuerDaten.push(nw);
        if (nw.name === 'Region') {
          this.aktRegion = nw.wert;
        } else if (nw.name === 'Datum') {
          this.datum = nw.wert;
          this.datumZG = this.abenteuerService.getDatum(this.datum, 'Zwölfgötter');
          this.datumThorwal = this.abenteuerService.getDatum(this.datum, 'Thorwal');
          this.datumZwerge = this.abenteuerService.getDatum(this.datum, 'Zwerge');
          this.datumIrdisch = this.abenteuerService.getDatum(this.datum, 'irdisch');
          this.jahreszeit = this.abenteuerService.getDatum(this.datum, 'Jahreszeit');
        } else if (nw.name.startsWith('Wetter')) {
          if (this.dreiTageWetter.length < 3) {
            this.dreiTageWetter = [];
            this.dreiTageWetter.push(new Wetter());
            this.dreiTageWetter.push(new Wetter());
            this.dreiTageWetter.push(new Wetter());
          }
          const splitted: string[] = nw.wert.split('#');
          const wetter: Wetter = new Wetter();
          wetter.bewoelkung = splitted[0];
          wetter.windstaerke = splitted[1];
          wetter.wellenhoehe = splitted[2];
          wetter.grundtempeatur = Number(splitted[3]);
          wetter.temperaturTag = Number(splitted[4]);
          wetter.temperaturNacht = Number(splitted[5]);
          wetter.niederschlag = splitted[6];
          if (nw.name === 'WetterHeute') {
            this.dreiTageWetter[0].copy(wetter);
          } else if (nw.name === 'WetterMorgen') {
            this.dreiTageWetter[1].copy(wetter);
          } else if (nw.name === 'WetterÜbermorgen') {
            this.dreiTageWetter[2].copy(wetter);
          }

        }
      });
    } else if (nachricht.subtyp === 'ids') {
      this.verbundenIds = (object as string[]);
    } else if (nachricht.subtyp === 'namen') {
      this.verbunden = (object as string[]);
    } else if (nachricht.subtyp === 'portraits') {
      this.portraits = (object as string[]);
    } else if (nachricht.subtyp === this.kategorie) {
      this.angefragtes = (object as Werte[]);
    }
  }

  public handleMessageOld(message: string): void {
    this.messages.push(message);
  }
  public handleCommandOld(message: string): void {
    if (message === 'connected') {
      console.log(true);
      this.lampColor = 'green';
    }
  }
  public handleObjectOld(message: string): void {
    const splitPunkt: number = message.indexOf(']');
    const typ: string = message.substring(1, splitPunkt);
    message = message.substring(splitPunkt + 1);
    while (message.includes('\\"')) {
      message = message.replace('\\"', '"');
    }
    const object = JSON.parse(message);
    console.log(typ + ': ' + message);
    if (typ === 'Gegenstand') {
      this.handleGegenstand(object);
    } else if (typ === 'nameWert<>') {
      (object as NameWert[]).forEach(nw => {
        console.log(nw);
        this.abenteuerDaten.push(nw);
        if (nw.name === 'Region') {
          this.aktRegion = nw.wert;
        } else if (nw.name === 'Datum') {
          this.datum = nw.wert;
          this.datumZG = this.abenteuerService.getDatum(this.datum, 'Zwölfgötter');
          this.datumThorwal = this.abenteuerService.getDatum(this.datum, 'Thorwal');
          this.datumZwerge = this.abenteuerService.getDatum(this.datum, 'Zwerge');
          this.datumIrdisch = this.abenteuerService.getDatum(this.datum, 'irdisch');
        }
      });
    } else if (typ === 'namen') {
      this.verbunden = (object as string[]);
    } else if (typ === 'portraits') {
      this.portraits = (object as string[]);
    }
  }

  public handleGegenstand(object): void {
    console.log(object);
    this.objectMessages.set((object as Gegenstand).name, object);
  }

  public sendGegenstand(object): void {
    this.sharedService.sendEvent(object);
  }

  naechsterTag(): void {
    this.datum = this.abenteuerService.erhoeheDatum(this.datum, 1);
    this.datumZG = this.abenteuerService.getDatum(this.datum, 'Zwölfgötter');
    this.datumThorwal = this.abenteuerService.getDatum(this.datum, 'Thorwal');
    this.datumZwerge = this.abenteuerService.getDatum(this.datum, 'Zwerge');
    this.datumIrdisch = this.abenteuerService.getDatum(this.datum, 'irdisch');
    this.updateDate();
    this.aktualisiereWettervorhersage();
  }


  public processInfokastenString(): void {
    this.infos = [];
    let str = this.infokastenString;
    let lastWasNotThere = false;
    let count = 0;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.keyWords.length; i++) {
      const array = str.split(this.keyWords[i]);
      if (array.length > 1) {
        this.infos.push(array[0].trim());
        str = array[1].trim();
        if (lastWasNotThere) {
          for (let j = 0; j < count; j++) {
            this.infos.push('');
          }
          lastWasNotThere = false;
          count = 0;
        }
      } else {
        count++;
        lastWasNotThere = true;
      }
    }
    this.infos.push(str);
    for (let i = 0; i < this.infos.length; i++) {
      if (this.infos[i] === 'keine') {
        this.infos[i] = '';
      }
    }
    console.log(this.infos);
    this.$profession.paketKosten = +this.infos[0].split(' ')[1];
    this.voraussetzungenStr = this.konfiguriereVoraussetzungenString(this.infos[1]);
    this.voraussetzungenStr = this.makeStringArrayKonform(this.voraussetzungenStr);
    this.sonderfertigkeitenStr = this.makeStringArrayKonform(this.infos[2]);
    this.kampftechnikenStr = this.konfiguriereKampftechnikenString(this.infos[3]);
    const talenteKeywords = ['Körper:', 'Gesellschaft:', 'Natur:', 'Wissen:', 'Handwerk:', '-'];
    this.talenteStr = '';
    this.infos[4].split(' ').forEach(word => {
      if (!talenteKeywords.includes(word)) {
        if (word !== '-') {
          this.talenteStr += word + ' ';
        }
      } else {
        this.talenteStr = this.talenteStr.trim() + ', ';
      }
    });
    this.talenteStr = this.talenteStr.substring(1);
    this.talenteStr = this.makeStringArrayKonform(this.talenteStr.trim());
    this.zauberStr = this.konfiguriereZauberString(this.infos[5]);
    this.liturgienStr = this.makeStringArrayKonform(this.infos[6]);
    this.empfohleneVorteileStr = this.makeStringArrayKonform(this.infos[7]);
    this.empfohleneNachteileStr = this.makeStringArrayKonform(this.infos[8]);
    this.empfohleneNachteileStr = this.konfiguriereVuNString(this.empfohleneNachteileStr);
    this.ungeeigneteVorteileStr = this.makeStringArrayKonform(this.infos[9]);
    this.ungeeigneteNachteileStr = this.makeStringArrayKonform(this.infos[10]);
    this.ungeeigneteNachteileStr = this.konfiguriereVuNString(this.ungeeigneteNachteileStr);
  }

  public makeStringArrayKonform(str: string): string {
    // console.log('makeStringArrayKonform');
    // console.log(str);
    let inKlammer = false;
    let result = '';
    str.split(', ').forEach(part => {
      // console.log(part);
      if (part.includes('(')) {
        inKlammer = true;
        if (part.includes(')')){
          inKlammer = false;
        }
      } else if (part.includes(')')) {
        inKlammer = false;
      }


      if (inKlammer) {
        // console.log('zwischen Klammern -> setze , hinten an');
        result += part + ', ';
      } else {
        // console.log('außerhalb von Klammern -> setze # hinten an');
        result += part + '#';
      }
      /*if (part.includes(')')) {
        inKlammer = false;
      }*/
    });
    result = result.substring(0, result.length - 1);
    if (result.endsWith(',')) {
      result = result.substring(0, result.length - 1);
    }
    return result;
  }


  public konfiguriereVuNString(vunString: string): string {
    let result = '';
    const array = vunString.split('#');
    if (array[0].includes('Angst')) {
      const vunArray = array[0].split('... ');
      let angst = vunArray[0] + '... (';
      let inKlammern = '';
      vunArray[1].split(', ').forEach(teil => {
        if (teil.includes(' und ')) {
          inKlammern += teil.split(' und ')[0] + ', ';
          inKlammern += teil.split(' und ')[1] + ', ';
        } else {
          inKlammern += teil + ', ';
        }
      });
      inKlammern = inKlammern.substring(0, inKlammern.length - 2);
      angst += inKlammern + ')';
      result = angst;
    } else {
      result = array[0];
    }
    for (let i = 1; i < array.length; i++) {
      if (array[i].includes('Angst')) {
        const vunArray = array[0].split('... ');
        let angst = vunArray[0] + '... (';
        let inKlammern = '';
        vunArray[1].split(', ').forEach(teil => {
          if (teil.includes(' und ')) {
            inKlammern += teil.split(' und ')[0] + ', ';
            inKlammern += teil.split(' und ')[1] + ', ';
          } else {
            inKlammern += teil + ', ';
          }
        });
        inKlammern = inKlammern.substring(0, inKlammern.length - 2);
        angst += inKlammern + ')';

        result += '#' + angst;
      } else {
        result += '#' + array[i];
      }
    }
    return result;
  }

  public konfiguriereZauberString(zauberString: string): string {
    let result = '';
    console.log('String input: ' + zauberString);
    if (zauberString.includes('Zaubertrick')) {
      console.log('String includes: Zaubertrick');
      const splitted = zauberString.split('; ');
      let zaubertricks = splitted[0];

      console.log('Zaubertrick part: ' + zaubertricks);
      zaubertricks = zaubertricks.replace(': ', ' (') + ')';
      result = zaubertricks + '#';
      zauberString = splitted[1];
      console.log('other part: ' + zauberString);
    }
    const array = zauberString.split(', ');
    result += array[0];
    for (let i = 1; i < array.length; i++) {
      result += '#' + array[i];
    }
    return result;
  }

  public konfiguriereVoraussetzungenString(sf: string): string {
    const re: RegExp = / \([\d]* AP\)/g;
    const array = sf.match(re);
    if (array !== null && array !== undefined) {
      array.forEach(regex => {
        sf = sf.replace(regex, '');
      });
    }
    return sf;
  }

  logEvent(event: StringEvent): void {
    console.log(event.wert);
  }

  public resetProfession(): void {
    this.infokastenString = '';
    this.$profession.nameM = '';
    this.$profession.nameW = '';
    this.$profession.ausruestungUndTracht = '';
    this.$profession.beschreibung = '';
    this.$profession.paketKosten = 0;
    this.$profession.voraussetzungen = [];
    this.$profession.sonderfertigkeiten = [];
    this.$profession.kampftechniken = [];
    this.$profession.talente = [];
    this.$profession.zauber = [];
    this.$profession.liturgien = [];
    this.$profession.empfohleneVorteile = [];
    this.$profession.empfohleneNachteile = [];
    this.$profession.ungeeigneteVorteile = [];
    this.$profession.ungeeigneteNachteile = [];
    this.voraussetzungenStr = '';
    this.sonderfertigkeitenStr = '';
    this.kampftechnikenStr = '';
    this.talenteStr = '';
    this.zauberStr = '';
    this.liturgienStr = '';
    this.empfohleneVorteileStr = '';
    this.empfohleneNachteileStr = '';
    this.ungeeigneteVorteileStr = '';
    this.ungeeigneteNachteileStr = '';
  }

  public konfiguriereKampftechnikenString(kampftechnikString: string): string {
    if (kampftechnikString.length < 5) {
      kampftechnikString = 'keine';
    }
    const indexListe = ['eine', 'zwei', 'drei', 'vier', 'fünf', 'sechs'];
    let result = '';
    const array = kampftechnikString.split(', ');
    let auswahlString = '';
    let wahl = false;
    let auswahlFw: string;
    array.forEach(kampftechnik => {
      if (!wahl) {
        const paar = kampftechnik.split(' ');
        if (paar.length > 2) {
          wahl = true;
          auswahlString += indexListe.indexOf(paar[0]) + 1;
          let nachDpkt = false;
          for (let i = 1; i < paar.length; i++) {
            if (nachDpkt) {
              if (paar[i].trim() !== 'oder') {
                auswahlString += ',' + paar[i];
              }
            }
            if (paar[i].includes(':')) {
              auswahlFw = paar[i].substring(0, paar[0].length - 2);
              nachDpkt = true;
            }
          }
          result += auswahlString;
        } else {
          result += kampftechnik + '#';
        }
      } else {
        result += ',' + kampftechnik;
      }

    });
    if (auswahlFw !== undefined) {
      result += ',' + auswahlFw;
    }
    if (result.endsWith('#')) {
      result = result.substring(0, result.length - 1);
    }
    // console.log(result);
    return result;
  }



  public addVorUndNachteil(): void {
    this.dataService.addVorUndNachteil(this.$vorUndNachteil);
  }

  public addZauberToDB(): void {
    this.$zauber.beschreibung = this.replaceMinus(this.$zauber.beschreibung);
    this.$zauber.wirkung = this.replaceMinus(this.$zauber.wirkung);
    this.dataService.addZauberToDB(this.$zauber);
    this.$zauber.erweiterungen = [];
    this.$zauber.wirkungQS = [];
    this.$wirkungQSString = '';
    this.$zauber.probenMod = '';
    this.$zauber.name = '';
    this.$zauber.beschreibung = '';
    this.$zauber.wirkung = '';
    this.$dauerBisSteigerung = '';
  }

  public addZaubertrickToDB(): void {
    this.dataService.addZaubertrickToDB(this.$zaubertrick);
    this.$zaubertrick.name = '';
    this.$zaubertrick.beschreibung = '';
    this.$zaubertrick.reichweite = '';
    this.$zaubertrick.wirkungsDauer = '';
    this.$zaubertrick.zielKathegorie = '';
    this.$zaubertrick.merkmal = '';
    this.$zaubertrick.verbreitung = '';
    this.$infostring = '';
  }

  public deleteVorUndNachteil(): void {
    this.dataService.deleteVorUndNachteil(this.$vorUndNachteil);
  }

  public getVuN(): void {
    this.$vorUndNachteil = this.$alleVorUndNachteile[this.$vunIndex];
  }

  public getProf(): void {
    this.$profession = this.$alleProfessionen[this.$profIndex];
    this.convertArraysToStrings();
  }

  public loadVuN(): void {
    this.dataService.getVorUndNachteile().subscribe((data: VorUndNachteil[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.$alleVorUndNachteile = data;
      this.$vorUndNachteil = data[0];
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public setProbe(): void {
    this.$zauber.probe = [];
    this.$probeString.split('/').forEach(str => {
      this.$zauber.probe.push(str);
    });
  }

  public setWirkungQS(): void {
    this.$zauber.wirkungQS = [];
    this.$wirkungQSString = this.$wirkungQSString.substring(3);
    const array = this.$wirkungQSString.split('QS ');
    array.forEach(str => {
      this.$zauber.wirkungQS.push(this.replaceMinus(str));
    });
  }

  public addErweiterung(): void {
    this.$erweiterungString = this.replaceMinus(this.$erweiterungString);
    this.$zauber.erweiterungen.push(this.$erweiterungString);
    this.$erweiterungString = '';
  }

  public replaceMinus(str: string): string {
    while (str.includes('-')) {
      let check = true;
      const index = str.indexOf('-');
      let vor = str[index - 1];
      let nach = str[index + 1];
      if (vor === ' ') {
        vor = str[index - 2];
        check = false;
      }
      if (nach === ' ') {
        nach = str[index + 2];
        check = false;
      } else if (nach === nach.toLocaleUpperCase()) {
        check = false;
      }

      if (this.checkIfNumber(vor) || this.checkIfNumber(nach)) {
        check = false;
      }
      if (this.checkIfNumber(vor) && this.checkIfNumber(nach)) {
        str = str.replace('-', ' bis ');
      } else if (check) {
        str = str.replace('-', '');
      } else {
        str = str.replace('-', '－');
      }
    }
    while (str.includes('－')) {
      str = str.replace('－', '-');
    }
    return str;
  }

  public setDauerBisSteigerung(): void {
    let workString = this.$dauerBisSteigerung.substring(13);
    let array = workString.split(' AsP-Kosten: ');
    this.$zauber.zauberDauer = array[0];
    workString = array[1];
    array = workString.split(' Reichweite: ');
    this.$zauber.aspKosten = array[0];
    workString = array[1];
    array = workString.split(' Wirkungsdauer: ');
    this.$zauber.reichweite = array[0];
    workString = array[1];
    array = workString.split(' Zielkategorie: ');
    this.$zauber.wirkungsDauer = array[0];
    workString = array[1];
    array = workString.split(' Merkmal: ');
    this.$zauber.zielKathegorie = array[0];
    workString = array[1];
    array = workString.split(' Verbreitung: ');
    this.$zauber.merkmal = array[0];
    workString = array[1];
    array = workString.split(' Steigerungsfaktor: ');
    this.$zauber.verbreitung = array[0];
    this.$zauber.steigerungskosten = array[1];
  }

  public setZaubertrickInfostring(): void {
    let workString = this.$infostring;
    let array = workString.split(' Reichweite: ');
    this.$zaubertrick.beschreibung = this.replaceMinus(array[0]);
    workString = array[1];
    array = workString.split(' Wirkungsdauer: ');
    this.$zaubertrick.reichweite = array[0];
    workString = array[1];
    array = workString.split(' Zielkategorie: ');
    this.$zaubertrick.wirkungsDauer = array[0];
    workString = array[1];
    array = workString.split(' Merkmal: ');
    this.$zaubertrick.zielKathegorie = array[0];
    workString = array[1];
    array = workString.split(' Anmerkung: ');
    this.$zaubertrick.merkmal = array[0];
    this.$zaubertrick.verbreitung = array[1];
  }

  public checkIfNumber(str: string): boolean {
    let check = false;
    for (let i = 0; i < 10; i++) {
      if (str === '' + i) {
        check = true;
      }
    }
    return check;
  }

  public getAlleProfessionen(): void {
    this.$alleProfessionen = [];
    this.dataService.getAlleProfessionen().subscribe((data: Profession[]) => {
      data.sort((a, b) => (a.nameM < b.nameM ? -1 : 1));
      this.$alleProfessionen = data;
      console.log(data);
      this.$profession = data[0];
      this.convertArraysToStrings();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public convertArraysToStrings(): void {
    console.log('convertArraysToStrings');
    if (this.$profession.voraussetzungen !== undefined) {
      this.voraussetzungenStr = this.$profession.voraussetzungen.join('#');
    }
    if (this.$profession.sonderfertigkeiten !== undefined) {
      this.sonderfertigkeitenStr = this.$profession.sonderfertigkeiten.join('#');
    }
    if (this.$profession.kampftechniken !== undefined) {
      this.kampftechnikenStr = this.$profession.kampftechniken.join('#');
    }
    if (this.$profession.talente !== undefined) {
      this.talenteStr = this.$profession.talente.join('#');
    }
    if (this.$profession.zauber !== undefined) {
      this.zauberStr = this.$profession.zauber.join('#');
    }
    if (this.$profession.liturgien !== undefined) {
      this.liturgienStr = this.$profession.liturgien.join('#');
    }
    if (this.$profession.empfohleneVorteile !== undefined) {
      this.empfohleneVorteileStr = this.$profession.empfohleneVorteile.join('#');
    }
    if (this.$profession.empfohleneNachteile !== undefined) {
      this.empfohleneNachteileStr = this.$profession.empfohleneNachteile.join('#');
    }
    if (this.$profession.ungeeigneteVorteile !== undefined) {
      this.ungeeigneteVorteileStr = this.$profession.ungeeigneteVorteile.join('#');
    }
    if (this.$profession.ungeeigneteNachteile !== undefined) {
      this.ungeeigneteNachteileStr = this.$profession.ungeeigneteNachteile.join('#');
    }
  }

  public addProfessionToDB(): void {
    this.convertVoraussetzungenToArray();
    this.convertSonderfertigkeitenToArray();
    this.convertKampftechnikenToArray();
    this.convertTalenteToArray();
    this.convertZauberToArray();
    this.convertLiturgienToArray();
    this.convertEmpfohleneVorteileToArray();
    this.convertEmpfohleneNachteileToArray();
    this.convertUngeeigneteVorteileToArray();
    this.convertUngeeigneteNachteileToArray();
    this.dataService.addProfessionToDB(this.$profession).subscribe((data: Profession) => {
      this.$message = data.nameM + ' wurde hinzugefügt!';
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.resetProfession();

  }

  public convertVoraussetzungenToArray(): void {
    if (this.voraussetzungenStr !== '') {
      const array = this.voraussetzungenStr.split('#');
      this.$profession.voraussetzungen = array;
    }
  }

  public convertSonderfertigkeitenToArray(): void {
    if (this.sonderfertigkeitenStr !== '') {
      const array = this.sonderfertigkeitenStr.split('#');
      this.$profession.sonderfertigkeiten = array;
    }
  }

  public convertKampftechnikenToArray(): void {
    if (this.kampftechnikenStr !== '') {
      const array = this.kampftechnikenStr.split('#');
      this.$profession.kampftechniken = array;
    }
  }

  public convertTalenteToArray(): void {
    if (this.talenteStr !== '') {
      const array = this.talenteStr.split('#');
      this.$profession.talente = array;
    }
  }

  public convertZauberToArray(): void {
    if (this.zauberStr !== '') {
      const array = this.zauberStr.split('#');
      this.$profession.zauber = array;
      console.log(this.$profession.zauber);
    }
  }

  public convertLiturgienToArray(): void {
    if (this.liturgienStr !== '') {
      const array = this.liturgienStr.split('#');
      this.$profession.liturgien = array;
    }
  }

  public convertEmpfohleneVorteileToArray(): void {
    if (this.empfohleneVorteileStr !== '') {
      const array = this.empfohleneVorteileStr.split('#');
      this.$profession.empfohleneVorteile = array;
    }
  }

  public convertEmpfohleneNachteileToArray(): void {
    if (this.empfohleneNachteileStr !== '') {
      const array = this.empfohleneNachteileStr.split('#');
      this.$profession.empfohleneNachteile = array;
    }
  }

  public convertUngeeigneteVorteileToArray(): void {
    if (this.ungeeigneteVorteileStr !== '') {
      const array = this.ungeeigneteVorteileStr.split('#');
      this.$profession.ungeeigneteVorteile = array;
    }
  }

  public convertUngeeigneteNachteileToArray(): void {
    if (this.ungeeigneteNachteileStr !== '') {
      const array = this.ungeeigneteNachteileStr.split('#');
      this.$profession.ungeeigneteNachteile = array;
    }
  }

  public reloadEvent(e: StringEvent): void {
    const event: StringEvent = {
      wert: e.wert
    };
    this.reload.emit(event);
  }

  public navigateBack(): void {
    if (this.$option === 0) {
      this.localData.nav = 0;
    } else {
      const currentMenu: number = Math.floor(this.$option / 10) * 10;
      if (this.$option % 10 === 0) {
        this.$option = 0;
      } else {
        this.$option = currentMenu;
      }
    }
    sessionStorage.setItem('GmNav', '' + this.$option);
  }

  waehleCharakter(name: string, id: string): void {
    if (this.ausgewaehlterCharakter === name) {
      this.ausgewaehlterCharakter = '';
      this.ausgewaehlterCharakterId = '';
    } else {
      this.ausgewaehlterCharakter = name;
      this.ausgewaehlterCharakterId = id;
    }
  }

  /*berechneWetter(): void {
    this.wetter = new Wetter();
    this.wetter.copy(this.abenteuerService.generiereWetter(this.inWueste, this.jahreszeit, this.windigeRegion, this.region));
  }*/

  berechneDreiTageWetter(): void {
    this.dreiTageWetter = [];
    let wetter = new Wetter();
    wetter.copy(this.abenteuerService.generiereWetter(this.inWueste, this.jahreszeit, this.windigeRegion, this.region));
    this.dreiTageWetter.push(wetter);
    for (let i = 0; i <= 1; i++) {
      wetter = new Wetter();
      wetter.copy(this.abenteuerService.wettervorhersage(
        this.inWueste, this.jahreszeit, this.windigeRegion, this.region, this.dreiTageWetter[i]));
      this.dreiTageWetter.push(wetter);
    }
    this.updateWetter();
  }

  aktualisiereWettervorhersage(): void {
    console.log(this.dreiTageWetter);
    this.dreiTageWetter.shift();
    console.log(this.dreiTageWetter);
    const neuesWetter: Wetter = new Wetter();
    neuesWetter.copy(this.abenteuerService.wettervorhersage(
      this.inWueste, this.jahreszeit, this.windigeRegion, this.region, this.dreiTageWetter[1]));
    this.dreiTageWetter.push(neuesWetter);
    this.updateWetter();
  }

  /*wettervorhersage(): void {
    const neuesWetter: Wetter = new Wetter();
    neuesWetter.copy(this.abenteuerService.wettervorhersage(this.inWueste, this.jahreszeit, this.windigeRegion, this.region, this.wetter));
    this.wetter = new Wetter();
    this.wetter.copy(neuesWetter);
  }*/

  getSanitizedURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
