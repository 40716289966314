import { Component, OnInit } from '@angular/core';
import {Kaempferin} from '../../../_classes/kampf/kaempferin';
import {Npc} from '../../../_classes/npc';
import {DataService} from '../../../_services/data.service';
import {Kultur} from '../../../_interfaces/kultur';
import {Profession} from '../../../_interfaces/profession';
import {Spezies} from '../../../_interfaces/spezies';
import {SteigerungstabelleService} from '../../../_services/steigerungstabelle.service';
import {Waffe} from '../../../_classes/kampf/waffe';
import {Waffensammlung} from '../../../_classes/sammlungen/waffensammlung';
import {Kampftechnik} from '../../../_interfaces/kampftechnik';
import {NameWertNumber} from '../../../_interfaces/name-wert-number';
import {TestItemA} from '../../../_classes/ausruestung/test-item-a';
import {TestItemB} from '../../../_classes/ausruestung/test-item-b';
import {TestItemC} from '../../../_classes/ausruestung/test-item-c';

@Component({
  selector: 'app-npc-generator',
  templateUrl: './npc-generator.component.html',
  styleUrls: ['./npc-generator.component.sass']
})
export class NpcGeneratorComponent implements OnInit {

  npc: Npc;
  kaempferin: Kaempferin;
  erfahrungsBeschreibungen: string[];
  erfahrungsInfos: string[][];
  alleSpezies: Spezies[];
  alleKulturen: Kultur[];
  alleProfessionen: Profession[];
  waffenSammlumg: Waffensammlung;
  alleKampftechniken: Kampftechnik[];
  apKonto = 0;
  apKosten: number[] = [];


  npcTypen: string[] = ['Wache', 'Schütze', 'Stutzer'];
  guteEigenschaften: string[][] = [['kk', 'mu', 'ko'], [ 'ff', 'mu'], ['ge', 'mu', 'in']];
  primaerKampftechniken: string[][] = [ ['Hiebwaffen', 'Schwerter', 'Stangenwaffen'],
                                        ['Bögen', 'Armbrüste'],
                                        ['Fechtwaffen']];


  constructor(public dataService: DataService,
              public steigerung: SteigerungstabelleService) {
    this.npc = new Npc();
    this.kaempferin = this.npc.kaempferin;
    this.npc.stufe = 0;
  }

  ngOnInit(): void {
    this.loadData();
    this.apKosten.push(0); // Spezies
    this.apKosten.push(1); // Eigenschaften
  }

  loadData(): void {
    this.erfahrungsBeschreibungen = [];
    this.dataService.request2DStringArray('/glossary/erfahrung').subscribe((data: string[][]) => {
      this.erfahrungsInfos = [];
      data.forEach(d => {
        this.erfahrungsBeschreibungen.push(d[0]);
        const zeile: string[] = [];
        for (let i = 1; i < 8; i++) {
          zeile.push(d[i]);
        }
        this.erfahrungsInfos.push(zeile);
      });
    });
    this.dataService.getAlleSpezies().subscribe((data: Spezies[]) => {
      data.sort((a, b) => (a.unterArt < b.unterArt ? -1 : 1));
      data.sort((a, b) => (a.art < b.art ? -1 : 1));
      this.alleSpezies = data;
    });
    this.dataService.getAlleKulturen().subscribe((data: Kultur[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.alleKulturen = data;
    });
    this.dataService.getAlleProfessionen().subscribe((data: Profession[]) => {
      data.sort((a, b) => (a.nameW < b.nameW ? -1 : 1));
      this.alleProfessionen = data;
      this.bestimmeEigenschaftswerte();
    });
    const waffen: Waffe[] = [];
    this.dataService.getAlleWaffen().subscribe((data: Waffe[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.forEach(w => {
        const waffe: Waffe = new Waffe();
        waffe.copy(w);
        waffen.push(waffe);
      });
      this.waffenSammlumg = new Waffensammlung(waffen);
    });
    this.dataService.getAlleKampftechniken().subscribe((data: Kampftechnik[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.alleKampftechniken = data;
    });
    /*
    console.log('get TestItemA');
    this.dataService.getTestItems().subscribe((data: any[]) => {
      console.log(data);
      const items: TestItemA[] = [];
      data.forEach(d => {
        if (d.type === 'A') {
          const item: TestItemA = new TestItemA(d);
          items.push(item);
        } else if (d.type === 'B') {
          const item: TestItemB = new TestItemB(d);
          items.push(item);
        } else if (d.type === 'C') {
          const item: TestItemC = new TestItemC(d);
          items.push(item);
        }
      });
      console.log(items);
      items.forEach(i => {
        if (i instanceof TestItemB) {
          console.log('B');
        } else if (i instanceof TestItemC) {
          console.log('C');
        } else if (i instanceof TestItemA) {
          console.log('A');
        }
      });
    });
    */
  }

  bestimmeEigenschaftswerte(): void {
    this.kaempferin.resetEigenschaftspunkte();

    /** Eigenschaftsänderungen aus Spezies */
    const mods: string[] = this.alleSpezies[this.npc.spezies].eigenschaftsAenderungen;
    mods.forEach(mod => {
      if (mod.includes('||')) {
        const splitted: string[] = mod.split(' || ');
        const index = Math.floor(Math.random() * splitted.length);
        this.modEigenschaft(splitted[index]);
      } else {
        this.modEigenschaft(mod);
      }

    });
    this.apKosten[0] = this.alleSpezies[this.npc.spezies].apWert;

    /** automatisch gwählte Eigenschaftsänderungen */
    const type = this.npcTypen.indexOf(this.npc.type);
    // zeilen 2, 5
    const maxEigWert = Number(this.erfahrungsInfos[2][this.npc.stufe]);
    const maxEigPkte = Number(this.erfahrungsInfos[5][this.npc.stufe]);


    let kostenSumme = 0;
    // erhöhe typische Eigenschaften auf Maximum
    this.guteEigenschaften[type].forEach(eig => {
      console.log('erhöhe ' + eig + ' auf Maximum:');
      let eigWert = this.kaempferin.getAttrWert(eig);
      while (eigWert < maxEigWert) {
        console.log('while(' + eigWert + '<' + maxEigWert + ')...');
        eigWert++;
        const kosten = this.steigerung.getKosten(eigWert, 4);
        console.log('Kosten für ' + eig + ' von ' + (eigWert - 1) + ' auf ' + eigWert + ': ' + kosten + ' (Summe bisher: ' + kostenSumme + ')');
        kostenSumme -= - kosten;
      }
      this.kaempferin.setAttrWert(eig, eigWert);
    });

    // erhöhe restliche Eigenschaften auf bis Punktelimit erreicht
    while (this.kaempferin.getEigenschaftspunkte() < maxEigPkte) {
      const neuerWert = this.kaempferin.erhoeheZufaelligeEigenschaft(maxEigWert);
      let kosten = 0;
      if (neuerWert !== -1) {
        kosten = this.steigerung.getKosten(neuerWert, 4);
      }
      kostenSumme -= - kosten;
    }
    console.log(kostenSumme);
    this.apKosten[1] = kostenSumme;
    this.kaempferin.resetWerteAufBasis();
    this.waehlePrimaerwaffe();
  }

  modEigenschaft(mod: string): void {
    const split: string[] = mod.split(': ');
    const eig = split[0];
    const eigMod: number = Number(split[1]);
    this.kaempferin.modifiziereEigenschaft(eig, eigMod);
  }

  waehlePrimaerwaffe(): void {
    this.kaempferin.kampftechniken = [];
    const type = this.npcTypen.indexOf(this.npc.type);
    const ktListe: string[] = this.primaerKampftechniken[type];
    const kt = ktListe[Math.floor(Math.random() * ktListe.length)];
    const maxKtWert = Number(this.erfahrungsInfos[4][this.npc.stufe]);
    const kampftechnik: NameWertNumber = {
      name: kt,
      wert: maxKtWert
    };
    const raufenWert = 6 + Math.floor(Math.random() * (maxKtWert - 6) + 1);
    const raufen: NameWertNumber = {
      name: 'Raufen',
      wert: raufenWert
    };
    this.kaempferin.kampftechniken.push(kampftechnik);
    this.kaempferin.kampftechniken.push(raufen);
    console.log(this.kaempferin.kampftechniken);
    const waffen: Waffe[] = this.waffenSammlumg.getWaffenDerKampftechnik(kt);
    const waffe: Waffe = new Waffe();
    waffe.copy(waffen[Math.floor(Math.random() * waffen.length)]);
    this.kaempferin.rechteHand.copy(waffe);
    if (waffe.zweihand) {
      this.kaempferin.linkeHand.copy(waffe);
    } else {
      this.kaempferin.linkeHand.copy(this.waffenSammlumg.getFaeuste());
    }
    this.kaempferin.calculateWerte(this.alleKampftechniken);
    console.log(waffe);
  }

}
