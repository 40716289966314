import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

// import {Kaempferin} from '../_interfaces/kaempferin';
import {compareNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import {Kaempferin} from '../_classes/kampf/kaempferin';

@Injectable({
  providedIn: 'root'
})
export class HeldenblattCommService {

  constructor() {}

  private subject = new Subject<any>();
  private subjectKampf = new Subject<any>();
  private subjectKampfSocket = new Subject<any>();
  private subjectItemComponent = new Subject<any>();
  private aufrufe: number;

  public aktualisiereGeld(): void {
    this.subject.next();
  }

  public aktualisiereBelastung(): void {
    this.subjectKampf.next();
  }

  public emitWsUpdate(kaempfer: Kaempferin[]): void {
    // this.subjectKampfSocket.next();
    this.subjectKampfSocket.next(kaempfer);
  }

  getClickEvent(): Observable<any>{
    return this.subject.asObservable();
  }

  public sendEvent(object): void {
    this.subjectItemComponent.next(object);
  }
  getEvent(): Observable<any>{
    return this.subjectItemComponent.asObservable();
  }


  public setVeMod(veErsch: number, veDivisor: number): void {
    const data = [veErsch, veDivisor];
    this.subject.next(data);
  }

  getBelastungUpdate(): Observable<any>{
    return this.subjectKampf.asObservable();
  }

  getWsUpdate(): Observable<any>{
    return this.subjectKampfSocket.asObservable();
  }
}
