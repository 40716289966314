import {VorUndNachteil} from './charakter/vorUndNachteil';
import {Gvorundnachteil} from './glossar/gvorundnachteil';

export class Kombivun {
  charakterVuN: VorUndNachteil;
  glossarVuN: Gvorundnachteil;

  public anzeigeName(): string {
    let name = this.charakterVuN.name;
    if (this.glossarVuN.stufen > 1) {
      const stufen = ['' , 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
      name += ' ' + stufen[this.charakterVuN.stufe];
    }
    if (this.charakterVuN.gewaehlteKategorie !== '') {
      name += ' (' + this.charakterVuN.gewaehlteKategorie + ')';
    }
    if (this.charakterVuN.spezifikation !== '') {
      name += ' ' + this.charakterVuN.gewaehlteKategorie;
    }
    return name;
  }

  /** -1: Nachteil; 0: Sonderfertigkeit; 1: Vorteil */
  public type(): number {
    let type = -1;
    if (this.glossarVuN.vorteil) {
      type = 1;
    }
    return type;
  }

  public copy(other: Kombivun): void {
    this.charakterVuN = new VorUndNachteil();
    this.charakterVuN.copy(other.charakterVuN);
    this.glossarVuN = new Gvorundnachteil();
    this.glossarVuN.copy(other.glossarVuN);
  }
}
