export class Wetter {
  bewoelkung: string;
  windstaerke: string;
  wellenhoehe: string;
  grundtempeatur: number;
  temperaturTag: number;
  temperaturNacht: number;
  niederschlag: string;

  public copy(other: Wetter): void {
    this.bewoelkung = other.bewoelkung;
    this.windstaerke = other.windstaerke;
    this.wellenhoehe = other.wellenhoehe;
    this.grundtempeatur = other.grundtempeatur;
    this.temperaturTag = other.temperaturTag;
    this.temperaturNacht = other.temperaturNacht;
    this.niederschlag = other.niederschlag;
  }

  public toString(): string {
    let ausgabe = this.bewoelkung;
    ausgabe += '#' + this.windstaerke;
    ausgabe += '#' + this.wellenhoehe;
    ausgabe += '#' + this.grundtempeatur;
    ausgabe += '#' + this.temperaturTag;
    ausgabe += '#' + this.temperaturNacht;
    ausgabe += '#' + this.niederschlag;
    return ausgabe;
  }
}
