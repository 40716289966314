import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeldenbogenComponent } from './heldendokumente/heldenbogen/heldenbogen.component';
import { TalentblattComponent } from './heldendokumente/talentblatt/talentblatt.component';
import { TitelbereichComponent } from './heldendokumente/titelbereich/titelbereich.component';
import { TalentItemComponent } from './heldendokumente/talentblatt/talent-item/talent-item.component';
import {TooltipComponent} from './heldendokumente/talentblatt/tooltip/tooltip.component';
import { LoginComponent } from './menu/login/login.component';
import { MainmenuComponent } from './menu/mainmenu/mainmenu.component';
import { LogoutComponent } from './menu/logout/logout.component';
import { RegisterComponent } from './menu/register/register.component';
import { AuswahlbildschirmComponent } from './menu/auswahlbildschirm/auswahlbildschirm.component';
import { SpielmeisteroptionenComponent } from './menu/spielmeisteroptionen/spielmeisteroptionen.component';
import { CharaktererstellungComponent } from './menu/charaktererstellung/charaktererstellung.component';
import { ErfahrungComponent } from './menu/charaktererstellung/erfahrung/erfahrung.component';
import { SpeziesComponent } from './menu/charaktererstellung/spezies/spezies.component';
import {KoerperattributeComponent} from './menu/charaktererstellung/koerperattribute/koerperattribute.component';
import { KulturComponent } from './menu/charaktererstellung/kultur/kultur.component';
import { EigenschaftenComponent } from './menu/charaktererstellung/eigenschaften/eigenschaften.component';
import { ProfessionComponent } from './menu/charaktererstellung/profession/profession.component';
import { VorundnachteilComponent } from './menu/charaktererstellung/vorundnachteil/vorundnachteil.component';
import { ZauberComponent } from './menu/charaktererstellung/zauber/zauber.component';
import { SonderfertigkeitenComponent } from './menu/spielmeisteroptionen/sonderfertigkeiten/sonderfertigkeiten.component';
import { SonderfertigkeitComponent } from './menu/charaktererstellung/sonderfertigkeit/sonderfertigkeit.component';
import { SteigerungenComponent } from './menu/charaktererstellung/steigerungen/steigerungen.component';
import { FinalisierungComponent } from './menu/charaktererstellung/finalisierung/finalisierung.component';
import { KampfblattComponent } from './heldendokumente/kampfblatt/kampfblatt.component';
import { WaffenComponent } from './menu/spielmeisteroptionen/waffen/waffen.component';
import { AllgemeinComponent } from './heldendokumente/allgemein/allgemein.component';
import { ZauberblattComponent } from './heldendokumente/zauberblatt/zauberblatt.component';
import { RuestungComponent } from './menu/spielmeisteroptionen/ruestung/ruestung.component';
import { ItemComponent } from './menu/spielmeisteroptionen/item/item.component';
import { AusruestungComponent } from './heldendokumente/ausruestung/ausruestung.component';
import {HeldenblattCommService} from './_services/heldenblatt-comm.service';
import { KampfsystemComponent } from './heldendokumente/kampfsystem/kampfsystem.component';
import { ArenaComponent } from './menu/spielmeisteroptionen/arena/arena.component';
import { NpcGeneratorComponent } from './menu/spielmeisteroptionen/npc-generator/npc-generator.component';
import { NotizenComponent } from './heldendokumente/notizen/notizen.component';
import {PersistenceService} from './_services/persistence.service';
import { TemplateComponent } from './_template/template/template.component';
import { TalentbogenComponent } from './heldendokumente/talentbogen/talentbogen.component';
import { FileuploadComponent } from './menu/fileupload/fileupload.component';
import { AbenteuerComponent } from './heldendokumente/abenteuer/abenteuer.component';
import { EntwicklungComponent } from './heldendokumente/entwicklung/entwicklung.component';
import { ZauberbogenComponent } from './heldendokumente/zauberbogen/zauberbogen.component';
import { ProbenblattComponent } from './heldendokumente/probenblatt/probenblatt.component';
import { GsonderfertigkeitenComponent } from './menu/spielmeisteroptionen/glossar/gsonderfertigkeiten/gsonderfertigkeiten.component';
import { GtierComponent } from './menu/spielmeisteroptionen/glossar/gtier/gtier.component';
import { TierComponent } from './menu/erschaffung/tier/tier.component';
import { TiereComponent } from './heldendokumente/tiere/tiere.component';
import { HeldenerschaffungComponent } from './menu/heldenerschaffung/heldenerschaffung.component';
import { AErfahrungComponent } from './menu/heldenerschaffung/a-erfahrung/a-erfahrung.component';
import { BSpeziesComponent } from './menu/heldenerschaffung/b-spezies/b-spezies.component';

@NgModule({
    declarations: [
        AppComponent,
        HeldenbogenComponent,
        TalentblattComponent,
        TitelbereichComponent,
        TalentItemComponent,
        TooltipComponent,
        TooltipComponent,
        LoginComponent,
        MainmenuComponent,
        LogoutComponent,
        RegisterComponent,
        AuswahlbildschirmComponent,
        SpielmeisteroptionenComponent,
        CharaktererstellungComponent,
        ErfahrungComponent,
        SpeziesComponent,
        KoerperattributeComponent,
        KulturComponent,
        EigenschaftenComponent,
        ProfessionComponent,
        VorundnachteilComponent,
        ZauberComponent,
        SonderfertigkeitenComponent,
        SonderfertigkeitComponent,
        SteigerungenComponent,
        FinalisierungComponent,
        KampfblattComponent,
        WaffenComponent,
        AllgemeinComponent,
        ZauberblattComponent,
        RuestungComponent,
        ItemComponent,
        AusruestungComponent,
        KampfsystemComponent,
        ArenaComponent,
        NpcGeneratorComponent,
        NotizenComponent,
        TemplateComponent,
        TalentbogenComponent,
        FileuploadComponent,
        AbenteuerComponent,
        EntwicklungComponent,
        ZauberbogenComponent,
        ProbenblattComponent,
        GsonderfertigkeitenComponent,
        GtierComponent,
        TierComponent,
        TiereComponent,
        HeldenerschaffungComponent,
        AErfahrungComponent,
        BSpeziesComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
