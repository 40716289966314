import {Gzustandstufe} from './gzustandstufe';

export class Gzustand {
  name: string;
  beschreibung: string;
  stufen: Map<number, Gzustandstufe>;


  public copy(other: Gzustand): void {
    this.name = other.name;
    this.beschreibung = other.beschreibung;
    this.stufen = new Map<number, Gzustandstufe>();
    Object.keys(other.stufen).forEach((key) => {
      const entry: Gzustandstufe = other.stufen[key] as Gzustandstufe;
      const stufe: Gzustandstufe = new Gzustandstufe();
      stufe.copy(entry);
      this.stufen.set(Number(key), stufe);
    });
  }
}
