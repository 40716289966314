import {WertStringString} from './wert-string-string';
import {WertStringNumber} from './wert-string-number';

export class MenuOptions {
  stringOptions: WertStringString[] = [];
  numOptions: WertStringNumber[] = [];

  public copy(other: MenuOptions): void {
    this.stringOptions = [];
    other.stringOptions.forEach(o => {
      const eintrag: WertStringString = new WertStringString();
      eintrag.copy(o);
      this.stringOptions.push(eintrag);
    });
    this.numOptions = [];
    other.numOptions.forEach(o => {
      const eintrag: WertStringNumber = new WertStringNumber();
      eintrag.copy(o);
      this.numOptions.push(eintrag);
    });
  }

  public setWertNumber(name: string, wert: number): void {
    let neu = true;
    this.numOptions.forEach(eintrag => {
      if (eintrag.name === name) {
        eintrag.wert = wert;
        neu = false;
      }
    });
    if (neu) {
      const eintrag: WertStringNumber = new WertStringNumber();
      eintrag.name = name;
      eintrag.wert = wert;
      this.numOptions.push(eintrag);
    }
  }

  public setWertString(name: string, wert: string): void {
    let neu = true;
    this.stringOptions.forEach(eintrag => {
      if (eintrag.name === name) {
        eintrag.wert = wert;
        neu = false;
      }
    });
    if (neu) {
      const eintrag: WertStringString = new WertStringString();
      eintrag.name = name;
      eintrag.wert = wert;
      this.stringOptions.push(eintrag);
    }
  }

  public getWertNumber(name: string): number {
    let wanted: number;
    this.numOptions.forEach(eintrag => {
      if (eintrag.name === name) {
        wanted = eintrag.wert;
      }
    });
    return wanted;
  }

  public getWertString(name: string): string {
    let wanted: string;
    this.stringOptions.forEach(eintrag => {
      if (eintrag.name === name) {
        wanted = eintrag.wert;
      }
    });
    return wanted;
  }
}
