import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {Gtier} from '../../_classes/glossar/gtier';
import {Tier} from '../../_classes/charakter/tier';
import {Kombitalent} from '../../_classes/kombitalent';
import {Gtalent} from '../../_classes/glossar/gtalent';
import {Geinsatzmoeglichkeit} from '../../_classes/glossar/geinsatzmoeglichkeit';
import {Gtalentanwendung} from '../../_classes/glossar/gtalentanwendung';
import {Talent} from '../../_interfaces/talent';
import {Werte} from '../../_classes/charakter/werte';
import {Loyalitaet} from '../../_classes/charakter/loyalitaet';

@Component({
  selector: 'app-tiere',
  templateUrl: './tiere.component.html',
  styleUrls: ['./tiere.component.sass']
})
export class TiereComponent implements OnInit {

  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  gewaehltesTier = 1;
  tier: Tier;
  tierInfos: Gtier;

  talente: Kombitalent[] = [];

  talentWertBedeutung: string[] = ['Gerade mal davon gehört', 'Absoluter Anfänger', 'Anfänger', 'Grundkenntnisse', 'Einige Kenntnisse', 'Genaue Kenntnisse', 'Fähiger Amateur', 'Schlechter Profi', 'Annehmbarer Profi', 'Solider Profi', 'Erfahrener Profi', 'Sehr erfahrener Profi', 'Experte', 'Bewährter Experte', 'Meister', 'Bewährter Meister', 'Regionaler Meister', 'Regionaler Großmeister', 'Globaler Meister', 'Globaler Großmeister', 'Legendärer Großmeister'];
  loyalitaetWertBedeutung: string[] = ['wild', 'verwildert', 'leicht verwildert', 'scheu', 'neutral', 'leicht zutraulich', 'zutraulich', 'etwas gehorsam', 'meist gehorsam', 'gehorsam', 'meist loyal', 'überwiegend loyal', 'loyal', 'sehr loyal', 'extrem Loyal', 'bedingungslos Loyal', 'starke Verbindung', 'treue Bande', 'Bande fürs Leben', 'Bande bis in den Tod', 'Seelenverbindung'];
  bedeutungAktiv = '';

  loyalitaetAttributeZahl = false;
  loyalitaetIndex = 0;
  loyalitaet: Loyalitaet = new Loyalitaet();

  constructor() { }

  ngOnInit(): void {
    this.selectTier(this.charakter.tiere[0].id);
    this.initialisiereTalente();
    this.changeBezugsperson();
  }

  initialisiereTalente(): void {
    this.talente = [];
    const loyGTal: Gtalent = new Gtalent();
    loyGTal.name = 'Loyalität';
    loyGTal.talentArt = 'tierisch';
    loyGTal.probe = ['MU', 'KL', 'IN'];
    loyGTal.beschreibung = 'Die Loyalität einer Person gegenüber stellt die Beziehung dar und bestimmt, wie bereitwillig Anweisungen befolgt werden.';
    loyGTal.belastung = 'nein';
    loyGTal.qualitaet = 'Befehle werden schneller und besser Befolgt. Bei Befehlen von mehreren Personen folg das Tier dem mit der höheren QS.';
    loyGTal.misslungeneProbe = 'Eine misslungene Probe auf Loyalität bedeutet bei domestizierten Tieren, dass sie den Befehl nicht ausführen. Bei Wildtieren hingegen muss der Spielleiter bei einer misslungenen Probe die Tabelle Misslungene Loyalitätsprobe bei Wildtieren konsultieren.';
    loyGTal.kritischerErfolg = 'Bei einem Kritischen Erfolg steigt die Loyalität um 1W3+1 Punkte (bis zu einem Maximum je nach Tierart). Die Steigerung wird mit den Helden-AP verrechnet.';
    loyGTal.patzer = 'Bei einem Patzer sinkt die Loyalität um 1W3+1 Punkte (bis zu einem Minimum von 0). Der Verlust wird mit den Helden-AP verrechnet.';
    loyGTal.steigerungskosten = '-';
    loyGTal.werkzeug = '';
    loyGTal.einsatzmoeglichkeiten = [];
    loyGTal.anwendungsgebiete = [];

    this.tier.getWerteDerKategorie('Talent').forEach(talent => {
      this.glossar.talente.forEach(gtal => {
        if (gtal.name === talent.name) {
          const neu: Kombitalent = new Kombitalent();
          neu.charaktertalent = talent;
          neu.glossartalent = gtal;
          this.talente.push(neu);
        }
      });
    });
    this.talente.sort((a, b) => (a.glossartalent.name < b.glossartalent.name ? -1 : 1));
  }

  selectTier(id: number): void {
    console.log(this.charakter.tiere);
    this.gewaehltesTier = id;
    this.charakter.tiere.forEach(tier => {
      if (tier.id === id) {
        this.tier = tier;
      }
    });
    this.glossar.tiere.forEach(info => {
      if (this.tier.art === info.art && this.tier.unterart === info.unterArt && this.tier.variante === info.variante) {
        this.tierInfos = info;
      }
    });
  }

  public increaseBeeinflussterWert(name: string): void {
    console.log('increaseBeeinflussterWert ' + name);
    const beinfWert = Number(this.tier.getBeeinflWert(name, 'manuell').getWert());
    this.tier.setBeeinflWert(name, 'manuell', String(beinfWert + 1));
    this.ping.emit();
  }

  public decreaseBeeinflussterWert(name: string): void {
    console.log('decreaseBeeinflussterWert ' + name);
    const beinfWert = Number(this.tier.getBeeinflWert(name, 'manuell').getWert());
    this.tier.setBeeinflWert(name, 'manuell', String(beinfWert - 1));
    this.ping.emit();
  }

  public decreaseTalentMod(talent: string): void {
    this.tier.decreaseBeeinflWert(talent, 'manuell');
    this.ping.emit();
  }

  public increaseTalentMod(talent: string): void {
    this.tier.increaseBeeinflWert(talent, 'manuell');
    this.ping.emit();
    console.log(this.tier.getAlleBeeinflWerte('Loyalität'));
  }

  public getAttributMod(talent: string, attribut: string): number {
    let mod = this.tier.getWert(attribut).abzug;
    mod += this.tier.getWert(talent).abzug;
    return mod;
  }

  public getTalentAttributWert(talent: string, attribut: string): number {
    let wert = this.tier.getCurrentWert(attribut);
    wert -= this.tier.getWert(talent).abzug;
    return wert;
  }

  getLoyalitaetAttributWert(attribut: string): number {
    let wert = this.tier.getCurrentWert(attribut);
    wert = this.tier.computeNeuenWertDurchBeeinflussung('Loyalität', wert);
    return wert;
  }

  zeigeTalentwertBeschreibung(name: string): void {
    this.bedeutungAktiv = name;
    setTimeout(() => {
      this.bedeutungAktiv = '';
    }, 1500);
  }

  public istRoutine(talent: Kombitalent): boolean {
    if (talent.glossartalent.name === 'Körperbeherrschung') {
      console.log('istRoutine(' + talent.glossartalent.name + ')');
    }
    let routine = false;
    this.tier.calculateGesamtModifikationFuer(talent.glossartalent.name);
    let mod: number = -this.tier.getWert(talent.glossartalent.name).abzug;
    if (talent.glossartalent.name === 'Körperbeherrschung') {
      console.log('mod: ' + mod);
    }
    if (mod >= -3) {
      if (mod > 3) {
        mod = 3;
      }
      let fw = 19 - ((mod + 3) * 3);
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('fw: ' + fw);
      }
      let punkteUnter13 = 0;
      talent.glossartalent.probe.forEach(attr => {
        const attrWert = this.tier.getCurrentWert(attr);

        if (attrWert < 13) {
          punkteUnter13 = punkteUnter13 + (13 - attrWert);
          if (talent.glossartalent.name === 'Körperbeherrschung') {
            console.log('attrWert < 13');
            console.log('=> punkteUnter13 = punkteUnter13 + (13 - ' + attrWert + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + punkteUnter13 + ' + (13 - ' + attrWert + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + punkteUnter13 + ' + (' + (13 - attrWert) + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + (punkteUnter13 + (13 - attrWert)));
            console.log('=> ' + punkteUnter13);
          }
        }
        if (talent.glossartalent.name === 'Körperbeherrschung') {
          console.log('attrWert(' + attr + '): ' + attrWert);
          console.log('punkteUnter13(' + attr + '): ' + punkteUnter13);
        }
      });
      fw += (punkteUnter13 * 3);
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('benötigter FW: ' + fw);
      }
      let aktFw = this.tier.getWert(talent.glossartalent.name).wert;
      if (talent.aktiveSpzialisierung !== '') {
        aktFw += 2;
      }
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('vorhandener FW: ' + aktFw);
      }
      if (aktFw >= fw) {
        routine = true;
      }
    }
    return routine;
  }

  getLoyalitaetAttribute(): void {
    this.tier.getAlleBeeinflWerte('Loyalität').forEach(beinf => {

    });
  }

  changeBezugsperson(): void {
    this.loyalitaet.copy(this.tier.loyalitaeten[this.loyalitaetIndex]);
  }

}
