import {Allgemein} from './allgemein';

export class Behaeltnis extends Allgemein {
  maxTragkraft: number;
  inhaltTyp: string;
  maxInhalt: number;

  public copy(other: Behaeltnis): void {
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
    this.beschreibung = other.beschreibung;
    this.zitat = other.zitat;
    this.preis = other.preis;
    this.gewicht = other.gewicht;
    this.komplexitaet = other.komplexitaet;
    this.anzahl = other.anzahl;
    this.strukturpunkte = other.strukturpunkte;
    this.regel = other.regel;
    this.maxTragkraft = other.maxTragkraft;
    this.inhaltTyp = other.inhaltTyp;
    this.maxInhalt = other.maxInhalt;
  }
}
