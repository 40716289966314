export class Gwert {
  kategorie: string;
  name: string;
  wert: number;

  public copy(other: Gwert): void {
    this.kategorie = other.kategorie;
    this.name = other.name;
    this.wert = other.wert;
  }
}
