export class Nachricht {
  sender: string;
  reciever: string;
  typ: string;
  subtyp: string;
  body: string;

  constructor() {
    this.sender = '';
    this.reciever = '';
    this.typ = '';
    this.subtyp = '';
    this.body = '';
  }

}
