export class GsonderfertigkeitVoraussetzung {
  stufe: number;
  kategorie: string; // zB. Talent
  katStufe: string; // zB. B
  kosten: number;
  // Voraussetzung "Rechnen 4" wird zu:
  voraussetzung: string; // Rechnen
  voraussetzungStufe: number; // 4

  constructor() {
    this.stufe = 1;
    this.kategorie = '';
    this.katStufe = '';
    this.kosten = 0;
    // Voraussetzung "Rechnen 4" wird zu:
    this.voraussetzung = '';
    this.voraussetzungStufe = 0;
  }

  public copy(other: GsonderfertigkeitVoraussetzung): void {
    this.stufe = other.stufe;
    this.kategorie = other.kategorie;
    this.katStufe = other.katStufe;
    this.kosten = other.kosten;
    // Voraussetzung "Rechnen 4" wird zu:
    this.voraussetzung = other.voraussetzung;
    this.voraussetzungStufe = other.voraussetzungStufe;
  }
}
