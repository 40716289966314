<div class="body">
  <h2>Tiere</h2>
  <div class="zeile" style="width: 100%; justify-content: space-around">
    <select [(ngModel)]="art" (ngModelChange)="aendereArt()">
      <option [value]="a" *ngFor="let a of arten">{{a}}</option>
    </select>
    <select [(ngModel)]="unterart" (ngModelChange)="aendereUnterart()">
      <option [value]="ua" *ngFor="let ua of unterarten">{{ua}}</option>
    </select>
    <select [(ngModel)]="name" (ngModelChange)="waehleTier()">
      <option [value]="sf" *ngFor="let sf of namen">{{sf}}</option>
    </select>
  </div>
  <div class="zeile" style="width: 100%; justify-content: flex-start">
    <h3>Neues Tier</h3>
    <div class="img">
      <img alt="plus" src="assets/images/button_s_pos.png" (click)="sendeTierAnDB()"/>
    </div>
    <div class="img">
      <img alt="arrows" src="assets/images/reload.png" (click)="resetTier()"/>
    </div>
  </div>
  <div class="zeile" style="width: 100%; justify-content: flex-start">
    <label>Art:</label>
    <input class="wideInput" type="text" [(ngModel)]="tier.art">
    <label>Unterart:</label>
    <input class="wideInput" type="text" [(ngModel)]="tier.unterArt">
    <label>Name:</label>
    <input class="wideInput" type="text" [(ngModel)]="tier.variante">
  </div>
  <div class="zeile">
    <label>Beschreibung:</label>
    <textarea class="vielText" [(ngModel)]="tier.beschreibung" (ngModelChange)="tier.beschreibung = entferneUmbrueche(tier.beschreibung)"></textarea>
  </div>
  <h3>Wissenswertes</h3>
  <div class="zeile" *ngFor="let akd of tier.anekdoten">
    <label>Anekdote:</label>
    <div>{{akd}}</div>
    <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneAnekdote(akd)"/>
  </div>
  <div class="zeile">
    <label>Name:</label>
    <textarea class="vielText" [(ngModel)]="neueAnekdote" (ngModelChange)="neueAnekdote = entferneUmbrueche(neueAnekdote)"></textarea>
    <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeAnekdoteHinzu()"/>
  </div>
  <div class="zeile">
    <label>Größe (raw input):</label>
    <input class="wideInput" type="text" [(ngModel)]="groesseRaw" (blur)="leseGroesseAus()">
    <div class="eintrag" *ngFor="let g of tier.groessen">{{g.kategorie}}: {{g.min}} bis {{g.max}}</div>
  </div>
  <div class="zeile">
    <label>Gewicht:</label>
    <input class="wideInput" type="number" [(ngModel)]="tier.minGewicht">
    &nbsp;bis&nbsp;
    <input class="wideInput" type="number" [(ngModel)]="tier.maxGewicht">
  </div>
  <div class="zeile">
    <label>Werte (raw input):</label>
    <input class="wideInput" type="text" [(ngModel)]="werteRaw" (blur)="leseWerteAus()">
  </div>
  <div class="zeile">
    <div class="eintrag" *ngFor="let w of tier.werte">
      <b>{{w.name}}</b>: {{w.wert}}
      <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneWert(w)"/>
    </div>

  </div>
  <div class="zeile">
    <label>NK-Angriffe:</label>
    <div class="komposition" *ngFor="let ang of tier.angriffenk">
      <label>Name:</label>
      <input type="text" [(ngModel)]="ang.angriffsart">
      <label>AT:</label>
      <input type="number" [(ngModel)]="ang.at">
      <label>TP:</label>
      <input type="text" [(ngModel)]="ang.schaden">
      <label>Reichweite:</label>
      <input type="text" [(ngModel)]="ang.reichweite">
      <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneAngriffNk(ang)"/>
    </div>
    <div class="komposition">
      <label>Name:</label>
      <input type="text" [(ngModel)]="neuerAngriffNk.angriffsart">
      <label>AT:</label>
      <input type="number" [(ngModel)]="neuerAngriffNk.at">
      <label>TP:</label>
      <input type="text" [(ngModel)]="neuerAngriffNk.schaden">
      <label>Reichweite:</label>
      <input type="text" [(ngModel)]="neuerAngriffNk.reichweite">
      <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeAngriffNkHinzu()"/>
    </div>
  </div>
  <div class="zeile">
    <label>FK-Angriffe:</label>
    <div class="komposition" *ngFor="let ang of tier.angriffefk">
      <label>Name:</label>
      <input type="text" [(ngModel)]="ang.angriffsart">
      <label>FK:</label>
      <input type="number" [(ngModel)]="ang.fk">
      <label>LZ:</label>
      <input type="number" [(ngModel)]="ang.lz">
      <label>TP:</label>
      <input type="text" [(ngModel)]="ang.schaden">
      <label>Reichweiten:</label>
      <input type="text" [(ngModel)]="ang.reichweiten">
      <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneAngriffFk(ang)"/>
    </div>
    <div class="komposition">
      <label>Name:</label>
      <input type="text" [(ngModel)]="neuerAngriffFk.angriffsart">
      <label>FK:</label>
      <input type="number" [(ngModel)]="neuerAngriffFk.fk">
      <label>LZ:</label>
      <input type="number" [(ngModel)]="neuerAngriffFk.lz">
      <label>TP:</label>
      <input type="text" [(ngModel)]="neuerAngriffFk.schaden">
      <label>Reichweiten:</label>
      <input type="text" [(ngModel)]="neuerAngriffFk.reichweiten">
      <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeAngriffFkHinzu()"/>
    </div>
  </div>
  <div class="zeile">
    <label>Aktionen:</label>
    <input type="number" [(ngModel)]="tier.aktionen">
    <label>Anzahl:</label>
    <input type="text" [(ngModel)]="tier.anzahl">
    <label>RS/BE:</label>
    <input type="text" [(ngModel)]="rsBeRaw" (blur)="verarbeiteRsBe()">
  </div>
  <div class="zeile">
    <label>Größe:</label>
    <select [(ngModel)]="tier.groessenKategorie">
      <option [value]="data" *ngFor="let data of zielgroessen">{{data}}</option>
    </select>
    <label>Typus:</label>
    <select [(ngModel)]="tier.typus">
      <option [value]="data" *ngFor="let data of zieltypen">{{data}}</option>
    </select>
  </div>

  <h3>Vor- und Nachteile:</h3>
  Bei empfohlen empfohlen# davor schreiben
  <div class="zeile">
    <div class="komposition" *ngFor="let data of tier.vun">
      <label>Name:</label>
      <input type="text" [(ngModel)]="data.name">
      <label>Stufe:</label>
      <input type="number" [(ngModel)]="data.stufe">
      <label>Kategorie:</label>
      <input type="text" [(ngModel)]="data.gewaehlteKategorie">
      <label>Spezifikation:</label>
      <input type="text" [(ngModel)]="data.spezifikation">
      <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneVuN(data)"/>
    </div>
    <div class="komposition">
      <label>Name:</label>
      <input type="text" [(ngModel)]="neueVuN.name">
      <label>Stufe:</label>
      <input type="number" [(ngModel)]="neueVuN.stufe">
      <label>Kategorie:</label>
      <input type="text" [(ngModel)]="neueVuN.gewaehlteKategorie">
      <label>Spezifikation:</label>
      <input type="text" [(ngModel)]="neueVuN.spezifikation">
      <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeVuNHinzu()"/>
    </div>
  </div>

  <h3>Sonderfertigkeiten:</h3>
  Bei empfohlen empfohlen# davor schreiben
  <div class="zeile">
    <div class="komposition" *ngFor="let sf of tier.sf">
      <label>Name:</label>
      <input type="text" [(ngModel)]="sf.name">
      <label>(Angriffe):</label>
      <input type="text" [(ngModel)]="sf.angriffsart">
      <label>Stufe:</label>
      <input type="number" [(ngModel)]="sf.stufe">
      <label>Kategorie:</label>
      <input type="text" [(ngModel)]="sf.gewaehlteKategorie">
      <label>Spezifikation:</label>
      <input type="text" [(ngModel)]="sf.spezifikation">
      <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneSF(sf)"/>
    </div>
    <div class="komposition">
      <label>Name:</label>
      <input type="text" [(ngModel)]="neueSonderfertigkeit.name">
      <label>(Angriffe):</label>
      <input type="text" [(ngModel)]="neueSonderfertigkeit.angriffsart">
      <label>Stufe:</label>
      <input type="number" [(ngModel)]="neueSonderfertigkeit.stufe">
      <label>Kategorie:</label>
      <input type="text" [(ngModel)]="neueSonderfertigkeit.gewaehlteKategorie">
      <label>Spezifikation:</label>
      <input type="text" [(ngModel)]="neueSonderfertigkeit.spezifikation">
      <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeSFHinzu()"/>
    </div>
  </div>
  <h3>Talente und Zauber</h3>
  <div class="zeile">
    <label>Talente (raw input):</label>
    <input class="wideInput" type="text" [(ngModel)]="talenteRaw" (blur)="leseTalente()">
  </div>
  <div class="zeile">
    <label>Zauber (raw input):</label>
    <input class="wideInput" type="text" [(ngModel)]="zauberRaw" (blur)="leseZauber()">
  </div>
  <div class="zeile">
    <label>Kampfverhalten:</label>
    <textarea class="vielText" [(ngModel)]="tier.kampfverhalten" (ngModelChange)="tier.kampfverhalten = entferneUmbrueche(tier.kampfverhalten)"></textarea>
  </div>
  <div class="zeile">
    <label>Flucht:</label>
    <textarea class="vielText" [(ngModel)]="tier.flucht" (ngModelChange)="tier.flucht = entferneUmbrueche(tier.flucht)"></textarea>
  </div>
  <h3>Wissensprobe</h3>
  <div class="zeile" *ngFor="let wissen of tier.wissensProbe">
    <label>{{wissen.talent}} ({{wissen.anwendung}})</label>
    <label>QS</label> {{wissen.qs}}: {{wissen.wissen}}
    <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneWissen(wissen)"/>
  </div>
  <div class="zeile">
    <label>Talent:</label>
    <select [(ngModel)]="neuesWissen.talent" (ngModelChange)="getAnwendungen()">
      <option *ngFor="let talent of talente">{{talent}}</option>
    </select>
    <label>Anwendung:</label>
    <select [(ngModel)]="neuesWissen.anwendung">
      <option *ngFor="let anw of anwendungen">{{anw}}</option>
    </select>
    <label>QS:</label>
    <input type="number" [(ngModel)]="neuesWissen.qs">
    <label>Wissen:</label>
    <input type="text" [(ngModel)]="neuesWissen.wissen">
    <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeWissenHinzu()"/>
  </div>

  <h3>Sonderregeln</h3>
  <div class="zeile" *ngFor="let data of tier.sonderregeln">
    <label>Name:</label>
    <input type="text" [(ngModel)]="data.name">
    <label>Regel:</label>
    <input type="text" [(ngModel)]="data.wert">
    <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneSonderregel(data)"/>
  </div>
  <div class="zeile">
    <label>Name:</label>
    <input type="text" [(ngModel)]="neueSonderregel.name">
    <label>Regel:</label>
    <input type="text" [(ngModel)]="neueSonderregel.wert">
    <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeSonderregelHinzu()"/>
  </div>

  <h3>Anmerkungen</h3>
  <div class="zeile" *ngFor="let anm of tier.anmerkungen">
    <label>Name:</label>
    <input type="text" [(ngModel)]="anm.name">
    <label>Anmerkung:</label>
    <input type="text" [(ngModel)]="anm.wert">
    <img class="roundButton" alt="plus" src="assets/images/button_s_neg.png" (click)="entferneAnmerkung(anm)"/>
  </div>
  <div class="zeile">
    <label>Name:</label>
    <input type="text" [(ngModel)]="neueAnmerkung.name">
    <label>Anmerkung:</label>
    <input type="text" [(ngModel)]="neueAnmerkung.wert">
    <img class="roundButton" alt="plus" src="assets/images/button_s_pos.png" (click)="fuegeAnmerkungHinzu()"/>
  </div>
</div>
