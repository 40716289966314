import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Eigenschaft} from '../../../_interfaces/eigenschaft';
import {Talent} from '../../../_interfaces/talent';
import {Talentanwendung} from '../../../_interfaces/talentanwendung';
import {EventTooltip} from '../../../_interfaces/event-tooltip';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.sass']
})
export class TooltipComponent implements OnInit {

  @Input()
  tooltip$!: boolean;
  @Input()
  talent$!: Talentanwendung;

  @Output() ping2: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  public activateTt(nr: number, talent: string): void {
    const eventObject: EventTooltip = {
      art: nr,
      talent: this.talent$.anwendungsgebiet,
      tal: false
    };
    this.ping2.emit(eventObject);
  }

  ngOnInit(): void {
  }

}
