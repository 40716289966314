import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../_interfaces/user';
import {DataService} from '../../_services/data.service';
import {BoolEvent} from '../../_interfaces/bool-event';
import {Charakter} from '../../_interfaces/charakter';
import {WebsocketService} from '../../_services/websocket.service';
import {Session} from 'inspector';
import {Benutzer} from '../../_classes/benutzer/benutzer';
import {HttpClient} from '@angular/common/http';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {PersistenceService} from '../../_services/persistence.service';
import {Mainmenu} from '../../_interfaces/components/mainmenu';
import localizeExtractLoader from '@angular-devkit/build-angular/src/extract-i18n/ivy-extract-loader';
import {Glossar} from '../../_classes/glossar/glossar';
import {StringEvent} from '../../_interfaces/events/string-event';
import {Gegenstand} from '../../_classes/ausruestung/gegenstand';
import {Allgemein} from '../../_classes/ausruestung/allgemein';
import {Seil} from '../../_classes/ausruestung/seil';
import {Ruestung} from '../../_classes/ausruestung/ruestung';
import {Parierwaffe} from '../../_classes/ausruestung/parierwaffe';
import {Nahkampfwaffe} from '../../_classes/ausruestung/nahkampfwaffe';
import {Leuchtmittel} from '../../_classes/ausruestung/leuchtmittel';
import {Fernkampfwaffe} from '../../_classes/ausruestung/fernkampfwaffe';
import {Alkohol} from '../../_classes/ausruestung/alkohol';
import {Behaeltnis} from '../../_classes/ausruestung/behaeltnis';
import {Gruppenmitglied} from '../../_classes/abenteuer/gruppenmitglied';
import {IpService} from '../../_services/ip.service';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.sass']
})
export class MainmenuComponent implements OnInit  {

  public localData: Mainmenu = {
    name: undefined,
    benutzer: undefined
  };
  public glossar: Glossar;
  public gruppe: Gruppenmitglied[];

  benutzerListe: Benutzer[] = [];
  public register: boolean;

  constructor(public dataService: DataService,
              private http: HttpClient,
              private persistence: PersistenceService,
              private ipService: IpService) {

    const connection = window.location.origin;
    // http://77.21.86.74:4200
    let array = connection.split('//');
    array = array[1].split(':');
    // const ip = array[0]; // meine IP
    const ip = this.ipService.getIp();
    // const ip = '192.168.137.1';
    // const ip = '192.168.178.47'; // LynnArne WLAN IP
    // const ip = '192.168.178.32'; // Mays IP
    // const ip = '192.168.178.20'; // Mays IP Desktop
    // const ip = '192.168.0.99';
    // console.log(ip);

    // sessionStorage.setItem('ip', ip);
    this.register = false;
  }

  ngOnInit(): void {
    const storedGlossar: Glossar = this.persistence.retrieveLocalComponent('glossar');
    const stored: Mainmenu = this.persistence.retrieveComponent('mainmenu');
    // console.log(stored);
    // console.log(storedGlossar);
    if (stored != null) {
      this.localData = stored;
    }
    if (storedGlossar != null) {
      console.log('read stored Glossar');
      this.glossar = new Glossar();
      this.glossar.copy(storedGlossar);
    }
    /*
    this.dataService.getGlossar().subscribe((data: Glossar) => {
      console.log(data);
    });
    */
    this.initialisiere();
  }



  public bestimmeBenutzer(): boolean {
    let set = false;
    const name = this.localData.name;
    if (name != null) {
      set = true;
      this.benutzerListe.forEach(b => {
        if (b.name === name) {
          this.localData.benutzer = new Benutzer();
          this.localData.benutzer.copy(b);
          this.persistence.storeComponent('mainmenu', this.localData);
          sessionStorage.setItem('benutzername', name);
        }
      });
    }
    return set;
  }





  public loadBenutzer(): void {
    this.dataService.getAlleBenutzer().subscribe((data: Benutzer[]) => {
      data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
      console.log('%cdata: getAlleBenutzer() -> recieved:', 'background: #ffe6f2; color: #660033');
      console.log(data);
      this.benutzerListe = [];
      data.forEach(d => {
        const neuerBenutzer: Benutzer = new Benutzer();
        neuerBenutzer.copy(d);
        this.benutzerListe.push(neuerBenutzer);
      });
      const stored: Mainmenu = this.persistence.retrieveComponent('mainmenu');
      // console.log(stored);
      if (stored == null) {

        const set = this.bestimmeBenutzer();
        if (set) {
          this.persistence.storeComponent('mainmenu', this.localData);
          console.log(this.persistence.retrieveComponent('mainmenu'));
        }
      }
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public loadGlossar(): void {
    const stored: Glossar = this.persistence.retrieveLocalComponent('glossar');
    // console.log(stored);
    if (stored == null) {
      console.log('stored == null');
      this.dataService.getGlossar().subscribe((data: Glossar) => {
        console.log('%cdata: getGlossar() -> recieved:', 'background: #ffe6f2; color: #660033');
        console.log(data);
        this.glossar = new Glossar();
        this.glossar.copy(data);
        this.persistence.storeLocalComponent('glossar', data);
        console.log(this.persistence.retrieveLocalComponent('glossar'));
        this.persistence.logLocalDiskUsage();
      }, error => {
        console.log('%cERROR: ${error.message}', 'color: red;');
      });
    } else {
      this.glossar = new Glossar();
      this.glossar.copy(stored);
    }
    this.persistence.logLocalDiskUsage();
  }

  public anmelden(): void {
    this.bestimmeBenutzer();
    if (this.localData.benutzer == null) {
      alert('Der Name ' + this.localData.name + ' existiert leider nicht in der Datenbank.');
    }
  }

  public registrieren(): void {
    this.dataService.registriereBenutzer(this.localData.name).subscribe((data: number) => {
      const id = data;
      if (id > 0) {
        this.loadBenutzer();
      }
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }


  initialisiere(): void {
    this.loadGlossar();
    this.loadBenutzer();
    this.dataService.getGruppe().subscribe((data: Gruppenmitglied[]) => {
      this.gruppe = data;
      this.gruppe.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    });
  }

  public openRegistration(): void {
    this.register = true;
  }

  public reload(e: StringEvent): void {
    if (e.wert === 'ausruestung') {
      this.dataService.getAusruestung().subscribe((data: Gegenstand[]) => {
        const ausruestung: Gegenstand[] = [];
        data.forEach(d => {
          let gegenstand: Gegenstand;
          if (d.type === 'Allgemein') {
            gegenstand = new Allgemein();
          } else if (d.type === 'Behältnis') {
            gegenstand = new Behaeltnis();
          } else if (d.type === 'Alkohol') {
            gegenstand = new Alkohol();
          } else if (d.type === 'Fernkampfwaffe') {
            gegenstand = new Fernkampfwaffe();
          } else if (d.type === 'Leuchtmittel') {
            gegenstand = new Leuchtmittel();
          } else if (d.type === 'Nahkampfwaffe') {
            gegenstand = new Nahkampfwaffe();
          } else if (d.type === 'Parierwaffe') {
            gegenstand = new Parierwaffe();
          } else if (d.type === 'Rüstung') {
            gegenstand = new Ruestung();
          } else if (d.type === 'Seile') {
            gegenstand = new Seil();
          }
          gegenstand.copy(d);
          ausruestung.push(gegenstand);
        });
        ausruestung.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
        ausruestung.sort((a, b) => (a.type.toLowerCase() < b.type.toLowerCase() ? -1 : 1));
        this.glossar.ausruestung = ausruestung;
        this.persistence.storeLocalComponent('glossar', this.glossar);
        console.log(this.persistence.retrieveLocalComponent('glossar'));
        this.persistence.logLocalDiskUsage();
      }, error => {
        console.log('%cERROR: ${error.message}', 'color: red;');
      });
    }
  }

  /*
  public isAuthenticated(): boolean {
    return sessionStorage.getItem('auth') === 'true';
  }



  public closeRegistration($event: BoolEvent): void {
    // console.log($event);
    this.register = false;
  }

  public loggedIn(): boolean {
    return sessionStorage.getItem('auth') === 'true';
  }
*/

  /*
  public actualizeActualUser(): void {
    // console.log('mainmenu: getChosenUser()');
    const $storedName = sessionStorage.getItem('user');
    this.$userlist.forEach((user) => {
      // console.log('Vergleich: ' + user.username + ' = ' + $storedName + '?');
      if (user.username === $storedName) {
        // console.log('true');
        // console.log(user);
        this.$user = user;
      }
    });
  }*/

  /*
  public loadData(): void {
    // console.log('load Data');
    this.$userlist = [];
    this.dataService.getAlleUser().subscribe((data: User[]) => {
      data.sort((a, b) => (a.username < b.username ? -1 : 1));
      this.$userlist = data;
      // console.log(data);
      this.$charakterList = [];
      this.dataService.getAlleCharaktere().subscribe((chars: Charakter[]) => {
        this.$charakterList = chars;
        // console.log(chars);
      }, error => {
        console.log('%cERROR: ${error.message}', 'color: red;');
      });
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }
  */

  getImageList(): string[] {
    const list: string[] = ['armor_f.png'
      , 'armor_m.png'
      , 'arrow.png'
      , 'banner_axe.png'
      , 'banner_charaktere.png'
      , 'banner_kampf.png'
      , 'banner_lagerfeuer.png'
      , 'banner_leer.png'
      , 'banner_silver.png'
      , 'banner_wuerfel.png'
      , 'bar_oben.png'
      , 'bar_unten.png'
      , 'button.png'
      , 'button_s.png'
      , 'button_silver.png'
      , 'button_s_neg.png'
      , 'button_s_pos.png'
      , 'check.png'
      , 'dices.png'
      , 'fahne_o.png'
      , 'fahne_o_blue.png'
      , 'fahne_o_darkorange.png'
      , 'fahne_o_green.png'
      , 'fahne_o_grey.png'
      , 'fahne_o_orange.png'
      , 'fahne_o_purple.png'
      , 'fahne_o_red.png'
      , 'fahne_o_silver.png'
      , 'fahne_o_yellow.png'
      , 'feather.png'
      , 'filelist.txt'
      , 'imageFinder.ps1'
      , 'paper_small.png'
      , 'pergament_horizontal.png'
      , 'plus.png'
      , 'reload.png'
      , 'roll_l.png'
      , 'roll_m.png'
      , 'split.png'];
    return list;
  }
}
