
<div class="spalten">
  <div class="zeilen">
    <!-- Hauptmenü -->
    <h1 *ngIf="$option === 0">Hauptmenü</h1>
    <div class="body" *ngIf="$option === 0">
      <div class="button" (click)="changeMenu(10)">
        <div class="buttontext">
          Glossar bearbeiten
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(20)">
        <div class="buttontext">
          Charaktermenü
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(30)">
        <div class="buttontext">
          Umgebung und Wetter
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(40)">
        <div class="buttontext">
          Erstellung
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
    </div>

    <!-- Glossar bearbeiten -->
    <h1 *ngIf="$option === 10">Glossar bearbeiten</h1>
    <div class="body" *ngIf="$option === 10">
      <div class="button" (click)="changeMenu(18)">
        <div class="buttontext">
          Gegenstand hinzufügen
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(15)">
        <div class="buttontext">
          Sonderfertigkeiten hinzufügen
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(16)">
        <div class="buttontext">
          Waffen hinzufügen
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(17)">
        <div class="buttontext">
          Rüstung hinzufügen
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(19)">
        <div class="buttontext">
          File-Upload
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
      <div class="button" (click)="changeMenu(11)">
        <div class="buttontext">
          Tier hinzufügen
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
    </div>
    <div class="addMenu" *ngIf="$option === 15">
      <app-gsonderfertigkeiten *ngIf="glossar != null" [glossar]="glossar"></app-gsonderfertigkeiten>
    </div>
    <div class="addMenu" *ngIf="$option === 16">
      <app-waffen></app-waffen>
    </div>
    <div class="addMenu" *ngIf="$option === 17">
      <app-ruestung></app-ruestung>
    </div>
    <div class="addMenu" *ngIf="$option === 18">
      <app-item [spielmeister]="true" [glossar]="glossar" (reload)="reloadEvent($event)" [held]="''"></app-item>
    </div>
    <div class="addMenu" *ngIf="$option === 19">
      <app-fileupload (imageSetter)="logEvent($event)"></app-fileupload>
    </div>
    <div class="addMenu" *ngIf="$option === 11">
      <app-gtier *ngIf="glossar != null" [glossar]="glossar"></app-gtier>
    </div>

    <!-- Charaktermenü -->
    <h1 *ngIf="$option === 20">Charaktermenü <img class="akt" alt="bild"  src="assets/images/reload.png"  (click)="getVerbundeneCharaktere()"/></h1>

    <div class="body" *ngIf="$option === 20">
      <div class="vertical">
        <div class="row">
          <div class="spieler{{ausgewaehlterCharakter === name}}" *ngFor="let name of verbunden index as i" (click)="waehleCharakter(name, verbundenIds[i])">
            <div class="portraitContainer">
              <div class="portrait"  [style.background-image]="'url(' + portraits[i] + ')'"></div>
            </div>
            <div class="name">{{name}}</div>
          </div>
          <div *ngIf="verbunden.length === 0">kein Charakter verbunden</div>
        </div>
        <input type="text" [(ngModel)]="kategorie">
        <button (click)="stelleAnfrage()">Anfrage</button>
        <div *ngFor="let wert of angefragtes">{{wert.name}}: {{wert.wert}}</div>
      </div>

    </div>


    <!-- Umgebung und Wetter -->
    <h1 *ngIf="$option === 30">Umgebung und Wetter</h1>

    <div class="body" *ngIf="$option === 30">
      <div class="vertical">
        <div class="region" style="background-image: url('assets/images/Gelaende/{{aktRegion}}.jpg')"></div>
        <div class="zeile">
          <label class="b22">Region:</label>
          <select  [(ngModel)]="aktRegion" (change)="updateRegion()">
            <option [value]="region" *ngFor="let region of regions">{{region}}</option>
          </select>
          <label class="b13">in der Wüste:</label>
          <input type="checkbox" [(ngModel)]="inWueste">

          <label class="b13">windige Region:</label>
          <input type="checkbox" [(ngModel)]="windigeRegion">
        </div>
        <div class="zeile">
          <label class="b22">geographische Höhe:</label>
          <select [(ngModel)]="region">
            <option [value]="hoehe" *ngFor="let hoehe of geoRegion">{{hoehe}}</option>
          </select>
          <label class="b13">Jahreszeit:</label>
          <select [(ngModel)]="jahreszeit">
            <option [value]="jz" *ngFor="let jz of jahreszeiten">{{jz}}</option>
          </select>
        </div>
        <h4>Wetter</h4>
        <div class="zeile">
          <button (click)="berechneDreiTageWetter()">berechne Wetter</button>
          <button (click)="aktualisiereWettervorhersage()">Wettervorhersage</button>
        </div>
        <div class="vertical">
          <div class="zeile" style="width: 120px; justify-content: space-around">
            <div *ngFor="let weather of dreiTageWetter index as i" class="b3h" style="border: 1px solid black; font-size: large; color: white; background-color: cornflowerblue; text-align: center">{{wetterWolken.get(weather.bewoelkung)}}</div>
          </div>
        </div>
        <div class="vertical" *ngFor="let weather of dreiTageWetter index as i">
          <h4>{{wettervorhersagen[i]}}</h4>
          <div class="zeile">
            <label class="b22">Bewölkung:</label>
            <div class="b70">{{weather.bewoelkung}}</div>
          </div>
          <div class="zeile">
            <label class="b22">Windstärke:</label>
            <div class="b70">{{weather.windstaerke}}</div>
          </div>
          <div class="zeile">
            <label class="b22">Wellenhöhe:</label>
            <div class="b70">{{weather.wellenhoehe}}</div>
          </div>
          <div class="zeile">
            <label class="b22">Niederschlag:</label>
            <div class="b70">{{weather.niederschlag}}</div>
          </div>
          <div class="zeile">
            <label class="b22">Temperatur Tag / Nacht:</label>
            <div class="b70">{{weather.temperaturTag}}°C / {{weather.temperaturNacht}}°C</div>
          </div>
        </div>

        <div class="zeile"></div>
        <div class="zeile"></div>

      </div>
    </div>


    <!-- Erstellung -->
    <h1 *ngIf="$option === 40">Erstellung bearbeiten</h1>
    <div class="body" *ngIf="$option === 40">
      <div class="button" (click)="changeMenu(41)">
        <div class="buttontext">
          Tier erstellen
        </div>
        <img src="../../../assets/images/roll_m.png"/>
      </div>
    </div>
    <div class="addMenu" *ngIf="$option === 41">
      <app-tier [glossar]="glossar"></app-tier>
    </div>

  </div>



  <div class="infoBereich">
    <div class="infoZeile">
      <div class="connection" id="lamp" [style.background-color]="lampColor" (click)="toggleConnection()"></div>
      <label>Websocket Status</label>
    </div>
    <div class="infoZeile">
      <div class="links">Region:</div>
      <select  [(ngModel)]="aktRegion" (change)="updateRegion()">
        <option [value]="region" *ngFor="let region of regions">{{region}}</option>
      </select>
    </div>
    <div class="infoZeile">
      <div class="links">Datum:</div><input type="text" [(ngModel)]="datum" (blur)="updateDate()">
      <img alt="plus" src="assets/images/button_s_pos.png" (click)="naechsterTag()"/>
    </div>
    <div class="infoZeile">
      <div class="links">Zwölfgötter:</div>{{datumZG}}
    </div>
    <div class="infoZeile">
      <div class="links">Thorwal:</div>{{datumThorwal}}
    </div>
    <div class="infoZeile">
      <div class="links">Zwerge:</div>{{datumZwerge}}
    </div>
    <div class="infoZeile">
      <div class="links">irdisch:</div>{{datumIrdisch}}
    </div>
    <div class="infoZeile" *ngFor="let msg of messages">
      {{msg}}
    </div>
    <div class="infoZeile" *ngFor="let recipient of objectMessages | keyvalue">
      <label>{{recipient.key}}</label><div class="infoButton" (click)="sendGegenstand(recipient.value)">lade Gegenstand</div>
    </div>

  </div>
</div>

<div class="back" (click)="navigateBack()">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    zurück
  </div>
</div>
