import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Talentwert} from '../../_interfaces/talentwert';
import {Eigenschaft} from '../../_interfaces/eigenschaft';
import {DataService} from '../../_services/data.service';
import {User} from '../../_interfaces/user';
import {Charakter} from '../../_interfaces/charakter';
import {WebsocketService} from '../../_services/websocket.service';
import {CharakterEvent} from '../../_interfaces/events/charakter-event';
import {Step7} from '../../_interfaces/charaktererstellung/step7';
import {Sonderfertigkeit} from '../../_interfaces/sonderfertigkeit';
import {Talent} from '../../_interfaces/talent';
import {FormControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// import {Kaempferin} from '../../_interfaces/kaempferin';
import {ObjectFactoryService} from '../../_services/object-factory.service';
import {HeldenblattCommService} from '../../_services/heldenblatt-comm.service';
import {Kaempferin} from '../../_classes/kampf/kaempferin';
import {Heldenbogen} from '../../_interfaces/components/sheet/heldenbogen';
import {PersistenceService} from '../../_services/persistence.service';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {StringEvent} from '../../_interfaces/events/string-event';
import {Gruppenmitglied} from '../../_classes/abenteuer/gruppenmitglied';
import {NameWert} from '../../_classes/name-wert';
import {AbenteuerService} from '../../_services/abenteuer.service';
import {MenuOptions} from '../../_classes/menu/menuOptions';
import {WertStringString} from '../../_classes/menu/wert-string-string';
import {WertStringNumber} from '../../_classes/menu/wert-string-number';
import {BeeinflussteWerte} from '../../_classes/charakter/beeinflusste-werte';
import {Nachricht} from '../../_classes/comm/nachricht';
import {Werte} from '../../_classes/charakter/werte';
import {WebsocketNewService} from '../../_services/websocket-new.service';
import {Message} from '../../_classes/comm/message';

@Component({
  selector: 'app-heldenbogen',
  templateUrl: './heldenbogen.component.html',
  styleUrls: ['./heldenbogen.component.sass']
})
export class HeldenbogenComponent implements OnInit, OnDestroy {

  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Output() reload: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;
  @Input() gruppe: Gruppenmitglied[];


  destroyed = new Subject();

  id: string;
  msgprefix = 'katalog_';

  oldChar: Charakter;
  destroyed$ = new Subject();

  options: MenuOptions = new MenuOptions();

  abenteuerDaten: NameWert[] = [];
  region = '';
  datum: string[];
  alter: string;

  katalog: any;

  // public localData: Heldenbogen = {
  //   navigation: undefined
  // };

  background = 'Kulturland';
  backgrounds = ['Dschungel', 'Eis- und Schnee', 'Gebirge', 'Höhle', 'Kulturland', 'Meer', 'Steppe', 'Sumpf', 'Wald', 'Wüste'];
  index: number = null;

  /*
  private chatSub$: Observable<any>;
  public kaemperin: Kaempferin;
  messages: string[] = [];
  kaempfer: Kaempferin[];

  // websocket
  destroyed$ = new Subject();
  */


  constructor(public dataService: DataService,
              private websocket: WebsocketService,
              private factory: ObjectFactoryService,
              private sharedService: HeldenblattCommService,
              private persistence: PersistenceService,
              private abenteuerService: AbenteuerService,
              private websocket2: WebsocketNewService) {


    // this.kaempfer = [];
    // this.kaemperin = new Kaempferin();
    // console.log(sessionStorage.getItem('char'));
    // const navString = sessionStorage.getItem('navigation');
    // const nav: number = +sessionStorage.getItem('navigation');
    /*if (navString === undefined || navString === null || navString === '') {
      this.localData.navigation = 0;
      sessionStorage.setItem('navigation', '0');
    } else {
      this.localData.navigation = nav;
    }*/
  }

  ngOnInit(): void {
    this.options.setWertNumber('navigation', undefined);

    this.id = this.charakter.id + '';
    console.log('[HELDENERSCHAFFUNG]',this.id);
    this.loadData();
    this.index = Math.floor(Math.random() * 9);
    console.log(this.charakter);
    // const stored: Heldenbogen = this.persistence.retrieveComponent('heldenbogen');
    const stored: MenuOptions = this.persistence.retrieveComponent('heldenbogen');
    console.log(stored);
    if (stored != null) {
      // this.localData = stored;
      this.options = new MenuOptions();
      this.options.copy(stored);
    } else {
      this.changeNav(2);
    }
    console.log(this.charakter);
    const abenteuerWS = this.websocket.connect(String(this.charakter.id), 'abenteuer').pipe(
      takeUntil(this.destroyed$),
    );

    abenteuerWS.subscribe((message: string) => {
      console.log(message);
      const nachricht: Nachricht = JSON.parse(message);
      console.log(nachricht);
      if (nachricht.typ === 'obj') {
        this.handleObject(nachricht);
      } else if (nachricht.typ === 'cmd'){
        this.handleCommand(nachricht);
      }
    });
  }

  handleObject(nachricht: Nachricht): void {
    let message = nachricht.body;
    while (message.includes('\\"')) {
      message = message.replace('\\"', '"');
    }
    const object = JSON.parse(message);
    console.log(object);
    if (nachricht.subtyp === 'nameWert<>') {
      (object as NameWert[]).forEach(nw => {
        this.abenteuerDaten.push(nw);
        if (nw.name === 'Region') {
          this.region = nw.wert;
        } else if (nw.name === 'Datum') {
          const datumRaw = nw.wert;
          this.datum = [];
          this.datum.push(this.abenteuerService.getDatum(datumRaw, 'Zwölfgötter'));
          this.datum.push(this.abenteuerService.getDatum(datumRaw, 'Thorwal'));
          this.datum.push(this.abenteuerService.getDatum(datumRaw, 'Zwerge'));
          this.datum.push(this.abenteuerService.getDatum(datumRaw, 'irdisch'));
          this.alter = this.abenteuerService.getAlterInJahren(datumRaw, this.charakter.tsaTag);
          if (this.alter !== 'unbekannt') {
            this.charakter.alter = Number(this.alter.split(' ')[0]);
          }
        }
      });
    } else if (nachricht.subtyp === 'zustand') {
      const zustand = (object as BeeinflussteWerte);
      this.charakter.updateBeeinflWert(zustand);
    }

    const websocket2Conn = this.websocket2.connect(this.id).pipe(
      takeUntil(this.destroyed),
    );

    websocket2Conn.subscribe((raw: string) => {
      const message2: Message = JSON.parse(raw);
      if (message2.body != null && message2.body.length > 0) {
        if (message2.type.startsWith(this.msgprefix)) {
          const msg_type = message2.type.substring(this.msgprefix.length);
          console.log('[HELDENERSCHAFFUNG][WERTE]', msg_type);
          if (msg_type === 'get') {
            this.katalog = JSON.parse(message2.body);
            console.log('[HELDENERSCHAFFUNG][WERTE]', this.katalog);
          }
        }
      } else {
        console.error('MESSAGE BODY IS NULL');
      }
    });
    this.getSF();
  }

  private getSF(): void {
    const message: Message = new Message('katalog', this.msgprefix + 'get', '',
      0, -1, this.msgprefix + 'get');
    this.websocket2.sendMessage(message);
  }

  handleCommand(nachricht: Nachricht): void {
    if (nachricht.body === 'getWerteDerKategorie()') {

      const neueNachricht: Nachricht = new Nachricht();
      neueNachricht.sender = String(this.charakter.id);
      neueNachricht.reciever = 'GM';
      neueNachricht.typ = 'fwdobj';
      neueNachricht.subtyp = nachricht.subtyp;
      neueNachricht.body = JSON.stringify(this.charakter.getWerteDerKategorie(neueNachricht.subtyp));
      // this.websocket.sendWithProtocol(String(this.charakter.id), 'GM',
      //         '[obj][werte<>]' + JSON.stringify(this.charakter.getWerteDerKategorie(kategorie)));
      this.websocket.sendNachricht(neueNachricht);
      console.log(neueNachricht);
      console.log('werte der Kategorie ' + neueNachricht.subtyp + ' gesendet!');
    } else if (nachricht.body === 'getWert()') {
      const neueNachricht: Nachricht = new Nachricht();
      neueNachricht.sender = String(this.charakter.id);
      neueNachricht.reciever = 'GM';
      neueNachricht.typ = 'fwdobj';
      neueNachricht.subtyp = nachricht.subtyp;
      const werte: Werte[] = [];
      werte.push(this.charakter.getWert(neueNachricht.subtyp));
      neueNachricht.body = JSON.stringify(werte);
      this.websocket.sendNachricht(neueNachricht);
    }
  }

  public loadData(): void {
    this.dataService.getCharakterWithName(sessionStorage.getItem('benutzername'), this.charakter.name).subscribe((data: Charakter) => {
      this.oldChar = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  /*public loadData(): void {
    this.dataService.getCharakterWithName(sessionStorage.getItem('user'), sessionStorage.getItem('char')).subscribe((data: Charakter) => {
      this.charakter = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }*/

  public changeNav(nav: number): void {
    // this.localData.navigation = nav;
    // this.persistence.storeComponent('heldenbogen', this.localData);
    this.options.setWertNumber('navigation', nav);
    this.persistence.storeComponent('heldenbogen', this.options);
    // const navString = sessionStorage.setItem('navigation', '' + this.localData.navigation);
  }

  public updateChar(): void {
    this.update.emit();
  }

  public saveCharakter(): void {
    /*const $username = sessionStorage.getItem('user');
    console.log(this.charakter);*/
    if (this.charakter != null) {
      this.dataService.saveCharakter('/charakter/'
        + this.charakter.name, this.charakter).subscribe((data: boolean) => {
        if (data) {
          window.alert(this.charakter.name + ' wurde an die Dantenbank übermittelt.');
        } else {
          window.alert('Bei der Übermittlung ist ein Fehler aufgetreten.');
        }
      }, error => {
        console.log('%cERROR: ${error.message}', 'color: red;');
      });
    } else {
      window.alert('Der Charakter ist null oder undefined.');
    }
  }

  public alertParent(): void {
    this.ping.emit();
  }

  public reloadEvent(e: StringEvent): void {
    const event: StringEvent = {
      wert: e.wert
    };
    this.reload.emit(event);
  }

  ngOnDestroy(): void {
    // this.destroyed$.next();
  }

}
