import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { Talentwert } from '../../_interfaces/talentwert';
import { DataService } from '../../_services/data.service';
import {Eigenschaft} from '../../_interfaces/eigenschaft';
import {Talent} from '../../_interfaces/talent';
import {EventTooltip} from '../../_interfaces/event-tooltip';
import {Talentanwendung} from '../../_interfaces/talentanwendung';
import {Charakter} from '../../_interfaces/charakter';
import {StringEvent} from '../../_interfaces/events/string-event';
import {Glossar} from '../../_classes/glossar/glossar';
import {Heldin} from '../../_classes/charakter/heldin';
import {Werte} from '../../_classes/charakter/werte';

@Component({
  selector: 'app-talentblatt',
  templateUrl: './talentblatt.component.html',
  styleUrls: ['./talentblatt.component.sass']
})
export class TalentblattComponent implements OnInit, OnChanges {
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  public $talentItemsK: Talentwert[];
  public $talentItemsG: Talentwert[];
  public $talentItemsN: Talentwert[];
  public $talentItemsW: Talentwert[];
  public $talentItemsH: Talentwert[];
  public $talente: Talent[];
  public $talentanwendungen: Talentanwendung[];
  public $attributeK: boolean;
  public $attributeG: boolean;
  public $attributeN: boolean;
  public $attributeW: boolean;
  public $attributeH: boolean;

  public $currentTalentInfo: Talent;
  public $currentTalentAnwendungInfo: Talentanwendung;


  public stufen = ['-', 'I', 'II', 'III', 'IV'];

  constructor(public dataService: DataService) {
    console.log('TalentblattComponent constructor');
    this.$talentItemsK = [];
    this.$talentItemsG = [];
    this.$talentItemsN = [];
    this.$talentItemsW = [];
    this.$talentItemsH = [];
    this.resetCurrentTalentInfo();
    this.resetCurrentTalentanwendungInfo();

    this.$attributeK = false;
    this.$attributeG = false;
    this.$attributeN = false;
    this.$attributeW = false;
    this.$attributeH = false;
  }

  ngOnInit(): void {
    this.loadData();
    // this.calculateSchmerz();
  }

  ngOnChanges(): void {
    this.loadData();
  }

  public loadData(): void {
    this.$talentItemsK = [];
    this.$talentItemsG = [];
    this.$talentItemsN = [];
    this.$talentItemsW = [];
    this.$talentItemsH = [];
    this.$talente = [];
    this.$talentanwendungen = [];
    const talente: Werte[] = this.charakter.getAlleWerteDerKategorie('Talent');
    // console.log(talente);
    talente.sort((a, b) => (a.name < b.name ? -1 : 1));
    talente.forEach(charTalent => {
      this.glossar.talente.forEach(gTalent => {
        if (charTalent.name === gTalent.name) {
          const werte: number[] = [];
          gTalent.probe.forEach(attr => {
            werte.push(this.charakter.getWert(attr).akt());
          });
          const talent: Talentwert = {
            name: charTalent.name,
            probe: gTalent.probe,
            art: gTalent.talentArt,
            aktFW: charTalent.wert,
            maxFW: charTalent.wert,
            be: gTalent.belastung,
            probeWerte: werte,
            routine: '',
            stg: gTalent.steigerungskosten,
            startWert: 0
          };
          // console.log(talent);
          if (talent.art === 'körperlich') {
            this.$talentItemsK.push(talent);
          } else if (talent.art === 'gesellschaftlich') {
            this.$talentItemsG.push(talent);
          } else if (talent.art === 'natur') {
            this.$talentItemsN.push(talent);
          } else if (talent.art === 'wissen') {
            this.$talentItemsW.push(talent);
          } else if (talent.art === 'handwerk') {
            this.$talentItemsH.push(talent);
          }
        }
      });
    });

    this.dataService.getAlleTalente().subscribe((data: Talent[]) => {
      this.$talente = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });

    this.dataService.getAlleTalentanwendungen().subscribe((data: Talentanwendung[]) => {
      this.$talentanwendungen = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public changeAttributeView(typ: number): void {
    switch (typ) {
      case 0:
        this.$attributeK = !this.$attributeK;
        break;
      case 1:
        this.$attributeG = !this.$attributeG;
        break;
      case 2:
        this.$attributeN = !this.$attributeN;
        break;
      case 3:
        this.$attributeW = !this.$attributeW;
        break;
      case 4:
        this.$attributeH = !this.$attributeH;
        break;
    }
  }

  public getTalentInfo(event: StringEvent): void {
    console.log('getTalentInfo');
    if (this.$currentTalentInfo.name === event.wert) {
      this.resetCurrentTalentInfo();
    } else {
      this.$talente.forEach(info => {
        if (info.name === event.wert) {
          this.$currentTalentInfo = info;
        }
      });
      console.log(this.$currentTalentInfo);
    }
  }

  public getTalentAnwendungInfo(name: string): void {
    console.log('getTalentAnwendungInfo(' + name + ')');
    if (this.$currentTalentAnwendungInfo.anwendungsgebiet === name) {
      this.resetCurrentTalentanwendungInfo();
    } else {
      this.$talentanwendungen.forEach(info => {
        if (info.anwendungsgebiet === name) {
          this.$currentTalentAnwendungInfo = info;
        }
      });
    }
  }

  public resetCurrentTalentInfo(): void {
    this.$currentTalentInfo = {
      name: '',
      talentArt: '',
      einsatzmoeglichkeiten: [],
      probe: [],
      beschreibung: '',
      anwendungsgebiete: [],
      belastung: '',
      werkzeuge: [],
      qualitaet: '',
      misslungeneProbe: '',
      kritischerErfolg: '',
      patzer: '',
      steigerungskosten: ''
    };
    this.resetCurrentTalentanwendungInfo();
  }

  public resetCurrentTalentanwendungInfo(): void {
    this.$currentTalentAnwendungInfo = {
      talent: '',
      anwendungsgebiet: '',
      beschreibung: '',
      probenArt: '',
      handlungsModifikator: [],
      beispiel: ''
    };
  }

  getAttributeWert(attr: string): number {
    this.charakter.calculateGesamtModifikationFuer(attr);
    const wert: number = this.charakter.getWert(attr).akt();
    return wert;
  }

  public calculateSchmerz(): void {
    /*let schmerz = this.charakter.basiswerte.schmerzDurchLep;
    schmerz -= -this.charakter.basiswerte.manuellerSchmerz;
    if (schmerz <= 4) {
      this.charakter.basiswerte.modifikatoren.schmerz = schmerz;
    } else {
      this.charakter.basiswerte.modifikatoren.schmerz = 4;
    }*/
  }
}
