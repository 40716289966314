<h3>Charakter fertigstellen</h3>
<h3>Einen Namen wählen</h3>
<div *ngIf="heldin.kultur.namensInformationen !== undefined && heldin.kultur.namensInformationen !== ''" class="kasten">
  {{heldin.kultur.namensInformationen}}
</div>
<b>Namensvorschläge</b>
<div class="kasten">
  <div class="spalte" *ngIf="$vorNamenListe.length > 0">
    <b>Vornamen</b>
    <div *ngFor="let name of $vorNamenListe">{{name}}</div>
  </div>
  <div class="spalte" *ngIf="$nachNamenListe.length > 0">
    <b>Nachnamen</b>
    <div *ngFor="let name of $nachNamenListe">{{name}}</div>
    <div *ngIf="$nachNamenListeAdel.length > 0">
      <div *ngFor="let name of $nachNamenListe">{{name}} (adel)</div>
    </div>
  </div>
</div>
<div>
  <b>Dein Name: </b>
  <input type="text" [(ngModel)]="heldin.name" (ngModelChange)="alertParent()">
</div>
<div>
  <h3>Alter</h3>
  <div>
    <b>manuell: </b>
    <input type="number" [(ngModel)]="heldin.alter" (ngModelChange)="alertParent()">
  </div>
  <div>
    <b>Gewürfelt ({{alterWuerfel}})</b>
    <input type="number" [(ngModel)]="alterWurf" (ngModelChange)="werfeAlter()">
  </div>
</div>
