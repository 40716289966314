import {Kaempferin} from './kampf/kaempferin';

export class Npc {
  kaempferin: Kaempferin;
  type: string;
  stufe: number;
  spezies: number;
  kultur: number;
  profession: number;

  constructor() {
    this.kaempferin = new Kaempferin();
    this.type = 'Wache';
    this.stufe = 0;
    this.spezies = 4;
    this.kultur = 13;
    this.profession = 0;
  }
}
