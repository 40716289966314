import {Allgemein} from './allgemein';

export class Leuchtmittel extends Allgemein {
  einheitBrenndauer: string;
  brenndauer: number;
  radius: number;

  public copy(other: Leuchtmittel): void {
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
    this.beschreibung = other.beschreibung;
    this.zitat = other.zitat;
    this.preis = other.preis;
    this.gewicht = other.gewicht;
    this.komplexitaet = other.komplexitaet;
    this.anzahl = other.anzahl;
    this.strukturpunkte = other.strukturpunkte;
    this.regel = other.regel;
    this.einheitBrenndauer = other.einheitBrenndauer;
    this.brenndauer = other.brenndauer;
    this.radius = other.radius;
  }
}
