import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Kultur} from '../../../_interfaces/kultur';
import {Step5} from '../../../_interfaces/charaktererstellung/step5';
import {Step10} from '../../../_interfaces/charaktererstellung/step10';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';

@Component({
  selector: 'app-finalisierung',
  templateUrl: './finalisierung.component.html',
  styleUrls: ['./finalisierung.component.sass']
})
export class FinalisierungComponent implements OnInit {
  @Input() heldin: Heldenerschaffung;
  // public $name: string;
  // public $alter: number;

  // Mensch 12+1W3 14+1W3 16+1W3 20+1W6 25+1W6 30+1W6 35+1W6
  // Elf 12+1W6 19+1W6 26+2W6 39+3W6 58+4W6 83+5W6 113+6W6
  // Halbelf 12+1W3 14+1W3 16+1W3 20+1W6 25+1W6 30+1W6 35+1W6
  // Zwerg 20+1W6 27+2W6 40+3W6 59+4W6 84+5W6 105+6W6 142+7W6

  public startAlterWuerfelMensch = ['1W3', '1W3', '1W3', '1W6', '1W6', '1W6', '1W6'];
  public startAlterMensch = [14, 16, 18, 23, 30, 37, 45];
  public startAlterWuerfelElf = ['1W6', '1W6', '2W6', '3W6', '4W6', '5W6', '6W6'];
  public startAlterElf = [14, 19, 26, 39, 58, 83, 113];
  public startAlterWuerfelHalbelf = ['1W3', '1W3', '1W3', '1W6', '1W6', '1W6', '1W6'];
  public startAlterHalbelf = [14, 16, 18, 23, 30, 37, 45];
  public startAlterWuerfelZwerg = ['1W6', '2W6', '3W6', '4W6', '5W6', '6W6', '7W6'];
  public startAlterZwerg = [20, 27, 40, 59, 84, 105, 142];

  public alterWuerfel = '';
  public alterMod = 0;
  public alterWurf: number;
  public alter = '';

  public $vorNamenListe: string[];
  public $nachNamenListe: string[];
  public $nachNamenListeAdel: string[];

  constructor() {
    this.$vorNamenListe = [];
    this.$nachNamenListe = [];
    this.$nachNamenListeAdel = [];
  }

  ngOnInit(): void {
    this.getVorgeschlageneNamen();
    this.getStartAlterWurf();
  }

  public getVorgeschlageneNamen(): void {
    if (this.heldin.weiblich) {
      this.heldin.kultur.typischeNamenFrau.forEach(name => {
        this.$vorNamenListe.push(name);
      });
      this.heldin.kultur.typischeNamenEhrenfrau.forEach(name => {
        this.$vorNamenListe.push(name);
      });
    } else {
      this.heldin.kultur.typischeNamenMann.forEach(name => {
        this.$vorNamenListe.push(name);
      });
      this.heldin.kultur.typischeNamenEhrenmann.forEach(name => {
        this.$vorNamenListe.push(name);
      });
    }
    this.heldin.kultur.typischeNamenAdelsfamilie.forEach(name => {
      this.$nachNamenListeAdel.push(name);
    });
    this.heldin.kultur.typischeNamenNachname.forEach(name => {
      this.$nachNamenListe.push(name);
    });
    this.heldin.kultur.typischeNamenStaemme.forEach(name => {
      this.$nachNamenListe.push(name);
    });
    this.heldin.kultur.typischeNamenTocamuyac.forEach(name => {
      this.$vorNamenListe.push(name);
    });
    this.heldin.kultur.typischeNamenUtulu.forEach(name => {
      this.$vorNamenListe.push(name);
    });
    this.heldin.kultur.typischeNamenWaldmenschen.forEach(name => {
      this.$vorNamenListe.push(name);
    });
  }

  public getStartAlterWurf(): void {
    const stufe = this.heldin.erfahrung.stufe;
    if (this.heldin.spezies.art === 'Menschen') {
      this.alterWuerfel = this.startAlterWuerfelMensch[stufe];
      this.alterMod = this.startAlterMensch[stufe];
    } else if (this.heldin.spezies.art === 'Elfen') {
      this.alterWuerfel = this.startAlterWuerfelElf[stufe];
      this.alterMod = this.startAlterElf[stufe];
    } else if (this.heldin.spezies.art === 'Halbelfen') {
      this.alterWuerfel = this.startAlterWuerfelHalbelf[stufe];
      this.alterMod = this.startAlterHalbelf[stufe];
    } else if (this.heldin.spezies.art === 'Zwerge') {
      this.alterWuerfel = this.startAlterWuerfelZwerg[stufe];
      this.alterMod = this.startAlterZwerg[stufe];
    }
  }

  public werfeAlter(): void {
    this.heldin.alter = this.alterWurf + this.alterMod;
    this.alter = '' + this.heldin.alter;
  }

  public alertParent(): void {
    /* const eventObject: Step10 = {
      $alter: this.$alter,
      $name: this.$name
    };
    this.ping.emit(eventObject);*/
  }

}
