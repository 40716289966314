import {Component, Input, OnInit, OnChanges, SimpleChanges, Inject, Output, EventEmitter} from '@angular/core';
import {User} from '../../_interfaces/user';
import {DataService} from '../../_services/data.service';
import {$u} from 'codelyzer/angular/styles/chars';
import {Talentwert} from '../../_interfaces/talentwert';
import {Step10} from '../../_interfaces/charaktererstellung/step10';
import {NumberEvent} from '../../_interfaces/events/number-event';
import {Charakter} from '../../_interfaces/charakter';
import {CharakterEvent} from '../../_interfaces/events/charakter-event';
import {Benutzer} from '../../_classes/benutzer/benutzer';
import {Auswahlbildschirm} from '../../_interfaces/components/auswahlbildschirm';
import {PersistenceService} from '../../_services/persistence.service';
import {Mainmenu} from '../../_interfaces/components/mainmenu';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {DOCUMENT} from '@angular/common';
import {StringEvent} from '../../_interfaces/events/string-event';
import {Gruppenmitglied} from '../../_classes/abenteuer/gruppenmitglied';

@Component({
  selector: 'app-auswahlbildschirm',
  templateUrl: './auswahlbildschirm.component.html',
  styleUrls: ['./auswahlbildschirm.component.sass']
})
export class AuswahlbildschirmComponent implements OnInit {

  @Output() reload: EventEmitter<any> = new EventEmitter<any>();
  @Input() benutzer: Benutzer;
  @Input() glossar: Glossar;
  @Input() gruppe: Gruppenmitglied[];
  heldin: Heldin;

  public localData: Auswahlbildschirm = {
    nav: undefined,
    spielmeister: undefined,
    charakter: undefined
  };

  public spielmeisterNamen = ['jan', 'spielmeister'];

  constructor(@Inject(DOCUMENT) private document: Document,
              private dataService: DataService,
              private persistence: PersistenceService) {
  }

  ngOnInit(): void {
    this.localData.spielmeister = false;
    if (this.spielmeisterNamen.includes(this.benutzer.name.toLowerCase())) {
      this.localData.spielmeister = true;
    }
    const stored: Auswahlbildschirm = this.persistence.retrieveComponent('auswahlbildschirm');
    console.log(stored);
    console.log(this.glossar);
    if (stored != null) {
      this.localData = stored;
      if (this.localData.charakter != null) {
        const charakter: Heldin = new Heldin();
        charakter.copy(this.localData.charakter);
        this.localData.charakter = charakter;
      }
    } else {
      this.changeNav(0);
    }
    /*this.dataService.getHeldin('Fianna Funkenschein').subscribe((data: Heldin) => {
      this.heldin = new Heldin();
      this.heldin.copy(data);
      console.log(this.heldin);
    });*/
  }

  public changeNav(nr: number): void {
    if (nr === 0) {
      const mainData: Mainmenu = this.persistence.retrieveComponent('mainmenu');
      sessionStorage.clear();
      this.persistence.storeComponent('mainmenu', mainData);
      this.document.defaultView.location.reload();
    }
    this.localData.nav = nr;
    this.persistence.storeComponent('auswahlbildschirm', this.localData);
  }


  public navEvent(event: NumberEvent): void {
    this.localData.nav = event.$wert;
  }


  public getCharakter(name: string): void {
    this.dataService.getHeldin(name).subscribe((charakter: Heldin) => {
      if (charakter != null) {
        console.log(charakter);
        this.localData.charakter = new Heldin();
        this.localData.charakter.copy(charakter);
        console.log(this.glossar);
        this.changeNav(10);
      }
    });
  }


  public leaveHeldenbogen(): void {
    this.changeNav(0);
  }

  public updateChar(): void {
    this.persistence.storeComponent('auswahlbildschirm', this.localData);
  }

  public reloadEvent(e: StringEvent): void {
    const event: StringEvent = {
      wert: e.wert
    };
    this.reload.emit(event);
  }

  /*
  public isAuthenticated(): boolean {
    return sessionStorage.getItem('auth') === 'true';
  }*/
  /*
   public getCharaktereOfUser(): void {
     const $username = sessionStorage.getItem('user');
     console.log(this.alleCharaktere);
     console.log('getCharaktereOfUser ' + $username);
     this.userCharaktere = [];
     this.alleCharaktere.forEach(char => {
       if (char.spieler === $username) {
         this.userCharaktere.push(char);
       }
     });
   }*/

  /*public setChar(char: Charakter): void {
    sessionStorage.setItem('char', char.name);
    this.changeNav(10);
    this.localData.charakter = char;
    sessionStorage.setItem('nav', '10');
  }*/
}
