export class Gtierangriffenk {
  angriffsart: string;
  at: number;
  schaden: string;
  reichweite: string;

  constructor() {
    this.schaden = '1W6';
    this.reichweite = 'kurz';
  }

  public copy(other: Gtierangriffenk): void {
    this.angriffsart = other.angriffsart;
    this.at = other.at;
    this.schaden = other.schaden;
    this.reichweite = other.reichweite;
  }
}
