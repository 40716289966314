<div class="vertical">
  <div class="body" *ngIf="charakter !== undefined">
    <div class="linkeSpalte">
      <div class="table">
        <div class="header">
          <h3>Steckbrief</h3>
        </div>
        <div class="zeile">
          <div class="name">Name:</div>
          <div class="text">{{charakter.name}}</div>
          <div class="name">Geschlecht:</div>
          <div class="text">{{charakter.geschlecht}}</div>
        </div>
        <div class="zeile">
          <div class="name">Spezies:</div>
          <div class="text">{{charakter.spezies}}</div>
          <div class="name">Alter:</div>
          <div class="text">{{alter}}</div>
        </div>
        <div class="zeile">
          <div class="name">Kultur:</div>
          <div class="text">{{charakter.kultur}}</div>
          <div class="name">Größe:</div>
          <div class="text">{{charakter.groesse}} Halbfinger</div>
        </div>
        <div class="zeile">
          <div class="name">Profession:</div>
          <div class="text">{{charakter.profession}}</div>
          <div class="name">Gewicht:</div>
          <div class="text">{{charakter.gewicht}} Stein</div>
        </div>
        <div class="zeile">
          <div class="name">Haarfarbe:</div>
          <div class="text">{{charakter.haarfarbe}}</div>
          <div class="name">Augenfarbe:</div>
          <div class="text">{{charakter.augenfarbe}}</div>
        </div>
        <div class="zeile">
          <div class="name">Sozialer Stand:</div>
          <div class="text">{{glossar.getSozialstatus(charakter.getWert("Sozialstatus").akt()).name}}</div>
          <div class="name" *ngIf="charakter.titel != null">Titel:</div>
          <div class="text">{{charakter.titel}}</div>
        </div>
      </div>

      <div class="steckbriefHead" *ngIf="!steckbrief" (click)="steckbrief = true">▼ Weitere Infos ▼</div>
      <div class="steckbriefHead" *ngIf="steckbrief" (click)="steckbrief = false">▲ Weniger Infos ▲</div>
      <div *ngIf="steckbrief">
        <div class="steckbrief" *ngFor="let notiz of charakter.getSteckbrief()">
          <b>{{notiz.name.substring(1)}}: </b>{{notiz.text}}
        </div>
        <div class="steckbrief" *ngIf="charakter.getSteckbrief().length === 0">
          Hier werden alle Notizen aus dem Notizen-Reiter angezeigt, deren Name mit # beginnt.
        </div>
      </div>

      <div class="table">
        <div class="header">
          <h3>Vorteile</h3>
        </div>
        <div *ngFor="let vun of vorteile">
          <div class="zeile" *ngIf="vun.name">
            <div class="langername" (click)="setVuNInfo(vun.name)">
              {{vun.name}}
              <label *ngIf="vunInfo.stufen > 1">{{vun.stufe}}</label>
              <label *ngIf="vun.gewaehlteKategorie !== ''">({{vun.gewaehlteKategorie}})</label>
              <label *ngIf="vun.spezifikation !== ''">[{{vun.spezifikation}}]</label>
            </div>
            <div class="vunInfo" *ngIf="vunInfo.name === vun.name">
              <b>Beschreibung</b>
              {{vunInfo.beschreibung}}
              <b>Regel</b>
              {{vunInfo.regel}}
            </div>
          </div>
        </div>
        <div class="header">
          <h3>Nachteile</h3>
        </div>
        <div *ngFor="let vun of nachteile">
          <div class="zeile" *ngIf="vun.name">
            <div class="langername" (click)="setVuNInfo(vun.name)">
              {{vun.name}}
              <label *ngIf="vunInfo.stufen > 1">{{vun.stufe}}</label>
              <label *ngIf="vun.gewaehlteKategorie !== ''">({{vun.gewaehlteKategorie}})</label>
              <label *ngIf="vun.spezifikation !== ''">[{{vun.spezifikation}}]</label>
            </div>
            <div class="vunInfo" *ngIf="vunInfo.name === vun.name">
              <b>Beschreibung</b>
              {{vunInfo.beschreibung}}
              <b>Regel</b>
              {{vunInfo.regel}}
            </div>
          </div>
        </div>
      </div>

      <!--div class="table">
        <div class="header">
          <h3>Nachteile</h3>
        </div>
        <div *ngFor="let vun of charakter.vorUndNachteile">
          <div class="zeile" *ngIf="!vun.vorteil">
            <div class="langername" (click)="setVuNInfo(vun)">
              {{vun.name}}
              <label *ngIf="vun.stufe > 0">{{vun.stufeString}}</label>
              <label *ngIf="vun.kathegorie !== ''">({{vun.kathegorie}})</label>
              <label *ngIf="vun.notiz !== ''">[{{vun.notiz}}]</label>
            </div>
            <div class="vunInfo" *ngIf="vunInfo.name === vun.name">
              <b>Beschreibung</b>
              {{vun.beschreibung}}
              <b>Regel</b>
              {{vun.regel}}
            </div>
          </div>
        </div>
      </div-->

      <div class="table">
        <div class="header">
          <!--h3>Allgemeine Sonderfertigkeiten</h3-->
          <h3>Alle Sonderfertigkeiten</h3>
        </div>
        <div *ngFor="let sf of charakter.sonderfertigkeiten">
          <!--div class="zeile" *ngIf="sf.type === 'AllgemeineSF'">
            <div class="langername" (click)="setSfInfo(sf)">
              {{sf.name}}
              <label *ngIf="sf.stufe > 1">{{getStufenString(sf.stufe)}}</label>
              <label *ngIf="sf.notiz !== ''">[{{sf.notiz}}]</label>
            </div>
            <div class="vunInfo" *ngIf="sfInfo.name === sf.name">
              <b>Beschreibung</b>
              {{sf.beschreibung}}
              <b>Regel</b>
              {{sf.regel}}
            </div>
          </div-->
          <div class="zeile">
            <div class="langername" (click)="setSfInfo(sf.name)">
              {{sf.name}}
              <label *ngIf="sf.stufe > 1">{{getStufenString(sf.stufe)}}</label>
              <label *ngIf="sf.gewaehlteKategorie !== ''">({{sf.gewaehlteKategorie}})</label>
              <label *ngIf="sf.spezifikation !== ''">[{{sf.spezifikation}}]</label>
            </div>
            <!--
            <div class="vunInfo" *ngIf="sfInfo.name === sf.name">
              <b>Beschreibung</b>
              {{sfInfo.beschreibung}}
              <b>Regel</b>
              {{sfInfo.regel}}
            </div>
            -->
            <div class="vunInfo" *ngIf="sf_info != null && sf_info.name === sf.name">
              <b *ngIf="sf_info['beschreibung'] != null">Beschreibung</b>
              <span *ngIf="sf_info['beschreibung'] != null">{{sf_info['beschreibung']}}</span>
              <b *ngIf="sf_info['regel'] != null">Regel</b>
              <span *ngIf="sf_info['regel'] != null">{{sf_info['regel']}}</span>
            </div>
          </div>
        </div>
      </div>



      <!--div class="zustand">
        <div class="zustandName">Verwirrung</div>
        <div class="zustandWert">{{charakter.basiswerte.modifikatoren.verwirrung + charakter.basiswerte.modifikatoren.verwirrungManuell}}</div>
        <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseVerwirrung()"/>
        <div class="zustandMod">{{charakter.basiswerte.modifikatoren.verwirrungManuell}}</div>
        <img src="../../../../assets/images/button_s_pos.png" (click)="increaseVerwirrung()"/>
        <div class="zustandBeschr">{{charakter.basiswerte.modifikatoren.verwirrungBeschr[charakter.basiswerte.modifikatoren.verwirrung + charakter.basiswerte.modifikatoren.verwirrungManuell]}}</div>
      </div>
      <div class="zustand">
        <div class="zustandName">Belastung</div>
        <div class="zustandWert">{{charakter.basiswerte.modifikatoren.belastung + charakter.basiswerte.modifikatoren.belastungManuell}}</div>
        <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseBelastung()"/>
        <div class="zustandMod">{{charakter.basiswerte.modifikatoren.belastungManuell}}</div>
        <img src="../../../../assets/images/button_s_pos.png" (click)="increaseBelastung()"/>
        <div class="zustandBeschr">{{charakter.basiswerte.modifikatoren.belastungBeschr[charakter.basiswerte.modifikatoren.belastung + charakter.basiswerte.modifikatoren.belastungManuell]}}</div>
      </div>
      <div class="zustand">
        <div class="zustandName">Betäubung</div>
        <div class="zustandWert">{{charakter.basiswerte.modifikatoren.betaeubung + charakter.basiswerte.modifikatoren.betaeubungManuell}}</div>
        <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseBetaeubung()"/>
        <div class="zustandMod">{{charakter.basiswerte.modifikatoren.betaeubungManuell}}</div>
        <img src="../../../../assets/images/button_s_pos.png" (click)="increaseBetaeubung()"/>
        <div class="zustandBeschr">{{charakter.basiswerte.modifikatoren.betaeubungBeschr[charakter.basiswerte.modifikatoren.betaeubung + charakter.basiswerte.modifikatoren.betaeubungManuell]}}</div>
      </div>
      <div class="zustand">
        <div class="zustandName">Furcht</div>
        <div class="zustandWert">{{charakter.basiswerte.modifikatoren.furcht + charakter.basiswerte.modifikatoren.furchtManuell}}</div>
        <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseFurcht()"/>
        <div class="zustandMod">{{charakter.basiswerte.modifikatoren.furchtManuell}}</div>
        <img src="../../../../assets/images/button_s_pos.png" (click)="increaseFurcht()"/>
        <div class="zustandBeschr">{{charakter.basiswerte.modifikatoren.furchtBeschr[charakter.basiswerte.modifikatoren.furcht + charakter.basiswerte.modifikatoren.furchtManuell]}}</div>
      </div>
      <div class="zustand">
        <div class="zustandName">Paralyse</div>
        <div class="zustandWert">{{charakter.basiswerte.modifikatoren.paralyse + charakter.basiswerte.modifikatoren.paralyseManuell}}</div>
        <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseParalyse()"/>
        <div class="zustandMod">{{charakter.basiswerte.modifikatoren.paralyseManuell}}</div>
        <img src="../../../../assets/images/button_s_pos.png" (click)="increaseParalyse()"/>
        <div class="zustandBeschr">{{charakter.basiswerte.modifikatoren.paralyseBeschr[charakter.basiswerte.modifikatoren.paralyse + charakter.basiswerte.modifikatoren.paralyseManuell]}}</div>
      </div-->
    </div>

    <div class="rechteSpalte">
      <div class="table">
        <!--div class="row">
          <div class="name">Erfahrungsgrad:</div>
          <div class="text">{{charakter.erfahrung.name}}</div>
        </div-->
        <!--div class="row">
          <div class="name">Start-AP:</div>
          <div class="text">{{charakter.erfahrung.startAP}}</div>
        </div-->
        <!--div class="row">
          <div class="name">AP-Konto:</div>
          <div class="text">{{charakter.getWert('AP').akt()}}</div>
        </div-->
        <!--div class="row">
          <div class="name">AP ausgegeben:</div>
          <div class="text">{{charakter.erfahrung.apLog}}</div>
        </div-->
        <!--div class="row">
          <div class="name">AP hinzufügen:</div>
          <input type="number" [(ngModel)]="apToAdd" placeholder="0" required="true">
          <img src="../../../../assets/images/button_s_pos.png" (click)="addAP()"/>
        </div-->
        <!--div class="portrait" style="background-image: url('assets/images/items/{{charakter.name}}.jpg')"></div-->

        <!--img class="portrait" alt="bild" src="assets/images/charaktere/{{charakter.id}}.jpg"/-->
        <img *ngIf="!editPortrait" class="portrait" alt="bild"  [src]="getSanitizedURL()"/>
        <div *ngIf="!editPortrait" class="right" (click)="editPortrait = true">✎</div>
        <app-fileupload (imageSetter)="setPortrait($event)" *ngIf="editPortrait"></app-fileupload>
        <div *ngIf="editPortrait" class="right" (click)="editPortrait = false">❌</div>
        <div class="row">
          <h3 class="header">Eigenschaften</h3>
        </div>
        <div class="row" *ngFor="let eig of charakter.getAlleWerteDerKategorie('Attribut')">
          <div class="eig">{{eig.name}}: </div>
          <div class="wert">{{eig.akt()}}</div>
          <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseBeeinflussterWert(eig.name)"/>
          <div class="wert">
            <label *ngIf="charakter.getBeeinflWert(eig.name, 'manuell').getNumericWert() > 0">
              +{{charakter.getBeeinflWert(eig.name, 'manuell').getNumericWert()}}
            </label>
            <label *ngIf="charakter.getBeeinflWert(eig.name, 'manuell').getNumericWert() <= 0">
              {{charakter.getBeeinflWert(eig.name, 'manuell').getNumericWert()}}
            </label>
          </div>
          <img src="../../../../assets/images/button_s_pos.png" (click)="increaseBeeinflussterWert(eig.name)"/>
        </div>
      </div>
    </div>
  </div>

  <h3>Zustände</h3>
  <div class="zustand1" style="font-weight: bold">
    <div class="zustandName">Name</div>
    <div class="zustandWert">Stufe</div>
    <div class="img"></div>
    <div class="zustandMod">Mod.</div>
    <div class="img"></div>
    <div class="zustandBeschr">Beschreibung</div>
    <div class="zustandBeschr">Regeltechnisches</div>
  </div>
  <div *ngFor="let zustand of charakter.getAlleNormalenZustaende() index as i">
    <div class="zustand{{i % 2}}" *ngIf="zustandInfoName !== zustand.name">
      <div class="vBox">
        <div class="zustandName" (click)="getZustandInfos(zustand.name)">{{zustand.name}}</div>
      </div>
      <div class="vBox">
        <div class="zustandWert" (click)="getZustandInfos(zustand.name)">{{zustand.akt()}}</div>
      </div>
      <div class="vBox">
        <img src="../../../../assets/images/button_s_neg.png" (click)="decreaseBeeinflussterWert(zustand.name)"/>
      </div>
      <div class="vBox">
        <div class="zustandMod" (click)="getZustandInfos(zustand.name)">{{charakter.getBeeinflWert(zustand.name, 'manuell').getWert()}}</div>
      </div>
      <div class="vBox">
        <img src="../../../../assets/images/button_s_pos.png" (click)="increaseBeeinflussterWert(zustand.name)"/>
      </div>
      <div class="vBox">
        <div class="zustandBeschr" (click)="getZustandInfos(zustand.name)">{{glossar.getZustand(zustand.name).stufen.get(zustand.akt()).beschreibung}}</div>
      </div>
      <div class="vBox" style="color: darkgreen" *ngIf="zustand.akt() < 1">
        <div class="zustandBeschr" (click)="getZustandInfos(zustand.name)">{{glossar.getZustand(zustand.name).stufen.get(zustand.akt()).auswirkungen}}</div>
      </div>
      <div class="vBox" style="color: darkred" *ngIf="zustand.akt() > 0">
        <div class="zustandBeschr" (click)="getZustandInfos(zustand.name)">{{glossar.getZustand(zustand.name).stufen.get(zustand.akt()).auswirkungen}}</div>
      </div>
    </div>
    <div class="zustand{{i % 2}}" *ngIf="zustandInfoName === zustand.name" (click)="getZustandInfos(zustand.name)">
      <div class="vBox">
        <div class="zustandName">{{zustand.name}}</div>
      </div>
      <div class="vBox">
        <div class="beschreibung">{{zustandInfo.beschreibung}}</div>
      </div>
    </div>
  </div>

</div>
