export class Gtraditionsartefakt {
  stufe: number;
  volumen: number;
  aspKosten: string;
  merkmal: string;

  public copy(other: Gtraditionsartefakt): void {
    this.stufe = other.stufe;
    this.volumen = other.volumen;
    this.aspKosten = other.aspKosten;
    this.merkmal = other.merkmal;
  }
}
