<div *ngIf="charakter !== undefined">
  <div class="titel">
    <img src="../../../assets/images/bar_unten.png"/>
    <div class="textBox">
      <h1>Heldendokument</h1>
      <h2>{{ titel$ }}</h2>
      <div class="zeile">
        <h3 style="cursor: pointer" (click)="verringereDatumIndex()">←</h3>
        <h3 class="datum">
          {{datum[datumIndex]}}
        </h3>
        <h3 style="cursor: pointer" (click)="erhoheDatumIndex()">→</h3>
      </div>
      <label *ngIf="datumIndex === 0"> (Zwölfgötter)</label>
      <label *ngIf="datumIndex === 1"> (throwalsch)</label>
      <label *ngIf="datumIndex === 2"> (zwergisch)</label>
      <label *ngIf="datumIndex === 3"> (irdisch)</label>
    </div>
  </div>

  <div class="eigenschaften" *ngIf="titel$ !== 'Entwicklung'">
    <div class="attribut" *ngFor="let attribut of charakter.getAlleWerteDerKategorie('Attribut')">
      <div class="name">{{ attribut.name }}</div>
      <div class="wert">{{ attribut.akt() }}</div>
    </div>
  </div>

  <div class="basiswerte" *ngIf="charakter.werte != null && titel$ !== 'Entwicklung'">
    <div class="attributbreit" *ngIf="titel$ !== 'Ausrüstung'">
      <div class="name">LeP-Aktuell</div>
      <div class="flexHorizontal">
        <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseWert('LeP')"/>
        <div class="wert">{{charakter.getWert('LeP').akt()}}</div>
        <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseWert('LeP')"/>
      </div>
    </div>
    <div class="attribut" *ngIf="titel$ !== 'Ausrüstung'">
      <div class="name">LeP</div>
      <div class="wert">{{charakter.getWert('LeP').wert}}</div>
    </div>
    <div class="attribut" *ngIf="titel$ !== 'Ausrüstung'">
      <div class="name">GS</div>
      <div class="wert">{{charakter.getWert('GS').wert - charakter.getWert('GS').abzug}}</div>
    </div>
    <!-- Kampf -->
    <div class="attribut" *ngIf="titel$ === 'Kampf'">
      <div class="name">AW</div>
      <div class="wert">{{((charakter.getWert('AW').akt())) | number: '1.0-0'}}</div>
    </div>
    <div class="attribut" *ngIf="titel$ === 'Kampf'">
      <div class="name">INI</div>
      <div class="wert">{{charakter.getWert('INI').akt()}} </div>
    </div>
    <div class="attribut" *ngIf="titel$ === 'Kampf'">
      <div class="name">SK</div>
      <div class="wert">{{charakter.getWert('SK').akt()}}</div>
    </div>
    <div class="attribut" *ngIf="titel$ === 'Kampf'">
      <div class="name">ZK</div>
      <div class="wert">{{charakter.getWert('ZK').akt()}}</div>
    </div>
    <!-- Zauber -->
    <div class="attributbreit" *ngIf="titel$ === 'Zauber'">
      <div class="name">AsP-Aktuell</div>
      <div class="flexHorizontal">
        <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseWert('AsP')"/>
        <div class="wert">{{charakter.getWert('AsP').akt()}}</div>
        <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseWert('AsP')"/>
      </div>
    </div>
    <div class="attributbreit" *ngIf="titel$ === 'Zauber'">
      <div class="name">AsP-Maximal</div>
      <div class="wert">{{charakter.getWert('AsP').wert}}</div>
    </div>

    <!-- Trinkfestigkeit -->
    <div class="attributbreit" *ngIf="titel$ === 'Allgemeine Informationen' || titel$ === 'Talente' || titel$ === 'Proben'">
      <div class="name">Trunkenheit</div>
      <div class="flexHorizontal">
        <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreasePromille('Trink-AuP')"/>
        <div class="wert">{{(charakter.getWert('Trink-AuP').wert - charakter.getCurrentWert('Trink-AuP')) * (1 / 8) | number:'1.0-1'}}‰</div>
        <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increasePromille('Trink-AuP')"/>
      </div>
    </div>
    <!-- Erschöpfung -->
    <div class="attributbreit" *ngIf="titel$ === 'Allgemeine Informationen' || titel$ === 'Talente' || titel$ === 'Proben'">
      <div class="name">Erschöpfung</div>
      <div class="flexHorizontal">
        <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseWert('Erschöpfung')"/>
        <div class="wert" *ngIf="charakter.getWert('Erschöpfung').akt() <= charakter.getWert('KO').akt()">{{charakter.getWert('Erschöpfung').akt()}}</div>
        <div class="wert" style="color: darkred" *ngIf="charakter.getWert('Erschöpfung').akt() > charakter.getWert('KO').akt()">{{charakter.getWert('Erschöpfung').akt()}}</div>
        <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseWert('Erschöpfung')"/>
      </div>
    </div>
    <!--div class="attribut" *ngIf="titel$ === 'Allgemeine Informationen' || titel$ === 'Talente'">
      <div class="name">T-AuP</div>
      <div class="wert">{{charakter.getWert('Trink-AuP').wert}}</div>
    </div>
    <div class="attribut" *ngIf="titel$ === 'Allgemeine Informationen' || titel$ === 'Talente'">
      <div class="bild"></div>
    </div-->
  </div>

<!-- Ausrüsrtung -->
  <div class="basiswerte" *ngIf="titel$ === 'Ausrüstung'">
    <div class="attributbreit" *ngFor="let muenze of muenzenNamen index as i">
      <div class="horizonzal">
        <img class="waehrung" src="../../../../assets/images/items/{{muenze}}.png"/>
        <div class="b6">
          <div class="waehrungname">{{muenze}}</div>
          <div class="waehrungwert">{{muenzenAnzahl[i]}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--div class="popup">
  <h3>Übrige Talentpunkte in Zechen</h3>
  <div class="buttons">
    <div class="buttonRound" *ngFor="let tap of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">{{tap}}</div>
  </div>

</div-->
