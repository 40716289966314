import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {formatDate} from '@angular/common';
import {min} from 'rxjs/operators';
import {BeeinflussteWerte} from '../../_classes/charakter/beeinflusste-werte';
import {Kombitalent} from '../../_classes/kombitalent';
import {Gtalent} from '../../_classes/glossar/gtalent';
import {SteigerungstabelleService} from '../../_services/steigerungstabelle.service';
import {Werte} from '../../_classes/charakter/werte';

@Component({
  selector: 'app-entwicklung',
  templateUrl: './entwicklung.component.html',
  styleUrls: ['./entwicklung.component.sass']
})
export class EntwicklungComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  navBar: string[] = ['Abenteuerpunkte', 'Steigerung von Fertigkeiten', 'Steigerung von Eigenschaften',
    'Erlernen von Sonderfertigkeiten'];

  nav = 0;
  unterNav = 'Talent';
  apHinzuGrund: string;
  apHinzuWert = 0;

  apGeschichte: BeeinflussteWerte[] = [];

  charakterStufe = 0;

  stufen: number[] = [0, 1, 2, 3, 4, 5, 6];
  stufenNamen: string[] = ['unerfahren', 'durchschnittlich', 'erfahren', 'kompetent',
    'meisterlich', 'brillant', 'legendär'];
  stufenAPGrenze: number[] = [900, 1000, 1100, 1200, 1400, 1700, 2100];
  stufenEigGrenze: number[] = [12, 13, 14, 15, 16, 17, 18];
  stufenFertGrenze: number[] = [10, 10, 10, 13, 16, 19, 20];
  maxWert = 0;

  stufenKampfGrenze: number[] = [8, 10, 12, 14, 16, 18, 20];
  stufenMaxEigPkte: number[] = [95, 98, 100, 102, 105, 109, 114];
  stufenMaxZaubOderLiturg: number[] = [8, 10, 12, 14, 16, 18, 20];
  stufenMaxFremdZoL: number[] = [0, 1, 2, 3, 4, 5, 6];

  /** Steigerungen */
  quittung: BeeinflussteWerte[] = [];
  steigerungen: Werte[] = [];
  steigerung = 0;
  kosten = 0;
  gesamtKosten = 0;

  /** Fertigkeiten */
  wahlIndex = 0;

  /** Talente */
  werte: Werte[] = [];


  constructor(private steigerungstabelle: SteigerungstabelleService) { }

  ngOnInit(): void {
    this.getAPGeschichte();
    this.ermittleStufe();
    this.ladeWerte();
  }

  getAPGeschichte(): void {
    this.apGeschichte = this.charakter.getAlleBeeinflWerte('AP');
    this.apGeschichte.sort((a, b) =>
      (Number(a.modName.split('#')[0])) < Number(b.modName.split('#')[0]) ? -1 : 1);
    this.apGeschichte.forEach(eintrag => {
      eintrag.modName = eintrag.modName.replace('🠖', '→');
    });
  }

  fuegeAPhinzu(): void {
    if (this.apHinzuWert !== 0) {
      if (this.apHinzuGrund == null || this.apHinzuGrund === '') {
        const date = new Date();
        this.apHinzuGrund = date.toLocaleDateString() + ' - ' + date.toLocaleTimeString();
      }
      const index = this.getAktuellenAPIndex();
      this.charakter.setBeeinflWert('AP', index + '#' + this.apHinzuGrund, String(this.apHinzuWert));
      this.getAPGeschichte();
      this.ermittleStufe();
      this.apHinzuGrund = null;
      this.apHinzuWert = 0;
    }
  }

  getAktuellenAPIndex(): number {
    const letzterEintrag = this.apGeschichte[this.apGeschichte.length - 1];
    let index = 0;
    if (this.apGeschichte.length > 0) {
      index = Number(letzterEintrag.modName.split('#')[0]) + 1;
    }
    return index;
  }

  ermittleStufe(): void {
    let summe = 0;
    this.charakter.getAlleBeeinflWerte('AP').forEach(bw => {
      if (Number(bw.modWert) > 0) {
        summe += Number(bw.modWert);
      }
    });
    this.stufenAPGrenze.forEach(grenze => {
      if (summe >= grenze) {
        this.charakterStufe = this.stufen[this.stufenAPGrenze.indexOf(grenze)];
      }
    });
  }

  ladeWerte(): void {
    console.log('ladeWerte');
    this.werte = [];
    this.wahlIndex = 0;
    console.log(this.unterNav);
    console.log(this.charakter.werte);
    this.charakter.getWerteDerKategorie(this.unterNav).forEach(wert => {
      this.werte.push(wert);
    });
    console.log(this.werte);
    this.werte.sort((a, b) => a.name < b.name ? -1 : 1);
    this.resetWert();
  }

  erhoeheWertSteigerung(): void {
    const wert = this.werte[this.wahlIndex];
    // if (wert.wert + this.steigerung < this.getGrenze()) {
    if (wert.wert + this.steigerung < this.maxWert) {
      this.steigerung++;
    }
    const stg = this.getSteigerungsSpalte(wert.name);
    this.berechneKosten(wert.wert, stg);
  }
  verringereWertSteigerung(): void {
    const wert = this.werte[this.wahlIndex];
    if (this.steigerung > 0) {
      this.steigerung--;
    }
    const stg = this.getSteigerungsSpalte(wert.name);
    this.berechneKosten(wert.wert, stg);
  }

  berechneKosten(aktuellerWert: number, stg: string): void {
    const spalten: string[] = ['A', 'B', 'C', 'D', 'E'];
    const spalte = spalten.indexOf(stg);
    this.kosten = 0;
    for (let i = aktuellerWert + 1; i <= aktuellerWert + this.steigerung; i++) {
      this.kosten += this.steigerungstabelle.getKosten(i, spalte);
    }
  }

  getGrenze(): number {
    let grenze = 0;
    if (this.unterNav === 'Talent') {
      grenze = this.stufenFertGrenze[this.charakterStufe];
    } else if (this.unterNav === 'Kampftechnik') {
      grenze = this.stufenKampfGrenze[this.charakterStufe];
    }
    return grenze;
  }

  getSteigerungsSpalte(name: string): string {
    let stg = '';
    if (this.unterNav === 'Talent') {
      this.glossar.talente.forEach(t => {
        if (t.name === name) {
          stg = t.steigerungskosten;
        }
      });
    } else if (this.unterNav === 'Kampftechnik') {
      this.glossar.kampftechniken.forEach(k => {
        if (k.name === name) {
          stg = k.steigerung;
        }
      });
    } else {
      stg = 'E';
    }
    return stg;
  }

  fuegeSteigerungHinzu(name: string, aktuellerWert: number): void {
    if (this.steigerung > 0) {
      let guard = true;
      this.quittung.forEach(etr => {
        if (etr.modName.includes(name)) {
          guard = false;
        }
      });
      if (guard) {
        const eintrag: BeeinflussteWerte = new BeeinflussteWerte();
        eintrag.modifiziertes = 'AP';
        eintrag.kr = 0;
        eintrag.modName = name + ' ' + aktuellerWert + ' 🠖 ' + (aktuellerWert + this.steigerung);
        eintrag.modWert = String(-this.kosten);
        this.quittung.push(eintrag);
        const steigerungsEintrag: Werte = new Werte();
        steigerungsEintrag.name = name;
        steigerungsEintrag.wert = this.steigerung;
        this.steigerungen.push(steigerungsEintrag);
        this.berechneGesamtKosten();
        this.steigerung = 0;
        this.kosten = 0;
      }

    }
  }

  entferneSteigerung(name: string): void {
    const temp: BeeinflussteWerte[] = [];
    const temp2: Werte[] = [];
    this.quittung.forEach(eintrag => {
      if (eintrag.modName !== name) {
        temp.push(eintrag);
      }
    });
    this.quittung = [];
    temp.forEach(e => {
      this.quittung.push(e);
    });

    this.steigerungen.forEach(eintrag => {
      if (eintrag.name !== name) {
        temp2.push(eintrag);
      }
    });
    this.steigerungen = [];
    temp2.forEach(e => {
      this.steigerungen.push(e);
    });
    this.berechneGesamtKosten();
  }

  berechneGesamtKosten(): void {
    this.gesamtKosten = 0;
    this.quittung.forEach(eintrag => {
      this.gesamtKosten += Number(eintrag.modWert);
    });
  }

  steigerungDurchfuehren(): void {
    let index = this.getAktuellenAPIndex();
    this.quittung.forEach(eintrag => {
      const name = index + '#' + eintrag.modName;
      this.charakter.setBeeinflWert(eintrag.modifiziertes, name, eintrag.modWert);
      index ++;
    });
    this.quittung = [];
    this.steigerungen.forEach(stg => {
      this.charakter.steigereWert(stg);
    });
    this.steigerungen = [];
    this.steigerung = 0;
    this.kosten = 0;
    this.getAPGeschichte();
    console.log(this.charakter.beeinflussteWerte);
  }



  resetWert(): void {
    this.steigerung = 0;
    this.kosten = 0;
    this.getMaxert();
  }

  getMaxert(): void {
    const name = this.werte[this.wahlIndex].name;
    if (this.unterNav === 'Talent') {
      this.glossar.talente.forEach(t => {
        if (t.name === name) {
          console.log(t);
          let max = 0;
          t.probe.forEach(attr => {
            max = Math.max(this.charakter.getWert(attr).wert);
          });
          this.maxWert = max + 2;
        }
      });
    } else if (this.unterNav === 'Kampftechnik') {
      this.glossar.kampftechniken.forEach(k => {
        if (k.name === name) {
          console.log(k);
          const leList = k.leiteigenschaften.split('/');
          let max = 0;
          leList.forEach(le => {
            max = Math.max(this.charakter.getWert(le).wert);
          });
          this.maxWert = max + 2;
        }
      });
    } else {
      this.maxWert = 25;
    }
  }

  public changeNav(button: string): void {
    this.nav = this.navBar.indexOf(button);
    if (button === 'Steigerung von Eigenschaften') {
      this.unterNav = 'Attribut';
    } else if (button === 'Steigerung von Fertigkeiten') {
      this.unterNav = 'Talent';
    }
    this.ladeWerte();
  }
}
