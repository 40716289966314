
  <h3>Anpassen der Eigenschaften</h3>
  <div class="steigerungsZeile" *ngFor="let eigenschaft of heldin.eigenschaften">
    <img class="steigerungEigenschaftImage" src="../../../assets/images/button_s_neg.png" (click)="decreaseEigenschaft(eigenschaft.name)"/>
    <div class="steigerungEigenschaftName">{{ eigenschaft.name }}</div>
    <div class="steigerungEigenschaftWert">{{ eigenschaft.startwert }}</div>
    <div class="steigerungEigenschaftModPos" *ngIf="eigenschaft.modifikator > 0">+ {{ eigenschaft.modifikator }}</div>
    <div class="steigerungEigenschaftModNeg" *ngIf="eigenschaft.modifikator === 0"></div>
    <div class="steigerungEigenschaftModNeg" *ngIf="eigenschaft.modifikator < 0"> {{ eigenschaft.modifikator }}</div>
    <img class="steigerungEigenschaftImage" src="../../../assets/images/button_s_pos.png" (click)="increaseEigenschaft(eigenschaft.name)"/>
  </div>
  <div>
    Maximaler Eigenschaftswert: {{ maxWert }}
  </div>
  <div>
    Eigenschaftspunkte verteilt: {{ $eigPkte }} / {{ maxPunkte }}
  </div>
  <h3>Wähle durch deine Spezies modifizierte Eigenschaften</h3>
  <div class="flexCol" *ngFor="let mod of heldin.eigenschaftenWahlAusSpezies index as i">
    <div class="row8">
      <input class="radio" type="radio" name="mod" value="{{ mod }}" [checked]="heldin.eigenschaftenWahlAusSpeziesGewaehlt[i]" (click)="changeMod(mod)">
      <div class="attr">
        {{ mod.split(': ')[0] }}:
      </div>
      <div class="wert">
        <label *ngIf="mod.split(': ')[1] > 0">+</label>
        {{ mod.split(': ')[1] }}
      </div>
    </div>
  </div>
  <h3>---</h3>
  <!--div *ngFor="let mods of heldin.spezies.eigenschaftsAenderungen">
    <div *ngIf="mods.includes('||')">
      <div class="flexCol" *ngFor="let mod of mods.split(' || ')">
        <div class="row8">
          <input class="radio" type="radio" name="mod" value="{{ mod }}" checked (click)="changeMod(mod)">
          <div class="attr">
            {{ mod.split(': ')[0] }}:
          </div>
          <div class="wert">
            <label *ngIf="mod.split(': ')[1] > 0">+</label>
            {{ mod.split(': ')[1] }}
          </div>
        </div>
      </div>
    </div>
  </div-->


