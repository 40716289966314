export class Gtierwissen {
  talent: string;
  anwendung: string;
  qs: number;
  wissen: string;

  public copy(other: Gtierwissen): void {
    this.talent = other.talent;
    this.anwendung = other.anwendung;
    this.qs = other.qs;
    this.wissen = other.wissen;
  }
}
