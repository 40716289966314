
import {Gtalentanwendung} from './gtalentanwendung';
import {Geinsatzmoeglichkeit} from './geinsatzmoeglichkeit';

export class Gtalent {
  name: string;
  talentArt: string;
  probe: string[];
  beschreibung: string;
  belastung: string;
  qualitaet: string;
  misslungeneProbe: string;
  kritischerErfolg: string;
  patzer: string;
  steigerungskosten: string;
  werkzeug: string;
  einsatzmoeglichkeiten: Geinsatzmoeglichkeit[];
  anwendungsgebiete: Gtalentanwendung[];

  public copy(other: Gtalent): void {
    console.log('copy ...');
    this.name = other.name;
    this.talentArt = other.talentArt;
    this.probe = other.probe;
    this.beschreibung = other.beschreibung;
    this.belastung = other.belastung;
    this.qualitaet = other.qualitaet;
    this.misslungeneProbe = other.misslungeneProbe;
    this.kritischerErfolg = other.kritischerErfolg;
    this.patzer = other.patzer;
    this.steigerungskosten = other.steigerungskosten;
    this.werkzeug = other.werkzeug;
    this.einsatzmoeglichkeiten = other.einsatzmoeglichkeiten;
    this.anwendungsgebiete = other.anwendungsgebiete;
  }
}
