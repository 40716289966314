<h3>Steigerungen vornehmen</h3>
<h3>Talente</h3>
<div class="table">
  <div class="row" style="font-weight: bold">
    <div class="name" style="margin-top: 8px">Körperliche Talente</div>
  </div>
  <div class="row" style="font-weight: bold">
    <div class="name">Name</div>
    <div class="probe">Probe</div>
    <div class="fwMod">-</div>
    <div class="fw">FW</div>
    <div class="fwMod">+</div>
    <div class="stg">Stg.</div>
  </div>
  <div *ngFor="let talent of heldin.talentwerte index as i">
    <div class="row" *ngIf="talent.art==='körperlich'">
      <div class="name" (click)="getTalentInfo(talent)">{{talent.name}}</div>
      <div class="probe">{{talent.probe}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseTalent(talent)"/>
      <div class="fw">{{talent.maxFW + heldin.talentwerteAusProfession[i].maxFW}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseTalent(talent)"/>
      <div class="stg">{{talent.stg}}</div>
      <div class="info" *ngIf="$currentTalentInfo.name === talent.name">
        <b>Beschreibung</b>
        {{$currentTalentInfo.beschreibung}}
        <b>Auswirkungen guter Proben</b>
        {{$currentTalentInfo.qualitaet}}
        <b>Kritischer Erfolg</b>
        {{$currentTalentInfo.kritischerErfolg}}
        <b>Auswirkungen misslungener Proben</b>
        {{$currentTalentInfo.misslungeneProbe}}
        <b>Patzer</b>
        {{$currentTalentInfo.patzer}}
        <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
        <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
        <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
        <label *ngFor="let anw of $currentTalentInfo.anwendungsgebiete">{{anw}}</label>
      </div>
    </div>
  </div>

  <div class="row" style="font-weight: bold">
    <div class="name" style="margin-top: 8px">Natur-Talente</div>
    <div class="probe"></div>
    <div class="fwMod"></div>
    <div class="fw"></div>
    <div class="fwMod"></div>
    <div class="stg"></div>
  </div>
  <div *ngFor="let talent of heldin.talentwerte">
    <div class="row" *ngIf="talent.art==='natur'">
      <div class="name" (click)="getTalentInfo(talent)">{{talent.name}}</div>
      <div class="probe">{{talent.probe}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseTalent(talent)"/>
      <div class="fw">{{talent.maxFW}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseTalent(talent)"/>
      <div class="stg">{{talent.stg}}</div>
      <div class="info" *ngIf="$currentTalentInfo.name === talent.name">
        <b>Beschreibung</b>
        {{$currentTalentInfo.beschreibung}}
        <b>Auswirkungen guter Proben</b>
        {{$currentTalentInfo.qualitaet}}
        <b>Kritischer Erfolg</b>
        {{$currentTalentInfo.kritischerErfolg}}
        <b>Auswirkungen misslungener Proben</b>
        {{$currentTalentInfo.misslungeneProbe}}
        <b>Patzer</b>
        {{$currentTalentInfo.patzer}}
        <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
        <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
        <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
        <label *ngFor="let anw of $currentTalentInfo.anwendungsgebiete">{{anw}}</label>
      </div>
    </div>
  </div>

  <div class="row" style="font-weight: bold">
    <div class="name" style="margin-top: 8px">Gesellschaftliche Talente</div>
    <div class="probe"></div>
    <div class="fwMod"></div>
    <div class="fw"></div>
    <div class="fwMod"></div>
    <div class="stg"></div>
  </div>
  <div *ngFor="let talent of heldin.talentwerte">
    <div class="row" *ngIf="talent.art==='gesellschaftlich'">
      <div class="name" (click)="getTalentInfo(talent)">{{talent.name}}</div>
      <div class="probe">{{talent.probe}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseTalent(talent)"/>
      <div class="fw">{{talent.maxFW}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseTalent(talent)"/>
      <div class="stg">{{talent.stg}}</div>
      <div class="info" *ngIf="$currentTalentInfo.name === talent.name">
        <b>Beschreibung</b>
        {{$currentTalentInfo.beschreibung}}
        <b>Auswirkungen guter Proben</b>
        {{$currentTalentInfo.qualitaet}}
        <b>Kritischer Erfolg</b>
        {{$currentTalentInfo.kritischerErfolg}}
        <b>Auswirkungen misslungener Proben</b>
        {{$currentTalentInfo.misslungeneProbe}}
        <b>Patzer</b>
        {{$currentTalentInfo.patzer}}
        <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
        <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
        <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
        <label *ngFor="let anw of $currentTalentInfo.anwendungsgebiete">{{anw}}</label>
      </div>
    </div>
  </div>


  <div class="row" style="font-weight: bold">
    <div class="name" style="margin-top: 8px">Wissens-Talente</div>
    <div class="probe"></div>
    <div class="fwMod"></div>
    <div class="fw"></div>
    <div class="fwMod"></div>
    <div class="stg"></div>
  </div>
  <div *ngFor="let talent of heldin.talentwerte">
    <div class="row" *ngIf="talent.art==='wissen'">
      <div class="name" (click)="getTalentInfo(talent)">{{talent.name}}</div>
      <div class="probe">{{talent.probe}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseTalent(talent)"/>
      <div class="fw">{{talent.maxFW}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseTalent(talent)"/>
      <div class="stg">{{talent.stg}}</div>
      <div class="info" *ngIf="$currentTalentInfo.name === talent.name">
        <b>Beschreibung</b>
        {{$currentTalentInfo.beschreibung}}
        <b>Auswirkungen guter Proben</b>
        {{$currentTalentInfo.qualitaet}}
        <b>Kritischer Erfolg</b>
        {{$currentTalentInfo.kritischerErfolg}}
        <b>Auswirkungen misslungener Proben</b>
        {{$currentTalentInfo.misslungeneProbe}}
        <b>Patzer</b>
        {{$currentTalentInfo.patzer}}
        <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
        <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
        <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
        <label *ngFor="let anw of $currentTalentInfo.anwendungsgebiete">{{anw}}</label>
      </div>
    </div>
  </div>

  <div class="row" style="font-weight: bold">
    <div class="name" style="margin-top: 8px">Handwerkliche Talente</div>
    <div class="probe"></div>
    <div class="fwMod"></div>
    <div class="fw"></div>
    <div class="fwMod"></div>
    <div class="stg"></div>
  </div>
  <div *ngFor="let talent of heldin.talentwerte">
    <div class="row" *ngIf="talent.art==='handwerk'">
      <div class="name" (click)="getTalentInfo(talent)">{{talent.name}}</div>
      <div class="probe">{{talent.probe}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseTalent(talent)"/>
      <div class="fw">{{talent.maxFW}}</div>
      <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseTalent(talent)"/>
      <div class="stg">{{talent.stg}}</div>
      <div class="info" *ngIf="$currentTalentInfo.name === talent.name">
        <b>Beschreibung</b>
        {{$currentTalentInfo.beschreibung}}
        <b>Auswirkungen guter Proben</b>
        {{$currentTalentInfo.qualitaet}}
        <b>Kritischer Erfolg</b>
        {{$currentTalentInfo.kritischerErfolg}}
        <b>Auswirkungen misslungener Proben</b>
        {{$currentTalentInfo.misslungeneProbe}}
        <b>Patzer</b>
        {{$currentTalentInfo.patzer}}
        <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
        <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
        <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
        <label *ngFor="let anw of $currentTalentInfo.anwendungsgebiete">{{anw}}</label>
      </div>
    </div>
  </div>
</div>
<h3>Kampftechniken</h3>
<div *ngIf="(heldin.$optionaleKtListe.length - -heldin.$optionaleKtListeAktiv.length )> 0">
  <div *ngIf="heldin.$optionaleKtAnz > 0">
    <b>{{heldin.$optionaleKtAnz}} wählbare Kampftechnik<label *ngIf="heldin.$optionaleKtAnz > 1">en</label> aus der Profession</b>
    <div *ngFor="let kt of heldin.$optionaleKtListe">
      <label (click)="chooseOptionaleKampftechnik(kt)">{{kt}}</label>
    </div>
  </div>
  <b>gewählte Kampftechniken aus der Profession</b>
  <div *ngFor="let kt of heldin.$optionaleKtListeAktiv">
    <label (click)="unchooseOptionaleKampftechnik(kt)">{{kt}}</label>
  </div>
</div>
<div class="table">
  <div class="row" style="font-weight: bold">
    <div class="name">Name</div>
    <div class="probe">AT / PA</div>
    <div class="fwMod">-</div>
    <div class="fw">FW</div>
    <div class="fwMod">+</div>
    <div class="stg">Stg.</div>
  </div>
  <div class="row" *ngFor="let kampftechnik of heldin.kampftechniken index as i">
    <div class="name" (click)="getKampftechnikInfo(kampftechnik)">{{kampftechnik.name}}</div>
    <div class="probe">{{kampftechnik.atWert}}<label *ngIf="kampftechnik.nahkampf"> / {{kampftechnik.paWert}}</label></div>
    <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseKampffertigkeit(kampftechnik)"/>
    <div class="fw">{{kampftechnik.wert + kampftechnik.startwert + heldin.kampftechnikenAusProfession[i].wert}}</div>
    <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseKampffertigkeit(kampftechnik)"/>
    <div class="stg">{{kampftechnik.steigerungsfaktor}}</div>
    <div class="info" *ngIf="$currentKampftechnikInfo.name === kampftechnik.name">
      <b>Beschreibung</b>
      {{$currentKampftechnikInfo.beschreibung}}
      <div *ngIf="kampftechnik.besonderheiten !== ''">
        <b>Besonderheiten</b>
        {{$currentKampftechnikInfo.besonderheiten}}
      </div>
      <b>Leiteigenschaften</b>
      {{$currentKampftechnikInfo.leiteigenschaften}}
    </div>
  </div>
</div>
