import { Injectable } from '@angular/core';
// import {Kaempferin} from '../_interfaces/kaempferin';
import {Waffe} from '../_interfaces/waffe';
import {Kaempferin} from '../_classes/kampf/kaempferin';

@Injectable({
  providedIn: 'root'
})
export class ModifikatorenService {

  constructor() { }

  public getAt(kaempfer: Kaempferin, waffe: Waffe): number {
    let wert = waffe.at;
    wert -= waffe.atMod;
    wert -= kaempfer.betaeubung;
    wert -= kaempfer.schmerz;
    wert -= kaempfer.belastung;
    wert -= kaempfer.furcht;
    wert -= kaempfer.paralyse;
    return wert;
  }

  public getPa(kaempfer: Kaempferin, waffe: Waffe): number {
    let wert = waffe.pa;
    wert -= waffe.paMod;
    wert -= kaempfer.betaeubung;
    wert -= kaempfer.schmerz;
    wert -= kaempfer.belastung;
    wert -= kaempfer.furcht;
    wert -= kaempfer.paralyse;
    wert -= 3 * kaempfer.verteidigungenBisher;
    return wert;
  }

  public getAw(kaempfer: Kaempferin): number {
    let wert = kaempfer.aw;
    wert -= kaempfer.betaeubung;
    wert -= kaempfer.schmerz;
    wert -= kaempfer.belastung;
    wert -= kaempfer.furcht;
    wert -= kaempfer.paralyse;
    wert -= 3 * kaempfer.verteidigungenBisher;
    return wert;
  }

}
