import {GrpKombiGegenstand} from '../grp-kombi-gegenstand';

export class Ausruestung {
  itemid: number;
  anzahl = 1;
  zustand = 0.0;
  notiz = '';
  ausgeruestet = false;
  name: string;
  trageort = 'Körper';
  charItemId: number;
  mengenItem: boolean;

  public copy(other: Ausruestung): void {
    this.itemid = other.itemid;
    this.anzahl = other.anzahl;
    this.zustand = other.zustand;
    this.notiz = other.notiz;
    this.ausgeruestet = other.ausgeruestet;
    this.name = other.name;
    this.trageort = other.trageort;
    this.charItemId = other.charItemId;
    this.mengenItem = other.mengenItem;
  }

  public copyGrpItem(id: number, other: GrpKombiGegenstand): void {
    this.itemid = other.gegenstand.id;
    this.anzahl = other.anzahl;
    this.zustand = other.zustand;
    this.notiz = other.notiz;
    this.ausgeruestet = false;
    this.name = other.name;
    this.trageort = other.trageort;
    this.charItemId = id;
    this.mengenItem = other.mengenItem;
  }
}
