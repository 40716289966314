import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ObjectUnsubscribedError, Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import { Talentwert } from '../_interfaces/talentwert';
import {Eigenschaft} from '../_interfaces/eigenschaft';
import {Talent} from '../_interfaces/talent';
import {Talentanwendung} from '../_interfaces/talentanwendung';
import {User} from '../_interfaces/user';
import {VorUndNachteil} from '../_interfaces/vor-und-nachteil';
import {Spezies} from '../_interfaces/spezies';
import {Erschaffungsattribut} from '../_interfaces/erschaffungsattribut';
import {Kultur} from '../_interfaces/kultur';
import {Profession} from '../_interfaces/profession';
import {CharakterVuN} from '../_interfaces/charakter-vu-n';
import {Zauber} from '../_interfaces/zauber';
import {Zaubertrick} from '../_interfaces/zaubertrick';
import {Step2} from '../_interfaces/charaktererstellung/step2';
import {Sonderfertigkeit} from '../_interfaces/sonderfertigkeit';
import {Kampftechnik} from '../_interfaces/kampftechnik';
import {Charakter} from '../_interfaces/charakter';
import {Basiswerte} from '../_interfaces/basiswerte';
import {Heldenerschaffung} from '../_interfaces/charaktererstellung/heldenerschaffung';
import {Armor} from '../_interfaces/armor';
import {Item} from '../_interfaces/item';
import {Waffe} from '../_classes/kampf/waffe';
import {TestItemA} from '../_classes/ausruestung/test-item-a';
import {Kaempferin} from '../_classes/kampf/kaempferin';
import {Benutzer} from '../_classes/benutzer/benutzer';
import {Heldin} from '../_classes/charakter/heldin';
import {Glossar} from '../_classes/glossar/glossar';
import {Gegenstand} from '../_classes/ausruestung/gegenstand';
import {Gruppenmitglied} from '../_classes/abenteuer/gruppenmitglied';
import {IpService} from './ip.service';
import {Gsonderfertigkeit} from '../_classes/glossar/gsonderfertigkeit';
import {Gtier} from '../_classes/glossar/gtier';
import {NameWert} from '../_classes/name-wert';
import {Schriftstueck} from "../_classes/abenteuer/schriftstueck";
// import {Waffe} from '../_interfaces/waffe';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private serverUrl;
  private ip;
  // 77.21.118.71
  // 192.168.178.20
  // 77.21.86.74
  // 77.21.21.48
  // private ip = '77.21.86.74';
  // private ip = '192.168.178.32'; // Mays IP
  // private ip = '192.168.178.44'; // meine IP
  // private ip = '192.168.137.1';
  // private ip = '192.168.178.47'; // LynnArne WLAN IP
  // private ip = '192.168.0.99';
  // private ip = '192.168.178.20'; // Mays IP Desktop
  // 77.21.86.74

  private token: any;

  constructor(private http: HttpClient,
              private ipService: IpService) {
    this.ip = ipService.getIp();
    this.getServerURL();
  }

  private getServerAdress(): string {
    /*let storedIP = sessionStorage.getItem('ip');
    if (storedIP == null) {
      storedIP = this.ip;
    }
    const url = 'http://' + storedIP + ':8080';*/
    const url = 'http://' + this.ip + ':8080';
    /*let url: string;
    if (this.serverUrl === undefined) {
      url = 'http://' + storedIP + ':8080';
    } else {
      url = this.serverUrl;
    }*/
    // console.log(url);
    return url;
  }

  private getServerURL(): void {
    // console.log('getServerURL');
    let ipAdress: string;

    const promise = this.http.get('http://api.ipify.org/?format=json').toPromise();
    // console.log(promise);
    promise.then((data) => {
      // @ts-ignore
      ipAdress = data.ip;
      // console.log(ipAdress);
      this.serverUrl = 'http://' + this.ip + ':8080';
      /*if (ipAdress === this.ip) {
        // this.serverUrl = 'http://192.168.178.44:8080'; // meine IP
        this.serverUrl = 'http://192.168.137.1:8080';
        // this.serverUrl = 'http://192.168.178.47:8080'; // LynnArne WLAN IP
        // this.serverUrl = 'http://192.168.0.99:8080';
        // this.serverUrl = 'http://192.168.178.45:8080';
        // this.serverUrl = 'http://192.168.178.32:8080'; // Mays IP
        // this.serverUrl = 'http://192.168.178.20:8080'; // Mays IP Desktop

      } else {
        this.serverUrl = 'http://' + this.ip + ':8080';
      }*/
      // console.log(this.serverUrl);
    }).catch((error) => {
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  /**
   * returns client public ip
   */
  public connectToServer(): Observable<any> {
    const ip = window.location.origin; // this will give you the ip:port
    // if you just want the ip or hostname you can use window.location.hostname
    const url =  ip + '/api/';
    const endpoint = 'test/';
    // console.log(ip);
    console.log('%c' + ip, 'color: #003366');
    return this.http.get('http://api.ipify.org/?format=json');
  }

  // GET
  public getTalentarten(user: string, char: string): Observable<Talentwert[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Talentwert[]>(serverUrl + '/charakter/' + user + '/' + char + '/talentwerte', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getEigenschaften(user: string, char: string): Observable<Eigenschaft[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Eigenschaft[]>(serverUrl + '/charakter/' + user + '/' + char + '/eigenschaften', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }
  /** Heldenerschaffung */

  // POST
  public saveHeldenerschaffung(h: Heldenerschaffung): void {
    const serverUrl = this.getServerAdress();
    console.log(h);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(h);
    console.log(body);
    this.http.post<any>(serverUrl + '/heldenerschaffung', body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  // GET
  public getHeldenerschaffungOfUser(user: string): Observable<Heldenerschaffung> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Heldenerschaffung>(serverUrl + '/heldenerschaffung/load/' + user, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }


  /** Talent */

  // GET
  public getAlleTalente(): Observable<Talent[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Talent[]>(serverUrl + '/glossary/talente', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }


  /** Waffe */

  // POST
  public addWaffeToDB(w: Waffe): void {
    const serverUrl = this.getServerAdress();
    console.log('füge Waffe hinzu (' + w.name + ')');
    console.log(w);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(w);
    console.log(body);
    this.http.post<any>(serverUrl + '/glossary/waffe', body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  // GET
  public getAlleWaffen(): Observable<Waffe[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Waffe[]>(serverUrl + '/glossary/waffe', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }


  /** Rüstung */

  // POST
  public addRuestungToDB(r: Armor): void {
    const serverUrl = this.getServerAdress();
    console.log('füge Rüstung hinzu (' + r.name + ')');
    console.log(r);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(r);
    console.log(body);
    this.http.post<any>(serverUrl + '/glossary/ruestung', body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  // GET
  public getAlleRuestungen(): Observable<Armor[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Armor[]>(serverUrl + '/glossary/ruestung', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }


  /** Item */

  // POST
  public addGegenstandToDB(g: Gegenstand): void {
    const serverUrl = this.getServerAdress();
    console.log('füge Waffe hinzu (' + g.name + ')');
    console.log(g);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    let type = g.type;
    if (type === 'Behältnis') {
      type = 'Behaeltnis';
    } else if (type === 'Rüstung') {
      type = 'Ruestung';
    }
    const body = JSON.stringify(g);
    console.log(body);
    console.log(serverUrl + '/glossary/ausruestung/' + type);
    this.http.post<any>(serverUrl + '/glossary/ausruestung/' + type, body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  // POST
  public schlageGegenstandVor(g: Gegenstand): void {
    const serverUrl = this.getServerAdress();
    console.log(g);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(g);
    console.log(body);
    let type = g.type;
    if (type === 'Behältnis') {
      type = 'Behaeltnis';
    } else if (type === 'Rüstung') {
      type = 'Ruestung';
    }
    console.log(serverUrl + '/glossary/ausruestung/vorschlag/' + type);
    this.http.post<any>(serverUrl + '/glossary/ausruestung/vorschlag/' + type, body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }


  // POST
  public addItemToDB(i: Item): void {
    const serverUrl = this.getServerAdress();
    console.log('füge Item hinzu (' + i.name + ')');
    console.log(i);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(i);
    console.log(body);
    this.http.post<any>(serverUrl + '/glossary/item', body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  // GET
  public getAlleItems(): Observable<Item[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Item[]>(serverUrl + '/glossary/item', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }


  /** Kampftechnik */

  // POST
  public setKampftechniken(url: string, k: Kampftechnik[]): void {
    const serverUrl = this.getServerAdress();
    console.log(k);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(k);
    console.log(body);
    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
    });
  }

  // GET
  public getAlleKampftechniken(): Observable<Kampftechnik[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Kampftechnik[]>(serverUrl + '/glossary/kampftechnik', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleKaempferin(): Observable<Kaempferin[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Kaempferin[]>(serverUrl + '/glossary/kaempferin', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // POST
  public addKaempferinToDB(k: Kaempferin): void {
    const serverUrl = this.getServerAdress();
    console.log('füge Kaempferin hinzu (' + k.name + ')');
    console.log(k);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(k);
    console.log(body);
    this.http.post<any>(serverUrl + '/glossary/kaempferin', body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }


  /** Talentanwendung */

  // GET
  public getAlleTalentanwendungen(): Observable<Talentanwendung[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Talentanwendung[]>(serverUrl + '/glossary/talentanwendungen', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

// GET
  public getAlleBenutzer(): Observable<Benutzer[]> {
    console.log('%cgetAlleBenutzer()', 'background: #e6e6ff; color: #0000ff');
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    // console.log(serverUrl + '/benutzer');
    return this.http.get<Benutzer[]>(serverUrl + '/benutzer', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleUser(): Observable<User[]> {
    const serverUrl = this.getServerAdress();
    // console.log('getAlleUser()');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log(serverUrl + '/user');
    return this.http.get<User[]>(serverUrl + '/user', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getGlossarNew(): Observable<Glossar> {
    console.log('%cgetGlossar()', 'background: #e6e6ff; color: #0000ff');
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Glossar>(serverUrl + '/glossary', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getGlossar(): Observable<Glossar> {
    console.log('%cgetGlossar()', 'background: #e6e6ff; color: #0000ff');
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Glossar>(serverUrl + '/glossary', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAusruestung(): Observable<Gegenstand[]> {
    console.log('%cgetGlossar()', 'background: #e6e6ff; color: #0000ff');
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Gegenstand[]>(serverUrl + '/glossary/ausruestung', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getNewItemId(): Observable<string> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<string>(serverUrl + '/charakter/itemid', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleCharaktere(): Observable<Charakter[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Charakter[]>(serverUrl + '/charakter', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getHeldin(name: string): Observable<Heldin> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log(serverUrl + '/charakter/' + name + '');
    return this.http.get<Heldin>(serverUrl + '/charakter/' + name + '', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getCharakterWithName(user: string, name: string): Observable<Charakter> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log(serverUrl + '/charakter/' + user + '/' + name);
    return this.http.get<Charakter>(serverUrl + '/charakter/' + user + '/' + name, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public authenticate(user: string, pwd: string): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    // console.log('Login-attempt for ' + user + ' with password: ' + pwd);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<boolean>(serverUrl + '/user/login/' + user + '/' + pwd, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // POST
  public register(user: string, pwd: string): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    // console.log('Login-attempt for ' + user + ' with password: ' + pwd);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.post<boolean>(serverUrl + '/user/register/' + user + '/' + pwd, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // POST
  public registriereBenutzer(user: string): Observable<number> {
    const serverUrl = this.getServerAdress();
    // console.log('Login-attempt for ' + user + ' with password: ' + pwd);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.post<number>(serverUrl + '/benutzer/register/' + user, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // POST
  public addVorUndNachteil(vun: VorUndNachteil): void {
    const serverUrl = this.getServerAdress();
    // console.log('füge vun hinzu (' + vun.name + ')');
    // console.log(vun);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    const body = JSON.stringify(vun);

    this.http.post<any>(serverUrl + '/glossary/vorundnachteile', body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public finalizeHeldenerschaffung(url: string, h: Heldenerschaffung): void {
    const serverUrl = this.getServerAdress();
    // console.log('füge vun hinzu (' + vun.name + ')');
    console.log(h);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    const body = JSON.stringify(h);

    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public postVorUndNachteile(url: string, vun: CharakterVuN[]): void {
    const serverUrl = this.getServerAdress();
    // console.log(vun);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    const body = JSON.stringify(vun);

    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public deleteVorUndNachteil(vun: VorUndNachteil): void {
    const serverUrl = this.getServerAdress();
    // console.log(vun);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(vun);

    this.http.post<any>(serverUrl + '/glossary/vorundnachteile/delete', body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // GET
  public requestBoolean(url: string): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('url: ' + url);
    // console.log('GET-request: ' + this.serverUrl + url);
    return this.http.get<boolean>(serverUrl + url, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public requestString(url: string): Observable<string> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('url: ' + url);
    // console.log('GET-request: ' + this.serverUrl + url);
    return this.http.get<string>(serverUrl + url, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public requestErschaffungsattribut(url: string): Observable<Erschaffungsattribut[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('url: ' + url);
    // console.log('GET-request: ' + this.serverUrl + url);
    return this.http.get<Erschaffungsattribut[]>(serverUrl + url, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public requestStringArray(url: string): Observable<string[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('data-service/requestStringArray(url: string)/url: ' + url);
    // console.log('data-service/requestStringArray(url: string)/GET-request: ' + this.serverUrl + url);
    return this.http.get<string[]>(serverUrl + url, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public request2DStringArray(url: string): Observable<string[][]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('data-service/request2DStringArray(url: string)/url: ' + url);
    console.log('data-service/request2DStringArray(url: string)/GET-request: ' + this.serverUrl + url);
    return this.http.get<string[][]>(serverUrl + url, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // PUT
  public putRequest(url: string): void {
    const serverUrl = this.getServerAdress();
    console.log('PUT-request: ' + serverUrl + url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log(serverUrl + url);
    this.http.put<any>(serverUrl + url, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  public getSpezies(id: string): Observable<Spezies> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Spezies>(serverUrl + '/glossary/spezies/' + id, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleSpezies(): Observable<Spezies[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/spezies/');
    return this.http.get<Spezies[]>(serverUrl + '/glossary/spezies/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleKulturen(): Observable<Kultur[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/kultur/');
    return this.http.get<Kultur[]>(serverUrl + '/glossary/kultur/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getGruppe(): Observable<Gruppenmitglied[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/zaubertrick/');
    return this.http.get<Gruppenmitglied[]>(serverUrl + '/abenteuer/gruppe/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getMaterial(): Observable<NameWert[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<NameWert[]>(serverUrl + '/abenteuer/material/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getSchriftstuecke(): Observable<Schriftstueck[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Schriftstueck[]>(serverUrl + '/abenteuer/schriftstuecke/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getTestItems(): Observable<any[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/kultur/');
    return this.http.get<any[]>(serverUrl + '/glossary/testItem/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleProfessionen(): Observable<Profession[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/profession/');
    return this.http.get<Profession[]>(serverUrl + '/glossary/profession/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleZauber(): Observable<Zauber[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/zauber/');
    return this.http.get<Zauber[]>(serverUrl + '/glossary/zauber/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

// GET
  public getAlleZaubertricks(): Observable<Zaubertrick[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/zaubertrick/');
    return this.http.get<Zaubertrick[]>(serverUrl + '/glossary/zaubertrick/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getTypischeKulturen(url: string): Observable<Kultur[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + url);
    return this.http.get<Kultur[]>(serverUrl + url, httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // DELETE
  public resetHeldenerschaffung(url: string): void {
    const serverUrl = this.getServerAdress();
    // console.log('DELETE-request: ' + this.serverUrl + url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    this.http.delete<any>(serverUrl + url, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // GET
  public getVorUndNachteile(): Observable<VorUndNachteil[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log('GET-request: ' + this.serverUrl + '/glossary/vorundnachteile/');
    return this.http.get<VorUndNachteil[]>(serverUrl + '/glossary/vorundnachteile/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getAlleSF(): Observable<any[]> {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log('GET-request: ' + this.serverUrl + '/glossary/sonderfertigkeit/');
    return this.http.get<any[]>(serverUrl + '/glossary/sonderfertigkeit/', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getTalentwerteUser(user: string): Observable<Talentwert[]> {
    const serverUrl = this.getServerAdress();
    // console.log('getAlleUser()');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Talentwert[]>(serverUrl + '/heldenerschaffung/' + user + '/talentwerte', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getZauberUser(user: string, charName: string): Observable<Zauber[]> {
    const serverUrl = this.getServerAdress();
    // console.log('getAlleUser()');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Zauber[]>(serverUrl + '/charakter/' + user + '/' + charName + '/zauber', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  // GET
  public getZauberTricksUser(user: string, charName: string): Observable<Zaubertrick[]> {
    const serverUrl = this.getServerAdress();
    // console.log('getAlleUser()');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.get<Zaubertrick[]>(serverUrl + '/charakter/' + user + '/' + charName + '/zaubertrick', httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
      );
  }

  public makeUrlConform(str: string): string {
    const codingTable: string[] = ['AE', 'OE' , 'UE', 'ae', 'oe', 'ue', 'sz', '_'];
    const stringTable: string[] = ['Ä', 'Ö' , 'Ü', 'ä', 'ö', 'ü', 'ß', ' '];
    for (let i = 0; i < codingTable.length; i++) {
      // console.log(str + ' includes ' + stringTable[i] + '?');
      while (str.includes(stringTable[i])) {
        // console.log('    -> yes');
        str = str.replace(stringTable[i], codingTable[i]);
      }
    }
    // console.log('converted to: ' + str);
    return str;
  }

  // POST
  public addZauberToDB(z: Zauber): void {
    const serverUrl = this.getServerAdress();
    // console.log('füge Zauber hinzu (' + z.name + ')');
    // console.log(z);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    const body = JSON.stringify(z);

    this.http.post<any>(serverUrl + '/glossary/zauber', body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public addZaubertrickToDB(z: Zaubertrick): void {
    const serverUrl = this.getServerAdress();
    console.log('füge Zaubertrick hinzu (' + z.name + ')');
    console.log(z);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    const body = JSON.stringify(z);

    this.http.post<any>(serverUrl + '/glossary/zaubertrick', body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

// POST
  public addSonderfertigkeit(sf: any): void {
    const serverUrl = this.getServerAdress();
    console.log(sf);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log(sf);
    const body = JSON.stringify(sf);
    this.http.post<Sonderfertigkeit>(serverUrl + '/glossary/sonderfertigkeit/' + sf.klasse, body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public addGTier(tier: Gtier): void {
    const serverUrl = this.getServerAdress();
    console.log(tier);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log(tier);
    const body = JSON.stringify(tier);
    this.http.post<Sonderfertigkeit>(serverUrl + '/glossary/tier', body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public addSonderfertigkeitToDB(sf: Sonderfertigkeit): Observable<Sonderfertigkeit> {
    const serverUrl = this.getServerAdress();
    console.log(sf);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(sf);
    return this.http.post<Sonderfertigkeit>(serverUrl + '/glossary/sonderfertigkeit', body, httpOptions);
  }

  // POST
  public addProfessionToDB(p: Profession): Observable<Profession> {
    const serverUrl = this.getServerAdress();
    console.log(p);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(p);
    console.log(serverUrl + '/glossary/profession');
    return this.http.post<Profession>(serverUrl + '/glossary/profession', body, httpOptions);
  }

  // POST
  public postKoerperattribute(url: string, attr: Step2): void {
    const serverUrl = this.getServerAdress();
    // console.log('postKoerperattribute');
    // console.log(attr);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    let body: string = JSON.stringify(attr);
    body = '[' + body + ']';
    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public setProfession(url: string, p: Profession): void {
    const serverUrl = this.getServerAdress();
    // console.log(p);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    const body = JSON.stringify(p);

    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
      // console.log(data);
    });
  }

  // POST
  public setZauber(url: string, z: Zauber[]): void {
    const serverUrl = this.getServerAdress();
    // console.log(p);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(z);
    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
    });
  }

  // POST
  public setTalente(url: string, t: Talentwert[]): void {
    const serverUrl = this.getServerAdress();
    console.log(t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(t);
    console.log(body);
    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
    });
  }



  // POST
  public setSF(url: string, sf: Sonderfertigkeit[]): void {
    const serverUrl = this.getServerAdress();
    console.log(sf);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(sf);
    console.log(body);
    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
    });
  }

  // POST
  public setBasiswerteForHeldenerschaffung(url: string, basiswerte: Basiswerte): void {
    const serverUrl = this.getServerAdress();
    console.log(basiswerte);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(basiswerte);
    console.log(body);
    this.http.post<any>(serverUrl + url, body, httpOptions).subscribe(data => {
    });
  }

  // POST
  public saveCharakter(url: string, c: Heldin): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    console.log(c);
    // c = this.checkTypesOfCharakter(c);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(c);
    // console.log(body);
    return this.http.post<boolean>(serverUrl + url, body, httpOptions);
  }

  // POST
  public addMaterial(data: NameWert): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    console.log(data);
    // c = this.checkTypesOfCharakter(c);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(data);
    // console.log(body);
    return this.http.post<boolean>(serverUrl + '/abenteuer/material/', body, httpOptions);
  }

  // DELETE
  public deleteFromMaterial(name: string): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    console.log(name);
    // c = this.checkTypesOfCharakter(c);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log(body);
    return this.http.delete<boolean>(serverUrl + '/abenteuer/material/' + name, httpOptions);
  }

  // POST
  public addSchriftstueck(data: Schriftstueck): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    console.log(data);
    // c = this.checkTypesOfCharakter(c);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    const body = JSON.stringify(data);
    // console.log(body);
    return this.http.post<boolean>(serverUrl + '/abenteuer/schriftstuecke/', body, httpOptions);
  }

  // DELETE
  public deleteFromSchriftstueck(name: string): Observable<boolean> {
    const serverUrl = this.getServerAdress();
    console.log(name);
    // c = this.checkTypesOfCharakter(c);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    // console.log(body);
    return this.http.delete<boolean>(serverUrl + '/abenteuer/schriftstuecke/' + name, httpOptions);
  }

  // POST
  public setPortrait(name: string, image: any): void {
    const serverUrl = this.getServerAdress();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    console.log(serverUrl + '/charakter/' + name);
    const body = JSON.stringify(image);
    // console.log(body);
    this.http.post<any>(serverUrl + '/charakter/portrait/' + name, body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  public checkTypesOfCharakter(c: Charakter): Charakter {
    console.log('Schritt 1');
    c.alter = Number(c.alter);
    c.groesse = Number(c.groesse);
    c.gewicht = Number(c.gewicht);

    console.log('Schritt 2');
    c.basiswerte.maxLeP = Number(c.basiswerte.maxLeP);
    c.basiswerte.aktLeP = Number(c.basiswerte.aktLeP);
    c.basiswerte.dreiViertelLep = Number(c.basiswerte.dreiViertelLep);
    c.basiswerte.halbLep = Number(c.basiswerte.halbLep);
    c.basiswerte.einViertelLep = Number(c.basiswerte.einViertelLep);
    c.basiswerte.schmerzDurchLep = Number(c.basiswerte.schmerzDurchLep);
    c.basiswerte.maxAsP = Number(c.basiswerte.maxAsP);
    c.basiswerte.aktAsP = Number(c.basiswerte.aktAsP);
    c.basiswerte.maxKaP = Number(c.basiswerte.maxKaP);
    c.basiswerte.aktKaP = Number(c.basiswerte.aktKaP);
    c.basiswerte.aktSchips = Number(c.basiswerte.aktSchips);
    c.basiswerte.maxSchips = Number(c.basiswerte.maxSchips);
    c.basiswerte.aktSk = Number(c.basiswerte.aktSk);
    c.basiswerte.maxSk = Number(c.basiswerte.maxSk);
    c.basiswerte.aktZk = Number(c.basiswerte.aktZk);
    c.basiswerte.maxZk = Number(c.basiswerte.maxZk);
    c.basiswerte.aktAw = Number(c.basiswerte.aktAw);
    c.basiswerte.maxAw = Number(c.basiswerte.maxAw);
    c.basiswerte.aktIni = Number(c.basiswerte.aktIni);
    c.basiswerte.maxIni = Number(c.basiswerte.maxIni);
    c.basiswerte.aktGs = Number(c.basiswerte.aktGs);
    c.basiswerte.maxGs = Number(c.basiswerte.maxGs);

    console.log('Schritt 3');
    c.basiswerte.modifikatoren.alleProbenMod = Number(c.basiswerte.modifikatoren.alleProbenMod);
    c.basiswerte.modifikatoren.alleEigenschaftenMod = Number(c.basiswerte.modifikatoren.alleEigenschaftenMod);
    c.basiswerte.modifikatoren.alleTalenteMod = Number(c.basiswerte.modifikatoren.alleTalenteMod);
    c.basiswerte.modifikatoren.alleKampftechnikenMod = Number(c.basiswerte.modifikatoren.alleKampftechnikenMod);
    c.basiswerte.modifikatoren.multiplikatorGs = Number(c.basiswerte.modifikatoren.multiplikatorGs);
    c.basiswerte.modifikatoren.belastung = Number(c.basiswerte.modifikatoren.belastung);
    c.basiswerte.modifikatoren.betaeubung = Number(c.basiswerte.modifikatoren.betaeubung);
    c.basiswerte.modifikatoren.entrueckung = Number(c.basiswerte.modifikatoren.entrueckung);
    c.basiswerte.modifikatoren.furcht = Number(c.basiswerte.modifikatoren.furcht);
    c.basiswerte.modifikatoren.paralyse = Number(c.basiswerte.modifikatoren.paralyse);
    c.basiswerte.modifikatoren.schmerz = Number(c.basiswerte.modifikatoren.schmerz);
    c.basiswerte.modifikatoren.verwirrung = Number(c.basiswerte.modifikatoren.verwirrung);
    for (let i = 0; i < c.basiswerte.modifikatoren.eigenschaftMod.length; i++) {
      c.basiswerte.modifikatoren.eigenschaftMod[i] = Number(c.basiswerte.modifikatoren.eigenschaftMod[i]);
    }
    for (let i = 0; i < c.basiswerte.modifikatoren.talentMod.length; i++) {
      c.basiswerte.modifikatoren.talentMod[i] = Number(c.basiswerte.modifikatoren.talentMod[i]);
    }
    for (let i = 0; i < c.basiswerte.modifikatoren.talentanwendungMod.length; i++) {
      c.basiswerte.modifikatoren.talentanwendungMod[i] = Number(c.basiswerte.modifikatoren.talentanwendungMod[i]);
    }
    for (let i = 0; i < c.basiswerte.modifikatoren.attributMod.length; i++) {
      c.basiswerte.modifikatoren.attributMod[i] = Number(c.basiswerte.modifikatoren.attributMod[i]);
    }

    /*
    console.log('Schritt 4');
    c.erfahrung.startAP = Number(c.erfahrung.startAP);
    c.erfahrung.maxEigenschaft = Number(c.erfahrung.maxEigenschaft);
    c.erfahrung.maxFertigkeit = Number(c.erfahrung.maxFertigkeit);
    c.erfahrung.maxKampftechnik = Number(c.erfahrung.maxKampftechnik);
    c.erfahrung.maxPktEigenschaft = Number(c.erfahrung.maxPktEigenschaft);
    c.erfahrung.maxAnzZauber = Number(c.erfahrung.maxAnzZauber);
    c.erfahrung.maxFremdZauber = Number(c.erfahrung.maxFremdZauber);
    c.erfahrung.apLog = Number(c.erfahrung.apLog);
    c.erfahrung.apKonto = Number(c.erfahrung.apKonto);
    c.erfahrung.stufe = Number(c.erfahrung.stufe);

    console.log('Schritt 5');
    c.eigenschaften.forEach(eig => {
      eig.wert = Number(eig.wert);
    });

    console.log('Schritt 6');
    c.talente.forEach(talent => {
      talent.maxFW = Number(talent.maxFW);
      talent.aktFW = Number(talent.aktFW);
      talent.startWert = Number(talent.startWert);
      for (let i = 0; i < talent.probeWerte.length; i++) {
        talent.probeWerte[i] = Number(talent.probeWerte[i]);
      }
    });*/
    return c;
  }
}
