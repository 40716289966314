export class BeeinflussteWerte {
  modifiziertes: string;
  modName: string;
  modWert: string;
  kr: number;

  public copy(other: BeeinflussteWerte): void {
    this.modifiziertes = other.modifiziertes;
    this.modName = other.modName;
    this.modWert = other.modWert;
    this.kr = other.kr;
  }

  public getWert(): string {
    if (this.modWert == null || this.modWert === '') {
      this.modWert = '0';
    }
    return this.modWert;
  }

  public getNumericWert(): number {
    if (this.modWert == null || this.modWert === '') {
      this.modWert = '0';
    }
    return Number(this.modWert);
  }

  public setWert(wert: string): void {
    this.modWert = wert;
  }
}
