<div class="body">
  <div class="menu">
    <div *ngFor="let art of menu" (click)="setTalentart(art)">
      <label class="talentartglow" *ngIf="art === nav">{{art}}</label>
      <label class="talentart" *ngIf="art !== nav">{{art}}</label>
    </div>
  </div>
  <div *ngIf="nav === 'Personen'">
    <div *ngFor="let held of gruppe">
      <div class="zeile" *ngIf="held.name !== charakter.name">

        <div class="portraitBox">
          <img class="portrait" alt="bild" [src]="getSanitizedURL(held.portrait)"/>
        </div>
        <div class="textBox">
          <div class="zeile">
            <h3>{{held.name}}</h3>
            <h3 style="font-weight: normal" *ngIf="held.alter !== 0">Alter: {{held.alter}}</h3>
            <h3 style="font-weight: normal" *ngIf="held.alter === 0">Alter: unbekannt</h3>
            <h3 style="font-weight: normal">Größe: {{held.groesse}} HF</h3>
          </div>
          <div class="beschreibung">
            {{held.beschreibung}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="nav === 'Material'">
    <div class="zeile" style="justify-content: space-around">
      <button (click)="getMaterial()">lade Material neu</button>
      <select [(ngModel)]="index" (ngModelChange)="selectMaterial()">
        <option [value]="material.indexOf(mat)" *ngFor="let mat of material">{{mat.name}}</option>
      </select>
    </div>
    <div class="zeile" style="justify-content: space-around">
      <button class="invisibleButton" (click)="changeMaterial(-1)">↢ zurück</button>
      <button class="invisibleButton" (click)="changeMaterial(1)">weiter ↣</button>
    </div>
    <div  class="materialBox">
      <div class="materialName">{{active.name}}</div>
      <img class="materialBild" alt="bild" [src]="getSanitizedURL(active.wert)"/>
    </div>
  </div>
  <div *ngIf="nav === 'Schriftstücke'">
    <div class="zeile" style="justify-content: space-around">
      <button (click)="getSchriftstueck()">lade Schriftstücke neu</button>
      <select [(ngModel)]="schriftIndex" (ngModelChange)="selectSchriftstueck()">
        <option [value]="schriften.indexOf(stck)" *ngFor="let stck of schriften">{{stck.name}}</option>
      </select>
    </div>
    <div  class="materialBox">
      <div class="materialName">{{selectedSchrift.name}}</div>
      <div class="schriftstueck" id="handwrite" *ngIf="selectedSchrift.textart === 'Handschrift'">{{angezeigterText}}</div>
      <div class="schriftstueck" id="handwrite2" *ngIf="selectedSchrift.textart === 'Handschrift2'">{{angezeigterText}}</div>
      <div class="schriftstueck" id="handwrite3" *ngIf="selectedSchrift.textart === 'Handschrift3'">{{angezeigterText}}</div>
      <div class="schriftstueck" id="handwrite4" *ngIf="selectedSchrift.textart === 'Handschrift4'">{{angezeigterText}}</div>
      <div class="schriftstueck" id="handwrite5" *ngIf="selectedSchrift.textart === 'Handschrift5'">{{angezeigterText}}</div>
      <div class="schriftstueck" id="druck" *ngIf="selectedSchrift.textart === 'Druckschrift'">{{angezeigterText}}</div>
    </div>
  </div>
</div>
