<div class="nav0" *ngIf="localData.nav===0">
  <div class="body">
    <div class="beschreibung">
      <h2>Willkommen {{ benutzer.name }}!</h2>
      Du befindest dich im Hauptmenü. Wähle einen Charakter aus oder erstelle einen neuen.<br/>
    </div>
    <div class="banner" (click)="changeNav(2)">
      <div class="text">Charakterauswahl</div>
      <img src="../../../assets/images/banner_charaktere.png"/>
    </div>
    <div class="banner" (click)="changeNav(4)" *ngIf="localData.spielmeister">
      <div class="text">Arena</div>
      <img src="../../../assets/images/banner_kampf.png"/>
    </div>
    <div class="banner" (click)="changeNav(5)">
      <div class="text">NPC-Generator</div>
      <img src="../../../assets/images/banner_wuerfel.png"/>
    </div>
    <div class="banner">
      <div class="text">Charaktererstellung</div>
      <a class="link" href="http://h3004666.stratoserver.net:4201"></a>
      <img src="../../../assets/images/banner_wuerfel.png"/>
    </div>
    <div class="banner" (click)="changeNav(3)" *ngIf="localData.spielmeister">
      <div class="text">Spielmeisteroptionen</div>
      <img src="../../../assets/images/banner_axe.png"/>
    </div>
  </div>
</div>
<div class="nav1" *ngIf="localData.nav===1">
  <div class="body2">
    <app-heldenerschaffung [user]="benutzer" (ping)="navEvent($event)"></app-heldenerschaffung>
  </div>
</div>
<div class="nav2" *ngIf="localData.nav===2">
  <div class="body">
    <div class="beschreibung" *ngIf="benutzer.charaktere.length > 0">
      <h2>Charakterauswahl</h2>
      Du besitzt zur Zeit folgende<label *ngIf="benutzer.charaktere.length < 2">n</label> Charakter<label *ngIf="benutzer.charaktere.length > 1">e</label>:
    </div>
    <div class="beschreibung" *ngIf="benutzer.charaktere.length < 1">
      <h2>Charakterauswahl</h2>
      Du besitzt zur Zeit noch keinen Charakter.
    </div>
    <div class="banner" *ngFor="let name of benutzer.charaktere">
      <div class="chartext" (click)="getCharakter(name)">{{ name }}</div>
      <img src="../../../assets/images/banner_leer.png" (click)="getCharakter(name)"/>
    </div>
    <div class="banner" (click)="changeNav(0)">
      <div class="buttontext">&nbsp;zurück</div>
      <img src="../../../assets/images/banner_silver.png"/>
    </div>
  </div>
</div>
<div class="nav2" *ngIf="localData.nav===3">
  <!--div class="body"-->
    <div class="beschreibung">
      <h2>Spielmeisteroptionen</h2>
    </div>
    <app-spielmeisteroptionen [glossar]="glossar" [localData]="localData" (reload)="reloadEvent($event)"></app-spielmeisteroptionen>
  <!--/div-->
</div>
<div class="nav1" *ngIf="localData.nav===4">
  <div class="body2">
    <app-arena></app-arena>
  </div>
</div>
<div class="nav1" *ngIf="localData.nav===5">
  <div class="body2">
    <app-npc-generator></app-npc-generator>
  </div>
</div>
<app-heldenbogen *ngIf="localData.nav===10" [charakter]="localData.charakter" [glossar]="glossar" [gruppe]="gruppe" (ping)="leaveHeldenbogen()" (update)="updateChar()" (reload)="reloadEvent($event)"></app-heldenbogen>
<div class="back" *ngIf="localData.nav>10" (click)="changeNav(0)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    zurück
  </div>
</div>
<div class="back" *ngIf="localData.nav===1 || localData.nav===4 || localData.nav ===5" (click)="changeNav(0)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    zurück
  </div>
</div>

<app-logout *ngIf="localData.nav !== 10"></app-logout>
