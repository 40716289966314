import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataService} from '../../_services/data.service';
import {Spezies} from '../../_interfaces/spezies';
import {Erschaffungsattribut} from '../../_interfaces/erschaffungsattribut';
import {Kultur} from '../../_interfaces/kultur';
import {SteigerungstabelleService} from '../../_services/steigerungstabelle.service';
import {Profession} from '../../_interfaces/profession';
import {VorUndNachteil} from '../../_interfaces/vor-und-nachteil';
import {CharakterVuN} from '../../_interfaces/charakter-vu-n';
import {Talent} from '../../_interfaces/talent';
import {Talentwert} from '../../_interfaces/talentwert';
import {Zauber} from '../../_interfaces/zauber';
import {Step0} from '../../_interfaces/charaktererstellung/step0';
import {Step1} from '../../_interfaces/charaktererstellung/step1';
import {Step2} from '../../_interfaces/charaktererstellung/step2';
import {Step3} from '../../_interfaces/charaktererstellung/step3';
import {Step4} from '../../_interfaces/charaktererstellung/step4';
import {Step5} from '../../_interfaces/charaktererstellung/step5';
import {Step6} from '../../_interfaces/charaktererstellung/step6';
import {Step7} from '../../_interfaces/charaktererstellung/step7';
import {Step8} from '../../_interfaces/charaktererstellung/step8';
import {Sonderfertigkeit} from '../../_interfaces/sonderfertigkeit';
import {Step9} from '../../_interfaces/charaktererstellung/step9';
import {Kampftechnik} from '../../_interfaces/kampftechnik';
import {Basiswerte} from '../../_interfaces/basiswerte';
import {Step10} from '../../_interfaces/charaktererstellung/step10';
import {NumberEvent} from '../../_interfaces/events/number-event';
import {Heldenerschaffung} from '../../_interfaces/charaktererstellung/heldenerschaffung';
import {Modifikatoren} from '../../_interfaces/modifikatoren';
import {Erfahrung} from '../../_interfaces/erfahrung';
import {ObjectFactoryService} from '../../_services/object-factory.service';

@Component({
  selector: 'app-charaktererstellung',
  templateUrl: './charaktererstellung.component.html',
  styleUrls: ['./charaktererstellung.component.sass']
})
export class CharaktererstellungComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  public heldin: Heldenerschaffung;

  // public $step: number;
  // public $weitesterStep: number;
  public $stepcount = 10;
  // public $ap: number;
  // public $apProStep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  public professionZauber: number;
  public $apBackup: number;
  public $basiswerte: Basiswerte;

  public $talentInfos: Talent[];
  public $kampftechniken: Kampftechnik[];

  public $erfahrung: string;
  public $erfahrungsInfo: string[][];
  public $geschlecht: string;

  public $spezies: Spezies;

  public koerperattribute: Step2;

  public $kultur: Kultur;

  public $profession: Profession;

  public $alleTalente: Talentwert[];
  public $charKampftechniken: Kampftechnik[];

  public $eigenschaften: Erschaffungsattribut[];
  public $attributmodguard: boolean;

  public $eigPkte: number;

  public $listOfChosenVuN: CharakterVuN[];

  public $listOfChosenZauber: Zauber[];
  public $tradition: string;

  public $listOfChosenSF: Sonderfertigkeit[];
  public $name: string;
  public $alter: number;

  constructor(public dataService: DataService, public steigerung: SteigerungstabelleService, private factory: ObjectFactoryService) {
    sessionStorage.setItem('step', '0');
    this.$erfahrung = '0';
    this.$geschlecht = 'true';
    this.$apBackup = 900;
    this.$erfahrungsInfo = [];
    this.professionZauber = 0;
    this.getTalentInfos();
    this.getKampftechniken();
    this.resetHeldenerschaffung();
    this.heldin.$weitesterStep = 0;

    this.koerperattribute = {
      haarfarbe: undefined,
      augenfarbe: undefined,
      groesse: undefined,
      gewicht: undefined,
      schamhaare: undefined,
      geschlechtsorgan: undefined,
      brueste: undefined
    };

    this.$profession = undefined;

    this.$eigenschaften = [];
    this.$attributmodguard = false;



    this.$eigPkte = 64;

    this.$listOfChosenVuN = [];

    this.$listOfChosenZauber = [];

    if (sessionStorage.getItem('step')) {
      let nr: number;
      nr = +sessionStorage.getItem('step');
      this.heldin.$step = nr;
    } else {
      // console.log('nav initially created');
      this.heldin.$step = 0;
      sessionStorage.setItem('step', '0');
    }
    const $username = sessionStorage.getItem('user');
    this.dataService.resetHeldenerschaffung('/heldenerschaffung/' + $username);
    // this.calculateGesamtKosten();
    this.$basiswerte = {
      modifikatoren: undefined,
      maxLeP: 0,
      aktLeP: 0,
      dreiViertelLep: 0,
      halbLep: 0,
      einViertelLep: 0,
      schmerzDurchLep: 0,
      manuellerSchmerz: 0,
      maxAsP: 0,
      aktAsP: 0,
      maxKaP: 0,
      aktKaP: 0,
      aktSchips: 0,
      maxSchips: 0,
      aktSk: 0,
      maxSk: 0,
      aktZk: 0,
      maxZk: 0,
      aktAw: 0,
      maxAw: 0,
      manAw: 0,
      aktIni: 0,
      maxIni: 0,
      iniWurf: 0,
      aktGs: 0,
      maxGs: 0
    };
  }

  ngOnInit(): void {
  }

  public decrementStep(): void {
    switch (this.heldin.$step) {
      case 1: // Spezies
        // this.$ap += this.$spezies.apWert;
        this.$spezies = undefined;
        break;
      case 2: // Merkmale
              // this.$attributmodguard = false;
        break;
      case 3: // Kultur
        // this.$ap += +this.$kultur.paketKosten;
        this.$kultur = undefined;
        break;
      case 4: // Eigenschaften
        // this.$ap = this.$apBackup;
        break;
      case 5: // Profession
        this.$profession = undefined;
        // this.$ap = this.$apBackup;
        break;
      case 6: // Vor- und Nachteile
        this.$listOfChosenVuN = [];
        // this.$ap = this.$apBackup;
        break;
      case 7: // Zauber
        // this.$ap = this.$apBackup;
        break;
    }
    this.heldin.$step --;
    this.calculateGesamtKosten();
  }

  public incrementStep(): void {
    const $username = sessionStorage.getItem('user');
    switch (this.heldin.$step) {
      case 0:
        if (this.heldin.$weitesterStep <= 1) {
          this.heldin.$weitesterStep = 1;
        }
        break;
      case 1: // Spezies
        if (this.heldin.$weitesterStep <= 2) {
          this.heldin.$weitesterStep = 2;
        }
        break;
      case 2: // Merkmale
        if (this.heldin.augenfarbeNumber === undefined) {
          this.heldin.augenfarbeNumber = this.koerperattribute.augenfarbe;
        }
        if (this.heldin.haarfarbeNumber === undefined) {
          this.heldin.haarfarbeNumber = this.koerperattribute.haarfarbe;
        }
        if (this.heldin.groesseNumber === undefined) {
          this.heldin.groesseNumber = this.koerperattribute.groesse;
        }
        if (this.heldin.gewichtNumber === undefined) {
          this.heldin.gewichtNumber = this.koerperattribute.gewicht;
        }
        if (this.heldin.schamhaareNumber === undefined) {
          this.heldin.schamhaareNumber = this.koerperattribute.schamhaare;
        }
        if (this.heldin.bruesteNumber === undefined) {
          this.heldin.bruesteNumber = this.koerperattribute.brueste;
        }
        if (this.heldin.genitalNumber === undefined) {
          this.heldin.genitalNumber = this.koerperattribute.geschlechtsorgan;
        }

        if (this.heldin.haarfarbe === '') {
          console.log('getHaarfarbe');
          this.getHaarfarbe();
        }
        if (this.heldin.augenfarbe === '') {
          console.log('getAugenfarbe');
          this.getAugenfarbe();
        }
        if (this.heldin.groesse === undefined) {
          console.log('getKoerpergroesse');
          this.getKoerpergroesse();
        }
        if (this.heldin.gewicht === undefined) {
          console.log('getGewicht');
          this.getGewicht();
        }
        if (this.heldin.schamhaare === '') {
          console.log('getSchamhaare');
          this.getSchamhaare();
        }
        if (this.heldin.brueste === '') {
          console.log('getBrustgroesse');
          this.getBrustgroesse();
        }
        if (this.heldin.genital === '') {
          console.log('getGeschlechtsorgan');
          this.getGeschlechtsorgan();
        }
        console.log(this.koerperattribute);
        console.log(this.heldin);
        if (this.heldin.$weitesterStep <= 3) {
          this.heldin.$weitesterStep = 3;
        }
        break;
      case 3: // Kultur
        if (this.heldin.$weitesterStep <= 4) {
          this.heldin.$weitesterStep = 4;
        }
        break;
      case 4: // Eigenschaften
        if (this.heldin.$weitesterStep <= 5) {
          this.heldin.$weitesterStep = 5;
        }
        break;
      case 5: // Professionen
        if (this.heldin.$weitesterStep <= 6) {
          this.heldin.$weitesterStep = 6;
        }
        break;
      case 6: // Vor- und Nachteile
        if (this.heldin.$weitesterStep <= 7) {
          this.heldin.$weitesterStep = 7;
        }
        break;
      case 7: // Zauber
        if (this.heldin.$weitesterStep <= 8) {
          this.heldin.$weitesterStep = 8;
        }
        break;
      case 8: // Sonderfertigkeiten
        if (this.heldin.$weitesterStep <= 9) {
          this.heldin.$weitesterStep = 9;
        }
        this.calculateBasiswerte();
        break;
      case 9:
        if (this.heldin.$weitesterStep <= 10) {
          this.heldin.$weitesterStep = 10;
        }
        break;

      /*case 0:
        this.dataService.putRequest('/heldenerschaffung/' + $username + '/erfahrung/' + this.$erfahrung);
        this.dataService.putRequest('/heldenerschaffung/' + $username + '/geschlecht/' + this.$geschlecht);
        break;
      case 1: // Spezies
        this.dataService.putRequest('/heldenerschaffung/' + $username + '/spezies/' + this.$spezies.id);

        break;
      case 2: // Merkmale
        this.dataService.postKoerperattribute('/heldenerschaffung/' + $username + '/koerperattribute/', this.koerperattribute);
        break;
      case 3: // Kultur
        this.dataService.putRequest('/heldenerschaffung/' + $username + '/kultur/' + this.$kultur.name);
        this.$apBackup = this.$ap;
        break;
      case 4: // Eigenschaften
        this.setEigenschaften();
        break;
      case 5: // Professionen
        this.dataService.putRequest('/heldenerschaffung/' + $username + '/profession/' + this.$profession.nameM);
        // this.getTalentwerte();
        this.$apBackup = this.$ap;
        break;
      case 6: // Vor- und Nachteile
        this.$apBackup = this.$ap;
        this.dataService.postVorUndNachteile('/heldenerschaffung/' + $username + '/vorundnachteile/', this.$listOfChosenVuN);
        break;
      case 7: // Zauber
        this.$apBackup = this.$ap;
        this.dataService.setZauber('/heldenerschaffung/' + $username + '/zauber/', this.$listOfChosenZauber);
        break;
      case 8: // Sonderfertigkeiten
        this.$apBackup = this.$ap;
        this.dataService.setSF('/heldenerschaffung/' + $username + '/sonderfertigkeiten/', this.$listOfChosenSF);
        this.calculateBasiswerte();
        this.dataService.setBasiswerteForHeldenerschaffung('/heldenerschaffung/' + $username + '/basiswerte/', this.$basiswerte);
        break;
      case 9:
        this.dataService.setTalente('/heldenerschaffung/' + $username + '/talente/', this.$alleTalente);
        this.dataService.setKampftechniken('/heldenerschaffung/' + $username + '/kampftechniken/', this.$charKampftechniken);
        this.dataService.putRequest('/heldenerschaffung/' + $username + '/ap/' + this.$ap);*/
    }
    this.heldin.$step ++;
    this.calculateGesamtKosten();
    this.dataService.saveHeldenerschaffung(this.heldin);
  }

  public finalize(): void {
    const $username = sessionStorage.getItem('user');
    // {user}/name/{name}/alter/{alter}
    this.heldin.spieler = $username;
    this.heldin.erfahrung.apKonto = 0;
    for (let i = 0; i < this.$stepcount; i++) {
      this.heldin.erfahrung.apKonto += this.heldin.kostenProStep[i];
    }
    if (this.heldin.erfahrung.apKonto > 10) {
      this.heldin.erfahrung.apKonto = 10;
    }

    for (let i = 0; i < this.heldin.talentwerte.length; i++) {
      this.heldin.talentwerte[i].maxFW -= -this.heldin.talentwerteAusProfession[i].maxFW;
    }
    for (let i = 0; i < this.heldin.kampftechniken.length; i++) {
      this.heldin.kampftechniken[i].wert -= -this.heldin.kampftechnikenAusProfession[i].wert;
    }

    if (this.heldin.erfahrung.apKonto > 0 && this.heldin.name.length > 2 && this.heldin.alter > 0) {
      this.heldin.eigenschaften.forEach(eig => {
        eig.modifikator = 0;
      });
      this.dataService.finalizeHeldenerschaffung('/heldenerschaffung/' + $username, this.heldin);
    }
    this.alertParent();
  }

  public setEigenschaften(): void {
    const mu = this.$eigenschaften[0].wert;
    const kl = this.$eigenschaften[1].wert;
    const intui = this.$eigenschaften[2].wert;
    const ch = this.$eigenschaften[3].wert;
    const ff = this.$eigenschaften[4].wert;
    const ge = this.$eigenschaften[5].wert;
    const ko = this.$eigenschaften[6].wert;
    const kk = this.$eigenschaften[7].wert;
    const $username = sessionStorage.getItem('user');
    this.dataService.putRequest('/heldenerschaffung/' + $username + '/eigenschaften/setzen/'  + mu + '/' + kl + '/' + intui + '/' + ch
      + '/' + ff + '/' + ge + '/' + ko + '/' + kk + '/' + this.heldin.erfahrung.apKonto);
  }

  public getKampftechnikArray(kampftechnik: string): string[] {
    const result: string[] = [];
    if (kampftechnik.includes(',')) {
      const array: string[] = kampftechnik.split(',');
      for (let i = 1; i < array.length - 1; i++) {
        result.push(array[i]);
      }
    }
    return result;
  }

  public getTalentInfos(): void {
    this.$talentInfos = [];
    this.dataService.getAlleTalente().subscribe((data: Talent[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.$talentInfos = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getKampftechniken(): void {
    this.$talentInfos = [];
    this.dataService.getAlleKampftechniken().subscribe((data: Kampftechnik[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.heldin.kampftechniken = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  /*public getTalentwerte(): void {
    this.$alleTalente = [];
    const $username = sessionStorage.getItem('user');
    this.dataService.getTalentwerteUser($username).subscribe((data: Talentwert[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.sort((a, b) => (a.art < b.art ? -1 : 1));
      this.$alleTalente = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }*/

  public getKampftechnikWert(kampftechnik: string): number {
    let wert = 0;
    if (kampftechnik.includes(',')) {
      const array: string[] = kampftechnik.split(',');
      wert = +array[array.length - 1];
    }
    return wert;
  }

  public getKampftechnikAnz(kampftechnik: string): number {
    let anz = 0;
    if (kampftechnik.includes(',')) {
      const array: string[] = kampftechnik.split(',');
      anz = +array[0];
    }
    return anz;
  }

  public isArray(str: string, trenner: string): boolean {
    let result = false;
    if (str.includes(trenner)) {
      result = true;
    }
    return result;
  }

  /*public getTalentwertOfString(str: string): Talentwert {
    let talent: Talentwert = null;
    this.$alleTalente.forEach(t => {
      if (t.name === str) {
        talent = t;
      }
    });
    return talent;
  }*/

  public calculateGesamtKosten(): void {
    // console.log('apArray:');
    // console.log(this.$apProStep);
    this.heldin.erfahrung.apKonto = 0;
    for (let i = 0; i <= this.heldin.$weitesterStep; i++) {
      this.heldin.erfahrung.apKonto += this.heldin.kostenProStep[i];
    }
  }

  public calculateBasiswerte(): void {
    let asp = 0;
    let schips = 3;

    let lep = this.heldin.spezies.lePGrundwert + 2 * this.getEigenschaftWithName('KO').wert;

    if (this.$tradition !== undefined) {
      const traditionen = ['Gildenmagier', 'Hexe', 'Elf'];
      const leiteigenschaften = ['KL', 'CH', 'IN'];
      const leiteigenschaft = leiteigenschaften[traditionen.indexOf(this.$tradition)];
      let aspMod = this.getEigenschaftWithName(leiteigenschaft).wert;
      if (this.hasVuN('Zauberer')) {
        aspMod += 20;
      }
      asp = aspMod;
    }

    let skAttr = this.getEigenschaftWithName('MU').wert + this.getEigenschaftWithName('KL').wert + this.getEigenschaftWithName('IN').wert;
    skAttr /= 6;
    skAttr = Math.round(skAttr);
    let sk = this.heldin.spezies.SKGrundwert + skAttr;

    let zkAttr = this.getEigenschaftWithName('KO').wert + this.getEigenschaftWithName('KO').wert + this.getEigenschaftWithName('KK').wert;
    zkAttr /= 6;
    zkAttr = Math.round(zkAttr);
    let zk = this.heldin.spezies.ZKGrundwert + zkAttr;

    const aw = Math.round(this.getEigenschaftWithName('GE').wert / 2);

    const ini = Math.round((this.getEigenschaftWithName('MU').wert + this.getEigenschaftWithName('GE').wert) / 2);

    let gs = this.heldin.spezies.GSGrundwert;

    const glueck = this.getVuN('Glück');
    if (glueck !== undefined) {
      schips += glueck.stufe;
    }
    const pech = this.getVuN('Pech');
    if (pech !== undefined) {
      schips -= pech.stufe;
    }
    this.heldin.vorUndNachteile.forEach(vun => {
      if (vun.name === 'Hohe Lebenskraft') {
        lep += vun.stufe;
      } else if (vun.name === 'Niedrige Lebenskraft') {
        lep -= vun.stufe;
      } else if (vun.name === 'Hohe Astralkraft') {
        asp += vun.stufe;
      } else if (vun.name === 'Niedrige Astralkraft') {
        asp -= vun.stufe;
      } else if (vun.name === 'Hohe Seelenkraft') {
        sk += vun.stufe;
      } else if (vun.name === 'Niedrige Seelenkraft') {
        sk -= vun.stufe;
      } else if (vun.name === 'Hohe Zähigkeit') {
        zk += vun.stufe;
      } else if (vun.name === 'Niedrige Zähigkeit') {
        zk -= vun.stufe;
      } else if (vun.name === 'Flink') {
        gs ++;
      } else if (vun.name === 'Behäbig') {
        gs ++;
      } else if (vun.name === 'Zauberer') {
        asp += 20;
      }

    });
    this.heldin.basiswerte.maxLeP = lep;
    this.heldin.basiswerte.maxAsP = asp;
    this.heldin.basiswerte.maxSk = sk;
    this.heldin.basiswerte.maxZk = zk;
    this.heldin.basiswerte.maxAw = aw;
    this.heldin.basiswerte.maxIni = ini;
    this.heldin.basiswerte.maxGs = gs;
    this.heldin.basiswerte.maxSchips = schips;
  }

  public hasVuN(name: string): boolean {
    let hasVuN = false;
    this.$listOfChosenVuN.forEach(vun => {
      if (vun.name === name) {
        hasVuN = true;
      }
    });
    return hasVuN;
  }

  public getVuN(name: string): CharakterVuN {
    let wanted: CharakterVuN;
    this.$listOfChosenVuN.forEach(vun => {
      if (vun.name === name) {
        wanted = vun;
      }
    });
    return wanted;
  }

  public getEigenschaftWithName(name: string): Erschaffungsattribut {
    let attribut: Erschaffungsattribut;
    this.heldin.eigenschaften.forEach(attr => {
      if (attr.name === name || attr.langform === name) {
        attribut = attr;
      }
    });
    return attribut;
  }

  public updateStep0(): void {
    console.log(this.heldin.erfahrung.startAP);
    this.heldin.kostenProStep[0] = this.heldin.erfahrung.startAP;
    this.calculateGesamtKosten();
  }

  public updateStep1(): void {
    this.heldin.kostenProStep[1] = -1 * this.heldin.spezies.apWert;
    this.calculateGesamtKosten();
  }

  public updateStep2(event: Step2): void {
    if (event.haarfarbe !== undefined) {
      this.koerperattribute.haarfarbe = event.haarfarbe;
    }
    if (event.augenfarbe !== undefined) {
      this.koerperattribute.augenfarbe = event.augenfarbe;
    }
    if (event.groesse !== undefined) {
      this.koerperattribute.groesse = event.groesse;
    }
    if (event.gewicht !== undefined) {
      this.koerperattribute.gewicht = event.gewicht;
    }
    if (event.schamhaare !== undefined) {
      this.koerperattribute.schamhaare = event.schamhaare;
    }
    if (event.brueste !== undefined) {
      this.koerperattribute.brueste = event.brueste;
    }
    if (event.geschlechtsorgan !== undefined) {
      this.koerperattribute.geschlechtsorgan = event.geschlechtsorgan;
    }
  }

  public updateStep3(): void {
    const paketKosten: string = this.heldin.kultur.paketKosten;
    console.log(this.heldin.kultur.paketKosten);
    let array: string[] = paketKosten.split('(');
    console.log(array);
    array = array[1].split(')');
    console.log(array);
    array = array[0].split(' ');
    console.log(array);
    const kosten: number = Number(array[0]);
    console.log('Paketkosten: ' + kosten);
    this.heldin.kostenProStep[3] = -1 * kosten;
    this.calculateGesamtKosten();
  }

  public updateStep4(): void {
    this.calculateGesamtKosten();
  }

  public updateStep5(event: Step5): void {
    // console.log('updateStep5');
    this.$profession = event.profession;
    this.heldin.kostenProStep[5] = -1 * +this.$profession.paketKosten;
    this.calculateGesamtKosten();
  }

  public updateStep6(): void {
    this.calculateGesamtKosten();
  }

  public updateStep7(event: Step7): void {
    // console.log(event);
    // this.$ap = this.$apBackup - event.kosten;
    // this.$listOfChosenZauber = event.$listOfChosenZauber;
    // this.heldin.kostenProStep[7] = -1 * event.kosten + this.professionZauber;
    this.$tradition = event.$tradition;
    this.calculateGesamtKosten();
  }

  public updateStep8(): void {
    this.calculateGesamtKosten();
  }

  public updateStep9(event: Step9): void {
    // this.$alleTalente = event.$talente;
    // this.$charKampftechniken = event.$kampftechniken;
    this.heldin.kostenProStep[9] = -1 * event.$kosten;
    this.calculateGesamtKosten();
  }

  public alertParent(): void {
    const eventObject: NumberEvent = {
      $wert: 0
    };
    this.ping.emit(eventObject);
  }

  public resetHeldenerschaffung(): void {
    this.heldin = this.factory.getNewHeldenerschaffung();
    const $username = sessionStorage.getItem('user');
    this.heldin.spieler = $username;
  }

  public getAugenfarbe(): void {
    this.heldin.spezies.augenfarbe.forEach((str) => {
      const array: string[] = str.split(': ');
      const color: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.augenfarbeNumber) {
            this.heldin.augenfarbe = color;
            // this.$augenfarbeStr = color;
          }
        }
      } else {
        if (+array[1] === this.heldin.augenfarbeNumber) {
          this.heldin.augenfarbe = color;
          // this.$augenfarbeStr = color;
        }
      }
    });
  }

  public getHaarfarbe(): void {
    this.heldin.spezies.haarfarbe.forEach((str) => {
      const array: string[] = str.split(': ');
      const color: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.haarfarbeNumber) {
            this.heldin.haarfarbe = color;
            // this.$haarfarbeStr = color;
          }
        }
      } else {
        if (+array[1] === this.heldin.haarfarbeNumber) {
          this.heldin.haarfarbe = color;
          // this.$haarfarbeStr = color;
        }
      }
    });
  }

  public getKoerpergroesse(): void {
    this.heldin.spezies.koerpergroesse.forEach((str) => {
      const array: string[] = str.split(' + ');
      let size: number = +array[0];
      size += this.heldin.groesseNumber;
      this.heldin.groesse = size;
    });
    this.getGewicht();
  }

  public getGewicht(): void {
    if (this.heldin.groesseNumber !== undefined) {
      let size: number;
      this.heldin.spezies.koerpergroesse.forEach((strk) => {
        const array: string[] = strk.split(' + ');
        size = +array[0];
        size += this.heldin.groesseNumber;
      });
      this.heldin.spezies.gewicht.forEach((str) => {
        // console.log(str);
        const array: string[] = str.split(' ');
        let weight: number = size;
        // console.log('weight: ' + weight);
        weight -= +array[2];
        // console.log('weight: ' + weight);
        if (this.heldin.spezies.art === 'Zwerge') {
          weight += this.heldin.gewichtNumber;
        } else {
          if (array[3].includes('/')) {
            if (this.heldin.gewichtNumber % 2 === 0) {
              // console.log('gerade');
              weight += this.heldin.gewichtNumber;
            } else {
              // console.log('ungerade');
              weight -= this.heldin.gewichtNumber;
            }
          } else {
            if (array[3] === '+') {
              weight += this.heldin.gewichtNumber;
            } else {
              weight -= this.heldin.gewichtNumber;
            }
          }
        }
        this.heldin.gewicht = weight;
      });
    }
  }

  public getBrustgroesse(): void {
    this.heldin.spezies.brustgroesse.forEach((str) => {
      const array: string[] = str.split(': ');
      const brueste: string = array[0];
      let brustnummer = this.heldin.bruesteNumber;
      console.log(this.heldin.weiblich);
      if (this.heldin.weiblich) {
        brustnummer -= 12;
        if (brustnummer < 3) {
          brustnummer = 3;
        }
        console.log(brustnummer);
      }
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === brustnummer) {
            this.heldin.brueste = brueste;
          }
        }
      } else {
        if (+array[1] === brustnummer) {
          this.heldin.brueste = brueste;
        }
      }
    });
  }

  public getSchamhaare(): void {
    this.heldin.spezies.schamhaarmenge.forEach((str) => {
      const array: string[] = str.split(': ');
      const schamhaare: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.schamhaareNumber) {
            this.heldin.schamhaare = schamhaare;
          }
        }
      } else {
        if (+array[1] === this.heldin.schamhaareNumber) {
          this.heldin.schamhaare = schamhaare;
        }
      }
    });
  }

  public getGeschlechtsorgan(): void {
    let stringarray: string[];
    console.log(this.heldin.weiblich);
    if (this.heldin.weiblich === true) {
      console.log('weiblich');
      stringarray = this.heldin.spezies.vaginagroesse;
    } else {
      console.log('männlich');
      stringarray = this.heldin.spezies.penisgroesse;
    }
    stringarray.forEach((str) => {
      const array: string[] = str.split(': ');
      const geschlecht: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.genitalNumber) {
            this.heldin.genital = geschlecht;
            // this.$geschlechtsorganStr = geschlecht;
          }
        }
      } else {
        if (+array[1] === this.heldin.genitalNumber) {
          this.heldin.genital = geschlecht;
          // this.$geschlechtsorganStr = geschlecht;
        }
      }
    });
  }
}
