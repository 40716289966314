<div class="popup" (click)="activateTt(1, talent$.anwendungsgebiet)">
  <h3>{{ talent$.anwendungsgebiet }}</h3>
  <h4>Beschreibung</h4>
  {{ talent$.beschreibung }}
  <h4>Probenart</h4>
  {{ talent$.probenArt }}
  <h4>Handlungsmodifikatoren</h4>
  <div class="auflistung" *ngFor="let hmod of talent$.handlungsModifikator">
    {{ hmod }}
  </div>
</div>
