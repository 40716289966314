import {Waffe} from '../kampf/waffe';

export class Waffensammlung {
  waffen: Waffe[];

  constructor(waffen: Waffe[]) {
    this.waffen = waffen;
  }

  getWaffenDerKampftechnik(kt: string): Waffe[] {
    const gesucht: Waffe[] = [];
    this.waffen.forEach(waffe => {
      if (waffe.kampftechnik.toLowerCase() === kt.toLowerCase()) {
        const w: Waffe = new Waffe();
        w.copy(waffe);
        gesucht.push(w);
      }
    });
    return gesucht;
  }

  getFaeuste(): Waffe {
    const waffe: Waffe = new Waffe();
    this.waffen.forEach(w => {
      if (w.name.toLowerCase() === 'fäuste') {
        waffe.copy(w);
      }
    });
    return waffe;
  }
}
