import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../_interfaces/user';
import {DataService} from '../../_services/data.service';
import {EventTooltip} from '../../_interfaces/event-tooltip';
import {BoolEvent} from '../../_interfaces/bool-event';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {
  @Input() $userlist: User[];
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  public user$: User;
  public $error: number;
  public $errorPwd: boolean;
  public $errorPwd2: boolean;
  public $errorUser: boolean;
  public $errorUser2: boolean;

  constructor(public dataService: DataService) {
    this.user$ = {
      username: '',
      password: '',
      passwordWdh: ''
    };
    this.$error = 0;
    this.$errorPwd = false;
    this.$errorPwd2 = false;
    this.$errorUser = false;
    this.$errorUser2 = false;
  }

  public register(): void {
    this.$error = 0;
    if (this.user$.username === '') {
      this.$error ++;
      this.$errorUser = false;
      this.$errorUser2 = true;
    } else {
      this.$errorUser2 = false;
    }
    if (this.user$.password === '') {
      this.$error ++;
      this.$errorPwd = false;
      this.$errorPwd2 = true;
    } else {
      this.$errorPwd2 = false;
    }
    if (this.user$.password !== this.user$.passwordWdh) {
      this.$error ++;
      this.$errorPwd = true;
      this.$errorPwd2 = false;
    } else {
      this.$errorPwd = false;
    }
    if (this.$error === 0) {
      this.dataService.register(this.user$.username, this.user$.password).subscribe((data: boolean) => {
        // console.log('data: ' + data);
        if (!data) {
          this.$error ++;
          this.$errorUser = true;
        } else {

          const eventObject: BoolEvent = {
            value: true
          };
          this.ping.emit(eventObject);
        }
      });
    }
  }

  ngOnInit(): void {
  }

  public back(): void {
    const eventObject: BoolEvent = {
      value: true
    };
    this.ping.emit(eventObject);
  }

}
