<h1>Spezies wählen</h1>
<h2>{{heldin.spezies}}</h2>
<select [(ngModel)]="speziesIndex" (ngModelChange)="speziesChange()">
  <option [value]="speziesMenu.indexOf(spezies)" *ngFor="let spezies of speziesMenu">{{spezies.art}}: {{spezies.unterArt}}</option>
</select>
<h3>Modifikation der Eigenschaften durch Spezies</h3>
<div *ngFor="let nw of eigForce">
  <label>{{nw.name}}: {{nw.wert}}</label>
</div>
<div *ngFor="let nw of eigMods index as i">
  <label>{{nw.name}}: {{nw.wert}}</label>
  <input type="radio" name="eigMod" [value]="i" [(ngModel)]="eigModIndex" (ngModelChange)="attributMods()">
</div>
<h3>Augenfarbe und Haarfarbe erwürfeln</h3>
<div>
  <label>Augenfarbe (W20): </label>
  <input type="number" [(ngModel)]="wurfAuge" (ngModelChange)="waehleAugenfarbe()">
  <label>{{heldin.augenfarbe}}</label>
</div>
<div>
  <label>Haarfarbe (W20): </label>
  <input type="number" [(ngModel)]="wurfHaare" (ngModelChange)="waehleHaarfarbe()">
  <label>{{heldin.haarfarbe}}</label>
</div>
<div>
  <label>Größe {{wuerfelGroesse}}: </label>
  <input type="number" [(ngModel)]="wurfGroesse" (ngModelChange)="waehleGroesse()">
  <label>{{heldin.groesse}}</label>
</div>
<div>
  <label>Gewicht {{wuerfelGewicht}}: </label>
  <input type="number" [(ngModel)]="wurfGroesse" (ngModelChange)="waehleGewicht()">
  <label>{{heldin.gewicht}}</label>
</div>
<div>{{speziesMenu[speziesIndex].augenfarbe}}</div>
<div>{{speziesMenu[speziesIndex].haarfarbe}}</div>
<div>{{speziesMenu[speziesIndex].koerpergroesse}}</div>
<div>{{speziesMenu[speziesIndex].gewicht}}</div>
<div>{{speziesMenu[speziesIndex].brustgroesse}}</div>
<div>{{speziesMenu[speziesIndex].penisgroesse}}</div>
<div>{{speziesMenu[speziesIndex].vaginagroesse}}</div>
<div>{{speziesMenu[speziesIndex].schamhaarmenge}}</div>

<div><b>a</b>{{speziesMenu[speziesIndex].automatischeVorteile}}</div>
<div><b>d</b>{{speziesMenu[speziesIndex].dringendEmpfohleneVorteile}}</div>
<div><b>t</b>{{speziesMenu[speziesIndex].typischeVorteile}}</div>
<div><b>u</b>{{speziesMenu[speziesIndex].untypischeVorteile}}</div>

<div><b>a</b>{{speziesMenu[speziesIndex].automatischeNachteile}}</div>
<div><b>d</b>{{speziesMenu[speziesIndex].dringendEmpfohleneNachteile}}</div>
<div><b>t</b>{{speziesMenu[speziesIndex].typischeNachteile}}</div>
<div><b>u</b>{{speziesMenu[speziesIndex].untypischeNachteile}}</div>

