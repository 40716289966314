import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FileChangeEvent} from '@angular/compiler-cli/src/perform_watch';
import {DataService} from '../../_services/data.service';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {StringEvent} from '../../_interfaces/events/string-event';
import {Gruppenmitglied} from '../../_classes/abenteuer/gruppenmitglied';
import {NameWert} from "../../_classes/name-wert";
import {Schriftstueck} from "../../_classes/abenteuer/schriftstueck";

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.sass']
})
export class FileuploadComponent implements OnInit {

  @Output() imageSetter: EventEmitter<any> = new EventEmitter<any>();

  selectedFile = null;
  url: string | ArrayBuffer = '';
  image: string = null;
  name = '';

  material: NameWert[] = [];
  matIndex = 0;
  selectedMaterial: NameWert = new NameWert();

  schriften: Schriftstueck[] = [];
  schriftIndex = 0;
  selectedSchrift: Schriftstueck = new Schriftstueck();

  schriftName = '';
  schriftInhalt = '';
  schriftSprache = '';
  schriftSchrift = '';
  schriftTextart = '';


  constructor(public dataService: DataService,
              private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.getMaterial();
  }

  onFileChanged(event): void {
    if (event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      this.getBase64(file).then(data => {
        const stringEvent: StringEvent = {
          wert: data
        };
        this.image = data;
        this.imageSetter.emit(stringEvent);
      });
    }
  }

  getBase64(file): any {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  addMaterial(): void {
    const data: NameWert = new NameWert();
    data.name = this.name;
    data.wert = this.image + '';
    this.dataService.addMaterial(data).subscribe((returnValue: boolean) => {
      if (!returnValue) {
        console.error('Beim Speichern des Materials ist ein Fehler aufgetreten');
      }
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.image = null;
    this.name = '';
  }

  deleteMaterial(): void {
    this.dataService.deleteFromMaterial(this.selectedMaterial.name).subscribe((returnValue: boolean) => {
      if (!returnValue) {
        console.error('Beim Löschen des Materials ' + this.selectedMaterial.name + ' ist ein Fehler aufgetreten');
      }
      this.matIndex = 0;
      this.getMaterial();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getMaterial(): void {
    console.log('getMaterial()');
    this.dataService.getMaterial().subscribe((data: NameWert[]) => {
      this.material = data;
      console.log(data);
      if (data.length > 0) {
        this.selectMaterial();
      }
      console.log(this.material);
    });
  }

  selectMaterial(): void {
    this.selectedMaterial.copy(this.material[this.matIndex]);
    this.image = this.selectedMaterial.wert;
  }

  addSchriftstueck(): void {
    const data: Schriftstueck = new Schriftstueck();
    data.name = this.schriftName;
    data.inhalt = this.schriftInhalt;
    data.sprache = this.schriftSprache;
    data.schrift = this.schriftSchrift;
    data.textart = this.schriftTextart;
    this.dataService.addSchriftstueck(data).subscribe((returnValue: boolean) => {
      if (!returnValue) {
        console.error('Beim Speichern des Materials ist ein Fehler aufgetreten');
      }
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.schriftName = '';
    this.schriftInhalt = '';
    this.schriftSprache = '';
    this.schriftSchrift = '';
    this.schriftTextart = '';
  }

  deleteSchriftstueck(): void {
    this.dataService.deleteFromSchriftstueck(this.selectedSchrift.name).subscribe((returnValue: boolean) => {
      if (!returnValue) {
        console.error('Beim Löschen des Materials ' + this.selectedSchrift.name + ' ist ein Fehler aufgetreten');
      }
      this.schriftIndex = 0;
      this.getSchriftstueck();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getSchriftstueck(): void {
    console.log('getSchriftstuecke()');
    this.dataService.getSchriftstuecke().subscribe((data: Schriftstueck[]) => {
      this.schriften = data;
      console.log(data);
      if (data.length > 0) {
        this.selectSchriftstueck();
      }
      console.log(this.schriften);
    });
  }

  selectSchriftstueck(): void {
    this.selectedSchrift.copy(this.schriften[this.schriftIndex]);
    this.schriftName = this.selectedSchrift.name;
    this.schriftInhalt = this.selectedSchrift.inhalt;
    this.schriftSprache = this.selectedSchrift.sprache;
    this.schriftSchrift = this.selectedSchrift.schrift;
    this.schriftTextart = this.selectedSchrift.textart;
  }
}
