<h2 (click)="resetHeldenerschaffung()">von vorn Beginnen</h2>

<app-a-erfahrung [heldin]="heldin" [limits]="limits" (update)="updateLimits()"></app-a-erfahrung>

<app-b-spezies [heldin]="heldin" [limits]="limits" (update)="updateChar()"></app-b-spezies>

<h1>Kultur wählen</h1>
<h2>{{heldin.kultur}}</h2>

<h1>Eigenschaftspunkte verteilen</h1>

<h1>Profession wählen</h1>
<h2>{{heldin.profession}}</h2>

<h1>Vor- und Nachteile wählen</h1>

<h1>Steigerungen vornehmen</h1>

<h1>Kampftechniken berechnen</h1>

<h1>Sonderfertigkeiten aussuchen</h1>

<h1>Letzte Anpassungen vornehmen</h1>

<h1>Basiswerte berechnen</h1>

<h1>Ausrüstung einkaufen</h1>

<h1>Startalter festlegen</h1>
<h2>{{heldin.alter}}</h2>

<h1>Namen festlegen</h1>
<h2>{{heldin.name}}</h2>

<div *ngFor="let bf of heldin.beeinflussteWerte">{{bf.modifiziertes}}: {{bf.modName}} ({{bf.modWert}})</div>
