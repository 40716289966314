<div class="form">
  <input type="button" class="button" value="zurück" (click)="back()">
  <input type="text" [(ngModel)]="user$.username" placeholder="Username" required="true">
  <input type="password" [(ngModel)]="user$.password" placeholder="Passwort" required="true">
  <input type="password" [(ngModel)]="user$.passwordWdh" placeholder="Passwort wdh." required="true">
  <input type="submit" class="button" value="anmelden" (click)="register()">
  <div class="error">
    <div *ngIf="$errorPwd" class="pwd">Passwörter stimmen nicht überein!</div>
    <div *ngIf="$errorPwd2" class="pwd">Passwort darf nicht leer sein!</div>
    <div *ngIf="$errorUser" class="user">
      User existiert bereits!<br/>
      Bereits registrierte User:
      <div class="user" *ngFor="let user of $userlist">
        {{ user.username }}
      </div>

    </div>
    <div *ngIf="$errorUser2" class="user">User darf nicht leer sein!</div>
  </div>
</div>
