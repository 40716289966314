export class Gvorundnachteil {
  name: string;
  beschreibung: string;
  regel: string;
  apKosten: number;
  stufen: number;
  maxAnzahl: number;
  kategorie: string;
  stufeKosten: Map<string, number>;
  voraussetzungen: string[];
  vorteil: boolean;
  spezNoetig: boolean;
  art: string;

  public copy(other: Gvorundnachteil): void {
    this.name = other.name;
    this.beschreibung = other.beschreibung;
    this.regel = other.regel;
    this.apKosten = other.apKosten;
    this.stufen = other.stufen;
    this.maxAnzahl = other.maxAnzahl;
    this.kategorie = other.kategorie;
    this.stufeKosten = other.stufeKosten;
    this.voraussetzungen = other.voraussetzungen;
    this.vorteil = other.vorteil;
    this.spezNoetig = other.spezNoetig;
    this.art = other.art;
  }
}
