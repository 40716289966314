import {Allgemein} from './allgemein';

export class Alkohol extends Allgemein{
  public minQualiTaverne: number;
  public maxQualiTaverne: number;
  public preisProMass: number;
  public verkaufsEinheit: string;
  public verkaufsPreis: number;
  public wirkungNeg: string;
  public wirkungPos: string;
  public regionen: string;

  public einheitenUebrig: number;

  public getWirkung(pos: boolean): string {
    if (pos) {
      return this.getWirkungString(this.wirkungPos);
    } else {
      return this.getWirkungString(this.wirkungNeg);
    }
  }

  private getWirkungString(raw: string): string {
    let wirkung = '';
    const split: string[] = raw.split(', ');
    for (let i = 0; i < split.length; i++) {
      const modSplit: string[] = split[i].split(' ');
      const anz: number = Number(modSplit[0]);
      let mod = String(anz) + ' ';
      if (anz > 1) {
        mod += ' Stufen ' + modSplit[1];
      } else {
        mod += ' Stufe ' + modSplit[1];
      }
      wirkung += mod;
      if (i === split.length - 2) {
        wirkung += ' und ';
      } else if (i < split.length - 2) {
        wirkung += ', ';
      }
    }
    return wirkung;
  }

  public copy(other: Alkohol): void {
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
    this.beschreibung = other.beschreibung;
    this.zitat = other.zitat;
    this.preis = other.preis;
    this.gewicht = other.gewicht;
    this.komplexitaet = other.komplexitaet;
    this.anzahl = other.anzahl;
    this.strukturpunkte = other.strukturpunkte;
    this.regel = other.regel;
    this.minQualiTaverne = other.minQualiTaverne;
    this.maxQualiTaverne = other.maxQualiTaverne;
    this.preisProMass = other.preisProMass;
    this.verkaufsEinheit = other.verkaufsEinheit;
    this.verkaufsPreis = other.verkaufsPreis;
    this.wirkungNeg = other.wirkungNeg;
    this.wirkungPos = other.wirkungPos;
    this.regionen = other.regionen;
    this.einheitenUebrig = other.einheitenUebrig;
  }
}
