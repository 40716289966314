import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Talentwert} from '../../../_interfaces/talentwert';
import {SteigerungstabelleService} from '../../../_services/steigerungstabelle.service';
import {DataService} from '../../../_services/data.service';
import {Talent} from '../../../_interfaces/talent';
import {Step9} from '../../../_interfaces/charaktererstellung/step9';
import {Kampftechnik} from '../../../_interfaces/kampftechnik';
import {Erschaffungsattribut} from '../../../_interfaces/erschaffungsattribut';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';

@Component({
  selector: 'app-steigerungen',
  templateUrl: './steigerungen.component.html',
  styleUrls: ['./steigerungen.component.sass']
})
export class SteigerungenComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() heldin: Heldenerschaffung;
  @Input() $talentInfos: Talent[];
  public $kostenSumme = 0;

  public $currentTalentInfo: Talent;
  public $currentKampftechnikInfo: Kampftechnik;

  // public $optionaleKtListe: string[];
  // public $optionaleKtListeAktiv: string[];
  // public $optionaleKtAnz: number;
  // public $optionaleKtWert: number;

  // public talenteAusProfession: string[];
  // public talenteFWAusProfession: number[];
  // public kampftechnikenAusProfession: string[];
  // public kampftechnikenFWAusProfession: number[];

  constructor(public dataService: DataService, public steigerung: SteigerungstabelleService) {
    this.resetCurrentTalentInfo();
    this.resetCurrentKampftechnikInfo();
    // this.$optionaleKtListe = [];
    // this.$optionaleKtListeAktiv = [];
  }

  ngOnInit(): void {
    if (this.heldin.talentwerte.length === 0) {
      console.log('initialisiere Talente');
      this.createTalentwerte();
      this.readDataFromProfession();
    } else {
      this.readDataFromProfession();
      // const profTalente: Talentwert[] = this.getDataFromProfession();
      /*this.heldin.talentwerte.forEach(talent => {
        let differenz = 0;
        profTalente.forEach(pt => {
          if (pt.name === talent.name) {
            differenz = talent.maxFW - pt.maxFW;
          }
        });
        if (differenz > 0) {
          talent.maxFW -= differenz;
          for (let i = 0; i < differenz; i++) {
            this.increaseTalent(talent);
          }
        } else if (differenz < 0) {
          talent.maxFW += differenz;
          for (let i = 0; i > differenz; i--) {
            this.decreaseTalent(talent);
          }
        }
      });*/
    }
    this.resetKampftechnikStartwerte();
    this.getOptionaleKampftechniken();
    this.initialCalculate();
    this.alertParent();
  }

  public herausragend(name: string): number {
    let applies = 0;
    this.heldin.vorUndNachteile.forEach(vun => {
      if (vun.name.includes('Herausragende ')) {
        if (vun.kathegorie === name) {
          applies++;
        }
      }
    });
    return applies;
  }

  public resetCurrentTalentInfo(): void {
    this.$currentTalentInfo = {
      name: '',
      talentArt: '',
      einsatzmoeglichkeiten: [],
      probe: [],
      beschreibung: '',
      anwendungsgebiete: [],
      belastung: '',
      werkzeuge: [],
      qualitaet: '',
      misslungeneProbe: '',
      kritischerErfolg: '',
      patzer: '',
      steigerungskosten: ''
    };
  }

  public resetCurrentKampftechnikInfo(): void {
    this.$currentKampftechnikInfo = {
      nahkampf: true,
      name: '',
      beschreibung: '',
      besonderheiten: '',
      leiteigenschaften: [],
      steigerungsfaktor: '',
      wert: 0,
      startwert: 0,
      atWert: 0,
      paWert: 0
    };
  }

  public resetKampftechnikStartwerte(): void {
    this.heldin.kampftechnikenAusProfession = [];
    this.heldin.kampftechniken.forEach(kt => {
      kt.startwert = 0;
      const profKt: Kampftechnik = Object.assign({}, kt);
      profKt.startwert = 0;
      profKt.wert = 0;
      this.heldin.kampftechnikenAusProfession.push(profKt);
    });

  }

  public readDataFromProfession(): void {
    console.log('readDataFromProfession');
    // this.talenteAusProfession = [];
    // this.talenteFWAusProfession = [];
    this.heldin.profession.talente.forEach(talentString => {
      const array = talentString.split(' ');
      let talent: string = array[0];
      for (let i = 1; i < array.length - 1; i++) {
        talent += ' ' + array[i];
      }
      const fw = Number(array[array.length - 1]);
      this.heldin.talentwerteAusProfession.forEach(pt => {
        if (pt.name === talent) {
          pt.maxFW = fw;
        }
      });
    });

    console.log(this.heldin.talentwerteAusProfession);
    console.log(this.heldin.talentwerte);
    /*this.heldin.talentwerte.forEach(t => {
      let isProfTalent = false;
      for (let i = 0; i < this.talenteAusProfession.length; i++) {
        if (t.name === this.talenteAusProfession[i]) {
          isProfTalent = true;
          const fw: number = this.talenteFWAusProfession[i];
          if (t.maxFW > fw) {
            const diff = t.maxFW - fw;
            t.maxFW = fw;
          } else if (t.maxFW < fw){
            const diff = fw - t.maxFW;
            t.maxFW = fw;
          }
        }
      }

    });*/
    this.getOptionaleKampftechniken();
    this.alertParent();
  }

  public getOptionaleKampftechniken(): void {
    // this.$optionaleKtListe = [];

    // this.kampftechnikenAusProfession = [];
    // this.kampftechnikenFWAusProfession = [];
    this.heldin.profession.kampftechniken.forEach(ktstring => {
      if (ktstring.includes(',')) {
        /*const array = ktstring.split(',');
        this.$optionaleKtAnz = +array[0];
        this.$optionaleKtWert = +array[array.length - 1] - 6;
        for (let i = 1; i < array.length - 1; i++) {
          this.$optionaleKtListe.push(array[i] + ' ' + array[array.length - 1]);
        }*/
      } else {
        const array = ktstring.split(' ');
        let ktName: string = array[0];
        for (let i = 1; i < array.length - 1; i++) {
          ktName += ' ' + array[i];
        }
        const fw: number = +array[array.length - 1];
        this.heldin.kampftechnikenAusProfession.forEach(pkt => {
          if (pkt.name === ktName) {
            console.log(ktName + ' === ' + pkt.name);
            pkt.wert = fw - 6;
          }
        });
        // this.kampftechnikenAusProfession.push(ktName);
        // this.kampftechnikenFWAusProfession.push(fw);
      }
    });
    /*this.heldin.kampftechniken.forEach(kt => {
      for (let i = 0; i < this.kampftechnikenAusProfession.length; i++) {
        if (kt.name === this.kampftechnikenAusProfession[i]) {
          const fw: number = this.kampftechnikenFWAusProfession[i];
          if (kt.wert > fw) {
            const diff = kt.wert - fw;
            kt.wert = fw;
          } else if (kt.wert < fw){
            const diff = fw - kt.wert;
            kt.wert = fw;
          }
        }
      }
    });*/

    // this.$optionaleKtListe.sort((a, b) => (a < b ? -1 : 1));
    this.calculateATandPA();
  }

  public getDataFromProfession(): Talentwert[] {
    console.log('readDataFromProfession');
    const profTalente: Talentwert[] = [];
    this.heldin.profession.talente.forEach(talentString => {
      const array = talentString.split(' ');
      let talent: string = array[0];
      for (let i = 1; i < array.length - 1; i++) {
        talent += ' ' + array[i];
      }
      const info: Talent = this.getTalentinfo(talent);
      const talentwert: Talentwert = {
        maxFW: Number(array[array.length - 1]),
        startWert: 0,
        name: info.name,
        art: info.talentArt,
        be: info.belastung,
        stg: info.steigerungskosten,
        routine: '',
        aktFW: 0,
        probe: info.probe,
        probeWerte: [8, 8, 8]
      };
      profTalente.push(talentwert);
    });
    return profTalente;
  }

  public calculateATandPA(): void {
    this.heldin.kampftechniken.forEach(kt => {
      this.calculateATandPAforKampffertigkeit(kt);
    });
  }

  public calculateATandPAforKampffertigkeit(kt: Kampftechnik): void {
    const profKtFw: number = this.getProfKampftechnikFW(kt.name);
    if (kt.nahkampf) {
      kt.atWert = kt.wert + this.getKtModOfEigenschaft(this.getEigenschaft('MU')) - -profKtFw;
      kt.paWert = Math.ceil((kt.wert - -profKtFw) / 2)
        + this.getKtModOfEigenschaft(this.getEigenschaft(kt.leiteigenschaften[0]));
    } else {
      kt.atWert = kt.wert + this.getKtModOfEigenschaft(this.getEigenschaft('FF')) - -profKtFw;
    }
  }

  public getEigenschaft(name: string): Erschaffungsattribut {
    let gesucht: Erschaffungsattribut;
    this.heldin.eigenschaften.forEach(eig => {
      if (eig.name === name) {
        gesucht = eig;
      }
    });
    return gesucht;
  }

  public getKtModOfEigenschaft(eigenschaft: Erschaffungsattribut): number {
    let mod = 0;
    const rest: number = eigenschaft.wert - 8;
    if (rest >= 0) {
      mod = rest % 3;
    } else {
      mod = -1;
    }
    return mod;
  }

  public initialCalculate(): void {
    this.$kostenSumme = 0;
    this.heldin.talentwerte.forEach(talent => {
      const fw = talent.maxFW;
      console.log(talent.name + ': ' + talent.maxFW);
      if (fw > 0) {
        talent.maxFW = 0;
        for (let i = 0; i < fw; i++) {
          this.increaseTalent(talent);
        }
      } else if (fw < 0) {
        talent.maxFW = 0;
        for (let i = 0; i > fw; i--) {
          this.decreaseTalent(talent);
        }
      }
    });

    // via chooseOptionaleKampftechnik()
    const tempArray: string[] = [];
    this.heldin.$optionaleKtListeAktiv.forEach(node => {
      this.heldin.$optionaleKtListe.push(node);
      tempArray.push(node);
    });
    this.heldin.$optionaleKtListeAktiv = [];
    tempArray.forEach(kt => {
      this.heldin.$optionaleKtAnz ++;
      this.chooseOptionaleKampftechnik(kt);
    });

    this.heldin.kampftechniken.forEach(kt => {
      const fw = kt.wert;
      if (fw > 6) {
        kt.wert = 6;
        for (let i = 6; i < fw; i++) {
          this.increaseKampffertigkeit(kt);
        }
      } else if (fw < 6) {
        kt.wert = 6;
        for (let i = 6; i > fw; i--) {
          this.decreaseKampffertigkeit(kt);
        }
      }
    });
  }

  public increaseTalent(talent: Talentwert): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    let maxFW = this.heldin.erfahrung.maxFertigkeit;
    maxFW = maxFW - -this.herausragend(talent.name);
    const profFW = this.getProfTalentFW(talent.name);
    console.log(profFW);
    console.log(talent.maxFW);
    const gesamtFW = talent.maxFW - -profFW;
    console.log(gesamtFW);
    if (gesamtFW < maxFW) {
      const neuerWert = gesamtFW - -1;
      const kosten = this.steigerung.getKosten(neuerWert, spalten.indexOf(talent.stg));
      this.$kostenSumme += kosten;
      talent.maxFW = neuerWert - profFW;
    }
    console.log(this.heldin.talentwerte);
    this.alertParent();
  }

  public decreaseTalent(talent: Talentwert): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    const profFW = this.getProfTalentFW(talent.name);
    console.log(talent.maxFW + ' ' + profFW);
    if (talent.maxFW - -profFW > 0) {
      const neuerWert = talent.maxFW - 1 - -profFW;
      const kosten = this.steigerung.getKosten(talent.maxFW - -profFW, spalten.indexOf(talent.stg)) * -1;
      if (neuerWert >= 0) {
        this.$kostenSumme += kosten;
        talent.maxFW = neuerWert - profFW;
      }
    }
    this.alertParent();
  }

  public getTalentInfo(talent: Talentwert): void {
    if (this.$currentTalentInfo.name === talent.name) {
      this.resetCurrentTalentInfo();
      this.resetCurrentKampftechnikInfo();
    } else {
      this.$talentInfos.forEach(info => {
        if (info.name === talent.name) {
          this.$currentTalentInfo = info;
        }
      });
    }
  }

  public getKampftechnikInfo(kampftechnik: Kampftechnik): void {
    if (this.$currentKampftechnikInfo.name === kampftechnik.name) {
      this.resetCurrentKampftechnikInfo();
      this.resetCurrentTalentInfo();
    } else {
      this.heldin.kampftechniken.forEach(info => {
        if (info.name === kampftechnik.name) {
          this.$currentKampftechnikInfo = info;
        }
      });
    }
  }

  public increaseKampffertigkeit(kampffertigkeit: Kampftechnik): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    let maxFW = this.heldin.erfahrung.maxKampftechnik;
    maxFW = maxFW - -this.herausragend(kampffertigkeit.name);
    const profKtFw = this.getProfKampftechnikFW(kampffertigkeit.name);
    const wertSum: number = kampffertigkeit.wert - -profKtFw;
    if (wertSum < maxFW) {
      const neuerWert = wertSum + 1;
      const kosten = this.steigerung.getKosten(neuerWert, spalten.indexOf(kampffertigkeit.steigerungsfaktor));
      this.$kostenSumme += kosten;
      kampffertigkeit.wert = neuerWert - profKtFw;
      this.calculateATandPAforKampffertigkeit(kampffertigkeit);
    }
    this.alertParent();
  }

  public decreaseKampffertigkeit(kampffertigkeit: Kampftechnik): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    const profKtFw: number = this.getProfKampftechnikFW(kampffertigkeit.name);
    if (kampffertigkeit.wert - -profKtFw > 6) {
      const neuerWert = kampffertigkeit.wert - -profKtFw - 1;
      const kosten = this.steigerung.getKosten(kampffertigkeit.wert - -profKtFw, spalten.indexOf(kampffertigkeit.steigerungsfaktor)) * -1;
      if (neuerWert >= 0) {
        this.$kostenSumme += kosten;
        kampffertigkeit.wert = neuerWert - profKtFw;
        this.calculateATandPAforKampffertigkeit(kampffertigkeit);
      }
    }
    this.alertParent();
  }

  public chooseOptionaleKampftechnik(name: string): void {
    if (this.heldin.$optionaleKtAnz > 0) {
      const index = this.heldin.$optionaleKtListe.indexOf(name);
      const array = name.split(' ');
      let ktName = array[0];
      for (let i = 1; i < array.length - 1; i++) {
        ktName += ' ' + array[i];
      }
      console.log('name: ' + ktName);
      this.heldin.$optionaleKtListeAktiv.push(name);
      this.heldin.kampftechnikenAusProfession.forEach(kt => {
        if (kt.name === ktName) {
          kt.wert += this.heldin.$optionaleKtWert;
          this.calculateATandPAforKampffertigkeit(kt);
          this.heldin.$optionaleKtAnz --;
        }
      });
      for (let i = index; i < this.heldin.$optionaleKtListe.length - 1; i++) {
        this.heldin.$optionaleKtListe[i] = this.heldin.$optionaleKtListe[i - -1];
      }
      this.heldin.$optionaleKtListe.pop();
      this.heldin.$optionaleKtListeAktiv.sort((a, b) => (a < b ? -1 : 1));
    }
  }

  public unchooseOptionaleKampftechnik(name: string): void {
    const index = this.heldin.$optionaleKtListeAktiv.indexOf(name);
    const array = name.split(' ');
    let ktName = array[0];
    for (let i = 1; i < array.length - 1; i++) {
      ktName += ' ' + array[i];
    }
    this.heldin.$optionaleKtListe.push(name);
    this.heldin.kampftechnikenAusProfession.forEach(kt => {
      if (kt.name === ktName) {
        kt.wert -= this.heldin.$optionaleKtWert;
        this.calculateATandPAforKampffertigkeit(kt);
        this.heldin.$optionaleKtAnz ++;
      }
    });
    for (let i = index; i < this.heldin.$optionaleKtListeAktiv.length - 1; i++) {
      this.heldin.$optionaleKtListeAktiv[i] = this.heldin.$optionaleKtListeAktiv[i - -1];
    }
    this.heldin.$optionaleKtListeAktiv.pop();
    this.heldin.$optionaleKtListe.sort((a, b) => (a < b ? -1 : 1));
  }

  public alertParent(): void {
    const $alleTalente: Talentwert[] = [];
    const eventObject: Step9 = {
      $kosten: this.$kostenSumme,
      // $talente: $alleTalente,
      // $kampftechniken: this.$kampftechniken
    };
    this.ping.emit(eventObject);
  }

  public createTalentwerte(): void {
    this.heldin.talentwerte = [];
    this.heldin.talentwerteAusProfession = [];
    this.$talentInfos.forEach(info => {
      const talent: Talentwert = {
        maxFW: 0,
        startWert: 0,
        name: info.name,
        art: info.talentArt,
        be: info.belastung,
        stg: info.steigerungskosten,
        routine: '',
        aktFW: 0,
        probe: info.probe,
        probeWerte: [8, 8, 8]
      };
      this.heldin.talentwerte.push(Object.assign({}, talent));
      this.heldin.talentwerteAusProfession.push(Object.assign({}, talent));
    });
  }

  public getTalentinfo(name: string): Talent {
    let talent: Talent;
    this.$talentInfos.forEach(info => {
      if (info.name === name) {
        talent = info;
      }
    });
    return talent;
  }

  public getTalentwert(name: string): Talentwert {
    let wanted: Talentwert;
    this.heldin.talentwerte.forEach(talent => {
      if (talent.name === name) {
        wanted = Object.assign({}, talent);
        wanted.maxFW = 0;
        wanted.aktFW = 0;
      }
    });
    return wanted;
  }

  public getProfTalentFW(name: string): number {
    let fw = 0;
    this.heldin.talentwerteAusProfession.forEach(talent => {
      if (talent.name === name) {
        fw = talent.maxFW;
      }
    });
    return fw;
  }

  public getProfKampftechnikFW(name: string): number {
    let fw = 0;
    this.heldin.kampftechnikenAusProfession.forEach(kt => {
      if (kt.name === name) {
        fw = kt.wert;
      }
    });
    return fw;
  }
}
