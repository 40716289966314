import {Nahkampfwaffe} from './nahkampfwaffe';

export class Parierwaffe extends Nahkampfwaffe {
  passiverBonusPA: number;
  passiverBonusAT: number;
  parierTyp: string;
  schildgroesse: string;

  public copy(other: Parierwaffe): void {
    console.log('Parierwaffe.copy()');
    console.log(other);
    this.schildgroesse = other.schildgroesse;
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
    this.beschreibung = other.beschreibung;
    this.zitat = other.zitat;
    this.preis = other.preis;
    this.gewicht = other.gewicht;
    this.komplexitaet = other.komplexitaet;
    this.anzahl = other.anzahl;
    this.kampftechnik = other.kampftechnik;
    this.vorteil = other.vorteil;
    this.nachteil = other.nachteil;
    this.tp = other.tp;
    this.laenge = other.laenge;
    this.improvisiert = other.improvisiert;
    this.zweihand = other.zweihand;
    this.beschaedigung = other.beschaedigung;
    this.leiteigenschaft = other.leiteigenschaft;
    this.schadensschwelle = other.schadensschwelle;
    this.atMod = other.atMod;
    this.paMod = other.paMod;
    this.reichweite = other.reichweite;
    this.passiverBonusPA = other.passiverBonusPA;
    this.passiverBonusAT = other.passiverBonusAT;
    this.parierTyp = other.parierTyp;
    this.schildgroesse = other.schildgroesse;
  }
}
