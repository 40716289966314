<div class="flexVertikal">
  <div class="zeile">
    <h3 *ngIf="kampfrunde < 1">Der Kampf hat noch nicht begonnen</h3>
    <h3 *ngIf="kampfrunde > 0">Kampfrunde: {{kampfrunde}}</h3>
    <div class="flexVCenter">
      <button class="neueKR" (click)="neueKampfrunde()">nächste Kampfrunde</button>
    </div>
    <div class="flexVCenter">
      <button class="neueKR" (click)="resetKampf()">Kampf beenden</button>
    </div>
  </div>
  <div class="borderboxBLR">
    <div class="textArea" *ngIf="kampfPhase.startsWith('npcAT')">
      <b>Initiativaktion von {{anDerReihe}}.</b>
    </div>
    <div class="textArea" *ngIf="kampfPhase === 'npcAT'">
      Wähle wen {{anDerReihe}} angreifen soll:
    </div>

    <div class="buttonArea" *ngIf="kampfPhase === 'npcAT'">
      <button *ngFor="let gegner of gegnerInReichweite" class="subtileButton" (click)="waehleGegner(gegner)">{{gegner.name}}</button>
    </div>

    <div class="textArea" *ngIf="kampfPhase === 'npcATGewählt'">
      <div>Manueller Modifikator: <input class="invisible" type="number" [(ngModel)]="atpaMod" (ngModelChange)="berechneAtWerte()"></div>
      Wähle die Waffe zum Angriff aus:
    </div>
    <div *ngIf="anDerReiheKaempferin != null">
      <div class="buttonArea" *ngIf="!anDerReiheKaempferin.rechteHand.nahkampf && kampfPhase === 'npcATGewählt'">
        <div>Bewegung des Ziels</div>
        <select class="subtileButtonLong" [(ngModel)]="bewegZielIndex" (ngModelChange)="berechneAtWerte()">
          <option [value]="kampfservice.bewegungDesZiels.indexOf(bew)" *ngFor="let bew of kampfservice.bewegungDesZiels">{{ bew }}</option>
        </select>
      </div>
      <div class="buttonArea" *ngIf="!anDerReiheKaempferin.rechteHand.nahkampf && kampfPhase === 'npcATGewählt'">
        <div>Deine Bewegung</div>
        <select class="subtileButtonLong" [(ngModel)]="bewegSchuetzeIndex" (ngModelChange)="berechneAtWerte()">
          <option [value]="kampfservice.bewegungDesSchuetzen.indexOf(bew)" *ngFor="let bew of kampfservice.bewegungDesSchuetzen">{{ bew }}</option>
        </select>
      </div>
      <div class="buttonArea" *ngIf="!anDerReiheKaempferin.rechteHand.nahkampf && kampfPhase === 'npcATGewählt'">
        <div>Entfernung</div>
        <select class="subtileButtonLong" [(ngModel)]="fkRwErschwernis" (ngModelChange)="berechneAtWerte()">
          <option [value]="fkRwWert[i]" *ngFor="let rw of fkReichweiten index as i">{{ rw }}</option>
        </select>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'npcATGewählt'">
        <button class="subtileButtonLong" *ngIf="!anDerReiheKaempferin.rechteHand.zweihand" (click)="waehleWaffe(anDerReiheKaempferin.linkeHand, 'l')">{{anDerReiheKaempferin.linkeHand.name}} ({{atL}})</button>
        <button class="subtileButtonLong" (click)="waehleWaffe(anDerReiheKaempferin.rechteHand, 'r')">{{anDerReiheKaempferin.rechteHand.name}} ({{atR}})</button>
      </div>
    </div>
    <div class="textArea" *ngIf="kampfPhase === 'npcATWaffeGewählt'">
      {{shownMsg}}
    </div>
    <div class="buttonArea" *ngIf="kampfPhase === 'npcATWaffeGewählt'">
      <button class="subtileButtonLong" (click)="beendeAttacke()">weiter</button>
    </div>

    <div class="textArea" *ngIf="kampfPhase === 'passierschlagZone'">
      Bestimme die Trefferzone deines Passierschlags mit einem W20:
    </div>

    <div class="textArea" *ngIf="kampfPhase === 'veWahl'">
      <div>
        <b>{{verteidigerKaempfer.name}}</b> wird von <b>{{angreiferKaempfer.name}}</b> angegriffen!
        Dies wäre
        <span *ngIf="verteidigerKaempfer.weiblich">ihre </span>
        <span *ngIf="!verteidigerKaempfer.weiblich">seine </span>
        {{verteidigerKaempfer.verteidigungenBisher + 1}}. Verteidigung diese Runde.
        <div>Manueller Modifikator: <input class="invisible" type="number" [(ngModel)]="atpaMod" (ngModelChange)="berechneVeWerte()"></div>
      </div>
    </div>

    <div class="buttonArea" *ngIf="kampfPhase === 'veWahl'">
      <button class="subtileButtonLong" (click)="verteidigung(true, veL, false)" *ngIf="!verteidigerKaempfer.rechteHand.zweihand && veL > 0 && kampftechnikIsNahkampf">{{verteidigerKaempfer.linkeHand.name}} ({{veL}})</button>
      <button class="subtileButtonLong" (click)="verteidigung(false, veAw, false)">Ausweichen ({{veAw}})</button>
      <button class="subtileButtonLong" (click)="nichtVerteidigen()">nicht verteidigen</button>
      <button class="subtileButtonLong" (click)="verteidigung(true, veR, true)" *ngIf="veR > 0  && kampftechnikIsNahkampf">{{verteidigerKaempfer.rechteHand.name}} ({{veR}})</button>
    </div>

    <div class="textArea" *ngIf="kampfPhase === 'npcVEWaffeGewählt' || kampfPhase === 'passierschlag'">
      {{shownMsg}}
    </div>
    <div class="buttonArea" *ngIf="kampfPhase === 'npcVEWaffeGewählt'">
      <button class="subtileButtonLong" (click)="beendeVerteidigung()">weiter</button>
    </div>

    <div class="textArea" *ngIf="kampfPhase === 'tpErhalten'">
      {{schadenErhalter}} erhält Schaden in Zone "{{kaempferTrefferzone.get(schadenErhalter)}}"!
    </div>
    <div class="textArea" *ngIf="kampfPhase === 'tpErhalten' && angreiferIstNpc">
      Ermittle Schaden durch {{angreiferWaffeTp}}:
    </div>
    <div class="buttonArea" *ngIf="kampfPhase === 'tpErhalten' && !angreiferIstNpc">
      <input class="subtileButtonLong" type="number" [(ngModel)]="trefferpunkte">
    </div>
    <div class="buttonArea" *ngIf="kampfPhase === 'tpErhalten'">
      <button class="subtileButtonLong" (click)="schadenErmitteln()">weiter</button>
    </div>
    <div class="textArea" *ngIf="kampfPhase === 'tpErhaltenNachricht' && angreiferIstNpc">
      {{angreiferWurfString}}
    </div>
    <div class="textArea" *ngIf="kampfPhase === 'tpErhaltenNachricht'">
      {{shownMsg}}
    </div>
    <div class="buttonArea" *ngIf="kampfPhase === 'tpErhaltenNachricht'">
      <button class="subtileButtonLong" (click)="tpPhaseBeenden()">weiter</button>
    </div>


  </div>
  <div class="kampftabelle">
    <div class="zeile" id="headline">
      <div class="name">Name</div>
      <div class="team">Team</div>
      <div class="ini">INI</div>
      <div class="lep">LeP</div>
      <div class="akt">Akt.</div>
      <div class="fakt">f. Akt.</div>
      <div class="veBish">#VE</div>
      <div class="lakt">l. Akt</div>
      <div class="gruppe">Gruppe</div>
      <div class="waffenhand">Waffe</div>
      <div class="waffe">rechte / linke Hand </div>
      <div class="at">AT</div>
      <div class="pa">PA</div>
      <div class="aw">AW</div>
      <div class="tp">TP</div>
    </div>
    <div *ngFor="let kaempferin of kaempfer index as i">
      <div class="kaempfer" *ngIf="kaempferin.aktionenUebrig > 0">
        <div class="zeile" *ngIf="kaempferin.aktionenUebrig > 0">
          <div class="name" (click)="toggleKaempferinInfo(kaempferin.name)">{{kaempferin.name}}</div>
          <select class="team" [(ngModel)]="kaempferin.team" (ngModelChange)="updateKaempferin(kaempferin)">
            <option [value]="team" *ngFor="let team of [1, 2, 3, 4]">{{ team }}</option>
          </select>
          <div class="ini" (click)="decementIni(kaempferin)" (contextmenu)="incrementIni(kaempferin)">{{kaempferin.ini}}</div>
          <div class="lep" (click)="decementLep(kaempferin)" (contextmenu)="incrementLep(kaempferin)">{{kaempferin.lep}}</div>
          <button class="akt" (click)="decementAkt(kaempferin)" (contextmenu)="incrementAkt(kaempferin)">{{kaempferin.aktionenUebrig}}</button>
          <button class="fakt" (click)="decementFAkt(kaempferin)">{{kaempferin.freieAktionenUebrig}}</button>
          <button class="{{verteidigungsClass[i]}}" (click)="incrementVE(kaempferin, i, 0)">{{kaempferin.verteidigungenBisher}}</button>
          <input class="lakt" type="number" [(ngModel)]="kaempferin.laengerfristigeHandlung" (ngModelChange)="updateKaempferin(kaempferin)">
          <input class="gruppe" type="number" [(ngModel)]="kaempferin.gruppe" (ngModelChange)="changeGruppe(kaempferin)">
          <div class="waffenhand" *ngIf="!kaempferin.rechteHand.zweihand">rechts:</div>
          <div class="waffenhand" *ngIf="kaempferin.rechteHand.zweihand">beide:</div>
          <div class="waffe">{{kaempferin.rechteHand.name}}</div>
          <div class="at">{{kaempferin.rechteHand.at}}</div>
          <!--button class="pa" (click)="incrementVE(kaempferin, i, kaempferin.rechteHand.pa)">{{kaempferin.rechteHand.pa - 3 * kaempferin.verteidigungenBisher}}</button-->
          <!--button class="aw" (click)="incrementVE(kaempferin, i, kaempferin.aw)">{{kaempferin.aw - 3 * kaempferin.verteidigungenBisher}}</button-->
          <button class="pa">{{kaempferin.rechteHand.pa - 3 * kaempferin.verteidigungenBisher}}</button>
          <button class="aw">{{kaempferin.aw - 3 * kaempferin.verteidigungenBisher}}</button>
          <div class="tp">{{kaempferin.tpWaffeR}}</div>
          <img class="rmKaempferin" src="../../../../assets/images/button_s_neg.png" (click)="removeKaempferin(kaempferin)"/>
        </div>
        <div class="zeile">
          <div class="name"></div>
          <div class="team"></div>
          <div class="ini"></div>
          <div class="lep"></div>
          <div class="akt"></div>
          <div class="fakt"></div>
          <div class="veBish"></div>
          <div class="lakt"></div>
          <div class="gruppe"></div>
          <div class="waffenzeile" *ngIf="!kaempferin.rechteHand.zweihand">
            <div class="waffenhand">links:</div>
            <div class="waffe">{{kaempferin.linkeHand.name}}</div>
            <div class="at">{{kaempferin.linkeHand.at}}</div>
            <button class="pa" (click)="incrementVE(kaempferin, i, kaempferin.linkeHand.pa)">{{kaempferin.linkeHand.pa - 3 * kaempferin.verteidigungenBisher}}</button>
            <button class="aw" (click)="incrementVE(kaempferin, i, kaempferin.aw)">{{kaempferin.aw - 3 * kaempferin.verteidigungenBisher}}</button>
            <div class="tp">{{kaempferin.tpWaffeL}}</div>
          </div>
          <div class="waffenzeile" *ngIf="kaempferin.rechteHand.zweihand">
            <div class="waffenhand"></div>
            <div class="waffe"></div>
            <div class="at"></div>
            <div class="pa"></div>
            <div class="aw"></div>
            <div class="tp"></div>
          </div>
        </div>
        <!-- Infozeile -->
        <div class="zeile" *ngIf="kaempferInfo === kaempferin.name">
          <div class="zustand">
            <div class="zustandName">⛓️</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.belastung" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">💫</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.betaeubung" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">💀</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.furcht" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">🌀</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.paralyse" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">💥</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.schmerz" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">❓</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.verwirrung" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">GS</div>
            <div class="zustandWert">{{kaempferin.gs}}</div>
          </div>
        </div>
      </div>
      <div class="zeile" *ngIf="kaempferin.aktionenUebrig > 0">

      </div>
    </div>



    <div class="zeile">
      <h3>bereit für nächste Runde</h3>
    </div>
    <div *ngFor="let kaempferin of kaempfer index as i">
      <div class="kaempfer" *ngIf="kaempferin.aktionenUebrig < 1">
        <div class="zeile">
          <div class="name" (click)="toggleKaempferinInfo(kaempferin.name)">{{kaempferin.name}}</div>
          <select class="team" [(ngModel)]="kaempferin.team" (ngModelChange)="updateKaempferin(kaempferin)">
            <option [value]="team" *ngFor="let team of [1, 2, 3, 4]">{{ team }}</option>
          </select>
          <div class="ini" (click)="decementIni(kaempferin)" (contextmenu)="incrementIni(kaempferin)">{{kaempferin.ini}}</div>
          <div class="lep" (click)="decementLep(kaempferin)" (contextmenu)="incrementLep(kaempferin)">{{kaempferin.lep}}</div>
          <div class="akt" (contextmenu)="incrementAkt(kaempferin)">{{kaempferin.aktionenUebrig}}</div>
          <button class="fakt" (click)="decementFAkt(kaempferin)">{{kaempferin.freieAktionenUebrig}}</button>
          <button class="{{verteidigungsClass[i]}}" id="ve{{i}}" (click)="incrementVE(kaempferin, i, 0)">{{kaempferin.verteidigungenBisher}}</button>
          <input class="lakt" type="number" [(ngModel)]="kaempferin.laengerfristigeHandlung" (ngModelChange)="updateKaempferin(kaempferin)">
          <input class="gruppe" type="number" [(ngModel)]="kaempferin.gruppe" (ngModelChange)="changeGruppe(kaempferin)">
          <div class="waffenhand" *ngIf="!kaempferin.rechteHand.zweihand">rechts:</div>
          <div class="waffenhand" *ngIf="kaempferin.rechteHand.zweihand">beide:</div>
          <div class="waffe">{{kaempferin.rechteHand.name}}</div>
          <div class="at">{{kaempferin.rechteHand.at}}</div>
          <button class="pa" (click)="incrementVE(kaempferin, i, kaempferin.rechteHand.pa)">{{kaempferin.rechteHand.pa - 3 * kaempferin.verteidigungenBisher}}</button>
          <button class="aw" (click)="incrementVE(kaempferin, i, kaempferin.aw)">{{kaempferin.aw - 3 * kaempferin.verteidigungenBisher}}</button>
          <div class="tp">{{kaempferin.tpWaffeR}}</div>
          <img class="rmKaempferin" src="../../../../assets/images/button_s_neg.png" (click)="removeKaempferin(kaempferin)"/>
        </div>
        <div class="zeile">
          <div class="name"></div>
          <div class="team"></div>
          <div class="ini"></div>
          <div class="lep"></div>
          <div class="akt"></div>
          <div class="fakt"></div>
          <div class="veBish"></div>
          <div class="lakt"></div>
          <div class="gruppe"></div>
          <div class="waffenzeile" *ngIf="!kaempferin.rechteHand.zweihand">
            <div class="waffenhand">links:</div>
            <div class="waffe">{{kaempferin.linkeHand.name}}</div>
            <div class="at">{{kaempferin.linkeHand.at}}</div>
            <button class="pa" (click)="incrementVE(kaempferin, i, kaempferin.rechteHand.pa)">{{kaempferin.rechteHand.pa - 3 * kaempferin.verteidigungenBisher}}</button>
            <button class="aw" (click)="incrementVE(kaempferin, i, kaempferin.aw)">{{kaempferin.aw - 3 * kaempferin.verteidigungenBisher}}</button>
            <div class="tp">{{kaempferin.tpWaffeL}}</div>
          </div>
          <div class="waffenzeile" *ngIf="kaempferin.rechteHand.zweihand">
            <div class="waffenhand"></div>
            <div class="waffe"></div>
            <div class="at"></div>
            <div class="pa"></div>
            <div class="aw"></div>
            <div class="tp"></div>
          </div>
        </div>
        <!-- Infozeile -->
        <div class="zeile" *ngIf="kaempferInfo === kaempferin.name">
          <div class="zustand">
            <div class="zustandName">⛓️</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.belastung" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">💫</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.betaeubung" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">💀</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.furcht" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">🌀</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.paralyse" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">💥</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.schmerz" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">❓</div>
            <select class="zustandWert" [(ngModel)]="kaempferin.verwirrung" (ngModelChange)="changeZustand(kaempferin)">
              <option [value]="stufe" *ngFor="let stufe of [0, 1, 2, 3, 4]">{{ stufe }}</option>
            </select>
          </div>
          <div class="zustand">
            <div class="zustandName">GS</div>
            <div class="zustandWert">{{kaempferin.gs}}</div>
          </div>
          <div *ngFor="let rs of kaempferin.rsZonen index as i">
            <div class="rsPart">{{rs}}</div>
            <div class="rsPart">{{kaempferin.rs[i]}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h3>Kampflog</h3>
  <div class="log">
    <div class="text">
      <div class="msg"*ngFor="let zeile of aktuell">
        {{zeile}}
      </div>
    </div>
  </div>
  <!--div class="log">
    <div class="text">
      <div class="msg"*ngFor="let zeile of messages">
        {{zeile}}
      </div>
    </div>
  </div-->
  <!--div class="log">
    <div class="text">
      <div class="msg"*ngFor="let zeile of phasen">
        {{zeile}}
      </div>
    </div>
  </div-->
  <h3>NPC hinzufügen
    <img class="addKaempferin" src="../../../../assets/images/button_s_pos.png" (click)="updateKaempferin(neueKaempferin)"/>
  </h3>
  <div>zur Datenbank hinzufügen
    <img class="addKaempferin" src="../../../../assets/images/button_s_pos.png" (click)="addKaempferinToDB(neueKaempferin)"/>
  </div>
  <div class="zeile">
    <div class="name">Name</div>
    <div class="ztype">Zieltyp</div>
    <div class="zname">Zielgröße</div>
    <div class="ztype">Geschlecht</div>
    <div class="ini">INI</div>
    <div class="lep">LeP</div>
    <div class="maxAng">#AT</div>
    <div class="lep">AW</div>
    <div class="hand">rechte Hand:</div>
    <select class="waffe" [(ngModel)]="waffeIndexR" (ngModelChange)="getWaffeOfNeueKaempferin(true)">
      <option [value]="i" *ngFor="let waffe of alleWaffen index as i">{{ waffe.name }}</option>
    </select>
    <div>✊</div>
    <div *ngIf="neueKaempferin.rechteHand.zweihand || !neueKaempferin.rechteHand.nahkampf">✊</div>
  </div>
  <div class="zeile">
    <input class="name" type="text" [(ngModel)]="neueKaempferin.name">
    <select class="ztype" [(ngModel)]="neueKaempferin.zielName" (ngModelChange)="getGroessenOfZieltype()">
      <option [value]="zielName" *ngFor="let zielName of zielTypen">{{ zielName }}</option>
    </select>
    <select class="zname" [(ngModel)]="neueKaempferin.zielGroesse" (ngModelChange)="getZielZonenOfZiel()">
      <option [value]="zielGroesse" *ngFor="let zielGroesse of zielGroessen">{{ zielGroesse }}</option>
    </select>
    <select class="ztype" [(ngModel)]="neueKaempferin.weiblich">
      <option value="{{true}}">weiblich</option>
      <option value="{{false}}">männlich</option>
    </select>
    <input class="ini" type="number" [(ngModel)]="neueKaempferin.basisIni" (ngModelChange)="berechneWerteVonNeueKaempferin()">
    <input class="lep" type="number" [(ngModel)]="neueKaempferin.lepBasis" (ngModelChange)="berechneWerteVonNeueKaempferin()">
    <input class="maxAng" type="number" [(ngModel)]="neueKaempferin.maxAngriffe">
    <input class="lep" type="number" [(ngModel)]="neueKaempferin.aw">
    <div class="hand">linke Hand:</div>
    <select class="waffe" [(ngModel)]="waffeIndexL" (ngModelChange)="getWaffeOfNeueKaempferin(false)">
      <option [value]="i" *ngFor="let waffe of alleWaffen index as i">{{ waffe.name }}</option>
    </select>
    <div>✊</div>
    <div *ngIf="neueKaempferin.linkeHand.zweihand || !neueKaempferin.linkeHand.nahkampf">✊</div>
  </div>
  <div class="zeile">
    <div class="rsPart"><b>Rüstungswerte</b></div>
    <div class="rsPart" *ngFor="let zone of neueKaempferin.rsZonen">{{zone}}</div>
  </div>
  <div class="zeile">
    <div class="rsPart"></div>
    <input *ngFor="let rs of neueKaempferin.rs index as i" class="rsPart" type="number" [(ngModel)]="neueKaempferin.rs[i]">
  </div>
  <div class="zeile">
    <div class="lep" *ngFor="let eig of neueKaempferin.eigenschaften"><b>{{eig.name}}</b></div>
    <div class="lep">GSb</div>
    <div class="lep">GS</div>
  </div>
  <div class="zeile">
    <input class="lep" type="number" [(ngModel)]="neueKaempferin.eigenschaften[i].startwert" *ngFor="let eig of neueKaempferin.eigenschaften index as i" (ngModelChange)="berechneWerteVonNeueKaempferin()">
    <input class="lep" type="number" [(ngModel)]="neueKaempferin.gsBasis" (ngModelChange)="getWaffenwerteOfNeuerKaempferin()">
    <div class="lep">{{neueKaempferin.gs}}</div>
  </div>
  <div *ngFor="let kt of neueKaempferin.kampftechniken index as i" class="zeile">
    <div>{{kt.name}}: </div>
    <input class="lep" type="number" [(ngModel)]="neueKaempferin.kampftechniken[i].wert"  (ngModelChange)="getWaffenwerteOfNeuerKaempferin()">
    <img class="rmKaempferin" src="../../../../assets/images/button_s_neg.png" (click)="removeKampftechnik(kt)"/>

  </div>
  <div>
    {{neueKaempferin.rechteHand.name}}
    atMod: {{neueKaempferin.rechteHand.atMod}}
    paMod: {{neueKaempferin.rechteHand.paMod}}
    Schadenseigenschaft: {{neueKaempferin.rechteHand.schadenseig}}
    Schadensschwelle: {{neueKaempferin.rechteHand.schadensschwelle}}
    Schadensbonus: {{neueKaempferin.rechteHand.schandensb}}
    AT: {{neueKaempferin.rechteHand.at}}
    PA: {{neueKaempferin.rechteHand.pa}}
    TP: {{waffeTpR}}
  </div>
  <div>
    {{neueKaempferin.linkeHand.name}}
    atMod: {{neueKaempferin.linkeHand.atMod}}
    paMod: {{neueKaempferin.linkeHand.paMod}}
    Schadenseigenschaft: {{neueKaempferin.linkeHand.schadenseig}}
    Schadensschwelle: {{neueKaempferin.linkeHand.schadensschwelle}}
    Schadensbonus: {{neueKaempferin.linkeHand.schandensb}}
    AT: {{neueKaempferin.linkeHand.at}}
    PA: {{neueKaempferin.linkeHand.pa}}
    TP: {{waffeTpL}}
  </div>
  <h3>Lade KämpferIn</h3>
  <div *ngFor="let kaempferin of alleKaempfer">
    {{kaempferin.name}}
    <img class="addKaempferin" src="../../../../assets/images/button_s_pos.png" (click)="neueKaempferin.copy(kaempferin)"/>
  </div>
</div>


