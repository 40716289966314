<div class="verticalbody">
  <div class="table">
    <div class="header">
      <div class="spalte1">Talent</div>
      <div class="spalte2">Probe</div>
      <div class="spalte5">FW +/- Mod.</div>
      <div class="spalte6">R</div>
      <div class="spalte3">BE</div>
      <div class="vl"></div>
      <div class="spalte1">Talent</div>
      <div class="spalte2">Probe</div>
      <div class="spalte5">FW +/- Mod.</div>
      <div class="spalte6">R</div>
      <div class="spalte3">BE</div>
    </div>
    <div class="body">
      <div class="spalte">
        <div class="reihe">
          <div class="spalte1">Körpertalente</div>
          <div class="attribute" *ngIf="!$attributeK" (click)="changeAttributeView(0)">
            <div class="spalte2a">{{ getAttributeWert('MU') }}</div>
            <div class="spalte2b">{{ getAttributeWert('GE') }}</div>
            <div class="spalte2c">{{ getAttributeWert('KK') }}</div>
          </div>
          <div class="attribute" *ngIf="$attributeK" (click)="changeAttributeView(0)">
            <div class="spalte2a">MU</div>
            <div class="spalte2b">GE</div>
            <div class="spalte2c">KK</div>
          </div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
          <div class="vl"></div>
        </div>
        <div *ngFor="let talentItem of $talentItemsK">
          <app-talent-item [talentItem$]="talentItem" (ping)="getTalentInfo($event)" [charakter]="charakter"></app-talent-item>
          <div class="info" *ngIf="$currentTalentInfo.name === talentItem.name">
            <b>Beschreibung</b>
            {{$currentTalentInfo.beschreibung}}
            <b>Auswirkungen guter Proben</b>
            {{$currentTalentInfo.qualitaet}}
            <b>Kritischer Erfolg</b>
            {{$currentTalentInfo.kritischerErfolg}}
            <b>Auswirkungen misslungener Proben</b>
            {{$currentTalentInfo.misslungeneProbe}}
            <b>Patzer</b>
            {{$currentTalentInfo.patzer}}
            <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
            <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
            <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
            <div *ngFor="let anw of $currentTalentInfo.anwendungsgebiete" (click)="getTalentAnwendungInfo(anw)">
              {{anw}}
              <div class="unterinfo" *ngIf="$currentTalentAnwendungInfo.anwendungsgebiet === anw">
                <b>Beschreibung</b>
                {{$currentTalentAnwendungInfo.beschreibung}}
                <b>Probenart</b>
                {{$currentTalentAnwendungInfo.probenArt}}
                <b>Beispiel</b>
                {{$currentTalentAnwendungInfo.beispiel}}
                <label *ngIf="$currentTalentAnwendungInfo.handlungsModifikator.length > 0"><b>Handlungsmodifikatoren</b></label>
                <label *ngFor="let handlungsmod of $currentTalentAnwendungInfo.handlungsModifikator">{{handlungsmod}}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="reihe">
          <div class="spalte1"></div>
          <div class="spalte2"></div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
          <div class="vl"></div>
        </div>
        <div class="reihe">
          <div class="spalte1">Gesellschaftstalente</div>
          <div class="attribute" *ngIf="!$attributeG" (click)="changeAttributeView(1)">
            <div class="spalte2a">{{ getAttributeWert('IN') }}</div>
            <div class="spalte2b">{{ getAttributeWert('CH') }}</div>
            <div class="spalte2c">{{ getAttributeWert('CH') }}</div>
          </div>
          <div class="attribute" *ngIf="$attributeG" (click)="changeAttributeView(1)">
            <div class="spalte2a">IN</div>
            <div class="spalte2b">CH</div>
            <div class="spalte2c">CH</div>
          </div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
          <div class="vl"></div>
        </div>
        <div *ngFor="let talentItem of $talentItemsG">
          <app-talent-item [talentItem$]="talentItem" (ping)="getTalentInfo($event)" [charakter]="charakter"></app-talent-item>
          <div class="info" *ngIf="$currentTalentInfo.name === talentItem.name">
            <b>Beschreibung</b>
            {{$currentTalentInfo.beschreibung}}
            <b>Auswirkungen guter Proben</b>
            {{$currentTalentInfo.qualitaet}}
            <b>Kritischer Erfolg</b>
            {{$currentTalentInfo.kritischerErfolg}}
            <b>Auswirkungen misslungener Proben</b>
            {{$currentTalentInfo.misslungeneProbe}}
            <b>Patzer</b>
            {{$currentTalentInfo.patzer}}
            <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
            <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
            <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
            <div *ngFor="let anw of $currentTalentInfo.anwendungsgebiete" (click)="getTalentAnwendungInfo(anw)">
              {{anw}}
              <div class="unterinfo" *ngIf="$currentTalentAnwendungInfo.anwendungsgebiet === anw">
                <b>Beschreibung</b>
                {{$currentTalentAnwendungInfo.beschreibung}}
                <b>Probenart</b>
                {{$currentTalentAnwendungInfo.probenArt}}
                <b>Beispiel</b>
                {{$currentTalentAnwendungInfo.beispiel}}
                <label *ngIf="$currentTalentAnwendungInfo.handlungsModifikator.length > 0"><b>Handlungsmodifikatoren</b></label>
                <label *ngFor="let handlungsmod of $currentTalentAnwendungInfo.handlungsModifikator">{{handlungsmod}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="reihe">
          <div class="spalte1"></div>
          <div class="spalte2"></div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
          <div class="vl"></div>
        </div>
        <div class="reihe">
          <div class="spalte1">Natur-Talente</div>
          <div class="attribute" *ngIf="!$attributeN" (click)="changeAttributeView(2)">
            <div class="spalte2a">{{ getAttributeWert('MU') }}</div>
            <div class="spalte2b">{{ getAttributeWert('GE') }}</div>
            <div class="spalte2c">{{ getAttributeWert('KO') }}</div>
          </div>
          <div class="attribute" *ngIf="$attributeN" (click)="changeAttributeView(2)">
            <div class="spalte2a">MU</div>
            <div class="spalte2b">GE</div>
            <div class="spalte2c">KO</div>
          </div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
          <div class="vl"></div>
        </div>
        <div *ngFor="let talentItem of $talentItemsN">
          <app-talent-item [talentItem$]="talentItem" (ping)="getTalentInfo($event)" [charakter]="charakter"></app-talent-item>
          <div class="info" *ngIf="$currentTalentInfo.name === talentItem.name">
            <b>Beschreibung</b>
            {{$currentTalentInfo.beschreibung}}
            <b>Auswirkungen guter Proben</b>
            {{$currentTalentInfo.qualitaet}}
            <b>Kritischer Erfolg</b>
            {{$currentTalentInfo.kritischerErfolg}}
            <b>Auswirkungen misslungener Proben</b>
            {{$currentTalentInfo.misslungeneProbe}}
            <b>Patzer</b>
            {{$currentTalentInfo.patzer}}
            <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
            <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
            <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
            <div *ngFor="let anw of $currentTalentInfo.anwendungsgebiete" (click)="getTalentAnwendungInfo(anw)">
              {{anw}}
              <div class="unterinfo" *ngIf="$currentTalentAnwendungInfo.anwendungsgebiet === anw">
                <b>Beschreibung</b>
                {{$currentTalentAnwendungInfo.beschreibung}}
                <b>Probenart</b>
                {{$currentTalentAnwendungInfo.probenArt}}
                <b>Beispiel</b>
                {{$currentTalentAnwendungInfo.beispiel}}
                <label *ngIf="$currentTalentAnwendungInfo.handlungsModifikator.length > 0"><b>Handlungsmodifikatoren</b></label>
                <label *ngFor="let handlungsmod of $currentTalentAnwendungInfo.handlungsModifikator">{{handlungsmod}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="spalte">
        <div class="reihe">
          <div class="spalte1">Wissenstalente</div>
          <div class="attribute" *ngIf="!$attributeW" (click)="changeAttributeView(3)">
            <div class="spalte2a">{{ getAttributeWert('KL') }}</div>
            <div class="spalte2b">{{ getAttributeWert('KL') }}</div>
            <div class="spalte2c">{{ getAttributeWert('IN') }}</div>
          </div>
          <div class="attribute" *ngIf="$attributeW" (click)="changeAttributeView(3)">
            <div class="spalte2a">KL</div>
            <div class="spalte2b">KL</div>
            <div class="spalte2c">IN</div>
          </div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
        </div>
        <div *ngFor="let talentItem of $talentItemsW">
          <app-talent-item [talentItem$]="talentItem" (ping)="getTalentInfo($event)" [charakter]="charakter"></app-talent-item>
          <div class="info" *ngIf="$currentTalentInfo.name === talentItem.name">
            <b>Beschreibung</b>
            {{$currentTalentInfo.beschreibung}}
            <b>Auswirkungen guter Proben</b>
            {{$currentTalentInfo.qualitaet}}
            <b>Kritischer Erfolg</b>
            {{$currentTalentInfo.kritischerErfolg}}
            <b>Auswirkungen misslungener Proben</b>
            {{$currentTalentInfo.misslungeneProbe}}
            <b>Patzer</b>
            {{$currentTalentInfo.patzer}}
            <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
            <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
            <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
            <div *ngFor="let anw of $currentTalentInfo.anwendungsgebiete" (click)="getTalentAnwendungInfo(anw)">
              {{anw}}
              <div class="unterinfo" *ngIf="$currentTalentAnwendungInfo.anwendungsgebiet === anw">
                <b>Beschreibung</b>
                {{$currentTalentAnwendungInfo.beschreibung}}
                <b>Probenart</b>
                {{$currentTalentAnwendungInfo.probenArt}}
                <b>Beispiel</b>
                {{$currentTalentAnwendungInfo.beispiel}}
                <label *ngIf="$currentTalentAnwendungInfo.handlungsModifikator.length > 0"><b>Handlungsmodifikatoren</b></label>
                <label *ngFor="let handlungsmod of $currentTalentAnwendungInfo.handlungsModifikator">{{handlungsmod}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="reihe">
          <div class="spalte1"></div>
          <div class="spalte2"></div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
        </div>
        <div class="reihe">
          <div class="spalte1">Handwerkstalente</div>
          <div class="attribute" *ngIf="!$attributeH" (click)="changeAttributeView(4)">
            <div class="spalte2a">{{ getAttributeWert('FF') }}</div>
            <div class="spalte2b">{{ getAttributeWert('FF') }}</div>
            <div class="spalte2c">{{ getAttributeWert('KO') }}</div>
          </div>
          <div class="attribute" *ngIf="$attributeH" (click)="changeAttributeView(4)">
            <div class="spalte2a">FF</div>
            <div class="spalte2b">FF</div>
            <div class="spalte2c">KO</div>
          </div>
          <div class="spalte5"></div>
          <div class="spalte6"></div>
          <div class="spalte3"></div>
        </div>
        <div *ngFor="let talentItem of $talentItemsH">
          <app-talent-item [talentItem$]="talentItem" (ping)="getTalentInfo($event)" [charakter]="charakter"></app-talent-item>
          <div class="info" *ngIf="$currentTalentInfo.name === talentItem.name">
            <b>Beschreibung</b>
            {{$currentTalentInfo.beschreibung}}
            <b>Auswirkungen guter Proben</b>
            {{$currentTalentInfo.qualitaet}}
            <b>Kritischer Erfolg</b>
            {{$currentTalentInfo.kritischerErfolg}}
            <b>Auswirkungen misslungener Proben</b>
            {{$currentTalentInfo.misslungeneProbe}}
            <b>Patzer</b>
            {{$currentTalentInfo.patzer}}
            <label *ngIf="$currentTalentInfo.werkzeuge.length > 0"><b>Werkzeuge für den Talenteinsatz</b></label>
            <label *ngFor="let werkzeug of $currentTalentInfo.werkzeuge">{{werkzeug}}</label>
            <label *ngIf="$currentTalentInfo.anwendungsgebiete.length > 0"><b>Anwendungsgebiete</b></label>
            <div *ngFor="let anw of $currentTalentInfo.anwendungsgebiete" (click)="getTalentAnwendungInfo(anw)">
              {{anw}}
              <div class="unterinfo" *ngIf="$currentTalentAnwendungInfo.anwendungsgebiet === anw">
                <b>Beschreibung</b>
                {{$currentTalentAnwendungInfo.beschreibung}}
                <b>Probenart</b>
                {{$currentTalentAnwendungInfo.probenArt}}
                <b>Beispiel</b>
                {{$currentTalentAnwendungInfo.beispiel}}
                <label *ngIf="$currentTalentAnwendungInfo.handlungsModifikator.length > 0"><b>Handlungsmodifikatoren</b></label>
                <label *ngFor="let handlungsmod of $currentTalentAnwendungInfo.handlungsModifikator">{{handlungsmod}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="legende">FW = Fertigkeitswert, R = Routine, BE = Behinderung</div>
      </div>
    </div>
  </div>

</div>


