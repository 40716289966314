import { Component, OnInit } from '@angular/core';
import {Sonderfertigkeit} from '../../../_interfaces/sonderfertigkeit';
import {DataService} from '../../../_services/data.service';
import {Profession} from '../../../_interfaces/profession';
import {Item} from '../../../_interfaces/item';
import {ObjectFactoryService} from '../../../_services/object-factory.service';

@Component({
  selector: 'app-sonderfertigkeiten',
  templateUrl: './sonderfertigkeiten.component.html',
  styleUrls: ['./sonderfertigkeiten.component.sass']
})
export class SonderfertigkeitenComponent implements OnInit {
  // 0: Sonderfertigkeit
  // 1: allgemeineSF
  // 2: neuesAnwendungsgebiet
  // 3: Sprache
  public $message: string;

  public alle: Sonderfertigkeit[];
  public index: number;

  public $sonderfertigkeit: Sonderfertigkeit;

  // public $allgemeineSF: AllgemeineSF;
  public $allgemeineSFString: string;

  // public $neuesAnwendungsgebiet: NeuesAnwendungsgebiet;

  // public $sprache: Sprache;

  // public $kampfSF: KampfSF;
  public $kampfSFString: string;

  public $objektSFString: string;
  constructor(public dataService: DataService, private factory: ObjectFactoryService) {
    this.$message = '';
    this.getAlle();
    this.index = 0;
  }

  ngOnInit(): void {
  }

  public get(): void {
    this.$sonderfertigkeit = this.alle[this.index];
  }

  public getAlle(): void {
    this.dataService.getAlleSF().subscribe((data: Sonderfertigkeit[]) => {
      console.log(data);
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.alle = data;
      if (this.alle.length > 0) {
        this.get();
      }
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public convertAllgemeineSFString(): void {
    console.log('convertAllgemeineSFString');
    let raw = this.$allgemeineSFString;
    let stil = false;
    let $keyWords = [' Regel: ', ' Voraussetzung: ', ' AP-Wert: '];
    if (raw.includes(' Erweiterte Zaubersonderfertigkeiten: ')) {
      $keyWords = [' Regel: ', ' Erweiterte Zaubersonderfertigkeiten: ', ' Voraussetzung: ', ' AP-Wert: '];
      stil = true;
    } else if (raw.includes(' Erweiterte Zauberstilsonderfertigkeiten: ')) {
      $keyWords = [' Regel: ', ' Erweiterte Zauberstilsonderfertigkeiten: ', ' Voraussetzung: ', ' AP-Wert: '];
      stil = true;
    }
    if (raw.includes(' Voraussetzungen: ')) {
      $keyWords[$keyWords.indexOf(' Voraussetzung: ')] = ' Voraussetzungen: ';
    }

    const $infos: string[] = [];
    console.log(raw);
    $keyWords.forEach(key => {
      console.log('splitte bei: ' + key);
      const array = raw.split(key);
      console.log(array);
      $infos.push(array[0]);
      raw = array[1];
    });
    $infos.push(raw);
    console.log('Infos:');
    console.log($infos);
    $infos[3] = $infos[3].split(' Abenteuerpunkte')[0];
    this.$sonderfertigkeit.beschreibung = $infos[0];
    this.$sonderfertigkeit.regel = $infos[1];
    if (stil) {
      this.$sonderfertigkeit.erweiterteSF = $infos[2];
      this.$sonderfertigkeit.voraussetzungen = $infos[3];
      if ($infos[4].includes(' ')) {
        $infos[4] = $infos[4].split(' ')[0];
      }
      this.$sonderfertigkeit.apWert = +$infos[4];
    } else {
      this.$sonderfertigkeit.voraussetzungen = $infos[2];
      if ($infos[3].includes(' ')) {
        $infos[3] = $infos[3].split(' ')[0];
      }
      this.$sonderfertigkeit.apWert = +$infos[3];
    }
  }

  public convertKampfSFString(): void {
    console.log('convertKampfSFString');
    const rawArray = this.$kampfSFString.split(') ');
    const head = rawArray[0];
    const headArray = head.split(' (');
    const rawName = headArray[0];
    if (rawName.includes('-')) {
      const splittedName = rawName.split('-');
      let namePlusAnhang: string;
      let name = '';

      if (splittedName[1].includes('Stil')) {
        name = rawName;

      } else {

        const stufe = this.getStufeFromRoemisch(splittedName[splittedName.length - 1]);
        if (stufe > 1) {
          this.$sonderfertigkeit.stufe = stufe;
        }
        namePlusAnhang = splittedName[0];
        const nameArray = namePlusAnhang.split(' ');
        name = nameArray[0];
        for (let i = 1; i < nameArray.length - 1; i++) {
          name += ' ' + nameArray[i];
        }
      }
      this.$sonderfertigkeit.name = name;

    } else {
      this.$sonderfertigkeit.name = rawName;
    }


    this.$sonderfertigkeit.art = headArray[1];


    let raw = rawArray[1];
    for (let i = 2; i < rawArray.length; i++) {
      raw += ') ' + rawArray[i];
    }
    let $keyWords: string[] = [];
    let stil = false;
    $keyWords = [' Regel: ', ' Voraussetzung: ', ' Kampftechnik: ', ' AP-Wert: '];
    if (raw.includes(' Erweiterte Kampfsonderfertigkeiten: ')) {
      $keyWords = [' Regel: ', ' Erweiterte Kampfsonderfertigkeiten: ', ' Voraussetzung: ', ' Kampftechnik: ', ' AP-Wert: '];
      stil = true;
    }
    if (raw.includes(' Voraussetzungen: ')) {
      $keyWords[$keyWords.indexOf(' Voraussetzung: ')] = ' Voraussetzungen: ';
    }
    if (raw.includes(' Kampftechniken: ')) {
      $keyWords[$keyWords.indexOf(' Kampftechnik: ')] = ' Kampftechniken: ';
    }
    const $infos: string[] = [];
    console.log(raw);
    $keyWords.forEach(key => {
      console.log('splitte bei: ' + key);
      const array = raw.split(key);
      console.log(array);
      $infos.push(array[0]);
      raw = array[1];
    });
    $infos.push(raw);
    console.log('Infos:');
    console.log($infos);
    let apString = '';
    if (stil) {
      $infos[5] = $infos[5].split(' Abenteuerpunkte')[0];
      apString = $infos[5];
    } else {
      $infos[4] = $infos[4].split(' Abenteuerpunkte')[0];
      apString = $infos[4];
    }
    if (apString.includes('Stufe')) {
      const roemischString = apString.substring(6);
      console.log('roemischString: ' + roemischString);
      const rawstufenArray = roemischString.split(': ');
      console.log('rawstufenArray: ' + rawstufenArray);
      const roemischArray = rawstufenArray[0].split('/');
      console.log('roemischArray: ' + roemischArray);
      const kostenArray = rawstufenArray[1].split('/');
      console.log('kostenArray: ' + kostenArray);
      console.log('kostenArray[0]: ' + kostenArray[0]);
      this.$sonderfertigkeit.apWert = +kostenArray[0];
      for (let i = 0; i < roemischArray.length; i++) {
        const stufe = this.getStufeFromRoemisch(roemischArray[0]) - 1;
        const kosten: number = +kostenArray[i];
        this.$sonderfertigkeit.apJederStufe.push(kosten);
      }
      console.log(this.$sonderfertigkeit.apJederStufe);
    } else {
      if (stil) {
        this.$sonderfertigkeit.apWert = +$infos[5];
      } else {
        this.$sonderfertigkeit.apWert = +$infos[4];
      }
    }
    this.$sonderfertigkeit.beschreibung = $infos[0];
    this.$sonderfertigkeit.regel = $infos[1];
    if (stil) {
      this.$sonderfertigkeit.erweiterteSF = $infos[2];
      this.$sonderfertigkeit.voraussetzungen = $infos[3];
      this.$sonderfertigkeit.kampftechniken = $infos[4];
    } else {
      this.$sonderfertigkeit.voraussetzungen = $infos[2];
      this.$sonderfertigkeit.kampftechniken = $infos[3];
    }
  }

  public convertObjektZauberSFString(): void {
    console.log('convertObjektZauberSFString');
    let raw = this.$objektSFString;
    let objekt = true;
    let $keyWords = [' Wirkung: ', ' Voraussetzungen: ', ' Volumen: ', ' Bindungskosten: ', ' Merkmal: ', ' AP-Wert: '];
    if (!raw.includes(' Bindungskosten: ')) {
      $keyWords = [' Wirkung: ', ' Voraussetzungen: ', ' Volumen: ', ' AsP-Kosten: ', ' Merkmal: ', ' AP-Wert: '];
      objekt = false;
    }
    const $infos: string[] = [];
    console.log(raw);
    $keyWords.forEach(key => {
      console.log('splitte bei: ' + key);
      const array = raw.split(key);
      console.log(array);
      $infos.push(array[0]);
      raw = array[1];
    });
    $infos.push(raw);
    console.log('Infos:');
    console.log($infos);
    $infos[6] = $infos[6].split(' Abenteuerpunkte')[0];

    this.$sonderfertigkeit.name = $infos[0];
    this.$sonderfertigkeit.regel = $infos[1];
    this.$sonderfertigkeit.voraussetzungen = $infos[2];
    this.$sonderfertigkeit.volumenPunkte = +$infos[3].split(' Punkte')[0];
    if (objekt) {
      console.log('Objekt');
      this.$sonderfertigkeit.bindungskosten = +$infos[4].split(' permanente AsP')[0];
    } else {
      console.log('kein Objekt');
      this.$sonderfertigkeit.bindungskosten = 0;
      this.$sonderfertigkeit.aspKosten = $infos[4];
    }
    this.$sonderfertigkeit.merkmal = $infos[5];
    this.$sonderfertigkeit.apWert = +$infos[6];
  }

  public postSonderfertigkeit(): void {
    this.dataService.addSonderfertigkeitToDB(this.$sonderfertigkeit).subscribe((data: Sonderfertigkeit) => {
      this.$message = data.name + ' wurde hinzugefügt!';
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.resetAll();
  }

  public postAllgemeineSF(): void {
    console.log('füge allgemeineSF hinzu...');
    this.$sonderfertigkeit.name = this.$sonderfertigkeit.name.trim();
    this.$sonderfertigkeit.apWert = this.$sonderfertigkeit.apWert;
    this.$sonderfertigkeit.beschreibung = this.$sonderfertigkeit.beschreibung.trim();
    this.$sonderfertigkeit.voraussetzungen = this.$sonderfertigkeit.voraussetzungen.trim();
    this.$sonderfertigkeit.type = 'AllgemeineSF';
    this.dataService.addSonderfertigkeitToDB(this.$sonderfertigkeit).subscribe((data: Sonderfertigkeit) => {
      this.$message = data.name + ' wurde hinzugefügt!';
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.resetAll();
  }

  public postNeuesAnwGeb(): void {
    console.log('füge neuesAnwGeb hinzu...');
    this.$sonderfertigkeit.name = this.$sonderfertigkeit.name.trim();
    this.$sonderfertigkeit.apWert = this.$sonderfertigkeit.apWert;
    this.$sonderfertigkeit.beschreibung = this.$sonderfertigkeit.beschreibung.trim();
    this.$sonderfertigkeit.voraussetzungen = this.$sonderfertigkeit.voraussetzungen.trim();
    this.$sonderfertigkeit.type = 'NeuesAnwendungsgebiet';
    this.dataService.addSonderfertigkeitToDB(this.$sonderfertigkeit).subscribe((data: Sonderfertigkeit) => {
      this.$message = data.name + ' wurde hinzugefügt!';
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.resetAll();
  }

  public postSprache(): void {
    console.log('füge Sprache hinzu...');
    this.$sonderfertigkeit.name = this.$sonderfertigkeit.name.trim();
    this.$sonderfertigkeit.apWert = this.$sonderfertigkeit.apWert;
    this.$sonderfertigkeit.beschreibung = this.$sonderfertigkeit.beschreibung.trim();
    this.$sonderfertigkeit.voraussetzungen = this.$sonderfertigkeit.voraussetzungen.trim();
    this.$sonderfertigkeit.type = 'Sprache';
    this.dataService.addSonderfertigkeitToDB(this.$sonderfertigkeit).subscribe((data: Sonderfertigkeit) => {
      this.$message = data.name + ' wurde hinzugefügt!';
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.resetAll();
  }

  public postKampfSF(): void {
    console.log('füge Kampfsonderfertigkeit hinzu...');
    this.$sonderfertigkeit.name = this.$sonderfertigkeit.name.trim();
    this.$sonderfertigkeit.apWert = this.$sonderfertigkeit.apWert;
    this.$sonderfertigkeit.beschreibung = this.$sonderfertigkeit.beschreibung.trim();
    this.$sonderfertigkeit.voraussetzungen = this.$sonderfertigkeit.voraussetzungen.trim();
    this.$sonderfertigkeit.type = 'KampfSF';
    this.dataService.addSonderfertigkeitToDB(this.$sonderfertigkeit).subscribe((data: Sonderfertigkeit) => {
      this.$message = data.name + ' wurde hinzugefügt!';
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
    this.resetAll();
  }

  public resetAll(): void {
    this.$kampfSFString = '';
    this.$allgemeineSFString = '';
    this.$objektSFString = '';
    const sfType = this.$sonderfertigkeit.type;
    this.$sonderfertigkeit = this.factory.getNewSonderfertigkeit();
    this.$sonderfertigkeit.type = sfType;
  }

  public getStufeFromRoemisch(roemisch: string): number {
    const array = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
    return array.indexOf(roemisch) + 1;
  }
}
