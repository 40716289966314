import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VorUndNachteil} from '../../../_interfaces/vor-und-nachteil';
import {CharakterVuN} from '../../../_interfaces/charakter-vu-n';
import {Talentwert} from '../../../_interfaces/talentwert';
import {Talent} from '../../../_interfaces/talent';
import {Spezies} from '../../../_interfaces/spezies';
import {Profession} from '../../../_interfaces/profession';
import {DataService} from '../../../_services/data.service';
import {Zauber} from '../../../_interfaces/zauber';
import {Step1} from '../../../_interfaces/charaktererstellung/step1';
import {Step6} from '../../../_interfaces/charaktererstellung/step6';
import {Kampftechnik} from '../../../_interfaces/kampftechnik';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';

@Component({
  selector: 'app-vorundnachteil',
  templateUrl: './vorundnachteil.component.html',
  styleUrls: ['./vorundnachteil.component.sass']
})
export class VorundnachteilComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() heldin: Heldenerschaffung;

  public show = false;

  public methodLogging: boolean;

  public $kampftechniken: Kampftechnik[];

  public $stufenStrings = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  public $stgStrings = ['A', 'B', 'C', 'D', 'E'];

  public $giftStrings = ['Alkohol', 'Arax', 'Brabacudagift', 'Gelbschwanzskorpiongift', 'Ghulgift', 'Höhlenspinnengift', 'Hollbeerenbrechmittel', 'Kelmon', 'Krötengift', 'Kukris', 'Kvillottergift', 'Mandragora', 'Mantikorgift', 'Marbors Ruhe', 'Morfugift', 'Omrais', 'Rattenpilzgift', 'Seilschlangengift', 'Speikobragift', 'Sunsura', 'Tulmadron', 'Vogelspinnengift', 'Wurara'];
  public $giftStufen = [4, 3, 2, 3, 6, 4, 4, 2, 4, 4, 3, 2, 6, 6, 1, 4, 3, 3, 7, 3, 4, 2, 2];

  public $krankheitStrings = ['Aussatz', 'Blaue Keuche', 'Bleiche Sieche', 'Blutiger Rotz', 'Brabaker Schweiss', 'Dumpfschädel', 'Efferd- oder Kerkersieche', 'Flinker Difar', 'Gilbe', 'Jahresfieber', 'Lutanas', 'Rascher Wahn', 'Reisswasserseuche', 'Schlachtfeldfieber', 'Schwarze Wut', 'Sumpffieber', 'Tollwut', 'Wundfieber', 'Zorganpocken'];
  public $krankheitStufen = [5, 6, 6, 3, 3, 2, 2, 1, 2, 3, 3, 5, 5, 2, 3, 3, 6, 5, 5];

  public $alleTalente: Talentwert[];
  public $alleZauber: Zauber[];

  public $vorteile: VorUndNachteil[];
  public $nachteile: VorUndNachteil[];
  public $vorteilSwitch: boolean;
  public $chosenVuN: VorUndNachteil;
  public $vunIndex: number;
  // public $listOfChosenVuN: CharakterVuN[];
  public $autoGeneratedVuN: CharakterVuN[];
  public $charVuN: CharakterVuN;
  public $listStufen: string[];
  public $voraussetzungenErfuellt: boolean;
  public $listVoraussetzungen: string[];
  public $listVoraussetungenCheck: number[];
  public $vunSchonVorhanden: boolean;
  public $vunGegenteilVorhanden: boolean;
  public $oberKathegorie: string;

  public $kostenSumme: number;

  public $typischeVorteile: string[];
  public $untypischeVorteile: string[];
  public $typischeNachteile: string[];
  public $untypischeNachteile: string[];



  constructor(public dataService: DataService) {
    this.getAlleKampftechniken();
    this.$kostenSumme = 0;
    this.methodLogging = false;
    this.$vorteile = [];
    this.$nachteile = [];
    this.$autoGeneratedVuN = [];
    this.$vorteilSwitch = true;
    this.$vunIndex = 0;
    this.$listVoraussetzungen = [];
    this.$listVoraussetungenCheck = [];
    this.$vunSchonVorhanden = false;
    this.$vunGegenteilVorhanden = false;
    this.$oberKathegorie = 'Talent';

    this.$typischeVorteile = [];
    this.$untypischeVorteile = [];
    this.$typischeNachteile = [];
    this.$untypischeNachteile = [];
  }

  ngOnInit(): void {
    console.log(this.$kampftechniken);
    this.getAlleVorUndNachteile();
  }

  public getAlleKampftechniken(): void {
    this.$kampftechniken = [];
    this.dataService.getAlleKampftechniken().subscribe((data: Kampftechnik[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.$kampftechniken = data;
      console.log(data);
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public switchVuN(): void {
    if (this.methodLogging) {
      console.log('START: switchVuN()');
    }
    if (this.$vorteilSwitch) {
      this.$chosenVuN = this.$nachteile[0];
      this.resetCharVuN();
      this.$listStufen = [];
      for (let i = 0; i < this.$chosenVuN.stufen; i++) {
        this.$listStufen.push(this.$stufenStrings[i]);
      }
    } else {
      this.$chosenVuN = this.$vorteile[0];
      this.resetCharVuN();
      this.$listStufen = [];
      for (let i = 0; i < this.$chosenVuN.stufen; i++) {
        this.$listStufen.push(this.$stufenStrings[i]);
      }
    }
    this.calculateCharVuNKosten();
    this.$vorteilSwitch = !this.$vorteilSwitch;
    this.$vunIndex = 0;
    if (this.methodLogging) {
      console.log('END: switchVuN()');
    }
  }

  public getVuN(): void {
    if (this.methodLogging) {
      console.log('START: getVuN()');
    }
    if (this.$vorteilSwitch) {
      this.$chosenVuN = this.$vorteile[this.$vunIndex];
      this.resetCharVuN();
      this.$listStufen = [];
      for (let i = 0; i < this.$chosenVuN.stufen; i++) {
        this.$listStufen.push(this.$stufenStrings[i]);
      }
    } else {
      this.$chosenVuN = this.$nachteile[this.$vunIndex];
      this.resetCharVuN();
      this.$listStufen = [];
      for (let i = 0; i < this.$chosenVuN.stufen; i++) {
        this.$listStufen.push(this.$stufenStrings[i]);
      }
    }
    this.changeOberkathegorie();
    if (this.$chosenVuN.mehrfachKathegorie === 'Kampftechnik') {
      this.$charVuN.kathegorie = this.$kampftechniken[0].name;
    }
    this.calculateCharVuNKosten();
    if (this.methodLogging) {
      console.log('END: getVuN()');
    }
  }

  public calculateCharVuNKosten(): void {
    if (this.methodLogging) {
      console.log('START: calculateCharVuNKosten()');
    }
    let kosten = this.$chosenVuN.apKosten;
    // console.log('kosten: ' + kosten);
    const stufeStr = this.$charVuN.stufeString;
    if (stufeStr !== '') {
      const stufeNr = this.$charVuN.stufe;
      kosten *= stufeNr;
    }
    // console.log('kosten nach stufenmult.: ' + kosten);
    if ((this.$chosenVuN.mehrfachKathegorie === 'Talent' || this.$chosenVuN.mehrfachKathegorie === 'Fertigkeit') && this.$oberKathegorie === 'Talent') {
// Talent
      // console.log('Talent');
      const talent: Talentwert = this.getTalentwertOfString(this.$charVuN.kathegorie);
      if (talent != null) {
        // console.log('Steigerungskathegorie: ' + talent.stg);
        kosten *= (this.$stgStrings.indexOf(talent.stg) + 1);
      } else {
        // console.log('das Talent ' + this.$charVuN.kathegorie + ' scheint nicht zu existieren!');
      }
    } else if (this.$chosenVuN.mehrfachKathegorie === 'Gift') {
// Gift
      const index = this.$giftStrings.indexOf(this.$charVuN.notiz);
      kosten = Math.ceil(this.$giftStufen[index] / 2);
    } else if (this.$chosenVuN.mehrfachKathegorie === 'Krankheit') {
// Krankheit
      const index = this.$krankheitStrings.indexOf(this.$charVuN.notiz);
      kosten = Math.ceil(this.$krankheitStufen[index] / 2);
    }
    this.$charVuN.ap = kosten;
    // console.log('finales Kosten: ' + this.$charVuN.ap);
    if (this.methodLogging) {
      console.log('END: calculateCharVuNKosten()');
    }
  }

  public checkVuNVorhandenOderGegenteil(): void {
    if (this.methodLogging) {
      console.log('START: checkVuNVorhandenOderGegenteil()');
    }

    console.log(this.heldin.vorUndNachteile);

    this.$listVoraussetzungen = [];
    this.$listVoraussetungenCheck = [];
    if (this.$chosenVuN !== undefined) {
      const splitted = this.$chosenVuN.voraussetzungen.split(', ');
      splitted.forEach(str => {
        this.$listVoraussetzungen.push(str);
      });
      this.$listVoraussetzungen.forEach(vo => {
        this.$listVoraussetungenCheck.push(this.checkVoraussetung(vo));
      });
    }
    this.$vunSchonVorhanden = false;
    this.$vunGegenteilVorhanden = false;
    this.heldin.vorUndNachteile.forEach(vun => {
      if (vun.name + vun.kathegorie + vun.notiz === this.$chosenVuN.name + this.$chosenVuN.mehrfachKathegorie + this.$chosenVuN.userinput) {
        if (vun.kathegorie !== '') {
          if (this.$charVuN.kathegorie === vun.kathegorie) {
            this.$vunSchonVorhanden = true;
          }
        } else {
          this.$vunSchonVorhanden = true;
        }
      }
      if (vun.name === this.$chosenVuN.gegensatz) {
        if (vun.kathegorie !== '') {
          if (this.$charVuN.kathegorie === vun.kathegorie) {
            this.$vunGegenteilVorhanden = true;
          }
        } else {
          this.$vunGegenteilVorhanden = true;
        }
      }
    });

    if (this.$chosenVuN !== undefined) {
      this.calculateCharVuNKosten();
    } else {
      // console.log('$chosenVuN is undefined!');
    }
    if (this.methodLogging) {
      console.log('END: checkVuNVorhandenOderGegenteil()');
    }
  }

  public addVuN(): void {
    if (this.methodLogging) {
      console.log('START: addVuN()');
    }
    // Name (Mehrfach)* [notiz]* Stufe*   | * = optional; Stufe der Form I, II, ...
    let check = true;
    if (this.$vunSchonVorhanden) {
      if (!this.$chosenVuN.name.includes('Herausragende ')) {
        console.log('schon vorhanden');
        check = false;
      }
    }
    if (this.$vunGegenteilVorhanden) {
      console.log('gegenteil vorhanden');
      check = false;
    }
    this.$listVoraussetungenCheck.forEach(v => {
      if (v === -1) {
        console.log('voraussetzung nicht erfüllt');
        check = false;
      }
    });
    if (this.$chosenVuN.mehrfachKathegorie !== '') {
      if (this.$charVuN.kathegorie === '') {
        console.log('kathegorie leer');
        check = false;
      }
    }
    if (this.$chosenVuN.userinput && this.$charVuN.notiz === '') {
      console.log('notiz leer');
      check = false;
    }
    if (check) {
      let type = 0;
      this.$autoGeneratedVuN.forEach(vun => {
        if (this.$chosenVuN.name === vun.name) {

          if (this.$chosenVuN.mehrfachKathegorie !== '') {
            if (this.$charVuN.kathegorie === vun.kathegorie) {
              type = 1;
            }
          } else {
            type = 1;
          }
        }
      });
      let vunString = this.$chosenVuN.name;
      if (this.$chosenVuN.mehrfachKathegorie !== '') {
        vunString += ' (' + this.$charVuN.kathegorie + ')';
      }
      if (this.$chosenVuN.userinput) {
        vunString += ' [' + this.$charVuN.notiz + ']';
      }
      if (this.$chosenVuN.stufen > 1) {
        vunString += ' ' + this.$stufenStrings[this.$charVuN.stufe - 1];
      }
      let kosten = this.verarbeiteVuN(vunString, this.$chosenVuN.vorteil, type);
      if (!this.$chosenVuN.vorteil) {
        kosten *= -1;
      }
      this.checkVuNVorhandenOderGegenteil();
      this.sortListOfChosenVuN();
      this.resetCharVuN();
    }
    if (this.methodLogging) {
      console.log('END: addVuN()');
    }
    this.calculateGesamtKosten();
  }

  public removeVuN(vun: CharakterVuN): void {
    if (this.methodLogging) {
      console.log('START: removeVuN()');
    }
    let art = 'Nachteile';
    if (vun.vorteil) {
      art = 'Vorteile';
    }
    if (vun.type < 2) {
      const index = this.heldin.vorUndNachteile.indexOf(vun);
      for (let i = index; i < this.heldin.vorUndNachteile.length - 1; i++) {
        this.heldin.vorUndNachteile[i] = this.heldin.vorUndNachteile[i + 1];
      }
      this.heldin.vorUndNachteile.pop();
      // this.$ap += vun.ap;
      if (vun.type === 1) {
        window.alert('Dringend Empfohlende ' + art + ' sollten nur in Absprache mit dem Meister geändert oder entfernt werden!');
      }
    }
    this.checkVuNVorhandenOderGegenteil();
    this.resetCharVuN();
    if (this.methodLogging) {
      console.log('END: removeVuN()');
    }
    this.calculateGesamtKosten();
  }

  public removeVuNAuto(vun: CharakterVuN): void {
    if (this.methodLogging) {
      console.log('START: removeVuNAuto()');
    }
    let art = 'Nachteile';
    if (vun.vorteil) {
      art = 'Vorteile';
    }
    const index = this.heldin.vorUndNachteile.indexOf(vun);
    for (let i = index; i < this.heldin.vorUndNachteile.length - 1; i++) {
      this.heldin.vorUndNachteile[i] = this.heldin.vorUndNachteile[i + 1];
    }
    this.heldin.vorUndNachteile.pop();
    // this.checkVuNVorhandenOderGegenteil();
    // this.resetCharVuN();
    if (this.methodLogging) {
      console.log('END: removeVuNAuto()');
    }
    this.calculateGesamtKosten();
  }

  public resetCharVuN(): void {
    if (this.methodLogging) {
      console.log('START: resetCharVuN()');
    }
    this.$voraussetzungenErfuellt = false;
    this.$charVuN = {
      vorteil: true,
      name: '',
      stufe: 1,
      stufeString: 'I',
      kathegorie: '', // Kampftechniken, Talente, ...
      notiz: '',
      beschreibung: '',
      regel: '',
      ap: 0,
      type: 0
    };
    this.checkVuNVorhandenOderGegenteil();
    if (this.methodLogging) {
      console.log('END: resetCharVuN()');
    }
  }

  public getTalentwertOfString(str: string): Talentwert {
    if (this.methodLogging) {
      console.log('START: getTalentwertOfString()');
    }
    let talent: Talentwert = null;
    this.$alleTalente.forEach(t => {
      if (t.name === str) {
        talent = t;
      }
    });
    if (this.methodLogging) {
      console.log('END: getTalentwertOfString()');
    }
    return talent;
  }

  public sortListOfChosenVuN(): void {
    if (this.methodLogging) {
      console.log('START: sortListOfChosenVuN()');
    }
    this.heldin.vorUndNachteile.sort((a, b) => (
      a.name + a.kathegorie + a.notiz + a.stufeString < b.name + b.kathegorie + b.notiz + b.stufeString ? 1 : -1));
    this.heldin.vorUndNachteile.sort((a, b) => (a.vorteil < b.vorteil ? 1 : -1));
    if (this.methodLogging) {
      console.log('END: sortListOfChosenVuN()');
    }
  }


  // type = 0: manuell; 1: dringend empfohlen; 2: automatisch
  public verarbeiteVuN(vun: string, isvorteil: boolean, vunType: number): number {
    if (this.methodLogging) {
      console.log('START: verarbeiteVuN()');
    }
    // Name (Mehrfach)* [notiz]* Stufe*   | * = optional; Stufe der Form I, II, ...
    // console.log('verarbeiteVuN(' + vun + ')');
    let stufeStr = '';
    let stufeNr = 1;
    let kathegorieStr = '';
    let notizStr = '';
    // bestimme Stufe
    this.$stufenStrings.forEach(stf => {
      if (vun.endsWith(' ' + stf)) {
        stufeStr = stf;
        const splitted = vun.split(' ');
        vun = splitted[0];
        for (let i = 1; i < splitted.length - 1; i++) {
          vun += ' ' + splitted[i];
        }
      }
    });
    if (stufeStr !== '') {
      stufeNr = this.$stufenStrings.indexOf(stufeStr) + 1;
    }

    // bestimme Kathegorie
    if (vun.includes('(')) {
      kathegorieStr = vun.substring(vun.indexOf('(') + 1, vun.indexOf(')'));
      vun = vun.substring(0, vun.indexOf('(') - 1);
    }

    // bestimme Notiz
    if (vun.includes('[')) {
      notizStr = vun.substring(vun.indexOf('[') + 1, vun.indexOf(']'));
      vun = vun.substring(0, vun.indexOf('[') - 1);
    }
    console.log(notizStr);
    let vornachteil: VorUndNachteil;
    if (isvorteil) {
      // console.log('getVorteil(' + vun + ')');
      vornachteil = this.getVorteil(vun);
    } else {
      // console.log('getNachteil(' + vun + ')');
      vornachteil = this.getNachteil(vun);
    }
    let kosten = 0;
    if (vornachteil !== null) {
      const charVtl: CharakterVuN = {
        vorteil: isvorteil,
        name: vornachteil.name,
        stufe: this.$stufenStrings.indexOf(stufeStr) + 1,
        stufeString: stufeStr,
        kathegorie: kathegorieStr, // Kampftechniken, Talente, ...
        notiz: notizStr,
        beschreibung: vornachteil.beschreibung,
        regel: vornachteil.regel,
        ap: this.$charVuN.ap,
        type: vunType
      };
      charVtl.ap = this.getCharVuNKosten(vornachteil, charVtl);
      kosten = charVtl.ap;
      if (!this.schonVorhanden(charVtl)) {
        this.heldin.vorUndNachteile.push(charVtl);
        this.sortListOfChosenVuN();
      }
    }
    if (this.methodLogging) {
      console.log('END: verarbeiteVuN()');
    }
    return kosten;
  }

  public calculateGesamtKosten(): void {
    this.$kostenSumme = 0;
    // this.$listOfChosenVuN.forEach(vun => {
    this.heldin.vorUndNachteile.forEach(vun => {
      if (vun.type < 3) {
        this.$kostenSumme += vun.ap;
      }
    });

    this.heldin.kostenProStep[6] = -this.$kostenSumme;
    this.ping.emit();
  }

  public getCharVuNKosten(vorUndNach: VorUndNachteil, charVorUndNach: CharakterVuN): number {
    if (charVorUndNach.notiz === 'Tulmadron') {
      console.log(vorUndNach);
      console.log(charVorUndNach);
    }
    if (this.methodLogging) {
      console.log('START: getCharVuNKosten()');
    }
    let kosten = vorUndNach.apKosten;
    const stufeStr = charVorUndNach.stufeString;
    if (stufeStr !== '') {
      const stufeNr = charVorUndNach.stufe;
      kosten *= stufeNr;
    }
    if (vorUndNach.mehrfachKathegorie === 'Talent' || vorUndNach.mehrfachKathegorie === 'Fertigkeit') {
// Talent
      // console.log('Talent');
      const talent: Talentwert = this.getTalentwertOfString(charVorUndNach.kathegorie);
      if (talent != null) {
        // console.log('Steigerungskathegorie: ' + talent.stg);
        kosten *= (this.$stgStrings.indexOf(talent.stg) + 1);
      } else {
        // console.log('das Talent ' + charVorUndNach.kathegorie + ' scheint nicht zu existieren!');
      }
    } else if (vorUndNach.mehrfachKathegorie === 'Gift') {
// Gift
      console.log('checke Gift');
      const index = this.$giftStrings.indexOf(charVorUndNach.notiz);
      kosten = Math.ceil(this.$giftStufen[index] / 2);
    } else if (vorUndNach.mehrfachKathegorie === 'Krankheit') {
// Krankheit
      const index = this.$krankheitStrings.indexOf(charVorUndNach.notiz);
      kosten = Math.ceil(this.$krankheitStufen[index] / 2);
    }
    if (this.methodLogging) {
      console.log('END: getCharVuNKosten()');
    }
    return kosten;
  }

  public getVorteil(name: string): VorUndNachteil {
    if (this.methodLogging) {
      console.log('START: getVorteil()');
    }
    let vorteil: VorUndNachteil = null;
    this.$vorteile.forEach(vtl => {
      if (vtl.name === name) {
        vorteil = vtl;
      }
    });
    if (this.methodLogging) {
      console.log('END: getVorteil()');
    }
    return vorteil;
  }

  public getNachteil(name: string): VorUndNachteil {
    if (this.methodLogging) {
      console.log('START: getNachteil()');
    }
    let nachteil: VorUndNachteil = null;
    this.$nachteile.forEach(ntl => {
      if (ntl.name === name) {
        nachteil = ntl;
      }
    });
    if (this.methodLogging) {
      console.log('END: getNachteil()');
    }
    return nachteil;
  }

  public checkVoraussetung(vo: string): number {
    if (this.methodLogging) {
      console.log('START: checkVoraussetung()');
    }
    let checked = 0;
    // console.log('checkVoraussetung(' + vo + ')');
// Vorteil XY
    if (vo.startsWith('Vorteil')) {
      vo = vo.substr(8);
      this.heldin.vorUndNachteile.forEach(vun => {
        if (vun.name === vo) {
          checked = 1;
        }
      });
      if (checked === 0) {
        checked = -1;
        this.$vorteile.forEach(v => {
          if (v.name === vo) {
            checked = 0;
          }
        });
      }
    }
// kein Nachteil
    if (vo.startsWith('kein Nachteil') && vo !== 'kein Nachteil Unfähig auf das gleiche Talent') {
      vo = vo.substr(14);
      this.heldin.vorUndNachteile.forEach(vun => {
        if (vun.name === vo) {
          checked = -1;
        }
      });
      if (checked === 0) {
        this.$nachteile.forEach(v => {
          if (v.name === vo) {
            checked = 1;
          }
        });
      }
    }
// kein Vorteil
    if (vo.startsWith('kein Vorteil') && vo !== 'kein Vorteil Begabung auf das gleiche Talent') {
      vo = vo.substr(13);
      this.heldin.vorUndNachteile.forEach(vun => {
        if (vun.name === vo) {
          checked = -1;
        }
      });
      if (checked === 0) {
        this.$vorteile.forEach(v => {
          if (v.name === vo) {
            checked = 1;
          }
        });
      }
    }
// weiblich
    if (vo === 'weiblich') {
      if (this.heldin.weiblich) {
        checked = 1;
      } else {
        checked = -1;
      }
    }
// männlich
    if (vo === 'männlich') {
      if (this.heldin.weiblich) {
        checked = -1;
      } else {
        checked = 1;
      }
    }
// Spezies muss XY als automatischen oder ...
    if (vo.startsWith('Spezies muss')) {
      this.$autoGeneratedVuN.forEach(vun => {
        if (vun.name === this.$chosenVuN.name) {
          checked = 1;
        }
      });
    }
// maximal
    if (vo.startsWith('maximal')) {
      const splitted = vo.split(' ');
      let count: number = +splitted[1];

      // console.log('anzahl: ' + count);
      this.heldin.vorUndNachteile.forEach(vun => {
        // console.log(vun.name + ' === ' + this.$chosenVuN.name);
        if (vun.name === this.$chosenVuN.name) {
          count --;
        }
      });
      if (count <= 0) {
        checked = -1;
      } else {
        checked = 1;
      }
    }
// kein Vorteil Begabung auf das gleiche Talent
    if (vo === 'kein Vorteil Begabung auf das gleiche Talent') {
      checked = 1;
      this.heldin.vorUndNachteile.forEach(vun => {
        if (vun.name === 'Begabung') {
          if (vun.kathegorie === this.$charVuN.kathegorie) {
            checked = -1;
          }
        }
      });
    }
// kein Nachteil Unfähig auf das gleiche Talent
    if (vo === 'kein Nachteil Unfähig auf das gleiche Talent') {
      checked = 1;
      this.heldin.vorUndNachteile.forEach(vun => {
        if (vun.name === 'Unfähig') {
          if (vun.kathegorie === this.$charVuN.kathegorie) {
            checked = -1;
          }
        }
      });
    }
    if (this.methodLogging) {
      console.log('END: checkVoraussetung()');
    }
    return checked;
  }

  public getAlleVorUndNachteile(): void {
    if (this.methodLogging) {
      console.log('START: getAlleVorUndNachteile()');
    }
    this.$vorteile = [];
    this.$nachteile = [];
    this.dataService.getVorUndNachteile().subscribe((data: VorUndNachteil[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.$chosenVuN = data[0];
      this.resetCharVuN();
      this.$listStufen = [];
      for (let i = 0; i < data[0].stufen; i++) {
        this.$listStufen.push(this.$stufenStrings[i]);
      }
      data.forEach((vun) => {
        if (vun.vorteil) {
          this.$vorteile.push(vun);
        } else {
          this.$nachteile.push(vun);
        }
      });
      this.calculateCharVuNKosten();
      console.log(data);

      if (this.methodLogging) {
        console.log('END: getAlleVorUndNachteile()');
      }

      this.getTalentwerte();

    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getTalentwerte(): void {
    if (this.methodLogging) {
      console.log('START: getTalentwerte()');
    }
    this.$alleTalente = [];
    const $username = sessionStorage.getItem('user');
    this.dataService.getTalentwerteUser($username).subscribe((data: Talentwert[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.sort((a, b) => (a.art < b.art ? -1 : 1));
      this.$alleTalente = data;
      // --- copied from getAlleVorUndNachteile
      this.getAlleZauber();
      console.log(this.heldin.vorUndNachteile);
      this.getSpeziesUndProfessionsVuN();
      console.log(this.heldin.vorUndNachteile);
      // this.removeDoubleVun(); // error: removes existing VuN
      // console.log(this.heldin.vorUndNachteile);
      this.setZaubererIfMagisch();
      console.log(this.heldin.vorUndNachteile);
      this.show = true;
      // --- copy end
      this.getAutomatischeVuN();
      console.log(this.heldin.vorUndNachteile);

    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
    if (this.methodLogging) {
      console.log('END: getTalentwerte()');
    }
  }

  public getAlleZauber(): void {
    if (this.methodLogging) {
      console.log('START: getAlleZauber()');
    }
    this.$alleZauber = [];
    this.dataService.getAlleZauber().subscribe((data: Zauber[]) => {
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, true);
      });
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, false);
      });
      this.$alleZauber = data;
      // console.log(data);
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
    if (this.methodLogging) {
      console.log('END: getAlleZauber()');
    }
  }

  public replaceUmlaute(str: string, forward: boolean): string {
    if (this.methodLogging) {
      // console.log('START: replaceUmlaute()');
    }
    let guard = true;
    while (guard) {
      guard = false;
      if (forward) {
        if (str.includes('Ä')) {
          str = str.replace('Ä', 'Ae');
          guard = true;
        } else if (str.includes('Ö')) {
          str = str.replace('Ö', 'Oe');
          guard = true;
        } else if (str.includes('Ü')) {
          str = str.replace('Ü', 'Ue');
          guard = true;
        }
      } else {
        if (str.includes('Ae')) {
          str = str.replace('Ae', 'Ä');
          guard = true;
        } else if (str.includes('Oe')) {
          str = str.replace('Oe', 'Ö');
          guard = true;
        } else if (str.includes('Ue')) {
          str = str.replace('Ue', 'Ü');
          guard = true;
        }
      }
    }
    if (this.methodLogging) {
      // console.log('END: replaceUmlaute()');
    }
    return str;
  }

  public changeOberkathegorie(): void {
    if (this.methodLogging) {
      console.log('START: changeOberkathegorie()');
    }
    if (this.$oberKathegorie === 'Talent') {
      this.$charVuN.kathegorie = this.$alleTalente[0].name;
    } else if (this.$oberKathegorie === 'Zauber') {
      this.$charVuN.kathegorie = this.$alleZauber[0].name;
    } else if (this.$chosenVuN.mehrfachKathegorie === 'Kampftechnik') {
      this.$charVuN.kathegorie = this.$kampftechniken[0].name;
    }
    this.checkVuNVorhandenOderGegenteil();
    if (this.methodLogging) {
      console.log('END: changeOberkathegorie()');
    }
  }

  public getAutomatischeVuN(): void {
    if (this.methodLogging) {
      console.log('START: getAutomatischeVuN()');
    }
    // console.log(this.$spezies);
    // this.$listOfChosenVuN = [];
    if (this.heldin.spezies.automatischeVorteile !== undefined) {
      this.heldin.spezies.automatischeVorteile.forEach(vtl => {
        this.verarbeiteVuN(vtl, true, 2);
      });
    }
    if (this.heldin.spezies.dringendEmpfohleneVorteile !== undefined) {
      this.heldin.spezies.dringendEmpfohleneVorteile.forEach(vtl => {
        this.verarbeiteVuN(vtl, true, 1);
      });
    }
    if (this.heldin.spezies.automatischeNachteile !== undefined) {
      this.heldin.spezies.automatischeNachteile.forEach(ntl => {
        this.verarbeiteVuN(ntl, false, 2);
      });
    }
    if (this.heldin.spezies.dringendEmpfohleneNachteile !== undefined) {
      this.heldin.spezies.dringendEmpfohleneNachteile.forEach(ntl => {
        this.verarbeiteVuN(ntl, false, 1);
      });
    }
    this.sortListOfChosenVuN();
    this.heldin.vorUndNachteile.forEach(vun => {
      this.$autoGeneratedVuN.push(vun);
    });
    if (this.methodLogging) {
      console.log('END: getAutomatischeVuN()');
    }
    this.calculateGesamtKosten();
  }

  public getSpeziesUndProfessionsVuN(): void {
    const $vunToDelete: CharakterVuN[] = [];
    this.heldin.vorUndNachteile.forEach(vun => {
      if (vun.type > 0) {
        console.log(vun.name + ' -> delete');
        $vunToDelete.push(vun);
      } else {
        console.log(vun.name + ' -> keep');
      }
    });
    $vunToDelete.forEach(vun => {
      this.removeVuNAuto(vun);
    });
    this.checkVuNVorhandenOderGegenteil();
    console.log(this.heldin.vorUndNachteile);
    if (this.methodLogging) {
      console.log('START: getSpeziesUndProfessionsVuN()');
    }
    this.$typischeVorteile = [];
    this.$untypischeVorteile = [];
    this.$typischeNachteile = [];
    this.$untypischeNachteile = [];
    if (this.heldin.spezies.typischeVorteile !== undefined) {
      this.heldin.spezies.typischeVorteile.forEach(vun => {
        if (!this.$typischeVorteile.includes(vun)) {
          this.$typischeVorteile.push(vun);
        }
      });
    }
    if (this.heldin.profession.empfohleneVorteile !== undefined) {
      this.heldin.profession.empfohleneVorteile.forEach(vun => {
        if (!this.$typischeVorteile.includes(vun)) {
          this.$typischeVorteile.push(vun);
        }
      });
    }
    if (this.heldin.spezies.untypischeVorteile !== undefined) {
      this.heldin.spezies.untypischeVorteile.forEach(vun => {
        if (!this.$untypischeVorteile.includes(vun)) {
          this.$untypischeVorteile.push(vun);
        }
      });
    }
    if (this.heldin.profession.ungeeigneteVorteile !== undefined) {
      this.heldin.profession.ungeeigneteVorteile.forEach(vun => {
        if (!this.$untypischeVorteile.includes(vun)) {
          this.$untypischeVorteile.push(vun);
        }
      });
    }

    if (this.heldin.spezies.typischeNachteile !== undefined) {
      this.heldin.spezies.typischeNachteile.forEach(vun => {
        if (!this.$typischeNachteile.includes(vun)) {
          this.$typischeNachteile.push(vun);
        }
      });
    }
    if (this.heldin.profession.empfohleneNachteile !== undefined) {
      this.heldin.profession.empfohleneNachteile.forEach(vun => {
        if (!this.$typischeNachteile.includes(vun)) {
          this.$typischeNachteile.push(vun);
        }
      });
    }
    if (this.heldin.spezies.untypischeNachteile !== undefined) {
      this.heldin.spezies.untypischeNachteile.forEach(vun => {
        if (!this.$untypischeNachteile.includes(vun)) {
          this.$untypischeNachteile.push(vun);
        }
      });
    }
    if (this.heldin.profession.ungeeigneteNachteile !== undefined) {
      this.heldin.profession.ungeeigneteNachteile.forEach(vun => {
        if (!this.$untypischeNachteile.includes(vun)) {
          this.$untypischeNachteile.push(vun);
        }
      });
    }

    this.heldin.vorUndNachteile.sort((a, b) => (a < b ? -1 : 1));
    this.$typischeVorteile.sort((a, b) => (a < b ? -1 : 1));
    this.$untypischeVorteile.sort((a, b) => (a < b ? -1 : 1));
    this.$typischeNachteile.sort((a, b) => (a < b ? -1 : 1));
    this.$untypischeNachteile.sort((a, b) => (a < b ? -1 : 1));
  }

  public setZaubererIfMagisch(): void {
    if (this.heldin.profession.art === 'magisch') {
      console.log('Profession ist magisch.');
      const zaubererVorteil: VorUndNachteil = this.getVorteil('Zauberer');
      const zauberer: CharakterVuN = {
        vorteil: zaubererVorteil.vorteil,
        name: zaubererVorteil.name,
        stufe: 0,
        stufeString: '',
        kathegorie: '', // Kampftechniken, Talente, ...
        notiz: '',
        beschreibung: zaubererVorteil.beschreibung,
        regel: zaubererVorteil.regel,
        ap: zaubererVorteil.apKosten,
        type: 3
      };
      if (this.schonVorhanden(zauberer)) {
        console.log(zauberer.name + ' schon vorhanden!');
      } else {
        console.log(zauberer.name + ' noch nicht vorhanden!');
        this.heldin.vorUndNachteile.push(zauberer);
      }
      console.log(this.heldin.vorUndNachteile);
    }
  }

  // type = 0: manuell; 1: dringend empfohlen; 2: automatisch; 3: untypisch; 4: typisch
  public getVuNofString(vun: string, isvorteil: boolean, vunType: number): CharakterVuN {
    if (this.methodLogging) {
      console.log('START: getVuNofString()');
    }
    // Name (Mehrfach)* [notiz]* Stufe*   | * = optional; Stufe der Form I, II, ...
    // console.log('verarbeiteVuN(' + vun + ')');
    let stufeStr = '';
    let stufeNr = 1;
    let kathegorieStr = '';
    let notizStr = '';
    this.$stufenStrings.forEach(stf => {
      if (vun.endsWith(' ' + stf)) {
        stufeStr = stf;
        const splitted = vun.split(' ');
        vun = splitted[0];
        for (let i = 1; i < splitted.length - 1; i++) {
          vun += ' ' + splitted[i];
        }
      }
    });
    if (stufeStr !== '') {
      stufeNr = this.$stufenStrings.indexOf(stufeStr) + 1;
    }
    if (vun.includes('(')) {
      kathegorieStr = vun.substring(vun.indexOf('(') + 1, vun.indexOf(')'));
      vun = vun.substring(0, vun.indexOf('(') - 1);
    }
    if (vun.includes('[')) {
      notizStr = vun.substring(vun.indexOf('[') + 1, vun.indexOf(']'));
      vun = vun.substring(0, vun.indexOf('[') - 1);
    }
    let vornachteil: VorUndNachteil;
    if (isvorteil) {
      // console.log('getVorteil(' + vun + ')');
      vornachteil = this.getVorteil(vun);
    } else {
      // console.log('getNachteil(' + vun + ')');
      vornachteil = this.getNachteil(vun);
    }
    let charVtl: CharakterVuN;
    if (vornachteil !== null) {
      charVtl = {
        vorteil: isvorteil,
        name: vornachteil.name,
        stufe: this.$stufenStrings.indexOf(stufeStr) + 1,
        stufeString: stufeStr,
        kathegorie: kathegorieStr, // Kampftechniken, Talente, ...
        notiz: notizStr,
        beschreibung: vornachteil.beschreibung,
        regel: vornachteil.regel,
        ap: this.$charVuN.ap,
        type: vunType
      };
      if (!this.schonVorhanden(charVtl)) {
        this.heldin.vorUndNachteile.push(charVtl);
        this.sortListOfChosenVuN();
      }
    } else {
      // console.log('VuN not found: ' + vun);
    }
    if (this.methodLogging) {
      console.log('END: getVuNofString()');
    }
    return charVtl;
  }

  /*public removeDoubleVun(): void {
    const keep: CharakterVuN[] = [];
    this.heldin.vorUndNachteile.forEach(vun => {
      if (!this.schonVorhanden(vun)) {
        keep.push(vun);
      }
    });
    this.heldin.vorUndNachteile = keep;
  }*/

  public schonVorhanden(vun: CharakterVuN): boolean {
    let vorhanden = false;
    console.log(this.heldin.vorUndNachteile);
    this.heldin.vorUndNachteile.forEach(vorhVuN => {
      if (vun.name + vun.kathegorie + vun.notiz === vorhVuN.name + vorhVuN.kathegorie + vorhVuN.notiz) {
        vorhanden = true;
      }
    });
    return vorhanden;
  }
}
