import { Injectable } from '@angular/core';
import {Sonderfertigkeit} from '../_interfaces/sonderfertigkeit';
import {Heldenerschaffung} from '../_interfaces/charaktererstellung/heldenerschaffung';
import {Modifikatoren} from '../_interfaces/modifikatoren';
import {Basiswerte} from '../_interfaces/basiswerte';
import {Kampfblatt} from '../_interfaces/options/kampfblatt';
import {Ausruestung} from '../_interfaces/options/ausruestung';
import {Talentblatt} from '../_interfaces/options/talentblatt';
import {Zauberblatt} from '../_interfaces/options/zauberblatt';
import {Menu} from '../_interfaces/options/menu';
// import {Kaempferin} from '../_interfaces/kaempferin';
// import {Waffe} from '../_interfaces/waffe';
import {NameWertNumber} from '../_interfaces/name-wert-number';
import {Waffe} from '../_classes/kampf/waffe';
import {Kaempferin} from '../_classes/kampf/kaempferin';

@Injectable({
  providedIn: 'root'
})
export class ObjectFactoryService {

  constructor() { }

  public getNewSonderfertigkeit(): Sonderfertigkeit {
    const sf: Sonderfertigkeit = {
      name: '',
      beschreibung: '',
      voraussetzungen: '',
      apWert: 0,
      type: '',
      mehrfachKathegorie: '',
      chosenKathegorie: '',
      regel: '',
      stufe: 0,
      hatStufen: false,
      artDesErwwebs: 0,
      notiz: '',
      art: '',
      kampftechniken: '',
      apJederStufe: [],
      zugehoerigesTalent: '',
      kenntnis: [],
      schriftName: '',
      kosten: 0,
      erworben: false,
      erweiterteSF: '',
      volumenPunkte: 0,
      bindungskosten: 0,
      aspKosten: '',
      merkmal: ''
    };
    return sf;
  }

  public createNameWert(object: any): NameWertNumber {
    const nw: NameWertNumber = {
      name: object.name,
      wert: object.wert
    };
    return nw;
  }

  public getNewHeldenerschaffung(): Heldenerschaffung {
    const myModifikatoren: Modifikatoren = {
      handlungsunfaehig: [],
      alleProbenMod: 0,
      alleEigenschaftenMod: 0,
      eigenschaftsNamen: [],
      eigenschaftMod: [],
      alleTalenteMod: 0,
      talentNamen: [],
      talentMod: [],
      talentanwendungNamen: [],
      talentanwendungMod: [],
      alleKampftechnikenMod: 0,
      kampftechnikNamen: [],
      attributNamen: [],
      attributMod: [],
      multiplikatorGs: 0,
      gsDurchRuestung: 0,
      iniDurchRuestung: 0,
      gsRuestungManuell: 0,
      iniRuestungManuell: 0,
      beRuestungManuell: 0,

      belastungDurchGewicht: 0,
      belastungDurchRuestung: 0,
      belastung: 0,
      belastungManuell: 0,
      betaeubung: 0,
      betaeubungManuell: 0,
      entrueckung: 0,
      entrueckungManuell: 0,
      furchtManuell: 0,
      furcht: 0,
      paralyse: 0,
      paralyseManuell: 0,
      schmerz: 0,
      schmerzManuell: 0,
      verwirrung: 0,
      verwirrungManuell: 0,
      belastungBeschr: [],
      betaeubungBeschr: [],
      entrueckungBeschr: [],
      furchtBeschr: [],
      paralyseBeschr: [],
      schmerzBeschr: [],
      verwirrungBeschr: []
    };
    const myBasiswerte: Basiswerte = {
      modifikatoren: myModifikatoren,
      maxLeP: 0,
      aktLeP: 0,
      dreiViertelLep: 0,
      halbLep: 0,
      einViertelLep: 0,
      schmerzDurchLep: 0,
      manuellerSchmerz: 0,
      maxAsP: 0,
      aktAsP: 0,
      maxKaP: 0,
      aktKaP: 0,
      aktSchips: 0,
      maxSchips: 0,
      aktSk: 0,
      maxSk: 0,
      aktZk: 0,
      maxZk: 0,
      aktAw: 0,
      maxAw: 0,
      manAw: 0,
      aktIni: 0,
      maxIni: 0,
      iniWurf: 0,
      aktGs: 0,
      maxGs: 0
    };
    const heldin = {
      kostenProStep: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      basiswerte: myBasiswerte,
      erfahrung: null,
      $erfahrungStufe: '',
      spezies: null,
      kultur: null,
      schrift: false,
      profession: null,
      talentwerte: [],
      talentwerteAusProfession: [],
      zauber: [],
      zaubertricks: [],
      kampftechniken: [],
      kampftechnikenAusProfession: [],
      $optionaleKtListe: [],
      $optionaleKtListeAktiv: [],
      $optionaleKtAnz: 0,
      $optionaleKtWert: 0,
      vorUndNachteile: [],
      sonderfertigkeiten: [],
      eigenschaften: [],
      eigenschaftenWahlAusSpezies: [],
      eigenschaftenWahlAusSpeziesGewaehlt: [],
      speziesWurdeGeaendert: false,
      eigenschaftenKosten: 0,
      weiblich: true,
      rechtshaender: true,
      alter: 0,
      haarfarbe: '',
      augenfarbe: '',
      schamhaare: '',
      brueste: '',
      genital: '',
      augenfarbeNumber: undefined,
      bruesteNumber: undefined,
      genitalNumber: undefined,
      haarfarbeNumber: undefined,
      schamhaareNumber: undefined,
      groesse: undefined,
      gewicht: undefined,
      gewichtNumber: undefined,
      groesseNumber: undefined,
      lep: 0,
      asp: 0,
      kap: 0,
      sk: 0,
      zk: 0,
      aw: 0,
      ini: 0,
      gs: 0,
      schips: 0,
      name: '',
      spieler: '',
      $step: 0,
      $weitesterStep: 0,
      $apProStep: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };
    return heldin;
  }

  public getNewWaffe(): Waffe {
    const waffe = new Waffe();
    /*const waffe: Waffe = {
      nahkampf: true,
      name: '',
      dateiname: '',
      kampftechnik: '',
      schandensb: 0,
      schadenseig: '',
      chosenschadenseig: '',
      schadensschwelle: 0,
      tp: '',
      atMod: 0,
      paMod: 0,
      reichweite: '',
      chosenReichweite: '',
      at: 0,
      pa: 0,
      gewicht: 0,
      ladezeiten: '',
      reichweiten: '',
      munitionstyp: '',
      zitat: '',
      beschreibung: '',
      vorteil: '',
      nachteil: '',
      laengeInHF: 0,
      preisInSilber: 0,
      zweihand: false,
      status: ''
    };*/
    return waffe;
  }

  public copyWaffe(waffe: Waffe, other: Waffe): void {
    waffe.nahkampf = other.nahkampf;
    waffe.name = other.name;
    waffe.dateiname = other.dateiname;
    waffe.kampftechnik = other.kampftechnik;
    waffe.schandensb = other.schandensb;
    waffe.schadenseig = other.schadenseig;
    waffe.chosenschadenseig = other.chosenschadenseig;
    waffe.schadensschwelle = other.schadensschwelle;
    waffe.tp = other.tp;
    waffe.atMod = other.atMod;
    waffe.paMod = other.paMod;
    waffe.reichweite = other.reichweite;
    waffe.chosenReichweite = other.chosenReichweite;
    waffe.at = other.at;
    waffe.pa = other.pa;
    waffe.gewicht = other.gewicht;
    waffe.ladezeiten = other.ladezeiten;
    waffe.reichweiten = other.reichweiten;
    waffe.munitionstyp = other.munitionstyp;
    waffe.zitat = other.zitat;
    waffe.beschreibung = other.beschreibung;
    waffe.vorteil = other.vorteil;
    waffe.nachteil = other.nachteil;
    waffe.laengeInHF = other.laengeInHF;
    waffe.preisInSilber = other.preisInSilber;
    waffe.zweihand = other.zweihand;
    waffe.status = other.status;
  }

  public getNewMenu(): Menu {
    const menu: Menu = {
      ausruestung: this.getNewAusruestung(),
      kampfblatt: this.getNewKampfblatt(),
      talentblatt: this.getNewTalentblatt(),
      zauberblatt: this.getNewZauberblatt()
    };
    return menu;
  }

  public getNewKampfblatt(): Kampfblatt {
    const kampfblatt: Kampfblatt = {
      waffenart: 'nahkampf',
      chosenIndex: 0,
      chosenIndex2: 0,
      chosenIndexFK: 0,
      chosenLE: '',
      chosenLE2: '',
      fkWaffeReichweiteIndex: 0,
      fkZielgroesseIndex: 0,
      bewDesZielsIndex: 0,
      bewDesSchuetzenIndex: 0,
      zielgroesseIndex: 0,
      zielZoneIndex: 0,
      iniwurf: 0
    };
    return kampfblatt;
  }

  public getNewAusruestung(): Ausruestung {
    const ausruestung: Ausruestung = {

    };
    return ausruestung;
  }

  public getNewTalentblatt(): Talentblatt {
    const talentblatt: Talentblatt = {

    };
    return talentblatt;
  }

  public getNewZauberblatt(): Zauberblatt {
    const zauberblatt: Zauberblatt = {

    };
    return zauberblatt;
  }

  public getNewKaempferin(): Kaempferin {
    const kaempferin = new Kaempferin();
    /*const kaempferin: Kaempferin = {
      name: '',
      spieler: '',
      npc: false,
      team: 1,
      gruppe: 0,
      ini: 0,
      basisIni: 0,
      lep: 0,
      aw: 0,
      maxAngriffe: 1,

      zielGroesse: 'mittel',
      zielName: 'humanoid',

      gegnerInNahkampfreichweite: [],

      aktionenUebrig: 0,
      laengerfristigeHandlung: 0,
      freieAktionenUebrig: 0,
      verteidigungenBisher: 0,

      rechteHand: this.getNewWaffe(),
      linkeHand: this.getNewWaffe(),

      tpWaffeR: '',
      tpWaffeL: '',

      rsZonen: [],
      rs: [],

      rasse: '',
      weiblich: true,

      belastung: 0,
      betaeubung: 0,
      furcht: 0,
      paralyse: 0,
      schmerz: 0,
      verwirrung: 0,

      belastungKR: 0,
      betaeubungKR: 0,
      furchtKR: 0,
      paralyseKR: 0,
      schmerzKR: 0,
      verwirrungKR: 0,

      mu: 8,
      kl: 8,
      in: 8,
      ch: 8,
      ff: 8,
      ge: 8,
      ko: 8,
      kk: 8,

      kampftechniken: [],

      erschwernisAufNaechsteHandlung: 0
    };*/
    return kaempferin;
  }
}
