import {Gvoraussetzung} from './gvoraussetzung';
import {Gwert} from './gwert';
import {Sonderfertigkeit} from '../charakter/sonderfertigkeit';
import {Gvunratschlag} from './gvunratschlag';

export class Gprofession {
  nameM: string;
  nameW: string;
  gattung: string;
  art: string;
  ausruestungUndTracht: string;
  beschreibung: string;
  paketKosten: number;
  apFuerSprachenUndSchriften: number;
  image: string;
  image2: string;

  voraussetzungen: Gvoraussetzung[];
  sonderfertigkeiten: Sonderfertigkeit[];
  werte: Gwert[];
  vorUndNachteile: Gvunratschlag[];

  public copy(other: Gprofession): void {
    this.nameM = other.nameM;
    this.nameW = other.nameW;
    this.gattung = other.gattung;
    this.art = other.art;
    this.ausruestungUndTracht = other.ausruestungUndTracht;
    this.beschreibung = other.beschreibung;
    this.paketKosten = other.paketKosten;
    this.apFuerSprachenUndSchriften = other.apFuerSprachenUndSchriften;
    this.image = other.image;
    this.image2 = other.image2;
    this.voraussetzungen = [];
    other.voraussetzungen.forEach(data => {
      const neu: Gvoraussetzung = new Gvoraussetzung();
      neu.copy(data);
      this.voraussetzungen.push(neu);
    });
    this.sonderfertigkeiten = [];
    other.sonderfertigkeiten.forEach(data => {
      const neu: Sonderfertigkeit = new Sonderfertigkeit();
      neu.copy(data);
      this.sonderfertigkeiten.push(neu);
    });
    this.werte = [];
    other.werte.forEach(data => {
      const neu: Gwert = new Gwert();
      neu.copy(data);
      this.werte.push(neu);
    });
    this.vorUndNachteile = [];
    other.vorUndNachteile.forEach(data => {
      const neu: Gvunratschlag = new Gvunratschlag();
      neu.copy(data);
      this.vorUndNachteile.push(neu);
    });
  }
}
