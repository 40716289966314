<div class="body">
  <h3>Neue Notiz</h3>
  <div class="notiz">
    <input class="hname" type="text" [(ngModel)]="aktuelleNotiz.name">
    <textarea rows="6" class="htext" [(ngModel)]="aktuelleNotiz.text"></textarea>
    <div class="himage">
      <img src="../../../../assets/images/button_s_pos.png" (click)="fuegeNotizHinzu()"/>
    </div>
  </div>

  <h3>Bisherige Notizen</h3>
  <div class="notiz">
    <div class="hname">
      Name der Notiz
    </div>
    <div class="htext">
      Inhalt der Notiz
    </div>
    <div class="himage">
    </div>
  </div>
  <div class="notiz" *ngFor="let note of charakter.notizen">
    <div class="name" (click)="aendereNotiz(note)">
      <b>{{note.name}}</b>
    </div>
    <div class="text">
      {{note.text}}
    </div>
    <div class="image">
      <img src="../../../../assets/images/button_s_neg.png" (click)="entferneNotiz(note)"/>
    </div>
  </div>
</div>

