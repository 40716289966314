import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Talentwert} from '../../_interfaces/talentwert';
import {Eigenschaft} from '../../_interfaces/eigenschaft';
import {Charakter} from '../../_interfaces/charakter';
import {HeldenblattCommService} from '../../_services/heldenblatt-comm.service';
import {Subscription} from 'rxjs';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {Werte} from '../../_classes/charakter/werte';
import {MenuOptions} from '../../_classes/menu/menuOptions';
import {PersistenceService} from '../../_services/persistence.service';

@Component({
  selector: 'app-titelbereich',
  templateUrl: './titelbereich.component.html',
  styleUrls: ['./titelbereich.component.sass']
})
export class TitelbereichComponent implements OnInit, OnChanges {

  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;
  @Input() titel$!: string;
  @Input() datum: string[];
  public muenzenNamen: string[];
  public muenzenAnzahl: number[];

  public veErschwernis = 0;
  public veDivisor = 1;

  clickEventsubscription: Subscription;

  popup = false;

  datumIndex = 0;
  options: MenuOptions = new MenuOptions();

  constructor(private sharedService: HeldenblattCommService,
              private persistence: PersistenceService) {
  }

  ngOnInit(): void {
    const stored: MenuOptions = this.persistence.retrieveComponent('titelbereich');
    console.log(stored);
    if (stored != null) {
      this.options = new MenuOptions();
      this.options.copy(stored);
      this.datumIndex = this.options.getWertNumber('datumIndex');
    } else {
      this.options.setWertNumber('datumIndex', 0);
      this.datumIndex = this.options.getWertNumber('datumIndex');
      this.persistence.storeComponent('titelbereich', this.options);
    }
    console.log(this.charakter);
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe((data) => {
      if (data == null) {
        this.getGeld();
      } else {
        console.log(data);
        this.veErschwernis = Number(data[0]);
        this.veDivisor = Number(data[1]);
        const wert: Werte = this.charakter.getWert('AW');
        console.log('(' + (wert.wert - wert.abzug) + ' - ' + this.veErschwernis + ')' + ' / ' + this.veDivisor);
      }
    });
    this.getGeld();
    const zechen: number = this.charakter.getWert('Zechen').wert;
    const mu: number = this.charakter.getWert('MU').wert;
    const ko: number = this.charakter.getWert('KO').wert;
    const ausdauer: number = Math.round(((zechen * 2) + (mu + (ko * 2)) / 2) * (3 / 4));
    this.charakter.setWert('Trink-AuP', ausdauer);
  }

  ngOnChanges(): void {
    console.log('OnChanges!');
    // this.getGeld();
  }

  public decreaseWert(wert: string): void {
    this.charakter.decreaseBeeinflWert(wert, 'manuell');
    this.charakter.calculateGesamtModifikationFuer(wert);
    this.ping.emit();
    // this.charakter.getWert('AsP').abzug ++;
    // this.charakter.basiswerte.aktAsP--;
  }

  public decreasePromille(wert: string): void {
    if (this.charakter.getCurrentWert(wert) + 1 <= this.charakter.getWert(wert).wert) {
      this.charakter.increaseBeeinflWert(wert, 'manuell');
    }
    this.charakter.calculateGesamtModifikationFuer(wert);
    this.ping.emit();
    // this.charakter.getWert('AsP').abzug ++;
    // this.charakter.basiswerte.aktAsP--;
  }

  public increasePromille(wert: string): void {
    if (this.charakter.getCurrentWert(wert) - 1 >= 0) {
      this.charakter.decreaseBeeinflWert(wert, 'manuell');
    }
    this.charakter.calculateGesamtModifikationFuer(wert);
    this.ping.emit();
    // this.charakter.getWert('AsP').abzug ++;
    // this.charakter.basiswerte.aktAsP--;
  }

  erhoheDatumIndex(): void {
    if (this.options.getWertNumber('datumIndex') < this.datum.length - 1) {
      this.options.getWertNumber('datumIndex');
      this.options.setWertNumber('datumIndex', this.options.getWertNumber('datumIndex') + 1);
    } else {
      this.options.setWertNumber('datumIndex', 0);
    }
    this.datumIndex = this.options.getWertNumber('datumIndex');
    this.persistence.storeComponent('titelbereich', this.options);
  }
  verringereDatumIndex(): void {
    if (this.options.getWertNumber('datumIndex') > 0) {
      this.options.setWertNumber('datumIndex', this.options.getWertNumber('datumIndex') - 1);
    } else {
      this.options.setWertNumber('datumIndex', this.datum.length - 1);
    }
    this.datumIndex = this.options.getWertNumber('datumIndex');
    this.persistence.storeComponent('titelbereich', this.options);
  }

  public increaseWert(wert: string): void {
    this.charakter.increaseBeeinflWert(wert, 'manuell');
    this.charakter.calculateGesamtModifikationFuer(wert);
    this.ping.emit();
    // this.charakter.getWert('AsP').abzug --;
    // this.charakter.basiswerte.aktAsP++;
  }

  public getGeld(): void {
    this.muenzenNamen = ['Dukat', 'Silbertaler', 'Heller', 'Kreuzer'];
    this.muenzenAnzahl = [];
    this.muenzenNamen.forEach(muenze => {
      this.muenzenAnzahl.push(this.getMengeOfMuenze(muenze));
    });
  }

  private getMengeOfMuenze(muenze: string): number {
    let menge = 0;
    let id;
    this.glossar.ausruestung.forEach(item => {
      if (item.name === muenze) {
        id = item.id;
      }
    });
    if (this.charakter.ausruestung !== undefined) {
      this.charakter.ausruestung.forEach(item => {
        if (id === item.itemid) {
          menge += item.anzahl;
        }
      });
    }
    return menge;
  }
}
