<div class="verticalbody">

  <div class="menu">
    <div *ngFor="let art of talentarten" (click)="setTalentart(art)">
      <label class="talentartglow" *ngIf="art === localData.art">{{art}}</label>
      <label class="talentart" *ngIf="art !== localData.art">{{art}}</label>
    </div>
  </div>

  <div class="qualitaet">
    <div class="zeileOhneBorder">
      <div class="start">Fertigkeitspunkte:</div>
      <div class="hell">0-3</div>
      <div class="dunkel">4-6</div>
      <div class="hell">7-9</div>
      <div class="dunkel">10-12</div>
      <div class="hell">13-15</div>
      <div class="dunkel">16+</div>
    </div>
    <div class="zeileOhneBorder">
      <div class="start">Qualitätsstufe:</div>
      <div class="hell">1</div>
      <div class="dunkel">2</div>
      <div class="hell">3</div>
      <div class="dunkel">4</div>
      <div class="hell">5</div>
      <div class="dunkel">6</div>
    </div>
  </div>



  <div *ngIf="localData.art === '⚙️'">
    <h1>Einstellungen</h1>
    <div class="table">
      <b><div class="listeneintrag">
        <label class="key">Beinflussendes</label>
        <label class="value">In Talenten markiert</label>
      </div></b>
      <div class="listeneintrag" *ngFor="let recipient of localData.anzeigeListe | keyvalue">
        <label class="key">{{recipient.key}}</label>
        <input type="checkbox" [(ngModel)]="recipient.value" (ngModelChange)="changeTalentMarkierungen(recipient.key, recipient.value)">
      </div>
    </div>
  </div>
  <div *ngIf="localData.art === 'Kampf'">
    <h1>{{localData.art}}</h1>
    <div class="table">
      <div class="kopfzeile">
        <div class="infosymbol"></div>
        <div class="name">Kampftechnik</div>
        <div class="le">Leiteigenschaft</div>
        <div class="ktw">Wert</div>
        <div class="atfk">AT/FK</div>
        <div class="pa">PA</div>
        <div class="bwatfk">AT-/FK-Modifikatoren</div>
        <div class="bwpa">PA-Modifikatoren</div>
      </div>
      <div *ngFor="let talent of kampftechniken">
        <div class="zeile">
          <div class="infosymbol" (click)="setTalentInfo('INFO' + talent.glossarkampftechnik.name)">i</div>
          <div class="name">{{talent.glossarkampftechnik.name}}</div>
          <div class="le">
            <div class="leSmall" *ngFor="let le of talent.leiteigenschaften index as i">
              <label class="chosen" *ngIf="talent.leIndex === i">{{le}}</label>
              <label *ngIf="talent.leIndex !== i" (click)="talent.leIndex = i">{{le}}</label>
            </div>
          </div>
          <div class="ktw">
            <div (click)="zeigeTalentwertBeschreibung(talent.glossarkampftechnik.name)" *ngIf="bedeutungAktiv !== talent.glossarkampftechnik.name">{{talent.charaktertalent.wert}}</div>
            <div class="ktwBeschreibung" *ngIf="bedeutungAktiv === talent.glossarkampftechnik.name">
              {{ktWertBedeutung[talent.charaktertalent.wert]}}
            </div>
          </div>

          <div class="atfk" *ngIf="talent.glossarkampftechnik.nahkampf">{{charakter.errechneAT(talent.charaktertalent.wert)}}</div>
          <div class="atfk" *ngIf="!talent.glossarkampftechnik.nahkampf">{{charakter.errechneFK(talent.charaktertalent.wert)}}</div>
          <div class="pa" *ngIf="talent.glossarkampftechnik.nahkampf">{{charakter.errechneVE(talent.charaktertalent.wert, talent.leiteigenschaften[talent.leIndex])}}</div>
          <div class="pa" *ngIf="!talent.glossarkampftechnik.nahkampf">-</div>

          <div class="bwatfk" *ngIf="talent.glossarkampftechnik.nahkampf">
            <label *ngFor="let bw of charakter.getAlleBeeinflWerte('AT')">{{bw.modName}} ({{bw.modWert}})</label>
          </div>
          <div class="bwatfk" *ngIf="!talent.glossarkampftechnik.nahkampf">
            <label *ngFor="let bw of charakter.getAlleBeeinflWerte('AT')">{{bw.modName}} ({{bw.modWert}})</label>
          </div>
          <div class="bwpa" *ngIf="talent.glossarkampftechnik.nahkampf">
            <label *ngFor="let bw of charakter.getAlleBeeinflWerte('VE')">{{bw.modName}} ({{bw.modWert}})</label>
          </div>
        </div>
        <div class="info" *ngIf="talentInfo === 'INFO' + talent.glossarkampftechnik.name">
          <div class="header">
            <label></label>
            <label>Informationen zum Talent {{talent.glossarkampftechnik.name}}</label>
            <label class="cross" (click)="resetTalentInfo()">X</label>
          </div>
          <div class="text"><b>Beschreibung: </b>{{talent.glossarkampftechnik.beschreibung}}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="localData.art !== '⚙️' && localData.art !== 'Kampf'">
    <h1>{{localData.art}}</h1>
    <div class="table">
      <div class="kopfzeile">
        <div class="infosymbol"></div>
        <div class="name">Talent</div>
        <div class="probe">Probe</div>
        <div class="fwModName">FW +/- Mod</div>
        <div class="be">BE</div>
        <div class="r">R</div>
        <div class="anwendungen">
          <div class="zeile">
            <div class="spez">Spez.</div>
            <div class="anwendungsname">Anwendungen</div>
          </div>
        </div>
      </div>

      <div *ngIf="localData.art === 'Körpertalente'">
        <div *ngFor="let talent of kTalente">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + talent.glossartalent.name)">i</div>
            <div class="name">
              <label *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) === 0">{{talent.glossartalent.name}}</label>
              <label class="glowpos" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) > 0">{{talent.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) < 0">{{talent.glossartalent.name}}</label>
            </div>

            <div class="attribute" (click)="talent.attributeAlsZahl = !talent.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of talent.glossartalent.probe">
                <label *ngIf="!talent.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) < 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label class="bad" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) > 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) === 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>

              </div>
            </div>

            <div class="fwmod">
              <div class="fw" (click)="zeigeTalentwertBeschreibung(talent.glossartalent.name)">
                <label *ngIf="talent.aktiveSpzialisierung === ''">{{charakter.getWert(talent.glossartalent.name).wert}}</label>
                <label class="good" *ngIf="talent.aktiveSpzialisierung !== ''">{{charakter.getWert(talent.glossartalent.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
              <div class="fwBeschreibung" *ngIf="bedeutungAktiv === talent.glossartalent.name">
                {{talentWertBedeutung[charakter.getWert(talent.glossartalent.name).wert]}}
              </div>
            </div>
            <div class="be">{{talent.glossartalent.belastung}}</div>
            <div class="rPos" *ngIf="istRoutine(talent)">✓</div>
            <div class="r" *ngIf="!istRoutine(talent)"></div>
            <div class="anwendungen">
              <div *ngFor="let anw of talent.glossartalent.anwendungsgebiete index as i">
                <div class="border{{i > 0}}">
                  <div class="zeile">
                    <div class="spez">
                      <div class="star" *ngIf="talent.anwSpezialisierungen.includes(anw.anwendung)" (click)="setAnwendungsspezialisierung(talent, anw.anwendung)">
                        <label class="glowing" *ngIf="talent.aktiveSpzialisierung === anw.anwendung">✯</label>
                        <label *ngIf="talent.aktiveSpzialisierung !== anw.anwendung">✯</label>
                      </div>
                    </div>
                    <div class="infosymbol" (click)="setAnwendungInfo('INFOANWENDUNG' + talent.glossartalent.name, anw.anwendung, anw)">i</div>
                    <div class="anwendungsname">
                      <label *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) === 0">{{anw.anwendung}}</label>
                      <label class="glowpos" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) > 0">{{anw.anwendung}}</label>
                      <label class="glowneg" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) < 0">{{anw.anwendung}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFO' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{talent.glossartalent.beschreibung}}</div>
            <div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{talent.glossartalent.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{talent.glossartalent.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{talent.glossartalent.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{talent.glossartalent.patzer}}</div>
            <div class="text" *ngIf="talent.glossartalent.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{talent.glossartalent.werkzeug}}</div>
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(talent.glossartalent.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(talent.glossartalent.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFOANWENDUNG' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
      </div>

      <div *ngIf="localData.art === 'Gesellschaftstalente'">
        <div *ngFor="let talent of gTalente">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + talent.glossartalent.name)">i</div>
            <div class="name">
              <label *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) === 0">{{talent.glossartalent.name}}</label>
              <label class="glowpos" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) > 0">{{talent.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) < 0">{{talent.glossartalent.name}}</label>
            </div>

            <div class="attribute" (click)="talent.attributeAlsZahl = !talent.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of talent.glossartalent.probe">
                <label *ngIf="!talent.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) < 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label class="bad" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) > 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) === 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>

              </div>
            </div>

            <div class="fwmod">
              <div class="fw" (click)="zeigeTalentwertBeschreibung(talent.glossartalent.name)">
                <label *ngIf="talent.aktiveSpzialisierung === ''">{{charakter.getWert(talent.glossartalent.name).wert}}</label>
                <label class="good" *ngIf="talent.aktiveSpzialisierung !== ''">{{charakter.getWert(talent.glossartalent.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
              <div class="fwBeschreibung" *ngIf="bedeutungAktiv === talent.glossartalent.name">
                {{talentWertBedeutung[charakter.getWert(talent.glossartalent.name).wert]}}
              </div>
            </div>
            <div class="be">{{talent.glossartalent.belastung}}</div>
            <div class="rPos" *ngIf="istRoutine(talent)">✓</div>
            <div class="r" *ngIf="!istRoutine(talent)"></div>
            <div class="anwendungen">
              <div *ngFor="let anw of talent.glossartalent.anwendungsgebiete index as i">
                <div class="border{{i > 0}}">
                  <div class="zeile">
                    <div class="spez">
                      <div class="star" *ngIf="talent.anwSpezialisierungen.includes(anw.anwendung)" (click)="setAnwendungsspezialisierung(talent, anw.anwendung)">
                        <label class="glowing" *ngIf="talent.aktiveSpzialisierung === anw.anwendung">✯</label>
                        <label *ngIf="talent.aktiveSpzialisierung !== anw.anwendung">✯</label>
                      </div>
                    </div>
                    <div class="infosymbol" (click)="setAnwendungInfo('INFOANWENDUNG' + talent.glossartalent.name, anw.anwendung, anw)">i</div>
                    <div class="anwendungsname">
                      <label *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) === 0">{{anw.anwendung}}</label>
                      <label class="glowpos" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) > 0">{{anw.anwendung}}</label>
                      <label class="glowneg" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) < 0">{{anw.anwendung}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFO' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{talent.glossartalent.beschreibung}}</div>
            <div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{talent.glossartalent.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{talent.glossartalent.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{talent.glossartalent.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{talent.glossartalent.patzer}}</div>
            <div class="text" *ngIf="talent.glossartalent.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{talent.glossartalent.werkzeug}}</div>
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(talent.glossartalent.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(talent.glossartalent.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFOANWENDUNG' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>


        </div>
      </div>

      <div *ngIf="localData.art === 'Naturtalente'">
        <div *ngFor="let talent of nTalente">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + talent.glossartalent.name)">i</div>
            <div class="name">
              <label *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) === 0">{{talent.glossartalent.name}}</label>
              <label class="glowpos" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) > 0">{{talent.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) < 0">{{talent.glossartalent.name}}</label>
            </div>

            <div class="attribute" (click)="talent.attributeAlsZahl = !talent.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of talent.glossartalent.probe">
                <label *ngIf="!talent.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) < 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label class="bad" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) > 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) === 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>

              </div>
            </div>

            <div class="fwmod">
              <div class="fw" (click)="zeigeTalentwertBeschreibung(talent.glossartalent.name)">
                <label *ngIf="talent.aktiveSpzialisierung === ''">{{charakter.getWert(talent.glossartalent.name).wert}}</label>
                <label class="good" *ngIf="talent.aktiveSpzialisierung !== ''">{{charakter.getWert(talent.glossartalent.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
              <div class="fwBeschreibung" *ngIf="bedeutungAktiv === talent.glossartalent.name">
                {{talentWertBedeutung[charakter.getWert(talent.glossartalent.name).wert]}}
              </div>
            </div>
            <div class="be">{{talent.glossartalent.belastung}}</div>
            <div class="rPos" *ngIf="istRoutine(talent)">✓</div>
            <div class="r" *ngIf="!istRoutine(talent)"></div>
            <div class="anwendungen">
              <div *ngFor="let anw of talent.glossartalent.anwendungsgebiete index as i">
                <div class="border{{i > 0}}">
                  <div class="zeile">
                    <div class="spez">
                      <div class="star" *ngIf="talent.anwSpezialisierungen.includes(anw.anwendung)" (click)="setAnwendungsspezialisierung(talent, anw.anwendung)">
                        <label class="glowing" *ngIf="talent.aktiveSpzialisierung === anw.anwendung">✯</label>
                        <label *ngIf="talent.aktiveSpzialisierung !== anw.anwendung">✯</label>
                      </div>
                    </div>
                    <div class="infosymbol" (click)="setAnwendungInfo('INFOANWENDUNG' + talent.glossartalent.name, anw.anwendung, anw)">i</div>
                    <div class="anwendungsname">
                      <label *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) === 0">{{anw.anwendung}}</label>
                      <label class="glowpos" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) > 0">{{anw.anwendung}}</label>
                      <label class="glowneg" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) < 0">{{anw.anwendung}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFO' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{talent.glossartalent.beschreibung}}</div>
            <div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{talent.glossartalent.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{talent.glossartalent.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{talent.glossartalent.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{talent.glossartalent.patzer}}</div>
            <div class="text" *ngIf="talent.glossartalent.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{talent.glossartalent.werkzeug}}</div>
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(talent.glossartalent.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(talent.glossartalent.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFOANWENDUNG' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
      </div>

      <div *ngIf="localData.art === 'Wissenstalente'">
        <div *ngFor="let talent of wTalente">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + talent.glossartalent.name)">i</div>
            <div class="name">
              <label *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) === 0">{{talent.glossartalent.name}}</label>
              <label class="glowpos" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) > 0">{{talent.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) < 0">{{talent.glossartalent.name}}</label>
            </div>

            <div class="attribute" (click)="talent.attributeAlsZahl = !talent.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of talent.glossartalent.probe">
                <label *ngIf="!talent.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) < 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label class="bad" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) > 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) === 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>

              </div>
            </div>

            <div class="fwmod">
              <div class="fw" (click)="zeigeTalentwertBeschreibung(talent.glossartalent.name)">
                <label *ngIf="talent.aktiveSpzialisierung === ''">{{charakter.getWert(talent.glossartalent.name).wert}}</label>
                <label class="good" *ngIf="talent.aktiveSpzialisierung !== ''">{{charakter.getWert(talent.glossartalent.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
              <div class="fwBeschreibung" *ngIf="bedeutungAktiv === talent.glossartalent.name">
                {{talentWertBedeutung[charakter.getWert(talent.glossartalent.name).wert]}}
              </div>
            </div>
            <div class="be">{{talent.glossartalent.belastung}}</div>
            <div class="rPos" *ngIf="istRoutine(talent)">✓</div>
            <div class="r" *ngIf="!istRoutine(talent)"></div>
            <div class="anwendungen">
              <div *ngFor="let anw of talent.glossartalent.anwendungsgebiete index as i">
                <div class="border{{i > 0}}">
                  <div class="zeile">
                    <div class="spez">
                      <div class="star" *ngIf="talent.anwSpezialisierungen.includes(anw.anwendung)" (click)="setAnwendungsspezialisierung(talent, anw.anwendung)">
                        <label class="glowing" *ngIf="talent.aktiveSpzialisierung === anw.anwendung">✯</label>
                        <label *ngIf="talent.aktiveSpzialisierung !== anw.anwendung">✯</label>
                      </div>
                    </div>
                    <div class="infosymbol" (click)="setAnwendungInfo('INFOANWENDUNG' + talent.glossartalent.name, anw.anwendung, anw)">i</div>
                    <div class="anwendungsname">
                      <label *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) === 0">{{anw.anwendung}}</label>
                      <label class="glowpos" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) > 0">{{anw.anwendung}}</label>
                      <label class="glowneg" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) < 0">{{anw.anwendung}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFO' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{talent.glossartalent.beschreibung}}</div>
            <div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{talent.glossartalent.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{talent.glossartalent.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{talent.glossartalent.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{talent.glossartalent.patzer}}</div>
            <div class="text" *ngIf="talent.glossartalent.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{talent.glossartalent.werkzeug}}</div>
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(talent.glossartalent.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(talent.glossartalent.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFOANWENDUNG' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
      </div>

      <div *ngIf="localData.art === 'Handwerkstalente'">
        <div *ngFor="let talent of hTalente">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + talent.glossartalent.name)">i</div>
            <div class="name">
              <label *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) === 0">{{talent.glossartalent.name}}</label>
              <label class="glowpos" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) > 0">{{talent.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(talent.glossartalent.name)" *ngIf="aktiveEinsatzmoeglichkeiten(talent.glossartalent) < 0">{{talent.glossartalent.name}}</label>
            </div>

            <div class="attribute" (click)="talent.attributeAlsZahl = !talent.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of talent.glossartalent.probe">
                <label *ngIf="!talent.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) < 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label class="bad" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) > 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>
                <label *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) === 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</label>

              </div>
            </div>

            <div class="fwmod">
              <div class="fw" (click)="zeigeTalentwertBeschreibung(talent.glossartalent.name)">
                <label *ngIf="talent.aktiveSpzialisierung === ''">{{charakter.getWert(talent.glossartalent.name).wert}}</label>
                <label class="good" *ngIf="talent.aktiveSpzialisierung !== ''">{{charakter.getWert(talent.glossartalent.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
              <div class="fwBeschreibung" *ngIf="bedeutungAktiv === talent.glossartalent.name">
                {{talentWertBedeutung[charakter.getWert(talent.glossartalent.name).wert]}}
              </div>
            </div>
            <div class="be">{{talent.glossartalent.belastung}}</div>
            <div class="rPos" *ngIf="istRoutine(talent)">✓</div>
            <div class="r" *ngIf="!istRoutine(talent)"></div>
            <div class="anwendungen">
              <div *ngFor="let anw of talent.glossartalent.anwendungsgebiete index as i">
                <div class="border{{i > 0}}">
                  <div class="zeile">
                    <div class="spez">
                      <div class="star" *ngIf="talent.anwSpezialisierungen.includes(anw.anwendung)" (click)="setAnwendungsspezialisierung(talent, anw.anwendung)">
                        <label class="glowing" *ngIf="talent.aktiveSpzialisierung === anw.anwendung">✯</label>
                        <label *ngIf="talent.aktiveSpzialisierung !== anw.anwendung">✯</label>
                      </div>
                    </div>
                    <div class="infosymbol" (click)="setAnwendungInfo('INFOANWENDUNG' + talent.glossartalent.name, anw.anwendung, anw)">i</div>
                    <div class="anwendungsname">
                      <label *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) === 0">{{anw.anwendung}}</label>
                      <label class="glowpos" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) > 0">{{anw.anwendung}}</label>
                      <label class="glowneg" (click)="setAnwendungEinsatzInfo(talent.glossartalent.name, anw.anwendung)" *ngIf="aktiveEinsatzmoeglichkeitenAnwendung(anw) < 0">{{anw.anwendung}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === talent.glossartalent.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of talent.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of talent.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of talent.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFO' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{talent.glossartalent.beschreibung}}</div>
            <div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{talent.glossartalent.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{talent.glossartalent.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{talent.glossartalent.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{talent.glossartalent.patzer}}</div>
            <div class="text" *ngIf="talent.glossartalent.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{talent.glossartalent.werkzeug}}</div>
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(talent.glossartalent.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(talent.glossartalent.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="talentInfo === 'INFOANWENDUNG' + talent.glossartalent.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{talent.glossartalent.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
</div>


