import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Charakter} from '../../_interfaces/charakter';
import {DataService} from '../../_services/data.service';
import {WebsocketService} from '../../_services/websocket.service';
import {CharakterEvent} from '../../_interfaces/events/charakter-event';
import {Sonderfertigkeit} from '../../_interfaces/sonderfertigkeit';
import {Zauber} from '../../_interfaces/zauber';
import {Eigenschaft} from '../../_interfaces/eigenschaft';
import {Kampftechnik} from '../../_interfaces/kampftechnik';
import {HeldenblattCommService} from '../../_services/heldenblatt-comm.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {ObjectFactoryService} from '../../_services/object-factory.service';
import {Erschaffungsattribut} from '../../_interfaces/erschaffungsattribut';
import {Session} from 'inspector';
import {KampfdatenService} from '../../_services/kampfdaten.service';
// import {Kaempferin} from '../../_interfaces/kaempferin';
import {takeUntil} from 'rxjs/operators';
import {Ziel} from '../../_interfaces/kampf/ziel';
import {Waffe} from '../../_classes/kampf/waffe';
import {Kaempferin} from '../../_classes/kampf/kaempferin';
import {Heldin} from '../../_classes/charakter/heldin';
// import {Waffe} from '../../_interfaces/waffe';

@Component({
  selector: 'app-kampfblatt',
  templateUrl: './kampfblatt.component.html',
  styleUrls: ['./kampfblatt.component.sass']
})
export class KampfblattComponent implements OnInit, OnDestroy {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Charakter;
  @Input() heldin: Heldin;
  public kaempfer: Kaempferin[];


  // private websocket: WebsocketService;

  public userWaffe: Waffe;
  public userWaffeFK: Waffe;
  public chosenWaffe: Waffe;
  public chosenWaffe2: Waffe;
  public chosenWaffeFK: Waffe;
  public alleWaffenNahkampf: Waffe[];
  public alleWaffenNahkampf2: Waffe[];
  public alleWaffenFernkampf: Waffe[];
  public reichweitenString: string;
  public reichweitenWahl = ['kurz', 'mittel', 'lang'];
  public waffeninfo: Waffe;

  public passierschlagMod = 0;

  public kampftechnikInfo: Kampftechnik;

  public sfInfo: Sonderfertigkeit;

  public stufen = ['-', 'I', 'II', 'III', 'IV'];

  public nkWaffeTP: string;
  public nkWaffeTP2: string;
  public waffenLE: string[];
  public waffenLE2: string[];

  /** Fernkampf */
  public fkWaffeReichweiten: string[];
  public fkWaffeReichweitenType: string[]; // k: Kernschuss, n: Nah, m: Mittel, w: Weit, b: Ballistischer Schuss
  public fkWaffeTP: string;

  public laengerfristigeHandlung = 0;
  public me: Kaempferin;

  public gegnerWahl: string[];
  public stepsZumAngriff: string[];

  public gewaehlterGegner: Kaempferin;
  public gewaehlteWaffe: Waffe;
  public angreifer: Kaempferin;
  public gegnerAlsZiel: Ziel;

  public zielschuss = false;

  public kampfPhase = 'keine';

  public kritFlag = 0;
  public veErschw = 0;
  public veDivisor = 1;

  public messageParts: string[] = [];

  clickEventsubscription: Subscription;

  destroyed$ = new Subject();

  constructor(public dataService: DataService,
              private sharedService: HeldenblattCommService,
              private factory: ObjectFactoryService,
              public kampfdaten: KampfdatenService,
              private chatService: WebsocketService) {
    this.kaempfer = [];
    this.gegnerWahl = [];
    this.stepsZumAngriff = [];
    this.resetStepsZumAngriff();
    this.clickEventsubscription = this.sharedService.getBelastungUpdate().subscribe(() => {
      this.getBelastung();
    });

    /*this.clickEventsubscription = this.sharedService.getWsUpdate().subscribe((data: KaempferinClass[]) => {
      const message = JSON.stringify(data);
      if (message.startsWith('\"')) {
        window.alert(message);
      } else {
        this.kaempfer = data;
        this.updateFromWebsocket();
      }
    });*/

    this.resetWaffe();
    this.resetChosenWaffe();
    this.resetWaffenInfo();
    this.resetKampftechnikInfo();
    this.resetSfInfo();
    this.nkWaffeTP = '';
    this.nkWaffeTP2 = '';
    this.waffenLE = [];
    this.waffenLE2 = [];
    this.fkWaffeReichweiten = [];
    this.fkWaffeReichweitenType = [];
    this.fkWaffeTP = '';
    this.gewaehlteWaffe = this.factory.getNewWaffe();
  }

  ngOnInit(): void {
    if (this.charakter.menu === undefined) {
      this.charakter.menu = this.factory.getNewMenu();
    }
    if (this.charakter.menu.kampfblatt.waffenart === '' || this.charakter.menu.kampfblatt.waffenart === undefined) {
      this.charakter.menu.kampfblatt.waffenart = 'nahkampf';
    }
    this.setCurrentLePGrenzen();
    this.calculateSchmerz();
    this.getAlleWaffen();
    this.calculateSchadensbonus(this.chosenWaffe, 1);
    this.calculateSchadensbonus(this.chosenWaffe2, 2);
    this.getPAundAT(true);
    this.getFKundTP();

    const chatSub$ = this.chatService.connect(sessionStorage.getItem('benutzername'), 'kampf').pipe(
      takeUntil(this.destroyed$),
    );

    chatSub$.subscribe((data: string) => {
      const message = JSON.stringify(data);
      if (message.startsWith('\"AT#')) {
        const msg = message.substr(1, message.length - 2);
        const splitted: string[] = msg.split('#');
        this.angreifer = this.getKaempferWithName(splitted[1]);
        const kampftechnik = splitted[4];
        const awMod = (2 * this.kampfdaten.getKampftechnikType(kampftechnik));

        this.veErschw = Number(splitted[5]) + awMod;
        if (Number(splitted[6]) === 1) {
          this.veDivisor = 2;
        }
        this.sharedService.setVeMod(this.veErschw, this.veDivisor);
        this.kampfPhase = 'veWahl';
      } else if (message.startsWith('\"[')) {
        const list: Kaempferin[] = JSON.parse(data);
        this.kaempfer = [];
        list.forEach(k => {
          const kaempferin = new Kaempferin();
          kaempferin.copy(k);
          this.kaempfer.push(kaempferin);
        });
        this.updateFromWebsocket();
      }
    });
  }



  /**
   * Websocket
   */
  public updateFromWebsocket(): void {
    this.getPAundAT(true);
    this.getFKundTP();
    this.charakter.basiswerte.aktAw = (this.charakter.basiswerte.maxAw - -this.charakter.basiswerte.manAw)
      - (3 * this.getMe().verteidigungenBisher);
  }

  /*sendMessage(): void {
    // this.chatService.send(this.msgControl.value);
    // this.chatService.send('register|' + JSON.stringify(this.charakter));
    this.chatService.send(this.charakter);
  }*/

  betreteDenKampf(): void {
    this.updateKaempfer();
  }

  updateIni(): void {
    if (this.checkIfImKampf()) {
      this.updateKaempfer();
    }
  }

  updateKaempfer(): void {
    const me: Kaempferin = this.getMe();
    if (me.name === '') {
      me.name = this.charakter.name;
      me.spieler = this.charakter.spieler;
      me.basisIni = this.charakter.basiswerte.aktIni;
    }
    me.ini = this.charakter.basiswerte.aktIni - - this.charakter.basiswerte.iniWurf;
    me.lep = this.charakter.basiswerte.aktLeP;
    me.aktionenUebrig = -this.laengerfristigeHandlung;
    if (this.charakter.menu.kampfblatt.waffenart === 'nahkampf') {
      me.rechteHand = this.chosenWaffe;
      me.linkeHand = this.chosenWaffe2;
    } else {
      me.rechteHand = this.chosenWaffeFK;
      me.linkeHand = this.chosenWaffeFK;
    }
    if (this.charakter.ruestungen !== undefined) {
      this.charakter.ruestungen.forEach(r => {
        if (r.angelegt) {
          me.rsZonen = this.kampfdaten.getZonenOfZiel(me.zielName, me.zielGroesse);
          me.rs.push(r.rsKopf);
          me.rs.push(r.rsBrust);
          me.rs.push(r.rslArm);
          me.rs.push(r.rsrArm);
          me.rs.push(r.rslBein);
          me.rs.push(r.rsrBein);
        }
      });
    }
    this.me.eigenschaften = [];
    this.charakter.eigenschaften.forEach(eig => {
      this.me.eigenschaften.push(eig);
    });

    console.log(me);
    this.chatService.send(me);
  }

  verlasseDenKampf(): void {
    this.resetStepsZumAngriff();
    this.resetGegnerWahl();
    this.chatService.send('kampfVerlassen: ' + this.charakter.name);
  }

  forceWsUpdate(): void {
    this.gewaehlteWaffe = this.factory.getNewWaffe();
    this.resetGegnerWahl();
    this.resetWaffe();
    this.resetStepsZumAngriff();
    this.chatService.send('getUpdate');
  }

  public sendAtToDefenderAndZoneToGM(zone: string): void {
    // message to defender
    this.messageParts.push('AT');
    this.messageParts.push(this.me.name);
    this.messageParts.push(this.gewaehlteWaffe.kampftechnik);
    this.messageParts.push(String(0)); // VE-Erschwernis
    this.messageParts.push(this.gewaehlterGegner.name);
    this.messageParts.push(String(this.kritFlag));
    this.messageParts.push(this.gewaehlteWaffe.tp);
    this.sendMessage();
    // message to GM
    if (zone.includes(' - ')) {
      const splittedZone: string[] = zone.split(' - ');
      zone = splittedZone[0];
    }
    this.messageParts.push('Zone');
    this.messageParts.push(this.gewaehlterGegner.name);
    this.messageParts.push(zone);
    this.sendMessage();
    // reset choices before attack
    this.gewaehlteWaffe = this.factory.getNewWaffe();
    this.resetStepsZumAngriff();
    this.resetGegnerWahl();

    this.sendEndOfAttack();
  }

  public sendEndOfAttack(): void {
    this.messageParts.push('ATEnde');
    this.messageParts.push(this.me.name);
    this.sendMessage();
    // reset choices before attack
    this.gewaehlteWaffe = this.factory.getNewWaffe();
    this.resetStepsZumAngriff();
    this.resetGegnerWahl();
  }

  public sendAtPatzer(zone: string): void {
    if (zone.includes(' - ')) {
      const splittedZone: string[] = zone.split(' - ');
      zone = splittedZone[0];
    }
    this.messageParts.push('PatzerAT');
    this.messageParts.push(this.me.name);
    this.messageParts.push(zone);
    this.sendMessage();
    this.sendEndOfAttack();
  }

  public sendEndOfDefense(verteidigt: string): void {
    this.messageParts.push('VEEnde');
    this.messageParts.push(this.me.name);
    this.messageParts.push(verteidigt);
    this.sendMessage();
    this.changeKampfPhase('keine');
    this.angreifer = undefined;
    this.veErschw = 0;
    this.veDivisor = 1;
    this.sharedService.setVeMod(this.veErschw, this.veDivisor);
  }

  public sendVePatzer(zone: string): void {
    if (zone.includes(' - ')) {
      const splittedZone: string[] = zone.split(' - ');
      zone = splittedZone[0];
    }
    this.messageParts.push('PatzerVE');
    this.messageParts.push(this.me.name);
    this.messageParts.push(zone);
    this.sendMessage();
    this.sendEndOfDefense('0');
  }

  public sendMessage(): void {
    let message = this.messageParts[0];
    for (let i = 1; i < this.messageParts.length; i++) {
      message += '#' + this.messageParts[i];
    }
    this.chatService.send(message);
    this.messageParts = [];
  }

  public initiatePassierschlag(): void {
    this.passierschlagMod = -4;
    this.changeKampfPhase('passierschlag');
  }

  public endPassierschlag(erfolgreich: number): void {
    this.passierschlagMod = 0;
    if (erfolgreich === 0) {
      this.sendEndOfDefense('1');
    } else {
      this.changeKampfPhase('passierschlagZone');
    }
    this.resetWaffenWahl();
  }

  public changeWaffenart(): void {
    this.resetGegnerWahl();
    this.resetWaffenWahl();
    if (this.charakter.menu.kampfblatt.waffenart === 'fernkampf') {
      this.stepsZumAngriff[1] = 'done';
      this.gewaehlteWaffe = this.chosenWaffeFK;
    }
  }

  public sendPassierschlagToGM(zone: string): void {
    if (zone.includes(' - ')) {
      const splittedZone: string[] = zone.split(' - ');
      zone = splittedZone[0];
    }
    this.messageParts.push('PassierZone');
    this.messageParts.push(this.me.name);
    this.messageParts.push(this.angreifer.name);
    this.messageParts.push(zone);
    this.sendMessage();
    this.sendEndOfDefense('1');
  }

  public simulateAttack(): void {
    this.kampfPhase = 'veWahl';
    this.angreifer = this.me.gegnerInNahkampfreichweite[0];
  }

  public checkIfImKampf(): boolean {
    let imKampf = false;
    this.kaempfer.forEach(k => {
      if (k.name === this.charakter.name && k.spieler === this.charakter.spieler) {
        imKampf = true;
      }
    });
    return imKampf;
  }

  public getStyleOfKaempferin(kaempferin: Kaempferin): string {
    let style = 'enemy';
    if (kaempferin.team === this.me.team) {
      style = 'ally';
      if (kaempferin.name === this.charakter.name && kaempferin.spieler === this.charakter.spieler) {
        style = 'me';
      }
    }
    if (kaempferin.gruppe === 0) {
      style = 'invisible';
    }
    return style;
  }

  public getBelastung(): void {
    let belastung = this.charakter.basiswerte.modifikatoren.belastungManuell;
    belastung -= -Number(this.charakter.basiswerte.modifikatoren.belastungDurchGewicht);
    belastung -= -Number(this.charakter.basiswerte.modifikatoren.belastungDurchRuestung);
    if (belastung > 4) {
      belastung = 4;
    }
    this.charakter.basiswerte.modifikatoren.belastung = belastung;
  }

  public alertParent(): void {
    const eventObject: CharakterEvent = {
      charakter: this.charakter
    };
    this.ping.emit(eventObject);
  }

  public calculateSchadensbonus(waffe: Waffe, index: number): void {
    if (waffe !== undefined) {
      console.log('calculateSchadensbonus for ' + waffe.name);
      let schadensbonus = 0;
      console.log(waffe);
      console.log('bonus: ' + this.getEigenschaft(waffe.chosenschadenseig).wert + ' - ' + waffe.schadensschwelle);
      const eigenschaft: Erschaffungsattribut = this.getEigenschaft(waffe.chosenschadenseig);
      const bonus = eigenschaft.wert - -eigenschaft.modifikator - waffe.schadensschwelle;

      if (schadensbonus < bonus) {
        schadensbonus = bonus;
      }
      waffe.schandensb = schadensbonus;

      if (index === 1) {
        this.nkWaffeTP = '';
        let tpArray: string[];
        if (waffe.tp.includes('+')) {
          tpArray = waffe.tp.split('+');
        } else {
          tpArray = waffe.tp.split('-');
        }
        console.log(tpArray);
        const tpAnfang = tpArray[0];
        let tpEnde = 0;
        if (tpArray.length > 1) {
          tpEnde = Number(tpArray[1]);
        }

        if (waffe.tp.includes('-')) {
          tpEnde = (tpEnde * -1 );
        }
        console.log(waffe);
        console.log(waffe.schandensb);

        tpEnde -= -waffe.schandensb;
        console.log(tpEnde);

        this.nkWaffeTP = tpAnfang;
        if (tpEnde < 0) {
          this.nkWaffeTP += tpEnde;
        } else if (tpEnde > 0) {
          this.nkWaffeTP += '+' + tpEnde;
        }
      } else {
        this.nkWaffeTP2 = '';
        let tpArray: string[];
        if (waffe.tp.includes('+')) {
          tpArray = waffe.tp.split('+');
        } else {
          tpArray = waffe.tp.split('-');
        }
        console.log(tpArray);
        const tpAnfang = tpArray[0];
        let tpEnde = 0;
        if (tpArray.length > 1) {
          tpEnde = Number(tpArray[1]);
        }

        if (waffe.tp.includes('-')) {
          tpEnde = (tpEnde * -1 );
        }
        console.log(waffe);
        console.log(waffe.schandensb);

        tpEnde -= -waffe.schandensb;
        console.log(tpEnde);

        this.nkWaffeTP2 = tpAnfang;
        if (tpEnde < 0) {
          this.nkWaffeTP2 += tpEnde;
        } else if (tpEnde > 0) {
          this.nkWaffeTP2 += '+' + tpEnde;
        }
      }
    }
  }

  public resetWaffenInfo(): void {
    this.waffeninfo = this.factory.getNewWaffe();
  }

  public resetWaffe(): void {
    this.userWaffe = this.factory.getNewWaffe();
    this.userWaffeFK = this.factory.getNewWaffe();
  }

  public resetChosenWaffe(): void {
    this.chosenWaffe = this.factory.getNewWaffe();
    this.chosenWaffeFK = this.factory.getNewWaffe();
  }

  public resetKampftechnikInfo(): void {
    this.kampftechnikInfo = {
      nahkampf: true,
      name: '',
      beschreibung: '',
      besonderheiten: '',
      leiteigenschaften: [],
      steigerungsfaktor: '',
      wert: 0,
      startwert: 0,
      atWert: 0,
      paWert: 0
    };
  }

  public resetSfInfo(): void {
    this.sfInfo = this.factory.getNewSonderfertigkeit();
  }

  public calculateSchmerz(): void {
    /*let schmerz = this.charakter.basiswerte.schmerzDurchLep;
    schmerz -= -this.charakter.basiswerte.manuellerSchmerz;
    if (schmerz <= 4) {
      this.charakter.basiswerte.modifikatoren.schmerz = schmerz;
    } else {
      this.charakter.basiswerte.modifikatoren.schmerz = 4;
    }*/
    this.heldin.calculateGesamtModifikationFuer('Schmerz');
    this.ping.emit();
  }

  private checkSchmwerzDurchLeP(): void {
    /* let schmerzDurchLeP = 0;
    if (this.charakter.basiswerte.aktLeP <= this.charakter.basiswerte.dreiViertelLep) {
      schmerzDurchLeP -= -1;
    }
    if (this.charakter.basiswerte.aktLeP <= this.charakter.basiswerte.halbLep) {
      schmerzDurchLeP -= -1;
    }
    if (this.charakter.basiswerte.aktLeP <= this.charakter.basiswerte.einViertelLep) {
      schmerzDurchLeP -= -1;
    }
    console.log(this.charakter.basiswerte.aktLeP + ' <= 5: ' + schmerzDurchLeP);
    if (this.charakter.basiswerte.aktLeP <= 5) {
      schmerzDurchLeP -= -1;
    }
    this.charakter.basiswerte.schmerzDurchLep = schmerzDurchLeP;
    this.calculateSchmerz();*/
    let schmerzDurchLeP = 0;
    const lep = this.heldin.getWert('LeP').wert;
    const aktLeP = this.heldin.getWert('LeP').akt();
    const dreiViertelLep = Math.round(lep * 0.75);
    const halbLep = Math.round(lep * 0.5);
    const einViertelLep = Math.round(lep * 0.25);
    if (aktLeP <= dreiViertelLep) {
      schmerzDurchLeP -= -1;
    }
    if (aktLeP <= halbLep) {
      schmerzDurchLeP -= -1;
    }
    if (aktLeP <= einViertelLep) {
      schmerzDurchLeP -= -1;
    }
    if (aktLeP <= 5) {
      schmerzDurchLeP -= -1;
    }
    this.heldin.setBeeinflWert('Schmerz', 'LeP', String(schmerzDurchLeP));
    // this.charakter.basiswerte.schmerzDurchLep = schmerzDurchLeP;
    this.calculateSchmerz();
  }

  public decreaseLeP(): void {
    const aktuell: number = this.heldin.getBeeinflWert('LeP', 'manuell').getNumericWert();
    this.heldin.setBeeinflWert('LeP', 'manuell', String(aktuell - 1));
    // this.charakter.basiswerte.aktLeP--;
    this.setCurrentLePGrenzen();
    this.checkSchmwerzDurchLeP();
    this.updateKaempfer();
  }

  public increaseLeP(): void {
    const aktuell: number = this.heldin.getBeeinflWert('LeP', 'manuell').getNumericWert();
    this.heldin.setBeeinflWert('LeP', 'manuell', String(aktuell + 1));
    // this.charakter.basiswerte.aktLeP++;
    this.setCurrentLePGrenzen();
    this.checkSchmwerzDurchLeP();
    this.updateKaempfer();
  }

  private setCurrentLePGrenzen(): void {
    console.log('setCurrentLePGrenzen()');
    const maxLep = this.charakter.basiswerte.maxLeP;
    console.log('maxLep: ' + maxLep);
    this.charakter.basiswerte.dreiViertelLep = Math.floor((maxLep * 0.75));
    this.charakter.basiswerte.halbLep = Math.floor((maxLep * 0.5));
    this.charakter.basiswerte.einViertelLep = Math.floor((maxLep * 0.25));
    console.log(this.charakter.basiswerte);
    this.checkSchmwerzDurchLeP();
  }

  public decreaseAW(): void {
    console.log('decreaseAW');
    this.charakter.basiswerte.manAw--;
    console.log(this.charakter.basiswerte.manAw);
    this.charakter.basiswerte.aktAw = (this.charakter.basiswerte.maxAw - -this.charakter.basiswerte.manAw);
    if (this.me !== undefined) {
      if (this.me.verteidigungenBisher > 0) {
        this.charakter.basiswerte.aktAw -= (this.me.verteidigungenBisher * 3);
      }
    }
  }

  public increaseAW(): void {
    console.log('increaseAW');
    this.charakter.basiswerte.manAw++;
    // if me != undefined
    this.charakter.basiswerte.aktAw = (this.charakter.basiswerte.maxAw - -this.charakter.basiswerte.manAw);
    if (this.me !== undefined) {
      if (this.me.verteidigungenBisher > 0) {
        this.charakter.basiswerte.aktAw -= (this.me.verteidigungenBisher * 3);
      }
    }
  }

  public decreaseINI(): void {
    this.charakter.basiswerte.aktIni--;
  }

  public increaseINI(): void {
    this.charakter.basiswerte.aktIni++;
  }

  public decreaseSK(): void {
    this.charakter.basiswerte.aktSk--;
  }

  public increaseSK(): void {
    this.charakter.basiswerte.aktSk++;
  }

  public decreaseZK(): void {
    this.charakter.basiswerte.aktZk--;
  }

  public increaseZK(): void {
    this.charakter.basiswerte.aktZk++;
  }

  public getMe(): Kaempferin {
    // console.log(this.kaempfer);
    let me: Kaempferin;
    this.kaempfer.forEach(k => {
      if (k.name === this.charakter.name && k.spieler === this.charakter.spieler) {
        me = k;
      }
    });
    if (me === undefined) {
      me = this.factory.getNewKaempferin();
    }
    return me;
  }

  public getPAundAT(reset: boolean): void {
    const kt: Kampftechnik = this.getKampftechnik(this.chosenWaffe.kampftechnik);
    const mu: Erschaffungsattribut = this.getEigenschaft('MU');
    // let mod: number = (mu.wert - -mu.modifikator) - 8;
    let mod = this.heldin.getWert('MU').akt() - 8;
    let atBonus = 0;
    while (mod > 2) {
      mod -= 3;
      atBonus++;
    }
    const le: Erschaffungsattribut = this.getEigenschaft(this.charakter.menu.kampfblatt.chosenLE);
    // mod = (le.wert - -le.modifikator) - 8;
    mod = this.heldin.getWert(this.charakter.menu.kampfblatt.chosenLE).akt() - 8;
    let paBonus = 0;
    while (mod > 2) {
      mod -= 3;
      paBonus++;
    }

    const at = kt.atWert - -this.chosenWaffe.atMod - -atBonus;
    let atMod = 0;
    if (this.zielschuss) {
      atMod -= -this.gegnerAlsZiel.modifikator[this.charakter.menu.kampfblatt.zielZoneIndex];
    }

    this.chosenWaffe.at = at - -atMod;

    this.me = this.getMe();
    console.log(this.me);
    const verteidigunsMod = this.me.verteidigungenBisher * 3;
    paBonus -= verteidigunsMod;

    const pa = kt.paWert - -this.chosenWaffe.paMod - -paBonus;
    this.chosenWaffe.pa = pa;
    if (this.chosenWaffe2 !== undefined) {
      this.getPAundAT2();
    }
    if (reset) {
      this.resetGegnerWahl();
    }
  }

  public resetGegnerWahl(): void {
    this.gewaehlterGegner = undefined;
    this.gegnerWahl = [];
    this.stepsZumAngriff[0] = 'todo';
    if (this.charakter.menu.kampfblatt.waffenart === 'nahkampf') {
      this.me.gegnerInNahkampfreichweite.forEach(gegner => {
        this.gegnerWahl.push('nichtGewaehlt');
      });
    } else {
      this.kaempfer.forEach((k: Kaempferin) => {
        this.gegnerWahl.push('nichtGewaehlt');
      });
    }

  }

  public resetStepsZumAngriff(): void {
    this.stepsZumAngriff = [];
    this.stepsZumAngriff.push('todo');
    this.stepsZumAngriff.push('todo');
    this.kampfPhase = 'keine';
  }

  public waehleGegner(index: number): void {
    if (this.charakter.menu.kampfblatt.waffenart === 'nahkampf') {
      if (this.gewaehlterGegner !== this.me.gegnerInNahkampfreichweite[index]) {
        this.resetGegnerWahl();
        this.gewaehlterGegner = this.me.gegnerInNahkampfreichweite[index];
        this.stepsZumAngriff[0] = 'done';
        this.gegnerWahl[index] = 'gewaehlt';
      } else {
        this.resetGegnerWahl();
        this.stepsZumAngriff[0] = 'todo';
        this.gegnerWahl[index] = 'nichtGewaehlt';
      }
    } else {
      console.log(this.kaempfer);
      if (this.gewaehlterGegner !== this.kaempfer[index]) {
        this.resetGegnerWahl();
        this.gewaehlterGegner = this.kaempfer[index];
        this.stepsZumAngriff[0] = 'done';
        this.gegnerWahl[index] = 'gewaehlt';
      } else {
        this.resetGegnerWahl();
        this.stepsZumAngriff[0] = 'todo';
        this.gegnerWahl[index] = 'nichtGewaehlt';
      }
    }
    if (this.gewaehlterGegner != null) {
      this.gegnerAlsZiel = this.kampfdaten.getZielWithNameUndGroesse(this.gewaehlterGegner.zielName, this.gewaehlterGegner.zielGroesse);
      this.charakter.menu.kampfblatt.zielZoneIndex = 0;
      this.getFKundTP();
    }
  }

  public waehleWaffe(waffe: Waffe): void {
    if (waffe !== this.gewaehlteWaffe) {
      this.stepsZumAngriff[1] = 'done';
      this.gewaehlteWaffe = waffe;
    } else {
      this.resetWaffenWahl();
    }
  }

  public resetWaffenWahl(): void {
    this.stepsZumAngriff[1] = 'todo';
    this.gewaehlteWaffe = this.factory.getNewWaffe();
  }

  public getPAundAT2(): void {
    console.log('getPAundAT2');
    const kt: Kampftechnik = this.getKampftechnik(this.chosenWaffe2.kampftechnik);
    const mu: Erschaffungsattribut = this.getEigenschaft('MU');
    // let mod: number = (mu.wert - -mu.modifikator) - 8;
    let mod: number = this.heldin.getWert('MU').akt() - 8;
    let atBonus = 0;
    while (mod > 2) {
      mod -= 3;
      atBonus++;
    }
    const le: Erschaffungsattribut = this.getEigenschaft(this.charakter.menu.kampfblatt.chosenLE2);
    // mod = (le.wert - -le.modifikator) - 8;
    mod = this.heldin.getWert(this.charakter.menu.kampfblatt.chosenLE2).akt() - 8;
    let paBonus = 0;
    while (mod > 2) {
      mod -= 3;
      paBonus++;
    }
    let beidhaendig = false;
    this.charakter.vorUndNachteile.forEach(vun => {
      if (vun.name === 'Beidhändig') {
        beidhaendig = true;
      }
    });
    if (!beidhaendig) {
      atBonus -= 4;
      if (this.chosenWaffe2.kampftechnik !== 'Schilde') {
        paBonus -= 4;
      }
    }
    const at = kt.atWert - -this.chosenWaffe2.atMod - -atBonus;

    this.chosenWaffe2.at = at;

    this.me = this.getMe();
    const verteidigunsMod = this.me.verteidigungenBisher * 3;
    paBonus -= verteidigunsMod;

    const pa = kt.paWert - -this.chosenWaffe2.paMod - -paBonus;
    this.chosenWaffe2.pa = pa;
  }

  public getFKundTP(): void {
    if (this.chosenWaffeFK != null) {
      const kt: Kampftechnik = this.getKampftechnik(this.chosenWaffeFK.kampftechnik);
      const ff: Erschaffungsattribut = this.getEigenschaft('FF');
      // let mod: number = (ff.wert - -ff.modifikator) - 8;
      let mod = this.heldin.getWert('FF').akt() - 8;
      let fkBonus = 0;
      while (mod > 2) {
        mod -= 3;
        fkBonus++;
      }
      let tpBonus = 0;
      switch (this.fkWaffeReichweitenType[this.charakter.menu.kampfblatt.fkWaffeReichweiteIndex]) {
        case 'n':
          fkBonus -= -2;
          tpBonus++;
          break;
        case 'm':

          break;
        case 'w':
          fkBonus -= 2;
          tpBonus--;
          break;
      }
      let splitted: string[];
      if (this.chosenWaffeFK.tp.includes('+')) {
        splitted = this.chosenWaffeFK.tp.split('+');
      } else if ((this.chosenWaffeFK.tp.includes('-'))) {
        splitted = this.chosenWaffeFK.tp.split('-');
      }
      const anfang = splitted[0];
      let ende: number = tpBonus;
      if (splitted.length > 1) {
        ende -= -Number(splitted[1]);
      }
      let tp = anfang;
      if (ende > 0) {
        tp += '+' + ende;
      } else if (ende < 0) {
        tp += ende;
      }
      fkBonus -= -this.kampfdaten.bewegungDesZielsMod[this.charakter.menu.kampfblatt.bewDesZielsIndex];
      fkBonus -= -this.kampfdaten.bewegungDesSchuetzenMod[this.charakter.menu.kampfblatt.bewDesSchuetzenIndex];
      if (this.gegnerAlsZiel != null) {
        fkBonus -= -this.kampfdaten.zielgroessenMod[this.kampfdaten.zielgroessen.indexOf(this.gegnerAlsZiel.groessenkathegorie)];
      }
      if (this.zielschuss) {
        fkBonus -= -this.gegnerAlsZiel.modifikator[this.charakter.menu.kampfblatt.zielZoneIndex];
      }

      this.fkWaffeTP = tp;
      const fk = kt.atWert - -this.chosenWaffeFK.atMod - -fkBonus;
      this.chosenWaffeFK.at = fk;
    }

  }

  public removeWaffe(waffe: Waffe): void {
    let index = 0;
    let gesucht = -1;
    this.charakter.waffen.forEach(w => {
      if (w.name === waffe.name) {
        gesucht = index;
      }
      index++;
    });
    for (let i = gesucht; i < this.charakter.waffen.length - 1; i++) {
      this.charakter.waffen[i] = this.charakter.waffen[i + 1];
    }
    this.charakter.waffen.pop();
  }

  public berechneWaffenWerte(): void {
    this.calculateSchadensbonus(this.chosenWaffe, 1);
    this.calculateSchadensbonus(this.chosenWaffe2, 2);
    this.getPAundAT(true);
  }

  public berechneFKWaffenWerte(): void {
    this.getFKundTP();
  }

  public getAlleWaffen(): void {
    console.log('getAlleWaffen()');
    console.log(this.charakter.waffen);
    this.alleWaffenNahkampf = [];
    this.alleWaffenNahkampf2 = [];
    this.alleWaffenFernkampf = [];
    this.charakter.waffen.forEach(waffe => {
      if (waffe.nahkampf) {
        this.alleWaffenNahkampf.push(waffe);
        if (waffe.kampftechnik !== 'Stangenwaffen' && waffe.kampftechnik !== 'Zweihandhiebwaffen' && waffe.kampftechnik !== 'Zweihandschwerter') {
          this.alleWaffenNahkampf2.push(waffe);
        }
      } else {
        this.alleWaffenFernkampf.push(waffe);
      }
    });
    console.log(this.alleWaffenNahkampf);
    console.log(this.alleWaffenFernkampf);

    // this.chosenIndex = Number(sessionStorage.getItem('nkWaffenIndex'));
    // this.chosenIndexFK = Number(sessionStorage.getItem('fkWaffenIndex'));
    if (isNaN(this.charakter.menu.kampfblatt.chosenIndex)
      || this.charakter.menu.kampfblatt.chosenIndex === null
      || this.charakter.menu.kampfblatt.chosenIndex === undefined) {
      console.log('reset charakter.menu.kampfblatt.chosenIndex');
      this.charakter.menu.kampfblatt.chosenIndex = 0;
    } else if (this.charakter.menu.kampfblatt.chosenIndex >= this.alleWaffenNahkampf.length) {
      this.charakter.menu.kampfblatt.chosenIndex = 0;
    }
    if (isNaN(this.charakter.menu.kampfblatt.chosenIndex2)
      || this.charakter.menu.kampfblatt.chosenIndex2 === null
      || this.charakter.menu.kampfblatt.chosenIndex2 === undefined) {
      console.log('reset charakter.menu.kampfblatt.chosenIndex');
      this.charakter.menu.kampfblatt.chosenIndex2 = 0;
    } else if (this.charakter.menu.kampfblatt.chosenIndex2 >= this.alleWaffenNahkampf2.length) {
      this.charakter.menu.kampfblatt.chosenIndex2 = 0;
    }
    if (isNaN(this.charakter.menu.kampfblatt.chosenIndexFK)
      || this.charakter.menu.kampfblatt.chosenIndexFK === null
      || this.charakter.menu.kampfblatt.chosenIndexFK === undefined) {
      console.log('reset charakter.menu.kampfblatt.chosenIndexFK');
      this.charakter.menu.kampfblatt.chosenIndexFK = 0;
    }

    this.getWaffe();
    this.getWaffe2();
    this.getWaffeFK();
  }

  public getWaffe(): void {
    this.resetWaffenWahl();
    this.chosenWaffe = Object.assign({}, this.alleWaffenNahkampf[this.charakter.menu.kampfblatt.chosenIndex]);
    if (this.chosenWaffe !== null && this.chosenWaffe !== undefined) {
      this.waffenLE = this.chosenWaffe.schadenseig.split('/');
      if (this.charakter.menu.kampfblatt.chosenLE === ''
        || this.charakter.menu.kampfblatt.chosenLE === null
        || this.charakter.menu.kampfblatt.chosenLE === undefined
        || !this.chosenWaffe.schadenseig.includes(this.charakter.menu.kampfblatt.chosenLE)) {
        console.log('reset charakter.menu.kampfblatt.chosenLE');
        this.charakter.menu.kampfblatt.chosenLE = this.waffenLE[0];
      }
      this.berechneWaffenWerte();
    }
  }

  public getWaffe2(): void {
    this.chosenWaffe2 = Object.assign({}, this.alleWaffenNahkampf2[this.charakter.menu.kampfblatt.chosenIndex2]);
    if (this.chosenWaffe2 !== null && this.chosenWaffe2 !== undefined) {
      this.waffenLE2 = this.chosenWaffe2.schadenseig.split('/');
      if (this.charakter.menu.kampfblatt.chosenLE2 === ''
        || this.charakter.menu.kampfblatt.chosenLE2 === null
        || this.charakter.menu.kampfblatt.chosenLE2 === undefined
        || !this.chosenWaffe2.schadenseig.includes(this.charakter.menu.kampfblatt.chosenLE2)) {
        console.log('reset charakter.menu.kampfblatt.chosenLE');
        this.charakter.menu.kampfblatt.chosenLE2 = this.waffenLE2[0];
      }
      this.berechneWaffenWerte();
    }
  }

  public getWaffeFK(): void {
    // sessionStorage.setItem('fkWaffenIndex', '' + this.charakter.menu.kampfblatt.chosenIndexFK);
    this.chosenWaffeFK = this.alleWaffenFernkampf[this.charakter.menu.kampfblatt.chosenIndexFK];
    if (this.chosenWaffeFK !== undefined && this.chosenWaffeFK !== null) {
      this.fkWaffeReichweiten = this.chosenWaffeFK.reichweiten.split('/');
      this.fkWaffeReichweitenType.push('n');
      this.fkWaffeReichweitenType.push('m');
      this.fkWaffeReichweitenType.push('w');
      if (isNaN(this.charakter.menu.kampfblatt.fkWaffeReichweiteIndex)
        || this.charakter.menu.kampfblatt.fkWaffeReichweiteIndex === null
        || this.charakter.menu.kampfblatt.fkWaffeReichweiteIndex === undefined) {
        console.log('reset charakter.menu.kampfblatt.fkWaffeReichweiteIndex');
        this.charakter.menu.kampfblatt.fkWaffeReichweiteIndex = 1;
      }
    }
  }

  public getEigenschaft(name: string): Erschaffungsattribut {
    let gesucht: Erschaffungsattribut;
    this.charakter.eigenschaften.forEach(eigenschaft => {
      if (eigenschaft.name === name) {
        gesucht = eigenschaft;
      }
    });
    return gesucht;
  }

  public getKampftechnik(name: string): Kampftechnik {
    let gesucht: Kampftechnik;
    this.charakter.kampftechniken.forEach(kt => {
      if (kt.name === name) {
        gesucht = kt;
      }
    });
    return gesucht;
  }

  public setReichweite(): void {
    this.getFKundTP();
  }

  public setWaffenInfo(waffe: Waffe): void {
    if (this.waffeninfo.name === waffe.name) {
      this.resetWaffenInfo();
    } else {
      this.waffeninfo = waffe;
    }
  }

  public setLE(): void {
    this.berechneWaffenWerte();
  }

  public setKtInfo(kt: Kampftechnik): void {
    if (this.kampftechnikInfo.name === kt.name) {
      this.resetKampftechnikInfo();
    } else {
      this.kampftechnikInfo = kt;
    }
  }

  public setSfInfo(sf: Sonderfertigkeit): void {
    if (this.sfInfo.name === sf.name) {
      this.resetSfInfo();
    } else {
      this.sfInfo = sf;
    }
  }

  public changeKampfPhase(phase: string): void {
    this.kampfPhase = phase;
  }

  public setKritFlag(): void {
    this.kritFlag = 1;
    this.changeKampfPhase('atTrefferzone');
  }

  public getKaempferWithName(name: string): Kaempferin {
    let gesucht: Kaempferin;
    this.kaempfer.forEach(k => {
      if (k.name === name) {
        gesucht = k;
      }
    });
    return gesucht;
  }

  toogleZielschuss(): void {
    this.zielschuss = !this.zielschuss;
    this.getFKundTP();
    this.getPAundAT(false);
  }

  ngOnDestroy(): void {
    // this.destroyed$.next();
  }
}
