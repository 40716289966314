import {Sonderfertigkeit} from './charakter/sonderfertigkeit';
import {Gsonderfertigkeit} from './glossar/gsonderfertigkeit';
import {VorUndNachteil} from './charakter/vorUndNachteil';
import {Gvorundnachteil} from './glossar/gvorundnachteil';

export class Kombisf {
  charakterSF: Sonderfertigkeit;
  glossarSF: Gsonderfertigkeit;

  public anzeigeName(): string {
    let name = this.charakterSF.name;
    if (this.glossarSF.stufen > 1) {
      const stufen = ['' , 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
      name += ' ' + stufen[this.charakterSF.stufe];
    }
    if (this.charakterSF.gewaehlteKategorie !== '') {
      name += ' (' + this.charakterSF.gewaehlteKategorie + ')';
    }
    if (this.charakterSF.spezifikation !== '') {
      name += ' ' + this.charakterSF.spezifikation;
    }
    return name;
  }

  /** -1: Nachteil; 0: Sonderfertigkeit; 1: Vorteil */
  public type(): number {
    return 0;
  }

  public copy(other: Kombisf): void {
    this.charakterSF = new Sonderfertigkeit();
    this.charakterSF.copy(other.charakterSF);
    this.glossarSF = new Gsonderfertigkeit();
    this.glossarSF.copy(other.glossarSF);
  }
}
