export class Benutzer {
  id: number;
  name: string;
  charaktere: string[] = [];

  constructor() {
  }

  public initialize(id, name, charaktere): void {
    this.id = id;
    this.name = name;
    this.charaktere = [];
    charaktere.forEach(c => {
      this.charaktere.push(c);
    });
  }

  public copy(other: Benutzer): void {
    this.id = other.id;
    this.name = other.name;
    this.charaktere = [];
    other.charaktere.forEach(c => {
      this.charaktere.push(c);
    });
  }

  public clone(): Benutzer {
    const benutzer: Benutzer = new Benutzer();
    benutzer.copy(this);
    return benutzer;
  }
}
