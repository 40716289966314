<div class="body">
  <h1>Tier erstellen</h1>
  <div class="zeile" style="width: 100%; justify-content: flex-start">
    <select [(ngModel)]="art" (ngModelChange)="changeArt()">
      <option [value]="data" *ngFor="let data of arten">{{data}}</option>
    </select>
    <select [(ngModel)]="unterart" (ngModelChange)="changeUnterart()">
      <option [value]="data" *ngFor="let data of unterarten">{{data}}</option>
    </select>
    <select [(ngModel)]="variante">
      <option [value]="data" *ngFor="let data of varianten">{{data}}</option>
    </select>
  </div>
</div>
