import {Component, Input, OnInit} from '@angular/core';
import {Glossar} from '../../../_classes/glossar/glossar';

@Component({
  selector: 'app-tier',
  templateUrl: './tier.component.html',
  styleUrls: ['./tier.component.sass']
})
export class TierComponent implements OnInit {
  @Input() glossar: Glossar;

  arten: string[] = [];
  art: string;
  unterarten: string[] = [];
  unterart: string;
  varianten: string[] = [];
  variante: string;


  constructor() { }

  ngOnInit(): void {
    this.glossar.tiere.forEach(t => {
      if (!this.arten.includes(t.art)) {
        this.arten.push(t.art);
      }
    });
    this.arten.sort((a, b) => (a < b ? -1 : 1));
    this.art = this.arten[0];
    this.changeArt();
  }

  changeArt(): void {
    this.unterarten = [];
    this.glossar.tiere.forEach(t => {
      if (!this.unterarten.includes(t.unterArt) && t.art === this.art) {
        this.unterarten.push(t.unterArt);
      }
    });
    this.unterarten.sort((a, b) => (a < b ? -1 : 1));
    this.unterart = this.unterarten[0];
    this.changeUnterart();
  }

  changeUnterart(): void {
    this.varianten = [];
    this.glossar.tiere.forEach(t => {
      if (!this.varianten.includes(t.variante) && t.art === this.art  && t.unterArt === this.unterart) {
        this.varianten.push(t.variante);
      }
    });
    this.varianten.sort((a, b) => (a < b ? -1 : 1));
    this.variante = this.varianten[0];
  }

}
