import { Component, OnInit } from '@angular/core';
import {PersistenceService} from '../../_services/persistence.service';
import {DataService} from '../../_services/data.service';
import {WebsocketService} from '../../_services/websocket.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.sass']
})
export class TemplateComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
