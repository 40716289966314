import {Component, Input, OnInit} from '@angular/core';
import {Charakter} from '../../_interfaces/charakter';
import {Zauber} from '../../_interfaces/zauber';
import {Talentwert} from '../../_interfaces/talentwert';
import {Glossar} from '../../_classes/glossar/glossar';
import {Heldin} from '../../_classes/charakter/heldin';
import {DataService} from '../../_services/data.service';
import {Zaubertrick} from '../../_interfaces/zaubertrick';

@Component({
  selector: 'app-zauberblatt',
  templateUrl: './zauberblatt.component.html',
  styleUrls: ['./zauberblatt.component.sass']
})
export class ZauberblattComponent implements OnInit {
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;
  public zauberInfo: Zauber;
  public attrInfos: boolean[];
  public modifikatoren: number[];
  public alleZauber: Zauber[] = [];
  public alleZauberTrick: Zaubertrick[] = [];

  constructor(private dataService: DataService) {
    this.attrInfos = [];
    this.modifikatoren = [];
    this.resetZauberInfo();
  }

  ngOnInit(): void {
    this.dataService.getZauberUser('Kira', this.charakter.name).subscribe((data: Zauber[]) => {
      this.alleZauber = data;
      this.alleZauber.forEach(z => {
        this.attrInfos.push(false);
        this.modifikatoren.push(0);
      });
    });
    this.dataService.getZauberTricksUser('Kira', this.charakter.name).subscribe((data: Zaubertrick[]) => {
      this.alleZauberTrick = data;
    });

  }

  public setZauberInfo(zauber: Zauber): void {
    if (this.zauberInfo.name === zauber.name) {
      this.resetZauberInfo();
    } else {
      this.zauberInfo = zauber;
    }
  }

  public resetZauberInfo(): void {
    this.zauberInfo = {
      name: '',
      ritual: false,
      beschreibung: '',
      probe: [],
      probenMod: '',
      wirkung: '',
      wirkungQS: [],
      zauberDauer: '',
      aspKosten: '',
      reichweite: '',
      wirkungsDauer: '',
      zielKathegorie: '',
      merkmal: '',
      verbreitung: '',
      steigerungskosten: '',
      erweiterungen: [],
      fw: 0
    };
  }

  public increaseMod(zauber: Zauber): void {
    const index = this.alleZauber.indexOf(zauber);
    this.modifikatoren[index] ++;
  }

  public decreaseMod(zauber: Zauber): void {
    const index = this.alleZauber.indexOf(zauber);
    this.modifikatoren[index] --;
  }

  getAttributeWert(attr: string, zauber: Zauber): number {
    this.charakter.calculateGesamtModifikationFuer(attr);
    let wert: number = this.charakter.getWert(attr).akt();

    const index = this.alleZauber.indexOf(zauber);
    wert += this.modifikatoren[index];
    let zustaende = 0;

    // Betäubung
    zustaende -= this.charakter.getWert('Betäubung').akt();

    // Furcht
    zustaende -= this.charakter.getWert('Furcht').akt();

    // Paralyse
    zustaende -= this.charakter.getWert('Paralyse').akt();

    // Schmerz
    zustaende -= this.charakter.getWert('Schmerz').akt();

    // Verwirrung
    zustaende -= this.charakter.getWert('Verwirrung').akt();

    if (zustaende > 5) {
      zustaende = 5;
    }
    return wert + zustaende;
  }

  public toggleAttrInfo(zauber: Zauber): void {
    let index = 0;
    for (let i = 0; i < this.alleZauber.length; i++) {
      if (zauber.name === this.alleZauber[i].name) {
        index = i;
      }
    }
    this.attrInfos[index] = !this.attrInfos[index];
  }
}
