import {Ausruestung} from './charakter/ausruestung';
import {Gegenstand} from './ausruestung/gegenstand';
import {Seil} from './ausruestung/seil';
import {Behaeltnis} from './ausruestung/behaeltnis';
import {Leuchtmittel} from './ausruestung/leuchtmittel';
import {Nahkampfwaffe} from './ausruestung/nahkampfwaffe';
import {Fernkampfwaffe} from './ausruestung/fernkampfwaffe';
import {Ruestung} from './ausruestung/ruestung';
import {Parierwaffe} from './ausruestung/parierwaffe';
import {Allgemein} from './ausruestung/allgemein';

export class GrpKombiGegenstand {
  name: string;
  trageort: string;
  notiz: string;
  zustand: number;
  anzahl = 0;
  detail = false;
  erweiterteAnzeige = false;
  mengenItem = false;
  behGewicht = 0;
  behAnz = 0;
  ueberlast = false;
  behReinheit = false;
  bearbeiteNotiz = false;
  bearbeiteName = false;
  trageortBild: string;
  abgelegt: boolean;
  ausruestungen: Ausruestung[] = [];
  gegenstand: Gegenstand | Allgemein | Seil | Behaeltnis | Leuchtmittel | Nahkampfwaffe | Fernkampfwaffe | Ruestung;

  public addAusruestung(ausruestung: Ausruestung, mengenTyp: boolean): void {
    if (this.ausruestungen.length === 0) {
      this.name = ausruestung.name;
      this.trageort = ausruestung.trageort;
      this.notiz = ausruestung.notiz;
      this.zustand = ausruestung.zustand;
      this.mengenItem = mengenTyp;
    }
    this.ausruestungen.push(ausruestung);
    if (mengenTyp) {
      this.anzahl = ausruestung.anzahl;
    } else {
      this.anzahl++;
      if (this.anzahl === 1) {
        this.detail = true;
      } else {
        this.detail = false;
      }
    }
  }

  compare(other: GrpKombiGegenstand): boolean {
    let gleich = false;
    if (other != null) {
      if (this.name === other.name && this.trageort === other.trageort
        && this.zustand === other.zustand && this.notiz === other.notiz) {
        gleich = true;
      }
    }
    return gleich;
  }

  public copy(other: GrpKombiGegenstand): void {
    this.name = other.name;
    this.trageort = other.trageort;
    this.notiz = other.notiz;
    this.zustand = other.zustand;
    this.anzahl = other.anzahl;
    this.detail = other.detail;
    this.mengenItem = other.mengenItem;
    this.behGewicht = other.behGewicht;
    this.behAnz = other.behAnz;
    this.ueberlast = other.ueberlast;
    this.behReinheit = other.behReinheit;
    this.bearbeiteNotiz = other.bearbeiteNotiz;
    this.bearbeiteName = other.bearbeiteName;
    this.trageortBild = other.trageortBild;
    this.erweiterteAnzeige = other.erweiterteAnzeige;
    this.abgelegt = other.abgelegt;
    this.ausruestungen = [];
    other.ausruestungen.forEach(oa => {
      const ausruestung = new Ausruestung();
      ausruestung.copy(oa);
      this.ausruestungen.push(ausruestung);
    });

    if (other.gegenstand.type === 'Allgemein') {
      this.gegenstand = new Allgemein();
      this.gegenstand.copy(other.gegenstand as Allgemein);
    } else if (other.gegenstand.type === 'Seil') {
      this.gegenstand = new Seil();
      this.gegenstand.copy(other.gegenstand as Seil);
    } else if (other.gegenstand.type === 'Behaeltnis') {
      this.gegenstand = new Behaeltnis();
      this.gegenstand.copy(other.gegenstand as Behaeltnis);
    } else if (other.gegenstand.type === 'Leuchtmittel') {
      this.gegenstand = new Leuchtmittel();
      this.gegenstand.copy(other.gegenstand as Leuchtmittel);
    } else if (other.gegenstand.type === 'Nahkampfwaffe') {
      console.log('copy as Nahkampfwaffe');
      console.log(other.gegenstand as Nahkampfwaffe);
      this.gegenstand = new Nahkampfwaffe();
      this.gegenstand.copy(other.gegenstand as Nahkampfwaffe);
      console.log(this.gegenstand);
    } else if (other.gegenstand.type === 'Parierwaffe') {
      console.log('copy as Parierwaffe');
      console.log(other.gegenstand as Parierwaffe);
      this.gegenstand = new Parierwaffe();
      this.gegenstand.copy(other.gegenstand as Parierwaffe);
      console.log(this.gegenstand);
    } else if (other.gegenstand.type === 'Fernkampfwaffe') {
      this.gegenstand = new Fernkampfwaffe();
      this.gegenstand.copy(other.gegenstand as Fernkampfwaffe);
    } else if (other.gegenstand.type === 'Ruestung') {
      this.gegenstand = new Ruestung();
      this.gegenstand.copy(other.gegenstand as Ruestung);
    }
  }
}
