import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {Talentblatt} from '../../_interfaces/components/sheet/talentblatt';
import {Kombitalent} from '../../_classes/kombitalent';
import {Gtalentanwendung} from '../../_classes/glossar/gtalentanwendung';
import {Kombisf} from '../../_classes/kombisf';
import {Kombivun} from '../../_classes/kombivun';
import {Gvorundnachteil} from '../../_classes/glossar/gvorundnachteil';
import {Gsonderfertigkeit} from '../../_classes/glossar/gsonderfertigkeit';
import {PersistenceService} from '../../_services/persistence.service';
import {Gtalent} from '../../_classes/glossar/gtalent';
import {VorUndNachteil} from '../../_classes/charakter/vorUndNachteil';
import {Sonderfertigkeit} from '../../_classes/charakter/sonderfertigkeit';
import {Kombizauber} from '../../_classes/kombizauber';
import {Gzauber} from '../../_classes/glossar/gzauber';
import {Zaehlbar} from '../../_classes/ausruestung/zaehlbar';
import {Allgemein} from '../../_classes/ausruestung/allgemein';
import {Gprofession} from '../../_classes/glossar/gprofession';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-zauberbogen',
  templateUrl: './zauberbogen.component.html',
  styleUrls: ['./zauberbogen.component.sass']
})
export class ZauberbogenComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  public  localData: Talentblatt = {
    /** Navigation */
    art: 'Zauber',
    anzeigeListe: new Map<string, boolean>()
  };

  /** Navigation */


  tradition = 'keine';
  traditionSF: Gsonderfertigkeit;
  traditionEigenschaft = 'keine';
  gildenmagischerFremdZauber = '';
  tradtitionsArtefakte = new Map<string, string[]>();

  profession: Gprofession;

  artefaktVolumen = new Map<string, number[]>();

  regeln: string[] = [];


  erlaubteModifikationen = 0;
  mods: string[] = ['waehlbar', 'waehlbar', 'waehlbar', 'waehlbar', 'waehlbar', 'waehlbar'];
  modNamen: string[] = ['Erzwingen', 'Kosten senken', 'Reichweite erhöhen', 'Zauberdauer erhöhen', 'Zauberdauer senken', 'Geste oder Formel weglassen'];
  modAuswirkungen: string[] = ['Kosten um eine Stufe erhöht, Zauberprobe um 1 erleichtert',
    'Kosten um eine Stufe gesenkt, Zauberprobe um 1 erschwert',
    'Reichweite um eine Stufe erhöht, Zauberprobe um 1 erschwert',
    'Zauberdauer um eine Stufe erhöht, Zauberprobe um 1 erleichtert',
    'Zauberdauer um eine Stufe gesenkt, Zauberprobe um 1 erschwert',
    'Zauberprobe um jeweils 2 erschwert'];
  zauber2Mod: Kombizauber;
  modifizierbar = false;

  public zauber: Kombizauber[] = [];
  public zauberFremd: Kombizauber[] = [];

  public rituale: Kombizauber[] = [];
  public ritualeFremd: Kombizauber[] = [];

  public zaubertricks: Kombizauber[] = [];

  public zauberarten = ['Zauber', 'Rituale', 'Zaubertricks', 'deine Tradition'];

  public zauberInfo = '';
  public betrachteteAnwendung: Gtalentanwendung;
  public talentanwendungInfo = '';
  public einsatzmoeglichkeitInfoType = -1;
  public einsatzmoeglichkeitInfo: Kombisf | Kombivun;

  public vorteile: Gvorundnachteil[] = [];
  public sonderfertigkeiten: Gsonderfertigkeit[] = [];
  public nachteile: Gvorundnachteil[] = [];

  constructor(private persistence: PersistenceService,
              private sanitizer: DomSanitizer) {
    this.tradtitionsArtefakte.set('Gildenmagier', ['Magierstab']);
  }

  ngOnInit(): void {
    this.ermittleTradition();
    console.log(this.tradition);
    this.ermittleArtefaktVolumen();
    this.initialisiereZauber();
    const stored: Talentblatt = this.persistence.retrieveComponent('zauberblatt');
    // console.log(stored);
    if (stored != null) {
      this.localData = stored;
      const map = new Map<string, boolean>();
      Object.keys(this.localData.anzeigeListe).forEach((key: string) => {
        map.set(key, this.localData.anzeigeListe[key]);
      });
      this.localData.anzeigeListe = map;
    } else {
      this.localData.art = this.zauberarten[0];
      this.localData.anzeigeListe = new Map<string, boolean>();
    }
    console.log(this.zauber);
  }

  ermittleTradition(): void {
    this.charakter.sonderfertigkeiten.forEach(sf => {
      if (sf.name.startsWith('Tradition:')) {
        this.tradition = sf.name.substring(11);
        this.glossar.sonderfertigkeiten.forEach(gsf => {
          if (gsf.name === sf.name) {
            this.traditionSF = gsf;
          }
        });
        if (this.tradition === 'Gildenmagier') {
          this.gildenmagischerFremdZauber = sf.spezifikation;
          this.traditionEigenschaft = 'Klugkeit';
        } else if (this.tradition === 'Hexen') {
          this.traditionEigenschaft = 'Charisma';
        }
      }
    });
    if (this.traditionSF != null) {
      this.regeln = [];
      const splitted: string[] = this.traditionSF.regel.substring(2).split('• ');
      splitted.forEach(r => {
        this.regeln.push(r);
      });
    }
  }

  waehleZauber2Mod(zauber: Kombizauber, modifizierbar: boolean): void {
    this.modifizierbar = modifizierbar;
    if (this.zauber2Mod == null) {
      this.zauber2Mod = zauber;
    } else if (this.zauber2Mod.glossarZauber.name === zauber.glossarZauber.name) {
      this.zauber2Mod = null;
    } else {
      this.zauber2Mod = zauber;
    }
    if (this.zauber2Mod != null) {
      if (modifizierbar) {
        this.erlaubteModifikationen = Math.floor(this.zauber2Mod.charakterZauber.wert / 4);
      } else {
        this.erlaubteModifikationen = 0;
      }
      this.checkInitialModifikationen();
    }
  }
  waehleModifiation(index: number): void {
    if (this.mods[index] === 'waehlbar') {
      this.mods[index] = 'gewaehlt';
    } else if (this.mods[index] === 'gewaehlt') {
      this.mods[index] = 'waehlbar';
    }
    this.checkModifikationen();
  }

  checkModifikationen(): void {
    let count = 0;
    this.mods.forEach(m => {
      if (m === 'gewaehlt') {
        count ++;
      }
    });
    if (count >= this.erlaubteModifikationen) {
      for (let i = 0; i < this.mods.length; i++) {
        if (this.mods[i] !== 'gewaehlt') {
          this.mods[i] = 'nichtwaehlbar';
        }
      }
    } else {
      this.checkInitialModifikationen();
    }
  }

  /*
  'Erzwingen', 'Kosten senken',
  'Reichweite erhöhen', 'Zauberdauer erhöhen',
  'Zauberdauer senken', 'Geste oder Formel weglassen'
   */
  checkInitialModifikationen(): void {
    if (this.erlaubteModifikationen > 0) {
      // Erzwingen
      if (this.mods[0] !== 'gewaehlt') {
        this.mods[0] = 'waehlbar';
      }
      // Kosten senken
      if (this.zauber2Mod.glossarZauber.aspKosten.includes('nicht modifizierbar')) {
        this.mods[1] = 'nichtwaehlbar';
      } else {
        if (this.mods[1] !== 'gewaehlt') {
          this.mods[1] = 'waehlbar';
        }
      }
      // Reichweite erhöhen
      if (this.zauber2Mod.glossarZauber.reichweite.includes('nicht modifizierbar')) {
        this.mods[2] = 'nichtwaehlbar';
      } else {
        if (this.zauber2Mod.glossarZauber.reichweite.trim() === 'selbst') {
          this.mods[2] = 'nichtwaehlbar';
        } else {
          if (this.mods[2] !== 'gewaehlt') {
            this.mods[2] = 'waehlbar';
          }
        }
      }
      // Zauberdauer erhöhen
      if (this.zauber2Mod.glossarZauber.zauberDauer.includes('nicht modifizierbar')) {
        this.mods[3] = 'nichtwaehlbar';
      } else {
        if (this.mods[3] !== 'gewaehlt') {
          this.mods[3] = 'waehlbar';
        }
      }
      // Zauberdauer senken
      if (this.zauber2Mod.glossarZauber.zauberDauer.includes('nicht modifizierbar')) {
        this.mods[4] = 'nichtwaehlbar';
      } else {
        if (this.mods[4] !== 'gewaehlt') {
          this.mods[4] = 'waehlbar';
        }
      }
      // Geste oder Formel weglassen
      if (this.mods[5] !== 'gewaehlt') {
        this.mods[5] = 'waehlbar';
      }
    } else {
      for (let i = 0; i < this.mods.length; i++) {
        this.mods[i] = 'nichtwaehlbar';
      }
    }
  }

  ermittleArtefaktVolumen(): void {
    console.log(this.tradtitionsArtefakte);
    const artefakte: string[] = this.tradtitionsArtefakte.get(this.tradition);
    if (artefakte != null) {
      let index = 0;
      artefakte.forEach(artefakt => {
        console.log('betrachtetes artefakt: ' + artefakt);
        const volumen: number[] = [];
        const volMax = this.charakter.getWert('Volumen ' + artefakt).wert;
        volumen.push(volMax - this.charakter.calculateGesamtModifikationFuer('Volumen ' + artefakt));
        volumen.push(volMax);
        this.artefaktVolumen.set(artefakt, volumen);
        index ++;
      });
    }
  }

  /*public aktiveEinsatzmoeglichkeiten(talent: Gtalent): number {
    if (talent.name === 'Klettern') {
      console.log('aktiveEinsatzmoeglichkeiten(' + talent.name + ')');
      console.log(talent);
    }
    let aktiv = 0;
    let nachteilAktiv = false;
    talent.einsatzmoeglichkeiten.forEach(em => {
      if (em.aktiv && em.typ !== 'Nachteil') {
        if (!nachteilAktiv) {
          aktiv = 1;
        }
      } else if (em.aktiv && em.typ === 'Nachteil') {
        aktiv = -1;
        nachteilAktiv = true;
      }
    });
    return aktiv;
  }*/


  public hatSpezialisierung(talent: string, anwendung: string): boolean {
    let vorhanden = false;
    this.charakter.getAlleSonderfertigkeitenMitName('Fertigkeitsspezialisierung').forEach(sf => {
      if (sf.gewaehlteKategorie === talent && sf.spezifikation === anwendung) {
        vorhanden = true;
      }
    });
    return vorhanden;
  }

  public changeTalentMarkierungen(key: string, value: boolean): void {
    this.localData.anzeigeListe.set(key, value);
    console.log('changeTalentMarkierungen');
    console.log(this.localData);
    /*this.zauber.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });
    this.rituale.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });
    this.zaubertricks.forEach(t => {
      t.resetEinsatzmöglichkeiten();
      this.ermittleEinsatzmoeglichkeiten(t);
    });*/
    this.persistence.storeComponent('zauberblatt', this.localData);
  }

  public ermittleEinsatzmoeglichkeiten(talent: Kombitalent): void {
    talent.glossartalent.einsatzmoeglichkeiten.forEach(em => {
      if (em.typ === 'Vorteil' || em.typ === 'Nachteil') {
        this.charakter.vorUndNachteile.forEach(vun => {
          if (vun.name === em.einsatz) {
            if (em.typ === 'Nachteil') {
              this.fuegeNachteilHinzu(vun, talent);
            } else {
              this.fuegeVorteilHinzu(vun, talent);
            }
            if (!this.localData.anzeigeListe.has(vun.name)) {
              em.aktiv = true;
              this.localData.anzeigeListe.set(vun.name, true);
            } else {
              em.aktiv = this.localData.anzeigeListe.get(vun.name);
            }
          }
        });
      } else if (em.typ === 'Sonderfertigkeit') {
        this.charakter.sonderfertigkeiten.forEach(sf => {
          if (sf.name === em.einsatz) {
            this.fuegeSFHinzu(sf, talent);
            if (!this.localData.anzeigeListe.has(sf.name)) {
              em.aktiv = true;
              this.localData.anzeigeListe.set(sf.name, true);
            } else {
              em.aktiv = this.localData.anzeigeListe.get(sf.name);
            }
          }
        });
      }
    });

    talent.glossartalent.anwendungsgebiete.forEach(anw => {
      anw.einsatzmoeglichkeiten.forEach(em => {
        if (em.typ === 'Vorteil' || em.typ === 'Nachteil') {
          this.charakter.vorUndNachteile.forEach(vun => {
            if (vun.name === em.einsatz) {
              if (em.typ === 'Nachteil') {
                this.fuegeAnwNachteilHinzu(anw.anwendung, vun, talent);
              } else {
                this.fuegeAnwVorteilHinzu(anw.anwendung, vun, talent);
              }
              if (!this.localData.anzeigeListe.has(vun.name)) {
                em.aktiv = true;
                this.localData.anzeigeListe.set(vun.name, true);
              } else {
                em.aktiv = this.localData.anzeigeListe.get(vun.name);
              }
            }
          });
        } else if (em.typ === 'Sonderfertigkeit') {
          this.charakter.sonderfertigkeiten.forEach(sf => {
            if (sf.name === em.einsatz) {
              this.fuegeAnwSFHinzu(anw.anwendung, sf, talent);
              if (!this.localData.anzeigeListe.has(sf.name)) {
                em.aktiv = true;
                this.localData.anzeigeListe.set(sf.name, true);
              } else {
                em.aktiv = this.localData.anzeigeListe.get(sf.name);
              }
            }
          });
        }
      });
    });
    console.log(this.rituale);
    this.persistence.storeComponent('zauberblatt', this.localData);
  }

  private fuegeNachteilHinzu(vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    talent.nachteile.push(kombivun);
  }

  private fuegeAnwNachteilHinzu(anwendung: string, vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    let nListe = talent.anwNachteile.get(anwendung);
    if (nListe == null) {
      nListe = [];
    }
    nListe.push(kombivun);
    talent.anwNachteile.set(anwendung, nListe);
  }

  private fuegeSFHinzu(sf: Sonderfertigkeit, talent: Kombitalent): void {
    const kombisf = new Kombisf();
    kombisf.charakterSF = sf;
    kombisf.glossarSF = this.glossar.getSonderfertigkeit(sf.name);
    talent.sonderfertigkeiten.push(kombisf);
  }
  private fuegeAnwSFHinzu(anwendung: string, sf: Sonderfertigkeit, talent: Kombitalent): void {
    const kombisf = new Kombisf();
    kombisf.charakterSF = sf;
    kombisf.glossarSF = this.glossar.getSonderfertigkeit(sf.name);
    let sfListe = talent.anwSonderfertigkeiten.get(anwendung);
    if (sfListe == null) {
      sfListe = [];
    }
    sfListe.push(kombisf);
    talent.anwSonderfertigkeiten.set(anwendung, sfListe);
  }

  private fuegeVorteilHinzu(vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    talent.vorteile.push(kombivun);
  }

  private fuegeAnwVorteilHinzu(anwendung: string, vun: VorUndNachteil, talent: Kombitalent): void {
    const kombivun = new Kombivun();
    kombivun.charakterVuN = vun;
    kombivun.glossarVuN = this.glossar.getVorUndNachteil(vun.name);
    let vListe = talent.anwVorteile.get(anwendung);
    if (vListe == null) {
      vListe = [];
    }
    vListe.push(kombivun);
    talent.anwVorteile.set(anwendung, vListe);
  }

  public getZauberAttributWert(talent: string, attribut: string): number {
    let wert = this.charakter.getCurrentWert(attribut);
    if (talent === 'Zechen') {
      console.log(this.charakter.getWert(talent));
    }
    wert -= this.charakter.getWert(talent).abzug;
    return wert;
  }

  public getAttributMod(talent: string, attribut: string): number {
    let mod = this.charakter.getWert(attribut).abzug;
    mod += this.charakter.getWert(talent).abzug;
    return mod;
  }

  public decreaseZauberMod(talent: string): void {
    this.charakter.decreaseBeeinflWert(talent, 'manuell');
    this.ping.emit();
  }

  public increaseZauberMod(talent: string): void {
    this.charakter.increaseBeeinflWert(talent, 'manuell');
    this.ping.emit();
  }

  public initialisiereZauber(): void {
    this.zauber = [];
    this.zauberFremd = [];
    this.rituale = [];
    this.ritualeFremd = [];
    this.zaubertricks = [];
    this.charakter.getWerteDerKategorie('Zauber').forEach(wert => {
      console.log(wert.name);
      const kombi: Kombizauber = new Kombizauber();
      kombi.glossarZauber = new Gzauber();
      kombi.glossarZauber.copy(this.glossar.getZauber(wert.name));
      kombi.charakterZauber = wert;
      if (kombi.glossarZauber.art === 'Zauber') {
        if (this.zauberIstAusTradition(kombi.glossarZauber)) {
          this.zauber.push(kombi);
        } else {
          this.zauberFremd.push(kombi);
        }
      } else if (kombi.glossarZauber.art === 'Ritual') {
        if (this.zauberIstAusTradition(kombi.glossarZauber)) {
          this.rituale.push(kombi);
        } else {
          this.ritualeFremd.push(kombi);
        }
      } else if (kombi.glossarZauber.art === 'Zaubertrick') {
        this.zaubertricks.push(kombi);
      }
    });
    this.zauber.sort((a, b) => (a.glossarZauber.name < b.glossarZauber.name ? -1 : 1));
    this.rituale.sort((a, b) => (a.glossarZauber.name < b.glossarZauber.name ? -1 : 1));
    this.zaubertricks.sort((a, b) => (a.glossarZauber.name < b.glossarZauber.name ? -1 : 1));
    console.log(this.zauber);
    console.log(this.rituale);
    console.log(this.zaubertricks);
    this.zauberarten = [];
    if (this.zauber.length > 0) {
      this.zauberarten.push('Zauber');
    }
    if (this.rituale.length > 0) {
      this.zauberarten.push('Rituale');
    }
    if (this.zaubertricks.length > 0) {
      this.zauberarten.push('Zaubertricks');
    }
    this.zauberarten.push('deine Tradition');
    this.glossar.professionen.forEach(profession => {
      if (profession.nameM === this.charakter.profession || profession.nameW === this.charakter.profession) {
        if (profession.art === 'magisch') {
          this.zauberarten.push('deine Profession');
          this.profession = profession;
        }
      }
    });
    /*if (this.localData.zauberart == null || this.localData.zauberart === '') {
      this.localData.zauberart = this.zauberarten[0];
      this.persistence.storeComponent('zauberblatt', this.localData);
    }*/
  }

  zauberIstAusTradition(zauber: Gzauber): boolean {
    let check = false;
    if (zauber.verbreitung.includes(this.tradition)) {
      check = true;
    }
    if (this.gildenmagischerFremdZauber === zauber.name) {
      check = true;
    }
    if (zauber.verbreitung[0] === 'allgemein') {
      check = true;
    }
    return check;
  }

  public setZauberArt(art: string): void {
    this.localData.art = art;
    this.persistence.storeComponent('zauberblatt', this.localData);
  }

  public setAnwendungsspezialisierung(talent: Kombitalent, spez: string): void {
    if (talent.aktiveSpzialisierung === spez) {
      talent.aktiveSpzialisierung = '';
    } else {
      talent.aktiveSpzialisierung = spez;
    }
  }

  public setEinsatzInfo(einstz: Kombisf | Kombivun): void {
    console.log('setEinsatzInfo');
    let kombi: Kombivun | Kombisf;
    if (einstz instanceof Kombisf) {
      kombi = new Kombisf();
      kombi.copy(einstz);
    } else if (einstz instanceof Kombivun) {
      kombi = new Kombivun();
      kombi.copy(einstz);
    }
    console.log(kombi);
    if (this.einsatzmoeglichkeitInfo != null && this.einsatzmoeglichkeitInfo.anzeigeName() === kombi.anzeigeName()) {
      this.resetEinsatzInfo();
    } else {
      this.einsatzmoeglichkeitInfoType = kombi.type();
      this.einsatzmoeglichkeitInfo = kombi;
    }
  }

  public setTalentInfo(name: string): void {
    if (this.zauberInfo === name) {
      this.resetTalentInfo();
    } else {
      this.zauberInfo = name;
    }
    console.log(this.einsatzmoeglichkeitInfo);
  }

  public setAnwendungEinsatzInfo(talent: string, name: string): void {
    this.zauberInfo = talent;
    if (this.talentanwendungInfo === name) {
      this.resetTalentInfo();
    } else {
      this.talentanwendungInfo = name;
    }
    console.log(this.einsatzmoeglichkeitInfo);
  }

  public setAnwendungInfo(talent: string, name: string, info: Gtalentanwendung): void {
    this.zauberInfo = talent;
    if (this.talentanwendungInfo === name) {
      this.resetTalentInfo();
    } else {
      this.talentanwendungInfo = name;
      this.betrachteteAnwendung = info;
    }
    console.log(this.einsatzmoeglichkeitInfo);
  }

  public resetEinsatzInfo(): void {
    this.einsatzmoeglichkeitInfoType = -1;
    this.einsatzmoeglichkeitInfo = null;
  }

  public resetTalentInfo(): void {
    this.resetEinsatzInfo();
    this.resetAnwendungInfo();
    this.betrachteteAnwendung = null;
    this.zauberInfo = '';
  }

  public resetAnwendungInfo(): void {
    this.talentanwendungInfo = '';
  }

  public istRoutine(talent: Kombitalent): boolean {
    if (talent.glossartalent.name === 'Körperbeherrschung') {
      console.log('istRoutine(' + talent.glossartalent.name + ')');
    }
    let routine = false;
    this.charakter.calculateGesamtModifikationFuer(talent.glossartalent.name);
    let mod: number = -this.charakter.getWert(talent.glossartalent.name).abzug;
    if (talent.glossartalent.name === 'Körperbeherrschung') {
      console.log('mod: ' + mod);
    }
    if (mod >= -3) {
      if (mod > 3) {
        mod = 3;
      }
      let fw = 19 - ((mod + 3) * 3);
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('fw: ' + fw);
      }
      let punkteUnter13 = 0;
      talent.glossartalent.probe.forEach(attr => {
        const attrWert = this.charakter.getCurrentWert(attr);

        if (attrWert < 13) {
          punkteUnter13 = punkteUnter13 + (13 - attrWert);
          if (talent.glossartalent.name === 'Körperbeherrschung') {
            console.log('attrWert < 13');
            console.log('=> punkteUnter13 = punkteUnter13 + (13 - ' + attrWert + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + punkteUnter13 + ' + (13 - ' + attrWert + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + punkteUnter13 + ' + (' + (13 - attrWert) + ')');
            console.log('=> ' + punkteUnter13 + ' = ' + (punkteUnter13 + (13 - attrWert)));
            console.log('=> ' + punkteUnter13);
          }
        }
        if (talent.glossartalent.name === 'Körperbeherrschung') {
          console.log('attrWert(' + attr + '): ' + attrWert);
          console.log('punkteUnter13(' + attr + '): ' + punkteUnter13);
        }
      });
      fw += (punkteUnter13 * 3);
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('benötigter FW: ' + fw);
      }
      let aktFw = this.charakter.getWert(talent.glossartalent.name).wert;
      if (talent.aktiveSpzialisierung !== '') {
        aktFw += 2;
      }
      if (talent.glossartalent.name === 'Körperbeherrschung') {
        console.log('vorhandener FW: ' + aktFw);
      }
      if (aktFw >= fw) {
        routine = true;
      }
    }
    return routine;
  }

  getSanitizedURL(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.profession.image2);
  }
}
