import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../../_services/data.service';
import {Step0} from '../../../_interfaces/charaktererstellung/step0';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';
import {Erfahrung} from '../../../_interfaces/erfahrung';

@Component({
  selector: 'app-erfahrung',
  templateUrl: './erfahrung.component.html',
  styleUrls: ['./erfahrung.component.sass']
})
export class ErfahrungComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() backup: Step0;
  @Input() heldin: Heldenerschaffung;

  public $erfahrungsMenu: string[];
  public $erfahrungsInfo: string[][];
  // public $erfahrungStufe: string;
  public sex: string;

  constructor(public dataService: DataService) {
    // this.$geschlecht = 'true';
    this.$erfahrungsInfo = [];
    this.getMenu();
  }

  ngOnInit(): void {
    this.checkErfahrung();
  }

  public checkErfahrung(): void {
    console.log(this.heldin.erfahrung);
    if (this.heldin.erfahrung == null) {
      this.sex = 'true';
      console.log('was null');
      this.heldin.erfahrung = {
        name: 'unerfahren',
        startAP: 900,
        maxEigenschaft: 12,
        maxFertigkeit: 10,
        maxKampftechnik: 8,
        maxPktEigenschaft: 95,
        maxAnzZauber: 8,
        maxFremdZauber: 0,
        apLog: 0,
        apKonto: 900,
        stufe: 0
      };
      this.heldin.$erfahrungStufe = '0';
    } else {
      if (this.heldin.weiblich) {
        this.sex = 'true';
      } else {
        this.sex = 'false';
      }
      console.log('was NOT null');
      this.heldin.$erfahrungStufe = '' + this.heldin.erfahrung.stufe;
    }
    console.log(this.heldin.$erfahrungStufe);
    this.getInfos();
  }

  public getInfos(): void {
    console.log(this.heldin.$erfahrungStufe);
    console.log('/glossary/erfahrung/' + this.heldin.$erfahrungStufe);
    const $url: string = '/glossary/erfahrung/' + this.heldin.$erfahrungStufe;
    this.dataService.request2DStringArray($url).subscribe((data: string[][]) => {

      // console.log('step 0/getInfos()/data:');
      console.log(data);
      this.$erfahrungsInfo = data;
      this.heldin.erfahrung.name = data[0][1];
      this.heldin.erfahrung.startAP = Number(data[1][1]);
      this.heldin.erfahrung.maxEigenschaft = Number(data[2][1]);
      this.heldin.erfahrung.maxFertigkeit = Number(data[3][1]);
      this.heldin.erfahrung.maxKampftechnik = Number(data[4][1]);
      this.heldin.erfahrung.maxPktEigenschaft = Number(data[5][1]);
      this.heldin.erfahrung.maxAnzZauber = Number(data[6][1]);
      this.heldin.erfahrung.maxFremdZauber = Number(data[7][1]);
      this.heldin.erfahrung.stufe = Number(this.heldin.$erfahrungStufe);
      // console.log('step 0/getInfos()/ap: ' + this.$ap);
      this.alertParent();
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getJustInfos(): void {
    console.log(this.heldin.$erfahrungStufe);
    console.log('/glossary/erfahrung/' + this.heldin.$erfahrungStufe);
    const $url: string = '/glossary/erfahrung/' + this.heldin.$erfahrungStufe;
    this.dataService.request2DStringArray($url).subscribe((data: string[][]) => {
      this.$erfahrungsInfo = data;
      this.alertParent();
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public alertParent(): void {
    this.ping.emit();
  }

  public setGeschlecht(): void {
    console.log(this.sex);
    this.heldin.weiblich = (this.sex === 'true');
  }

  public getMenu(): void {
    const $username = sessionStorage.getItem('user');
    let $url: string;
    $url = '/heldenerschaffung/' + $username + '/erfahrung';
    this.dataService.requestStringArray($url).subscribe((data: string[]) => {
      this.$erfahrungsMenu = data;
      // console.log('step 0/getMenu()/data:');
      // console.log(data);
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }



  public loadHeldenerschaffung(): void {
    const $username = sessionStorage.getItem('user');
    this.dataService.getHeldenerschaffungOfUser($username).subscribe((data: Heldenerschaffung) => {
      this.heldin = Object.assign(this.heldin, data);
      console.log(data);
      /**
       * augenfarbeNumber: undefined,
       * bruesteNumber: undefined,
       * genitalNumber: undefined,
       * haarfarbeNumber: undefined,
       * schamhaareNumber: undefined,
       * groesse: undefined,
       * gewicht: undefined,
       * gewichtNumber: undefined,
       * groesseNumber: undefined,
       */
      if (this.heldin.augenfarbeNumber === 0) {
        this.heldin.augenfarbeNumber = undefined;
      }
      if (this.heldin.bruesteNumber === 0) {
        this.heldin.bruesteNumber = undefined;
      }
      if (this.heldin.genitalNumber === 0) {
        this.heldin.genitalNumber = undefined;
      }
      if (this.heldin.haarfarbeNumber === 0) {
        this.heldin.haarfarbeNumber = undefined;
      }
      if (this.heldin.schamhaareNumber === 0) {
        this.heldin.schamhaareNumber = undefined;
      }
      if (this.heldin.groesse === 0) {
        this.heldin.groesse = undefined;
      }
      if (this.heldin.gewicht === 0) {
        this.heldin.gewicht = undefined;
      }
      if (this.heldin.gewichtNumber === 0) {
        this.heldin.gewichtNumber = undefined;
      }
      if (this.heldin.groesseNumber === 0) {
        this.heldin.groesseNumber = undefined;
      }
      this.sex = '' + this.heldin.weiblich;
      this.getJustInfos();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }
}
