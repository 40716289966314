<div class="zeile">
  <h3 style="text-align: center">Waffen</h3>
</div>
<select [(ngModel)]="waffenIndex" (ngModelChange)="getWaffe()">
  <option [value]="alleWaffen.indexOf(waffe)" *ngFor="let waffe of alleWaffen">{{ waffe.name }}</option>
</select>
<div class="zeile">
  <label class="b30">Name: </label> <input class="b62" type="text" [(ngModel)]="waffe.name">
</div>
<div class="zeile">
  Nahkampfwaffe?
</div>
<div class="zeile">
  <input type="checkbox" [(ngModel)]="waffe.nahkampf">
</div>
<div class="zeile">
  <label class="b30">Dateiname: </label> <input class="b62" type="text" [(ngModel)]="waffe.dateiname">
</div>
<div class="zeile">
  <label class="b30">Kampftechnik: </label> <input class="b62" type="text" [(ngModel)]="waffe.kampftechnik">
</div>
<div class="zeile">
  <label class="b30">TP: </label> <input class="b62" type="text" [(ngModel)]="waffe.tp">
</div>
<div class="zeile">
  <label class="b30">Gewicht: </label> <input class="b62" type="number" [(ngModel)]="waffe.gewicht">
</div>
<div class="zeile">
  <label class="b30">Länge: </label> <input class="b62" type="number" [(ngModel)]="waffe.laengeInHF">
</div>
<div class="zeile">
  <label class="b30">Preis: </label> <input class="b62" type="number" [(ngModel)]="waffe.preisInSilber">
</div>
<div class="zeile">
  <label class="b30">Zitat: </label> <input class="b62" type="text" [(ngModel)]="waffe.zitat">
</div>
<div class="zeile">
  <label class="b30">Beschreibung: </label> <input class="b62" type="text" [(ngModel)]="waffe.beschreibung">
</div>
<div class="zeile">
  <label class="b30">Vorteil: </label> <input class="b62" type="text" [(ngModel)]="waffe.vorteil">
</div>
<div class="zeile">
  <label class="b30">Nachteil: </label> <input class="b62" type="text" [(ngModel)]="waffe.nachteil">
</div>
<div class="zeile" *ngIf="waffe.nahkampf">
  <label class="b30">Leiteigenschaften: </label> <input class="b30" type="text" [(ngModel)]="waffe.schadenseig">
  <label class="b30">Eigenschaftswert: </label> <input class="b30" type="number" [(ngModel)]="waffe.schadensschwelle">
</div>
<div class="zeile" *ngIf="waffe.nahkampf">
  <label class="b30">Reichweite: </label> <input class="b62" type="text" [(ngModel)]="waffe.reichweite">
</div>
<div class="zeile" *ngIf="waffe.nahkampf">
  <label class="b30">AT-Mod: </label> <input class="b30" type="number" [(ngModel)]="waffe.atMod">
  <label class="b30">PA-Mod: </label> <input class="b30" type="number" [(ngModel)]="waffe.paMod">
</div>
<div class="zeile" *ngIf="!waffe.nahkampf">
  <label class="b30">Reichweiten: </label> <input class="b62" type="text" placeholder="10/50/80" [(ngModel)]="waffe.reichweiten">
</div>
<div class="zeile" *ngIf="!waffe.nahkampf">
  <label class="b30">Ladezeit: </label> <input class="b62" type="text" [(ngModel)]="waffe.ladezeiten">
</div>
<div class="zeile" *ngIf="!waffe.nahkampf">
  <label class="b30">Munitionstyp: </label> <input class="b62" type="text" [(ngModel)]="waffe.munitionstyp">
</div>
<div class="zeile" >
  <input class="92" type="button" value="Waffe hinzufügen" required="true" (click)="addWaffeToDB()">
</div>
