import { Component, OnInit } from '@angular/core';
import {Armor} from '../../../_interfaces/armor';
import {DataService} from '../../../_services/data.service';

@Component({
  selector: 'app-ruestung',
  templateUrl: './ruestung.component.html',
  styleUrls: ['./ruestung.component.sass']
})
export class RuestungComponent implements OnInit {
  public ruestung: Armor;
  public alleRuestungen: Armor[];
  public ruestungIndex: number;

  constructor(public dataService: DataService) {
    this.resetRuestung();
    this.getAlleRuestungen();
    this.ruestungIndex = 0;
  }

  ngOnInit(): void {
  }

  public getAlleRuestungen(): void {
    this.dataService.getAlleRuestungen().subscribe((data: Armor[]) => {
      console.log(data);
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.alleRuestungen = data;
      this.getRuestung();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getRuestung(): void {
    this.ruestung = this.alleRuestungen[this.ruestungIndex];
    if (this.ruestung.dateiname === '' || this.ruestung.dateiname === null || this.ruestung.dateiname === undefined) {
      this.ruestung.dateiname = this.ruestung.name;
    }
  }

  public setDateiname(): void {
    console.log('setDateiname()');
    console.log(this.ruestung.name);
    this.ruestung.dateiname = this.ruestung.name;
  }

  public addRuestungToDB(): void {
    console.log(this.ruestung);
    if (this.ruestung.name !== '') {
      this.dataService.addRuestungToDB(this.ruestung);
      this.getAlleRuestungen();
      window.alert('Rüstung ' + this.ruestung.name + ' hinzugefügt.');
    }
    this.resetRuestung();
  }

  public resetRuestung(): void {
    this.ruestung = {
      name: '',
      zitat: '',
      beschreibung: '',
      notiz: '',
      dateiname: '',
      anmerkung: '',
      gewicht: 0, // in Stein
      preis: 0, // in Silbertalern
      strukPu: 0,
      komplexitaet: '',
      apKostenZumErlernen: 0,
      rvorteil: '',
      rnachteil: '',
      stabilitaetswert: 0,
      verschleissStufe: 0,
      zusaetzlicheAbzuege: '', // –1 GS, –1 INI
      belastung: 0,
      kathegorie: '', // Lederrüstung, Plattenrüstung, Kettenrüstung, Stoffrüstung
      angelegt: false,
      rsKopf: 0,
      rsBrust: 0,
      rsBauch: 0,
      rsRuecken: 0,
      rslArm: 0,
      rsrArm: 0,
      rslBein: 0,
      rsrBein: 0,
      ksKopf: 0,
      ksBrust: 0,
      ksBauch: 0,
      ksRuecken: 0,
      kslArm: 0,
      ksrArm: 0,
      kslBein: 0,
      ksrBein: 0,
      durchnaesst: false
    };
  }

}
