import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Heldin} from '../../_classes/charakter/heldin';
import {Erfahrung} from '../../_interfaces/erfahrung';
import {PersistenceService} from '../../_services/persistence.service';
import {Limits} from '../../_classes/charakter/limits';
import {Glossar} from '../../_classes/glossar/glossar';
import {Benutzer} from '../../_classes/benutzer/benutzer';

@Component({
  selector: 'app-heldenerschaffung',
  templateUrl: './heldenerschaffung.component.html',
  styleUrls: ['./heldenerschaffung.component.sass']
})
export class HeldenerschaffungComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: Benutzer;

  heldin: Heldin = new Heldin();
  limits: Limits = new Limits();

  constructor(private persistence: PersistenceService) { }

  ngOnInit(): void {
    /** prüfe ob schon eine Heldenerschaffung vorhanden ist */
    console.log('prüfe ob Heldin vorhanden ist für ' + this.user.name);
    const storedHeldin = (this.persistence.retrievePermanentComponent('neue_heldin_' + this.user.id) as Heldin);
    const storedLimits = (this.persistence.retrievePermanentComponent('limits_' + this.user.id) as Limits);
    console.log(storedHeldin);
    console.log(storedLimits);
    if (storedHeldin == null) {
      console.log('erstelle neue Heldin...');
      this.heldin = new Heldin();
      this.persistence.storePermanentComponent('neue_heldin_' + this.user.id, this.heldin);
    } else {
      this.heldin.copy(storedHeldin);
      console.log(this.heldin);
    }
    if (storedLimits == null) {
      this.limits = new Limits();
      this.persistence.storePermanentComponent('limits_' + this.user.id, this.limits);
    } else {
      this.limits.copy(storedLimits);
    }
  }


  public updateChar(): void {
    this.persistence.storePermanentComponent('neue_heldin_' + this.user.id, this.heldin);
    console.log(this.heldin);
  }

  public updateLimits(): void {
    this.persistence.storePermanentComponent('limits_' + this.user.id, this.limits);
  }

  /**
   * Lösche eine vorhandene Heldenerschaffung
   */
  public resetHeldenerschaffung(): void {
    this.persistence.deletePermanentComponent('neue_heldin_' + this.user.id);
    this.persistence.deletePermanentComponent('limits_' + this.user.id);
    this.ngOnInit();
  }
}
