import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Spezies} from '../../../_interfaces/spezies';
import {Step0} from '../../../_interfaces/charaktererstellung/step0';
import {Step2} from '../../../_interfaces/charaktererstellung/step2';
import {compareNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';

@Component({
  selector: 'app-koerperattribute',
  templateUrl: './koerperattribute.component.html',
  styleUrls: ['./koerperattribute.component.sass']
})
export class KoerperattributeComponent implements OnInit {

  @Input() heldin: Heldenerschaffung;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  public $wuerfelGroesse: string;
  public $wuerfelGewicht: string;
  public $augenfarbe: number;
  public $haarfarbe: number;
  public $groesse: number;
  public $gewicht: number;
  public $generate: boolean;
  public $schamhaare: number;
  public $brueste: number;
  public $geschlechtsorgan: number;
  public $geschlechtsorganStr: string;
  public $augenfarbeStr: string;
  public $haarfarbeStr: string;
  public $groesseStr: string;
  public $gewichtStr: string;
  public $schamhaareStr: string;
  public $bruesteStr: string;


  constructor() {
    this.$wuerfelGewicht = '';
    this.$wuerfelGroesse = '';
    this.$augenfarbe = undefined;
    this.$haarfarbe = undefined;
    this.$groesse = undefined;
    this.$gewicht = undefined;

    this.$generate = true;
    this.$schamhaare = undefined;
    this.$brueste = undefined;
    this.$geschlechtsorgan = undefined;
    this.$geschlechtsorganStr = '';
    this.$augenfarbeStr = '';
    this.$haarfarbeStr = '';
    this.$groesseStr = '';
    this.$gewichtStr = '';
    this.$schamhaareStr = '';
    this.$bruesteStr = '';
  }

  ngOnInit(): void {
    this.getKoerperGroesseWurf();
    this.getGewichtWurf();
    this.checkIfVorhanden();
    this.sendEvent();
  }

  public getAugenfarbe(): void {
    this.heldin.spezies.augenfarbe.forEach((str) => {
      const array: string[] = str.split(': ');
      const color: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.augenfarbeNumber) {
            this.heldin.augenfarbe = color;
            // this.$augenfarbeStr = color;
          }
        }
      } else {
        if (+array[1] === this.heldin.augenfarbeNumber) {
          this.heldin.augenfarbe = color;
          // this.$augenfarbeStr = color;
        }
      }
    });
    this.sendEvent();
  }

  public getHaarfarbe(): void {
    this.heldin.spezies.haarfarbe.forEach((str) => {
      const array: string[] = str.split(': ');
      const color: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.haarfarbeNumber) {
            this.heldin.haarfarbe = color;
            // this.$haarfarbeStr = color;
          }
        }
      } else {
        if (+array[1] === this.heldin.haarfarbeNumber) {
          this.heldin.haarfarbe = color;
          // this.$haarfarbeStr = color;
        }
      }
    });
    this.sendEvent();
  }

  public getKoerpergroesse(): void {
    this.heldin.spezies.koerpergroesse.forEach((str) => {
      const array: string[] = str.split(' + ');
      let size: number = +array[0];
      size += this.heldin.groesseNumber;
      this.heldin.groesse = size;
      this.$groesseStr = this.heldin.groesse / 100 + ' Schritt';
    });
    this.sendEvent();
    if (this.$gewicht !== undefined) {
      this.getGewicht();
    }
  }

  public getGewicht(): void {
    if (this.heldin.groesseNumber !== undefined) {
      let size: number;
      this.heldin.spezies.koerpergroesse.forEach((strk) => {
        const array: string[] = strk.split(' + ');
        size = +array[0];
        size += this.heldin.groesseNumber;
      });
      this.heldin.spezies.gewicht.forEach((str) => {
        // console.log(str);
        const array: string[] = str.split(' ');
        let weight: number = size;
        // console.log('weight: ' + weight);
        weight -= +array[2];
        // console.log('weight: ' + weight);
        if (this.heldin.spezies.art === 'Zwerge') {
          weight += this.heldin.gewichtNumber;
        } else {
          if (array[3].includes('/')) {
            if (this.heldin.gewichtNumber % 2 === 0) {
              // console.log('gerade');
              weight += this.heldin.gewichtNumber;
            } else {
              // console.log('ungerade');
              weight -= this.heldin.gewichtNumber;
            }
          } else {
            if (array[3] === '+') {
              weight += this.heldin.gewichtNumber;
            } else {
              weight -= this.heldin.gewichtNumber;
            }
          }
        }
        this.heldin.gewicht = weight;
        this.$gewichtStr = this.heldin.gewicht + ' Stein';
      });
      this.sendEvent();
    }
  }

  public getKoerperGroesseWurf(): void {
    this.$wuerfelGroesse = this.heldin.spezies.koerpergroesse[0].split(' + ')[1];
  }

  public getGewichtWurf(): void {
    const array: string[] = this.heldin.spezies.gewicht[0].split(' ');
    let start = -1;
    let end = -1;
    let str = '';
    // console.log(array);
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes('W')) {
        if (start < 0) {
          start = i;
          end = i;
        } else {
          end = i;
        }
      }
    }
    if (start > 0 && start !== end) {
      start --;
    }
    // console.log('start: ' + start + ', end: ' + end);
    for (let i = start; i <= end; i++) {
      str += array[i];
    }
    this.$wuerfelGewicht = str;
  }

  public getBrustgroesse(): void {
    this.heldin.spezies.brustgroesse.forEach((str) => {
      const array: string[] = str.split(': ');
      const brueste: string = array[0];
      let brustnummer = this.heldin.bruesteNumber;
      console.log(this.heldin.weiblich);
      if (!this.heldin.weiblich) {
        brustnummer -= 12;
        if (brustnummer < 3) {
          brustnummer = 3;
        }
        console.log(brustnummer);
      }
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === brustnummer) {
            this.heldin.brueste = brueste;
          }
        }
      } else {
        if (+array[1] === brustnummer) {
          this.heldin.brueste = brueste;
        }
      }
    });
    this.sendEvent();
  }

  public getSchamhaare(): void {
    this.heldin.spezies.schamhaarmenge.forEach((str) => {
      const array: string[] = str.split(': ');
      const schamhaare: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.schamhaareNumber) {
            this.heldin.schamhaare = schamhaare;
          }
        }
      } else {
        if (+array[1] === this.heldin.schamhaareNumber) {
          this.heldin.schamhaare = schamhaare;
        }
      }
    });
    this.sendEvent();
  }

  public getGeschlechtsorgan(): void {
    let stringarray: string[];
    console.log(this.heldin.weiblich);
    if (this.heldin.weiblich === true) {
      console.log('weiblich');
      stringarray = this.heldin.spezies.vaginagroesse;
    } else {
      console.log('männlich');
      stringarray = this.heldin.spezies.penisgroesse;
    }
    stringarray.forEach((str) => {
      const array: string[] = str.split(': ');
      const geschlecht: string = array[0];
      if (array[1].includes(';')) {
        const unterarray: string[] = array[1].split(';');
        for (let i = +unterarray[0]; i <= +unterarray[1]; i++) {
          if (i === this.heldin.genitalNumber) {
            this.heldin.genital = geschlecht;
            // this.$geschlechtsorganStr = geschlecht;
          }
        }
      } else {
        if (+array[1] === this.heldin.genitalNumber) {
          this.heldin.genital = geschlecht;
          // this.$geschlechtsorganStr = geschlecht;
        }
      }
    });
    this.sendEvent();
  }

  public switchGeneration(): void {
    this.$generate = !this.$generate;
  }

  public sendEvent(): void {
    // console.log('sendEvent');
    let af = this.heldin.augenfarbeNumber;
    let hf = this.heldin.haarfarbeNumber;
    let gr = this.heldin.groesseNumber;
    let gw = this.heldin.gewichtNumber;
    let sh = this.heldin.schamhaareNumber;
    let br = this.heldin.bruesteNumber;
    let go = this.heldin.genitalNumber;
    af = this.randomIfUndefined(af, 'W20');
    hf = this.randomIfUndefined(hf, 'W20');
    gr = this.randomIfUndefined(gr, this.$wuerfelGroesse);
    gw = this.randomIfUndefined(gw, this.$wuerfelGewicht);
    sh = this.randomIfUndefined(sh, '3W6');
    br = this.randomIfUndefined(br, '3W6');
    go = this.randomIfUndefined(go, '3W6');
    const eventObject: Step2 = {
      augenfarbe: af,
      haarfarbe: hf,
      groesse: gr,
      gewicht: gw,
      schamhaare: sh,
      brueste: br,
      geschlechtsorgan: go
    };
    this.ping.emit(eventObject);
  }

  public randomIfUndefined(value: number, berechnung: string): number {
    // console.log('KoerperattributeComponent/randomIfUndefined(' + value + ', ' + berechnung + ')');
    if (value === undefined) {
      const workarray = [];
      value = 0;
      if (berechnung.includes('+')) {
        const bigArray = berechnung.split('+');
        bigArray.forEach(str => {
          workarray.push(str);
        });
      } else {
        workarray.push(berechnung);
      }
      workarray.forEach(str => {
        const array = str.split('W');
        let multiStr: string = array[0];
        multiStr = multiStr.replace('–', '-');
        let multiplicator = 1;
        if (multiStr !== '') {
          multiplicator = +multiStr;
        }
        const dice: number = +array[1];
        let sum = 0;
        for (let i = 0; i < Math.abs(multiplicator); i++) {
          sum += this.getRandomInt(1, dice);
        }
        if (multiplicator >= 0) {
          value += sum;
        } else {
          value -= sum;
        }
      });
    }
    return value;
  }


  public getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * Math.floor(max + 1 - min)) + min;
  }

  public checkIfVorhanden(): void {
    // haarfarbe
    if (this.heldin.haarfarbeNumber !== undefined) {
      console.log('getHaarfarbe');
      this.getHaarfarbe();
    }
    // augenfarbe
    if (this.heldin.augenfarbeNumber !== undefined) {
      this.getAugenfarbe();
    }
    // größe
    if (this.heldin.groesseNumber !== undefined) {
      this.getKoerpergroesse();
    }
    // gewicht
    if (this.heldin.gewichtNumber !== undefined) {
      if (this.heldin.groesseNumber !== undefined) {
        this.getKoerpergroesse();
      }
      this.getGewicht();
    }
    // brust
    if (this.heldin.bruesteNumber !== undefined) {
      this.getBrustgroesse();
    }
    // schamhaare
    if (this.heldin.schamhaareNumber !== undefined) {
      this.getSchamhaare();
    }
    // genital
    if (this.heldin.genitalNumber !== undefined) {
      this.getGeschlechtsorgan();
    }
  }
}
