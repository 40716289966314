<div class="form">
  <input type="text" [(ngModel)]="user$.username" placeholder="Username">
  <input type="password" [(ngModel)]="user$.password" placeholder="Passwort" (keyup.enter)="authenticate()">
  <input type="button" class="button" value="anmelden" (click)="authenticate()">
</div>
<div class="error" *ngIf="$errorMessage">
  Benutzername oder Passwort sind falsch.
  Es gibt folgende User:
  <div class="user" *ngFor="let user of $userlist">
    {{ user.username }}
  </div>
</div>

