<div *ngIf="heldin.kultur !== null">
  <h3>Wähle eine Kultur</h3>
  <div>
    Alle Kulturen anzeigen:
    <input type="checkbox" (click)="switchKulturen()" [checked]="!$typischeKulturen">
  </div>
  <select [(ngModel)]="$kulturIndex" (ngModelChange)="getKultur()">
    <option [value]="$choosableKultur.indexOf(kultur)" *ngFor="let kultur of $choosableKultur">{{kultur.paketKosten}}</option>
  </select>
  <h3>Durch Kultur modifizierte Talente</h3>
  <div *ngFor="let talentmod of heldin.kultur.talentModifikatoren">
    {{talentmod}}
  </div>
  <h3 class="textHead">{{heldin.kultur.name}}</h3>
  <div class="textblock">
    {{ heldin.kultur.beschreibung }}
  </div>
  <h3 class="textHead">Verbreitung & Lebensweise</h3>
  <div class="textblock">
    {{ heldin.kultur.verbreitungUndLebensweise }}
  </div>
  <h3 class="textHead">Weltsicht & Glaube</h3>
  <div class="textblock">
    {{ heldin.kultur.weltsichtUndGlaube }}
  </div>
  <h3 class="textHead">Sitten & Bräuche</h3>
  <div class="textblock">
    {{ heldin.kultur.sittenUndBraeuche }}
  </div>
  <h3 class="textHead">Tracht & Bewaffnung</h3>
  <div class="textblock">
    {{ heldin.kultur.trachtUndBewaffnung }}
  </div>
</div>
