<div *ngIf="heldin.profession !== null">
  <h3>Wähle eine Profession</h3>
  <div>
    <select [(ngModel)]="$professionsart" (ngModelChange)="getGattungenOfProfessionsart(false)">
      <option [value]="art" *ngFor="let art of $professionsarten">{{ art }}</option>
    </select>
  </div>
  <div>
    <select [(ngModel)]="$professionsgattung" (ngModelChange)="getProfessionsgattung(false)">
      <option [value]="gattung" *ngFor="let gattung of $professionsgattungen index as i">{{ gattung }} ({{$gattungskosten[i]}} AP)</option>
    </select>
  </div>
  <select [(ngModel)]="$professionIndex" (ngModelChange)="getProfession()" *ngIf="!heldin.weiblich">
    <option [value]="$choosableProfession.indexOf(profession)" *ngFor="let profession of $choosableProfession">{{profession.nameM}} ({{profession.paketKosten}} AP)</option>
  </select>
  <select [(ngModel)]="$professionIndex" (ngModelChange)="getProfession()" *ngIf="heldin.weiblich">
    <option [value]="$choosableProfession.indexOf(profession)" *ngFor="let profession of $choosableProfession">{{profession.nameW}} ({{profession.paketKosten}} AP)</option>
  </select>
  <h3>Durch deine Profession erhaltene Talentwerte und Kampftechniken</h3>
  <div class="boxen">

    <div class="innerbox">
      <div *ngIf="heldin.profession.empfohleneVorteile !== undefined || heldin.profession.ungeeigneteVorteile !== undefined">
        <h3>Vorteile</h3>
        <div *ngIf="heldin.profession.empfohleneVorteile !== undefined">
          <div class="minihead">empfohlen</div>
          <div *ngFor="let vun of heldin.profession.empfohleneVorteile">{{vun}}</div>
        </div>
        <div *ngIf="heldin.profession.ungeeigneteVorteile !== undefined">
          <div class="minihead">ungeeignet</div>
          <div *ngFor="let vun of heldin.profession.ungeeigneteVorteile">{{vun}}</div>
        </div>
      </div>
      <br *ngIf="heldin.profession.empfohleneVorteile !== undefined || heldin.profession.ungeeigneteVorteile !== undefined"/>
      <div *ngIf="heldin.profession.empfohleneNachteile !== undefined || heldin.profession.ungeeigneteNachteile !== undefined">
        <h3>Nachteile</h3>
        <div *ngIf="heldin.profession.empfohleneNachteile !== undefined">
          <div class="minihead">empfohlen</div>
          <div *ngFor="let vun of heldin.profession.empfohleneNachteile">{{vun}}</div>
        </div>
        <div *ngIf="heldin.profession.ungeeigneteNachteile !== undefined">
          <div class="minihead">ungeeignet</div>
          <div *ngFor="let vun of heldin.profession.ungeeigneteNachteile">{{vun}}</div>
        </div>
      </div>
    </div>
    <div class="innerbox">
      <h3>Talente</h3>
      <div *ngFor="let talent of heldin.profession.talente">
        {{talent}}
      </div>
    </div>

    <div class="innerbox">
      <h3>Kampftechniken</h3>
      <div *ngFor="let kampftechnik of heldin.profession.kampftechniken">
        <div *ngIf="!kampftechnik.includes(',')">{{ kampftechnik }}</div>
      </div>
      <div *ngIf="$kampftechnikwahl !== undefined">
        <div class="minihead">{{$kampftechnikwahl}} wählbare aus</div>
        <div *ngFor="let kampftechnik of $kampftechniken">
          <div>{{ kampftechnik }} {{$kampftechnikFw}}</div>
        </div>
      </div>
      <h3>Sonderfertigkeiten</h3>
      <div *ngFor="let sf of heldin.profession.sonderfertigkeiten">
        {{sf}}
      </div>
    </div>
    <div class="innerbox" *ngIf="heldin.profession.zauber !== undefined">
      <h3 *ngIf="heldin.profession.zauber.length > 0">Zauber & Zaubertricks</h3>
      <div *ngFor="let zauber of heldin.profession.zauber">
        <div *ngIf="!zauber.includes('Zaubertrick')">{{zauber}}</div>
      </div>
      <div *ngIf="$zaubertrickwahl !== undefined">
        <div class="minihead">{{$zaubertrickwahl}}</div>
        <div *ngFor="let zaubertrick of $zaubertricks">
          <div>{{ zaubertrick }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="beschreibung">
    <div class="linkeSpalte">
      <h3 class="textHead" *ngIf="!heldin.weiblich">{{heldin.profession.nameM}}</h3>
      <h3 class="textHead" *ngIf="heldin.weiblich">{{heldin.profession.nameW}}</h3>
      <div class="textblock">
        {{ heldin.profession.beschreibung }}
      </div>
      <h3 class="textHead">Ausrüstung & Tracht</h3>
      <div class="textblock">
        {{ heldin.profession.ausruestungUndTracht }}
      </div>
    </div>
    <div class="rechteSpalte">
      <img class="professionsImage" src="../../../assets/images/professionen/{{heldin.profession.nameM}}.png"/>
    </div>
  </div>
</div>


