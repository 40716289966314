import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {Benutzer} from '../../../_classes/benutzer/benutzer';
import {Limits} from '../../../_classes/charakter/limits';
import {Heldin} from '../../../_classes/charakter/heldin';
import {Data} from '@angular/router';
import {DataService} from '../../../_services/data.service';

@Component({
  selector: 'app-a-erfahrung',
  templateUrl: './a-erfahrung.component.html',
  styleUrls: ['./a-erfahrung.component.sass']
})
export class AErfahrungComponent implements OnInit, OnChanges {
  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  @Input() heldin: Heldin;
  @Input() limits: Limits;

  public label: string[];
  public erfahrungsInfo: string[][];

  stufen: string[] = ['unerfahren', 'durchschnittlich', 'erfahren', 'kompetent', 'meisterlich', 'brilliant', 'legendär'];
  gewaehlteStufe = 0;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.gewaehlteStufe = this.limits.stufe;
    this.loadData();
  }

  public loadData(): void {
    console.log('HTTP GET: /glossary/erfahrung/' + this.gewaehlteStufe);
    const $url: string = '/glossary/erfahrung/' + this.gewaehlteStufe;
    this.dataService.request2DStringArray($url).subscribe((data: string[][]) => {
      console.log(data);
      this.erfahrungsInfo = data;
      this.limits.aktualisiere(this.gewaehlteStufe, data);
      this.heldin.setBeeinflWert('AP', '0#Start-AP', String(this.limits.startAP));
      console.log(this.heldin);
      this.update.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change: SimpleChange = changes.label;
    console.log('erfahrung -> ngOnChanges');
    this.ngOnInit();
  }



}
