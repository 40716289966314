export class Gsozialstatus {
  stufe: number;
  name: string;
  beispiele: string;

  public copy(other: Gsozialstatus): void {
    this.stufe = other.stufe;
    this.name = other.name;
    this.beispiele = other.beispiele;
  }
}
