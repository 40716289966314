import {Gsonderfertigkeit} from './gsonderfertigkeit';

export class Gkampfsonderfertigkeit extends Gsonderfertigkeit {
  kampffertigkeiten: string[];
  freigeschalteteSonderfertigkeiten: string[];
  begrenztAufWaffen: string[];

  public copy(other: Gkampfsonderfertigkeit): void {
    this.klasse = other.klasse;
    this.art = other.art;
    this.unterArt = other.unterArt;
    this.name = other.name;
    this.beschreibung = other.beschreibung;
    this.regel = other.regel;
    this.stufen = other.stufen;
    this.spezifikationNoetig = other.spezifikationNoetig;
    this.voraussetzungen = other.voraussetzungen;
    this.kampffertigkeiten = other.kampffertigkeiten;
    this.freigeschalteteSonderfertigkeiten = other.freigeschalteteSonderfertigkeiten;
    this.begrenztAufWaffen = other.begrenztAufWaffen;
  }
}
