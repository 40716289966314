import {Gegenstand} from './gegenstand';

export class Zaehlbar extends Gegenstand {
  beschreibung: string;
  zitat: string;
  preis: number;
  gewicht: number;
  komplexitaet: string;
  anzahl: number;

  public copy(other: Zaehlbar): void {
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
    this.beschreibung = other.beschreibung;
    this.zitat = other.zitat;
    this.preis = other.preis;
    this.gewicht = other.gewicht;
    this.komplexitaet = other.komplexitaet;
    this.anzahl = other.anzahl;
  }
}
