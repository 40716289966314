export class Gvunratschlag {
  name: string;
  stufe: number;
  gewaehlteKategorie: string;
  spezifikation: string;
  ratschlag: string;

  public copy(other: Gvunratschlag): void {
    this.name = other.name;
    this.stufe = other.stufe;
    this.gewaehlteKategorie = other.gewaehlteKategorie;
    this.spezifikation = other.spezifikation;
    this.ratschlag = other.ratschlag;
  }
}
