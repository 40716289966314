export class Sonderfertigkeit {
  name: string;
  stufe: number;
  gewaehlteKategorie: string;
  spezifikation: string;

  public copy(other: Sonderfertigkeit): void {
    this.name = other.name;
    this.stufe = other.stufe;
    this.gewaehlteKategorie = other.gewaehlteKategorie;
    this.spezifikation = other.spezifikation;
  }
}
