import {Zaehlbar} from './zaehlbar';

export class Ruestung extends Zaehlbar {
  kategorie: string;
  vorteil: string;
  nachteil: string;
  rsKopf: number;
  rsTorso: number;
  rslArm: number;
  rsrArm: number;
  rslBein: number;
  rsrBein: number;
  verschleiss: number;

  constructor() {
    super();
    this.rsKopf = 0;
    this.rsTorso = 0;
    this.rslArm = 0;
    this.rsrArm = 0;
    this.rslBein = 0;
    this.rsrBein = 0;
  }

  public copy(other: Ruestung): void {
    this.id = other.id;
    this.name = other.name;
    this.type = other.type;
    this.subtyp = other.subtyp;
    this.dateiname = other.dateiname;
    this.notiz = other.notiz;
    this.beschreibung = other.beschreibung;
    this.zitat = other.zitat;
    this.preis = other.preis;
    this.gewicht = other.gewicht;
    this.komplexitaet = other.komplexitaet;
    this.anzahl = other.anzahl;
    this.kategorie = other.kategorie;
    this.vorteil = other.vorteil;
    this.nachteil = other.nachteil;
    this.rsKopf = other.rsKopf;
    this.rsTorso = other.rsTorso;
    this.rslArm = other.rslArm;
    this.rsrArm = other.rsrArm;
    this.rslBein = other.rslBein;
    this.rsrBein = other.rsrBein;
    this.verschleiss = other.verschleiss;
  }
}
