import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Heldin} from '../../_classes/charakter/heldin';
import {Glossar} from '../../_classes/glossar/glossar';
import {Alkohol} from '../../_classes/ausruestung/alkohol';
import {Proben} from '../../_interfaces/options/proben';
import {PersistenceService} from '../../_services/persistence.service';
import {Notiz} from "../../_classes/charakter/notiz";

@Component({
  selector: 'app-probenblatt',
  templateUrl: './probenblatt.component.html',
  styleUrls: ['./probenblatt.component.sass']
})
export class ProbenblattComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;

  options: Proben = {
    nav: 0,
    nav2: 0,
    nav3: 0,
    panikStage: 0,
    panikArt: 0,
    panikDauerEinheit: 0,
    panikDauer: 0,
  };

  stufen: string[] = ['keine', 'Stufe I', 'Stufe II', 'Stufe III', 'Stufe IV'];

  // navBar: string[] = ['Jagd', 'Wache halten', 'Zechen'];
  navBar: string[] = ['Panik', 'Zechen'];

  alkSorten: string[] = ['Bier', 'Wein', 'Schnaps'];
  biere: Alkohol[] = [];
  weine: Alkohol[] = [];
  schnaepse: Alkohol[] = [];
  waehlbar: Alkohol[] = [];
  gewaehlt: Alkohol;
  wurf = 6;
  wuerfe: number[] = [];

  zechen: string[] = [];
  zechenTaP: number[] = [];
  wertZechen = 0;

  willenskraft: string[] = [];
  willenskraftTaP: number[] = [];
  wertWillenskraft = 0;
  panikursache = 0;
  skMod = 0;
  panikName = '';
  panikBeschreibung = '';
  panikDauerEinheit = '';

  panikWurf: string[] = ['Angriffslust', 'Angriffslust', 'Angriffslust', 'Brabbeln',
    'Brabbeln', 'Hysterie', 'Hysterie', 'Ohnmacht', 'Ohnmacht', 'Ohnmacht', 'Phobie',
    'Phobie', 'Phobie', 'Schreikrampf', 'Schreikrampf', 'Schreikrampf', 'Stumpfsinn',
    'Stumpfsinn', 'Stumpfsinn', 'Wegrennen'];

  panikArten: string[] = ['Angriffslust', 'Brabbeln', 'Hysterie', 'Ohnmacht', 'Phobie', 'Schreikrampf',
    'Stumpfsinn', 'Wegrennen'];

  panikBeschreibungen: string[] = ['Du wirst von einer unbändigen Angriffslust gegenüber der Panikursache gepackt. ' +
  'Du verfällst in den Status Blutrausch (ohne, dass dieser nach 2W20 KR endet) und greifst die Panikursache ' +
  'an.',
  'Du kannst dich nicht mehr richtig artikulieren. Bei Zaubern und Liturgien bedeutet dies, ' +
  'dass du die Formel bzw. das Gebet nicht aufsagen kannst und somit eine Erschwernis von 2 Punkten für deren ' +
  'Weglassen erhältst.',
  'Durch die Panikursache verfällst du in Hysterie, was sich in blindem Aktionismus und Aufgeregtheit äußert. ' +
  'Dadurch erleidest du bei allen Proben eine Erschwernis von 2.',
  'Du verlierst auf der Stelle das Bewusstsein. Du erhältst die Status Bewusstlos und Liegend.',
  'Du bekommst vor der Panikursache oder einem vom Meister festgelegten Auslöser panische Angst und erhältst für ' +
  'die Dauer des Panik-Status den Nachteil Angst vor … III.',
  'Du fängst an zu schreien. Während dieser Zeit erhältst du den Status Handlungsunfähig. Darüber hinaus ' +
  'sind Proben auf Sinnesschärfe, um dich zu hören, um 2 erleichtert.',
  'Sobald du diesen Panik-Status erleidest, begibst du dich in eine Fötalposition. Dadurch erhältst du die Status ' +
  'Bewegungsunfähig, Handlungsunfähig und Liegend.',
  'Du läufst auf der Stelle vor der Panikursache davon. Du versuchst so viel Platz zwischen dich und die ' +
  'Ursache zu bringen, wie du kannst. Dazu benutzt du während des Panik-Status alle Handlungen für deine ' +
  'Bewegung. Solltest du nicht weiter vorankommen, so erleidest du bis zum Ende des Panik-Status zusätzlich ' +
  'den Status Handlungsunfähig. Sollte die Panikursache ein Wesen sein, so steht ihm sofort ' +
  'ein Passierschlag gegen dich zu.'];

  panikZeiteinheiten: string[][] = [['KR', 'KR', 'KR', 'KR', 'KR', 'Minuten'],
  ['KR', 'KR', 'Minuten', 'Minuten', 'Stunden', 'Tage'],
  ['KR', 'KR', 'Minuten', 'Minuten', 'Stunden', 'Tage'],
  ['KR', 'KR', 'KR', 'Minuten', 'Minuten', 'Stunden'],
  ['KR', 'KR', 'Minuten', 'Minuten', 'Minuten', 'Stunden'],
  ['KR', 'KR', 'KR', 'KR', 'Minuten', 'Minuten'],
  ['KR', 'KR', 'KR', 'Minuten', 'Minuten', 'Minuten'],
  ['KR', 'KR', 'KR', 'KR', 'KR', 'Minuten']];

  wertWurf = 1;
  ergebnis = 0;
  reaktion = '';
  /** DEBUG */
  posBesonders: Alkohol[] = [];


  constructor(private persistenceService: PersistenceService) { }

  ngOnInit(): void {
    if (this.charakter.getWert('bisherige Getränke') == null) {
      this.charakter.setWert('bisherige Getränke', 0);
    }
    const stored: Proben = this.persistenceService.retrieveComponent('Proben');
    if (stored != null) {
      this.options = stored;
    }
    this.posBesonders = [];

    this.biere = [];
    this.weine = [];
    this.schnaepse = [];
    this.glossar.ausruestung.forEach(item => {
      if (item.type === 'Alkohol') {
        if (item.subtyp === 'Bier') {
          this.biere.push(item as Alkohol);
        } else if (item.subtyp === 'Wein') {
          this.weine.push(item as Alkohol);
        } else if (item.subtyp === 'Schnaps') {
          this.schnaepse.push(item as Alkohol);
        }
        if ((item as Alkohol).wirkungPos !== '1 Berauscht' || (item as Alkohol).wirkungNeg !== '2 Berauscht') {
          this.posBesonders.push(item as Alkohol);
        }
      }
    });
    this.biere.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.weine.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.schnaepse.sort((a, b) => (a.name < b.name ? -1 : 1));


    this.posBesonders.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.posBesonders.sort((a, b) => (a.subtyp < b.subtyp ? -1 : 1));

    this.waehleAlkSorte();
    this.aktualisiereWerte();
    this.setPanik();
  }

  aktualisiereWerte(): void {
    this.aktualisiereZechenWerte();
    this.aktualisiereWillenskraftWerte();
  }

  aktualisiereZechenWerte(): void {
    this.zechen = [];
    this.zechenTaP = [];
    this.glossar.talente.forEach(talent => {
      if (talent.name === 'Zechen') {
        talent.probe.forEach(attr => {
          let wert = this.charakter.getCurrentWert(attr);
          this.charakter.calculateGesamtModifikationFuer('Zechen');
          wert -= this.charakter.getWert(talent.name).abzug;
          // wert -= this.charakter.calculateGesamtModifikationFuer('Zechen');
          this.zechen.push(attr + ' ( ' + wert + ' )');
        });
        const fw = this.charakter.getWert(talent.name).wert;
        this.zechen.push('FW: ' + fw);
        for (let i = 0; i <= fw; i++) {
          this.zechenTaP.push(i);
        }
      }
    });
  }

  aktualisiereWillenskraftWerte(): void {
    this.willenskraft = [];
    this.willenskraftTaP = [];
    this.glossar.talente.forEach(talent => {
      if (talent.name === 'Willenskraft') {
        talent.probe.forEach(attr => {
          let wert = this.charakter.getCurrentWert(attr);
          this.charakter.calculateGesamtModifikationFuer('Willenskraft');
          wert -= this.charakter.getWert(talent.name).abzug;
          wert -= this.panikursache;
          this.willenskraft.push(attr + ' ( ' + wert + ' )');
        });
        let fw = this.charakter.getWert(talent.name).wert;
        if (this.charakter.hatSpezialisierung('Willenskraft', 'Bedrohungen standhalten')) {
          fw += 2;
        }
        this.willenskraft.push('FW: ' + fw);
        for (let i = 0; i <= fw; i++) {
          this.willenskraftTaP.push(i);
        }
      }
    });
  }

  resetPanik(wert: number): void {
    this.options.panikStage = 0;
    this.options.panikArt = 0;
    this.options.panikDauer = 0;
    this.options.panikDauerEinheit = 0;
    this.changeNav(wert);
  }

  changeNav(wert: number): void {
    this.options.nav = wert;
    this.options.nav2 = 0;
    this.options.nav3 = 0;
    this.persistenceService.storeComponent('Proben', this.options);
    this.aktualisiereWerte();
  }

  waehleAlkSorte(): void {
    // Falls alkSorte umgestellt wurde
    if (this.waehlbar.length > 0) {
      if (this.alkSorten.indexOf(this.waehlbar[this.options.nav3].subtyp) !== this.options.nav2) {
        this.options.nav3 = 0;
      }
    }
    this.waehlbar = [];
    if (Number(this.options.nav2) === 0) {
      this.biere.forEach(alk => {
        this.waehlbar.push(alk);
      });
    } else if (Number(this.options.nav2) === 1) {
      this.weine.forEach(alk => {
        this.waehlbar.push(alk);
      });
    } else if (Number(this.options.nav2) === 2) {
      this.schnaepse.forEach(alk => {
        this.waehlbar.push(alk);
      });
    }
    this.persistenceService.storeComponent('Proben', this.options);
    this.waehleAlk();
  }

  waehleAlk(): void {
    this.gewaehlt = this.waehlbar[this.options.nav3];
    if (this.gewaehlt.wirkungPos === 'keine') {
      this.wurf = 4;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '2 Berauscht') {
      this.wurf = 6;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '3 Berauscht') {
      this.wurf = 8;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '1 Betäubung') {
      this.wurf = 8;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '2 Berauscht, 1 Verwirrung') {
      this.wurf = 10;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '1 Verwirrung, 1 Berauscht') {
      this.wurf = 10;
    } else if (this.gewaehlt.wirkungPos === '1 Berauscht' && this.gewaehlt.wirkungNeg === '1 Berauscht, 1 Verwirrung') {
      this.wurf = 10;
    } else if (this.gewaehlt.wirkungPos === '2 Berauscht' && this.gewaehlt.wirkungNeg === '3 Berauscht') {
      this.wurf = 12;
    } else if (this.gewaehlt.wirkungPos === '2 Berauscht' && this.gewaehlt.wirkungNeg === '1 Betäubung') {
      this.wurf = 12;
    } else if (this.gewaehlt.wirkungPos === '2 Berauscht' && this.gewaehlt.wirkungNeg === '1 Betäubung, 1 Verwirrung') {
      this.wurf = 12;
    }
    this.wuerfe = [];
    for (let i = 1; i <= this.wurf; i++) {
      this.wuerfe.push(i);
    }
    this.persistenceService.storeComponent('Proben', this.options);
  }

  public decreaseBisherigeGetraenke(): void {
    if (this.charakter.getCurrentWert('bisherige Getränke') - 1 >= 0) {
      this.charakter.decreaseBeeinflWert('bisherige Getränke', 'manuell');
    }
    this.charakter.calculateGesamtModifikationFuer('bisherige Getränke');
    this.ping.emit();
  }

  public increaseBisherigeGetraenke(): void {
    this.charakter.increaseBeeinflWert('bisherige Getränke', 'manuell');
    this.charakter.calculateGesamtModifikationFuer('bisherige Getränke');
    this.ping.emit();
  }

  trinken(): void {
    let wert: number = (this.wertWurf - -this.charakter.getCurrentWert('bisherige Getränke')) - this.wertZechen;
    if (wert < 0) {
      wert = 0;
    }
    this.ergebnis = wert;
    this.increaseBisherigeGetraenke();
    const reaktionen: string[][] = [];
    if (this.gewaehlt.subtyp === 'Bier') {
      reaktionen.push(['Erfrischend!', 'Ist da überhaupt Alkohol drin?!']);
      reaktionen.push(['Lässt sich gut wegtrinken.']);
      reaktionen.push(['Morgen hab ich bestimmt nen Wolf...']);
      reaktionen.push(['Morgen hab ich sicher nen Werwolf...']);
      reaktionen.push(['Ich glaube ich kotze gleich!']);
    } else if (this.gewaehlt.subtyp === 'Wein') {
      reaktionen.push(['Erfrischend!', 'Ist da überhaupt Alkohol drin?!']);
      reaktionen.push(['Lässt sich gut wegtrinken.']);
      reaktionen.push(['Morgen hab ich bestimmt nen Wolf...']);
      reaktionen.push(['Morgen hab ich sicher nen Werwolf...']);
      reaktionen.push(['Ich glaube ich kotze gleich!']);
    } else if (this.gewaehlt.subtyp === 'Schnaps') {
      reaktionen.push(['Geht runter wie Öl!', 'Ist da überhaupt Alkohol drin?!']);
      reaktionen.push(['Lässt sich gut wegtrinken.']);
      reaktionen.push(['Morgen hab ich bestimmt nen Wolf...']);
      reaktionen.push(['Morgen hab ich sicher nen Werwolf...', 'Ouuu das brennt!', '*Hust* *Hust*']);
      reaktionen.push(['Ich glaube ich kotze gleich!', 'Schaaarf!', 'Meine Innereien brennen!', 'Alles brennt!']);
    }
    let stufe = 0;
    if (wert > 10) {
      stufe = 4;
    } else if (wert > 6) {
      stufe = 3;
    } else if (wert > 3) {
      stufe = 2;
    } else if (wert > 1) {
      stufe = 1;
    } else {
      stufe = 0;
    }
    const count = reaktionen[stufe].length;
    const index = Math.floor(Math.random() * count);
    this.reaktion = reaktionen[stufe][index];
    for (let i = 0; i < wert; i++) {
      this.increasePromille();
    }
    setTimeout(() => {
      this.reaktion = '';
    }, 3000);
    this.aktualisiereZechenWerte();
  }

  public increasePromille(): void {
    if (this.charakter.getCurrentWert('Trink-AuP') - 1 >= 0) {
      this.charakter.decreaseBeeinflWert('Trink-AuP', 'manuell');
    }
    this.charakter.calculateGesamtModifikationFuer('Trink-AuP');
    this.ping.emit();
  }

  public decreaseBeeinflussterWert(name: string): void {
    console.log('decreaseBeeinflussterWert ' + name);
    const beinfWert = Number(this.charakter.getBeeinflWert(name, 'manuell').getWert());
    this.charakter.setBeeinflWert(name, 'manuell', String(beinfWert - 1));
    this.ping.emit();
  }

  public increaseBeeinflussterWert(name: string): void {
    console.log('increaseBeeinflussterWert ' + name);
    const beinfWert = Number(this.charakter.getBeeinflWert(name, 'manuell').getWert());
    this.charakter.setBeeinflWert(name, 'manuell', String(beinfWert + 1));
    this.ping.emit();
  }

  probeGeschafft(geschafft: boolean): void {
    if (geschafft) {
      this.options.panikStage = 3;
    } else {
      this.options.panikStage = 1;
      this.increaseBeeinflussterWert('Furcht');
    }
    this.persistenceService.storeComponent('Proben', this.options);
  }

  angstWurf(w: number): void {
    const sk: number = this.charakter.getWert('SK').akt();
    if (sk > 2) {
      w++;
    } else if (sk < 1) {
      w--;
    }
    w = Math.min(Math.max(w, 1), 6);
    if (w <= this.charakter.getWert('Furcht').akt()) {
      this.options.panikStage = 2;
      const w20: number = Math.floor(Math.random() * 20);
      const w6: number = Math.floor(Math.random() * 6);
      const w6p1: number = Math.floor(Math.random() * 6) + 2;
      this.options.panikArt = w20;
      this.options.panikDauerEinheit = w6;
      this.options.panikDauer = w6p1;
      this.setPanik();

    } else {
      this.options.panikStage = 3;
    }

    this.persistenceService.storeComponent('Proben', this.options);
  }

  setPanik(): void {
    this.panikName = this.panikWurf[this.options.panikArt];
    const index: number = this.panikArten.indexOf(this.panikName);
    this.panikBeschreibung = this.panikBeschreibungen[index];
    this.panikDauerEinheit = this.panikZeiteinheiten[index][this.options.panikDauerEinheit];
  }
}
