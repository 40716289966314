import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  ip = '192.168.178.44';

  constructor(private http: HttpClient) { }

  public getIp(): string {
    // return '192.168.178.44';
    // return '192.168.0.83';
    // return '192.168.188.55';
    return 'h3004666.stratoserver.net';
  }

  private getServerURL(): void {
    const promise = this.http.get('http://api.ipify.org/?format=json').toPromise();
    promise.then((data) => {
      // @ts-ignore
      this.ip = data.ip;
    }).catch((error) => {
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }
}
