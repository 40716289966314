import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Spezies} from '../../../_interfaces/spezies';
import {DataService} from '../../../_services/data.service';
import {Step0} from '../../../_interfaces/charaktererstellung/step0';
import {Step1} from '../../../_interfaces/charaktererstellung/step1';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';

@Component({
  selector: 'app-spezies',
  templateUrl: './spezies.component.html',
  styleUrls: ['./spezies.component.sass']
})
export class SpeziesComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() heldin: Heldenerschaffung;

  public $speziesMenu: Spezies[];
  public $speziesIndex: number;
  public $eigMods: string[];

  constructor(public dataService: DataService) {
    // console.log('step 1/constructor');
    this.$eigMods = [];
  }

  ngOnInit(): void {
    this.getAlleSpezies();
  }

  public getSpeziesIndex(): void {
    if (this.heldin.spezies !== null) {
      for (let i = 0; i < this.$speziesMenu.length; i++) {
        if (this.$speziesMenu[i].unterArt === this.heldin.spezies.unterArt) {
          this.$speziesIndex = i;
        }
      }
    } else {
      this.$speziesIndex = 0;
    }
  }

  public getSpezies(): void {
    this.heldin.spezies = this.$speziesMenu[this.$speziesIndex];
    this.heldin.speziesWurdeGeaendert = true;
    this.getEigenschaftsMods();
    this.ping.emit();
  }

  public getAlleSpezies(): void {
    this.dataService.getAlleSpezies().subscribe((data: Spezies[]) => {
      data.sort((a, b) => (a.unterArt < b.unterArt ? -1 : 1));
      data.sort((a, b) => (a.art < b.art ? -1 : 1));
      // console.log('step 1/getAlleSpezies()/data:');
      // console.log(data);
      this.$speziesMenu = data;
      this.getSpeziesIndex();
      this.getSpezies();
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getEigenschaftsMods(): void {
    this.heldin.eigenschaftenWahlAusSpezies = [];
    this.heldin.eigenschaftenWahlAusSpeziesGewaehlt = [];
    this.$eigMods = [];
    let eig: string;
    this.heldin.spezies.eigenschaftsAenderungen.forEach(mod => {
      if (mod.includes(' || ')) { // z.B: "KL: -2 || KK: -2"
        // manuelle Wahl erforderlich
        const arr = mod.split(' || ');
        eig = arr[0].split(': ')[0];
        this.heldin.eigenschaftenWahlAusSpezies.push(arr[0]);
        this.heldin.eigenschaftenWahlAusSpeziesGewaehlt.push(true);
        for (let i = 1; i < arr.length - 1; i++) {
          this.heldin.eigenschaftenWahlAusSpezies.push(arr[i]);
          this.heldin.eigenschaftenWahlAusSpeziesGewaehlt.push(false);
          eig += ', ' + arr[i].split(': ')[0];
        }
        eig += ' oder ';
        let last = arr[arr.length - 1];
        this.heldin.eigenschaftenWahlAusSpezies.push(arr[arr.length - 1]);
        this.heldin.eigenschaftenWahlAusSpeziesGewaehlt.push(false);
        const val: number = +last.split(': ')[1];
        last = last.split(' ')[0];
        if (val >= 0) {
          last += ' +' + val;
        } else {
          last += ' ' + val;
        }
        eig += last;
      } else {
        // automatisch modifizierte Eigenschaften
        const arr = mod.split(' ');
        eig = arr[0];
        const val = +arr[1];
        if (val >= 0) {
          eig += ' +' + val;
        } else {
          eig += ' ' + val;
        }
      }
      this.$eigMods.push(eig);

    });
  }
}
