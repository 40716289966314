<app-auswahlbildschirm [glossar]="glossar" *ngIf="localData.benutzer != null" [benutzer]="localData.benutzer" [gruppe]="gruppe" (reload)="reload($event)"></app-auswahlbildschirm>
<div class="main" *ngIf="localData.benutzer == null">
  <div class="liste" *ngIf="!register">
    <h2>Wer bist du?</h2>
    <input type="text" [(ngModel)]="localData.name" placeholder="Benutzername" (keyup.enter)="anmelden()">
    <input type="button" class="button" value="anmelden" (click)="anmelden()">
    <input type="button" value="neu registrieren" (click)="openRegistration()">
  </div>
  <div class="liste" *ngIf="register">
    <h2>Registrieren</h2>
    <input type="text" [(ngModel)]="localData.name" placeholder="Benutzername" (keyup.enter)="registrieren()">
    <input type="button" class="button" value="registrieren" (click)="registrieren()">
  </div>
</div>
<div id="preload" *ngIf="glossar != null">
  <img *ngFor="let image of getImageList()" src="assets/images/{{image}}" width="1" height="1" alt="" />
  <img *ngFor="let item of glossar.ausruestung" src="assets/images/items/{{item.dateiname}}.png" width="1" height="1" alt="" />
</div>
<!--app-logout></app-logout-->
