<div class="body" *ngIf="tier != null">
  <!-- Tierauswahl -->
  <div class="rowCommon" style="justify-content: flex-start">
    <div class="portrait" *ngFor="let tier of charakter.tiere" (click)="selectTier(tier.id)">
      <div *ngIf="gewaehltesTier !== tier.id" class="image" [style.background-image]="'url(' + tier.portrait + ')'"></div>
      <div *ngIf="gewaehltesTier !== tier.id" class="text">{{tier.name}}</div>
      <div *ngIf="gewaehltesTier === tier.id" class="imageGold" [style.background-image]="'url(' + tier.portrait + ')'"></div>
      <div *ngIf="gewaehltesTier === tier.id" class="textGold">{{tier.name}}</div>
    </div>
  </div>
  <div class="rowCommon" style="justify-content: flex-start">
    <div class="portraitBox">
      <div class="drawnName">{{tier.name}}</div>
      <div class="image" [style.background-image]="'url(' + tier.portrait + ')'"></div>
      <!--img src="{{tier.portrait}}"-->
    </div>
    <div class="colCommon" style="justify-content: flex-start">
      <div class="rowCommon" style="justify-content: flex-start; border-style: solid; border-width: 4px 4px 1px 0">
        <label class="art">Art:</label>
        <div class="art">{{tier.art}}</div>
        <label class="unterart">Unterart:</label>
        <div class="unterart">{{tier.unterart}}</div>
        <label class="variante">Variante:</label>
        <div *ngIf="tier.variante !== tier.unterart" class="variante">{{tier.variante}}</div>
        <div *ngIf="tier.variante === tier.unterart" class="variante">-</div>
      </div>
      <div class="rowCommon" style="justify-content: flex-start; border-style: solid; border-width: 1px 4px 2px 0">
        <label class="geschlecht">Geschlecht:</label>
        <div class="geschlecht">{{tier.geschlecht}}</div>
        <label class="groesse">Größe:</label>
        <div class="groesse">{{tier.groesse}} Schritt</div>
        <label class="gewicht">Gewicht:</label>
        <div class="gewicht">{{tier.gewicht}} Stein</div>
      </div>
      <div class="rowCommon" style="justify-content: flex-start">
        <div class="colCommon" style="justify-content: flex-start">

          <!-- LeP -->
          <div class="rowCommon" style="justify-content: flex-start; border-style: solid; border-width: 0 2px 0 0">
            <label class="attribut1">LeP:</label>

            <div class="lep">{{tier.getCurrentWert('LeP')}} / {{tier.getWert('LeP').wert}}</div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseBeeinflussterWert('LeP')"/>
            </div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_pos.png" (click)="increaseBeeinflussterWert('LeP')"/>
            </div>

          </div>

          <!-- Attribute -->
          <div class="rowCommon" style="justify-content: flex-start; border-style: solid; border-width: 0 2px 0 0" *ngFor="let attr of tier.getWerteDerKategorie('Attribut') index as i">
            <label class="attribut{{i % 2}}">{{attr.name}}:</label>
            <div class="attribut{{i % 2}}">{{attr.akt()}}</div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseBeeinflussterWert(attr.name)"/>
            </div>
            <div class="mod">
              <label *ngIf="tier.getBeeinflWert(attr.name, 'manuell').getNumericWert() > 0">
                +{{tier.getBeeinflWert(attr.name, 'manuell').getNumericWert()}}
              </label>
              <label *ngIf="tier.getBeeinflWert(attr.name, 'manuell').getNumericWert() <= 0">
                {{tier.getBeeinflWert(attr.name, 'manuell').getNumericWert()}}
              </label>
            </div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_pos.png" (click)="increaseBeeinflussterWert(attr.name)"/>
            </div>
          </div>

          <!-- GS -->
          <div class="rowCommon" style="justify-content: flex-start; border-style: solid; border-width: 0 2px 0 0">
            <label class="attribut0">GS:</label>
            <div class="attribut0">{{tier.getCurrentWert('GS')}}</div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseBeeinflussterWert('GS')"/>
            </div>
            <div class="mod">
              <label *ngIf="tier.getBeeinflWert('GS', 'manuell').getNumericWert() > 0">
                +{{tier.getBeeinflWert('GS', 'manuell').getNumericWert()}}
              </label>
              <label *ngIf="tier.getBeeinflWert('GS', 'manuell').getNumericWert() <= 0">
                {{tier.getBeeinflWert('GS', 'manuell').getNumericWert()}}
              </label>
            </div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_pos.png" (click)="increaseBeeinflussterWert('GS')"/>
            </div>
          </div>

          <!-- INI -->
          <div class="rowCommon" style="justify-content: flex-start; border-style: solid; border-width: 0 2px 4px 0">
            <label class="attribut1">INI:</label>
            <div class="attribut1">{{tier.getCurrentWert('INI')}}</div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseBeeinflussterWert('INI')"/>
            </div>
            <div class="mod">
              <label *ngIf="tier.getBeeinflWert('INI', 'manuell').getNumericWert() > 0">
                +{{tier.getBeeinflWert('INI', 'manuell').getNumericWert()}}
              </label>
              <label *ngIf="tier.getBeeinflWert('INI', 'manuell').getNumericWert() <= 0">
                {{tier.getBeeinflWert('INI', 'manuell').getNumericWert()}}
              </label>
            </div>
            <div class="colCommon" style="justify-content: center">
              <img class="mod" src="../../../../assets/images/button_s_pos.png" (click)="increaseBeeinflussterWert('INI')"/>
            </div>
          </div>
        </div>
        <div class="beschreibung">
          {{tierInfos.beschreibung}}
        </div>
      </div>
    </div>

  </div>
  <div class="rowCommon" style="justify-content: center; border-style: solid; border-width: 0 4px 0 4px">
    <div class="colCommon" style="justify-content: flex-start; width: 50%; border-style: solid; border-width: 0 2px 4px 0">
      <div class="rowCommon" style="justify-content: center">
        <label class="headline">Talente</label>
      </div>
      <div class="talentHead">
        <div class="name">Name</div>
        <div class="probe">Attribute</div>
        <div class="fwMod">FW +/- Mod</div>
        <div class="be">BE</div>
        <div class="r">R</div>
      </div>
      <div class="talent" *ngFor="let talent of talente">
        <div class="name">{{talent.glossartalent.name}}</div>
        <div class="probe" (click)="talent.attributeAlsZahl = !talent.attributeAlsZahl">
          <div class="attr" *ngFor="let attr of talent.glossartalent.probe">
            <div *ngIf="!talent.attributeAlsZahl">{{attr}}</div>
            <div class="good" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) < 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</div>
            <div class="bad" *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) > 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</div>
            <div *ngIf="talent.attributeAlsZahl && getAttributMod(talent.glossartalent.name, attr) === 0">{{getTalentAttributWert(talent.glossartalent.name, attr)}}</div>

          </div>
        </div>
        <div class="fwMod">
          <div class="fw" *ngIf="bedeutungAktiv !== talent.glossartalent.name" (click)="zeigeTalentwertBeschreibung(talent.glossartalent.name)">
            <label *ngIf="talent.aktiveSpzialisierung === ''">{{tier.getWert(talent.glossartalent.name).wert}}</label>
            <label class="good" *ngIf="talent.aktiveSpzialisierung !== ''">{{tier.getWert(talent.glossartalent.name).wert + 2}}</label>
          </div>
          <div class="img" *ngIf="bedeutungAktiv !== talent.glossartalent.name">
            <img (click)="decreaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_neg.png"/>
          </div>
          <div class="mod" *ngIf="bedeutungAktiv !== talent.glossartalent.name">
            <label *ngIf="tier.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() !== 0">
              <label class="good" *ngIf="tier.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() > 0">+{{tier.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
              <label class="bad" *ngIf="tier.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert() < 0">{{tier.getBeeinflWert(talent.glossartalent.name, 'manuell').getNumericWert()}}</label>
            </label>
          </div>
          <div class="img" *ngIf="bedeutungAktiv !== talent.glossartalent.name">
            <img (click)="increaseTalentMod(talent.glossartalent.name)" src="../../../../assets/images/button_s_pos.png"/>
          </div>
          <div class="fwBeschreibung" *ngIf="bedeutungAktiv === talent.glossartalent.name">
            {{talentWertBedeutung[tier.getWert(talent.glossartalent.name).wert]}}
          </div>
        </div>
        <div class="be">{{talent.glossartalent.belastung}}</div>
        <div class="rPos" *ngIf="istRoutine(talent)">✓</div>
        <div class="r" *ngIf="!istRoutine(talent)"></div>

      </div>
    </div>
    <div class="colCommon" style="justify-content: flex-start; width: 50%; border-style: solid; border-width: 0 0 4px 2px">
      <div class="rowCommon" style="justify-content: center">
        <label class="headline">Nahkampf</label>
      </div>
      <div class="kampfHead">
        <div class="angriff">Nahkampfangriff</div>
        <div class="rw">Reichweite</div>
        <div class="at">AT</div>
        <div class="tp">Schaden</div>
      </div>
      <div class="kampf" *ngFor="let ang of tierInfos.angriffenk">
        <div class="angriff">{{ang.angriffsart}}</div>
        <div class="rw">{{ang.reichweite}}</div>
        <div class="at">{{ang.at}}</div>
        <div class="tp">{{ang.schaden}}</div>
      </div>
      <div class="rowCommon" style="justify-content: center" *ngIf="tierInfos.angriffefk.length > 0">
        <label class="headline">Fernkampf</label>
      </div>
      <div class="kampfHead" *ngIf="tierInfos.angriffefk.length > 0">
        <div class="angriff">Fernkampfangriff</div>
        <div class="rw">Reichweiten</div>
        <div class="at">FK</div>
        <div class="tp">Schaden</div>
        <div class="lz">Ladezeit</div>
      </div>
      <div class="kampf" *ngFor="let ang of tierInfos.angriffefk">
        <div class="angriff">{{ang.angriffsart}}</div>
        <div class="rw">{{ang.reichweiten}}</div>
        <div class="at">{{ang.fk}}</div>
        <div class="tp">{{ang.schaden}}</div>
        <div class="lz">{{ang.lz}}</div>
      </div>
      <div class="rowCommon" style="justify-content: center">
        <label class="headline">Kampfverhalten</label>
      </div>
      <div class="infotext">{{tierInfos.kampfverhalten}}</div>
      <div class="rowCommon" style="justify-content: center">
        <label class="headline">Flucht</label>
      </div>
      <div class="infotext">{{tierInfos.flucht}}</div>
    </div>
  </div>
  <div class="rowCommon" style="justify-content: center; border-style: solid; border-width: 0 4px 0 4px">
    <label class="headline">Befehle und Training</label>
  </div>
  <div class="colCommon" style="justify-content: flex-start; width: 50%; border-style: solid; border-width: 0 2px 4px 4px">
    <div class="talentHead">
      <div class="name">Loyalität zu</div>
      <div class="probe">Attribute</div>
      <div class="fwMod">FW +/- Mod</div>
      <div class="be">BE</div>
      <div class="r">R</div>
    </div>
    <div class="talent">
      <select class="name" [(ngModel)]="loyalitaetIndex" (ngModelChange)="changeBezugsperson()">
        <option [value]="tier.loyalitaeten.indexOf(person)" *ngFor="let person of tier.loyalitaeten">{{person.name}}</option>
      </select>
      <div class="probe" (click)="loyalitaetAttributeZahl = !loyalitaetAttributeZahl">
        <div class="attr" *ngFor="let attr of ['MU', 'KL', 'IN']">
          <div *ngIf="!loyalitaetAttributeZahl">{{attr}}</div>
          <div *ngIf="loyalitaetAttributeZahl">{{getLoyalitaetAttributWert(attr)}}</div>
        </div>
      </div>
      <div class="fwMod">
        <div class="fw" *ngIf="bedeutungAktiv !== 'Loyalität'" (click)="zeigeTalentwertBeschreibung('Loyalität')">
          <label>{{loyalitaet.wert}}</label>
        </div>
        <div class="img" *ngIf="bedeutungAktiv !== 'Loyalität'">
          <img (click)="decreaseTalentMod('Loyalität')" src="../../../../assets/images/button_s_neg.png"/>
        </div>
        <div class="mod" *ngIf="bedeutungAktiv !== 'Loyalität'">
          <label *ngIf="tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert() !== 0">
            <label class="good" *ngIf="tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert() > 0">+{{tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert()}}</label>
            <label class="bad" *ngIf="tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert() < 0">{{tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert()}}</label>
          </label>
        </div>
        <div class="img" *ngIf="bedeutungAktiv !== 'Loyalität'">
          <img (click)="increaseTalentMod('Loyalität')" src="../../../../assets/images/button_s_pos.png"/>
        </div>
        <div class="fwBeschreibung" *ngIf="bedeutungAktiv === 'Loyalität'">
          {{loyalitaetWertBedeutung[loyalitaet.wert]}}
        </div>
      </div>
      <div class="be">nein</div>
      <div class="r"></div>
    </div>
  </div>

  <!--div class="talent">

    <div class="probe" (click)="loyalitaetAlszahl = !loyalitaetAlszahl">
      <div class="attr" *ngFor="let attr of ['MU', 'KL', 'IN']">
        <div *ngIf="!loyalitaetAlszahl">{{attr}}</div>
        <div class="good" *ngIf="loyalitaetAlszahl && getAttributMod('Loyalität', attr) < 0">{{getTalentAttributWert('Loyalität', attr)}}</div>
        <div class="bad" *ngIf="loyalitaetAlszahl && getAttributMod('Loyalität', attr) > 0">{{getTalentAttributWert('Loyalität', attr)}}</div>
        <div *ngIf="loyalitaetAlszahl && getAttributMod('Loyalität', attr) === 0">{{getTalentAttributWert('Loyalität', attr)}}</div>

      </div>
    </div>
    <div class="fwMod">
      <div class="fw" *ngIf="bedeutungAktiv !== 'Loyalität'" (click)="zeigeTalentwertBeschreibung('Loyalität')">
        <label>{{tier.getCurrent(chosenLoyalitaet.wert, 'Loyalität')}}</label>
      </div>
      <div class="img" *ngIf="bedeutungAktiv !== 'Loyalität'">
        <img (click)="decreaseTalentMod('Loyalität')" src="../../../../assets/images/button_s_neg.png"/>
      </div>
      <div class="mod" *ngIf="bedeutungAktiv !== 'Loyalität'">
        <label *ngIf="tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert() !== 0">
          <label class="good" *ngIf="tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert() > 0">+{{tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert()}}</label>
          <label class="bad" *ngIf="tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert() < 0">{{tier.getBeeinflWert('Loyalität', 'manuell').getNumericWert()}}</label>
        </label>
      </div>
      <div class="img" *ngIf="bedeutungAktiv !== 'Loyalität'">
        <img (click)="increaseTalentMod('Loyalität')" src="../../../../assets/images/button_s_pos.png"/>
      </div>
      <div class="fwBeschreibung" *ngIf="bedeutungAktiv === 'Loyalität'">
        {{talentWertBedeutung[tier.getCurrent(chosenLoyalitaet.wert, 'Loyalität')]}}
      </div>
    </div>
    <div class="be">nein</div>
    <div class="r"></div>
  </div-->
</div>
