export class Gtiergroesse {
  kategorie: string;
  min: number;
  max: number;

  constructor() {
    this.kategorie = '';
    this.min = 0;
    this.max = 0;
  }


  public copy(other: Gtiergroesse): void {
    this.kategorie = other.kategorie;
    this.min = other.min;
    this.max = other.max;
  }
}
