export class Gzaubererweiterung {
  erweiterung: string;
  fwVoraussetzung: number;
  apKosten: number;
  beschreibung: string;

  public copy(other: Gzaubererweiterung): void {
    this.erweiterung = other.erweiterung;
    this.fwVoraussetzung = other.fwVoraussetzung;
    this.apKosten = other.apKosten;
    this.beschreibung = other.beschreibung;
  }
}
