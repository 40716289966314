<div class="body">
  <div class="zeileSpaceAround" style="margin-bottom: 8px">
    <div *ngFor="let button of navBar">
      <div class="button" *ngIf="button !== navBar[options.nav]" (click)="changeNav(navBar.indexOf(button))">{{button}}</div>
      <div class="buttonGlow" *ngIf="button === navBar[options.nav]" (click)="changeNav(navBar.indexOf(button))">{{button}}</div>
    </div>
  </div>
  <!-- Zechen -->
  <div class="spalte" *ngIf="navBar[options.nav] === 'Zechen'">
    <div class="spalten" >
      <div class="spalte" style="width: 60%">
        <div class="header">
          Alkohol gehört zum Leben in Aventurien dazu!
        </div>
        <div class="spalten">
          <div class="spalte" style="width: 70%">
            <div class="zeile">
              <div class="anweisung">Was trinkst du?</div>
            </div>
            <div class="zeile" style="margin-bottom: 8px">
              <select [(ngModel)]="options.nav2" (ngModelChange)="waehleAlkSorte()">
                <option [value]="alkSorten.indexOf(sorte)" *ngFor="let sorte of alkSorten">{{sorte}}</option>
              </select>
              <select [(ngModel)]="options.nav3" (ngModelChange)="waehleAlk()">
                <option [value]="waehlbar.indexOf(alk)" *ngFor="let alk of waehlbar">{{alk.name}}</option>
              </select>
            </div>
            <div class="zeile">
              <div class="anweisung">{{gewaehlt.name}}</div>
            </div>

            <div class="beschreibung">{{gewaehlt.beschreibung}}</div>
          </div>
          <div class="spalte" style="width: 30%">
            <!-- style="background-image: url('assets/images/items/{{item.gegenstand.dateiname}}.png'); background-size: contain;" -->
            <div class="bildRund" style="background-image: url('assets/images/items/{{waehlbar[options.nav3].dateiname}}.png'); background-size: contain;"></div>
            <!--img class="bildRund" src="../../../assets/images/items/{{waehlbar[options.nav3].dateiname}}.png"-->
          </div>
        </div>
      </div>
      <div class="spalte" style="width: 40%">
        <div class="bildZechen"></div>
        <!--img src="../../../assets/images/Gelaende/{{navBar[options.nav]}}.png"/-->
      </div>
    </div>

    <div class="zeile">
      <div class="head">Bisherige Getränke:</div>
      <img class="head" alt="minus" src="assets/images/button_s_neg.png" (click)="decreaseBisherigeGetraenke()"/>
      <div class="headAnz">{{charakter.getCurrentWert('bisherige Getränke')}}</div>
      <img class="head" alt="plus" src="assets/images/button_s_pos.png" (click)="increaseBisherigeGetraenke()"/>
    </div>


    <div class="zeile">
      <div class="anweisung">Würfle einen W{{wurf}}:</div>
      <select [(ngModel)]="wertWurf">
        <option *ngFor="let w of wuerfe">{{w}}</option>
      </select>
      <div class="anweisung">Lege eine Zechen Probe ab: </div>
      <div class="part" *ngFor="let part of zechen">{{part}}</div>
      <div class="anweisung">übrige Talentpunkte: </div>
      <select [(ngModel)]="wertZechen">
        <option *ngFor="let tap of zechenTaP">{{tap}}</option>
      </select>
    </div>

    <div class="zeileSpaceAround" *ngIf="charakter.getCurrentWert('Trink-AuP') - 1 >= 0">
      <button (click)="trinken()">Trinken!</button>
    </div>
    <div class="zeileSpaceAround" *ngIf="charakter.getCurrentWert('Trink-AuP') <= 0">
      <h3 style="color: darkred">Du bist ohnmächtig!</h3>
    </div>
    <div class="zeileSpaceAround" *ngIf="reaktion !== ''">
      <div class="popup">
        {{reaktion}}
      </div>
    </div>
  </div>

  <!-- Panik -->
  <div class="spalte" *ngIf="navBar[options.nav] === 'Panik'">
    <div class="spalten" >
      <div class="spalte" style="width: 60%">
        <div class="header">
          Bei genug Schrecken kann jeder in Panik verfallen!
        </div>
        <div class="spalten">
          <div class="spalte" style="width: 70%">
            <div class="zeile">
              <div class="anweisung">Deine Furcht:</div>
            </div>
            <div class="zeile">
              <img class="head" alt="minus" src="assets/images/button_s_neg.png" (click)="decreaseBeeinflussterWert('Furcht')"/>
              <div class="verticalCenter">
                <div class="anweisung">
                  {{stufen[charakter.getWert('Furcht').akt()]}}
                </div>
              </div>
              <img class="head" alt="plus" src="assets/images/button_s_pos.png" (click)="increaseBeeinflussterWert('Furcht')"/>
            </div>
            <div class="zeile" *ngIf="options.panikStage == 0">
              <div class="anweisung">
                Stufe der Panikursache
              </div>
              <select [(ngModel)]="panikursache" (ngModelChange)="aktualisiereWillenskraftWerte()">
                <option [value]="stufe" a *ngFor="let stufe of [0,1,2,3,4,5]">{{stufe}}</option>
              </select>
            </div>

          </div>
          <div class="spalte" style="width: 30%">
            <div class="zeile">
              <div class="anweisung">Deine Seelenkraft: {{charakter.getWert('SK').akt()}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="spalte" style="width: 40%">
        <div class="bildPanik"></div>
        <!--img src="../../../assets/images/Gelaende/{{navBar[options.nav]}}.png"/-->
      </div>
    </div>




    <div class="zeile" *ngIf="options.panikStage == 0">

      <div class="anweisung">Lege eine Willenskraft ( Bedrohungen standhalten ) Probe ab: </div>
      <div class="part" *ngFor="let part of willenskraft">{{part}}</div>


    </div>

    <div class="zeile" *ngIf="options.panikStage == 0">
      <div class="anweisung">Probe geschafft?</div>
    </div>

    <div class="zeileSpaceAround" *ngIf="options.panikStage == 0">
      <button (click)="probeGeschafft(true)">Ja</button>
      <button (click)="probeGeschafft(false)">Nein</button>
    </div>

    <div class="zeile" *ngIf="options.panikStage == 1">
      <div class="anweisung">
        Seelenkraft Modifikator:
      </div>
      <select [(ngModel)]="skMod">
        <option [value]="mod" *ngFor="let mod of [0,-1,-2,-3,-4,-5]">{{mod}}</option>
      </select>
    </div>
    <div class="zeile" *ngIf="options.panikStage == 1">
      <div class="anweisung">Würfle einen W6:</div>
    </div>
    <div class="zeileSpaceAround" *ngIf="options.panikStage == 1">
      <button class="smallButton" *ngFor="let w of [1,2,3,4,5,6]" (click)="angstWurf(w)">{{w}}</button>
    </div>

    <div class="zeileSpaceAround" *ngIf="options.panikStage == 3">
      <h3 style="color: darkgreen">Du verfällst nicht in Panik!</h3>
    </div>
    <div class="zeileSpaceAround" *ngIf="options.panikStage == 2">
      <h3 style="color: darkred">Du verfällst in Panik!</h3>
    </div>
    <div class="zeileSpaceAround" *ngIf="options.panikStage == 2">
      <h4>{{panikName}}</h4>
    </div>
    <div class="zeileSpaceAround" style="text-align: center" *ngIf="options.panikStage == 2">
      <div>{{panikBeschreibung}}</div>
    </div>
    <div class="zeileSpaceAround" style="text-align: center" *ngIf="options.panikStage == 2">
      <div><b>Dauer der Panik:</b> {{options.panikDauer}} {{panikDauerEinheit}}</div>
    </div>

    <div class="zeileSpaceAround" *ngIf="options.panikStage > 1">
      <button (click)="resetPanik(this.options.nav)">Reset</button>
    </div>
  </div>


</div>

