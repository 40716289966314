import {Werte} from './charakter/werte';
import {Gtalent} from './glossar/gtalent';
import {Kombivun} from './kombivun';
import {Kombisf} from './kombisf';
import {Gzauber} from './glossar/gzauber';

export class Kombizauber {
  charakterZauber: Werte;
  glossarZauber: Gzauber;
  vorteile: Kombivun[] = [];
  nachteile: Kombivun[] = [];
  sonderfertigkeiten: Kombisf[] = [];
  anwVorteile: Map<string, Kombivun[]> = new Map<string, Kombivun[]>();
  anwNachteile: Map<string, Kombivun[]> = new Map<string, Kombivun[]>();
  anwSonderfertigkeiten: Map<string, Kombisf[]> = new Map<string, Kombisf[]>();
  anwSpezialisierungen: string[] = [];
  attributeAlsZahl = true;
  aktiveSpzialisierung = '';

  resetEinsatzmöglichkeiten(): void {
    this.vorteile = [];
    this.nachteile = [];
    this.sonderfertigkeiten = [];
    this.anwVorteile = new Map<string, Kombivun[]>();
    this.anwNachteile = new Map<string, Kombivun[]>();
    this.anwSonderfertigkeiten = new Map<string, Kombisf[]>();
  }
}
