<h3>Wähle Sonderfertigkeiten</h3>
<select [(ngModel)]="$chosenType" (ngModelChange)="resetIndex()">
  <option [value]="type" *ngFor="let type of $typesOfSF">{{ type }}</option>
</select>
<div *ngIf="$chosenType==='allgemeine Sonderfertigkeiten'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$allgemeineSF.indexOf(sf)" *ngFor="let sf of $allgemeineSF">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='Kampf-Sonderfertigkeiten'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$kampfSF.indexOf(sf)" *ngFor="let sf of $kampfSF">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='Sprachen'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$sprachen.indexOf(sf)" *ngFor="let sf of $sprachen">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='neue Talentanwendungsgebiete'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$neueAnwendungsgebiete.indexOf(sf)" *ngFor="let sf of $neueAnwendungsgebiete">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>



<div *ngIf="$chosenType==='magische Sonderfertigkeiten'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$magischeSF.indexOf(sf)" *ngFor="let sf of $magischeSF">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='Kampfstil-Sonderfertigkeiten'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$kampfstil.indexOf(sf)" *ngFor="let sf of $kampfstil">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='Magiestil-Sonderfertigkeiten'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$magiestil.indexOf(sf)" *ngFor="let sf of $magiestil">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='erweiterte Kampf-Sonderfertigkeiten'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$erwKampf.indexOf(sf)" *ngFor="let sf of $erwKampf">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='erweiterte magische Sonderfertigkeiten'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$erwMagie.indexOf(sf)" *ngFor="let sf of $erwMagie">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>
<div *ngIf="$chosenType==='Stabzauber'">
  <select [(ngModel)]="$sfIndex" (ngModelChange)="getSF()">
    <option [value]="$stabzauber.indexOf(sf)" *ngFor="let sf of $stabzauber">{{ sf.name }} ({{sf.apWert}} AP)</option>
  </select>
</div>

<div *ngIf="$chosenSF!==undefined">
  <select [(ngModel)]="$chosenSF.artDesErwwebs">
    <option [value]=0>manuell</option>
    <option [value]=1>aus Profession</option>
  </select>
</div>
<div>
  <h3>kostenlose Sonderfertigkeiten aus Profession</h3>
  <div *ngFor="let sf of heldin.profession.sonderfertigkeiten">{{sf}}</div>
</div>

<div *ngIf="$chosenSF!==undefined">
  <div *ngIf="$chosenSF.name === 'Fertigkeitsspezialisierung'">
    <select [(ngModel)]="talentIndex" (ngModelChange)="getTalent()">
      <option [value]="alleTalente.indexOf(talent)" *ngFor="let talent of alleTalente">{{ talent.name }} ({{talent.steigerungskosten}})</option>
    </select>
  </div>
  <div *ngIf="$chosenSF.name === 'Fertigkeitsspezialisierung'">
    <select [(ngModel)]="talentanwendungIndex" (ngModelChange)="setTalentanwendung()">
      <option [value]="talentanwendungen.indexOf(anw)" *ngFor="let anw of talentanwendungen">{{ anw }}</option>
    </select>
  </div>
</div>
<div class="vorUndNachteilBox" *ngIf="$chosenSF !== undefined">
  <div class="zeile2">
    <label>
      <b>Name: </b>{{$chosenSF.name}}
      <select [(ngModel)]="$chosenStufe" *ngIf="$hatStufen" (ngModelChange)="berechneKosten()">
        <option [value]="$stufenliste.indexOf(stufe)" *ngFor="let stufe of $stufenliste">{{ stufe }}</option>
      </select>
    </label>
    <label *ngIf="$chosenSF.type === 'KampfSF'" style="font-style: italic">- {{$chosenSF.art}} -</label>
    <label *ngIf="$hatNotiz && talentanwendungen.length < 2">
      <b>{{$chosenSF.notiz}}: </b>
      <input type="text" [(ngModel)]="$input">
    </label>
    <label *ngIf="$hatMerkmal">
      <b>{{$chosenSF.mehrfachKathegorie}}: </b>
      <select *ngIf="$chosenSF.mehrfachKathegorie === 'Merkmal'" [(ngModel)]="$merkmalInput">
        <option [value]="merkmal" *ngFor="let merkmal of merkmale">{{ merkmal }}</option>
      </select>
      <div *ngIf="$chosenSF.mehrfachKathegorie !== 'Merkmal'">
        <input type="text" [(ngModel)]="$merkmalInput">
      </div>
    </label>
    <label>
      <b>Kosten: </b>{{$apKosten}} AP
    </label>
  </div>
  <div class="zeile2" *ngIf="$chosenSF.type === 'NeuesAnwendungsgebiet'" style="text-align: justify">
    <label><b>zugehöriges Talent:</b> {{$chosenSF.zugehoerigesTalent}}</label>
  </div>
  <div class="zeile2" style="text-align: justify">
    <b>Voraussetzungen</b>
  </div>
  <div class="zeile2" *ngFor="let voraus of $voraussetzungen">
    <div class="left">
      {{voraus}}
    </div>
    <div class="right">
      <label class="mediocre">manuell Prüfen</label>
    </div>
  </div>
  <div *ngIf="$chosenSF.type !== 'Stabzauber'">
    <div class="zeile2" style="text-align: justify">
      <b>Beschreibung</b>
    </div>
    <div class="zeile2" style="text-align: justify">
      {{$chosenSF.beschreibung}}
    </div>
  </div>

  <div  *ngIf="$chosenSF.type !== 'Sprache' && $chosenSF.type !== 'NeuesAnwendungsgebiet'">
    <div class="zeile2" style="text-align: justify">
      <b>Regel</b>
    </div>
    <div class="zeile2" style="text-align: justify">
      {{$chosenSF.regel}}
    </div>
  </div>

  <div *ngIf="$chosenSF.type === 'KampfSF' || $chosenSF.type === 'erweiterteKampfSF'">
    <div class="zeile2" style="text-align: justify">
      <b>Kampftechniken</b>
    </div>
    <div class="zeile2" style="text-align: justify">
      {{$chosenSF.kampftechniken}}
    </div>
  </div>
  <div *ngIf="$chosenSF.type === 'Kampfstil'">
    <div class="zeile2" style="text-align: justify">
      <b>erweiterte Kampfsonderfertigkeiten</b>
    </div>
    <div class="zeile2" style="text-align: justify">
      {{$chosenSF.erweiterteSF}}
    </div>
  </div>
  <div *ngIf="$chosenSF.type === 'Magiestil'">
    <div class="zeile2" style="text-align: justify">
      <b>erweiterte Magiesonderfertigkeiten</b>
    </div>
    <div class="zeile2" style="text-align: justify">
      {{$chosenSF.erweiterteSF}}
    </div>
  </div>
</div>

<input type="button" class="button" value="hinzufügen" (click)="addSFtoList()">
<h3>Gewählte Sonderfertigkeiten</h3>
<div class="table">
  <div class="row" style="font-weight: bold">
    <div class="name">Name</div>
    <div class="typ">Typ</div>
    <div class="kosten">AP-Kosten</div>
    <div class="image"></div>
  </div>
  <div class="row" *ngFor="let sf of heldin.sonderfertigkeiten">
    <div class="name">{{sf.name}}
      <label *ngIf="sf.hatStufen">{{stufenStrings[sf.stufe]}}</label>
      <label *ngIf="sf.mehrfachKathegorie !== ''">({{sf.mehrfachKathegorie}})</label>
      <label *ngIf="sf.notiz !== ''"> [{{sf.notiz}}]</label>
    </div>
    <div class="typ">{{sf.type}}</div>
    <div class="kosten" *ngIf="sf.artDesErwwebs == 0">{{sf.apWert}}</div>
    <div class="kosten" *ngIf="sf.artDesErwwebs != 0">aus Prof.</div>
    <img class="image" src="../../../../assets/images/button_s_neg.png" (click)="removeSF(sf)"/>
  </div>
  <div class="row" style="font-style: italic">
    <div class="name">Gesamt</div>
    <div class="typ"style="border-right: none; border-left: none;"></div>
    <div class="kosten">{{$kostenSumme}}</div>
    <div class="image"></div>
  </div>
</div>
