export class Gkampftechnik {
  name: string;
  leiteigenschaften: string;
  steigerung: string;
  bruchfaktor: number;
  beschreibung: string;
  besonderheiten: string;
  nahkampf: boolean;

  public copy(other: Gkampftechnik): void {
    console.log('copy ...');
    this.name = other.name;
    this.leiteigenschaften = other.leiteigenschaften;
    this.steigerung = other.steigerung;
    this.bruchfaktor = other.bruchfaktor;
    this.beschreibung = other.beschreibung;
    this.besonderheiten = other.besonderheiten;
    this.nahkampf = other.nahkampf;
  }
}
