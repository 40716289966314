import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../_services/data.service';
import {ObjectFactoryService} from '../../../_services/object-factory.service';
import {Waffe} from '../../../_classes/kampf/waffe';
// import {Waffe} from '../../../_interfaces/waffe';

@Component({
  selector: 'app-waffen',
  templateUrl: './waffen.component.html',
  styleUrls: ['./waffen.component.sass']
})
export class WaffenComponent implements OnInit {
  public waffe: Waffe;
  public alleWaffen: Waffe[];

  public waffenIndex: number;

  constructor(public dataService: DataService, public factory: ObjectFactoryService) {
    this.waffe = factory.getNewWaffe();
    this.getAlleWaffen();
    this.waffenIndex = 0;
  }

  ngOnInit(): void {
  }

  public getAlleWaffen(): void {
    this.dataService.getAlleWaffen().subscribe((data: Waffe[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.alleWaffen = data;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getWaffe(): void {
    this.waffe = this.alleWaffen[this.waffenIndex];
    if (this.waffe.dateiname === '' || this.waffe.dateiname == null) {
      this.waffe.dateiname = this.waffe.name;
    }
  }

  public addWaffeToDB(): void {
    const zweiHandTalente = 'Stangenwaffen, Zweihandhiebwaffen, Zweihandschwerter';
    if (this.waffe.nahkampf) {
      this.waffe.zweihand = true;
    } else if (zweiHandTalente.includes(this.waffe.kampftechnik)) {
      this.waffe.zweihand = true;
    }
    if (this.waffe.name !== '' && this.waffe.tp !== '') {
      this.dataService.addWaffeToDB(this.waffe);
      this.getAlleWaffen();
      window.alert('Waffe hinzugefügt.');
    }
  }
}
