import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Kultur} from '../../../_interfaces/kultur';
import {Spezies} from '../../../_interfaces/spezies';
import {DataService} from '../../../_services/data.service';
import {Step3} from '../../../_interfaces/charaktererstellung/step3';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';

@Component({
  selector: 'app-kultur',
  templateUrl: './kultur.component.html',
  styleUrls: ['./kultur.component.sass']
})
export class KulturComponent implements OnInit {
  @Input() heldin: Heldenerschaffung;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  public $kulturMenu: Kultur[];
  public $kulturIndex: number;
  public $typischeKulturen: boolean;
  public $choosableKultur: Kultur[];

  constructor(public dataService: DataService) {
    this.$kulturMenu = [];
    this.$typischeKulturen = true;
  }

  ngOnInit(): void {
    this.getAlleKulturen();
  }

  public getKultur(): void {
    this.heldin.kultur = this.$choosableKultur[this.$kulturIndex];
    this.ping.emit();
  }

  public getAlleKulturen(): void {
    console.log('getAlleKulturen');
    this.dataService.getAlleKulturen().subscribe((data: Kultur[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.$kulturMenu = data;
      // console.log(data);
      this.getKulturIndex();
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public switchKulturen(): void {
    this.$kulturIndex = 0;
    if (!this.$typischeKulturen) {
      this.switchToTypischeKulturen();
    } else {
      this.switchToAlleKulturen();
    }
    this.$typischeKulturen = !this.$typischeKulturen;
  }

  public switchToAlleKulturen(): void {
    // console.log('switchToAlleKulturen');
    this.$choosableKultur = [];
    this.$kulturMenu.forEach((k) => {
      this.$choosableKultur.push(k);
    });
    this.getKultur();
  }

  public switchToTypischeKulturen(): void {
    // console.log('switchToTypischeKulturen');
    this.$choosableKultur = [];
    console.log(this.heldin.spezies.ueblicheKulturen);
    this.$kulturMenu.forEach((k) => {
      console.log(k.name);
      if (this.heldin.spezies.ueblicheKulturen.includes(k.name)) {
        console.log('push -> ' + k.name);
        this.$choosableKultur.push(k);
      }
    });
    console.log(this.$choosableKultur);
    this.getKultur();
  }

  public getKulturIndex(): void {
    console.log('getKulturIndex');
    console.log(this.heldin.kultur);
    if (this.heldin.kultur !== null) {
      this.$kulturIndex = -1;
      for (let i = 0; i < this.$kulturMenu.length; i++) {
        console.log(this.$kulturMenu[i].name + ' === ' + this.heldin.kultur.name + '?');
        if (this.$kulturMenu[i].name === this.heldin.kultur.name) {
          console.log('-> true');
          this.$kulturIndex = i;
          console.log('this.$kulturIndex = ' + this.$kulturIndex);
        }
      }
      this.$typischeKulturen = false;
      this.switchToAlleKulturen();
    } else {
      this.$kulturIndex = 0;
      this.switchToTypischeKulturen();
    }
  }
}
