<div class="zeile">
  <h3 style="text-align: center">Sonderfertigkeiten</h3>
</div>
<div class="zeile">
  <label class="b92">{{$message}}</label>
</div>
<select [(ngModel)]="index" (ngModelChange)="get()">
  <option [value]="alle.indexOf(obj)" *ngFor="let obj of alle">{{ obj.name }}</option>
</select>


<input class="92" type="button" value="reset Sonderfertigkeit" required="true" (click)="resetAll()">
<div *ngIf="$sonderfertigkeit !== undefined">
  <div class="zeile">
    <label class="b30">Name: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.name">
  </div>
  <h3>Infostring Allgemeine SF</h3>
  <label class="b30">Infostring: </label> <input class="b62" type="text" [(ngModel)]="$allgemeineSFString" (blur)="convertAllgemeineSFString()">


  <h3>Infostring Kampf-SF</h3>
  <label class="b30">Infostring: </label> <input class="b62" type="text" [(ngModel)]="$kampfSFString" (blur)="convertKampfSFString()">

  <h3>Infostring Objekt-SF</h3>
  <label class="b30">Infostring: </label> <input class="b62" type="text" [(ngModel)]="$objektSFString" (blur)="convertObjektZauberSFString()">
  <br/>

  <div class="zeile">
    <label class="b30">Beschreibung: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.beschreibung">
  </div>
  <div class="zeile">
    <label class="b30">Voraussetzungen: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.voraussetzungen">
  </div>
  <div class="zeile">
    <label class="b30">AP-Wert: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.apWert">
  </div>
  <h3>Typ (AllgemeineSF, KampfSF, NeuesAnwendungsgebiet, Sprache, MagischeSF, Kampfstil, Magiestil, erweiterteKampfSF, erweiterteMagischeSF, Stabzauber)</h3>
  <div class="zeile">
    <label class="b30">Sonderfertigkeitstyp: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.type">
  </div>

  <!-- allgemeineSF -->

  <h3>allgemeineSF / magischeSF</h3>
  <div class="zeile">
    <label class="b30">Regel: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.regel">
  </div>
  <div class="zeile">
    <label class="b30">Stufe: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.stufe">
  </div>
  <div class="zeile">
    <label class="b30">Notiz (Art der Notiz, leer falls ohne): </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.notiz">
  </div>
  <div class="zeile">
    <label class="b30">Mehrfachkethegorie (leer falls ohne): </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.mehrfachKathegorie">
  </div>

  <!-- neues Anwendungsgebiet -->
  <h3>neues Anwendungsgebiet</h3>
  <div class="zeile">
    <label class="b30">zugehöriges Talent: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.zugehoerigesTalent">
  </div>

  <!-- Sprache -->
  <h3>Sprache</h3>
  <div class="zeile">
    <label class="b30">Stufe: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.stufe">
  </div>
  <div class="zeile">
    <label class="b30">Schriftname: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.schriftName">
  </div>
  <div class="zeile">
    <label class="b30">Schrift-Kosten: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.kosten">
  </div>

  <!-- KampfSF -->
  <h3>KampfSF</h3>
  <div class="zeile">
    <label class="b30">Regel: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.regel">
  </div>
  <div class="zeile">
    <label class="b30">Art: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.art">
  </div>
  <div class="zeile">
    <label class="b30">Kampftechniken: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.kampftechniken">
  </div>
  <div class="zeile">
    <label class="b30">Stufe: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.stufe">
  </div>
  <div class="zeile">
    <label class="b30">{{$sonderfertigkeit.apJederStufe}}</label>
  </div>

  <h3>Stil-SF</h3>
  <div class="zeile">
    <label class="b30">erweiterte SF: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.erweiterteSF">
  </div>

  <h3>Stabzauber</h3>
  <div class="zeile">
    <label class="b30">Volumen: </label> <input class="b62" type="number" [(ngModel)]="$sonderfertigkeit.volumenPunkte">
  </div>
  <div class="zeile">
    <label class="b30">AsP-Kosten: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.aspKosten">
  </div>
  <div class="zeile">
    <label class="b30">Merkmal: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.merkmal">
  </div>
  <div class="zeile">
    <label class="b30">Bindungskosten: </label> <input class="b62" type="text" [(ngModel)]="$sonderfertigkeit.bindungskosten">
  </div>
  <input class="92" type="button" value="Sonderfertigkeit hinzufügen" required="true" (click)="postSonderfertigkeit()">
</div>

