export class Gvoraussetzung {
  voraussetzung;
  voraussetzungStufe;


  public copy(other: Gvoraussetzung): void {
    this.voraussetzung = other.voraussetzung;
    this.voraussetzungStufe = other.voraussetzungStufe;
  }
}
