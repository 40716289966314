import {Gkampftechnik} from './gkampftechnik';
import {Gsonderfertigkeit} from './gsonderfertigkeit';
import {Gsprachen} from './gsprachen';
import {Gtalent} from './gtalent';
import {Gvorundnachteil} from './gvorundnachteil';
import {Gegenstand} from '../ausruestung/gegenstand';
import {Gzustand} from './gzustand';
import {Gsozialstatus} from './gsozialstatus';
import {Gzauber} from './gzauber';
import {Gprofession} from './gprofession';
import {Gtier} from './gtier';

export class Glossar {
  kampftechniken: Gkampftechnik[];
  sonderfertigkeiten: Gsonderfertigkeit[];
  sprachen: Gsprachen[];
  talente: Gtalent[];
  vorUndNachteile: Gvorundnachteil[];
  ausruestung: Gegenstand[];
  zustaende: Gzustand[];
  sozialstati: Gsozialstatus[];
  zauber: Gzauber[];
  professionen: Gprofession[];
  tiere: Gtier[];

  public copy(other: Glossar): void {
    console.log(other);
    console.log('%cGlossar: copy kampftechniken', 'background: #333333; color: #f2f2f2');
    this.kampftechniken = [];
    if (other.kampftechniken != null) {
      other.kampftechniken.forEach(data => {
        const neu: Gkampftechnik = new Gkampftechnik();
        neu.copy(data);
        this.kampftechniken.push(neu);
      });
    } else {
      console.log('other.kampftechniken: ' + other.kampftechniken);
    }

    console.log('%cGlossar: copy sonderfertigkeiten', 'background: #333333; color: #f2f2f2');
    this.sonderfertigkeiten = other.sonderfertigkeiten;
      /*this.sonderfertigkeiten = [];
      if (other.sonderfertigkeiten != null) {
        other.sonderfertigkeiten.forEach(data => {
          const neu: Gsonderfertigkeit = new Gsonderfertigkeit();
          neu.copy(data);
          this.sonderfertigkeiten.push(neu);
        });
      } else {
        console.log('other.sonderfertigkeiten: ' + other.sonderfertigkeiten);
      }*/

    console.log('%cGlossar: copy sprachen', 'background: #333333; color: #f2f2f2');
    this.sprachen = [];
    other.sprachen.forEach(data => {
      const neu: Gsprachen = new Gsprachen();
      neu.copy(data);
      this.sprachen.push(neu);
    });

    console.log('%cGlossar: copy talente', 'background: #333333; color: #f2f2f2');
    this.talente = [];
    other.talente.forEach(data => {
      const neu: Gtalent = new Gtalent();
      neu.copy(data);
      this.talente.push(neu);
    });

    console.log('%cGlossar: copy vorUndNachteile', 'background: #333333; color: #f2f2f2');
    this.vorUndNachteile = [];
    other.vorUndNachteile.forEach(data => {
      const neu: Gvorundnachteil = new Gvorundnachteil();
      neu.copy(data);
      this.vorUndNachteile.push(neu);
    });

    console.log('%cGlossar: copy ausruestung', 'background: #333333; color: #f2f2f2');
    this.ausruestung = other.ausruestung;
    this.ausruestung.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    // this.ausruestung.sort((a, b) => (a.subtyp.toLowerCase() < b.subtyp.toLowerCase() ? -1 : 1));
    this.ausruestung.sort((a, b) => (a.type.toLowerCase() < b.type.toLowerCase() ? -1 : 1));

    console.log('%cGlossar: copy zustaende', 'background: #333333; color: #f2f2f2');
    this.zustaende = [];
    other.zustaende.forEach(data => {
      const neu: Gzustand = new Gzustand();
      neu.copy(data);
      this.zustaende.push(neu);
    });

    console.log('%cGlossar: copy sozialstati', 'background: #333333; color: #f2f2f2');
    this.sozialstati = [];
    other.sozialstati.forEach(data => {
      const neu: Gsozialstatus = new Gsozialstatus();
      neu.copy(data);
      this.sozialstati.push(neu);
    });


    console.log('%cGlossar: copy zauber', 'background: #333333; color: #f2f2f2');
    this.zauber = [];
    other.zauber.forEach(data => {
      const neu: Gzauber = new Gzauber();
      neu.copy(data);
      this.zauber.push(neu);
    });

    console.log('%cGlossar: copy professionen', 'background: #333333; color: #f2f2f2');
    this.professionen = [];
    other.professionen.forEach(data => {
      const neu: Gprofession = new Gprofession();
      neu.copy(data);
      this.professionen.push(neu);
    });
    console.log('%cGlossar: copy tiere', 'background: #333333; color: #f2f2f2');
    this.tiere = [];
    other.tiere.forEach(data => {
      const neu: Gtier = new Gtier();
      neu.copy(data);
      this.tiere.push(neu);
    });
  }

  getZustand(name: string): Gzustand {
    let wanted: Gzustand;
    this.zustaende.forEach(zustand => {
      if (zustand.name === name) {
        wanted = zustand;
      }
    });
    return wanted;
  }

  getZauber(name: string): Gzauber {
    let wanted: Gzauber;
    this.zauber.forEach(zauber => {
      if (zauber.name === name) {
        wanted = zauber;
      }
    });
    return wanted;
  }

  getSozialstatus(stufe: number): Gsozialstatus {
    console.log('getSozialstatus(' + stufe + ')');
    console.log(this.sozialstati);
    let wanted: Gsozialstatus;
    this.sozialstati.forEach(data => {
      if (data.stufe === stufe) {
        wanted = data;
      }
    });
    return wanted;
  }

  getVorUndNachteil(name: string): Gvorundnachteil {
    let wanted: Gvorundnachteil;
    this.vorUndNachteile.forEach(data => {
      if (data.name === name) {
        wanted = new Gvorundnachteil();
        wanted.copy(data);
      }
    });
    return wanted;
  }

  getSonderfertigkeit(name: string): Gsonderfertigkeit {
    let wanted: Gsonderfertigkeit;
    this.sonderfertigkeiten.forEach(data => {
      if (data.name === name) {
        wanted = new Gsonderfertigkeit();
        wanted.copy(data);
      }
    });
    return wanted;
  }
}
