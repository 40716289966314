<div class="zeile">
  <h3 style="text-align: center">Rüstungen</h3>
</div>
<div class="zeile" >
  <input class="92" type="button" value="reset Rüstung" required="true" (click)="resetRuestung()">
</div>
<select [(ngModel)]="ruestungIndex" (ngModelChange)="getRuestung()">
  <option [value]="alleRuestungen.indexOf(armor)" *ngFor="let armor of alleRuestungen">{{ armor.name }}</option>
</select>
<div class="zeile">
  <label class="b30">Name: </label> <input class="b62" type="text" [(ngModel)]="ruestung.name" (blur)="setDateiname()">
</div>
<div class="zeile">
  <label class="b30">Zitat: </label> <input class="b62" type="text" [(ngModel)]="ruestung.zitat">
</div>
<div class="zeile">
  <label class="b30">Beschreibung: </label> <input class="b62" type="text" [(ngModel)]="ruestung.beschreibung">
</div>
<div class="zeile">
  <label class="b30">Notiz: </label> <input class="b62" type="text" [(ngModel)]="ruestung.notiz">
</div>
<div class="zeile">
  <label class="b30">Dateiname: </label> <input class="b62" type="text" [(ngModel)]="ruestung.dateiname">
</div>
<div class="zeile">
  <label class="b30">Anmerkung: </label> <input class="b62" type="text" [(ngModel)]="ruestung.anmerkung">
</div>
<div class="zeile">
  <label class="b30">Gewicht: </label> <input class="b62" type="number" [(ngModel)]="ruestung.gewicht">
</div>
<div class="zeile">
  <label class="b30">Preis in Silbertalern: </label> <input class="b62" type="number" [(ngModel)]="ruestung.preis">
</div>
<div class="zeile">
  <label class="b30">Strukturpunkte: </label> <input class="b62" type="number" [(ngModel)]="ruestung.strukPu">
</div>
<div class="zeile">
  <label class="b30">Komplexität: </label> <input class="b62" type="text" [(ngModel)]="ruestung.komplexitaet">
</div>
<div class="zeile">
  <label class="b30">AP-Kosten Berufsgeheimnis (Herstellung): </label> <input class="b62" type="number" [(ngModel)]="ruestung.apKostenZumErlernen">
</div>
<div class="zeile">
  <label class="b30">Vorteil: </label> <input class="b62" type="text" [(ngModel)]="ruestung.rvorteil">
</div>
<div class="zeile">
  <label class="b30">Nachteil: </label> <input class="b62" type="text" [(ngModel)]="ruestung.rnachteil">
</div>
<div class="zeile">
  <label class="b30">Stabilitätswert: </label> <input class="b62" type="number" [(ngModel)]="ruestung.stabilitaetswert">
</div>
<div class="zeile">
  <label class="b30">Zusätzliche Abzüge: </label> <input class="b62" type="text" [(ngModel)]="ruestung.zusaetzlicheAbzuege">
</div>
<div class="zeile">
  <label class="b30">Belastung: </label> <input class="b62" type="number" [(ngModel)]="ruestung.belastung">
</div>
<div class="zeile">
  <label class="b30">Kathegorie: </label> <input class="b62" type="text" [(ngModel)]="ruestung.kathegorie">
</div>

<h3 style="text-align: center">Rüstung</h3>
<div class="zeile">
  <label class="b30">rsKopf: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rsKopf">
</div>
<div class="zeile">
  <label class="b30">rsBrust: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rsBrust">
</div>
<div class="zeile">
  <label class="b30">rsBauch: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rsBauch">
</div>
<div class="zeile">
  <label class="b30">rsRuecken: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rsRuecken">
</div>
<div class="zeile">
  <label class="b30">rslArm: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rslArm">
</div>
<div class="zeile">
  <label class="b30">rsrArm: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rsrArm">
</div>
<div class="zeile">
  <label class="b30">rslBein: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rslBein">
</div>
<div class="zeile">
  <label class="b30">rsrBein: </label> <input class="b62" type="number" [(ngModel)]="ruestung.rsrBein">
</div>

<h3 style="text-align: center">Kälteschutz</h3>
<div class="zeile">
  <label class="b30">ksKopf: </label> <input class="b62" type="number" [(ngModel)]="ruestung.ksKopf">
</div>
<div class="zeile">
  <label class="b30">ksBrust: </label> <input class="b62" type="number" [(ngModel)]="ruestung.ksBrust">
</div>
<div class="zeile">
  <label class="b30">ksBauch: </label> <input class="b62" type="number" [(ngModel)]="ruestung.ksBauch">
</div>
<div class="zeile">
  <label class="b30">ksRuecken: </label> <input class="b62" type="number" [(ngModel)]="ruestung.ksRuecken">
</div>
<div class="zeile">
  <label class="b30">kslArm: </label> <input class="b62" type="number" [(ngModel)]="ruestung.kslArm">
</div>
<div class="zeile">
  <label class="b30">ksrArm: </label> <input class="b62" type="number" [(ngModel)]="ruestung.ksrArm">
</div>
<div class="zeile">
  <label class="b30">kslBein: </label> <input class="b62" type="number" [(ngModel)]="ruestung.kslBein">
</div>
<div class="zeile">
  <label class="b30">ksrBein: </label> <input class="b62" type="number" [(ngModel)]="ruestung.ksrBein">
</div>
<div class="zeile" >
  <input class="92" type="button" value="Rüstung hinzufügen" required="true" (click)="addRuestungToDB()">
</div>
