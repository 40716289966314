import {Werte} from './charakter/werte';
import {Gtalent} from './glossar/gtalent';
import {Kombivun} from './kombivun';
import {Kombisf} from './kombisf';
import {Gkampftechnik} from './glossar/gkampftechnik';

export class Kombikampftechnik {
  charaktertalent: Werte;
  glossarkampftechnik: Gkampftechnik;
  vorteile: Kombivun[] = [];
  nachteile: Kombivun[] = [];
  sonderfertigkeiten: Kombisf[] = [];
  anwVorteile: Map<string, Kombivun[]> = new Map<string, Kombivun[]>();
  anwNachteile: Map<string, Kombivun[]> = new Map<string, Kombivun[]>();
  anwSonderfertigkeiten: Map<string, Kombisf[]> = new Map<string, Kombisf[]>();
  anwSpezialisierungen: string[] = [];
  attributeAlsZahl = true;
  aktiveSpzialisierung = '';
  leIndex = 0;
  leiteigenschaften: string[] = [];
}
