export class Gtierangriffefk {
  angriffsart: string;
  fk: number;
  lz: number;
  schaden: string;
  reichweiten: string;

  public copy(other: Gtierangriffefk): void {
    this.angriffsart = other.angriffsart;
    this.fk = other.fk;
    this.lz = other.lz;
    this.schaden = other.schaden;
    this.reichweiten = other.reichweiten;
  }
}
