<div class="flexVertikal" *ngIf="erfahrungsInfos != null">
  <!-- Stufe -->
  <select [(ngModel)]="npc.stufe" (ngModelChange)="bestimmeEigenschaftswerte()">
    <option [value]="erfahrungsInfos[0].indexOf(stf)" *ngFor="let stf of erfahrungsInfos[0]">{{stf}}</option>
  </select>

  <div *ngFor="let row of [0, 1, 2, 3, 4, 5, 6, 7]">
    <div>{{erfahrungsBeschreibungen[row]}}: {{erfahrungsInfos[row][npc.stufe]}}</div>
  </div>

  <!-- Spezies -->
  <select [(ngModel)]="npc.spezies" (ngModelChange)="bestimmeEigenschaftswerte()">
    <option [value]="alleSpezies.indexOf(spezies)" *ngFor="let spezies of alleSpezies">{{spezies.art}} ({{spezies.unterArt}})</option>
  </select>
  <div *ngFor="let eigMod of alleSpezies[npc.spezies].eigenschaftsAenderungen">{{eigMod}}</div>
  <!-- Typ -->
  <select [(ngModel)]="npc.type" (ngModelChange)="bestimmeEigenschaftswerte()">
    <option [value]="typ" *ngFor="let typ of npcTypen">{{typ}}</option>
  </select>
  <div class="zeile">
    <div class="small" *ngFor="let eig of kaempferin.eigenschaften">{{eig.name}}</div>
  </div>
  <div class="zeile">
    <div class="small" *ngFor="let eig of kaempferin.eigenschaften">{{eig.startwert}}</div>
  </div>
  <div>Kosten: {{apKosten[0]}} + {{apKosten[1]}}</div>
  <!-- Spezies -->
  <select [(ngModel)]="npc.kultur" (ngModelChange)="bestimmeEigenschaftswerte()">
    <option [value]="alleKulturen.indexOf(kultur)" *ngFor="let kultur of alleKulturen">{{kultur.name}}</option>
  </select>




  <div>name: {{kaempferin.name}}</div>
  <div>spieler: {{kaempferin.spieler}}</div>
  <div>npc: {{kaempferin.npc}}</div>
  <div>ini: {{kaempferin.ini}}</div>
  <div>basisIni: {{kaempferin.basisIni}}</div>
  <div>lep: {{kaempferin.lep}}</div>
  <div>lepBasis: {{kaempferin.lepBasis}}</div>
  <div>aw: {{kaempferin.aw}}</div>
  <div>maxAngriffe: {{kaempferin.maxAngriffe}}</div>
  <div>gs: {{kaempferin.gs}}</div>
  <div>gsBasis: {{kaempferin.gsBasis}}</div>
  <div>zielGroesse: {{kaempferin.zielGroesse}}</div>
  <div>zielName: {{kaempferin.zielName}}</div>
  <div>gegnerInNahkampfreichweite: {{kaempferin.gegnerInNahkampfreichweite}}</div>
  <div>aktionenUebrig: {{kaempferin.aktionenUebrig}}</div>
  <div>laengerfristigeHandlung: {{kaempferin.laengerfristigeHandlung}}</div>
  <div>freieAktionenUebrig: {{kaempferin.freieAktionenUebrig}}</div>
  <div>verteidigungenBisher: {{kaempferin.verteidigungenBisher}}</div>
  <div *ngFor="let kt of kaempferin.kampftechniken">{{kt.name}}: {{kt.wert}}</div>
  <div>rechteHand: {{kaempferin.rechteHand.name}} ({{kaempferin.rechteHand.kampftechnik}}) AT: {{kaempferin.rechteHand.at}} PA: {{kaempferin.rechteHand.pa}}</div>
  <div>atMod / paMod: {{kaempferin.rechteHand.atMod}} / {{kaempferin.rechteHand.paMod}}</div>
  <div>tp: {{kaempferin.rechteHand.getCurrentTP(0)}}</div>
  <div>linkeHand: {{kaempferin.linkeHand.name}} ({{kaempferin.linkeHand.kampftechnik}}) AT: {{kaempferin.linkeHand.at}} PA: {{kaempferin.linkeHand.pa}}</div>
  <div>atMod / paMod: {{kaempferin.linkeHand.atMod}} / {{kaempferin.linkeHand.paMod}}</div>
  <div>tp: {{kaempferin.linkeHand.getCurrentTP(0)}}</div>
  <div>rsZonen: {{kaempferin.rsZonen}}</div>
  <div>rs: {{kaempferin.rs}}</div>
  <div>rasse: {{kaempferin.rasse}}</div>
  <div>weiblich: {{kaempferin.weiblich}}</div>
</div>
