import {Component, Input, OnInit} from '@angular/core';
import {Glossar} from '../../../../_classes/glossar/glossar';
import {Gsonderfertigkeit} from '../../../../_classes/glossar/gsonderfertigkeit';
import {GsonderfertigkeitVoraussetzung} from '../../../../_classes/glossar/gsonderfertigkeit-voraussetzung';
import {DataService} from '../../../../_services/data.service';
import {Gkampfsonderfertigkeit} from '../../../../_classes/glossar/gkampfsonderfertigkeit';
import {Gtraditionsartefaktsonderfertigkeit} from '../../../../_classes/glossar/gtraditionsartefaktsonderfertigkeit';
import {Gtraditionsartefakt} from '../../../../_classes/glossar/gtraditionsartefakt';

@Component({
  selector: 'app-gsonderfertigkeiten',
  templateUrl: './gsonderfertigkeiten.component.html',
  styleUrls: ['./gsonderfertigkeiten.component.sass']
})
export class GsonderfertigkeitenComponent implements OnInit {
  @Input() glossar: Glossar;

  klassen: string[] = ['GSonderfertigkeit', 'GKampfSonderfertigkeit', 'GTraditionsartefaktSonderfertigkeit'];
  kampftechniken: string[] = [];
  waffen: string[] = [];

  arten: string[] = [];
  art = '';
  unterarten: string[] = [];
  unterart = '';
  namen: string[] = [];
  name = '';
  sonderfertigkeit: Gsonderfertigkeit = new Gsonderfertigkeit();
  neueVoraussetzung: GsonderfertigkeitVoraussetzung = new GsonderfertigkeitVoraussetzung();
  neueKampftechnik = '';
  neueWaffenbegrenzung = '';
  neueFreigeschalteteSF = '';
  neuesTraditionsartefakt: Gtraditionsartefakt = new Gtraditionsartefakt();
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.glossar.sonderfertigkeiten.forEach(sf => {
      // Fülle arten
      if (!this.arten.includes(sf.art)) {
        this.arten.push(sf.art);
      }
    });
    this.arten.sort((a, b) => (a < b ? -1 : 1));
    // lege art initial fest
    this.art = this.arten[0];

    this.glossar.sonderfertigkeiten.forEach(sf => {
      // Fülle unterArten für art
      if (sf.art === this.art) {
        if (!this.unterarten.includes(sf.unterArt)) {
          this.unterarten.push(sf.unterArt);
        }
      }
    });
    this.unterarten.sort((a, b) => (a < b ? -1 : 1));
    // lege unterArt initial fest
    this.unterart = this.unterarten[0];

    this.glossar.sonderfertigkeiten.forEach(sf => {
      // Fülle unterArten für art
      if (sf.art === this.art && sf.unterArt === this.unterart) {
        if (!this.namen.includes(sf.name)) {
          this.namen.push(sf.name);
        }
      }
    });
    this.namen.sort((a, b) => (a < b ? -1 : 1));
    // lege unterArt initial fest
    this.name = this.namen[0];
    this.waehleSonderfertigkeit();
    this.glossar.kampftechniken.forEach(kt => {
      this.kampftechniken.push(kt.name);
    });
    this.kampftechniken.sort((a, b) => (a < b ? -1 : 1));
    this.kampftechniken.unshift('alle');
    this.kampftechniken.unshift('');

    this.waffen = [];
    this.glossar.ausruestung.forEach(item => {
      if (item.type === 'Nahkampfwaffe' || item.type === 'Fernkampfwaffe' || item.type === 'Parierwaffe'){
        this.waffen.push(item.name);
      }
    });
    this.waffen.sort((a, b) => (a < b ? -1 : 1));
    this.waffen.unshift('alle');
    this.waffen.unshift('');
  }

  aendereArt(): void {
    this.unterarten = [];
    this.glossar.sonderfertigkeiten.forEach(sf => {
      // Fülle unterArten für art
      if (sf.art === this.art) {
        if (!this.unterarten.includes(sf.unterArt)) {
          this.unterarten.push(sf.unterArt);
        }
      }
    });
    this.unterarten.sort((a, b) => (a < b ? -1 : 1));
    // lege unterArt initial fest
    this.unterart = this.unterarten[0];
    this.aendereUnterart();
  }

  aendereUnterart(): void {
    this.namen = [];
    this.glossar.sonderfertigkeiten.forEach(sf => {
      // Fülle unterArten für art
      if (sf.art === this.art && sf.unterArt === this.unterart) {
        if (!this.namen.includes(sf.name)) {
          this.namen.push(sf.name);
        }
      }
    });
    this.namen.sort((a, b) => (a < b ? -1 : 1));
    // lege unterArt initial fest
    this.name = this.namen[0];
    this.waehleSonderfertigkeit();
  }

  waehleSonderfertigkeit(): void {
    this.sonderfertigkeit = new Gsonderfertigkeit();
    this.glossar.sonderfertigkeiten.forEach(sf => {
      // Fülle unterArten für art
      if (sf.art === this.art && sf.unterArt === this.unterart && sf.name === this.name) {
        console.log(sf);
        if (sf.klasse === 'GKampfSonderfertigkeit') {
          this.sonderfertigkeit = new Gkampfsonderfertigkeit();
        } else if (sf.klasse === 'GTraditionsartefaktSonderfertigkeit') {
          this.sonderfertigkeit = new Gtraditionsartefaktsonderfertigkeit();
        }
        this.sonderfertigkeit.copy(sf);
      }
    });
    this.entferneUmbrueche();
    console.log(this.sonderfertigkeit);
  }

  entferneKampftechnik(kt: string): void {
    const neueKt: string[] = [];
    (this.sonderfertigkeit as Gkampfsonderfertigkeit).kampffertigkeiten.forEach(k => {
      if (k !== kt) {
        neueKt.push(k);
      }
    });
    (this.sonderfertigkeit as Gkampfsonderfertigkeit).kampffertigkeiten = [];
    neueKt.forEach(k => {
      (this.sonderfertigkeit as Gkampfsonderfertigkeit).kampffertigkeiten.push(k);
    });
  }

  fuegeKampftechnikHinzu(): void {
    if (this.neueKampftechnik !== '') {
      if (this.neueKampftechnik === 'alle') {
        (this.sonderfertigkeit as Gkampfsonderfertigkeit).kampffertigkeiten = [];
        this.kampftechniken.forEach(kt => {
          if (kt !== '' && kt !== 'alle') {
            (this.sonderfertigkeit as Gkampfsonderfertigkeit).kampffertigkeiten.push(kt);
          }
        });
      } else {
        (this.sonderfertigkeit as Gkampfsonderfertigkeit).kampffertigkeiten.push(this.neueKampftechnik);
      }
      this.neueKampftechnik = '';
    }
  }

  entferneWaffenbegrenzung(w: string): void {
    const neu: string[] = [];
    (this.sonderfertigkeit as Gkampfsonderfertigkeit).begrenztAufWaffen.forEach(wf => {
      if (wf !== w) {
        neu.push(wf);
      }
    });
    (this.sonderfertigkeit as Gkampfsonderfertigkeit).begrenztAufWaffen = [];
    neu.forEach(wf => {
      (this.sonderfertigkeit as Gkampfsonderfertigkeit).begrenztAufWaffen.push(wf);
    });
  }

  fuegeWaffenbegrenzungHinzu(): void {
    if (this.neueWaffenbegrenzung !== '') {
      if (this.neueWaffenbegrenzung === 'alle') {
        (this.sonderfertigkeit as Gkampfsonderfertigkeit).begrenztAufWaffen = [];
        this.waffen.forEach(w => {
          if (w !== '' && w !== 'alle') {
            (this.sonderfertigkeit as Gkampfsonderfertigkeit).begrenztAufWaffen.push(w);
          }
        });
      } else {
        (this.sonderfertigkeit as Gkampfsonderfertigkeit).begrenztAufWaffen.push(this.neueWaffenbegrenzung);
      }
      this.neueWaffenbegrenzung = '';
    }
  }

  entferneFreigeschalteteSF(s: string): void {
    const neu: string[] = [];
    (this.sonderfertigkeit as Gkampfsonderfertigkeit).freigeschalteteSonderfertigkeiten.forEach(sf => {
      if (sf !== s) {
        neu.push(sf);
      }
    });
    (this.sonderfertigkeit as Gkampfsonderfertigkeit).freigeschalteteSonderfertigkeiten = [];
    neu.forEach(sf => {
      (this.sonderfertigkeit as Gkampfsonderfertigkeit).freigeschalteteSonderfertigkeiten.push(sf);
    });
  }

  fuegeFreigeschalteteSFHinzu(): void {
    (this.sonderfertigkeit as Gkampfsonderfertigkeit).freigeschalteteSonderfertigkeiten.push(this.neueFreigeschalteteSF);
    this.neueFreigeschalteteSF = '';
  }

  entferneVoraussetzung(voraus: GsonderfertigkeitVoraussetzung): void {
    const neueVoaus: GsonderfertigkeitVoraussetzung[] = [];
    this.sonderfertigkeit.voraussetzungen.forEach(v => {
      if (v !== voraus) {
        neueVoaus.push(v);
      }
    });
    this.sonderfertigkeit.voraussetzungen = [];
    neueVoaus.forEach(v => {
      this.sonderfertigkeit.voraussetzungen.push(v);
    });
  }

  fuegeVoraussetzungHinzu(): void {
    console.log(this.sonderfertigkeit);
    this.sonderfertigkeit.voraussetzungen.push(this.neueVoraussetzung);
    this.neueVoraussetzung = new GsonderfertigkeitVoraussetzung();
  }

  entferneTraditionsartefakt(trad: Gtraditionsartefakt): void {
    const neueTradArt: Gtraditionsartefakt[] = [];
    (this.sonderfertigkeit as Gtraditionsartefaktsonderfertigkeit).traditionsartefakte.forEach(t => {
      if (t !== trad) {
        neueTradArt.push(t);
      }
    });
    (this.sonderfertigkeit as Gtraditionsartefaktsonderfertigkeit).traditionsartefakte = [];
    neueTradArt.forEach(t => {
      (this.sonderfertigkeit as Gtraditionsartefaktsonderfertigkeit).traditionsartefakte.push(t);
    });
  }

  fuegeTraditionsartefaktHinzu(): void {
    (this.sonderfertigkeit as Gtraditionsartefaktsonderfertigkeit).traditionsartefakte.push(this.neuesTraditionsartefakt);
    this.neuesTraditionsartefakt = new Gtraditionsartefakt();
  }

  sendeSonderfertigkeitAnDB(): void {
    this.dataService.addSonderfertigkeit(this.sonderfertigkeit);
    this.resetSonderfertigkeit();
  }

  resetSonderfertigkeit(): void {
    const art = this.sonderfertigkeit.art;
    const unterart = this.sonderfertigkeit.unterArt;
    this.sonderfertigkeit = new Gsonderfertigkeit();
    this.sonderfertigkeit.art = art;
    this.sonderfertigkeit.unterArt = unterart;
  }

  entferneUmbrueche(): void {
    if (this.sonderfertigkeit.beschreibung != null) {
      while (this.sonderfertigkeit.beschreibung.includes('\n')) {
        this.sonderfertigkeit.beschreibung = this.sonderfertigkeit.beschreibung.replace('\n', ' ');
      }
    }
    if (this.sonderfertigkeit.regel != null) {
      while (this.sonderfertigkeit.regel.includes('\n')) {
        this.sonderfertigkeit.regel = this.sonderfertigkeit.regel.replace('\n', ' ');
      }
    }
  }

}
