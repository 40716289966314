<input type="button" class="button" value="lade Heldenerschaffung" (click)="loadHeldenerschaffung()">
<h3>Wähle einen Erfahrungsgrad</h3>
<select [(ngModel)]="heldin.$erfahrungStufe" (ngModelChange)="getInfos()">
  <option [value]="$erfahrungsMenu.indexOf(erfahrung)" *ngFor="let erfahrung of $erfahrungsMenu">{{erfahrung}}</option>
</select>
<div class="infobox">
  <div class="zeile" *ngFor="let data of $erfahrungsInfo; let i = index">
    <div class="left">
      {{ data[0] }}:
    </div>
    <div class="right">
      {{ data[1] }}
    </div>
  </div>
</div>
<h3>Wähle ein Geschlecht</h3>
<select [(ngModel)]="this.sex" (ngModelChange)="setGeschlecht()">
  <option value='true'>weiblich</option>
  <option value='false'>männlich</option>
</select>
