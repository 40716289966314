<div class="verticalbody">

  <div class="flexHorizontal">
    <div class="menu">
      <div *ngFor="let art of zauberarten" (click)="setZauberArt(art)">
        <label class="zauberartglow" *ngIf="art === localData.art">{{art}}</label>
        <label class="zauberart" *ngIf="art !== localData.art">{{art}}</label>
      </div>
    </div>
    <div class="tradition">
      <div class="traditionBild">{{tradition}}</div>
      <img class="traditionBild" src="../../../../assets/images/traditionen/{{tradition}}.png"/>
      <div class="traditionBild">({{traditionEigenschaft}})</div>
    </div>
  </div>


  <div class="qualitaet">
    <div class="zeileOhneBorder">
      <div class="start">Fertigkeitspunkte:</div>
      <div class="hell">0-3</div>
      <div class="dunkel">4-6</div>
      <div class="hell">7-9</div>
      <div class="dunkel">10-12</div>
      <div class="hell">13-15</div>
      <div class="dunkel">16+</div>
    </div>
    <div class="zeileOhneBorder">
      <div class="start">Qualitätsstufe:</div>
      <div class="hell">1</div>
      <div class="dunkel">2</div>
      <div class="hell">3</div>
      <div class="dunkel">4</div>
      <div class="hell">5</div>
      <div class="dunkel">6</div>
    </div>
  </div>
  <div class="modBereich" *ngIf="zauber2Mod != null">
    <h2>Modifikationen für {{zauber2Mod.glossarZauber.name}}</h2>
    <h3>erlaubte Modifikationen: {{erlaubteModifikationen}}</h3>
    <div class="flexArea">
      <div class="space" *ngFor="let m of mods index as i">
        <div [ngClass]="m" (click)="waehleModifiation(i)">
          <div>{{modNamen[i]}}</div>
        </div>
      </div>
    </div>
    <div class="auswirkung{{mods[i]}}" *ngFor="let auswirkung of modAuswirkungen index as i">
      <b>{{modNamen[i]}}</b>: {{auswirkung}}
    </div>



  </div>



  <!-- Tradition -->
  <div *ngIf="localData.art === 'deine Tradition'">
    <h1>{{tradition}}</h1>
    <div class="spalten">
      <div class="twoThirdsSpalte">
        <h3>Beschreibung</h3>
        <div class="textfeld">{{traditionSF.beschreibung}}</div>
        <h3>Regel</h3>
        <div class="textfeldBullet" *ngFor="let regel of regeln">• {{regel}}</div>
      </div>
      <div class="oneThirdSpalte">
        <div *ngFor="let artefakt of tradtitionsArtefakte.get(tradition)">
          <h3>Traditionsartefakt: {{artefakt}}</h3>
          <div class="spalten">
            <div class="twoThirdsSpalte">
              <div *ngIf="artefakt === 'Magierstab'">
                Das Volumen eines Stabes wird in Punkten gemessen.
                Jede Sonderfertigkeit, die in einen Stab gebunden wird,
                verbraucht eine gewisse Menge an Volumenpunkten.
                Wenn nicht genug Volumenpunkte vorhanden sind,
                kann der Stabzauber nicht in den Stab gebunden werden.
                Stäbe besitzen standardmäßig ein Volumen von 18
                Punkten.
              </div>
            </div>

            <div class="oneThirdSpalte">
              <div class="horizontal"><b>{{artefakt}}</b></div>
              <div class="artefakt" style="background-image: url('assets/images/items/{{artefakt}}.png')"></div>
              <div class="horizontal">Volumen</div>
              <div class="horizontal">
                <label *ngFor="let v of artefaktVolumen.get(artefakt) index as i">
                  <label *ngIf="i > 0">/</label> {{v}}
                </label>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

  <!-- Profession -->
  <div *ngIf="localData.art === 'deine Profession'">
    <div class="spalten">
      <div class="twoThirdsSpalte">
        <h2>{{charakter.profession}}</h2>
        <h3>Beschreibung</h3>
        <div class="textfeld">{{profession.beschreibung}}</div>
      </div>
      <div class="oneThirdSpalte">
        <div class="siegelfeld" style="background-image: url('assets/images/hand.png')">
          <div class="siegel">{{profession.image2}}</div>
          <div class="siegelText">(Akademiesiegel)</div>
        </div>
        <h3>Ausrüstung und Tracht</h3>
        <div class="textfeld">{{profession.ausruestungUndTracht}}</div>

      </div>
    </div>

  </div>

  <div *ngIf="localData.art !== 'deine Tradition'">
    <!--h1>{{localData.zauberart}}</h1-->
    <div class="table">

      <!-- Zauber -->
      <div *ngIf="localData.art === 'Zauber'">
        <h1>Erlernte Zauber aus deiner Tradition</h1>
        <div class="kopfzeile">
          <div class="infosymbol"></div>
          <div class="name">Talent</div>
          <div class="probe">Probe</div>
          <div class="fwModName">FW +/- Mod</div>
          <div class="merkmal">Merkmal</div>
          <div class="zielkategorie">Zielkategorie</div>
          <div class="zauberDauer">Zauberdauer</div>
          <div class="aspKosten">AsP Kosten</div>
        </div>
        <div *ngFor="let z of zauber">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + z.glossarZauber.name)">i</div>
            <div class="name" (click)="waehleZauber2Mod(z, true)">
              <label>{{z.glossarZauber.name}}</label>
              <!--label class="glowpos" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) > 0">{{z.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) < 0">{{z.glossartalent.name}}</label-->
            </div>

            <div class="attribute" (click)="z.attributeAlsZahl = !z.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of z.glossarZauber.probe">
                <label *ngIf="!z.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) < 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label class="bad" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) > 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) === 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
              </div>
            </div>

            <div class="fwmod">
              <div class="fw">
                <label *ngIf="z.aktiveSpzialisierung === ''">{{charakter.getWert(z.glossarZauber.name).wert}}</label>
                <label class="good" *ngIf="z.aktiveSpzialisierung !== ''">{{charakter.getWert(z.glossarZauber.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
            </div>
            <div class="merkmal">{{z.glossarZauber.merkmal}}</div>
            <div class="zielkategorie">{{z.glossarZauber.zielKategorie}}</div>
            <div class="zauberDauer">{{z.glossarZauber.zauberDauer}}</div>
            <div class="aspKosten">{{z.glossarZauber.aspKosten}}</div>



          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFO' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zu: {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{z.glossarZauber.beschreibung}}</div>
            <div class="text"><b>Reichweite: </b>{{z.glossarZauber.reichweite}}</div>
            <div class="text"><b>Wirkung: </b>{{z.glossarZauber.wirkung}}</div>
            <div class="text"><b>Wirkungsdauer: </b>{{z.glossarZauber.wirkungsDauer}}</div>
            <div class="text" *ngIf="z.glossarZauber.wirkungQS.length > 0"><b>Wirkung nach QS: </b></div>
            <div class="text" *ngFor="let wirkung of z.glossarZauber.wirkungQS"><b>{{wirkung.qs}}</b>: {{wirkung.wirkung}}</div>

            <div class="text">
              <b>Verbreitung: </b>
              <label *ngFor="let v of z.glossarZauber.verbreitung index as i">
                <label *ngIf="i === 0">{{v}}</label>
                <label *ngIf="i > 0">, {{v}}</label>
              </label>
            </div>
            <!--div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{z.glossarZauber.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{z.glossarZauber.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{z.glossarZauber.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{z.glossarZauber.patzer}}</div>
            <div class="text" *ngIf="z.glossarZauber.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{z.glossartalent.werkzeug}}</div-->
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(z.glossarZauber.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(z.glossarZauber.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFOANWENDUNG' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
        <h1>Erlernte Zauber aus anderen Traditionen</h1>
        <div class="kopfzeile">
          <div class="infosymbol"></div>
          <div class="name">Talent</div>
          <div class="probe">Probe</div>
          <div class="fwModName">FW +/- Mod</div>
          <div class="merkmal">Merkmal</div>
          <div class="zielkategorie">Zielkategorie</div>
          <div class="zauberDauer">Zauberdauer</div>
          <div class="aspKosten">AsP Kosten</div>
        </div>
        <div *ngFor="let z of zauberFremd">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + z.glossarZauber.name)">i</div>
            <div class="name" (click)="waehleZauber2Mod(z, false)">
              <label>{{z.glossarZauber.name}}</label>
              <!--label class="glowpos" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) > 0">{{z.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) < 0">{{z.glossartalent.name}}</label-->
            </div>

            <div class="attribute" (click)="z.attributeAlsZahl = !z.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of z.glossarZauber.probe">
                <label *ngIf="!z.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) < 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label class="bad" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) > 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) === 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
              </div>
            </div>

            <div class="fwmod">
              <div class="fw">
                <label *ngIf="z.aktiveSpzialisierung === ''">{{charakter.getWert(z.glossarZauber.name).wert}}</label>
                <label class="good" *ngIf="z.aktiveSpzialisierung !== ''">{{charakter.getWert(z.glossarZauber.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
            </div>
            <div class="merkmal">{{z.glossarZauber.merkmal}}</div>
            <div class="zielkategorie">{{z.glossarZauber.zielKategorie}}</div>
            <div class="zauberDauer">{{z.glossarZauber.zauberDauer}}</div>
            <div class="aspKosten">{{z.glossarZauber.aspKosten}}</div>


          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFO' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zu: {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{z.glossarZauber.beschreibung}}</div>
            <div class="text"><b>Reichweite: </b>{{z.glossarZauber.reichweite}}</div>
            <div class="text"><b>Wirkung: </b>{{z.glossarZauber.wirkung}}</div>
            <div class="text"><b>Wirkungsdauer: </b>{{z.glossarZauber.wirkungsDauer}}</div>
            <div class="text" *ngIf="z.glossarZauber.wirkungQS.length > 0"><b>Wirkung nach QS: </b></div>
            <div class="text" *ngFor="let wirkung of z.glossarZauber.wirkungQS"><b>{{wirkung.qs}}</b>: {{wirkung.wirkung}}</div>

            <div class="text">
              <b>Verbreitung: </b>
              <label *ngFor="let v of z.glossarZauber.verbreitung index as i">
                <label *ngIf="i === 0">{{v}}</label>
                <label *ngIf="i > 0">, {{v}}</label>
              </label>
            </div>
            <!--div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{z.glossarZauber.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{z.glossarZauber.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{z.glossarZauber.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{z.glossarZauber.patzer}}</div>
            <div class="text" *ngIf="z.glossarZauber.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{z.glossartalent.werkzeug}}</div-->
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(z.glossarZauber.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(z.glossarZauber.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFOANWENDUNG' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
      </div>

      <!-- Rituale -->
      <div *ngIf="localData.art === 'Rituale'">
        <h1>Erlernte Rituale aus deiner Tradition</h1>
        <div class="kopfzeile">
          <div class="infosymbol"></div>
          <div class="name">Talent</div>
          <div class="probe">Probe</div>
          <div class="fwModName">FW +/- Mod</div>
          <div class="merkmal">Merkmal</div>
          <div class="zielkategorie">Zielkategorie</div>
          <div class="zauberDauer">Zauberdauer</div>
          <div class="aspKosten">AsP Kosten</div>
        </div>
        <div *ngFor="let z of rituale">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + z.glossarZauber.name)">i</div>
            <div class="name" (click)="waehleZauber2Mod(z, true)">
              <label>{{z.glossarZauber.name}}</label>
              <!--label class="glowpos" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) > 0">{{z.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) < 0">{{z.glossartalent.name}}</label-->
            </div>

            <div class="attribute" (click)="z.attributeAlsZahl = !z.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of z.glossarZauber.probe">
                <label *ngIf="!z.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) < 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label class="bad" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) > 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) === 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>

              </div>
            </div>

            <div class="fwmod">
              <div class="fw">
                <label *ngIf="z.aktiveSpzialisierung === ''">{{charakter.getWert(z.glossarZauber.name).wert}}</label>
                <label class="good" *ngIf="z.aktiveSpzialisierung !== ''">{{charakter.getWert(z.glossarZauber.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
            </div>
            <div class="merkmal">{{z.glossarZauber.merkmal}}</div>
            <div class="zielkategorie">{{z.glossarZauber.zielKategorie}}</div>
            <div class="zauberDauer">{{z.glossarZauber.zauberDauer}}</div>
            <div class="aspKosten">{{z.glossarZauber.aspKosten}}</div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFO' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zu: {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{z.glossarZauber.beschreibung}}</div>
            <div class="text"><b>Reichweite: </b>{{z.glossarZauber.reichweite}}</div>
            <div class="text"><b>Wirkung: </b>{{z.glossarZauber.wirkung}}</div>
            <div class="text"><b>Wirkungsdauer: </b>{{z.glossarZauber.wirkungsDauer}}</div>
            <div class="text" *ngIf="z.glossarZauber.wirkungQS.length > 0"><b>Wirkung nach QS: </b></div>
            <div class="text" *ngFor="let wirkung of z.glossarZauber.wirkungQS"><b>{{wirkung.qs}}</b>: {{wirkung.wirkung}}</div>

            <div class="text">
              <b>Verbreitung: </b>
              <label *ngFor="let v of z.glossarZauber.verbreitung index as i">
                <label *ngIf="i === 0">{{v}}</label>
                <label *ngIf="i > 0">, {{v}}</label>
              </label>
            </div>
            <!--div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{z.glossarZauber.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{z.glossarZauber.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{z.glossarZauber.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{z.glossarZauber.patzer}}</div>
            <div class="text" *ngIf="z.glossarZauber.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{z.glossartalent.werkzeug}}</div-->
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(z.glossarZauber.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(z.glossarZauber.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFOANWENDUNG' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>

        <h1>Erlernte Rituale aus anderen Traditionen</h1>
        <div class="kopfzeile">
          <div class="infosymbol"></div>
          <div class="name">Talent</div>
          <div class="probe">Probe</div>
          <div class="fwModName">FW +/- Mod</div>
          <div class="merkmal">Merkmal</div>
          <div class="zielkategorie">Zielkategorie</div>
          <div class="zauberDauer">Zauberdauer</div>
          <div class="aspKosten">AsP Kosten</div>
        </div>

        <div *ngFor="let z of ritualeFremd">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + z.glossarZauber.name)">i</div>
            <div class="name" (click)="waehleZauber2Mod(z, false)">
              <label>{{z.glossarZauber.name}}</label>
              <!--label class="glowpos" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) > 0">{{z.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) < 0">{{z.glossartalent.name}}</label-->
            </div>

            <div class="attribute" (click)="z.attributeAlsZahl = !z.attributeAlsZahl">
              <div class="attr" *ngFor="let attr of z.glossarZauber.probe">
                <label *ngIf="!z.attributeAlsZahl">{{attr}}</label>
                <label class="good" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) < 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label class="bad" *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) > 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>
                <label *ngIf="z.attributeAlsZahl && getAttributMod(z.glossarZauber.name, attr) === 0">{{getZauberAttributWert(z.glossarZauber.name, attr)}}</label>

              </div>
            </div>

            <div class="fwmod">
              <div class="fw">
                <label *ngIf="z.aktiveSpzialisierung === ''">{{charakter.getWert(z.glossarZauber.name).wert}}</label>
                <label class="good" *ngIf="z.aktiveSpzialisierung !== ''">{{charakter.getWert(z.glossarZauber.name).wert + 2}}</label>
              </div>
              <div class="img">
                <img (click)="decreaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_neg.png"/>
              </div>
              <div class="mod">
                <label *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() !== 0">
                  <label class="good" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() > 0">+{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                  <label class="bad" *ngIf="charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert() < 0">{{charakter.getBeeinflWert(z.glossarZauber.name, 'manuell').getNumericWert()}}</label>
                </label>
                <!--label *ngIf="$talentModName === talentItem$.name && $talentModWert !== 0">
                  <label class="good" *ngIf="$talentModWert > 0">+{{$talentModWert}}</label>
                  <label class="bad" *ngIf="$talentModWert < 0">{{$talentModWert}}</label>
                </label-->
              </div>
              <div class="img">
                <img (click)="increaseZauberMod(z.glossarZauber.name)" src="../../../../assets/images/button_s_pos.png"/>
              </div>
            </div>
            <div class="merkmal">{{z.glossarZauber.merkmal}}</div>
            <div class="zielkategorie">{{z.glossarZauber.zielKategorie}}</div>
            <div class="zauberDauer">{{z.glossarZauber.zauberDauer}}</div>
            <div class="aspKosten">{{z.glossarZauber.aspKosten}}</div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFO' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zu: {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{z.glossarZauber.beschreibung}}</div>
            <div class="text"><b>Reichweite: </b>{{z.glossarZauber.reichweite}}</div>
            <div class="text"><b>Wirkung: </b>{{z.glossarZauber.wirkung}}</div>
            <div class="text"><b>Wirkungsdauer: </b>{{z.glossarZauber.wirkungsDauer}}</div>
            <div class="text" *ngIf="z.glossarZauber.wirkungQS.length > 0"><b>Wirkung nach QS: </b></div>
            <div class="text" *ngFor="let wirkung of z.glossarZauber.wirkungQS"><b>{{wirkung.qs}}</b>: {{wirkung.wirkung}}</div>

            <div class="text">
              <b>Verbreitung: </b>
              <label *ngFor="let v of z.glossarZauber.verbreitung index as i">
                <label *ngIf="i === 0">{{v}}</label>
                <label *ngIf="i > 0">, {{v}}</label>
              </label>
            </div>
            <!--div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{z.glossarZauber.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{z.glossarZauber.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{z.glossarZauber.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{z.glossarZauber.patzer}}</div>
            <div class="text" *ngIf="z.glossarZauber.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{z.glossartalent.werkzeug}}</div-->
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(z.glossarZauber.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(z.glossarZauber.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFOANWENDUNG' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
      </div>

      <!-- Zaubertricks -->
      <div *ngIf="localData.art === 'Zaubertricks'">
        <h1>Zaubertricks</h1>
        <div class="kopfzeile">
          <div class="infosymbol"></div>
          <div class="name">Zaubertrick</div>
          <div class="reichweite">Reichweite</div>
          <div class="wirkungsDauer">Wirkungsdauer</div>
          <div class="merkmal">Merkmal</div>
          <div class="zielkategorie">Zielkategorie</div>
          <div class="zauberDauer">Zauberdauer</div>
          <div class="aspKostenKlein">AsP Kosten</div>
        </div>
        <div *ngFor="let z of zaubertricks">
          <div class="zeile">
            <div class="infosymbol" (click)="setTalentInfo('INFO' + z.glossarZauber.name)">i</div>
            <div class="name">
              <label>{{z.glossarZauber.name}}</label>
              <!--label class="glowpos" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) > 0">{{z.glossartalent.name}}</label>
              <label class="glowneg" (click)="setTalentInfo(z.glossarZauber.name)" *ngIf="aktiveEinsatzmoeglichkeiten(z.glossartalent) < 0">{{z.glossartalent.name}}</label-->
            </div>
            <div class="reichweite">{{z.glossarZauber.reichweite}}</div>
            <div class="wirkungsDauer">{{z.glossarZauber.wirkungsDauer}}</div>
            <div class="merkmal">{{z.glossarZauber.merkmal}}</div>
            <div class="zielkategorie">{{z.glossarZauber.zielKategorie}}</div>
            <div class="zauberDauer">{{z.glossarZauber.zauberDauer}}</div>
            <div class="aspKostenKlein">{{z.glossarZauber.aspKosten}}</div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo === ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.vorteile">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.sonderfertigkeiten">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.nachteile">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === z.glossarZauber.name && talentanwendungInfo !== ''">
            <div class="header">
              <label></label>
              <label>Einsatzmöglichkeiten und Beeinflussungen für {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="zeile">
              <div class="linkeSpalte">
                <div *ngFor="let vorteil of z.anwVorteile.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(vorteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && vorteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || vorteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{vorteil.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let sf of z.anwSonderfertigkeiten.get(talentanwendungInfo)">
                  <label class="namePos" (click)="setEinsatzInfo(sf)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && sf.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || sf.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{sf.anzeigeName()}}</label>
                  </label>
                </div>
                <div *ngFor="let nachteil of z.anwNachteile.get(talentanwendungInfo)">
                  <label class="nameNeg" (click)="setEinsatzInfo(nachteil)">
                    <label class="extraGlow" *ngIf="einsatzmoeglichkeitInfo != null && nachteil.anzeigeName() === einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfo == null || nachteil.anzeigeName() !== einsatzmoeglichkeitInfo.anzeigeName()">{{nachteil.anzeigeName()}}</label>
                  </label>
                </div>
              </div>
              <div class="rechteSpalte">
                <label class="noInfo" *ngIf="einsatzmoeglichkeitInfo === null">- Informationen zu Beinflussungen und Einsatzmöglichkeiten -</label>
                <div *ngIf="einsatzmoeglichkeitInfo != null">
                  <div class="infoNeg" *ngIf="einsatzmoeglichkeitInfoType < 0">
                    <label>{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                  <div class="infoPos" *ngIf="einsatzmoeglichkeitInfoType >= 0">
                    <label *ngIf="einsatzmoeglichkeitInfoType === 0">{{einsatzmoeglichkeitInfo.glossarSF.regel}}</label>
                    <label *ngIf="einsatzmoeglichkeitInfoType === 1">{{einsatzmoeglichkeitInfo.glossarVuN.regel}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFO' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zu: {{z.glossarZauber.name}}</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Wirkung: </b>{{z.glossarZauber.wirkung}}</div>
            <div class="text" *ngIf="z.glossarZauber.wirkungQS.length > 0"><b>Wirkung nach QS: </b></div>
            <div class="text" *ngFor="let wirkung of z.glossarZauber.wirkungQS"><b>{{wirkung.qs}}</b>: {{wirkung.wirkung}}</div>

            <div class="text">
              <b>Verbreitung: </b>
              <label *ngFor="let v of z.glossarZauber.verbreitung index as i">
                <label *ngIf="i === 0">{{v}}</label>
                <label *ngIf="i > 0">, {{v}}</label>
              </label>
            </div>
            <!--div class="text"><b>Beispielhafte Auswirkungen guter Qualität: </b>{{z.glossarZauber.qualitaet}}</div>
            <div class="text"><b>Auswirkungen kritischer Erfolge: </b>{{z.glossarZauber.kritischerErfolg}}</div>
            <div class="text"><b>Auswirkungen misslungener Proben: </b>{{z.glossarZauber.misslungeneProbe}}</div>
            <div class="text"><b>Auswirkungen verpatzer Proben: </b>{{z.glossarZauber.patzer}}</div>
            <div class="text" *ngIf="z.glossarZauber.werkzeug !== ''"><b>Hilfsmittel und Werkzeuge: </b>{{z.glossartalent.werkzeug}}</div-->
            <div class="header" *ngIf="charakter.getAlleBeeinflWerte(z.glossarZauber.name).length > 0">
              <label></label>
              <label>aktive Modifikatoren</label>
              <label></label>
            </div>
            <div class="text" *ngFor="let mod of charakter.getAlleBeeinflWerte(z.glossarZauber.name)">
              <b>{{mod.modName}}:</b> {{mod.modWert}}
            </div>
          </div>

          <div class="info" *ngIf="zauberInfo === 'INFOANWENDUNG' + z.glossarZauber.name">
            <div class="header">
              <label></label>
              <label>Informationen zum Talent {{z.glossarZauber.name}} ({{talentanwendungInfo}})</label>
              <label class="cross" (click)="resetTalentInfo()">X</label>
            </div>
            <div class="text"><b>Beschreibung: </b>{{betrachteteAnwendung.beschreibung}}</div>
            <div class="text"><b>Art der Probe: </b>{{betrachteteAnwendung.probenart}}</div>
            <div class="text"><b>Beispiel: </b>{{betrachteteAnwendung.beispiel}}</div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <div class="spacer"></div>
</div>


