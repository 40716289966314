import {NameWert} from '../name-wert';
import {VorUndNachteil} from '../charakter/vorUndNachteil';
import {Gtierwissen} from './gtierwissen';
import {Gtiergroesse} from './gtiergroesse';
import {Gtierangriffenk} from './gtierangriffenk';
import {Gtiersf} from './gtiersf';
import {Gtierangriffefk} from './gtierangriffefk';

export class Gtier {
  art: string;
  unterArt: string;
  variante: string;
  beschreibung: string;
  minGewicht: number;
  maxGewicht: number;
  aktionen: number;
  anzahl: string;
  groessenKategorie: string;
  typus: string;
  kampfverhalten: string;
  flucht: string;
  anekdoten: string[];
  wissensProbe: Gtierwissen[];
  groessen: Gtiergroesse[];
  vun: VorUndNachteil[];
  werte: NameWert[];
  angriffenk: Gtierangriffenk[];
  angriffefk: Gtierangriffefk[];
  sf: Gtiersf[];
  sonderregeln: NameWert[];
  anmerkungen: NameWert[];

  constructor() {
    this.art = '';
    this.unterArt = '';
    this.variante = '';
    this.beschreibung = '';
    this.minGewicht = 0;
    this.maxGewicht = 0;
    this.aktionen = 1;
    this.anzahl = '';
    this.groessenKategorie = 'mittel';
    this.typus = 'vierbeinig';
    this.kampfverhalten = '';
    this.flucht = '';
    this.anekdoten = [];
    this.wissensProbe = [];
    this.groessen = [];
    this.vun = [];
    this.werte = [];
    this.angriffenk = [];
    this.angriffefk = [];
    this.sf = [];
    this.sonderregeln = [];
    this.anmerkungen = [];
  }

  public copy(other: Gtier): void {
    this.art = other.art;
    this.unterArt = other.unterArt;
    this.variante = other.variante;
    this.beschreibung = other.beschreibung;
    this.minGewicht = other.minGewicht;
    this.maxGewicht = other.maxGewicht;
    this.aktionen = other.aktionen;
    this.anzahl = other.anzahl;
    this.groessenKategorie = other.groessenKategorie;
    this.typus = other.typus;
    this.kampfverhalten = other.kampfverhalten;
    this.flucht = other.flucht;
    this.anekdoten = [];
    other.anekdoten.forEach(eintrag => {
      this.anekdoten.push(eintrag);
    });
    this.wissensProbe = [];
    other.wissensProbe.forEach(eintrag => {
      const neu: Gtierwissen = new Gtierwissen();
      neu.copy(eintrag);
      this.wissensProbe.push(neu);
    });
    this.groessen = [];
    other.groessen.forEach(eintrag => {
      const neu: Gtiergroesse = new Gtiergroesse();
      neu.copy(eintrag);
      this.groessen.push(neu);
    });
    this.vun = [];
    other.vun.forEach(eintrag => {
      const neu: VorUndNachteil = new VorUndNachteil();
      neu.copy(eintrag);
      this.vun.push(neu);
    });
    this.werte = [];
    other.werte.forEach(eintrag => {
      const neu: NameWert = new NameWert();
      neu.copy(eintrag);
      this.werte.push(neu);
    });
    this.angriffenk = [];
    other.angriffenk.forEach(eintrag => {
      const neu: Gtierangriffenk = new Gtierangriffenk();
      neu.copy(eintrag);
      this.angriffenk.push(neu);
    });
    this.angriffefk = [];
    other.angriffefk.forEach(eintrag => {
      const neu: Gtierangriffefk = new Gtierangriffefk();
      neu.copy(eintrag);
      this.angriffefk.push(neu);
    });
    this.sf = [];
    other.sf.forEach(eintrag => {
      const neu: Gtiersf = new Gtiersf();
      neu.copy(eintrag);
      this.sf.push(neu);
    });
    this.sonderregeln = [];
    other.sonderregeln.forEach(eintrag => {
      const neu: NameWert = new NameWert();
      neu.copy(eintrag);
      this.sonderregeln.push(neu);
    });
    this.anmerkungen = [];
    other.anmerkungen.forEach(eintrag => {
      const neu: NameWert = new NameWert();
      neu.copy(eintrag);
      this.anmerkungen.push(neu);
    });
  }




}
