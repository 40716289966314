<div class="head">
  <h2>Charaktererstellung</h2>
  <label *ngIf="heldin.$step < $stepcount">Schritt: {{ heldin.$step+1 }} / {{ $stepcount }}</label>
  <div class="bad">

  </div>
  <div class="bad" *ngIf="heldin.$step === 10">
    <b>Anmerkung: </b> Bei der Heldenerschaffung dürfen nur 10 AP übrig bleiben.<br>
    Sollten mehr als 10 AP über sein, werden diese auf 10 reduziert!
  </div>
</div>

<div class="body">
  <div class="spalte">
    <div class="navigation">
      <img src="../../../assets/images/bar_oben.png"/>
      <div class="buttonleiste">
        <div class="button" *ngIf="heldin.$step>0" (click)="decrementStep()">
          zurück
        </div>
        <div class="button" *ngIf="heldin.$step===0"></div>
        <div class="button" *ngIf="heldin.$step < 10" (click)="incrementStep()">
          weiter
        </div>
        <div class="button" *ngIf="heldin.$step===10" (click)="finalize()">
          abschließen
        </div>
      </div>
      <img src="../../../assets/images/bar_unten.png"/>
    </div>

    <div *ngIf="heldin.$step===0">
      <app-erfahrung (ping)="updateStep0()" [heldin]="heldin"></app-erfahrung>
    </div>

    <div *ngIf="heldin.$step===1">
      <app-spezies (ping)="updateStep1()" [heldin]="heldin"></app-spezies>
    </div>

    <div *ngIf="heldin.$step===2">
      <app-koerperattribute [heldin]="heldin" (ping)="updateStep2($event)"></app-koerperattribute>
    </div>

    <div *ngIf="heldin.$step===3">
      <app-kultur [heldin]="heldin" (ping)="updateStep3()"></app-kultur>
    </div>

    <div *ngIf="heldin.$step===4">
      <app-eigenschaften [heldin]="heldin" [maxPunkte]="heldin.erfahrung.maxPktEigenschaft" [maxWert]="heldin.erfahrung.maxEigenschaft" (ping)="updateStep4()"></app-eigenschaften>
    </div>

    <div *ngIf="heldin.$step===5">
      <app-profession [heldin]="heldin" (ping)="updateStep5($event)"></app-profession>
    </div>

    <div *ngIf="heldin.$step===6">
      <app-vorundnachteil [heldin]="heldin" (ping)="updateStep6()"></app-vorundnachteil>
    </div>

    <div *ngIf="heldin.$step===7">
      <app-zauber [heldin]="heldin" [maxAnzahlZauber]="heldin.erfahrung.maxAnzZauber" [maxAnzahlFremdZauber]="heldin.erfahrung.maxFremdZauber" [maxFW]="heldin.erfahrung.maxFertigkeit" (ping)="updateStep7($event)"></app-zauber>
    </div>

    <div *ngIf="heldin.$step===8">
      <app-sonderfertigkeit [heldin]="heldin" (ping)="updateStep8()"></app-sonderfertigkeit>
    </div>

    <div *ngIf="heldin.$step===9">
      <app-steigerungen [heldin]="heldin" [$talentInfos]="$talentInfos" (ping)="updateStep9($event)"></app-steigerungen>
    </div>

    <div *ngIf="heldin.$step===10">
      <app-finalisierung [heldin]="heldin"></app-finalisierung>
    </div>
  </div>
</div>

<div class="box" *ngIf="heldin.$step < 9 && heldin.$step > 0">
  <div class="zeile">
    <h3>Abenteuerpunkte</h3>
  </div>
  <div class="zeile">
    {{ this.heldin.erfahrung.apKonto }}
  </div>
  <div class="zeile">
    <h3 class="unterHeadline">Geschlecht</h3>
  </div>
  <div class="zeile" *ngIf="heldin.weiblich">
    weiblich
  </div>
  <div class="zeile" *ngIf="!heldin.weiblich">
    männlich
  </div>
  <div *ngIf="heldin.$step > 4">
    <div class="zeile">
      <h3>Eigenschaften</h3>
    </div>
    <div class="zeile" *ngFor="let eigenschaft of heldin.eigenschaften">
      <div class="name">
        {{ eigenschaft.name }}:
      </div>
      <div class="wert">
        {{ eigenschaft.startwert + eigenschaft.modifikator }}
      </div>
      <div class="mod">
      </div>
    </div>
  </div>
</div>

<div class="fixbox" *ngIf="heldin.$step > 8">
  <div class="zeile">
    <h3>Abenteuerpunkte</h3>
  </div>
  <div class="zeile">
    {{ this.heldin.erfahrung.apKonto }}
  </div>
  <div>
    <div class="zeile">
      <h3>Eigenschaften</h3>
    </div>
    <div class="zeile" *ngFor="let eigenschaft of heldin.eigenschaften">
      <div class="name">
        {{ eigenschaft.name }}:
      </div>
      <div class="wert">
        {{ eigenschaft.startwert + eigenschaft.modifikator }}
      </div>
      <div class="mod">
      </div>
    </div>
  </div>
  <div class="zeile">
    <h3>Basiswerte</h3>
  </div>
  <div class="zeile">
    Lebenspunkte: {{ heldin.basiswerte.maxLeP }}
  </div>
  <div class="zeile">
    Astralpunkte: {{ heldin.basiswerte.maxAsP }}
  </div>
  <div class="zeile">
    Seelenkraft: {{ heldin.basiswerte.maxSk }}
  </div>
  <div class="zeile">
    Zähigkeit: {{ heldin.basiswerte.maxZk }}
  </div>
  <div class="zeile">
    Ausweichen: {{ heldin.basiswerte.maxAw }}
  </div>
  <div class="zeile">
    Initiative: {{ heldin.basiswerte.maxIni }}
  </div>
  <div class="zeile">
    Geschwindigkeit: {{ heldin.basiswerte.maxGs }}
  </div>
  <div class="zeile">
    Schicksalspunkte: {{ heldin.basiswerte.maxSchips }}
  </div>
</div>
