import {GsonderfertigkeitVoraussetzung} from './gsonderfertigkeit-voraussetzung';

export class Gsonderfertigkeit {
  klasse: string;
  art: string;
  unterArt: string;
  name: string;
  beschreibung: string;
  regel: string;
  stufen: number;
  spezifikationNoetig: boolean;
  voraussetzungen: GsonderfertigkeitVoraussetzung[];

  constructor() {
    this.klasse = 'GSonderfertigkeit';
    this.art = '';
    this.unterArt = '';
    this.name = '';
    this.beschreibung = '';
    this.regel = '';
    this.stufen = 1;
    this.spezifikationNoetig = false;
    this.voraussetzungen = [];
  }

  public copy(other: Gsonderfertigkeit): void {
    this.klasse = other.klasse;
    this.art = other.art;
    this.unterArt = other.unterArt;
    this.name = other.name;
    this.beschreibung = other.beschreibung;
    this.regel = other.regel;
    this.stufen = other.stufen;
    this.spezifikationNoetig = other.spezifikationNoetig;
    this.voraussetzungen = other.voraussetzungen;
  }
}
