import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.sass']
})
export class LogoutComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

  public logout(): void {
    sessionStorage.clear();
    this.resetStorage();
    this.document.defaultView.location.reload();
  }
  public resetStorage(): void {
    console.log('resetStorage');
    localStorage.clear();
    sessionStorage.clear();
  }
}
