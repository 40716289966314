<div class="body">
  <!-- alles -->
  <div style="display: flex; flex-flow: row; justify-content: space-around">
    <div class="erstelleItemButton"><!-- neues Item erstellen --></div>
    <div *ngIf="!allesAusgeklappt">
      <div class="zwischenKopf" (click)="klappeAllesAus()">
        <label class="arrow">▼</label> <label class="text">alles ausklappen</label> <label class="arrow">▼</label>
      </div>
    </div>
    <div *ngIf="allesAusgeklappt">
      <div class="zwischenKopf" (click)="klappeAllesEin()">
        <label class="arrow">▲</label> <label class="text">alles einklappen</label> <label class="arrow">▲</label>
      </div>
    </div>
    <div *ngIf="!localData.erstelleItemMenu" class="erstelleItemButton" (click)="initiereItemErstellung()">Item hinzufügen</div>
    <div *ngIf="localData.erstelleItemMenu" class="erstelleItemButton" style="color: darkred" (click)="brecheItemErstellungAb()">hinzufügen abbrechen</div>
  </div>

  <!-- Item hinzufügen -->
  <div *ngIf="localData.erstelleItemMenu">
    <h3 style="width: 100%; text-align: center">Item hinzufügen</h3>
    <div class="kopfzeile">
      <div class="artSelect">Art</div>
      <div class="artSelectBright">Unterart</div>
      <div class="itemname">Itemname</div>
      <div class="featherNeu"></div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
      <div class="neuesItemZustand" *ngIf="chosenItemArt==='Seile'">Länge</div>
    </div>
    <div class="zeile">
      <select class="artSelect" [(ngModel)]="chosenItemArt" (ngModelChange)="aendereItemArt()">
        <option [value]="art" *ngFor="let art of itemArten">{{art}}</option>
      </select>
      <select class="artSelectBright" [(ngModel)]="chosenItemSubArt" (ngModelChange)="aendereItemSubArt()">
        <option [value]="subart" *ngFor="let subart of itemSubArten">{{subart}}</option>
      </select>
      <select *ngIf="!itemUmbenennen" class="itemname" [(ngModel)]="chosenItem" (ngModelChange)="aendereItem()">
        <option [value]="item" *ngFor="let item of itemList">{{item}}</option>
      </select>
      <img *ngIf="!itemUmbenennen" class="featherNeu" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="itemUmbenennen = true"/>

      <input class="itemname" type="text" *ngIf="itemUmbenennen" [(ngModel)]="itemName">
      <img *ngIf="itemUmbenennen" class="featherNeu" alt="bearbeite Notiz" src="assets/images/reload.png" (click)="itemUmbenennen = false"/>
      <input type="number" class="anzahl" [(ngModel)]="itemAnzahl"/>
      <select class="trageort" [(ngModel)]="itemTrageort">
        <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
      </select>
      <input class="neuesItemZustand" *ngIf="chosenItemArt==='Seile'" type="number" [(ngModel)]="itemZustand">
      <div class="neuesItemButton" (click)="itemHinzufuegen()">hinzufügen</div>
    </div>
  </div>

  <!-- Währung (0) -->
  <div *ngIf="localData.kategorien[0] && itemWaehrung.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(0)">
      <label class="arrow">▲</label> <label class="text">Währung</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemWaehrung">
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteNotiz" class="note">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteNotiz" class="noteLight">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
          </div>
        </div>
      </div>
      <div class="name">
        <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
      </div>
      <div class="anzahl">
        <label>{{item.ausruestungen[0].anzahl}}</label>
        <div class="knoepfe">
          <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseMengenItem(item)"/>
          <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseMengenItem(item)"/>
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiereMengenItem()" *ngIf="transferPipeAusr != null && transferPipeAusr.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[0] && itemWaehrung.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(0)">
      <label class="arrow">▼</label> <label class="text">Währung</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Allgemeine Gegenstände (2) -->
  <div *ngIf="localData.kategorien[2] && itemsAllgemein.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(2)">
      <label class="arrow">▲</label> <label class="text">Allgemeine Gegenstände</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemsAllgemein">
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteNotiz" class="note">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteNotiz" class="noteLight">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
          </div>
        </div>
      </div>
      <div class="name">
        <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
      </div>
      <div class="anzahl">
        <label>{{item.anzahl}}</label>
        <div class="knoepfe">
          <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
          <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[2] && itemsAllgemein.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(2)">
      <label class="arrow">▼</label> <label class="text">Allgemeine Gegenstände</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Leuchtmittel (3) -->
  <div *ngIf="localData.kategorien[3] && itemsLeuchtmittel.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(3)">
      <label class="arrow">▲</label> <label class="text">Leuchtmittel</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemsLeuchtmittel">
      <div class="centerItem" style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div class="einschub">
        <div class="zeileKurz">
          <div *ngIf="!item.bearbeiteNotiz" class="note">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
              </div>
            </div>
          </div>
          <div *ngIf="item.bearbeiteNotiz" class="noteLight">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
              </div>
            </div>
          </div>
          <div class="name">
            <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
          </div>
          <div class="anzahl">
            <label>{{item.anzahl}}</label>
            <div class="knoepfe">
              <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
              <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
            </div>
          </div>
        </div>
        <div class="zeileKurzUnten" style="justify-content: space-around">
          <label *ngIf="item.gegenstand.brenndauer !== 1">maximale Brenndauer: &nbsp;{{item.gegenstand.brenndauer}} {{item.gegenstand.einheitBrenndauer}}n</label>
          <label *ngIf="item.gegenstand.brenndauer === 1">maximale Brenndauer: &nbsp;{{item.gegenstand.brenndauer}} {{item.gegenstand.einheitBrenndauer}}</label>
        </div>
        <div class="zeileKurzUnten" style="justify-content: flex-end">
          <div class="fixedWidth">
            übrig: &nbsp;{{item.gegenstand.brenndauer - item.ausruestungen[0].zustand | number:'1.0-1'}}
            <label *ngIf="item.gegenstand.brenndauer - item.ausruestungen[0].zustand === 1">Stunde</label>
            <label *ngIf="item.gegenstand.brenndauer - item.ausruestungen[0].zustand !== 1">Stunden</label>
          </div>
          <input class="slider" type="range" min="0" max="{{item.gegenstand.brenndauer}}" step="0.1" [(ngModel)]="item.ausruestungen[0].zustand" (change)="aendereBrenndauer()">
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div class="centerItem" style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div class="centerItem" *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div class="centerItem" *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[3] && itemsLeuchtmittel.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(3)">
      <label class="arrow">▼</label> <label class="text">Leuchtmittel</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Seile (5) -->
  <div *ngIf="localData.kategorien[5] && itemsSeil.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(5)">
      <label class="arrow">▲</label> <label class="text">Seile</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemsSeil">
      <div class="centerItem" style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div class="einschub">
        <div class="zeileKurz">
          <div *ngIf="!item.bearbeiteNotiz" class="note">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
              </div>
            </div>
          </div>
          <div *ngIf="item.bearbeiteNotiz" class="noteLight">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
              </div>
            </div>
          </div>
          <div class="name">
            <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht * item.ausruestungen[0].zustand | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht * item.ausruestungen[0].zustand | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.ausruestungen[0].zustand * item.anzahl | number:'1.0-4'}}</label>
          </div>
          <div class="anzahl">
            <label>{{item.anzahl}}</label>
            <div class="knoepfe">
              <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
              <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
            </div>
          </div>
        </div>
        <div *ngIf="item.erweiterteAnzeige">
          <div class="zeileKurzUnten" style="justify-content: flex-end">
            <div class="fixedWidth">
              teile bei: &nbsp;{{schneidLaenge | number:'1.0-1'}}
              <label>Schritt</label>
            </div>
            <input class="sliderSeil" type="range" min="0" max="{{item.ausruestungen[0].zustand}}" step="0.1" [(ngModel)]="schneidLaenge">
            <div class="teilen" (click)="schnitt(item)">Schnitt</div>
          </div>
        </div>
        <div *ngIf="!item.erweiterteAnzeige">
          <div class="zeileKurzUnten" style="justify-content: space-around">
            <div class="laenge">Länge: &nbsp;{{item.ausruestungen[0].zustand | number:'1.0-1'}}
              <label>Schritt</label>
            </div>
            <div class="schneiden" (click)="initiereSchnitt(item)">Zerschneiden</div>
          </div>
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div class="centerItem" style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div class="centerItem" *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div class="centerItem" *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[5] && itemsSeil.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(5)">
      <label class="arrow">▼</label> <label class="text">Seile</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Alkohol (4) -->
  <div *ngIf="localData.kategorien[4] && itemAlkohol.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(4)">
      <label class="arrow">▲</label> <label class="text">Alkohol</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemAlkohol">
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteNotiz" class="note">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteNotiz" class="noteLight">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
          </div>
        </div>
      </div>
      <div class="name">
        <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
      </div>
      <div class="anzahl">
        <label>{{item.anzahl}}</label>
        <div class="knoepfe">
          <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
          <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[4] && itemAlkohol.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(4)">
      <label class="arrow">▼</label> <label class="text">Alkohol</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Behältnisse (1) -->
  <div *ngIf="localData.kategorien[1] && itemBehaeltnis.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(1)">
      <label class="arrow">▲</label> <label class="text">Behältnisse</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Inhalt</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemBehaeltnis">
      <div class="imgBox">
        <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
             (click)="itemInfos(item.gegenstand.id)"></div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (click)="aktuellerBeaheltnisName = item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteNotiz" class="note">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteNotiz" class="noteLight">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
          </div>
        </div>
      </div>
      <div class="name">
        <label>eigenes: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label [class]="'last' + item.ueberlast">Inhalt: {{item.behGewicht | number:'1.0-4'}} / {{item.gegenstand.maxTragkraft}}</label>
      </div>
      <div class="anzahl">
        <label *ngIf="!item.behReinheit">gemischt</label>
        <label *ngIf="item.behReinheit && item.gegenstand.inhaltTyp === 'Währung'">Münzen</label>
        <label *ngIf="item.behReinheit && item.gegenstand.inhaltTyp !== 'Währung'">{{item.gegenstand.inhaltTyp}}</label>
        <label [class]="'last' + item.ueberlast" *ngIf="item.behReinheit">{{item.behAnz | number:'1.0-4'}} / {{item.gegenstand.maxInhalt}}</label>
      </div>
      <div class="trageort">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of behaeltnisTrageorte.get(item.name)">{{ort}}</option>
        </select>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
      </div>
    </div>
    <div class="zeile" style="width: 100%">
      <div class="imgBox">
        <div class="img" style="background-image: url('assets/images/itemsAusruestung/Körper{{charakter.geschlecht}}.png')"></div>
      </div>
      <div class="name">
        Körper
      </div>
      <div class="note">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label style="overflow-wrap: anywhere"></label>
          <div style="display: flex; flex-flow: column; justify-content: center">
          </div>
        </div>
      </div>
      <div class="name">
        <label>getragenes Gewicht:</label>
        <label [class]="'last' + ((charakter.getWert('KK').akt() * 2) < gewichtAmKoerper)">{{gewichtAmKoerper | number:'1.0-4'}} / {{charakter.getWert('KK').akt() * 2}}</label>
      </div>
      <div class="anzahl">
        <label>gemischt</label>
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[1] && itemBehaeltnis.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(1)">
      <label class="arrow">▼</label> <label class="text">Behältnisse</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Nahkampfwaffen (6) -->
  <div *ngIf="localData.kategorien[6] && itemsNahkampfwaffe.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(6)">
      <label class="arrow">▲</label> <label class="text">Nahkampfwaffen</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemsNahkampfwaffe">
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div class="einschub">
        <div class="zeileKurz">
          <div *ngIf="!item.bearbeiteNotiz" class="note">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
              </div>
            </div>
          </div>
          <div *ngIf="item.bearbeiteNotiz" class="noteLight">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
              </div>
            </div>
          </div>
          <div class="name">
            <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
          </div>
          <div class="anzahl">
            <label>{{item.anzahl}}</label>
            <div class="knoepfe">
              <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
              <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
            </div>
          </div>
        </div>
        <div class="zeileKurzUnten" style="justify-content: space-around">
          Zustand
        </div>
        <div class="zeileKurzUnten" style="justify-content: flex-end">
          <div class="fixedWidth">
            {{zustaende[item.ausruestungen[0].zustand]}}
          </div>
          <input class="sliderZustand" type="range" min="0" max="4" step="1" [(ngModel)]="item.ausruestungen[0].zustand" (change)="aendereZustand()">
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
        <b>LE / TP</b> {{item.gegenstand.leiteigenschaft}} / {{item.gegenstand.schadensschwelle}}
        <b>AT / PA-Mod:</b> {{item.gegenstand.atMod}} / {{item.gegenstand.paMod}}
        <b>Reichweite</b> {{item.gegenstand.reichweite}}
      </div>
      <div class="vorteil" *ngIf="itemAnzeige === item.gegenstand.id">
        {{item.gegenstand.vorteil}}
      </div>
      <div class="nachteil" *ngIf="itemAnzeige === item.gegenstand.id">
        {{item.gegenstand.nachteil}}
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[6] && itemsNahkampfwaffe.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(6)">
      <label class="arrow">▼</label> <label class="text">Nahkampfwaffen</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Fernkampfwaffen (7) -->
  <div *ngIf="localData.kategorien[7] && itemsFernkampfwaffe.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(7)">
      <label class="arrow">▲</label> <label class="text">Fernkampfwaffen</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemsFernkampfwaffe">
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div class="einschub">
        <div class="zeileKurz">
          <div *ngIf="!item.bearbeiteNotiz" class="note">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
              </div>
            </div>
          </div>
          <div *ngIf="item.bearbeiteNotiz" class="noteLight">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
              </div>
            </div>
          </div>
          <div class="name">
            <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
          </div>
          <div class="anzahl">
            <label>{{item.anzahl}}</label>
            <div class="knoepfe">
              <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
              <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
            </div>
          </div>
        </div>
        <div class="zeileKurzUnten" style="justify-content: space-around">
          Zustand
        </div>
        <div class="zeileKurzUnten" style="justify-content: flex-end">
          <div class="fixedWidth">
            {{zustaende[item.ausruestungen[0].zustand]}}
          </div>
          <input class="sliderZustand" type="range" min="0" max="4" step="1" [(ngModel)]="item.ausruestungen[0].zustand" (change)="aendereZustand()">
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
        <b>Basis-Schaden:</b> {{item.gegenstand.tp}}
        <b>Ladezeit:</b> {{item.gegenstand.ladezeit}}
        <b>Reichweiten:</b> {{item.gegenstand.reichweiten}}
      </div>
      <div class="vorteil" *ngIf="itemAnzeige === item.gegenstand.id">
        {{item.gegenstand.vorteil}}
      </div>
      <div class="nachteil" *ngIf="itemAnzeige === item.gegenstand.id">
        {{item.gegenstand.nachteil}}
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[7] && itemsFernkampfwaffe.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(7)">
      <label class="arrow">▼</label> <label class="text">Fernkampfwaffen</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Munition (8) -->
  <div *ngIf="localData.kategorien[8] && itemsMunition.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(8)">
      <label class="arrow">▲</label> <label class="text">Munition</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemsMunition">
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteNotiz" class="note">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteNotiz" class="noteLight">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
          </div>
        </div>
      </div>
      <div class="name">
        <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
        <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
      </div>
      <div class="anzahl">
        <label>{{item.anzahl}}</label>
        <div class="knoepfe">
          <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
          <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"></div>
        </div>
      </div>
      <div *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[8] && itemsMunition.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(8)">
      <label class="arrow">▼</label> <label class="text">Munition</label> <label class="arrow">▼</label>
    </div>
  </div>

  <!-- Rüstungen (9) -->
  <div *ngIf="localData.kategorien[9] && itemsRuestung.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(9)">
      <label class="arrow">▲</label> <label class="text">Rüstungen</label> <label class="arrow">▲</label>
    </div>
    <div class="kopfzeile" style="width: 100%">
      <div class="imgBox"></div>
      <div class="name">Name</div>
      <div class="note">Notiz</div>
      <div class="name">Gewicht in Stein</div>
      <div class="anzahl">Anzahl</div>
      <div class="trageort">Trageort</div>
    </div>
    <div class="zeile{{item.ausruestungen[0].ausgeruestet}}" style="width: 100%" *ngFor="let item of itemsRuestung">
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div *ngIf="item != null && item.ausruestungen.length > 0" class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.gegenstand.dateiname}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.bearbeiteName" class="name">
        <div style="display: flex; flex-flow: row; justify-content: space-between">
          <label class="nameShort" style="cursor: pointer">{{item.name}}</label>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/feather.png" (click)="item.bearbeiteName = true"/>
          </div>
        </div>
      </div>
      <div *ngIf="item.bearbeiteName" class="name">
        <div class="light" style="display: flex; flex-flow: row; justify-content: space-between">
          <textarea class="textAreaName" [(ngModel)]="item.name" (blur)="aendereName(item)"></textarea>
          <div style="display: flex; flex-flow: column; justify-content: center">
            <img class="feather" alt="bearbeite Name" src="assets/images/check.png" (click)="aendereName(item)"/>
          </div>
        </div>
      </div>
      <div class="einschub">
        <div class="zeileKurz">
          <div *ngIf="!item.bearbeiteNotiz" class="note">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <label style="overflow-wrap: anywhere">{{item.notiz}}</label>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeite Notiz" src="assets/images/feather.png" (click)="item.bearbeiteNotiz = true"/>
              </div>
            </div>
          </div>
          <div *ngIf="item.bearbeiteNotiz" class="noteLight">
            <div style="display: flex; flex-flow: row; justify-content: space-between">
              <textarea class="textArea" [(ngModel)]="item.notiz" (blur)="aendereNotiz(item)"></textarea>
              <div style="display: flex; flex-flow: column; justify-content: center">
                <img class="feather" alt="bearbeiteNotiz" src="assets/images/check.png" (click)="aendereNotiz(item)"/>
              </div>
            </div>
          </div>
          <div class="name">
            <label *ngIf="item.anzahl === 1">{{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">einzeln: {{item.gegenstand.gewicht | number:'1.0-4'}}</label>
            <label *ngIf="item.anzahl > 1">gesamt: {{item.gegenstand.gewicht * item.anzahl | number:'1.0-4'}}</label>
          </div>
          <div class="anzahl">
            <label>{{item.anzahl}}</label>
            <div class="knoepfe">
              <img alt="bild" src="assets/images/button_s_neg.png" (click)="decreaseItem(item)"/>
              <img alt="bild" src="assets/images/button_s_pos.png" (click)="increaseItem(item)"/>
            </div>
          </div>
        </div>
        <div class="zeileKurzUnten" style="justify-content: space-around">
          Zustand
        </div>
        <div class="zeileKurzUnten" style="justify-content: flex-end">
          <div class="fixedWidth">
            {{zustaende[item.ausruestungen[0].zustand]}}
          </div>
          <input class="sliderZustand" type="range" min="0" max="4" step="1" [(ngModel)]="item.ausruestungen[0].zustand" (change)="aendereZustand()">
        </div>
      </div>
      <div class="trageort" *ngIf="!item.compare(transferPipeEingang)">
        <select class="trageort" [(ngModel)]="item.trageort" (ngModelChange)="aendereTrageort(item)">
          <option [value]="ort" *ngFor="let ort of trageorte">{{ort}}</option>
        </select>
      </div>
      <div class="trageort" *ngIf="item.compare(transferPipeEingang)">
        <div class="kleinesBild">
          <label>Packe um in:</label>
        </div>
        <select class="trageort" [(ngModel)]="gewaehlterTransferOrt" (ngModelChange)="getTransferPipelineAusgang()">
          <option [value]="ort" *ngFor="let ort of transferOrte">{{ort}}</option>
        </select>
        <div class="kleinesBild" *ngIf="!transferBereit"></div>
        <div class="kleinesBild" *ngIf="transferBereit">
          <div class="button" (click)="transferiere()" *ngIf="transferPipeAusr != null && transferPipeEingang.anzahl > 0">
            ➳
          </div>
          <div class="button" *ngIf="transferPipeAusr == null || transferPipeAusr.anzahl <= 0"></div>
          <img alt="bild" src="assets/images/check.png" (click)="stoppeTransfer()"/>
        </div>
      </div>
      <div style="display: flex; flex-flow: column; justify-content: center">
        <div class="imgBox">
          <div class="img" style="background-image: url('assets/images/itemsAusruestung/{{item.trageortBild}}.png')"
               (click)="itemInfos(item.gegenstand.id)"></div>
        </div>
      </div>
      <div *ngIf="!item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild">
          <img *ngIf="item.anzahl > 1 && itemBehaeltnis.length > 0" alt="bild" src="assets/images/split.png" (click)="leiteTransferEin(item)"/>
        </div>
      </div>
      <div *ngIf="item.compare(transferPipeEingang)" style="display: flex; flex-flow: column; justify-content: space-around">
        <div class="kleinesBild"><div class="trash" (click)="trash(item)">🗑️</div></div>
        <div class="kleinesBild"></div>
      </div>
      <div class="regel" *ngIf="itemAnzeige === item.gegenstand.id">
        <div *ngIf="item.gegenstand.beschreibung != null">
          <b>Beschreibung:</b> {{item.gegenstand.beschreibung}}
        </div>
        <div *ngIf="item.gegenstand.regel != null">
          <b>Regel:</b> {{item.gegenstand.regel}}
        </div>
        <div class="horizontalCenter">
          <div class="rsTable">
            <div class="rsHeader">RS</div>
            <div class="rsBlock">
              <label class="rsTop">Kopf</label>
              <label class="rsBot">{{item.gegenstand.rsKopf}}</label>
            </div>
            <div class="rsBlock">
              <label class="rsTop">Torso</label>
              <label class="rsBot">{{item.gegenstand.rsTorso}}</label>
            </div>
            <div class="rsBlock">
              <label class="rsTop">l. Arm</label>
              <label class="rsBot">{{item.gegenstand.rslArm}}</label>
            </div>
            <div class="rsBlock">
              <label class="rsTop">r. Arm</label>
              <label class="rsBot">{{item.gegenstand.rsrArm}}</label>
            </div>
            <div class="rsBlock">
              <label class="rsTop">l. Bein</label>
              <label class="rsBot">{{item.gegenstand.rslBein}}</label>
            </div>
            <div class="rsBlock">
              <label class="rsTop">r. Bein</label>
              <label class="rsBot">{{item.gegenstand.rsrBein}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="vorteil" *ngIf="itemAnzeige === item.gegenstand.id">
        {{item.gegenstand.vorteil}}
      </div>
      <div class="nachteil" *ngIf="itemAnzeige === item.gegenstand.id">
        {{item.gegenstand.nachteil}}
      </div>
    </div>
  </div>
  <div *ngIf="!localData.kategorien[9] && itemsRuestung.length > 0">
    <div class="zwischenKopf" (click)="toggleMenu(9)">
      <label class="arrow">▼</label> <label class="text">Rüstungen</label> <label class="arrow">▼</label>
    </div>
  </div>
</div>


<div class="popup" *ngIf="popupMessage !== ''">
  <div style="display: flex; flex-flow: row; justify-content: center">
    <div class="popupBox">
      <div class="message">{{popupMessage}}</div>
      <div class="auswahl" *ngIf="popupType === 0">
        <div (click)="loeschenAbbrechen()" class="popupButton" style="color: darkred; border-color: darkred;">Nein</div>
        <div (click)="loescheGegenstand()" class="popupButton" style="color: darkgreen; border-color: darkgreen;">Ja</div>
      </div>
    </div>
  </div>
</div>
