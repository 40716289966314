import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { Talentwert } from '../../../_interfaces/talentwert';
import {Talent} from '../../../_interfaces/talent';
import {EventTooltip} from '../../../_interfaces/event-tooltip';
import {Step7} from '../../../_interfaces/charaktererstellung/step7';
import {StringEvent} from '../../../_interfaces/events/string-event';
import {Charakter} from '../../../_interfaces/charakter';
import {Heldin} from '../../../_classes/charakter/heldin';

@Component({
  selector: 'app-talent-item',
  templateUrl: './talent-item.component.html',
  styleUrls: ['./talent-item.component.sass']
})
export class TalentItemComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() talentItem$!: Talentwert;
  @Input() charakter: Heldin;

  public $attribute: boolean;

  public $talentModName: string;
  public $talentModWert: number;

  public talenteOhneBewegungOderSprache = ['Selbstbeherrschung', 'Sinnesschärfe', 'Gassenwissen', 'Menschenkenntnis', 'Willenskraft',
    'Orientierung'];

  constructor() {
    this.$attribute = false;
    this.resetTalentMod();
  }

  ngOnInit(): void {
  }

  public resetTalentMod(): void {
    this.$talentModName = '';
    this.$talentModWert = 0;
  }

  public increaseTalentMod(talent: Talentwert): void {
    if (this.$talentModName !== talent.name) {
      this.resetTalentMod();
    }
    this.$talentModName = talent.name;
    this.$talentModWert++;
    const mod: number = -this.$talentModWert;
    this.charakter.setBeeinflWert(talent.name, 'manuell', String(mod));
  }

  public decreaseTalentMod(talent: Talentwert): void {
    if (this.$talentModName !== talent.name) {
      this.resetTalentMod();
    }
    this.$talentModName = talent.name;
    this.$talentModWert--;
    const mod: number = -this.$talentModWert;
    this.charakter.setBeeinflWert(talent.name, 'manuell', String(mod));
  }

  public changeAttributeView(): void {
    this.$attribute = !this.$attribute;
  }

  public alertParent(): void {
    const eventObject: StringEvent = {
      wert: this.talentItem$.name
    };
    this.ping.emit(eventObject);
  }

  getAttributeWert(attr: string, talent: Talentwert): number {
    this.charakter.calculateGesamtModifikationFuer(talent.name);
    const fwMod = this.charakter.getWert(talent.name).abzug;
    console.log(talent.name + ': ' + fwMod);
    this.charakter.calculateGesamtModifikationFuer(attr);
    const wert: number = this.charakter.getWert(attr).akt() + fwMod;
    return wert;
  }
}
