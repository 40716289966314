<h3>Setze körperliche Attribute</h3>
<div class="row40">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">Augenfarbe (W20): </div>
  <input type="number" class="w8" [(ngModel)]="heldin.augenfarbeNumber" (ngModelChange)="getAugenfarbe()">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">{{ heldin.augenfarbe }}</div>
</div>
<div class="row40">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">Haarfarbe (W20): </div>
  <input type="number" class="w8" [(ngModel)]="heldin.haarfarbeNumber" (ngModelChange)="getHaarfarbe()">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">{{ heldin.haarfarbe }}</div>
</div>
<div class="row40">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">Körpergröße ({{ $wuerfelGroesse }}): </div>
  <input type="number" class="w8" [(ngModel)]="heldin.groesseNumber" (ngModelChange)="getKoerpergroesse()">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">{{ $groesseStr }}</div>
</div>
<div class="row40">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">Gewicht ({{ $wuerfelGewicht }}): </div>
  <input type="number" class="w8" [(ngModel)]="heldin.gewichtNumber" (ngModelChange)="getGewicht()">
  <div style="display: flex; flex-flow: column; justify-content: center" class="w16">{{ $gewichtStr }}</div>
</div>
<div>
  Geschlechtsmerkmale automatisch bestimmmen
  <input type="checkbox" checked="checked" (click)="switchGeneration()">
</div>
<div *ngIf="!$generate">
  <div class="row40">
    <div style="display: flex; flex-flow: column; justify-content: center" class="w16">Brüste (3W6): </div>
    <input type="number" class="w8" [(ngModel)]="heldin.bruesteNumber" (ngModelChange)="getBrustgroesse()">
    <div style="display: flex; flex-flow: column; justify-content: center" class="w16">{{ heldin.brueste }}</div>
  </div>
  <div class="row40">
    <div style="display: flex; flex-flow: column; justify-content: center" class="w16">Schambehaarung (3W6): </div>
    <input type="number" class="w8" [(ngModel)]="heldin.schamhaareNumber" (ngModelChange)="getSchamhaare()">
    <div style="display: flex; flex-flow: column; justify-content: center" class="w16">{{ heldin.schamhaare }}</div>
  </div>
  <div class="row40">
    <div style="display: flex; flex-flow: column; justify-content: center" class="w16">Geschlechtsorgan (3W6): </div>
    <input type="number" class="w8" [(ngModel)]="heldin.genitalNumber" (ngModelChange)="getGeschlechtsorgan()">
    <div style="display: flex; flex-flow: column; justify-content: center" class="w16">{{ heldin.genital }}</div>
  </div>
</div>
