import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Charakter} from '../../_interfaces/charakter';
import {Eigenschaft} from '../../_interfaces/eigenschaft';
import {Erschaffungsattribut} from '../../_interfaces/erschaffungsattribut';
import {CharakterVuN} from '../../_interfaces/charakter-vu-n';
import {Sonderfertigkeit} from '../../_interfaces/sonderfertigkeit';
import {DataService} from '../../_services/data.service';
import {ObjectFactoryService} from '../../_services/object-factory.service';
import {Glossar} from '../../_classes/glossar/glossar';
import {Heldin} from '../../_classes/charakter/heldin';
import {BeeinflussteWerte} from '../../_classes/charakter/beeinflusste-werte';
import {Werte} from '../../_classes/charakter/werte';
import {VorUndNachteil} from '../../_classes/charakter/vorUndNachteil';
import {Gvorundnachteil} from '../../_classes/glossar/gvorundnachteil';
import {Gsonderfertigkeit} from '../../_classes/glossar/gsonderfertigkeit';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {StringEvent} from '../../_interfaces/events/string-event';
import {Gzustand} from '../../_classes/glossar/gzustand';

@Component({
  selector: 'app-allgemein',
  templateUrl: './allgemein.component.html',
  styleUrls: ['./allgemein.component.sass']
})
export class AllgemeinComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() charakter: Heldin;
  @Input() glossar: Glossar;
  @Input() katalog: any;
  @Input() alter: string;

  public vorteile: VorUndNachteil[] = [];
  public nachteile: VorUndNachteil[] = [];

  public vunInfo: Gvorundnachteil;
  public sfInfo: Gsonderfertigkeit;

  public sf_info: any;
  public apToAdd: number;

  editPortrait = false;

  zustandInfoName = '';
  zustandInfo: Gzustand;

  steckbrief = false;

  constructor(private factory: ObjectFactoryService,
              private sanitizer: DomSanitizer) {
    this.apToAdd = 0;
    this.resetVuNInfo();
    this.resetSfInfo();
  }

  ngOnInit(): void {
    console.log('allgemein ngOnInit');
    console.log(this.charakter);
    console.log(this.glossar);
    this.charakter.vorUndNachteile.forEach(vun => {
      let vorteil = false;
      this.glossar.vorUndNachteile.forEach(gvun => {
        if (gvun.name === vun.name) {
          if (gvun.vorteil) {
            vorteil = true;
          }
        }
      });
      if (vorteil) {
        this.vorteile.push(vun);
      } else {
        this.nachteile.push(vun);
      }
    });
  }

  setPortrait(event: StringEvent): void {
    this.charakter.portrait = event.wert;
    this.editPortrait = false;
  }

  getSanitizedURL(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.charakter.portrait);
  }

  public addAP(): void {
    this.charakter.getWert('AP').abzug += -this.apToAdd;
    this.ping.emit();
  }

  public decreaseEig(eig: Erschaffungsattribut): void {
    eig.modifikator --;
    this.ping.emit();
  }

  public increaseEig(eig: Erschaffungsattribut): void {
    eig.modifikator ++;
    this.ping.emit();
  }

  public resetVuNInfo(): void {
    this.vunInfo = new Gvorundnachteil();
  }

  public setVuNInfo(name: string): void {
    this.resetSfInfo();
    if (this.vunInfo.name === name) {
      this.resetVuNInfo();
    } else {
      this.glossar.vorUndNachteile.forEach(vun => {
        if (vun.name === name) {
          this.vunInfo = vun;
        }
      });
    }
  }

  public resetSfInfo(): void {
    this.sfInfo = new Gsonderfertigkeit();
    this.sf_info = undefined;
  }

  public setSfInfo(name: string): void {
    this.resetVuNInfo();
    // if (this.sfInfo.name === name) {
    if (this.sf_info != null && this.sf_info.name === name) {
      this.resetSfInfo();
    } else {
      this.katalog.all_sonderfertigkeiten.forEach(sf => {
        if (sf.name === name) {
          this.sf_info = sf;
          /*
          this.klasse = 'GSonderfertigkeit';
          this.art = '';
          this.unterArt = '';
          this.name = '';
          this.beschreibung = '';
          this.regel = '';
          this.stufen = 1;
          this.spezifikationNoetig = false;
          this.voraussetzungen = [];
          */
        }
      });
      /*
      this.glossar.sonderfertigkeiten.forEach(sf => {
        if (sf.name === name) {
          this.sfInfo = sf;
        }
      });
      */
    }
  }

  public getStufenString(stufe: number): string {
    const stufen = ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII'];
    return stufen[stufe];
  }

  public increaseBeeinflussterWert(name: string): void {
    console.log('increaseBeeinflussterWert ' + name);
    const beinfWert = Number(this.charakter.getBeeinflWert(name, 'manuell').getWert());
    this.charakter.setBeeinflWert(name, 'manuell', String(beinfWert + 1));
    this.ping.emit();
  }

  public decreaseBeeinflussterWert(name: string): void {
    console.log('decreaseBeeinflussterWert ' + name);
    const beinfWert = Number(this.charakter.getBeeinflWert(name, 'manuell').getWert());
    this.charakter.setBeeinflWert(name, 'manuell', String(beinfWert - 1));
    this.ping.emit();
  }

  public decreaseSchmerz(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Schmerz', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) - 1));
    // this.charakter.basiswerte.modifikatoren.schmerzManuell --;
    this.ping.emit();
  }

  public increaseSchmerz(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Schmerz', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) + 1));
    // this.charakter.basiswerte.modifikatoren.schmerzManuell ++;
    this.ping.emit();
  }

  public decreaseVerwirrung(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Verwirrung', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) - 1));
    // this.charakter.basiswerte.modifikatoren.verwirrungManuell --;
    this.ping.emit();
  }

  public increaseVerwirrung(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Verwirrung', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) + 1));
    // this.charakter.basiswerte.modifikatoren.verwirrungManuell ++;
    this.ping.emit();
  }

  public decreaseBelastung(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Belastung', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) - 1));
    // this.charakter.basiswerte.modifikatoren.belastungManuell --;
    this.ping.emit();
  }

  public increaseBelastung(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Belastung', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) + 1));
    // this.charakter.basiswerte.modifikatoren.belastungManuell ++;
    this.ping.emit();
  }

  public decreaseBetaeubung(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Betäubung', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) - 1));
    // this.charakter.basiswerte.modifikatoren.betaeubungManuell --;
    this.ping.emit();
  }

  public increaseBetaeubung(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Betäubung', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) + 1));
    // this.charakter.basiswerte.modifikatoren.betaeubungManuell ++;
    this.ping.emit();
  }

  public decreaseFurcht(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Furcht', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) - 1));
    // this.charakter.basiswerte.modifikatoren.furchtManuell --;
    this.ping.emit();
  }

  public increaseFurcht(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Furcht', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) + 1));
    // this.charakter.basiswerte.modifikatoren.furchtManuell ++;
    this.ping.emit();
  }

  public decreaseParalyse(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Paralyse', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) - 1));
    // this.charakter.basiswerte.modifikatoren.paralyseManuell --;
    this.ping.emit();
  }

  public increaseParalyse(): void {
    const beinfWert: BeeinflussteWerte = this.charakter.getBeeinflWert('Paralyse', 'manuell');
    beinfWert.setWert(String(Number(beinfWert.getWert()) + 1));
    // this.charakter.basiswerte.modifikatoren.paralyseManuell ++;
    this.ping.emit();
  }

  getZustandInfos(name: string): void {
    if (this.zustandInfoName === name) {
      this.zustandInfoName = '';
      this.zustandInfo = undefined;
    } else {
      this.zustandInfoName = name;
      this.zustandInfo = this.glossar.getZustand(name);
    }
  }

}
