import {Gsonderfertigkeit} from './gsonderfertigkeit';
import {Gtraditionsartefakt} from './gtraditionsartefakt';

export class Gtraditionsartefaktsonderfertigkeit extends Gsonderfertigkeit{
  traditionsartefakte: Gtraditionsartefakt[];

  public copy(other: Gtraditionsartefaktsonderfertigkeit): void {
    this.klasse = other.klasse;
    this.art = other.art;
    this.unterArt = other.unterArt;
    this.name = other.name;
    this.beschreibung = other.beschreibung;
    this.regel = other.regel;
    this.stufen = other.stufen;
    this.spezifikationNoetig = other.spezifikationNoetig;
    this.voraussetzungen = other.voraussetzungen;
    this.traditionsartefakte = other.traditionsartefakte;
  }
}
