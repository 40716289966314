import {Gzauberqswirkung} from './gzauberqswirkung';
import {Gzaubererweiterung} from './gzaubererweiterung';
import {Gsozialstatus} from './gsozialstatus';

export class Gzauber {
  name: string;
  art: string;
  probe: string[];
  beschreibung: string;
  wirkung: string;
  zauberDauer: string;
  aspKosten: string;
  reichweite: string;
  wirkungsDauer: string;
  zielKategorie: string;
  merkmal: string;
  wirkungQS: Gzauberqswirkung[]; // "<QS>: Wirkung"
  verbreitung: string[];
  steigerungskosten: string;
  // "<Name> (FW: <FW>, <AP_Kosten> AP): Wirkung"
  erweiterungen: Gzaubererweiterung[];

  public copy(other: Gzauber): void {
    this.name = other.name;
    this.art = other.art;
    this.probe = other.probe;
    this.beschreibung = other.beschreibung;
    this.wirkung = other.wirkung;
    this.zauberDauer = other.zauberDauer;
    this.aspKosten = other.aspKosten;
    this.reichweite = other.reichweite;
    this.wirkungsDauer = other.wirkungsDauer;
    this.zielKategorie = other.zielKategorie;
    this.merkmal = other.merkmal;
    this.wirkungQS = [];
    other.wirkungQS.forEach(data => {
      const neu: Gzauberqswirkung = new Gzauberqswirkung();
      neu.copy(data);
      this.wirkungQS.push(neu);
    });
    this.verbreitung = other.verbreitung;
    this.steigerungskosten = other.steigerungskosten;
    this.erweiterungen = [];
    other.erweiterungen.forEach(data => {
      const neu: Gzaubererweiterung = new Gzaubererweiterung();
      neu.copy(data);
      this.erweiterungen.push(neu);
    });
  }
}
