import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {Heldin} from '../../../_classes/charakter/heldin';
import {Limits} from '../../../_classes/charakter/limits';
import {Spezies} from '../../../_interfaces/spezies';
import {DataService} from '../../../_services/data.service';
import {Werte} from '../../../_classes/charakter/werte';
import {NameWert} from '../../../_classes/name-wert';
import {BeeinflussteWerte} from '../../../_classes/charakter/beeinflusste-werte';
import {min} from 'rxjs/operators';
import {PersistenceService} from '../../../_services/persistence.service';

@Component({
  selector: 'app-b-spezies',
  templateUrl: './b-spezies.component.html',
  styleUrls: ['./b-spezies.component.sass']
})
export class BSpeziesComponent implements OnInit, OnChanges  {
  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  @Input() heldin: Heldin;
  @Input() limits: Limits;

  public speziesMenu: Spezies[];
  public speziesIndex = 0;
  public eigForce: NameWert[] = [];
  public eigMods: NameWert[] = [];
  public eigModIndex: number;

  public alteSpezies: string;

  wurfAuge: number;
  wurfHaare: number;
  wurfGroesse: number;

  wuerfelGroesse: string;
  wuerfelGewicht: string;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    console.log(this.heldin);
    this.speziesIndex = 0;
    this.eigModIndex = 0;
    this.alteSpezies = this.heldin.spezies;
    this.loadData();
  }

  public loadData(): void {
    this.dataService.getAlleSpezies().subscribe((data: Spezies[]) => {
      data.sort((a, b) => (a.unterArt < b.unterArt ? -1 : 1));
      data.sort((a, b) => (a.art < b.art ? -1 : 1));
      // console.log('step 1/getAlleSpezies()/data:');
      // console.log(data);
      this.speziesMenu = data;
      this.waehleSpezies();
      this.alteSpezies = '';
    });
  }

  speziesChange(): void {
    this.eigModIndex = 0;
    this.heldin.augenfarbe = undefined;
    this.heldin.haarfarbe = undefined;
    this.heldin.groesse = undefined;
    this.heldin.gewicht = undefined;
    this.heldin.brueste = undefined;
    this.heldin.genital = undefined;
    this.heldin.schamhaare = undefined;
    this.waehleSpezies();
  }

  waehleSpezies(): void {
    let neueSpezies = false;
    this.speziesMenu.forEach(spezies => {
      console.log(spezies.unterArt + ' === ' + this.alteSpezies);
      if (spezies.unterArt === this.alteSpezies) {
        this.speziesIndex = this.speziesMenu.indexOf(spezies);
        this.restoreEigModIndex();
        neueSpezies = true;
      }
    });
    this.setSpezies();
    if (!neueSpezies) {
      this.attributMods();
    }
  }

  restoreEigModIndex(): void {
    this.erstelleEigenschaftsModifikatoren();
    console.log('restoreEigModIndex');
    console.log(this.eigMods);
    console.log(this.heldin.beeinflussteWerte);
    if (this.eigMods.length > 0) {
      this.eigMods.forEach(em => {
        const bw: BeeinflussteWerte = this.heldin.getBeeinflWertIfVorhanden(em.name, 'Charaktererstellung_Spezies_' + em.name);
        console.log(bw);
        if (bw != null && bw.modWert === em.wert) {
          console.log(bw.modWert + ' === ' + em.wert);
          this.eigModIndex = this.eigMods.indexOf(em);
          console.log(this.eigMods);
          console.log(em);
          console.log(this.eigMods.indexOf(em));
          console.log('this.eigModIndex: ' + this.eigModIndex);
        }
      });
    }
  }

  erstelleEigenschaftsModifikatoren(): void {
    this.eigForce = [];
    this.eigMods = [];
    this.speziesMenu[this.speziesIndex].eigenschaftsAenderungen.forEach(em => {
      if (em.includes('||')) {
        const splitted: string[] = em.split(' || ');
        splitted.forEach(wahl => {
          const raw: string[] = wahl.split(': ');
          const nw: NameWert = new NameWert();
          nw.name = raw[0];
          nw.wert = raw[1];
          this.eigMods.push(nw);
        });
      } else {
        const raw: string[] = em.split(': ');
        const nw: NameWert = new NameWert();
        nw.name = raw[0];
        nw.wert = raw[1];
        this.eigForce.push(nw);
        this.heldin.setBeeinflWert(raw[0], 'Charaktererstellung_Spezies_' + raw[0], raw[1]);
      }
    });
    this.heldin.setBeeinflWert(this.eigMods[this.eigModIndex].name, 'Charaktererstellung_Spezies_'
      + this.eigMods[this.eigModIndex].name, this.eigMods[this.eigModIndex].wert);
    this.update.emit();
  }

  attributMods(): void {
    const eigenschaften: string[] = ['MU', 'KL', 'IN', 'CH', 'FF', 'GE', 'KO', 'KK'];
    eigenschaften.forEach(eig => {
      this.heldin.removeModifikatorPrefix(eig, 'Charaktererstellung_Spezies_' + eig);
    });
    this.erstelleEigenschaftsModifikatoren();
  }

  setSpezies(): void {
    this.heldin.spezies = this.speziesMenu[this.speziesIndex].unterArt;
    this.heldin.setBeeinflWert('AP', 'Charaktererstellung_Spezies', String(this.speziesMenu[this.speziesIndex].apWert));
    this.wuerfelGroesse = this.speziesMenu[this.speziesIndex].koerpergroesse[0].split(' + ')[1];
    this.getGewichtWurf();
  }

  public getGewichtWurf(): void {
    const array: string[] = this.speziesMenu[this.speziesIndex].gewicht[0].split(' ');
    let start = -1;
    let end = -1;
    let str = '';
    // console.log(array);
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes('W')) {
        if (start < 0) {
          start = i;
          end = i;
        } else {
          end = i;
        }
      }
    }
    if (start > 0 && start !== end) {
      start --;
    }
    // console.log('start: ' + start + ', end: ' + end);
    for (let i = start; i <= end; i++) {
      str += array[i];
    }
    this.wuerfelGewicht = str;
  }

  waehleAugenfarbe(): void {
    this.heldin.augenfarbe = this.wurfAttribut(this.speziesMenu[this.speziesIndex].augenfarbe, this.wurfAuge);
    this.update.emit();
  }

  waehleHaarfarbe(): void {
    this.heldin.haarfarbe = this.wurfAttribut(this.speziesMenu[this.speziesIndex].haarfarbe, this.wurfHaare);
    this.update.emit();
  }

  waehleGroesse(): void {
    const splitted: string[] = this.speziesMenu[this.speziesIndex].koerpergroesse[0].split(' + ');
    this.heldin.groesse = Number(splitted[0]) + this.wurfGroesse;
    this.update.emit();
  }

  public waehleGewicht(): void {
    if (this.heldin.groesse != null) {
      let size: number;
      this.speziesMenu[this.speziesIndex].koerpergroesse.forEach((strk) => {
        const array: string[] = strk.split(' + ');
        size = +array[0];
        size += this.heldin.groesse;
      });
      this.speziesMenu[this.speziesIndex].gewicht.forEach((str) => {
        // console.log(str);
        const array: string[] = str.split(' ');
        let weight: number = size;
        // console.log('weight: ' + weight);
        weight -= +array[2];
        // console.log('weight: ' + weight);
        if (this.speziesMenu[this.speziesIndex].art === 'Zwerge') {
          weight += this.heldin.gewicht;
        } else {
          if (array[3].includes('/')) {
            if (this.heldin.gewicht % 2 === 0) {
              // console.log('gerade');
              weight += this.heldin.gewicht;
            } else {
              // console.log('ungerade');
              weight -= this.heldin.gewicht;
            }
          } else {
            if (array[3] === '+') {
              weight += this.heldin.gewicht;
            } else {
              weight -= this.heldin.gewicht;
            }
          }
        }
        this.heldin.gewicht = weight;
      });
      this.update.emit();
    }
  }

  wurfAttribut(inputString: string[], wurf: number): string {
    // [blond: 1;5,schwarz: 6;9,dunkelgrau: 10;11,hellgrau: 12;13,salzweiß: 14,silberweiß: 15,feuerrot: 16;17,kupferrot: 18;20]
    let wanted: string;
    inputString.forEach(eintrag => {
      // blond: 1;5 || silberweiß: 15
      const splitted: string[] = eintrag.split(': ');
      // [blond,1;5] || [silberweiß,15]
      if (splitted[1].includes(';')) {
        const minMax: string[] = splitted[1].split(';');
        if (wurf >= Number(minMax[0]) && wurf <= Number(minMax[1])) {
          wanted = splitted[0];
        }
      } else {
        if (wurf === Number(splitted[1])) {
          wanted = splitted[0];
        }
      }
    });
    return wanted;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change: SimpleChange = changes.label;
    console.log('spezies -> ngOnChanges');
    this.ngOnInit();
  }
}
