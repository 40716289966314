<div *ngIf="show">
  <h3>Vor- und Nachteile</h3>
  <div class="infobox">
    <div>
      <h3 class="textHead">Vorteile</h3>
      <div class="infobox">
        <div *ngIf="$typischeVorteile.length > 0">
          <div>
            <div class="minihead">typisch</div>
            <div *ngFor="let vun of $typischeVorteile">{{vun}}</div>
          </div>
        </div>
        <div *ngIf="$untypischeVorteile.length > 0">
          <div>
            <div class="minihead">untypisch</div>
            <div *ngFor="let vun of $untypischeVorteile">{{vun}}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h3 class="textHead">Nachteile</h3>
      <div class="infobox">

        <div *ngIf="$typischeNachteile.length > 0">
          <div>
            <div class="minihead">typisch</div>
            <div *ngFor="let vun of $typischeNachteile">{{vun}}</div>
          </div>
        </div>
        <div *ngIf="$untypischeNachteile.length > 0">
          <div>
            <div class="minihead">untypisch</div>
            <div *ngFor="let vun of $untypischeNachteile">{{vun}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3>Gewählte Vor- und Nachteile</h3>
  <div class="table">
    <div class="row">
      <div class="name" style="font-weight: bold">Name</div>
      <div class="anmerkung" style="font-weight: bold">Anmerkung</div>
      <div class="kosten" style="font-weight: bold">Kosten</div>
      <div class="image"></div>
    </div>
    <div class="row" *ngFor="let vun of heldin.vorUndNachteile">
      <div class="name">
        {{vun.name}}
        <label *ngIf="vun.stufeString !== ''"> {{vun.stufeString}}</label>
        <label *ngIf="vun.kathegorie !== ''"> ({{vun.kathegorie}})</label>
        <label *ngIf="vun.notiz !== ''"> [{{vun.notiz}}]</label>
      </div>
      <div class="anmerkung" *ngIf="vun.type === 0">frei wählbar</div>
      <div class="anmerkung" *ngIf="vun.type === 1">dringend empfohlen</div>
      <div class="anmerkung" *ngIf="vun.type > 1">automatisch</div>
      <div class="kosten">{{vun.ap}} AP</div>
      <div class="image" *ngIf="vun.type > 1"></div>
      <img class="image" *ngIf="vun.type < 2" src="../../../assets/images/button_s_neg.png" (click)="removeVuN(vun)"/>
    </div>
    <div class="row">
      <div class="name" style="font-weight: bold"></div>
      <div class="anmerkung" style="font-weight: bold"></div>
      <div class="kosten" style="font-weight: bold">{{$kostenSumme}} AP</div>
      <div class="image"></div>
    </div>
  </div>

  <h3>Wähle Vor- und Nachteile</h3>
  <div *ngIf="$vorteilSwitch">
    <input type="button" class="button" value="zu Nachteilen wechseln" (click)="switchVuN()">
    <h3>Vorteile</h3>
    <select [(ngModel)]="$vunIndex" (ngModelChange)="getVuN()">
      <option [value]="$vorteile.indexOf(vun)" *ngFor="let vun of $vorteile">{{ vun.name }}</option>
    </select>
  </div>

  <div *ngIf="!$vorteilSwitch">
    <input type="button" class="button" value="zu Vorteilen wechseln" (click)="switchVuN()">
    <h3>Nachteile</h3>
    <select [(ngModel)]="$vunIndex" (ngModelChange)="getVuN()">
      <option [value]="$nachteile.indexOf(vun)" *ngFor="let vun of $nachteile">{{ vun.name }}</option>
    </select>
  </div>

  <div *ngIf="$chosenVuN.userinput">
    Beschreibung: <input type="text" [(ngModel)]="$charVuN.notiz">
  </div>

  <div *ngIf="$chosenVuN.mehrfachKathegorie === 'Talent'">
    <select [(ngModel)]="$charVuN.kathegorie" (ngModelChange)="checkVuNVorhandenOderGegenteil()">
      <option [value]="talent.name" *ngFor="let talent of $alleTalente">{{ talent.name }} {{ talent.stg }}</option>
    </select>
  </div>
  <div *ngIf="$chosenVuN.mehrfachKathegorie === 'Kampftechnik'">
    <select [(ngModel)]="$charVuN.kathegorie" (ngModelChange)="checkVuNVorhandenOderGegenteil()">
      <option [value]="kampftechnik.name" *ngFor="let kampftechnik of $kampftechniken">{{ kampftechnik.name }} {{ kampftechnik.steigerungsfaktor }}</option>
    </select>
  </div>
  <div *ngIf="$chosenVuN.mehrfachKathegorie === 'Fertigkeit'">
    <select [(ngModel)]="$oberKathegorie" (ngModelChange)="changeOberkathegorie()">
      <!--option value="Talent">Liturgie</option-->
      <option value="Talent">Talent</option>
      <option value="Zauber">Zauber</option>
    </select>

    <div *ngIf="$oberKathegorie === 'Talent'">
      <select [(ngModel)]="$charVuN.kathegorie" (ngModelChange)="checkVuNVorhandenOderGegenteil()">
        <option [value]="talent.name" *ngFor="let talent of $alleTalente">{{ talent.name }} {{ talent.stg }}</option>
      </select>
    </div>
    <div *ngIf="$oberKathegorie === 'Zauber'">
      <select [(ngModel)]="$charVuN.kathegorie" (ngModelChange)="checkVuNVorhandenOderGegenteil()">
        <option [value]="zauber.name" *ngFor="let zauber of $alleZauber">{{ zauber.name }} {{ zauber.steigerungskosten }}</option>
      </select>
    </div>

  </div>

  <div *ngIf="$chosenVuN.stufen > 1">
    Stufe:
    <select [(ngModel)]="$charVuN.stufe" (ngModelChange)="calculateCharVuNKosten()">
      <option [value]="$listStufen.indexOf(stufe)+1" *ngFor="let stufe of $listStufen">{{stufe}}</option>
    </select>
  </div>

  <div class="vorUndNachteilBox">
    <div class="zeile">
      <div><b>Name:</b> {{$chosenVuN.name}}
        <label *ngIf="$chosenVuN.userinput"> {{$charVuN.notiz}}</label>
        <label *ngIf="$chosenVuN.stufen > 1"> {{$listStufen[$charVuN.stufe - 1]}}</label>
      </div>
      <div>
        <b>AP-Kosten:</b> {{$charVuN.ap}}
      </div>
    </div>
    <div *ngIf="this.$chosenVuN.voraussetzungen !== ''">
      <h3>Voraussetzungen</h3>
      <div *ngFor="let voraus of this.$listVoraussetzungen; index as i;">
        {{voraus}}
        <label class="good" *ngIf="$listVoraussetungenCheck[i] === 1">erfüllt</label>
        <label class="mediocre" *ngIf="$listVoraussetungenCheck[i] === 0">manuell Prüfen</label>
        <label class="bad" *ngIf="$listVoraussetungenCheck[i] === -1">nicht erfüllt</label>
      </div>
    </div>
    <div *ngIf="this.$vunSchonVorhanden">
      <label class="bad" *ngIf="$chosenVuN.vorteil">Vorteil ist schon vorhanden!</label>
      <label class="bad" *ngIf="!$chosenVuN.vorteil">Nachteil ist schon vorhanden!</label>
    </div>
    <div *ngIf="this.$vunGegenteilVorhanden">
      <label class="bad" *ngIf="$chosenVuN.vorteil">Gegenteil des Vorteils ist vorhanden!</label>
      <label class="bad" *ngIf="!$chosenVuN.vorteil">Gegenteil des Nachteils ist vorhanden!</label>
    </div>
    <h3>Beschreibung</h3>
    {{$chosenVuN.beschreibung}}
    <h3>Regel</h3>
    {{$chosenVuN.regel}}
  </div>
  <input *ngIf="$chosenVuN.vorteil" type="button" class="button" value="Vorteil hinzufügen" (click)="addVuN()">
  <input *ngIf="!$chosenVuN.vorteil" type="button" class="button" value="Nachteil hinzufügen" (click)="addVuN()">
</div>
