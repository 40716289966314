import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Erschaffungsattribut} from '../../../_interfaces/erschaffungsattribut';
import {SteigerungstabelleService} from '../../../_services/steigerungstabelle.service';
import {DataService} from '../../../_services/data.service';
import {Step4} from '../../../_interfaces/charaktererstellung/step4';
import {Spezies} from '../../../_interfaces/spezies';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';

@Component({
  selector: 'app-eigenschaften',
  templateUrl: './eigenschaften.component.html',
  styleUrls: ['./eigenschaften.component.sass']
})
export class EigenschaftenComponent implements OnInit {
  @Input() heldin: Heldenerschaffung;
  @Input() maxWert: number;
  @Input() maxPunkte: number;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

  // public $eigenschaften: Erschaffungsattribut[];
  public $eigPkte: number;
  // public $kostenSumme: number;

  constructor(public dataService: DataService, public steigerung: SteigerungstabelleService) {
    // console.log('reset Eigenschaften');
  }

  ngOnInit(): void {
    this.initialisiereEigenschaften();

    this.heldin.eigenschaften.forEach((attr) => {
      this.heldin.spezies.eigenschaftsAenderungen.forEach((mod) => {
        if (!mod.includes('||')) {
          const paar: string[] = mod.split(': ');
          if (paar[0] === attr.name) {
            attr.modifikator = +paar[1];
          }
        }
      });
    });
    this.heldin.spezies.eigenschaftsAenderungen.forEach(mods => {
      if (mods.includes('||')) {
        const array = mods.split(' || ');
        if (this.heldin.speziesWurdeGeaendert) {
          this.changeMod(array[array.length - 1]);
          this.heldin.speziesWurdeGeaendert = false;
        }
      }
    });
    this.alertParent(0);
  }

  public initialisiereEigenschaften(): void {
    if (this.heldin.eigenschaften.length === 0) {
      this.heldin.eigenschaftenKosten = 0;
      this.heldin.eigenschaften = [];
      this.$eigPkte = 64;
      const $username = sessionStorage.getItem('user');
      const kf = ['MU', 'KL', 'IN', 'CH', 'FF', 'GE', 'KO', 'KK'];
      const lf = ['Mut', 'Klugheit', 'Intuition', 'Charisma', 'Fingerfertigkeit', 'Gewandheit', 'Konstitution', 'Körperkraft'];
      for (let i = 0; i < 8; i++) {
        const ersch: Erschaffungsattribut = {
          name: kf[i],
          wert: 8,
          startwert: 8,
          modifikator: 0,
          langform: lf[i]
        };
        this.heldin.eigenschaften.push(ersch);
      }
    } else {
      this.$eigPkte = 0;
      this.heldin.eigenschaften.forEach(eig => {
        this.$eigPkte += Number(eig.startwert);
      });
    }
  }

  public increaseEigenschaft(name: string): void {
    console.log('increaseEigenschaft');
    console.log(this.heldin.eigenschaften);
    console.log(this.$eigPkte);
    console.log(this.maxPunkte);
    if (this.$eigPkte < this.maxPunkte) {
      console.log('d1');
      this.heldin.eigenschaften.forEach((attr) => {
        console.log('d2');
        if (attr.name === name) {
          console.log('d3');
          if (attr.startwert < this.maxWert) {
            console.log('d4');
            const neuerWert = attr.startwert + 1;
            const kosten = this.steigerung.getKosten(neuerWert, 4);
            // console.log('Kosten: ' + kosten);
            attr.startwert = neuerWert;
            this.$eigPkte ++;
            /*if (this.$ap >= kosten) {
              this.$ap -= kosten;
              attr.startwert = neuerWert;
              this.$eigPkte ++;
            }*/
            this.alertParent(kosten);
          }
        }
      });
    }
  }

  public decreaseEigenschaft(name: string): void {
    console.log('decreaseEigenschaft');
    this.heldin.eigenschaften.forEach((attr) => {
      if (attr.name === name) {
        if (attr.startwert > 8) {
          const kosten = -1 * this.steigerung.getKosten(attr.startwert, 4);
          // this.$ap += kosten;
          attr.startwert --;
          this.$eigPkte --;
          this.alertParent(kosten);
        }
      }
    });
  }

  public changeMod(radio: string): void {
    const index = this.heldin.eigenschaftenWahlAusSpezies.indexOf(radio);
    for (let i = 0; i < this.heldin.eigenschaftenWahlAusSpeziesGewaehlt.length; i++) {
      if (i === index) {
        this.heldin.eigenschaftenWahlAusSpeziesGewaehlt[i] = true;
      } else {
        this.heldin.eigenschaftenWahlAusSpeziesGewaehlt[i] = false;
      }
    }
    console.log(this.heldin.eigenschaftenWahlAusSpeziesGewaehlt);
    const radioAttr: string = radio.split(': ')[0];
    const radioMod: string = radio.split(': ')[1];
    this.heldin.eigenschaften.forEach((attr) => {
      attr.modifikator = 0;
      this.heldin.spezies.eigenschaftsAenderungen.forEach((mod) => {
        if (!mod.includes('||')) {
          const paar: string[] = mod.split(': ');
          if (paar[0] === attr.name) {
            attr.modifikator = +paar[1];
          }
        }
      });
      if (radioAttr === attr.name) {
        attr.modifikator = +radioMod;
      }
    });
  }

  public alertParent(apKosten: number): void {
    console.log(this.heldin.eigenschaften);
    this.heldin.eigenschaften[0].wert = this.heldin.eigenschaften[0].startwert + this.heldin.eigenschaften[0].modifikator;
    this.heldin.eigenschaften[1].wert = this.heldin.eigenschaften[1].startwert + this.heldin.eigenschaften[1].modifikator;
    this.heldin.eigenschaften[2].wert = this.heldin.eigenschaften[2].startwert + this.heldin.eigenschaften[2].modifikator;
    this.heldin.eigenschaften[3].wert = this.heldin.eigenschaften[3].startwert + this.heldin.eigenschaften[3].modifikator;
    this.heldin.eigenschaften[4].wert = this.heldin.eigenschaften[4].startwert + this.heldin.eigenschaften[4].modifikator;
    this.heldin.eigenschaften[5].wert = this.heldin.eigenschaften[5].startwert + this.heldin.eigenschaften[5].modifikator;
    this.heldin.eigenschaften[6].wert = this.heldin.eigenschaften[6].startwert + this.heldin.eigenschaften[6].modifikator;
    this.heldin.eigenschaften[7].wert = this.heldin.eigenschaften[7].startwert + this.heldin.eigenschaften[7].modifikator;
    this.heldin.eigenschaftenKosten += apKosten;
    this.heldin.kostenProStep[4] = -1 * this.heldin.eigenschaftenKosten;
    this.ping.emit();
  }

}
