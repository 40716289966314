<div class="flexVertikal">
  <div class="flexH">
    <div class="flexV">

      <div class="flexH">
        <div class="kampfreihenfolge">
          <h3 class="flexHC">
            Kampfreihenfolge
            <div class="reload">
              <img src="../../../../assets/images/reload.png" (click)="forceWsUpdate()"/>
            </div>
          </h3>
          <div class="{{getStyleOfKaempferin(kaempfer)}}" *ngFor="let kaempfer of kaempfer">
            <label *ngIf="kaempfer.aktionenUebrig > 0">{{kaempfer.name}}</label>
            <label style="text-decoration: line-through" *ngIf="kaempfer.aktionenUebrig <= 0">{{kaempfer.name}}</label>
          </div>
        </div>
        <div class="kampfgruppe" *ngIf="charakter.menu.kampfblatt.waffenart === 'nahkampf'">
          <h3>Gegner in Nahkampfreichweite</h3>
          <div *ngIf="me.gruppe !== 0">
            <div class="gewaehlterGegner" *ngFor="let kaempfer of me.gegnerInNahkampfreichweite index as i">
              <div class="flexHC">
                <label *ngIf="gegnerWahl[i]==='gewaehlt'">&nbsp; ⚔️ &nbsp;</label>
                <label class="{{gegnerWahl[i]}}" *ngIf="kaempfer.team !== me.team" (click)="waehleGegner(i)">{{kaempfer.name}}</label>
                <label *ngIf="gegnerWahl[i]==='gewaehlt'">&nbsp; ⚔️ &nbsp;</label>
              </div>
            </div>
          </div>
        </div>
        <div class="kampfgruppe" *ngIf="charakter.menu.kampfblatt.waffenart === 'fernkampf'">
          <h3>Gegner in Fernkampfreichweite</h3>
          <div *ngIf="me.gruppe !== 0">
            <div class="gewaehlterGegner" *ngFor="let k of kaempfer index as i">
              <div class="flexHC" *ngIf="k.gruppe !== 0">
                <label *ngIf="gegnerWahl[i]==='gewaehlt'">&nbsp; 🎯️ &nbsp;</label>
                <label class="{{gegnerWahl[i]}}" *ngIf="k.team !== me.team" (click)="waehleGegner(i)">{{k.name}}</label>
                <label *ngIf="gegnerWahl[i]==='gewaehlt'">&nbsp; 🎯️ &nbsp;</label>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="flexH">
        <div class="borderbox">
          <button class="kampfbeitritt" (click)="betreteDenKampf()" *ngIf="!checkIfImKampf()">Kampf beitreten</button>
          <button class="kampfbeitritt" (click)="verlasseDenKampf()" *ngIf="checkIfImKampf()">Kampf verlassen</button>
        </div>
        <div class="borderbox">
          <div class="flexVC">
            <div class="iniWurfName">Initiative-Wurf (W6):</div>
          </div>
          <input class="iniwurf" type="number" [(ngModel)]="charakter.basiswerte.iniWurf" required="true" (ngModelChange)="updateIni()">
        </div>
        <div class="borderbox">
          <div class="flexVC">
            <div class="laengerfrHandl">Längerfristige Handlung:</div>
          </div>
          <input class="iniwurf" type="number" [(ngModel)]="getMe().laengerfristigeHandlung" required="true" (ngModelChange)="updateKaempfer()">
          <div class="flexVC">
            <div class="aktionen">Aktionen</div>
          </div>
        </div>
      </div>

    </div>
    <div class="angriffsablauf">
      <h3>Angriffsablauf</h3>
      <label class="{{stepsZumAngriff[0]}}">Gegener wählen</label>
      <label class="{{stepsZumAngriff[1]}}">Waffe wählen</label>
      <label>prüfe Modifikationen</label>
      <label>Wurf geschafft?</label>

    </div>
  </div>



  <div class="waffenbox">
    <div class="flexHC">
      <select class="waffenWahl" *ngIf="charakter.menu.kampfblatt !== undefined" [(ngModel)]="charakter.menu.kampfblatt.waffenart" (change)="changeWaffenart()">
        <option value="nahkampf">Nahkampf</option>
        <option value="fernkampf">Fernkampf</option>
      </select>
    </div>
    <div *ngIf="charakter.menu.kampfblatt.waffenart === 'nahkampf'">
      <h3 style="margin-bottom: 4px; margin-top: 2px; margin-left: 8px" *ngIf="!chosenWaffe.zweihand" (click)="waehleWaffe(chosenWaffe)">
        rechte Hand <label *ngIf="gewaehlteWaffe === chosenWaffe">&nbsp; ⚔️</label>
      </h3>
      <h3 style="margin-bottom: 4px; margin-top: 2px; margin-left: 8px" *ngIf="chosenWaffe.zweihand" (click)="waehleWaffe(chosenWaffe)">
        beide Hände <label *ngIf="gewaehlteWaffe === chosenWaffe">&nbsp; ⚔️</label>
      </h3>
      <div class="waffenzeile" id="waffenheadline">
        <div class="name">Name</div>
        <div class="at">AT</div>
        <div class="pa">PA</div>
        <div class="tp">TP</div>
        <div class="rw">Reichweite</div>
        <div class="le">LE</div>
        <div class="kt">Kampftechnik</div>
      </div>
      <div class="waffenzeile" *ngIf="chosenWaffe !== undefined">
        <select class="name" [(ngModel)]="charakter.menu.kampfblatt.chosenIndex" (ngModelChange)="getWaffe()">
          <option [value]="alleWaffenNahkampf.indexOf(waffe)" *ngFor="let waffe of alleWaffenNahkampf">{{ waffe.name }}</option>
        </select>
        <div class="at">
          {{chosenWaffe.at}}
        </div>
        <div class="pa">
          {{((chosenWaffe.pa - veErschw) / veDivisor) | number: '1.0-0'}}
        </div>
        <div class="tp">
          {{nkWaffeTP}}
        </div>
        <div class="rw">
          {{chosenWaffe.reichweite}}
        </div>
        <select class="le" [(ngModel)]="charakter.menu.kampfblatt.chosenLE" (ngModelChange)="setLE()">
          <option [value]="le" *ngFor="let le of waffenLE">{{ le }}</option>
        </select>
        <div class="kt">
          {{chosenWaffe.kampftechnik}}
        </div>
      </div>
      <div *ngIf="chosenWaffe.kampftechnik !== 'Stangenwaffen' && chosenWaffe.kampftechnik !== 'Zweihandhiebwaffen' && chosenWaffe.kampftechnik !== 'Zweihandschwerter'">
        <h3 style="margin-bottom: 4px; margin-top: 6px; margin-left: 8px" (click)="waehleWaffe(chosenWaffe2)">linke Hand <label *ngIf="gewaehlteWaffe === chosenWaffe2">&nbsp; ⚔️</label></h3>
        <div class="waffenzeile" id="waffenheadline2">
          <div class="name">Name</div>
          <div class="at">AT</div>
          <div class="pa">PA</div>
          <div class="tp">TP</div>
          <div class="rw">Reichweite</div>
          <div class="le">LE</div>
          <div class="kt">Kampftechnik</div>
        </div>
        <div class="waffenzeile" *ngIf="chosenWaffe2 !== undefined">
          <select class="name" [(ngModel)]="charakter.menu.kampfblatt.chosenIndex2" (ngModelChange)="getWaffe2()">
            <option [value]="alleWaffenNahkampf2.indexOf(waffe)" *ngFor="let waffe of alleWaffenNahkampf2">{{ waffe.name }}</option>
          </select>
          <div class="at">
            {{chosenWaffe2.at}}
          </div>
          <div class="pa">
            {{((chosenWaffe2.pa - veErschw) / veDivisor) | number: '1.0-0'}}
          </div>
          <div class="tp">
            {{nkWaffeTP2}}
          </div>
          <div class="rw">
            {{chosenWaffe2.reichweite}}
          </div>
          <select class="le" [(ngModel)]="charakter.menu.kampfblatt.chosenLE2" (ngModelChange)="setLE()">
            <option [value]="le" *ngFor="let le of waffenLE2">{{ le }}</option>
          </select>
          <div class="kt">
            {{chosenWaffe2.kampftechnik}}
          </div>
        </div>
      </div>
      <div class="fkwaffeUntererBereich">
        <div class="options">⚙️</div>
        <div class="zielgroesse1">Zielgröße:</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner != null">{{gewaehlterGegner.zielGroesse}}</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner == null">-</div>
        <div class="zielgroesse1">Zielart:</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner != null">{{gewaehlterGegner.zielName}}</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner == null">-</div>
        <!--select class="zielgroesse2" [(ngModel)]="charakter.menu.kampfblatt.zielgroesseIndex" (ngModelChange)="getFKundTP()">
          <option [value]="kampfdaten.zielgroessen.indexOf(zielgroesse)" *ngFor="let zielgroesse of kampfdaten.zielgroessen">{{ zielgroesse }}</option>
        </select-->
        <div class="gezielterSchuss" (click)="toogleZielschuss()">
          gezielter Schlag
        </div>
        <div class="zielschuss" *ngIf="zielschuss" (click)="toogleZielschuss()"> 🎯️ </div>
        <div class="zielschuss" *ngIf="!zielschuss" (click)="toogleZielschuss()"> ✓ </div>
        <div class="zone">Zone:</div>
        <select class="zonenwahl" [(ngModel)]="charakter.menu.kampfblatt.zielZoneIndex" *ngIf="gegnerAlsZiel != null" (ngModelChange)="getPAundAT(false)">
          <option [value]="gegnerAlsZiel.koerperteile.indexOf(zone)" *ngFor="let zone of gegnerAlsZiel.koerperteile">{{ zone }}</option>
        </select>
        <div class="zonenwahl" *ngIf="gegnerAlsZiel == null">-</div>
      </div>
    </div>
    <div *ngIf="charakter.menu.kampfblatt.waffenart === 'fernkampf'">
      <h3 style="margin-bottom: 4px; margin-top: 2px; margin-left: 8px">Fernkampfwaffe</h3>
      <div class="fkwaffenzeile" id="fkwaffenheadline">
        <div class="name">Name</div>
        <div class="fk">FK</div>
        <div class="tp">TP</div>
        <div class="reichweite">Reichweite</div>
        <div class="kt">Kampftechnik</div>
        <div class="bewDesZ">Bew. d. Ziels</div>
        <div class="bewDesS">Deine Bewegung</div>
      </div>
      <div class="fkwaffenzeile" *ngIf="alleWaffenFernkampf.length > 0">
        <select class="name" [(ngModel)]="charakter.menu.kampfblatt.chosenIndexFK" (ngModelChange)="getWaffeFK()">
          <option [value]="alleWaffenFernkampf.indexOf(waffe)" *ngFor="let waffe of alleWaffenFernkampf">{{ waffe.name }}</option>
        </select>
        <div class="fk">
          {{chosenWaffeFK.at}}
        </div>
        <div class="tp">
          {{fkWaffeTP}}
        </div>
        <select class="reichweite" [(ngModel)]="charakter.menu.kampfblatt.fkWaffeReichweiteIndex" (ngModelChange)="setReichweite()">
          <option [value]="fkWaffeReichweiten.indexOf(rw)" *ngFor="let rw of fkWaffeReichweiten">bis {{ rw }} Schritt</option>
        </select>
        <div class="kt">
          {{chosenWaffeFK.kampftechnik}}
        </div>
        <select class="bewDesZ" [(ngModel)]="charakter.menu.kampfblatt.bewDesZielsIndex" (ngModelChange)="getFKundTP()">
          <option [value]="kampfdaten.bewegungDesZiels.indexOf(bew)" *ngFor="let bew of kampfdaten.bewegungDesZiels">{{ bew }}</option>
        </select>
        <select class="bewDesS" [(ngModel)]="charakter.menu.kampfblatt.bewDesSchuetzenIndex" (ngModelChange)="getFKundTP()">
          <option [value]="kampfdaten.bewegungDesSchuetzen.indexOf(bew)" *ngFor="let bew of kampfdaten.bewegungDesSchuetzen">{{ bew }}</option>
        </select>
      </div>
      <div class="fkwaffeUntererBereich" *ngIf="alleWaffenFernkampf.length > 0">
        <div class="options">⚙️</div>
        <div class="zielgroesse1">Zielgröße:</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner != null">{{gewaehlterGegner.zielGroesse}}</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner == null">-</div>
        <div class="zielgroesse1">Zielart:</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner != null">{{gewaehlterGegner.zielName}}</div>
        <div class="zielgroesse2" *ngIf="gewaehlterGegner == null">-</div>
        <!--select class="zielgroesse2" [(ngModel)]="charakter.menu.kampfblatt.zielgroesseIndex" (ngModelChange)="getFKundTP()">
          <option [value]="kampfdaten.zielgroessen.indexOf(zielgroesse)" *ngFor="let zielgroesse of kampfdaten.zielgroessen">{{ zielgroesse }}</option>
        </select-->
        <div class="gezielterSchuss" (click)="toogleZielschuss()">
          gezielter Schuss
        </div>
        <div class="zielschuss" *ngIf="zielschuss" (click)="toogleZielschuss()"> 🎯️ </div>
        <div class="zielschuss" *ngIf="!zielschuss" (click)="toogleZielschuss()"> ✓ </div>
        <div class="zone">Zone:</div>
        <select class="zonenwahl" [(ngModel)]="charakter.menu.kampfblatt.zielZoneIndex" *ngIf="gegnerAlsZiel != null" (ngModelChange)="getFKundTP()">
          <option [value]="gegnerAlsZiel.koerperteile.indexOf(zone)" *ngFor="let zone of gegnerAlsZiel.koerperteile">{{ zone }}</option>
        </select>
        <div class="zonenwahl" *ngIf="gegnerAlsZiel == null">-</div>
      </div>
    </div>
  </div>

  <div class="flexH">
    <div class="borderboxBLR">

      <div class="textArea" *ngIf="kampfPhase === 'atTrefferzone'">
        Bestimme die Trefferzone deiner Attacke mit einem W20:
      </div>
      <div class="textArea" *ngIf="kampfPhase === 'atPatzerListe'">
        Bestimme die Auswirkungen deines Patzers mit 2W6:
      </div>

      <div class="buttonArea" *ngIf="kampfPhase === 'keine'">
        <button class="subtileButtonGrey" *ngIf="stepsZumAngriff.includes('todo')">meisterhafter Angriff</button>
        <button class="subtileButtonGrey" *ngIf="stepsZumAngriff.includes('todo')">erfolgreicher Angriff</button>
        <button class="subtileButtonGrey" *ngIf="stepsZumAngriff.includes('todo')">misslungener Angriff</button>
        <button class="subtileButtonGrey" *ngIf="stepsZumAngriff.includes('todo')">Patzer!</button>

        <button class="subtileButtonGold" *ngIf="!stepsZumAngriff.includes('todo')" (click)="changeKampfPhase('atKrit')">meisterhafter Angriff</button>
        <button class="subtileButton" *ngIf="!stepsZumAngriff.includes('todo')" (click)="changeKampfPhase('atTrefferzone')">erfolgreicher Angriff</button>
        <button class="subtileButton" *ngIf="!stepsZumAngriff.includes('todo')" (click)="sendEndOfAttack()">misslungener Angriff</button>
        <button class="subtileButtonRed" *ngIf="!stepsZumAngriff.includes('todo')" (click)="changeKampfPhase('atPatzer')">Patzer!</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'atKrit'">
        <button class="subtileButtonRed" (click)="setKritFlag()">bestätigt</button>
        <button class="subtileButton" (click)="setKritFlag()">nicht bestätigt</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'atPatzer'">
        <button class="subtileButtonRed" (click)="changeKampfPhase('atPatzerListe')">bestätigt</button>
        <button class="subtileButton" (click)="changeKampfPhase('atPatzer')">nicht bestätigt</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'atPatzerListe'">
        <button *ngFor="let zone of kampfdaten.geAtPatzerBereiche()" class="subtileButtonSmall" (click)="sendAtPatzer(zone)">{{zone}}</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'atTrefferzone'">
        <button *ngFor="let zone of kampfdaten.getZonenBereiche(gewaehlterGegner.zielName, gewaehlterGegner.zielGroesse)" class="subtileButtonMedium" (click)="sendAtToDefenderAndZoneToGM(zone)">
          {{zone}}<br/>
          {{kampfdaten.getZone(+zone.split(' ')[0], gewaehlterGegner.zielName, gewaehlterGegner.zielGroesse)}}
        </button>
      </div>

      <div class="textArea" *ngIf="kampfPhase === 'veWahl'">
        Du wirst von {{angreifer.name}} angegriffen!
        Dies wäre deine {{me.verteidigungenBisher + 1}}. Verteidigung diese Runde.
      </div>
      <div class="textArea" *ngIf="kampfPhase === 'passierschlag'">
        Du kannst einen Passierschlag gegen {{angreifer.name}} ausführen. Wähle deine Waffenhand!
      </div>
      <div class="textArea" *ngIf="kampfPhase === 'passierschlagZone'">
        Bestimme die Trefferzone deines Passierschlags mit einem W20:
      </div>

      <div class="buttonArea" *ngIf="kampfPhase === 'veWahl'">
        <button class="subtileButtonGold" (click)="changeKampfPhase('veKrit')">meisterhafte Verteidigung</button>
        <button class="subtileButton" (click)="sendEndOfDefense('1')">erfolgreiche Verteidigung</button>
        <button class="subtileButton" (click)="sendEndOfDefense('0')">misslungene Verteidigung</button>
        <button class="subtileButtonRed" (click)="changeKampfPhase('vePatzer')">Patzer!</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'veKrit'">
        <button class="subtileButtonGold" (click)="initiatePassierschlag()">bestätigt</button>
        <button class="subtileButton" (click)="sendEndOfDefense('1')">nicht bestätigt</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'vePatzer'">
        <button class="subtileButtonRed" (click)="changeKampfPhase('vePatzerListe')">bestätigt</button>
        <button class="subtileButton" (click)="sendEndOfDefense('0')">nicht bestätigt</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'vePatzerListe'">
        <button *ngFor="let zone of kampfdaten.geVePatzerBereiche()" class="subtileButtonSmall" (click)="sendVePatzer(zone)">{{zone}}</button>
      </div>
      <div class="buttonArea" *ngIf="kampfPhase === 'passierschlag'">
        <button class="subtileButtonGrey"  *ngIf="stepsZumAngriff[1] === 'todo'">erfolgreicher Passierschlag</button>
        <button class="subtileButtonGrey" *ngIf="stepsZumAngriff[1] === 'todo'">misslungener Passierschlag</button>
        <button class="subtileButton" *ngIf="stepsZumAngriff[1] === 'done'" (click)="endPassierschlag(1)">erfolgreicher Passierschlag</button>
        <button class="subtileButton" *ngIf="stepsZumAngriff[1] === 'done'" (click)="endPassierschlag(0)">misslungener Passierschlag</button>
      </div>

      <div class="buttonArea" *ngIf="kampfPhase === 'passierschlagZone'">
        <button *ngFor="let zone of kampfdaten.getZonenBereiche(angreifer.zielName, angreifer.zielGroesse)" class="subtileButtonMedium" (click)="sendPassierschlagToGM(zone)">{{zone}}</button>
      </div>
    </div>
  </div>

  <div class="flexHorizontal">

    <div class="kampftechniken">
      <h3>Kampftechniken</h3>
      <div class="headline">
        <div class="name">
          Name
        </div>
        <div class="leiteigenschaft">
          Leiteig.
        </div>
        <div class="at">
          AT/FK
        </div>
        <div class="pa">
          PA
        </div>
      </div>
      <div class="zeilekurz" *ngFor="let kampftechnik of charakter.kampftechniken">
        <div class="name" (click)="setKtInfo(kampftechnik)">
          {{kampftechnik.name}}
        </div>
        <div class="leiteigenschaft">
          <label *ngFor="let leiteigenschaft of kampftechnik.leiteigenschaften index as i">
            <label *ngIf="i > 0"> / </label>{{leiteigenschaft}}
          </label>
        </div>
        <div class="at">
          {{kampftechnik.atWert}}
        </div>
        <div class="pa">
          <label *ngIf="kampftechnik.nahkampf">{{kampftechnik.paWert}}</label>
          <label *ngIf="!kampftechnik.nahkampf">-</label>
        </div>
        <div class="informationen" *ngIf="kampftechnikInfo.name === kampftechnik.name">
          <b>Beschreibung</b>
          {{kampftechnikInfo.beschreibung}}
          <div *ngIf="kampftechnikInfo.besonderheiten !== ''">
            <b>Besonderheiten</b>
            {{kampftechnikInfo.besonderheiten}}
          </div>
        </div>
      </div>

      <h3>Kampf-Sonderfertigkeiten</h3>
      <div *ngFor="let sf of charakter.sonderfertigkeiten">
        <div class="zeilekurz" *ngIf="sf.type === 'KampfSF'">
          <div class="name" (click)="setSfInfo(sf)">
            {{sf.name}} <label *ngIf="sf.stufe > 0">{{stufen[sf.stufe]}}</label>
          </div>
          <div class="sfart">
            <i>{{sf.art}}</i>
          </div>
          <div class="informationen" *ngIf="sfInfo.name === sf.name">
            <div>
              <b>Beschreibung</b>
              {{sf.beschreibung}}
            </div>
            <div>
              <b>Regel</b>
              {{sf.regel}}
            </div>
            <div>
              <b>Kampftechniken</b>
              {{sf.kampftechniken}}
            </div>
          </div>
        </div>
      </div>

    </div>


    <div>
      <h3>Lebensenergie</h3>
      <div class="flexHorizontal">
        <div class="boxLeP">
          <div class="name">aktuell</div>
          <div class="flexHorizontal">
            <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseLeP()"/>
            <div class="wert">{{heldin.getWert('LeP').akt()}}</div>
            <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseLeP()"/>
          </div>
        </div>
        <div class="attribut">
          <div class="name">maximal</div>
          <div class="wert">{{heldin.getWert('LeP').wert}}</div>
        </div>
        <div class="attribut">
          <div class="name">Schmerz</div>
          <div *ngIf="heldin.getBeeinflWert('LeP', 'LeP').getNumericWert() === undefined" class="wert">kein</div>
          <div *ngIf="heldin.getBeeinflWert('LeP', 'LeP').getNumericWert() == 0" class="wert">kein</div>
          <div *ngIf="heldin.getBeeinflWert('LeP', 'LeP').getNumericWert() == 1" class="wert">leicht</div>
          <div *ngIf="heldin.getBeeinflWert('LeP', 'LeP').getNumericWert() == 2" class="wert">mittel</div>
          <div *ngIf="heldin.getBeeinflWert('LeP', 'LeP').getNumericWert() == 3" class="wert">stark</div>
          <div *ngIf="heldin.getBeeinflWert('LeP', 'LeP').getNumericWert() == 4" class="wert">extrem</div>
        </div>
      </div>

      <div class="flexHorizontal">
        <div>
          <h3>Ausweichen</h3>
          <div class="flexHorizontal">
            <div class="boxLeP">
              <div class="name">aktuell</div>
              <div class="flexHorizontal">
                <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseAW()"/>
                <div class="wert">{{heldin.getWert('AW').akt()}}</div>
                <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseAW()"/>
              </div>
            </div>
            <div class="attribut">
              <div class="name">maximal</div>
              <div class="wert">{{heldin.getWert('AW').wert}}</div>
            </div>
          </div>
        </div>
        <div>
          <h3>Initiative</h3>
          <div class="flexHorizontal">
            <div class="boxLeP">
              <div class="name">aktuell</div>
              <div class="flexHorizontal">
                <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseINI()"/>
                <div class="wert">{{heldin.getWert('INI').akt()}}</div>
                <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseINI()"/>
              </div>
            </div>
            <div class="attribut">
              <div class="name">maximal</div>
              <div class="wert">{{heldin.getWert('INI').wert}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flexHorizontal">
        <div>
          <h3>Seelenkraft</h3>
          <div class="flexHorizontal">
            <div class="boxLeP">
              <div class="name">aktuell</div>
              <div class="flexHorizontal">
                <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseSK()"/>
                <div class="wert">{{heldin.getWert('SK').akt()}}</div>
                <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseSK()"/>
              </div>
            </div>
            <div class="attribut">
              <div class="name">maximal</div>
              <div class="wert">{{heldin.getWert('SK').wert}}</div>
            </div>
          </div>
        </div>
        <div>
          <h3>Zähigkeit</h3>
          <div class="flexHorizontal">
            <div class="boxLeP">
              <div class="name">aktuell</div>
              <div class="flexHorizontal">
                <img class="fwMod" src="../../../../assets/images/button_s_neg.png" (click)="decreaseZK()"/>
                <div class="wert">{{heldin.getWert('ZK').akt()}}</div>
                <img class="fwMod" src="../../../../assets/images/button_s_pos.png" (click)="increaseZK()"/>
              </div>
            </div>
            <div class="attribut">
              <div class="name">maximal</div>
              <div class="wert">{{heldin.getWert('ZK').wert}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <div class="space"></div>
</div>
