export class VorUndNachteil {
  name: string;
  stufe: number;
  gewaehlteKategorie: string;
  spezifikation: string;

  constructor() {
    this.stufe = 1;
  }

  public copy(other: VorUndNachteil): void {
    this.name = other.name;
    this.stufe = other.stufe;
    this.gewaehlteKategorie = other.gewaehlteKategorie;
    this.spezifikation = other.spezifikation;
  }
}
