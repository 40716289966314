import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Zauber} from '../../../_interfaces/zauber';
import {DataService} from '../../../_services/data.service';
import {Profession} from '../../../_interfaces/profession';
import {SteigerungstabelleService} from '../../../_services/steigerungstabelle.service';
import {lifecycleHookToNodeFlag} from '@angular/compiler/src/view_compiler/provider_compiler';
import {Step6} from '../../../_interfaces/charaktererstellung/step6';
import {Step7} from '../../../_interfaces/charaktererstellung/step7';
import {CharakterVuN} from '../../../_interfaces/charakter-vu-n';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';
import {Zaubertrick} from '../../../_interfaces/zaubertrick';

@Component({
  selector: 'app-zauber',
  templateUrl: './zauber.component.html',
  styleUrls: ['./zauber.component.sass']
})
export class ZauberComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() heldin: Heldenerschaffung;
  @Input() maxAnzahlZauber: number;
  @Input() maxAnzahlFremdZauber: number;
  @Input() maxFW: number;

  public $alleZauber: Zauber[];
  public $zauber: Zauber;
  public $zauberAnz: number;
  public $fremdZauberAnz: number;
  public $zauberIndex: number;
  // public $listOfChosenZauber: Zauber[];

  public $alleZaubertricks: Zaubertrick[];
  public $zaubertrick: Zaubertrick;
  public $zaubertrickIndex: number;

  public $noOfProfTricks: number;
  public $noOfChosenTricks: number;
  public $zaubertricksOfProfession: string[];

  public mengenArray = ['kein', 'ein', 'zwei', 'drei', 'vier', 'fünf'];

  public $tradition: string;
  public $kostenSumme = 0;
  public $trickkostenSumme = 0;

  public zauberAusProfession: Zauber[];

  constructor(public dataService: DataService, public steigerung: SteigerungstabelleService) {
    this.getAlleZaubertricks();
    this.zauberAusProfession = [];
  }

  ngOnInit(): void {
    // this.$listOfChosenZauber = [];
    this.$zauberAnz = 0;
    this.$fremdZauberAnz = 0;
    if (this.heldin.zauber.length > 0) {

    }
    this.getAlleZauber();
  }

  public getZauber(): void {
    this.$zauber = this.$alleZauber[this.$zauberIndex];
  }

  public getZaubertrick(): void {
    this.$zaubertrick = this.$alleZaubertricks[this.$zaubertrickIndex];
  }

  public alertParent(): void {
    const eventObject: Step7 = {
      // $listOfChosenZauber: this.$listOfChosenZauber,
      $tradition: this.$tradition,
      kosten: this.$kostenSumme
    };
    this.heldin.kostenProStep[7] = -1 * this.$kostenSumme - this.$trickkostenSumme;
    this.ping.emit(eventObject);
  }

  public herausragend(name: string): number {
    let applies = 0;
    this.heldin.vorUndNachteile.forEach(vun => {
      if (vun.name.includes('Herausragende ')) {
        if (vun.kathegorie === name) {
          applies++;
        }
      }
    });
    console.log('applies: ' + applies);
    return applies;
  }

  public getAlleZauber(): void {
    this.$alleZauber = [];
    this.dataService.getAlleZauber().subscribe((data: Zauber[]) => {
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, true);
      });
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, false);
      });
      this.$alleZauber = data;
      this.$zauberIndex = 0;
      this.getZauber();
      this.getTradition();
      this.getZauberOfProfession();
      this.countZauber();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getAlleZaubertricks(): void {
    this.$alleZaubertricks = [];
    this.dataService.getAlleZaubertricks().subscribe((data: Zaubertrick[]) => {
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, true);
      });
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      data.forEach(z => {
        z.name = this.replaceUmlaute(z.name, false);
      });
      this.$alleZaubertricks = data;
      this.$zaubertrickIndex = 0;
      this.getZaubertrick();
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public replaceUmlaute(str: string, forward: boolean): string {
    let guard = true;
    while (guard) {
      guard = false;
      if (forward) {
        if (str.includes('Ä')) {
          str = str.replace('Ä', 'Ae');
          guard = true;
        } else if (str.includes('Ö')) {
          str = str.replace('Ö', 'Oe');
          guard = true;
        } else if (str.includes('Ü')) {
          str = str.replace('Ü', 'Ue');
          guard = true;
        }
      } else {
        if (str.includes('Ae')) {
          str = str.replace('Ae', 'Ä');
          guard = true;
        } else if (str.includes('Oe')) {
          str = str.replace('Oe', 'Ö');
          guard = true;
        } else if (str.includes('Ue')) {
          str = str.replace('Ue', 'Ü');
          guard = true;
        }
      }
    }
    return str;
  }

  public getZauberWithName(name: string): Zauber {
    if (name === 'Projektimago') {
      name = 'Projectimago';
    } else if (name === 'Aeolito') {
      name = 'Äolito';
    }
    let wanted: Zauber;
    this.$alleZauber.forEach(zauber => {
      if (zauber.name === name) {
        wanted = zauber;
      }
    });
    if (wanted === undefined) {
      this.$alleZauber.forEach(zauber => {
        const zauberSplitted = zauber.name.split(' ');
        if (name.startsWith(zauberSplitted[0])) {
          wanted = zauber;
        }
      });
      if (wanted === undefined) {
        console.log(name + ' nicht in Zaubern gefunden!');
      }
    }
    return wanted;
  }

  public getTradition(): void {
    if (this.heldin.profession.voraussetzungen !== undefined) {
      this.heldin.profession.voraussetzungen.forEach(v => {
        if (v.includes('Tradition')) {
          this.$tradition = v.substring(v.indexOf('(') + 1, v.indexOf(')'));
        }
      });
    }
    console.log(this.$tradition);
  }

  public addZauber(): void {
    this.$zauber.fw = 1;
    let allowed = false;
    if (this.$zauber.verbreitung.includes('allgemein') || this.$zauber.verbreitung.includes(this.$tradition)) {
      console.log('Zauber ist in der Tradition verfügbar');
      if (this.$zauberAnz < this.maxAnzahlZauber) {
        allowed = true;
      }
    } else {
      console.log('Zauber ist NICHT in der Tradition verfügbar');
      if (this.$fremdZauberAnz < this.maxAnzahlFremdZauber) {
        allowed = true;
      }
    }
    if (this.heldin.zauber.includes(this.$zauber)) {
      allowed = false;
    }
    if (allowed) {
      const spalten = ['A', 'B', 'C', 'D', 'E'];
      const kosten = this.steigerung.getKosten(1, spalten.indexOf(this.$zauber.steigerungskosten));
      this.$kostenSumme += kosten;
      console.log('Kosten: ' + kosten);
      this.heldin.zauber.push(this.$zauber);
    }
    this.countZauber();
  }

  public addZaubertrick(): void {
    console.log('addZaubertrick');
    console.log(this.heldin.zaubertricks);
    let allowed = true;
    if (this.heldin.zaubertricks.includes(this.$zaubertrick)) {
      allowed = false;
    }
    console.log(allowed);
    if (allowed) {
      this.heldin.zaubertricks.push(this.$zaubertrick);

      /*
      if (this.$zaubertricksOfProfession !== undefined) {
        this.$zaubertricksOfProfession.forEach(pt => {
          if (this.$zaubertrick.name === pt) {
            if (this.$noOfChosenTricks > 0) {
              this.$trickkostenSumme -= 1;
            }
            this.$noOfChosenTricks --;
          }
        });
      }
      this.$trickkostenSumme -= -1;
      */
      console.log('zauberTrick added');
    }
    this.calculateZaubertrickKosten();
    this.alertParent();
  }

  public calculateZaubertrickKosten(): void {
    let chosenTricks = this.$noOfProfTricks;
    this.$trickkostenSumme = 0;
    this.heldin.zaubertricks.forEach(zaubertrick => {

      if (this.$zaubertricksOfProfession !== undefined) {
        this.$zaubertricksOfProfession.forEach(pt => {
          if (zaubertrick.name === pt) {
            if (chosenTricks > 0) {
              this.$trickkostenSumme -= 1;
            }
            chosenTricks --;
          }
        });
      }
      this.$trickkostenSumme -= -1;

    });
  }

  public removeZaubertrick(zaubertrick: Zaubertrick): void {
    console.log('removeZaubertrick');
    console.log(this.heldin.zaubertricks);
    const index = this.heldin.zaubertricks.indexOf(zaubertrick);
    for (let i = index; i < this.heldin.zaubertricks.length - 1; i++) {
      this.heldin.zaubertricks[i] = this.heldin.zaubertricks[i + 1];
    }
    this.heldin.zaubertricks.pop();

    /*
    if (this.$zaubertricksOfProfession !== undefined) {
      this.$zaubertricksOfProfession.forEach(pt => {
        if (zaubertrick.name === pt) {
          this.$trickkostenSumme -= -1;
          this.$noOfChosenTricks ++;
        }
      });
    }
    this.$trickkostenSumme -= 1;
    */
    console.log('zauberTrick added');
    this.calculateZaubertrickKosten();
    this.alertParent();
  }

  public increaseZauber(zauber: Zauber): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    let maxFW = this.maxFW;
    maxFW = maxFW - -this.herausragend(zauber.name);
    if (zauber.fw < maxFW) {
      const neuerWert = zauber.fw + 1;
      const kosten = this.steigerung.getKosten(neuerWert, spalten.indexOf(zauber.steigerungskosten));
      this.$kostenSumme += kosten;
      console.log('Kosten: ' + kosten);
      zauber.fw = neuerWert;
    }
    this.alertParent();
  }

  public decreaseZauber(zauber: Zauber): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    if (zauber.fw > 0) {
      const neuerWert = zauber.fw - 1;
      const kosten = this.steigerung.getKosten(zauber.fw, spalten.indexOf(zauber.steigerungskosten)) * -1;
      this.$kostenSumme += kosten;
      console.log('Kosten: ' + kosten);
      if (neuerWert > 0) {
        zauber.fw = neuerWert;
      } else {
        const index = this.heldin.zauber.indexOf(zauber);
        for (let i = index; i < this.heldin.zauber.length - 1; i++) {
          this.heldin.zauber[i] = this.heldin.zauber[i + 1];
        }
        this.heldin.zauber.pop();
      }
    }
    this.countZauber();
    this.alertParent();
  }

  public decreaseTempZauber(zauber: Zauber): void {
    const spalten = ['A', 'B', 'C', 'D', 'E'];
    if (zauber.fw > 0) {
      const neuerWert = zauber.fw - 1;
      const kosten = this.steigerung.getKosten(zauber.fw, spalten.indexOf(zauber.steigerungskosten)) * -1;
      this.$kostenSumme += kosten;
      console.log('Kosten: ' + kosten);
      if (neuerWert >= 0) {
        zauber.fw = neuerWert;
      }
    }
  }

  public countZauber(): void {
    this.$zauberAnz = 0;
    this.$fremdZauberAnz = 0;
    this.heldin.zauber.forEach(zauber => {
      if (zauber.verbreitung.includes('allgemein') || zauber.verbreitung.includes(this.$tradition)) {
        this.$zauberAnz ++;
      } else {
        this.$fremdZauberAnz ++;
      }
    });
    this.heldin.zauber.forEach(z => {
      z.name = this.replaceUmlaute(z.name, true);
    });
    this.heldin.zauber.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.heldin.zauber.forEach(z => {
      z.name = this.replaceUmlaute(z.name, false);
    });
    this.alertParent();
  }

  public getZauberOfProfession(): void {
    console.log(this.heldin.profession.nameM);
    console.log(this.heldin.profession.zauber);
    // const profZauber: string[] = [];
    this.zauberAusProfession = [];
    if (this.heldin.profession.zauber !== undefined) {
      this.heldin.profession.zauber.forEach(zauberStr => {
        console.log(zauberStr);
        if (!zauberStr.includes('Zaubertrick')) {
          const array = zauberStr.split(' ');
          let name = array[0];
          for (let i = 1; i < array.length - 1; i++) {
            name += ' ' + array[i];
          }
          const zauber = this.getZauberWithName(name);
          zauber.fw = Number(array[array.length - 1]);
          this.zauberAusProfession.push(zauber);

          // profZauber.push(name);
        } else { // ein Zaubertrick aus folgender Liste (Feuerfinger, Handwärmer, Lockruf, Trocken)
          this.$zaubertricksOfProfession = [];
          console.log(zauberStr);
          const split1 = zauberStr.split('(');
          const menge = split1[0].split(' ')[0];
          this.$noOfChosenTricks = this.mengenArray.indexOf(menge);
          this.$noOfProfTricks = this.$noOfChosenTricks;
          const trickArray = split1[1].split(', ');
          trickArray.forEach(trick => {
            if (trick.endsWith(')')) {
              trick = trick.substring(0, trick.length - 1);
            }
            this.$zaubertricksOfProfession.push(trick);
          });
          console.log(this.$zaubertricksOfProfession);
        }
      });

      console.log(this.heldin.zauber);
      this.heldin.zauber.forEach(z => {
        let ausProf = false;
        let diff = 0;
        this.zauberAusProfession.forEach(zauber => {
          if (z.name === zauber.name) {
            ausProf = true;
            diff = z.fw - zauber.fw;
          }
        });
        if (!ausProf) {
          const fw = z.fw;
          z.fw = 0;
          console.log(z.name + ' ist nicht aus Profession, FW: ' + z.fw);
          for (let i = 0; i < fw; i++) {
            this.increaseZauber(z);
          }
        } else {
          if (diff < 0) { // zauberFW < profFW
            z.fw -= -diff;
            for (let i = 0; i > diff; i--) {
              this.decreaseZauber(z);
            }
          } else if (diff > 0) { // zauberFW > profFW
            z.fw -= diff;
            for (let i = 0; i < diff; i++) {
              this.increaseZauber(z);
            }
          }
          console.log(z.name + ' ist aus Profession');
        }
      });

      this.zauberAusProfession.forEach(zauber => {
        let vorhanden = false;
        this.heldin.zauber.forEach(z => {
          if (z.name === zauber.name) {
            vorhanden = true;
          }
        });
        if (!vorhanden) {
          console.log(zauber.name + ' ist ein Professionszauber aber nicht unter Zaubern vorhanden!');
          const z: Zauber = this.getZauberWithName(zauber.name);
          z.fw = zauber.fw;
          console.log('decrease ' + z.name + ' from ' + z.fw + ' to 0.');
          while (z.fw > 0) {
            console.log('aktueller wert: ' + z.fw);
            this.decreaseTempZauber(z);
          }
        }
      });
    }
    this.calculateZaubertrickKosten();
  }
}
