<div class="zeile">
  <h3>Gegenstand hinzufügen</h3>
  <img class="reload" (click)="reloadAusruestung()" src="assets/images/reload.png">
  <!--div class="reload" (click)="reloadAusruestung()">🗘</div-->
</div>
<div class="zeile" *ngIf="spielmeister">
  <div class="nameLang">Vorhandenen Gegenstand ändern:</div>
  <select class="wert" [(ngModel)]="index">
    <option [value]="waehlbareGegenstaende.indexOf(item)" *ngFor="let item of waehlbareGegenstaende">{{item.name}}</option>
  </select>
  <div class="name"></div>
  <button (click)="load()">laden</button>
  <div class="name"></div>
  <button (click)="reset()">reset</button>
</div>
<div class="zeile">
  <div class="name">Typ:</div>
  <select class="wert" [(ngModel)]="gegenstand.type" (ngModelChange)="changeTyp()">
    <option [value]="typ" *ngFor="let typ of typen">{{typ}}</option>
  </select>
  <div class="name">Subtyp:</div>
  <input class="wert" type="text" [(ngModel)]="this.gegenstand.subtyp">
  <div class="name">Name:</div>
  <input class="wert" type="text" [(ngModel)]="this.gegenstand.name" (blur)="changeName()">
  <div class="name">Dateiname:</div>
  <input class="wert" type="text" [(ngModel)]="this.gegenstand.dateiname">
</div>
<div class="zeile" *ngIf="isZaehlbar()">
  <div class="name">Beschreibung:</div>
  <input class="textfeld" type="text" [(ngModel)]="$any(this.gegenstand).beschreibung">
</div>
<div class="zeile" *ngIf="isZaehlbar()">
  <div class="name">Zitat:</div>
  <input *ngIf="spielmeister" class="textfeld" type="text" [(ngModel)]="$any(this.gegenstand).zitat">
  <input *ngIf="!spielmeister" class="textfeld" type="text" [(ngModel)]="userZitat" (blur)="changeUserzitat()">
</div>


<div class="zeile" *ngIf="isZaehlbar()">
  <div class="name">Preis:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).preis">
  <div class="name">Gewicht:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).gewicht">
  <div class="name">Komplexität:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).komplexitaet">

  <div *ngIf="isAllgemein()" class="name">Strukturpkt.:</div>
  <input *ngIf="isAllgemein()" class="wert" type="number" [(ngModel)]="$any(this.gegenstand).strukturpunkte">
</div>
<div class="zeile" *ngIf="isAllgemein()">
  <div class="name">Regel:</div>
  <input class="textfeld" type="text" [(ngModel)]="$any(this.gegenstand).regel">
</div>

<div class="zeile" *ngIf="isAlkohol()">
  <div class="name">min. Q. Tav.:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).minQualiTaverne">
  <div class="name">max. Q. Tav.:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).maxQualiTaverne">
  <div class="name">verk.-Einheit:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).verkaufsEinheit">
  <div class="name">verk.-Preis:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).verkaufsPreis" (blur)="changePreis()">
</div>
<div class="zeile" *ngIf="isAlkohol()">
  <div class="name">Wirkung Neg.:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).wirkungNeg">
  <div class="name">Wirkung Pos.:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).wirkungPos">
  <div class="name">Regionen:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).regionen">
  <div class="name">Preis/Maß:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).preisProMass">
</div>

<div class="zeile" *ngIf="isBehaeltnis()">
  <div class="name">max. Tragkraft:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).maxTragkraft">
  <div class="name">Inhalt-Typ:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).inhaltTyp">
  <div class="name">max. Inhalt:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).maxInhalt">
</div>

<div class="zeile" *ngIf="isLeuchtmittel()">
  <div class="name">Einheit:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).einheitBrenndauer">
  <div class="name">Brenndauer:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).brenndauer">
  <div class="name">Radius:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).radius">
</div>

<div class="zeile" *ngIf="isSeil()">
  <div class="name">Länge:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).laenge">
</div>

<div class="zeile" *ngIf="isWaffe()">
  <div class="name">Vorteil:</div>
  <input class="textfeld" type="text" [(ngModel)]="$any(this.gegenstand).vorteil">
</div>
<div class="zeile" *ngIf="isWaffe()">
  <div class="name">Nachteil:</div>
  <input class="textfeld" type="text" [(ngModel)]="$any(this.gegenstand).nachteil">
</div>
<div class="zeile" *ngIf="isWaffe()">
  <div class="name">Kampftechnik:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).kampftechnik">
  <div class="name">TP:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).tp">
  <div class="name">Länge:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).laenge">
  <div class="nameShort">impr?:</div>
  <input class="checkbox" type="checkbox" [(ngModel)]="$any(this.gegenstand).improvisiert">
  <div class="nameShort">2H?:</div>
  <input class="checkbox" type="checkbox" [(ngModel)]="$any(this.gegenstand).zweihand">
</div>

<div class="zeile" *ngIf="isNahkampfwaffe()">
  <div class="name">Leiteig.:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).leiteigenschaft">
  <div class="name">Schadenssch.:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).schadensschwelle">
  <div class="name">AT-Mod.:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).atMod">
  <div class="name">PA-Mod.:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).paMod">
</div>
<div class="zeile" *ngIf="isNahkampfwaffe()">
  <div class="name">Reichweite:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).reichweite">
</div>

<div class="zeile" *ngIf="isParierwaffe()">
  <div class="name">Typ:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).parierTyp">
  <div class="name">Schildgröße:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).schildgroesse">
  <div class="name">PA-Bonus:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).passiverBonusAT">
  <div class="name">AT-Bonus:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).passiverBonusPA">
</div>

<div class="zeile" *ngIf="isFernkampfwaffe()">
  <div class="name">Ladezeit:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).ladezeit">
  <div class="name">Reichweiten:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).reichweiten">
  <div class="name">Munitionstyp:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).munitionstyp">
</div>

<div class="zeile" *ngIf="isRuestung()">
  <div class="name">Vorteil:</div>
  <input class="textfeld" type="text" [(ngModel)]="$any(this.gegenstand).vorteil">
</div>
<div class="zeile" *ngIf="isRuestung()">
  <div class="name">Nachteil:</div>
  <input class="textfeld" type="text" [(ngModel)]="$any(this.gegenstand).nachteil">
</div>
<div class="zeile" *ngIf="isRuestung()">
  <div class="name">Kategorie:</div>
  <input class="wert" type="text" [(ngModel)]="$any(this.gegenstand).kategorie">
  <div class="name">RS-Kopf:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).rsKopf">
  <div class="name">RS-Torso:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).rsTorso">
</div>
<div class="zeile" *ngIf="isRuestung()">
  <div class="name">RS-lArm:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).rslArm">
  <div class="name">RS-rArm:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).rsrArm">
  <div class="name">RS-lBein:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).rslBein">
  <div class="name">RS-rBein:</div>
  <input class="wert" type="number" [(ngModel)]="$any(this.gegenstand).rsrBein">
</div>

<div class="zeile">
  <div class="name"></div>
  <button style="font-weight: bold" (click)="addItemToDB()">fertigstellen</button>
</div>
<div class="image" style="background-image: url('assets/images/items/{{gegenstand.dateiname}}.png')"></div>
<div class="zeileCenter" *ngIf="isZaehlbar()">
  {{$any(this.gegenstand).zitat}}
</div>
