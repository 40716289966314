import { Injectable } from '@angular/core';
import {Ziel} from '../_interfaces/kampf/ziel';
import {Kaempferin} from '../_classes/kampf/kaempferin';
import {Sicht} from '../_interfaces/kampf/sicht';
import {Zielgroesse} from '../_interfaces/kampf/zielgroesse';
// import {Kaempferin} from '../_interfaces/kaempferin';

@Injectable({
  providedIn: 'root'
})
export class KampfdatenService {

  // zielarten = ['mehrbeinig', 'arachnoid', 'sechsbeinig', 'vierbeinig', 'vogelartig, 'humanoid'];
  public zielgroessen: string[] = ['winzig', 'klein', 'mittel', 'groß', 'riesig'];
  public zielgroessenMod: number[] = [-8, -4, 0, 4, 8];
  public zielgroessenBeschr: string[] = ['Ratte, Kröte, Spatz', 'Rehkitz, Schaf, Ziege', 'Mensch, Zwerg, Esel', 'Oger, Troll, Rind', 'Drache, Riese, Elefant'];

  public bewegungDesZiels: string[] = ['steht still', 'leicht', 'schnell', 'schlägt Haken'];
  public bewegungDesZielsMod: number[] = [2, 0, -2, -4];

  public bewegungDesSchuetzen: string[] = ['ich stehe still', 'ich gehe', 'ich renne'];
  public bewegungDesSchuetzenMod: number[] = [0, -2, -4];

  public sichtModifikatoren: Sicht[] = [];
  public zielGroesseModifikatoren: Zielgroesse[] = [];

  public ziele: Ziel[];

  constructor() {
    this.ziele = [];
    const mehrbeinig: Ziel = {
      groessenkathegorie: 'mittel',
      name: 'mehrbeinig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso',
        'zufällige Gliedmaße', 'zufällige Gliedmaße', 'zufällige Gliedmaße', 'zufällige Gliedmaße',
        'zufällige Gliedmaße', 'zufällige Gliedmaße', 'zufällige Gliedmaße', 'zufällige Gliedmaße',
        'zufällige Gliedmaße', 'zufällige Gliedmaße', 'zufällige Gliedmaße', 'zufällige Gliedmaße',
        'zufällige Gliedmaße', 'zufällige Gliedmaße'],
      koerperteile: ['Kopf', 'Torso'],
      modifikator: [-10, -8]
    };
    const arachnoidGross: Ziel = {
      groessenkathegorie: 'groß',
      name: 'arachnoid',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linkes Vorderbein', 'rechtes Vorderbein',
        'linkes mittleres Vorderbein', 'rechtes mittleres Vorderbein',
        'linkes mittleres Hinterbein', 'rechtes mittleres Hinterbein',
        'linkes Hinterbein', 'rechtes Hinterbein'],
      koerperteile: ['Kopf', 'Torso', 'Vorderbein', 'mittleres Vorderbein', 'mittleres Hinterbein', 'Hinterbein'],
      modifikator: [-10, -4, -10, -10, -10, -10]
    };
    const arachnoidMittel: Ziel = {
      groessenkathegorie: 'mittel',
      name: 'arachnoid',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linkes Vorderbein', 'rechtes Vorderbein',
        'linkes mittleres Vorderbein', 'rechtes mittleres Vorderbein',
        'linkes mittleres Hinterbein', 'rechtes mittleres Hinterbein',
        'linkes Hinterbein', 'rechtes Hinterbein'],
      koerperteile: ['Kopf', 'Torso', 'Vorderbein', 'mittleres Vorderbein', 'mittleres Hinterbein', 'Hinterbein'],
      modifikator: [-10, -4, -10, -10, -10, -10]
    };
    const arachnoidKlein: Ziel = {
      groessenkathegorie: 'klein',
      name: 'arachnoid',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linkes Vorderbein', 'rechtes Vorderbein',
        'linkes mittleres Vorderbein', 'rechtes mittleres Vorderbein',
        'linkes mittleres Hinterbein', 'rechtes mittleres Hinterbein',
        'linkes Hinterbein', 'rechtes Hinterbein'],
      koerperteile: ['Kopf', 'Torso', 'Vorderbein', 'mittleres Vorderbein', 'mittleres Hinterbein', 'Hinterbein'],
      modifikator: [-10, -4, -10, -10, -10, -10]
    };
    const sechsbeinigRiesig: Ziel = {
      groessenkathegorie: 'riesig',
      name: 'sechsbeinig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linke vordere Gliedmaße', 'rechte vordere Gliedmaße',
        'linke mittlere Gliedmaße', 'linke mittlere Gliedmaße', 'rechte mittlere Gliedmaße', 'rechte mittlere Gliedmaße',
        'linke hintere Gliedmaße', 'rechte hintere Gliedmaße',
        'Schwanz', 'Schwanz'],
      koerperteile: ['Kopf', 'Torso', 'vordere Gliedmaße', 'mittlere Gliedmaße', 'hintere Gliedmaße', 'Schwanz'],
      modifikator: [-10, -10, -8, -10, -10]
    };
    const sechsbeinigGross: Ziel = {
      groessenkathegorie: 'groß',
      name: 'sechsbeinig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linke vordere Gliedmaße', 'rechte vordere Gliedmaße',
        'linke mittlere Gliedmaße', 'rechte mittlere Gliedmaße',
        'linke hintere Gliedmaße', 'rechte hintere Gliedmaße',
        'Schwanz', 'Schwanz'],
      koerperteile: ['Kopf', 'Torso', 'vordere Gliedmaße', 'mittlere Gliedmaße', 'hintere Gliedmaße', 'Schwanz'],
      modifikator: [-8, -10, -10, -10, -10]
    };
    const vierbeinigGross: Ziel = {
      groessenkathegorie: 'groß',
      name: 'vierbeinig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linkes Vorderbein', 'linkes Vorderbein', 'rechtes Vorderbein', 'rechtes Vorderbein',
        'linkes Hinterbein', 'linkes Hinterbein', 'rechtes Hinterbein', 'rechtes Hinterbein'],
      koerperteile: ['Kopf', 'Torso', 'Vorderbein', 'Hinterbein'],
      modifikator: [-7, -5, -8, -8]
    };
    const vierbeinigMittel: Ziel = {
      groessenkathegorie: 'mittel',
      name: 'vierbeinig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linkes Vorderbein', 'linkes Vorderbein', 'linkes Vorderbein', 'rechtes Vorderbein', 'rechtes Vorderbein', 'rechtes Vorderbein',
        'linkes Hinterbein', 'linkes Hinterbein', 'rechtes Hinterbein', 'rechtes Hinterbein'],
      koerperteile: ['Kopf', 'Torso', 'Vorderbein', 'Hinterbein'],
      modifikator: [-8, -6, -6, -8]
    };
    const vierbeinigKlein: Ziel = {
      groessenkathegorie: 'klein',
      name: 'vierbeinig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linkes Vorderbein', 'linkes Vorderbein', 'rechtes Vorderbein', 'rechtes Vorderbein',
        'linkes Hinterbein', 'linkes Hinterbein', 'rechtes Hinterbein', 'rechtes Hinterbein'],
      koerperteile: ['Kopf', 'Torso', 'Vorderbein', 'Hinterbein'],
      modifikator: [-8, -4, -8, -8]
    };
    const humanoidGross: Ziel = {
      groessenkathegorie: 'groß',
      name: 'humanoid',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso',
        'linker Arm', 'linker Arm', 'linker Arm', 'linker Arm', 'linker Arm', 'rechter Arm', 'rechter Arm', 'rechter Arm', 'rechter Arm', 'rechter Arm',
        'linkes Bein', 'linkes Bein', 'rechtes Bein', 'rechtes Bein'],
      koerperteile: ['Kopf', 'Torso', 'Arm', 'Bein', 'Auge', 'Fuß', 'Hals', 'Hand'],
      modifikator: [-10, -8, -2, -8, -16, -12, -14, -12]
    };
    const humanoidMittel: Ziel = {
      groessenkathegorie: 'mittel',
      name: 'humanoid',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linker Arm', 'linker Arm', 'rechter Arm', 'rechter Arm',
        'linkes Bein', 'linkes Bein', 'rechtes Bein', 'rechtes Bein'],
      koerperteile: ['Kopf', 'Torso', 'Arm', 'Bein', 'Auge', 'Fuß', 'Hals', 'Hand'],
      modifikator: [-10, -2, -8, -8, -16, -12, -14, -12]
    };
    const humanoidKlein: Ziel = {
      groessenkathegorie: 'klein',
      name: 'humanoid',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso',
        'linker Arm', 'linker Arm', 'rechter Arm', 'rechter Arm',
        'linkes Bein', 'rechtes Bein'],
      koerperteile: ['Kopf', 'Torso', 'Arm', 'Bein', 'Auge', 'Fuß', 'Hals', 'Hand'],
      modifikator: [-6, -8, -8, -10, -16, -12, -14, -12]
    };
    const vogelartigWinzig: Ziel = {
      groessenkathegorie: 'winzig',
      name: 'vogelartig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linker Flügel', 'linker Flügel', 'rechter Flügel', 'rechter Flügel',
        'linkes Bein', 'rechtes Bein'],
      koerperteile: ['Kopf', 'Torso', 'Flügel', 'Bein'],
      modifikator: [-8, -6, -8, -10]
    };
    const vogelartigKlein: Ziel = {
      groessenkathegorie: 'klein',
      name: 'vogelartig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linker Flügel', 'linker Flügel', 'rechter Flügel', 'rechter Flügel',
        'linkes Bein', 'rechtes Bein'],
      koerperteile: ['Kopf', 'Torso', 'Flügel', 'Bein'],
      modifikator: [-8, -6, -8, -10]
    };
    const vogelartigMittel: Ziel = {
      groessenkathegorie: 'mittel',
      name: 'vogelartig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linker Flügel', 'linker Flügel', 'rechter Flügel', 'rechter Flügel',
        'linkes Bein', 'rechtes Bein'],
      koerperteile: ['Kopf', 'Torso', 'Flügel', 'Bein'],
      modifikator: [-8, -6, -8, -10]
    };
    const vogelartigGross: Ziel = {
      groessenkathegorie: 'groß',
      name: 'vogelartig',
      wurftabelle: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      zugehoerigesTeil: ['Kopf', 'Kopf', 'Kopf', 'Kopf',
        'Torso', 'Torso', 'Torso', 'Torso', 'Torso', 'Torso',
        'linker Flügel', 'linker Flügel', 'rechter Flügel', 'rechter Flügel',
        'linkes Bein', 'rechtes Bein'],
      koerperteile: ['Kopf', 'Torso', 'Flügel', 'Bein'],
      modifikator: [-8, -6, -8, -10]
    };
    this.ziele.push(vogelartigWinzig);
    this.ziele.push(vogelartigKlein);
    this.ziele.push(vogelartigMittel);
    this.ziele.push(vogelartigGross);
    this.ziele.push(humanoidKlein);
    this.ziele.push(humanoidMittel);
    this.ziele.push(humanoidGross);
    this.ziele.push(vierbeinigKlein);
    this.ziele.push(vierbeinigMittel);
    this.ziele.push(vierbeinigGross);
    this.ziele.push(sechsbeinigGross);
    this.ziele.push(sechsbeinigRiesig);
    this.ziele.push(arachnoidKlein);
    this.ziele.push(arachnoidMittel);
    this.ziele.push(arachnoidGross);
    this.ziele.push(mehrbeinig);

    let sicht: Sicht = {
      name: 'Sicht klar und ungestört',
      beispiel: '',
      atMod: 0,
      veMod: 0,
      fkMod: 0
    };
    this.sichtModifikatoren.push(sicht);
    sicht = {
      name: 'Leichte Störung der Sicht',
      beispiel: 'leichtes Blattwerk, Morgendunst',
      atMod: -1,
      veMod: -1,
      fkMod: -2
    };
    this.sichtModifikatoren.push(sicht);
    sicht = {
      name: 'Ziel als Silhouette erkennbar',
      beispiel: 'Nebel, Mondlicht',
      atMod: -2,
      veMod: -2,
      fkMod: -4
    };
    this.sichtModifikatoren.push(sicht);
    sicht = {
      name: 'Ziel schemenhaft erkennbar',
      beispiel: 'starker Nebel, Sternenlicht',
      atMod: -3,
      veMod: -3,
      fkMod: -6
    };
    this.sichtModifikatoren.push(sicht);

    let zielgroesse: Zielgroesse = {
      name: 'winzig',
      beispiel: 'Ratte, Kröte, Spatz',
      atMod: -4,
      nurSchildOderAw: false,
      nurAw: false,
      fkMod: -8
    };
    this.zielGroesseModifikatoren.push(zielgroesse);
    zielgroesse = {
      name: 'klein',
      beispiel: 'Rehkitz, Schaf, Ziege',
      atMod: 0,
      nurSchildOderAw: false,
      nurAw: false,
      fkMod: -4
    };
    this.zielGroesseModifikatoren.push(zielgroesse);
    zielgroesse = {
      name: 'mittel',
      beispiel: 'Mensch, Zwerg, Esel',
      atMod: 0,
      nurSchildOderAw: false,
      nurAw: false,
      fkMod: 0
    };
    this.zielGroesseModifikatoren.push(zielgroesse);
    zielgroesse = {
      name: 'groß',
      beispiel: 'Oger, Troll, Rind',
      atMod: 0,
      nurSchildOderAw: true,
      nurAw: false,
      fkMod: 4
    };
    this.zielGroesseModifikatoren.push(zielgroesse);
    zielgroesse = {
      name: 'riesig',
      beispiel: 'Drache, Riese, Elefant',
      atMod: 0,
      nurSchildOderAw: false,
      nurAw: true,
      fkMod: 8
    };
    this.zielGroesseModifikatoren.push(zielgroesse);
  }

  public getZieleDerGroesse(groesse: string): Ziel[] {
    const ziele: Ziel[] = [];
    return ziele;
  }

  public getSichtmodifikatoren(): Sicht[] {
    return this.sichtModifikatoren;
  }

  public getNahkampfPatzer(kaempferin: Kaempferin, rechts: boolean, wurf: number): string {
    let msg: string;
    msg = '';
    switch (wurf) {
      case 2:
        if (rechts) {
          kaempferin.rechteHand.status = 'zerstört';
        } else {
          kaempferin.linkeHand.status = 'zerstört';
        }
        msg = 'Waffe zerstört: Die Waffe ist unwiederbringlich zerstört. ' +
          'Bei unzerstörbaren Waffen wird das ' +
          'Ergebnis wie bei 5 behandelt.';
        break;
      case 3:
        if (rechts) {
          kaempferin.rechteHand.status = 'schwer beschädigt';
        } else {
          kaempferin.linkeHand.status = 'schwer beschädigt';
        }
        msg = 'Waffe schwer ' +
          'beschädigt: Die Waffe ist nicht mehr verwendbar, bis ' +
          'sie repariert wird. Bei unzerstörbaren Waffen ' +
          'wird das Ergebnis wie bei 5 behandelt.';
        break;
      case 4:
        if (rechts) {
          kaempferin.rechteHand.status = 'beschädigt';
          // kaempferin.rechteHand.atMod -= 2;
          // kaempferin.rechteHand.paMod -= 2;
        } else {
          kaempferin.linkeHand.status = 'beschädigt';
          // kaempferin.linkeHand.atMod -= 2;
          // kaempferin.linkeHand.paMod -= 2;
        }
        msg = 'Waffe ' +
          'beschädigt: Die Waffe ist beschädigt worden. Alle ' +
          'Proben auf Attacke und Parade sind ' +
          'um 2 erschwert, bis sie repariert wird. ' +
          'Bei unzerstörbaren Waffen wird das ' +
          'Ergebnis wie bei 5 behandelt.';
        break;
      case 5:
        if (rechts) {
          kaempferin.rechteHand.status = 'verloren';
        } else {
          kaempferin.linkeHand.status = 'verloren';
        }
        msg = 'Waffe ' +
          'verloren: Die Waffe ist zu Boden gefallen.';
        break;
      case 6:
        if (rechts) {
          kaempferin.rechteHand.status = 'verhakt';
        } else {
          kaempferin.linkeHand.status = 'verhakt';
        }
        msg = 'Waffe stecken ' +
          'geblieben: Die Waffe des Helden ist in einem ' +
          'Baum, einer Holzwand, dem Boden oder ' +
          'Ähnlichem stecken geblieben. Um sie ' +
          'zu befreien, ist 1 Aktion und eine um 1 ' +
          'erschwerte Probe auf Kraftakt (Ziehen & ' +
          'Zerren) notwendig.';
        break;
      case 7:
        msg = 'Sturz: ' + kaempferin.name + ' stolpert und stürzt, wenn ' +
          'nicht eine um 2 erschwerte ' +
          'Probe auf Körperbeherrschung (Balance) ' +
          'gelingt. Sollte das nicht gelingen, ' +
          'erhält ' + kaempferin.name + ' den Status Liegend.';
        break;
      case 8:
        kaempferin.erschwernisAufNaechsteHandlung -= -2;
        msg = 'Stolpern: ' + kaempferin.name + ' stolpert, die nächste ' +
          'Handlung ist um 2 erschwert.';
        break;
      case 9:
        kaempferin.schmerz -= -1;
        kaempferin.schmerzKR = 3;
        msg = 'Fuß verdreht: ' + kaempferin.name + ' erhält für 3 Kampfrunden eine ' +
          'Stufe Schmerz.';
        break;
      case 10:
        kaempferin.betaeubung -= -1;
        msg = 'Beule: ' + kaempferin.name + ' hat sich im Eifer des Gefechts ' +
          'den Kopf gestoßen. ' + kaempferin.name + ' erhält für eine ' +
          'Stunde eine Stufe Betäubung.';
        break;
      case 11:
        msg = 'Selbst ' +
          'verletzt: ' + kaempferin.name + ' hat sich selbst verletzt und erleidet ' +
          'Schaden. Der Schaden der Waffe ' +
          'wird unter Einbeziehung des Schadensbonus ' +
          'ausgewürfelt. Bei unbewaffneten ' +
          'Kämpfern wird 1W6 TP angenommen.';
        break;
      case 12:
        msg = 'Selbst ' +
          'schwer verletzt: Ein schwerer Eigentreffer von ' + kaempferin.name + '. ' +
          'Der Schaden der Waffe wird unter ' +
          'Einbeziehung des Schadensbonus ' +
          'ausgewürfelt und dann verdoppelt. Bei ' +
          'unbewaffneten Kämpfern wird 1W6 TP ' +
          'angenommen.';
        break;
    }
    return msg;
  }

  public getZonenBereiche(zielname: string, zielgroesse: string): string[] {
    const gesucht: Ziel = this.getZielWithNameUndGroesse(zielname, zielgroesse);
    let teil = '';
    let count = 1;
    const array = [];
    let bereich = '';
    let start = true;
    gesucht.zugehoerigesTeil.forEach(t => {
      if (start) {
        bereich += count;
        start = false;
        teil = t;
      } else if (t !== teil) {
        if (Number(bereich) !== (count - 1)) {
          bereich += ' - ' + (count - 1);
        }
        array.push(bereich);
        bereich = '';
        bereich += count;
        teil = t;
      }
      count ++;
    });
    if (Number(bereich) !== 20) {
      bereich += ' - ' + 20;
    }
    array.push(bereich);
    return array;
  }

  public getZielWithNameUndGroesse(zielname: string, zielgroesse: string): Ziel {
    let gesucht: Ziel;
    this.ziele.forEach(ziel => {
      if (ziel.name === zielname && ziel.groessenkathegorie === zielgroesse) {
        gesucht = ziel;
      }
    });
    return gesucht;
  }

  public getZone(wurf: number, zielname: string, zielgroesse: string): string {
    const ziel: Ziel = this.getZielWithNameUndGroesse(zielname, zielgroesse);
    return ziel.zugehoerigesTeil[wurf];
  }

  public getZonenOfZiel(zielname: string, zielgroesse: string): string[] {
    const ziel: Ziel = this.getZielWithNameUndGroesse(zielname, zielgroesse);
    const zonen: string[] = [];
    ziel.zugehoerigesTeil.forEach(teil => {
      console.log(teil);
      if (!zonen.includes(teil)) {
        zonen.push(teil);
      }
    });
    console.log(zonen);
    return zonen;
  }

  public geAtPatzerBereiche(): string[] {
    return ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  }

  public geVePatzerBereiche(): string[] {
    return ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  }

  /**
   * 0: nahkampf
   * 1: wurfwaffen (Wurfwaffen)
   * 2: schusswaffe (Armbrüste, Bögen)
   */
  public getKampftechnikType(kt: string): number {
    let type = 0;
    if (kt === 'Wurfwaffen') {
      type = 1;
    } else if (kt === 'Armbrüste' || kt === 'Bögen') {
      type = 2;
    }
    return type;
  }

  public getAlleZielTypen(): string[] {
    const typen: string[] = [];
    this.ziele.forEach(ziel => {
      const name = ziel.name;
      if (!typen.includes(name)) {
        typen.push(name);
      }
    });
    return typen;
  }

  public getAlleZielGroessen(): string[] {
    const groessen: string[] = [];
    this.zielgroessen.forEach(ziel => {
      groessen.push(ziel);
    });
    return groessen;
  }

  public getAlleGroessenOfType(type: string): string[] {
    const groessen: string[] = [];
    this.ziele.forEach(ziel => {
      if (ziel.name === type) {
        const groesse = ziel.groessenkathegorie;
        if (!groessen.includes(groesse)) {
          groessen.push(groesse);
        }
      }
    });
    return groessen;
  }

}
