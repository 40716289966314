import {Waffe} from './waffe';
import {NameWertNumber} from '../../_interfaces/name-wert-number';
import {Kampftechnik} from '../../_interfaces/kampftechnik';
import {Erschaffungsattribut} from '../../_interfaces/erschaffungsattribut';

export class Kaempferin {
  name: string;
  spieler: string;
  npc: boolean;
  team: number;
  gruppe: number;
  ini: number;
  basisIni: number;
  lep: number;
  lepBasis: number;
  aw: number;
  maxAngriffe: number;
  gs: number;
  gsBasis: number;

  zielGroesse: string;
  zielName: string;

  gegnerInNahkampfreichweite: Kaempferin[];

  aktionenUebrig: number;
  laengerfristigeHandlung: number;
  freieAktionenUebrig: number;
  verteidigungenBisher: number;

  rechteHand: Waffe;
  linkeHand: Waffe;

  tpWaffeR: string;
  tpWaffeL: string;

  // Rüstung
  rsZonen: string[];
  rs: number[];

  // Optisches
  rasse: string;
  weiblich: boolean;

  // modifikatoren
  belastung: number;
  betaeubung: number;
  furcht: number;
  paralyse: number;
  schmerz: number;
  verwirrung: number;

  belastungKR: number;
  betaeubungKR: number;
  furchtKR: number;
  paralyseKR: number;
  schmerzKR: number;
  verwirrungKR: number;

  eigenschaften: Erschaffungsattribut[];
  /*mu: number;
  kl: number;
  in: number;
  ch: number;
  ff: number;
  ge: number;
  ko: number;
  kk: number;*/

  kampftechniken: NameWertNumber[];

  erschwernisAufNaechsteHandlung: number;

  constructor() {
    console.log('constructor of kaempferin');
    this.name = '';
    this.spieler = '';
    this.npc = false;
    this.team = 1;
    this.gruppe = 0;
    this.ini = 0;
    this.basisIni = 0;
    this.lep = 0;
    this.lepBasis = 0;
    this.aw = 0;
    this.maxAngriffe = 1;
    this.gsBasis = 8;
    this.gs = 0;
    this.zielGroesse = 'mittel';
    this.zielName = 'humanoid';
    this.gegnerInNahkampfreichweite = [];
    this.aktionenUebrig = 0;
    this.laengerfristigeHandlung = 0;
    this.freieAktionenUebrig = 0;
    this.verteidigungenBisher = 0;
    this.linkeHand = new Waffe();
    this.rechteHand = new Waffe();
    this.tpWaffeR = '';
    this.tpWaffeL = '';
    this.rsZonen = [];
    this.rs = [];
    this.rasse = '';
    this.weiblich = true;
    this.belastung = 0;
    this.betaeubung = 0;
    this.furcht = 0;
    this.paralyse = 0;
    this.schmerz = 0;
    this.verwirrung = 0;
    this.belastungKR = 0;
    this.betaeubungKR = 0;
    this.furchtKR = 0;
    this.paralyseKR = 0;
    this.schmerzKR = 0;
    this.verwirrungKR = 0;
    this.eigenschaften = [];
    const kf = ['MU', 'KL', 'IN', 'CH', 'FF', 'GE', 'KO', 'KK'];
    const lf = ['Mut', 'Klugheit', 'Intuition', 'Charisma', 'Fingerfertigkeit', 'Gewandheit', 'Konstitution', 'Körperkraft'];
    for (let i = 0; i < 8; i++) {
      const ersch: Erschaffungsattribut = {
        name: kf[i],
        wert: 8,
        startwert: 8,
        modifikator: 0,
        langform: lf[i]
      };
      this.eigenschaften.push(ersch);
    }



    /*this.mu = 8;
    this.kl = 8;
    this.in = 8;
    this.ch = 8;
    this.ff = 8;
    this.ge = 8;
    this.ko = 8;
    this.kk = 8;*/
    this.kampftechniken = [];
    this.erschwernisAufNaechsteHandlung = 0;
  }



  public clone(other: Kaempferin): Kaempferin {
    const kaempferin = new Kaempferin();
    kaempferin.name = other.name;
    kaempferin.spieler = other.spieler;
    kaempferin.npc = other.npc;
    kaempferin.team = other.team;
    kaempferin.gruppe = other.gruppe;
    kaempferin.ini = other.ini;
    kaempferin.basisIni = other.basisIni;
    kaempferin.lep = other.lep;
    kaempferin.lepBasis = other.lepBasis;
    kaempferin.aw = other.aw;
    kaempferin.maxAngriffe = other.maxAngriffe;
    kaempferin.gs = other.gs;
    kaempferin.gsBasis = other.gsBasis;
    kaempferin.zielGroesse = other.zielGroesse;
    kaempferin.zielName = other.zielName;
    kaempferin.gegnerInNahkampfreichweite = other.gegnerInNahkampfreichweite;
    kaempferin.aktionenUebrig = other.aktionenUebrig;
    kaempferin.laengerfristigeHandlung = other.laengerfristigeHandlung;
    kaempferin.freieAktionenUebrig = other.freieAktionenUebrig;
    kaempferin.verteidigungenBisher = other.verteidigungenBisher;
    kaempferin.rechteHand = other.rechteHand;
    kaempferin.linkeHand = other.linkeHand;
    kaempferin.tpWaffeR = other.tpWaffeR;
    kaempferin.tpWaffeL = other.tpWaffeL;
    kaempferin.rsZonen = other.rsZonen;
    kaempferin.rs = other.rs;
    kaempferin.rasse = other.rasse;
    kaempferin.weiblich = other.weiblich;
    kaempferin.belastung = other.belastung;
    kaempferin.betaeubung = other.betaeubung;
    kaempferin.furcht = other.furcht;
    kaempferin.paralyse = other.paralyse;
    kaempferin.schmerz = other.schmerz;
    kaempferin.verwirrung = other.verwirrung;
    kaempferin.belastungKR = other.belastungKR;
    kaempferin.betaeubungKR = other.betaeubungKR;
    kaempferin.furchtKR = other.furchtKR;
    kaempferin.paralyseKR = other.paralyseKR;
    kaempferin.schmerzKR = other.schmerzKR;
    kaempferin.verwirrungKR = other.verwirrungKR;
    kaempferin.eigenschaften = [];
    other.eigenschaften.forEach(eig => {
      kaempferin.eigenschaften.push(eig);
    });
    /*kaempferin.mu = other.mu;
    kaempferin.kl = other.kl;
    kaempferin.in = other.in;
    kaempferin.ch = other.ch;
    kaempferin.ff = other.ff;
    kaempferin.ge = other.ge;
    kaempferin.ko = other.ko;
    kaempferin.kk = other.kk;*/
    kaempferin.kampftechniken = other.kampftechniken;
    kaempferin.erschwernisAufNaechsteHandlung = other.erschwernisAufNaechsteHandlung;
    return kaempferin;
  }

  public copy(other: Kaempferin): void {
    this.name = other.name;
    this.spieler = other.spieler;
    this.npc = other.npc;
    this.team = other.team;
    this.gruppe = other.gruppe;
    this.ini = other.ini;
    this.basisIni = other.basisIni;
    this.lep = other.lep;
    this.lepBasis = other.lepBasis;
    this.aw = other.aw;
    this.maxAngriffe = other.maxAngriffe;
    this.gs = other.gs;
    this.gsBasis = other.gsBasis;
    this.zielGroesse = other.zielGroesse;
    this.zielName = other.zielName;
    this.gegnerInNahkampfreichweite = other.gegnerInNahkampfreichweite;
    this.aktionenUebrig = other.aktionenUebrig;
    this.laengerfristigeHandlung = other.laengerfristigeHandlung;
    this.freieAktionenUebrig = other.freieAktionenUebrig;
    this.verteidigungenBisher = other.verteidigungenBisher;

    const rH: Waffe = new Waffe();
    rH.copy(other.rechteHand);
    this.rechteHand = rH;

    const lH: Waffe = new Waffe();
    lH.copy(other.linkeHand);
    this.linkeHand = lH;

    this.tpWaffeR = other.tpWaffeR;
    this.tpWaffeL = other.tpWaffeL;
    this.rsZonen = other.rsZonen;
    this.rs = other.rs;
    this.rasse = other.rasse;
    this.weiblich = other.weiblich;
    this.belastung = other.belastung;
    this.betaeubung = other.betaeubung;
    this.furcht = other.furcht;
    this.paralyse = other.paralyse;
    this.schmerz = other.schmerz;
    this.verwirrung = other.verwirrung;
    this.belastungKR = other.belastungKR;
    this.betaeubungKR = other.betaeubungKR;
    this.furchtKR = other.furchtKR;
    this.paralyseKR = other.paralyseKR;
    this.schmerzKR = other.schmerzKR;
    this.verwirrungKR = other.verwirrungKR;
    this.eigenschaften = [];
    other.eigenschaften.forEach(eig => {
      this.eigenschaften.push(eig);
    });
    /*this.mu = other.mu;
    this.kl = other.kl;
    this.in = other.in;
    this.ch = other.ch;
    this.ff = other.ff;
    this.ge = other.ge;
    this.ko = other.ko;
    this.kk = other.kk;*/
    this.kampftechniken = other.kampftechniken;
    this.erschwernisAufNaechsteHandlung = other.erschwernisAufNaechsteHandlung;
  }

  public initialisiereWerte(): void {

  }

  resetWerteAufBasis(): void {
    this.eigenschaften.forEach(eig => {
      eig.wert = eig.startwert;
    });
    this.lep = this.lepBasis;
    this.gs = this.gsBasis;
    this.ini = this.basisIni;
  }

  public calculateWerte(alleKampftechniken: Kampftechnik[]): void {
    console.log('calculateWerte');
    let ktRechts: Kampftechnik;
    let ktLinks: Kampftechnik;

    let handlungsunfaehig = false;
    let zustandsMod = 0; // AT, PA
    let zustandsModIni = 0; // INI
    let zustandsModGs = 1; // GS Divisor
    this.getZustaendeAlsListe().forEach(zustand => {
      zustandsMod -= zustand.wert;
      if (zustand.name === 'Belastung') {
        zustandsModIni -= zustand.wert;
      }
      if (zustand.name === 'Paralyse') {
        zustandsModGs = 1 - (0.25 * zustand.wert);
      }
      if (zustand.wert === 4) {
        handlungsunfaehig = true;
      }
    });

    this.basisIni = Math.round((this.getAttrWert('mu') - -this.getAttrWert('ge')) / 2.0);
    this.basisIni -= -zustandsModIni;

    this.gs = Math.round((this.gsBasis - -zustandsModIni) * zustandsModGs);
    this.lepBasis = this.getAttrWert('ko') * 2;

    alleKampftechniken.forEach(kt => {
      if (kt.name === this.rechteHand.kampftechnik) {
        ktRechts = kt;
      }
      if (kt.name === this.linkeHand.kampftechnik) {
        ktLinks = kt;
      }
    });

    // je 3 Punkte MU über 8
    let atMod = 0;
    console.log('mu: ' + this.getAttrWert('mu'));
    for (let i = 10; i < this.getAttrWert('mu'); i += 3) {
      atMod ++;
    }

    // je 3 Punkte FF über 8
    let fkMod = 0;
    for (let i = 10; i < this.getAttrWert('ff'); i += 3) {
      fkMod ++;
    }

    const paRMod = this.getPaModOfNeueKaempferin(ktRechts);
    const paLMod = this.getPaModOfNeueKaempferin(ktLinks);

    let atR = 6;
    let paR = 2;
    let atL = 6;
    let paL = 2;

    this.kampftechniken.forEach(kt => {
      if (kt.name === ktRechts.name) {
        atR = kt.wert;
      } else if (kt.name === ktLinks.name) {
        atL = kt.wert;
      }
    });

    // verrechne AT oder FK bonus aus Eigenschaftswerten
    if (ktRechts.nahkampf) {
      paR = Math.ceil(atR / 2.0) + paRMod;
      atR += atMod;
    } else {
      atR += fkMod;
      paR = 0;
    }
    if (ktLinks.nahkampf) {
      paL = Math.ceil(atL / 2.0) + paLMod;
      atL += atMod;
    } else {
      atL += fkMod;
      paL = 0;
    }
    this.aw = Math.round(this.getAttrWert('ge') / 2.0) - -zustandsMod;

    this.rechteHand.at = atR - -this.rechteHand.atMod - -zustandsMod;
    this.rechteHand.pa = paR - -this.rechteHand.paMod - -zustandsMod;

    this.linkeHand.at = atL - -this.linkeHand.atMod - -zustandsMod - 4;
    this.linkeHand.pa = paL - -this.linkeHand.paMod - -zustandsMod - 4;

    if (handlungsunfaehig) {
      this.rechteHand.at = 0;
      this.rechteHand.pa = 0;
      this.linkeHand.at = 0;
      this.linkeHand.pa = 0;
      this.aw = 0;
    }

    this.rechteHand.calculateSchadensbonus(this.getAttributeAsListe());
    this.linkeHand.calculateSchadensbonus(this.getAttributeAsListe());


    console.log('final AT ' + this.rechteHand.at + ' und PA ' + this.rechteHand.pa + ' für ' + this.rechteHand.name);
    console.log('final AT ' + this.linkeHand.at + ' und PA ' + this.linkeHand.pa + ' für ' + this.linkeHand.name);
  }

  public getAttrWert(le: string): number {
    let attr = 0;
    this.eigenschaften.forEach(eig => {
      if (eig.name.toLowerCase() === le.toLowerCase()) {
        attr = eig.wert;
      }
    });
    return attr;
  }

  public setAttrWert(le: string, wert: number): void {
    this.eigenschaften.forEach(eig => {
      if (eig.name.toLowerCase() === le.toLowerCase()) {
        eig.startwert = wert;
      }
    });
  }

  getAttributeAsListe(): NameWertNumber[] {
    const liste: NameWertNumber[] = [];
    this.eigenschaften.forEach(eig => {
      const nw: NameWertNumber = {
        name: eig.name.toLowerCase(),
        wert: eig.wert
      };
      liste.push(nw);
    });
    return liste;
  }

  getPaModOfNeueKaempferin(kt: Kampftechnik): number {
    let mod = 0;
    kt.leiteigenschaften.forEach(le => {
      const attr = this.getAttrWert(le);
      let paMod = 0;
      for (let i = 10; i < attr; i += 3) {
        paMod ++;
      }
      mod = Math.max(mod, paMod);
    });
    return mod;
  }

  naechsteKampfrunde(): void {
    this.belastungKR --;
    if (this.belastungKR <= 0) {
      this.belastungKR = 0;
    }
    this.betaeubungKR --;
    if (this.betaeubungKR <= 0) {
      this.betaeubungKR = 0;
    }
    this.furchtKR --;
    if (this.furchtKR <= 0) {
      this.furchtKR = 0;
    }
    this.paralyseKR --;
    if (this.paralyseKR <= 0) {
      this.paralyseKR = 0;
    }
    this.schmerzKR --;
    if (this.schmerzKR <= 0) {
      this.schmerzKR = 0;
    }
    this.verwirrungKR --;
    if (this.verwirrungKR <= 0) {
      this.verwirrungKR = 0;
    }
  }


  getZustaendeAlsListe(): NameWertNumber[] {
    const liste: NameWertNumber[] = [];
    let stufe: number = this.belastung;
    if (this.belastungKR > 0) {
      stufe++;
      if (stufe > 4) {
        stufe = 4;
      }
    }
    let nw: NameWertNumber = {
      name: 'Belastung',
      wert: stufe
    };
    liste.push(nw);

    stufe = this.betaeubung;
    if (this.betaeubungKR > 0) {
      stufe++;
      if (stufe > 4) {
        stufe = 4;
      }
    }
    nw = {
      name: 'Betäubung',
      wert: stufe
    };
    liste.push(nw);

    stufe = this.furcht;
    if (this.furchtKR > 0) {
      stufe++;
      if (stufe > 4) {
        stufe = 4;
      }
    }
    nw = {
      name: 'Furcht',
      wert: stufe
    };
    liste.push(nw);

    stufe = this.paralyse;
    if (this.paralyseKR > 0) {
      stufe++;
      if (stufe > 4) {
        stufe = 4;
      }
    }
    nw = {
      name: 'Paralyse',
      wert: stufe
    };
    liste.push(nw);

    stufe = this.schmerz;
    if (this.schmerzKR > 0) {
      stufe++;
      if (stufe > 4) {
        stufe = 4;
      }
    }
    nw = {
      name: 'Schmerz',
      wert: stufe
    };
    liste.push(nw);

    stufe = this.verwirrung;
    if (this.verwirrungKR > 0) {
      stufe++;
      if (stufe > 4) {
        stufe = 4;
      }
    }
    nw = {
      name: 'Verwirrung',
      wert: stufe
    };
    liste.push(nw);
    return liste;
  }

  public getEigenschaftspunkte(): number {
    let sum = 0;
    this.eigenschaften.forEach(eig => {
      sum -= -eig.startwert;
    });
    return sum;
  }

  /**
   *
   * @param max
   * returns neuenWert
   */
  public erhoeheZufaelligeEigenschaft(max: number): number {
    const index = Math.floor(Math.random() * 7) + 1;
    let neuerWert = -1;
    if (this.eigenschaften[index].startwert < max) {
      this.eigenschaften[index].startwert ++;
      neuerWert = this.eigenschaften[index].startwert;
    }
    return neuerWert;
  }

  public resetEigenschaftspunkte(): void {
    this.eigenschaften.forEach(eig => {
      eig.startwert = 8;
      eig.wert = 8;
    });
  }

  public modifiziereEigenschaft(name: string, mod: number): void {
    this.eigenschaften.forEach(eig => {
      if (name.toLowerCase() === eig.name.toLowerCase()) {
        eig.startwert -= -mod;
      }
    });
  }
}
