import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'platform'
})
export class PersistenceService {
  constructor() {
  }

  public storeComponent(name: string, component: any): void {
    const str = JSON.stringify(component);
    sessionStorage.setItem(name, str);
  }

  public storePermanentComponent(name: string, component: any): void {
    const str = JSON.stringify(component);
    localStorage.setItem(name, str);
  }

  public storeLocalComponent(name: string, component: any): void {
    /*
    const str = JSON.stringify(component);
    localStorage.setItem(name, str);
    */
  }

  public retrieveComponent(name: string): any {
    const str = sessionStorage.getItem(name);
    // console.log(str);
    let parsed;
    if (str != null && str.length > 2) {
      parsed = JSON.parse(str);
    }
    return parsed;
  }

  public retrievePermanentComponent(name: string): any {
    const str = localStorage.getItem(name);
    let parsed;
    if (str != null && str.length > 2) {
      parsed = JSON.parse(str);
    }
    return parsed;
  }

  public retrieveLocalComponent(name: string): any {
    const str = localStorage.getItem(name);
    // console.log(str);
    let parsed;
    if (str != null && str.length > 2) {
      parsed = JSON.parse(str);
    }
    // return parsed;
    return null;
  }

  public deleteComponent(name: string): void {
    sessionStorage.removeItem(name);
  }

  public deletePermanentComponent(name: string): void {
    localStorage.removeItem(name);
  }

  public deleteLocalComponent(name: string): void {
    localStorage.removeItem(name);
  }

  public logCurrentDiskUsage(): void {
    let lsTotal = 0;
    let xLen;
    let x;
    for (x in sessionStorage) {
      if (!sessionStorage.hasOwnProperty(x)){
        continue;
      }
      xLen = ((sessionStorage[x].length + x.length) * 2);
      lsTotal += xLen;
      console.log(x.substr(0, 50) + ' = ' + (xLen / 1024).toFixed(2) + ' KB');
    }
    const size = 'Total = ' + (lsTotal / 1024).toFixed(2) + ' KB';
    console.log('%c' + size, 'background: #e6ffee; color: #009933');
  }

  public logCurrentPermanentDiskUsage(): void {
    let lsTotal = 0;
    let xLen;
    let x;
    for (x in localStorage) {
      if (!localStorage.hasOwnProperty(x)){
        continue;
      }
      xLen = ((localStorage[x].length + x.length) * 2);
      lsTotal += xLen;
      console.log(x.substr(0, 50) + ' = ' + (xLen / 1024).toFixed(2) + ' KB');
    }
    const size = 'Total = ' + (lsTotal / 1024).toFixed(2) + ' KB';
    console.log('%c' + size, 'background: #e6ffee; color: #009933');
  }

  public logLocalDiskUsage(): void {
    let lsTotal = 0;
    let xLen;
    let x;
    for (x in localStorage) {
      if (!localStorage.hasOwnProperty(x)){
        continue;
      }
      xLen = ((localStorage[x].length + x.length) * 2);
      lsTotal += xLen;
      console.log(x.substr(0, 50) + ' = ' + (xLen / 1024).toFixed(2) + ' KB');
    }
    const size = 'Total = ' + (lsTotal / 1024).toFixed(2) + ' KB';
    console.log('%c' + size, 'background: #e6ffee; color: #009933');
  }
}
