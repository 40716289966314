<div class="body">
  <div class="zeileSpaceAround">
    <div *ngFor="let button of navBar">
      <div class="button" *ngIf="button !== navBar[nav]" (click)="changeNav(button)">{{button}}</div>
      <div class="buttonGlow" *ngIf="button === navBar[nav]" (click)="changeNav(button)">{{button}}</div>
    </div>

  </div>
  <div class="headline">
    <h3>Abenteuerpunkte</h3>
    <h3>Charakterstufe: {{stufenNamen[charakterStufe]}}</h3>
    <h3>aktuell: {{charakter.getCurrentWert('AP')}} AP</h3>
  </div>

  <div *ngIf="nav === 0">
    <div class="zeileSpaceAround">
      <div class="linkeSpalte">
        <h3>Abenteuerpunkte hinzufügen</h3>
        <div class="zeile">
          <input class="apHinzuGrund" type="text" placeholder="Wofür gibt es AP?" [(ngModel)]="apHinzuGrund">
          <input class="apHinzuWert" type="number" [(ngModel)]="apHinzuWert">
          <div class="button" (click)="fuegeAPhinzu()">hinzufügen</div>
        </div>
      </div>
      <div class="rechteSpalte">
        <h3>bisherige Veränderungen</h3>
        <div class="zeile" *ngFor="let eintrag of apGeschichte">
          <div class="modName">{{eintrag.modName.substring(eintrag.modName.indexOf('#')+1)}}:</div>
          <div class="modWert">{{eintrag.modWert}}</div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="nav === 1">
    <div class="zeile">
      <h3>Steigerung von Fertigkeiten</h3>
      <h3 *ngIf="unterNav === 'Talent'" class="nebenHead">maximaler Talentwert: {{maxWert}}</h3>
      <h3 *ngIf="unterNav === 'Kampftechnik'" class="nebenHead">maximaler Kampftechnikwert: {{maxWert}}</h3>
    </div>
    <div class="zeile">
      <div>Was soll gesteigert werden?</div>
      <select class="auswahl" [(ngModel)]="unterNav" (ngModelChange)="ladeWerte()">
        <option value='Talent'>Talent</option>
        <option value='Kampftechnik'>Kampftechnik</option>
      </select>
      <select class="auswahl" [(ngModel)]="wahlIndex" (ngModelChange)="resetWert()">
        <option [value]="werte.indexOf(talent)" *ngFor="let talent of werte">{{talent.name}}</option>
      </select>
      <div class="wert">aktuell: {{werte[wahlIndex].wert}}</div>
      <img alt="minus" src="assets/images/button_s_neg.png" (click)="verringereWertSteigerung()"/>
      <div class="wert">+ {{steigerung}}</div>
      <img alt="plus" src="assets/images/button_s_pos.png" (click)="erhoeheWertSteigerung()"/>
      <div class="wert">Kosten: {{kosten}} AP</div>
      <div class="button" (click)="fuegeSteigerungHinzu(werte[wahlIndex].name, werte[wahlIndex].wert)">hinzufügen</div>
    </div>

  </div>
  <div  *ngIf="nav === 2">
    <div class="zeile">
      <h3>Steigerung von Eigenschaften</h3>
      <h3 class="nebenHead">maximaler Eigenschaftsert: {{stufenEigGrenze[charakterStufe] + 2}}</h3>
    </div>
    <div class="zeile">
      <select class="auswahl" [(ngModel)]="wahlIndex" (ngModelChange)="resetWert()">
        <option [value]="werte.indexOf(talent)" *ngFor="let talent of werte">{{talent.name}}</option>
      </select>
      <div class="wert">aktuell: {{werte[wahlIndex].wert}}</div>
      <img alt="minus" src="assets/images/button_s_neg.png" (click)="verringereWertSteigerung()"/>
      <div class="wert">+ {{steigerung}}</div>
      <img alt="plus" src="assets/images/button_s_pos.png" (click)="erhoeheWertSteigerung()"/>
      <div class="wert">Kosten: {{kosten}} AP</div>
      <div class="button" (click)="fuegeSteigerungHinzu(werte[wahlIndex].name, werte[wahlIndex].wert)">hinzufügen</div>
    </div>
  </div>
  <h3 *ngIf="quittung.length > 0">Geplante Steigerungen</h3>
  <div class="zeile{{i%2}}" *ngFor="let eintrag of quittung index as i">
    <div class="modNameLang">{{eintrag.modName}}:</div>
    <div class="modWert">{{eintrag.modWert}} AP</div>
    <img alt="minus" src="assets/images/button_s_neg.png" (click)="entferneSteigerung(eintrag.modName)"/>
  </div>
  <div class="unterzeile{{quittung.length%2}}" *ngIf="quittung.length > 1">
    <div class="modNameLang">gesamt:</div>
    <div class="modWert">{{gesamtKosten}} AP</div>
  </div>
  <div class="zeile{{quittung.length%2}}" *ngIf="quittung.length > 0">
    <div class="modNameLang"></div>
    <div class="button" (click)="steigerungDurchfuehren()">steigern</div>
  </div>
</div>
