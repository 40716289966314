<div class="background" style="background-image: url('assets/images/Gelaende/{{region}}.jpg'); animation: drunkCam{{charakter.getCurrentWert('Berauscht')}} 20s infinite alternate">
  <div class="background" id="bg2" style="background-image: url('assets/images/Gelaende/{{region}}.jpg'); animation: doubleVision{{charakter.getCurrentWert('Berauscht')}} 15s infinite alternate"></div>
  <div class="blood" style="background-image: url('assets/images/Gelaende/Blood.png'); opacity: {{(charakter.getWert('LeP').abzug / charakter.getWert('LeP').wert)}}">
    <div class="blood2" style="background-image: url('assets/images/Gelaende/Blood2.png'); opacity: {{(charakter.getWert('LeP').abzug / charakter.getWert('LeP').wert)}}"></div>
  </div>
  <div class="dirt" style="background-image: url('assets/images/Gelaende/Dirt.png'); opacity: {{(charakter.getCurrentWert('Schmutz') / 4)}}"></div>
</div>
<div class="body" *ngIf="charakter !== undefined">
  <div *ngIf="options.getWertNumber('navigation') == 0">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum" [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Talente'"></app-titelbereich>
    <!--app-talentblatt [charakter]="charakter" [glossar]="glossar"></--app-talentblatt-->
    <app-talentbogen *ngIf="glossar != null && charakter != null" [glossar]="glossar" [charakter]="charakter" (ping)="updateChar()"></app-talentbogen>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 1">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Kampf'"></app-titelbereich>
    <!--app-kampfblatt (ping)="updateChar()" [heldin]="charakter" [charakter]="oldChar"></app-kampfblatt-->
    <app-kampfsystem *ngIf="glossar != null && charakter != null" [katalog]="katalog" (ping)="updateChar()" [glossar]="glossar" [charakter]="charakter"></app-kampfsystem>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 2">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Allgemeine Informationen'"></app-titelbereich>
    <app-allgemein *ngIf="glossar != null && charakter != null" [katalog]="katalog" [alter]="alter" [charakter]="charakter" [glossar]="glossar" (ping)="updateChar()"></app-allgemein>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 3">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Zauber'"></app-titelbereich>
    <app-zauberbogen *ngIf="glossar != null && charakter != null" [glossar]="glossar" [charakter]="charakter" (ping)="updateChar()"></app-zauberbogen>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 9">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Tiere'"></app-titelbereich>
    <app-tiere *ngIf="glossar != null && charakter != null" [glossar]="glossar" [charakter]="charakter" (ping)="updateChar()"></app-tiere>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 4">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Ausrüstung'"></app-titelbereich>
    <app-ausruestung *ngIf="glossar != null && charakter != null" [charakter]="charakter" [glossar]="glossar" (ping)="updateChar()" (reload)="reloadEvent($event)"></app-ausruestung>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 5">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Notizen'"></app-titelbereich>
    <app-notizen *ngIf="glossar != null && charakter != null" [charakter]="charakter" (ping)="updateChar()"></app-notizen>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 6">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Abenteuer'"></app-titelbereich>
    <app-abenteuer *ngIf="glossar != null && charakter != null" [charakter]="charakter" [gruppe]="gruppe"></app-abenteuer>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 7">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum"  [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Entwicklung'"></app-titelbereich>
    <app-entwicklung *ngIf="glossar != null && charakter != null" [charakter]="charakter" [glossar]="glossar" (ping)="updateChar()" ></app-entwicklung>
  </div>
  <div *ngIf="options.getWertNumber('navigation') == 8">
    <app-titelbereich *ngIf="glossar != null && charakter != null" [datum]="datum" [charakter]="charakter" (ping)="updateChar()" [glossar]="glossar" [titel$]="'Proben'"></app-titelbereich>
    <app-probenblatt *ngIf="glossar != null && charakter != null" [glossar]="glossar" [charakter]="charakter" (ping)="updateChar()"></app-probenblatt>
  </div>
</div>

<div class="flag" id="entwicklung" (click)="changeNav(7)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Entwicklung
  </div>
</div>
<div class="flag" id="abenteuer" (click)="changeNav(6)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Abenteuer
  </div>
</div>
<div class="flag" id="notizen" (click)="changeNav(5)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Notizen
  </div>
</div>
<div class="flag" id="allgemein" (click)="changeNav(2)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Allgem.
  </div>
</div>
<div class="flag" id="ausruestung" (click)="changeNav(4)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Ausrüst.
  </div>
</div>
<div class="flag" id="talente" (click)="changeNav(0)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Talente
  </div>
</div>
<div class="flag" id="kampf" (click)="changeNav(1)">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Kampf
  </div>
</div>
<div *ngIf="charakter !== undefined">
  <div class="flag" id="proben" (click)="changeNav(8)">
    <img src="../../../assets/images/fahne_o_grey.png"/>
    <div class="text">
      Proben
    </div>
  </div>
</div>
<div *ngIf="charakter !== undefined">
  <div class="flag" id="zauber" (click)="changeNav(3)" *ngIf="charakter.getWert('AsP').wert > 0">
    <img src="../../../assets/images/fahne_o_grey.png"/>
    <div class="text">
      Zauber
    </div>
  </div>
</div>
<div *ngIf="charakter !== undefined">
  <div class="flag" id="tiere" (click)="changeNav(9)">
    <img src="../../../assets/images/fahne_o_grey.png"/>
    <div class="text">
      Tiere
    </div>
  </div>
</div>
<div class="flag" id="save" (click)="saveCharakter()">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    Save
  </div>
</div>
<div class="flag" id="back" (click)="alertParent()">
  <img src="../../../assets/images/fahne_o_grey.png"/>
  <div class="text">
    zurück
  </div>
</div>
