import { Injectable } from '@angular/core';
import {Wetter} from '../_classes/abenteuer/wetter';

@Injectable({
  providedIn: 'root'
})
export class AbenteuerService {

  tage: number[] = [30, 30, 30, 30, 30, 30,
                    30, 30, 30, 30, 30, 30, 5];

  monateZwoelfgoetter: string[] = ['Praios', 'Rondra', 'Efferd', 'Travia',
                      'Boron', 'Hesinde', 'Firun', 'Tsa',
                      'Phex', 'Peraine', 'Ingerimm', 'Rahja',
                      'Namenlose Tage'];
  monateIrdisch: string[] = ['Juli', 'August', 'September', 'Oktober',
    'November', 'Dezember', 'Januar', 'Februar',
    'März', 'April', 'Mai', 'Juni',
    'Zeit zwischen Juni und Juli'];
  monateThrowal: string[] = ['Midsonnmond', 'Kornmond', 'Heimamond', 'Schlachtmond',
    'Sturmmond', 'Frostmond', 'Grimfrostmond', 'Goimond',
    'Friskenmond', 'Eimond', 'Faramond', 'Vinmond',
    'Hranngartage'];
  monateZwerge: string[] = ['Sommermond', 'Hitzemond', 'Regenmond', 'Weinmond',
    'Nebelmond', 'Dunkelmond', 'Frostmond', 'Neugeburt',
    'Marktmond', 'Saatmond', 'Feuermond', 'Brautmond',
    'Drachentage'];
  monateIrdischReihenfolge: string[] = ['Januar', 'Februar',
    'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober',
    'November', 'Dezember'];

  constructor() {}

  getDatum(datum: string, art: string): string {
    let ausgabe = '';
    const splitted: string[] = datum.split('.');
    const tag: number = Number(splitted[0]);
    const monat: number = Number(splitted[1]);
    const jahr: number = Number(splitted[2]);
    if (art === 'Zwölfgötter') {
      ausgabe = tag + '. ' + this.monateZwoelfgoetter[monat - 1] + ' ' + jahr + ' BF';
    } else if (art === 'Thorwal') {
      ausgabe = tag + '. ' + this.monateThrowal[monat - 1] + ' ' + jahr + ' BF';
    } else if (art === 'Zwerge') {
      ausgabe = tag + '. ' + this.monateZwerge[monat - 1] + ' ' + jahr + ' BF';
    } else if (art === 'irdisch') {
      // ausgabe = tag + '. ' + this.monateIrdisch[monat - 1] + ' ' + jahr + ' BF';
      ausgabe = this.getIrdischesDatum(tag, monat, jahr);
    } else if (art === 'Jahreszeit') {
      if (monat === 1 || monat === 2 || monat === 12 || monat === 13) {
        ausgabe = 'Sommer';
      } else if (monat === 3 || monat === 4 || monat === 5) {
        ausgabe = 'Herbst';
      } else if (monat === 6 || monat === 7 || monat === 8) {
        ausgabe = 'Winter';
      } else {
        ausgabe = 'Frühling';
      }
    }
    return ausgabe;
  }

  getIrdischesDatum(tag: number, monat: number, jahr: number): string {
    let ausgabe = '';
    let tageGesamt = this.getTagImJahr(tag, monat);
    console.log(tageGesamt);
    const tageIrdisch: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    for (let i = 0; i < tageIrdisch.length; i++) {
      if (tageGesamt - tageIrdisch[i] > 0) {
        tageGesamt = tageGesamt - tageIrdisch[i];
        console.log('tageGesamt - ' + tageIrdisch[i] + ': ' + tageGesamt);
      } else {
        console.log('index dex monats: ' + i);
        ausgabe = tageGesamt + '.' + this.monateIrdischReihenfolge[i] + '.' + jahr;
        i = tageIrdisch.length;
      }
    }
    return ausgabe;
  }

  getTagImJahr(tag: number, monat: number): number {
    let tageGesamt = 0;
    let monatVorher = monat - 1;
    if (monatVorher === 0) {
      monatVorher = 13;
    }
    console.log('monatVorher: ' + monatVorher);
    if (monatVorher < 7) {
      for (let i = 1; i <= monatVorher; i++) {
        tageGesamt += 30;
      }
      for (let i = 7; i <= 12; i++) {
        tageGesamt += 30;
      }
      tageGesamt += 5;
    } else {
      if (monatVorher === 13) {
        monatVorher = 12;
        tageGesamt += 5;
      }
      for (let i = 7; i <= monatVorher; i++) {
        tageGesamt += 30;
      }
    }
    if (tageGesamt === 365) {
      tageGesamt = 0;
    }
    return tageGesamt + tag;

  }

  erhoeheDatum(datum: string, tage: number): string {
    let ausgabe = '';
    const splitted: string[] = datum.split('.');
    let tag: number = Number(splitted[0]);
    let monat: number = Number(splitted[1]);
    let jahr: number = Number(splitted[2]);
    tag += tage;
    while (tag > this.tage[monat - 1]) {
      tag -= this.tage[monat - 1];
      monat ++;
      if (monat > 13) {
        monat = 1;
        jahr ++;
      }
    }
    ausgabe = tag + '.' + monat + '.' + jahr;
    return ausgabe;
  }

  getAlterInJahren(datum: string, tsaTag: string): string {
    console.log(datum + ' ' + tsaTag);
    let alter = 'unbekannt';
    if (tsaTag != null) {
      const splitted: string[] = datum.split('.');
      const tag: number = Number(splitted[0]);
      const monat: number = Number(splitted[1]);
      const jahr: number = Number(splitted[2]);

      const splittedGeb: string[] = tsaTag.split('.');
      const tagGeb: number = Number(splittedGeb[0]);
      const monatGeb: number = Number(splittedGeb[1]);
      const jahrGeb: number = Number(splittedGeb[2]);

      let jahre = jahr - jahrGeb;
      if (monatGeb > monat) {
        jahre --;
      } else if (monatGeb === monat) {
        if (tagGeb > tag) {
          jahre --;
        }
      }
      if (!isNaN(jahre)) {
        if (jahre !== 1) {
          alter = jahre + ' Jahre';
        } else {
          alter = jahre + ' Jahr';
        }
      }
    }
    return alter;
  }

  /**
   *
   * @param inWueste <i>befindet sich die Gruppe grade in einer Wüste?</i>
   * @param jahreszeit <i>Frühling; Sommer; Herbst; Winter</i>
   * @param windigeRegion <i>befindet sich die Gruppe grade an einem Winigen Ort (Meer, Küste, Berge)?</i>
   * @param region <i>Ewiges Eis; Höhen des Ehernen Schwerts; Hoher Norden; Tundra und Taiga;
   * Bornland, Thorwal; Streitende Königreiche bis Weiden; Zentrales Mittelreich;
   * Nördliches Horasreich, Almada, Aranien; Höhen des Raschtulswalls;
   * Südliches Horasreich, Reich der Ersten Sonne; Khôm; Echsensümpfe, Meridiana; Altoum, Gewürzinseln, Südmeer</i>
   */
  generiereWetter(inWueste: boolean, jahreszeit: string, windigeRegion: boolean, region: string): Wetter {
    const wetter = new Wetter();
    wetter.bewoelkung = this.ermittleBewoelkung(inWueste);
    wetter.windstaerke = this.ermittleWindstaerke(jahreszeit, windigeRegion);
    wetter.wellenhoehe = this.ermittleWellenhoehe(wetter.windstaerke);
    wetter.temperaturTag = this.ermittleTemperatur(region, jahreszeit);
    console.log('Tag: ' + wetter.temperaturTag + '°C');

    let wuerfel = 6;
    let wurfZusatz = 3;
    if (jahreszeit === 'Sommer') {
      wuerfel = 4;
      wurfZusatz = 2;
    } else if (jahreszeit === 'Winter') {
      wuerfel = 12;
      wurfZusatz = 5;
    }
    const wolken: string[] = ['völlig wolkenlos', 'einzelne Wolken', 'bewölkt mit Wolkenlücken', 'geschlossene Wolkendecke', 'Gewitter'];
    let wolkenTag: number[] = [10, 5, 0, -2, -5];
    let wolkenNacht: number[] = [-5, -2, 0, 2, 5];

    if (jahreszeit === 'Winter') {
      wolkenTag = [5, 2, 0, -5, -10];
      wolkenNacht = [-10, -5, 0, 2, 5];
    }

    const wolkenIndex = wolken.indexOf(wetter.bewoelkung);
    wetter.temperaturTag += wolkenTag[wolkenIndex];

    const wind: string[] = ['windstill', 'leichter Wind', 'sanfte Brise', 'frische Brise', 'steife Brise', 'starker Wind', 'Sturm'];
    const windV: number[] = [0, 5, 15, 35, 55, 65, 90];
    const windTemp: number[] = [8, 6, 4, 0, -2, -4, -6];
    const windIndex = wind.indexOf(wetter.windstaerke);
    wetter.temperaturTag += windTemp[windIndex];
    const wurf = Math.floor(Math.random() * wuerfel) + wurfZusatz;
    wetter.temperaturNacht = wetter.temperaturTag - wurf;
    console.log('Nacht: ' + wetter.temperaturNacht + '°C');
    wetter.temperaturNacht += wolkenNacht[wolkenIndex];
    /*if (wetter.temperaturTag > 10) {
      wetter.temperaturTag += windTemp[windIndex];
    } else {
      console.log('Tag -> Windchill');
      const temp = wetter.temperaturTag;
      wetter.temperaturTag = 13.12 + 0.6215 * temp + (0.4275 * temp - 35.75) * Math.pow(windV[windIndex], 0.16);
    }*/
    /*if (wetter.temperaturNacht > 10) {
      wetter.temperaturNacht += windTemp[windIndex];
    } else {
      console.log('Nacht -> Windchill');
      const temp = wetter.temperaturNacht;
      wetter.temperaturNacht
        = 13.12 + 0.6215 * temp + (0.4275 * temp - 35.75) * Math.pow(windV[windIndex], 0.16);
    }*/
    wetter.niederschlag = this.ermittleNiederschlag(wetter.bewoelkung, wetter.windstaerke, wetter.temperaturTag, jahreszeit);
    return wetter;
  }

  wettervorhersage(inWueste: boolean, jahreszeit: string, windigeRegion: boolean, region: string, altesWetter: Wetter): Wetter {
    console.log('Wettervorhersage...');
    const wetter = new Wetter();
    const aenderungsWurf = Math.floor(Math.random() * 20) + 1;
    console.log(aenderungsWurf);
    let aendereWindstaerke = true;
    let aendereBewoelkung = true;
    let aendereTemperatur = true;
    let aendereNiederschlag = true;
    let gewitterAenderung = true;
    if (altesWetter.bewoelkung === 'Gewitter') {
      console.log('Gewitter! -> ändere Wetter?');
      const gewitterAenderungsWurf = Math.floor(Math.random() * 20) + 1;
      if (gewitterAenderungsWurf === 20) {
        gewitterAenderung = false;
      }
    } else {
      gewitterAenderung = false;
    }

    if (jahreszeit === 'Sommer' || jahreszeit === 'Winter') {
      if (aenderungsWurf <= 9) {
        aendereWindstaerke = false;
        aendereBewoelkung = false;
        aendereTemperatur = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf === 10) {
        aendereBewoelkung = false;
        aendereTemperatur = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf === 11) {
        aendereWindstaerke = false;
        aendereBewoelkung = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf === 12) {
        aendereWindstaerke = false;
        aendereBewoelkung = false;
        aendereTemperatur = false;
      } else if (aenderungsWurf === 13) {
        aendereWindstaerke = false;
        aendereTemperatur = false;
      } else if (aenderungsWurf === 14) {
        aendereBewoelkung = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf === 15) {
        aendereBewoelkung = false;
        aendereTemperatur = false;
      } else if (aenderungsWurf === 16) {
        aendereBewoelkung = false;
        aendereWindstaerke = false;
      } else if (aenderungsWurf === 17) {
        aendereBewoelkung = false;
      } else if (aenderungsWurf === 18) {
        aendereWindstaerke = false;
      } else if (aenderungsWurf === 19) {
        aendereTemperatur = false;
      }
    } else {
      if (aenderungsWurf <= 4) {
        aendereWindstaerke = false;
        aendereBewoelkung = false;
        aendereTemperatur = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf === 5) {
        aendereBewoelkung = false;
        aendereTemperatur = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf === 6) {
        aendereWindstaerke = false;
        aendereBewoelkung = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf === 7) {
        aendereWindstaerke = false;
        aendereBewoelkung = false;
        aendereTemperatur = false;
      } else if (aenderungsWurf <= 9) {
        aendereWindstaerke = false;
        aendereTemperatur = false;
      } else if (aenderungsWurf <= 11) {
        aendereBewoelkung = false;
        aendereNiederschlag = false;
      } else if (aenderungsWurf <= 13) {
        aendereBewoelkung = false;
        aendereTemperatur = false;
      } else if (aenderungsWurf <= 15) {
        aendereBewoelkung = false;
        aendereWindstaerke = false;
      } else if (aenderungsWurf === 16) {
        aendereBewoelkung = false;
      } else if (aenderungsWurf === 17) {
        aendereWindstaerke = false;
      } else if (aenderungsWurf === 18) {
        aendereTemperatur = false;
      }
    }

    if (gewitterAenderung) {
      console.log('NUR BEI GEWITTER');
      do {
        wetter.bewoelkung = this.ermittleBewoelkung(inWueste);
      } while (wetter.bewoelkung === 'Gewitter');
    } else {
      if (aendereBewoelkung) {
        wetter.bewoelkung = this.ermittleBewoelkung(inWueste);
      } else {
        wetter.bewoelkung = altesWetter.bewoelkung;
      }
    }

    if (aendereWindstaerke) {
      wetter.windstaerke = this.ermittleWindstaerke(jahreszeit, windigeRegion);
      wetter.wellenhoehe = this.ermittleWellenhoehe(wetter.windstaerke);
    } else {
      wetter.windstaerke = altesWetter.windstaerke;
      wetter.wellenhoehe = altesWetter.wellenhoehe;
    }
    wetter.grundtempeatur = this.ermittleTemperatur(region, jahreszeit);

    let wuerfel = 6;
    let wurfZusatz = 3;
    if (jahreszeit === 'Sommer') {
      wuerfel = 4;
      wurfZusatz = 2;
    } else if (jahreszeit === 'Winter') {
      wuerfel = 12;
      wurfZusatz = 5;
    }
    const wolken: string[] = ['völlig wolkenlos', 'einzelne Wolken', 'bewölkt mit Wolkenlücken', 'geschlossene Wolkendecke', 'Gewitter'];
    let wolkenTag: number[] = [10, 5, 0, -2, -5];
    let wolkenNacht: number[] = [-5, -2, 0, 2, 5];

    if (jahreszeit === 'Winter') {
      wolkenTag = [5, 2, 0, -5, -10];
      wolkenNacht = [-10, -5, 0, 2, 5];
    }

    const wolkenIndex = wolken.indexOf(wetter.bewoelkung);
    wetter.temperaturTag = wetter.grundtempeatur + wolkenTag[wolkenIndex];

    const wind: string[] = ['windstill', 'leichter Wind', 'sanfte Brise', 'frische Brise', 'steife Brise', 'starker Wind', 'Sturm'];
    const windV: number[] = [0, 5, 15, 35, 55, 65, 90];
    const windTemp: number[] = [8, 6, 4, 0, -2, -4, -6];
    const windIndex = wind.indexOf(wetter.windstaerke);
    wetter.temperaturTag += windTemp[windIndex];

    const wurf = Math.floor(Math.random() * wuerfel) + wurfZusatz;
    wetter.temperaturNacht = wetter.temperaturTag - wurf;
    console.log('Nacht: ' + wetter.temperaturNacht + '°C');
    wetter.temperaturNacht += wolkenNacht[wolkenIndex];



    /*if (wetter.temperaturTag > 10) {
      wetter.temperaturTag += windTemp[windIndex];
    } else {
      console.log('Tag -> Windchill');
      const temp = wetter.temperaturTag;
      wetter.temperaturTag = 13.12 + 0.6215 * temp + (0.4275 * temp - 35.75) * Math.pow(windV[windIndex], 0.16);
    }*/
    /*if (wetter.temperaturNacht > 10) {
      wetter.temperaturNacht += windTemp[windIndex];
    } else {
      console.log('Nacht -> Windchill');
      const temp = wetter.temperaturNacht;
      wetter.temperaturNacht
        = 13.12 + 0.6215 * temp + (0.4275 * temp - 35.75) * Math.pow(windV[windIndex], 0.16);
    }*/
    if (aendereNiederschlag) {
      wetter.niederschlag = this.ermittleNiederschlag(wetter.bewoelkung, wetter.windstaerke, wetter.temperaturTag, jahreszeit);
    } else {
      wetter.niederschlag = altesWetter.niederschlag;
    }
    return wetter;
  }

  ermittleBewoelkung(inWueste: boolean): string {
    let bewoelkung = '';
    let wurf = Math.floor(Math.random() * 20) + 1;
    if (inWueste) {
      if (wurf <= 16) {
        bewoelkung = 'völlig wolkenlos';
      } else if (wurf <= 18) {
        bewoelkung = 'einzelne Wolken';
      } else if (wurf <= 19) {
        bewoelkung = 'bewölkt mit Wolkenlücken';
      } else {
        bewoelkung = 'geschlossene Wolkendecke';
      }
    } else {
      if (wurf <= 4) {
        bewoelkung = 'völlig wolkenlos';
      } else if (wurf <= 10) {
        bewoelkung = 'einzelne Wolken';
      } else if (wurf <= 16) {
        bewoelkung = 'bewölkt mit Wolkenlücken';
      } else {
        bewoelkung = 'geschlossene Wolkendecke';
      }
    }
    wurf = Math.floor(Math.random() * 20) + 1;
    console.log('Gewitterwurf: ' + wurf);
    if (bewoelkung === 'bewölkt mit Wolkenlücken') {
      if (inWueste) {
        if (wurf >= 15) {
          bewoelkung = 'Gewitter';
        }
      } else {
        if (wurf >= 10) {
          bewoelkung = 'Gewitter';
        }
      }
    } else {
      if (inWueste) {
        if (wurf >= 19) {
          bewoelkung = 'Gewitter';
        }
      } else {
        if (wurf >= 15) {
          bewoelkung = 'Gewitter';
        }
      }

    }
    return bewoelkung;
  }

  ermittleWindstaerke(jahreszeit: string, windigeRegion: boolean): string {
    let windstaerke = '';
    let wurf = Math.floor(Math.random() * 20) + 1;
    if (windigeRegion) {
      wurf += 2;
    }
    if (jahreszeit === 'Herbst') {
      if (wurf <= 3) {
        windstaerke = 'windstill';
      } else if (wurf <= 5) {
        windstaerke = 'leichter Wind';
      } else if (wurf <= 7) {
        windstaerke = 'sanfte Brise';
      } else if (wurf <= 10) {
        windstaerke = 'frische Brise';
      } else if (wurf <= 14) {
        windstaerke = 'steife Brise';
      } else if (wurf <= 18) {
        windstaerke = 'starker Wind';
      } else {
        windstaerke = 'Sturm';
      }
    } else {
      if (wurf <= 4) {
        windstaerke = 'windstill';
      } else if (wurf <= 7) {
        windstaerke = 'leichter Wind';
      } else if (wurf <= 10) {
        windstaerke = 'sanfte Brise';
      } else if (wurf <= 13) {
        windstaerke = 'frische Brise';
      } else if (wurf <= 16) {
        windstaerke = 'steife Brise';
      } else if (wurf <= 19) {
        windstaerke = 'starker Wind';
      } else {
        windstaerke = 'Sturm';
      }
    }
    return windstaerke;
  }

  ermittleWellenhoehe(windstaerke: string): string {
    let wellenhoehe = '';
    const wind: string[] = ['windstill', 'leichter Wind', 'sanfte Brise', 'frische Brise', 'steife Brise', 'starker Wind', 'Sturm'];
    const windV: number[] = [0, 5, 15, 35, 55, 65, 90];
    const wellen: string[] = ['spiegelglatte See', 'kleine schuppenartige Wellen', 'kleine selten brechende Wellen',
      'mittlere bis große Wellen, weiße Flächen nach Wellenbrechen, überall Schaumköpfe, selten Gischt',
      'die See türmt Sich, weißer Schaum in Streifen in Windrichtung, etwas Gischt',
      'mittlere Wellenberge mit langen Kämmen, Gischt weht von den Kämmen',
      'hohe bis sehr hohe Wellenberge, See rollt schwer und stoßartig und ist weiß vor Schaum, Gischt vermindert die Sicht'];
    wellenhoehe = wellen[wind.indexOf(windstaerke)];
    return wellenhoehe;
  }

  ermittleTemperatur(region: string, jahreszeit: string): number {
    let temperatur: number;
    if (region === 'Ewiges Eis') {
      if (jahreszeit === 'Sommer') {
        temperatur = -20;
      } else if (jahreszeit === 'Winter') {
        temperatur = -40;
      } else {
        temperatur = -30;
      }
    } else if (region === 'Höhen des Ehernen Schwerts') {
      if (jahreszeit === 'Sommer') {
        temperatur = -10;
      } else if (jahreszeit === 'Winter') {
        temperatur = -30;
      } else {
        temperatur = -20;
      }
    } else if (region === 'Hoher Norden') {
      if (jahreszeit === 'Sommer') {
        temperatur = 0;
      } else if (jahreszeit === 'Winter') {
        temperatur = -20;
      } else {
        temperatur = -10;
      }
    } else if (region === 'Tundra und Taiga') {
      if (jahreszeit === 'Sommer') {
        temperatur = 5;
      } else if (jahreszeit === 'Winter') {
        temperatur = -5;
      } else {
        temperatur = 0;
      }
    } else if (region === 'Bornland, Thorwal') {
      if (jahreszeit === 'Sommer') {
        temperatur = 10;
      } else if (jahreszeit === 'Winter') {
        temperatur = -5;
      } else {
        temperatur = 3;
      }
    } else if (region === 'Streitende Königreiche bis Weiden') {
      if (jahreszeit === 'Sommer') {
        temperatur = 10;
      } else if (jahreszeit === 'Winter') {
        temperatur = 0;
      } else {
        temperatur = 5;
      }
    } else if (region === 'Zentrales Mittelreich') {
      if (jahreszeit === 'Sommer') {
        temperatur = 15;
      } else if (jahreszeit === 'Winter') {
        temperatur = 5;
      } else {
        temperatur = 10;
      }
    } else if (region === 'Nördliches Horasreich, Almada, Aranien') {
      if (jahreszeit === 'Sommer') {
        temperatur = 20;
      } else if (jahreszeit === 'Winter') {
        temperatur = 10;
      } else {
        temperatur = 15;
      }
    } else if (region === 'Höhen des Raschtulswalls') {
      if (jahreszeit === 'Sommer') {
        temperatur = 5;
      } else if (jahreszeit === 'Winter') {
        temperatur = -10;
      } else {
        temperatur = 0;
      }
    } else if (region === 'Südliches Horasreich, Reich der Ersten Sonne') {
      if (jahreszeit === 'Sommer') {
        temperatur = 25;
      } else if (jahreszeit === 'Winter') {
        temperatur = 15;
      } else {
        temperatur = 20;
      }
    } else if (region === 'Khôm') {
      if (jahreszeit === 'Sommer') {
        temperatur = 40;
      } else if (jahreszeit === 'Winter') {
        temperatur = 30;
      } else {
        temperatur = 35;
      }
    } else if (region === 'Echsensümpfe, Meridiana') {
      if (jahreszeit === 'Sommer') {
        temperatur = 30;
      } else if (jahreszeit === 'Winter') {
        temperatur = 20;
      } else {
        temperatur = 25;
      }
    } else if (region === 'Altoum, Gewürzinseln, Südmeer') {
      if (jahreszeit === 'Sommer') {
        temperatur = 35;
      } else if (jahreszeit === 'Winter') {
        temperatur = 25;
      } else {
        temperatur = 30;
      }
    }
    return temperatur;
  }

  ermittleNiederschlag(bewoelkung: string, windstaerke, temperatur: number, jahreszeit: string): string {
    let niederschlag = 'kein Niederschlag';
    let wurf = Math.floor(Math.random() * 20) + 1;
    let niederschlagVorhanden = false;
    let gewitter = false;
    if (bewoelkung === 'einzelne Wolken' && wurf === 1) {
      niederschlagVorhanden = true;
    } else if (bewoelkung === 'bewölkt mit Wolkenlücken' && wurf <= 4) {
      niederschlagVorhanden = true;
    } else if (bewoelkung === 'geschlossene Wolkendecke' && wurf <= 10) {
      niederschlagVorhanden = true;
    } else if (bewoelkung === 'Gewitter' && wurf <= 19) {
      niederschlagVorhanden = true;
      gewitter = true;
    }
    const niederschlagsArt: string[][] = [['Nieselregen', 'ergiebiger Regen', 'Wolkenbruch'],
                                  ['Nieselregen', 'leichte Hagelschauer', 'starke Hagelschauer'],
                                  ['leichter Schneefall', 'ergiebiger Scheefall', 'heftiger Schneefall']];
    let artIndex = 0;
    if (temperatur <= 0) {
      if (jahreszeit === 'Sommer' || (jahreszeit !== 'Winter' && gewitter)) {
        artIndex = 1;
      } else {
        artIndex = 2;
      }
    }
    if (niederschlagVorhanden) {
      wurf = Math.floor(Math.random() * 20) + 1;
      if (windstaerke === 'windstill') {
        if (wurf <= 12) {
          niederschlag = niederschlagsArt[artIndex][0];
        } else if (wurf <= 19) {
          niederschlag = niederschlagsArt[artIndex][1];
        } else {
          niederschlag = niederschlagsArt[artIndex][2];
        }
      } else if (windstaerke === 'leichter Wind') {
        if (wurf <= 9) {
          niederschlag = niederschlagsArt[artIndex][0];
        } else if (wurf <= 18) {
          niederschlag = niederschlagsArt[artIndex][1];
        } else {
          niederschlag = niederschlagsArt[artIndex][2];
        }
      } else if (windstaerke === 'sanfte Brise') {
        if (wurf <= 7) {
          niederschlag = niederschlagsArt[artIndex][0];
        } else if (wurf <= 17) {
          niederschlag = niederschlagsArt[artIndex][1];
        } else {
          niederschlag = niederschlagsArt[artIndex][2];
        }
      } else if (windstaerke === 'frische Brise') {
        if (wurf <= 5) {
          niederschlag = niederschlagsArt[artIndex][0];
        } else if (wurf <= 16) {
          niederschlag = niederschlagsArt[artIndex][1];
        } else {
          niederschlag = niederschlagsArt[artIndex][2];
        }
      } else if (windstaerke === 'steife Brise') {
        if (wurf <= 3) {
          niederschlag = niederschlagsArt[artIndex][0];
        } else if (wurf <= 15) {
          niederschlag = niederschlagsArt[artIndex][1];
        } else {
          niederschlag = niederschlagsArt[artIndex][2];
        }
      } else if (windstaerke === 'starker Wind') {
        if (wurf <= 2) {
          niederschlag = niederschlagsArt[artIndex][0];
        } else if (wurf <= 13) {
          niederschlag = niederschlagsArt[artIndex][1];
        } else {
          niederschlag = niederschlagsArt[artIndex][2];
        }
      } else if (windstaerke === 'Sturm') {
        if (wurf <= 1) {
          niederschlag = niederschlagsArt[artIndex][0];
        } else if (wurf <= 10) {
          niederschlag = niederschlagsArt[artIndex][1];
        } else {
          niederschlag = niederschlagsArt[artIndex][2];
        }
      }
    }
    return niederschlag;
  }
}
