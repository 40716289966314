import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../../_services/data.service';
import {Sonderfertigkeit} from '../../../_interfaces/sonderfertigkeit';
import {Spezies} from '../../../_interfaces/spezies';
import {All} from 'tslint/lib/rules/completedDocsRule';
import {Step7} from '../../../_interfaces/charaktererstellung/step7';
import {Step8} from '../../../_interfaces/charaktererstellung/step8';
import {CharakterVuN} from '../../../_interfaces/charakter-vu-n';
import {Heldenerschaffung} from '../../../_interfaces/charaktererstellung/heldenerschaffung';
import {ObjectFactoryService} from '../../../_services/object-factory.service';
import {Talent} from '../../../_interfaces/talent';

@Component({
  selector: 'app-sonderfertigkeit',
  templateUrl: './sonderfertigkeit.component.html',
  styleUrls: ['./sonderfertigkeit.component.sass']
})
export class SonderfertigkeitComponent implements OnInit {
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();
  @Input() heldin: Heldenerschaffung;

  public $allgemeineSF: Sonderfertigkeit[];
  public $neueAnwendungsgebiete: Sonderfertigkeit[];
  public $sprachen: Sonderfertigkeit[];
  public $kampfSF: Sonderfertigkeit[];

  public $magischeSF: Sonderfertigkeit[];
  public $kampfstil: Sonderfertigkeit[];
  public $magiestil: Sonderfertigkeit[];
  public $erwKampf: Sonderfertigkeit[];
  public $erwMagie: Sonderfertigkeit[];
  public alleSF: Sonderfertigkeit[];

  public $stabzauber: Sonderfertigkeit[];

  public profSF: Sonderfertigkeit[];

  public $chosenSF: Sonderfertigkeit;
  public $sfIndex: number;

  public stufenStrings = ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];

  public $voraussetzungen: string[];
  public $stufenliste: string[];
  public $hatStufen: boolean;
  public $chosenStufe: number;
  public $apKosten: number;
  public $kostenSumme: number;
  public $hatNotiz: boolean;
  public $input: string;
  public $hatMerkmal: boolean;
  public $merkmalInput: string;

  public alleTalente: Talent[];
  public chosenTalent: Talent;
  public talentIndex: number;
  public talentanwendungen: string[];
  public chosenTalentanwendung: string;
  public talentanwendungIndex: number;

  // public $listOfChosenSF: any[];

  public $typesOfSF = ['allgemeine Sonderfertigkeiten', 'Kampf-Sonderfertigkeiten', 'Sprachen', 'neue Talentanwendungsgebiete',
    'magische Sonderfertigkeiten', 'Kampfstil-Sonderfertigkeiten', 'Magiestil-Sonderfertigkeiten', 'erweiterte Kampf-Sonderfertigkeiten',
    'erweiterte magische Sonderfertigkeiten', 'Stabzauber'];
  public $chosenType: string;
  public merkmale = ['Antimagie', 'Dämonisch', 'Einfluss', 'Elementar', 'Heilung', 'Hellsicht', 'Illusion', 'Sphären', 'Objekt', 'Telekinese', 'Verwandlung'];

  constructor(public dataService: DataService, private factory: ObjectFactoryService) {
    this.talentanwendungen = [];
    this.chosenTalentanwendung = '';
  }

  ngOnInit(): void {
    this.getAlleSF();
    this.$chosenType = 'allgemeine Sonderfertigkeiten';
    this.$voraussetzungen = [];
    this.calculateGesamtKosten();

  }

  public getAlleTalente(): void {
    this.alleTalente = [];
    this.dataService.getAlleTalente().subscribe((data: Talent[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.alleTalente = data;
      this.talentIndex = 0;
    }, error => {
      console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }

  public getTalent(): void {
    console.log('getTalent');
    this.chosenTalent = this.alleTalente[this.talentIndex];
    this.$chosenSF.mehrfachKathegorie = this.chosenTalent.name;
    this.talentanwendungen = [];
    this.talentanwendungIndex = 0;
    this.chosenTalent.anwendungsgebiete.forEach(anw => {
      this.talentanwendungen.push(anw);
    });
    this.chosenTalentanwendung = this.talentanwendungen[this.talentanwendungIndex];
    this.setTalentanwendung();
  }

  public setTalentanwendung(): void {
    console.log('setTalentanwendung');
    this.chosenTalentanwendung = this.talentanwendungen[this.talentanwendungIndex];
    this.$chosenSF.notiz = this.chosenTalentanwendung;
    this.berechneKosten();
  }

  public getAlleSF(): void {
    this.$allgemeineSF = [];
    this.$neueAnwendungsgebiete = [];
    this.$sprachen = [];
    this.$kampfSF = [];
    this.$magischeSF = [];
    this.$kampfstil = [];
    this.$magiestil = [];
    this.$erwKampf = [];
    this.$erwMagie = [];
    this.alleSF = [];
    this.$stabzauber = [];

    this.$sfIndex = 0;
    this.dataService.getAlleSF().subscribe((data: any[]) => {
      data.sort((a, b) => (a.name < b.name ? -1 : 1));
      // console.log('step 1/getAlleSpezies()/data:');
      console.log(data);
      data.forEach(sf => {
        // console.log(sf.type);
        // AllgemeineSF, KampfSF, NeuesAnwendungsgebiet, Sprache, MagischeSF, Kampfstil, Magiestil, erweiterteKampfSF, erweiterteMagischeSF
        if (sf.type === 'AllgemeineSF') {
          this.$allgemeineSF.push(sf);
        } else if (sf.type === 'NeuesAnwendungsgebiet') {
          this.$neueAnwendungsgebiete.push(sf);
        } else if (sf.type === 'Sprache') {
          this.$sprachen.push(sf);
        } else if (sf.type === 'KampfSF') {
          this.$kampfSF.push(sf);
        } else if (sf.type === 'MagischeSF') {
          this.$magischeSF.push(sf);
        } else if (sf.type === 'Kampfstil') {
          this.$kampfstil.push(sf);
        } else if (sf.type === 'Magiestil') {
          this.$magiestil.push(sf);
        } else if (sf.type === 'erweiterteKampfSF') {
          this.$erwKampf.push(sf);
        } else if (sf.type === 'erweiterteMagischeSF') {
          this.$erwMagie.push(sf);
        } else if (sf.type === 'Stabzauber') {
          this.$stabzauber.push(sf);
        }
      });
      this.alleSF = data;
      this.getSF();
      this.alertParent();
      this.getSFofProfession();
      this.getAlleTalente();
    }, error => {
      // console.log('%cERROR: ${error.message}', 'color: red;');
    });
  }



  public getSF(): void {
    const stufen = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
    this.$hatStufen = false;
    this.$hatNotiz = false;
    this.$hatMerkmal = false;
    this.$input = '';
    this.$merkmalInput = '';
    this.$chosenStufe = 0;
    this.$stufenliste = [];
    switch (this.$typesOfSF.indexOf(this.$chosenType)) {
      case 0:
        this.$chosenSF = this.$allgemeineSF[this.$sfIndex];
        if (this.$chosenSF.stufe > 1) {
          this.$hatStufen = true;
          for (let i = 0; i < this.$chosenSF.stufe; i++) {
            this.$stufenliste.push(stufen[i]);
          }
        }
        if (this.$chosenSF.notiz !== '') {
          this.$hatNotiz = true;
        }
        break;
      case 1:
        this.$chosenSF = this.$kampfSF[this.$sfIndex];
        if (this.$chosenSF.stufe > 1) {
          this.$hatStufen = true;
          for (let i = 0; i < this.$chosenSF.stufe; i++) {
            this.$stufenliste.push(stufen[i]);
          }
        }
        break;
      case 2:
        this.$chosenSF = this.$sprachen[this.$sfIndex];
        if (this.$chosenSF.stufe > 1) {
          this.$hatStufen = true;
          for (let i = 0; i < this.$chosenSF.stufe; i++) {
            this.$stufenliste.push(stufen[i]);
          }
        }
        break;
      case 3:
        this.$chosenSF = this.$neueAnwendungsgebiete[this.$sfIndex];
        break;
      case 4:
        this.$chosenSF = this.$magischeSF[this.$sfIndex];
        if (this.$chosenSF.mehrfachKathegorie !== '') {
          this.$hatMerkmal = true;
          if (this.$chosenSF.mehrfachKathegorie === 'Merkmal') {
            this.$merkmalInput = this.merkmale[0];
          }
        }
        break;
      case 5:
        this.$chosenSF = this.$kampfstil[this.$sfIndex];
        break;
      case 6:
        this.$chosenSF = this.$magiestil[this.$sfIndex];
        break;
      case 7:
        this.$chosenSF = this.$erwKampf[this.$sfIndex];
        break;
      case 8:
        this.$chosenSF = this.$erwMagie[this.$sfIndex];
        break;
      case 9:
        this.$chosenSF = this.$stabzauber[this.$sfIndex];
        if (this.$chosenSF.stufe > 1) {
          this.$hatStufen = true;
          for (let i = 0; i < this.$chosenSF.stufe; i++) {
            this.$stufenliste.push(stufen[i]);
          }
        }
        if (this.$chosenSF.notiz !== '') {
          this.$hatNotiz = true;
        }
        if (this.$chosenSF.mehrfachKathegorie !== '') {
          this.$hatMerkmal = true;
          if (this.$chosenSF.mehrfachKathegorie === 'Merkmal') {
            this.$merkmalInput = this.merkmale[0];
          }
        }
        break;
    }
    this.$apKosten = this.$chosenSF.apWert;
    this.getVoraussetzungen();
    if (this.$chosenSF.name === 'Fertigkeitsspezialisierung') {
      this.getTalent();
    }
  }

  public getVoraussetzungen(): void {
    this.$voraussetzungen = this.$chosenSF.voraussetzungen.split(', ');
  }

  public resetIndex(): void {
    this.$sfIndex = 0;
    this.getSF();
  }

  public setStufe(): void {
    if (this.$hatStufen) {
      if ('stufe' in this.$chosenSF) {
        this.$chosenSF.stufe = this.$chosenStufe + 1;
      }
    }
  }

  public berechneKosten(): void {
    console.log('berechne Kosten');
    if (this.$chosenSF.type === 'AllgemeineSF' || this.$chosenSF.type === 'Stabzauber') {
      let stufe: number = this.$chosenStufe;
      stufe++;
      this.$apKosten = this.$chosenSF.apWert * stufe;
    } else if (this.$chosenSF.type === 'KampfSF') {
      if (this.$hatStufen) {
        if (this.$chosenSF.apJederStufe.length > 0) {
          this.$apKosten = this.$chosenSF.apJederStufe[this.$chosenStufe];
          if (isNaN(this.$apKosten)) {
            let stufe: number = this.$chosenStufe;
            stufe++;
            this.$apKosten = this.$chosenSF.apWert * stufe;
          }
        } else {
          let stufe: number = this.$chosenStufe;
          stufe++;
          this.$apKosten = this.$chosenSF.apWert * stufe;
        }
      } else {
        this.$apKosten = this.$chosenSF.apWert;
      }
    } else if (this.$chosenSF.type === 'Sprache') {
      let stufe: number = this.$chosenStufe;
      stufe++;
      this.$apKosten = this.$chosenSF.apWert * stufe;
    }
    if (this.$chosenSF.name === 'Fertigkeitsspezialisierung') {
      const steigerungswerte = ['A', 'B', 'C', 'D'];
      this.$apKosten = steigerungswerte.indexOf(this.chosenTalent.steigerungskosten) - -1;
      let count = 1;
      this.heldin.sonderfertigkeiten.forEach(sf => {
        if (sf.name === 'Fertigkeitsspezialisierung' && sf.mehrfachKathegorie === this.chosenTalent.name) {
          count++;
        }
      });
      this.$apKosten *= count;
    }
  }

  public addSFtoList(): void {
    let nochNichtVorhanden = true;
    this.heldin.sonderfertigkeiten.forEach(chosen => {
      let string1 = chosen.name;
      let string2 = this.$chosenSF.name;
      if (chosen.hatStufen) {
        string1 += chosen.stufe;
        string2 += (this.$chosenStufe - -1);
      }
      if (this.$chosenSF.notiz !== undefined && this.$chosenSF.notiz !== null) {
        string1 += chosen.notiz;
        string2 += this.$chosenSF.notiz;
      }
      if (this.$chosenSF.mehrfachKathegorie !== undefined && this.$chosenSF.mehrfachKathegorie !== null) {
        string1 += chosen.mehrfachKathegorie;
        string2 += this.$chosenSF.mehrfachKathegorie;
      }
      console.log(string1 + ' === ' + string2);
      if (string1 === string2) {
        nochNichtVorhanden = false;
      }
    });
    if (nochNichtVorhanden) {
      const sf: Sonderfertigkeit = this.factory.getNewSonderfertigkeit();
      sf.name = this.$chosenSF.name;
      sf.beschreibung = this.$chosenSF.beschreibung;
      sf.voraussetzungen = this.$chosenSF.voraussetzungen;
      sf.apWert = this.$apKosten;
      sf.type = this.$chosenSF.type;
      sf.mehrfachKathegorie = this.$chosenSF.mehrfachKathegorie;
      sf.chosenKathegorie = '';
      sf.regel = this.$chosenSF.regel;
      sf.stufe = (this.$chosenStufe - -1);
      sf.hatStufen = this.$chosenSF.hatStufen;
      sf.artDesErwwebs = this.$chosenSF.artDesErwwebs;
      sf.notiz = this.$input;
      sf.mehrfachKathegorie = this.$merkmalInput;
      if (sf.notiz === '') {
        sf.notiz = this.$chosenSF.notiz;
      }
      if (sf.mehrfachKathegorie === '') {
        sf.mehrfachKathegorie = this.$chosenSF.mehrfachKathegorie;
      }
      sf.art = this.$chosenSF.art;
      sf.kampftechniken = this.$chosenSF.kampftechniken;
      sf.apJederStufe = this.$chosenSF.apJederStufe;
      sf.zugehoerigesTalent = this.$chosenSF.zugehoerigesTalent;
      sf.kenntnis = this.$chosenSF.kenntnis;
      sf.schriftName = this.$chosenSF.schriftName;
      sf.kosten = this.$chosenSF.kosten;
      sf.erworben = this.$chosenSF.erworben;
      this.heldin.sonderfertigkeiten.push(sf);
    }
    this.heldin.sonderfertigkeiten.sort((a, b) => (a.name + a.stufe > b.name + b.stufe ? 1 : -1));
    this.calculateGesamtKosten();
    this.berechneKosten();
  }

  public removeSF(sf: any): void {
    const index = this.heldin.sonderfertigkeiten.indexOf(sf);
    // console.log('index: ' + index);
    for (let i = index; i < this.heldin.sonderfertigkeiten.length - 1; i++) {
      this.heldin.sonderfertigkeiten[i] = this.heldin.sonderfertigkeiten[i + 1];
    }
    this.heldin.sonderfertigkeiten.pop();
    this.calculateGesamtKosten();
  }

  public calculateGesamtKosten(): void {
    this.$kostenSumme = 0;
    this.heldin.sonderfertigkeiten.forEach(sf => {
      if (sf.artDesErwwebs === 0) {
        this.$kostenSumme += sf.apWert;
      }
    });
    this.alertParent();
  }

  public alertParent(): void {
    console.log('alertParent()');
    this.heldin.kostenProStep[8] = -1 * this.$kostenSumme;
    this.ping.emit();
  }

  public getSFofProfession(): void {/*
    this.profSF = [];
    this.heldin.profession.sonderfertigkeiten.forEach(sf => {
      if (sf.includes('insgesamt')) {

      } else if (sf.startsWith('Fertigkeitsspezialisierung')) {

      } else {
        console.log(sf);
        let name = sf;
        let stufe = 0;
        let stufenString = '';
        if (sf.includes(' ')) {
          const array = sf.split(' ');
          name = array[0];
          for (let i = 1; i < array.length - 1; i++) {
            name += ' ' + array[i];
          }
          stufenString = array[array.length - 1];
          stufe = this.stufenStrings.indexOf(stufenString);
        }
        const sonderfertigkeit: Sonderfertigkeit = this.getSFwithName(name);
        if (sonderfertigkeit !== undefined) {
          sonderfertigkeit.stufe = stufe;
          console.log('push: ' + sonderfertigkeit.name);
          console.log('kosten: ' + sonderfertigkeit.apWert);
          let kosten = 0;

          let hatStufenAp = true;
          if (sonderfertigkeit.apJederStufe === undefined) {
            hatStufenAp = false;
          } else if (sonderfertigkeit.apJederStufe.length < 1) {
            hatStufenAp = false;
          }

          if (!hatStufenAp) {
            if (sonderfertigkeit.stufe > 0) {
              console.log(kosten);
              kosten -= -sonderfertigkeit.apWert * sonderfertigkeit.stufe;
              console.log(kosten);
            } else {
              console.log(kosten);
              kosten -= -sonderfertigkeit.apWert;
              console.log(kosten);
            }
          } else {
            let index = sonderfertigkeit.stufe - 1;
            if (index < 0) {
              index = 0;
            }
            kosten -= -sonderfertigkeit.apJederStufe[index];
          }
          let neu = true;
          this.heldin.sonderfertigkeiten.forEach(son => {
            if (son.name === sonderfertigkeit.name) {
              neu = false;
            }
          });
          console.log(kosten);
          sonderfertigkeit.artDesErwwebs = 1;
          if (neu) {
            this.heldin.sonderfertigkeiten.push(sonderfertigkeit);
          }
        } else {
          console.log(name + ' not found');
        }
      }
    });*/
  }

  public getSFwithName(name: string): Sonderfertigkeit {
    let wanted: Sonderfertigkeit;
    this.alleSF.forEach(sf => {
      if (sf.name === name) {
        wanted = sf;
      }
    });
    return wanted;
  }
}
